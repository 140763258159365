/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import "./addwebinardatetime.scss";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../../../shared/Modals/Modal";
import { PrimaryButton } from "../../../../../../shared/Buttons/Buttons";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../store/store";
import { addOrUpdateWebinar } from "../../../../../../store/features/AdminPanel/Webinar/WebinarApi";
import { editWebinarInfo } from "../../../../../../store/features/AdminPanel/Webinar/WebinarSlice";

const AddWebinarDateTimeModal = (props: any) => {
  const { isEdit, showModal, setIsEdit, setShowModal } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [timeValue, setTimeValue] = useState("");
  const [errors, setErrors] = useState<any>({});
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayName = days[startDate.getDay()];
  const formattedDate = startDate.toLocaleDateString();
  const dispatch = useAppDispatch();

  const webinarState: any = useAppSelector((store: RootStore) => {
    return store.WebinarSlice;
  });

  const clearForm = () => {
    setShowModal(false);
    setStartDate(new Date());
    setTimeValue("");
    setErrors({});
    setIsEdit(false);
    dispatch(editWebinarInfo({}));
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (startDate < new Date()) {
      setErrors({ date: "The date must be a date after today." });
      return;
    }
    if (timeValue === "") {
      setErrors({ time: "Time is required" });
      return;
    }

    const data: any = {
      date: moment(startDate).format("MM/DD/YYYY, dddd"),
      time: timeValue,
    };

    if (isEdit === true) {
      data.id = webinarState.upcoming_webinar.id;
    }

    dispatch(addOrUpdateWebinar(data));
  };

  useEffect(() => {
    if (webinarState.loading.add === false) {
      clearForm();
    }
  }, [webinarState.loading.add]);

  useEffect(() => {
    if (isEdit === true) {
      const date = webinarState.upcoming_webinar.date.split(",");
      const dateValue = new Date(date[0]);
      const time = moment(webinarState.upcoming_webinar.time, "hh:mm a").format(
        "HH:mm"
      );
      setStartDate(dateValue);
      setTimeValue(time);
    }
  }, [isEdit]);

  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      width="modal_width handle__modal"
      onClick={() => clearForm()}
    >
      <ModalHeader title={isEdit ? "Edit Date & Time" : "Add Date & Time"} />
      <ModalBody modalWidth="100%">
        <form onSubmit={onSubmit}>
          <div className="content">
            <div className="lg_width">
              <div className="custom_date_picker">
                <div className="custom_date_container">
                  <label htmlFor="date">Date</label>
                  {/* @ts-ignore */}
                  <DatePicker className={`timeInput ${errors.date && "error"}`} selected={startDate} value={`${formattedDate},${dayName}`} name="date" onChange={(date) => setStartDate(date)} />
                  {errors.date && (
                    // @ts-ignore
                    <div className="error__text">{errors.date}</div>
                  )}
                  {/* <div className="calender_icon">
                    <Calendar/>
                </div> */}
                </div>
              </div>
            </div>
            <div className="lg_width ">
              <div className="time-box">
                <span>Time</span>
                <input
                  value={timeValue}
                  onChange={(e) => setTimeValue(e.target.value)}
                  type="time"
                  className={`timeInput ${errors.time && "error"}`}
                  name="webinar_time"
                  id="timeInput"
                />
              </div>
              {errors.time && (
                // @ts-ignore
                <div className="error__text">{errors.time}</div>
              )}
            </div>

            <div className="addbtn">
              <div>
                <PrimaryButton
                  loading={webinarState.loading.add}
                  text={isEdit ? "Update" : "Add"}
                  type="submit"
                />
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddWebinarDateTimeModal;
