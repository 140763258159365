import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Subscribers from "../../../pages/AdminPanel/Subscribers/Subscribers";
import TacSubscriber from "../../../pages/AdminPanel/Subscribers/TacSubscriber";

const SubsCriberComp = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="user__tabs__container mt-7">
      <Tabs
        className="user__tab__container"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="user__tab__list__section">
          <Tab className="user__single__tab ">Download E-book</Tab>
          <Tab className="user__single__tab mll">Get inspired user</Tab>
        </TabList>

        <TabPanel>
          <div className="box__shadow">
            <Subscribers />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="box__shadow">
            <TacSubscriber />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default SubsCriberComp;
