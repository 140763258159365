/* eslint-disable import/newline-after-import */
import React, { useState } from "react";
import { Clock } from "react-feather";
import coursImg from "../../../../../assets/images/courseImg.png";
import noImage from "../../../../../assets/images/noImage.svg";
import "./ourCourses.scss";
const OurCourses = () => {
    const [isImage,setIsImage] = useState(true);
  return (
    <div className="pm__user__our__courses">
     
      <div className="pm__user__our__corses__cards__wrapper">
            <h3>Our Courses</h3>
        <div className="pm__user__our__corses__cards">
          {[1, 2, 3, 4,5].map((item) => (
            <div className="our__courses__card" key={item}>
             <div>
             <div className="our__course__image">
                <img src={isImage ? coursImg : noImage} alt="courseImg" />
              </div>
             </div>
              <div className="our__courses__details">
                <h4 className="our__courses__name">Product Strategy</h4>
                <span className="our__courses__description">
                  Thestibulum eget est nec ipsum asd as convallis adafol
                  scelerisque a sed mi lpa Morbi orbi asqw comm aafa.
                </span>
                <div className="our__courses__duration">
                  <Clock /> <span>3 Hr 30 min</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurCourses;
