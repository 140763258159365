import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createVideoIdInBunnyNet,
  deleteVideoTourVideo,
  getVideoFromBunnyNet,
  uploadVideoInBackend,
} from "./VideoTourApi";

const initialState: any = {
  bunny_video_id: "",
  is_loading: {
    videoId: false,
    uploadVideo: false,
    delete: false,
  },

  is_success: {
    videoId: false,
    uploadVideo: false,
    delete: false,
  },
  edit_uploaded_video: false,
  video_data: null,
};

const videoTourSlice = createSlice({
  name: "videoTourSlice",
  initialState,
  reducers: {
    editUploadedVideo: (state, { payload }: PayloadAction<any>) => {
      state.edit_uploaded_video = payload;
      state.bunny_video_id = state.video_data?.content?.videoId
      state.is_loading.uploadVideo = false;
      state.is_success.uploadVideo = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getVideoFromBunnyNet.pending,
      (state, { payload }: PayloadAction<any>) => {
        state.is_loading.delete = false;
        state.is_success.delete = false;
      }
    );
    builder.addCase(
      getVideoFromBunnyNet.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.video_data = payload?.data;
        state.is_loading.delete = false;
        state.is_success.delete = false;
      }
    );
    builder.addCase(
      getVideoFromBunnyNet.rejected,
      (state, { payload }: PayloadAction<any>) => {
        state.is_loading.delete = false;
        state.is_success.delete = false;
      }
    );
    // CREATE VIDEO ID
    builder.addCase(createVideoIdInBunnyNet.pending, (state) => {
      state.is_loading.videoId = true;
      state.is_success.videoId = false;
    });
    builder.addCase(createVideoIdInBunnyNet.fulfilled, (state, { payload }) => {
      state.is_loading.videoId = false;
      state.is_success.videoId = true;
    
      state.bunny_video_id = payload.guid;

    });
    builder.addCase(createVideoIdInBunnyNet.rejected, (state) => {
      state.is_loading.videoId = false;
    });

    // UPLOAD VIDEO IN BUNNENET
    builder.addCase(
      uploadVideoInBackend.pending,
      (state, { payload }: PayloadAction<any>) => {
        state.edit_uploaded_video = false;
        state.is_success.videoId = false;
        state.is_loading.uploadVideo = true;
        state.is_success.uploadVideo = false;
      }
    );

    builder.addCase(
      uploadVideoInBackend.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.edit_uploaded_video = false;
        state.is_success.videoId = false;
        // state.bunny_video_id = "";
        state.is_loading.uploadVideo = false;
        state.is_success.uploadVideo = true;
        state.video_data = payload?.data;
      }
    );
    builder.addCase(
      uploadVideoInBackend.rejected,
      (state, { payload }: PayloadAction<any>) => {
        state.edit_uploaded_video = false;
        state.is_success.videoId = false;
        state.is_loading.uploadVideo = false;
        state.is_success.uploadVideo = false;
      }
    );

    // delete Video
    builder.addCase(
      deleteVideoTourVideo.pending,
      (state, { payload }: PayloadAction<any>) => {
        state.is_loading.delete = true;
        state.is_success.delete = false;
      }
    );

    builder.addCase(
      deleteVideoTourVideo.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.is_loading.delete = false;
        state.is_success.delete = true;

        state.video_data = null;
      }
    );
    builder.addCase(
      deleteVideoTourVideo.rejected,
      (state, { payload }: PayloadAction<any>) => {
        state.is_loading.delete = false;
        state.is_success.delete = false;
      }
    );
  },
});

export default videoTourSlice.reducer;

export const { editUploadedVideo } = videoTourSlice.actions;
