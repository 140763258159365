/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState, useRef } from "react";

// JoditEditor editor
import JoditEditor from "jodit-react";
//
import AdminPanelLayout from "../../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";

import "../index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  AddTermsOfUse,
  getTermsOfUse,
} from "../../../../store/features/AdminPanel/Setting/Terms/TermsApi";

const TermsOfUse = () => {
  const dispatch = useAppDispatch();

  const editor = useRef(null);
  const [content, setContent] = useState("");
  const TermsState: any = useAppSelector((store: RootStore) => {
    return store.TermsOfuseSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const config: any = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    readOnly: false,
    placeholder: "write something here.....",
    height: 700,
    toolbarSticky: false,
    theme: themeState.themeMode === "dark" ? "dark" : "default",
  };

  useEffect(() => {
    dispatch(getTermsOfUse());
  }, []);

  useEffect(() => {
    if (TermsState.req_success) {
      setContent(TermsState?.terms?.content);
    }
  }, [TermsState.req_success]);
  const submitContent = () => {
    dispatch(AddTermsOfUse({ data: content }));
  };

  return (
    <AdminPanelLayout>
      <div className="terms_container mt-7">
        <span className="company__setting">Company Settings</span>
        <span className="company__setting__title">Terms of use</span>

        <div className="editor-wrapper">
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={(newContent) => setContent(newContent)}
          />
        </div>
        <div className="button_sec">
          <PrimaryButton
            style={{ width: "10rem" }}
            text="Submit"
            label="Submit"
            loading={TermsState.loading}
            onClick={submitContent}
          />
        </div>
      </div>
    </AdminPanelLayout>
  );
};

export default TermsOfUse;
