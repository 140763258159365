import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import CommingSoon from "../CommingSoon/CommingSoon";

function ApplicationTracker(props: any) {
  return (
    <>
      <Nav  normal navBgColorHandle  />
      <CommingSoon page="application tracking" />
    </>
  );
}

export default ApplicationTracker;
