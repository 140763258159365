import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { getInterviewAndWebminarVideos } from "./InterviewAndWebminarApi";

const initialState: any = {
  loading: {
    get_videos: false,
  },
  req_success: {
    get_videos: false,
  },
  videos: [],
  all_videos: [],
};

const InterviewAndWebminarSlice = createSlice({
  name: "InterviewAndWebminarSlice",
  initialState,
  reducers: {
    searchVideoInIAW: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length >= 1) {
        state.videos = state.all_videos.filter((e: any) =>
          e.name.toLowerCase().includes(payload.toLowerCase())
        );
      } else {
        state.videos = state.all_videos;
      }
    },
  },
  extraReducers: (builder) => {
    // GET INTERVIEW AND WEBMINAR VIDEOS
    builder.addCase(getInterviewAndWebminarVideos.pending, (state) => {
      state.loading.get_videos = true;
      state.req_success.get_videos = false;
    });
    builder.addCase(
      getInterviewAndWebminarVideos.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_videos = false;
        state.req_success.get_videos = true;
        state.videos = payload?.body?.data;
        state.all_videos = payload?.body?.data;
      }
    );
    builder.addCase(getInterviewAndWebminarVideos.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { searchVideoInIAW } = InterviewAndWebminarSlice.actions;

export default InterviewAndWebminarSlice.reducer;
