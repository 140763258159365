import React from "react"
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout"
import BoxShadow from "../../../shared/BoxShadow/BoxShadow"
import SiteManagerComp from "../../../components/AdminPanel/SiteManager/SiteManagerComp"

const SiteManager = () => {
  return (
    <AdminPanelLayout>
    <BoxShadow>
    <SiteManagerComp/>
    </BoxShadow>
    </AdminPanelLayout>
  )
}

export default SiteManager
