import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";
import { REQUIRED__TOASTER } from "../../../../helpers/utils/TosterError";

export const getInvoices = createAsyncThunk(
  "getInvoices",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/invoices`);

      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getActiveSubscription = createAsyncThunk(
  "getActiveSubscription",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/get-active-subscriptions`
      );

      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const upgradeAccount = createAsyncThunk(
  "upgradeAccount",
  async (
    { data, setProcessingTo }: { data: any; setProcessingTo: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/upgrade-account`,
        data
      );
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      setProcessingTo(false);
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "cancelSubscription",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/cancel-subscription`);
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCardInfo = createAsyncThunk(
  "getCardInfo",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/get-cards`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const applyCouponCode = createAsyncThunk(
  "applyCouponCode",
  async (
    { data, setCouponError }: { data: any; setCouponError: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/applyCoupon`, data);
      return res.data;
    } catch (error: any) {
      setCouponError("Your coupon code is invalid");
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkUserPricing = createAsyncThunk(
  "checkUserPricing",
  async ({ data }: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      let res: any;

      if (data) {
        res = await axiosInstance.post(`${dynamic_url}/checkUserPricing`, data);
      } else {
        res = await axiosInstance.post(`${dynamic_url}/checkUserPricing`);
      }
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
