import LoginFrom from "../../components/Auth/AuthForm/LoginForm/LoginFrom";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";

const Login = (props: any) => {
  return (
    <LandingLayout>
      <AuthLayout>
        <LoginFrom />
      </AuthLayout>
    </LandingLayout>
  );
};

export default Login;
