import { createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { ERROR__TOASTER } from "../../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const getLearningPathForStudent = createAsyncThunk(
  "getLearningPathForStudent",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/learning-paths`);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLearningPathDetailForStudent = createAsyncThunk(
  "getLearningPathDetailForStudent",
  async (
    { learningPathId }: { learningPathId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/learning-path-courses/${learningPathId}`
      );
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
