import React from "react";
import BlogBox from "../BlogBox/BlogBox";
import "./index.scss";

function ViewBlog(props: any) {
  return (
    <div className="view-blog-container">
      <div className="view-single-blog-container">
        <div className="single-blog-title">
          set video playback speed with javascript
        </div>
        <div className="blog-detail-container">
          <div className="author-img">
            <img
              src="https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=2000"
              alt="author"
            />
          </div>
          <div className="author-name">John Doe</div>
          <div className="blog-date">on March 16, 2022</div>
        </div>
        <div className="single-blog-image">
          <img
            src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg"
            alt="single"
          />
        </div>
        <div className="single-blog-content">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
            sint animi perspiciatis neque eligendi magni incidunt assumenda,
            odio tempore sit quam facere quaerat doloremque dolorem. Eaque
            corrupti similique, mollitia est asperiores quia cumque enim
            distinctio, consequatur consequuntur quidem fugiat cupiditate
            veritatis ipsam ab necessitatibus, quaerat nihil doloribus assumenda
            obcaecati. Earum?Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Officiis sint animi perspiciatis neque eligendi magni incidunt
            assumenda, odio tempore sit quam facere quaerat doloremque dolorem.
            Eaque corrupti similique, mollitia est asperiores quia cumque enim
            distinctio, consequatur consequuntur quidem fugiat cupiditate
            veritatis ipsam ab necessitatibus, quaerat nihil doloribus assumenda
            obcaecati. Earum? Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Officiis sint animi perspiciatis neque eligendi magni incidunt
            assumenda, odio tempore sit quam facere quaerat doloremque dolorem.
            Eaque corrupti similique, mollitia est asperiores quia cumque enim
            distinctio, consequatur consequuntur quidem fugiat cupiditate
            veritatis ipsam ab necessitatibus, quaerat nihil doloribus assumenda
            obcaecati. Earum?
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
            sint animi perspiciatis neque eligendi magni incidunt assumenda,
            odio tempore sit quam facere quaerat doloremque dolorem. Eaque
            corrupti similique, mollitia est asperiores quia cumque enim
            distinctio, consequatur consequuntur quidem fugiat cupiditate
            veritatis ipsam ab necessitatibus, quaerat nihil doloribus assumenda
            obcaecati. Earum?
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
            sint animi perspiciatis neque eligendi magni incidunt assumenda,
            odio tempore sit quam facere quaerat doloremque dolorem. Eaque
            corrupti similique, mollitia est asperiores quia cumque enim
            distinctio, consequatur consequuntur quidem fugiat cupiditate
            veritatis ipsam ab necessitatibus, quaerat nihil doloribus assumenda
            obcaecati. Earum?
          </p>
        </div>
      </div>
      <div className="section-title">Related Blogs</div>
      <div className="all-blog-posts-container">
        {[1, 2, 3].map(() => {
          return (
            <BlogBox
              category="Design"
              title="Feugiat adipiscing neque, at diam"
              authorImg="https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=2000"
              authorName="Paul Lomino"
              blogImg="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
              date="March 16, 2022"
              slug="/blogs/test-blog"
            />
          );
        })}
      </div>
    </div>
  );
}

export default ViewBlog;
