import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../../shared/Modals/Modal";
import {
  DangerButton,
  TACPrimaryButton,
} from "../../../../../shared/Buttons/Buttons";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import { cancelSubscription } from "../../../../../store/features/UserDashboard/Payment/PaymentApi";
import "./index.scss";

const CancelSubscriptionModal = (props: any) => {
  const { showModal, setShowModal } = props;
  const dispatch = useAppDispatch();
  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });
  const handleModal = () => {};
  return (
    <Modal show={showModal} setShow={setShowModal} onClick={handleModal}>
      <ModalHeader title="Are you sure you want to cancel?" />
      <div className="cancel__subscription__container">
        {" "}
        <ModalBody>
          <p className="cancel__subscription__desc">
            {" "}
            You won't be charged again, and you'll lose access to all premium
            features.
          </p>
          <div className="cancel__sub__divider" />
        </ModalBody>
        <ModalFooter>
          <div className="cancel__subscription__button__section">
            <div className="confirm__button">
              <TACPrimaryButton
                text="Yes, cancel it"
                onClick={() => dispatch(cancelSubscription())}
                loading={paymentState.loading.cancel_subscription}
              />
            </div>
            <div className="cancel__button">
              <DangerButton text="Cancel" onClick={() => setShowModal(false)} />
            </div>
          </div>
        </ModalFooter>
      </div>
    </Modal>
  );
};

export default CancelSubscriptionModal;
