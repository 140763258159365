interface ITeam {
  name: string;
  designation: string;
  meetingLink: string;
}

const MeetCard = (props: ITeam) => {
  const { name, designation, meetingLink } = props;
  return (
    <div className="meet_card__section">
      <div className="top__section">
        <span className="name"> {name}</span>
        <span className="post"> {designation}</span>
      </div>
      <div className="bottom__section">
        <a
          href={meetingLink}
          target="_blank"
          rel="noreferrer"
          role="button"
          className="candely__button"
        >
          Calendly Link
        </a>
      </div>
    </div>
  );
};

export default MeetCard;
