import { useLocation, useNavigate } from "react-router-dom";
import { Book } from "react-feather";
import { RootStore, useAppSelector } from "../../../store/store";

import CustomLink from "./CustomLink";
import { ROUTES } from "../../../helpers/utils/Constants/route";
import { TACPrimaryButton } from "../../../shared/Buttons/Buttons";
import NewSideBarLink from "../NewSideBarLink/NewSideBarLink";
import faqIcon from "../../../assets/icons/clipboard.svg";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";


const TacUserSidebar = (props: any) => {
  const { smSidebar, redirectToLandingBlogPage } = props;
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  
  const isTacUser = configState.configs?.membership_enabled === Domain.TAC ;

  return (
    <>
      {!pathname.includes("progress") && (
        <CustomLink
          icon="ph:chart-bar-light"
          title="My Progress"
          route="/progress"
          smSidebar={smSidebar}
        />
      )}
      {pathname.includes("progress") && (
        <CustomLink
          icon="ph:chart-bar-light"
          title="My Progress"
          route="/progress"
          smSidebar={smSidebar}
          active
        />
      )}
      {!pathname.includes("learning-path-stepper") && (
        <CustomLink
          icon="solar:lightbulb-outline"
          title="Learning Paths"
          route="/learning-path"
          smSidebar={smSidebar}
        />
      )}
      {pathname.includes("learning-path-stepper") && (
        <CustomLink
          icon="solar:lightbulb-outline"
          title="Learning Paths"
          route="/learning-path"
          smSidebar={smSidebar}
          active
        />
      )}

      {/* {!pathname.includes("view-course") && (
        <CustomLink
          icon="lucide:book"
          title="Courses"
          route="/list-course"
          smSidebar={smSidebar}
          sm
        />
      )}
      {pathname.includes("view-course") && (
        <CustomLink
          icon="lucide:book"
          title="Courses"
          route="/list-course"
          smSidebar={smSidebar}
          active
          sm
        />
      )}
       */}
            <NewSideBarLink
                img={faqIcon}
                icon={<Book />}
                title="Courses"
                smSidebar={smSidebar}
                isTacUser={isTacUser}
                links={[
                  {
                    id: 1,
                    title: "All Courses",
                    path: ROUTES.LIST_ALL_COURSES,
                    isLinkIcon:false,
                    from:"all"
                  },
                  {
                    id: 2,
                    title: "Get Agile Certified",
                    path:ROUTES.ALL_PREMIUM_COURSES,
                    isLinkIcon:true,
                    from:"certified"
                  },
                ]}
            />

      <CustomLink
        icon="ph:user-switch-thin"
        title="User Resources"
        route="/user-resources"
        smSidebar={smSidebar}
      />
      <CustomLink
        icon="ph:certificate"
        title="My Certificates"
        route={ROUTES.MY_CERTIFICATE}
        smSidebar={smSidebar}
      />
      <CustomLink
        icon="jam:write"
        title="Blogs"
        route="/blogs"
        smSidebar={smSidebar}
        onClick={redirectToLandingBlogPage}
        sm
      />

      <CustomLink
        icon="ic:outline-mode-comment"
        title="Interviews and Webinars"
        route="/interviews-and-webinars"
        smSidebar={smSidebar}
        sm
      />
      <CustomLink
        icon="carbon:headset"
        title="Audio Podcast"
        route="/audio-podcast"
        smSidebar={smSidebar}
      />
      <CustomLink
        icon="bx:bot"
        title="AI-Assist"
        route="/ai-assist"
        smSidebar={smSidebar}
      />
      {!smSidebar && authState.tac_user?.plan === "free" && (
        <div className="upgrade__button__container">
          <TACPrimaryButton
            text="Upgrade"
            onClick={() => navigate("/accounts/subscriptions")}
          />{" "}
        </div>
      )}
    </>
  );
};

export default TacUserSidebar;
