/* eslint-disable consistent-return */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { ChevronLeft } from "react-feather";
import Avatar from "../../../shared/Avatar/Avatar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import {
  getTenantCoursesFromPath,
  tenantEntollToCourse,
} from "../../../store/features/Tenant/Orgination/LearningPath/TenantLearningPathApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  // THINKFIC_BASE_URL,
  THINKFIC_COURSE_URL,
} from "../../../apiConfigs/urlConfig";
import { enrollToThinkificCourse, loginToThinkificAndRedirect } from "../../../store/features/Thinkific/ThikificApi";
import "./index.scss";
import TenantLoadingScreen from "../../Auth/TenantLoading/TenantLoadingScreen";

const AdminLearningPathCourses = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [slug, setSlug] = useState("");
  const pathState: any = useAppSelector((store: RootStore) => {
    return store.TenantLearningPathSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  useEffect(() => {
    dispatch(getTenantCoursesFromPath({ pathId: Number(params.id) }));
  }, []);
  //
  const startLearning = (course: any) => {
    setSlug(course.slug);

    dispatch(enrollToThinkificCourse({ courseId: course.course_id })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: course.slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          console.log(res.payload.url);
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };
  useEffect(() => {
    if (pathState.req_success.enroll) {
      window.location.href = `${themeState.configs.site_url}/login/${authState.tac_user.email}/${slug}/${authState.tenant}`;
    }
  }, [pathState.req_success.enroll]);

  return (
    <>
  
    <div className="UserLearningPathCourses__wrapper">
      {!pathState.loading.path_courses &&
        pathState.req_success.path_courses && (
          <>
            {" "}
            <div className="courses__wrapper">
              <div className="header__section">
                <ChevronLeft
                  className="back__icon"
                  onClick={() => navigate("/admin-learningpath")}
                />
                <span className="header__title">learning path detail </span>
              </div>

              <div className="stepper__container">
                {pathState.data.courses.map((course: any) => {
                  return (
                    <div className="stepper" key={course.id}>
                      <span
                        className={`counter ${
                          course.percentage_completed > 0 ? "started" : ""
                        } ${
                          Math.floor(Number(course.percentage_completed)) ===
                          100
                            ? "completed"
                            : ""
                        }`}
                      />
                      <div className="course__detail">
                        <span className="title">{course.name}</span>
                        <span className="duration">
                          Duration {course.custom_duration}
                        </span>
                        <span className="description">
                          {" "}
                          {course.description}
                        </span>
                        <div className="button_width_stepper completed_width">
                          {Math.floor(course.percentage_completed) === 0 && (
                            <PrimaryButton
                              onClick={() => startLearning(course)}
                              text="Start Course"
                              loading={course.slug === slug}
                            />
                          )}
                          {Math.floor(course.percentage_completed) > 0 &&
                            Math.floor(course.percentage_completed) < 100 && (
                              <PrimaryButton
                                onClick={() => startLearning(course)}
                                text="Resume Course"
                                loading={course.slug === slug}
                              />
                            )}
                          {Math.floor(course.percentage_completed) === 100 && (
                            <PrimaryButton
                              onClick={() => startLearning(course)}
                              text="Course Completed"
                              loading={course.slug === slug}
                            />
                          )}
                        </div>

                        <div className="stepper-divider" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="courses__detail__wrapper">
              <div className="card__section">
                <span className="buldle__tile">
                  {pathState.data.learning_path.name}
                </span>
                <div className="row">
                  <span className="coures__count">
                    {pathState.data.learning_path.course_count} Courses
                  </span>
                  {/* <span className="deadline"> Deadline: 28/11/2022</span> */}
                </div>
                <ProgressBar
                  completed={pathState.data.learning_path.progress || 0}
                  height="3px"
                  customLabel="."
                  baseBgColor="var( --primary-progress-bar-bg)"
                  bgColor="var( --secondary-progress-bar-bg)"
                  labelSize="0"
                />
                <span className="completed__count">
                  {Math.floor(pathState.data.learning_path.progress || 0)}%
                </span>
                <p className="description">
                  Learn Agile from the ground up, including popular Agile
                  frameworks; Scrum, Kanban, and Scrumban
                </p>

                <div className="detail__and__instructor__divider" />
                {pathState.data.instructors.map((instructor: any) => {
                  return (
                    <div className="instructor__profile" key={instructor.id}>
                      <div className="pic">
                        <Avatar imageUrl={instructor.avatar_url} />
                      </div>
                      <div className="info">
                        <span className="name">
                          {instructor.first_name} {instructor.last_name}
                        </span>
                        <span className="occupation"> {instructor.title}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
        
    </div>
    {
          pathState.loading.path_courses && (<TenantLoadingScreen message=""/>)
        }
    </>
  );
};

export default AdminLearningPathCourses;
