import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";

const validationSchema = yup.object().shape({
    title: yup.string().required(REQUIRED_VALIDATION("Title")),
  });
  
  export const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  