/* eslint-disable func-names */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import * as tus from "tus-js-client";
import { sha256 } from "js-sha256";

import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import {
  AUTH_URL,
  BUNNY_API_KEY,
  BUNNY_INTERVIEW_PREP_COLLECTION_ID,
  BUNNY_VIDEO_LIBRARY_ID,
} from "../../../../../apiConfigs/urlConfig";
import { REQUIRED__TOASTER } from "../../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const getInterviewRoles = createAsyncThunk(
  "getInterviewRoles",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(`${dynamic_url}/interview-roles`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInterviewQuestionsbyRoleId = createAsyncThunk(
  "getInterviewQuestionsbyRoleId",
  async ({ id }: { id: number }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(
        `${dynamic_url}/interview-questions-by-role/${id}`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const SubmitInterviewAnswer = createAsyncThunk(
  "SubmitInterviewAnswer",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.post(
        `${dynamic_url}/submit-interview-answer`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const submitAnswerFeedback = createAsyncThunk(
  "submitAnswerFeedback",
  async (
    { id, data }: { id: number; data: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.post(
        `${dynamic_url}/submit-answer-feedback/${id}`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUserFeedback = createAsyncThunk(
  "deleteUserFeedback",
  async (id: { id: number }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.delete(
        `${dynamic_url}/delete-answer-feedback/${id}`
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAnswerVideo = createAsyncThunk(
  "deleteUserVideo",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.post(
        `${dynamic_url}/delete-answer-videos/`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getQuestionAnswerById = createAsyncThunk(
  "getQuestionAnswerById",
  async ({ id }: { id: number }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(
        `${dynamic_url}/get-question-answer-by-id/${id}`
      );
      // REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      // toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPresignedUrlForAudioUpload = createAsyncThunk(
  "getPresignedUrlForAudioUpload",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(
        `${dynamic_url}/get-interview-audio-upload-url`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createVideoId = createAsyncThunk(
  "createVideoId",
  async ({ data }: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const res = await axios.post(
        `https://video.bunnycdn.com/library/${BUNNY_VIDEO_LIBRARY_ID}/videos`,
        data,
        {
          headers: {
            AccessKey: BUNNY_API_KEY,
          },
        }
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// export const uploadVideoToAws = createAsyncThunk(
//   "uploadVideoToAws",
//   async (
//     {
//       url,
//       file,
//       setUploadProgress,
//     }: { url: string; file: any; setUploadProgress: any },
//     { dispatch, rejectWithValue }
//   ) => {
//     try {
//       const res = await axios.put(url, file, {
//         headers: {
//           "Content-Type": file.type,
//         },
//         onUploadProgress: (progressEvent) => {
//           const percentCompleted = Math.round(
//             (progressEvent.loaded * 100) / progressEvent.total
//           );
//           setUploadProgress(percentCompleted);
//         },
//       });
//       return res.data;
//     } catch (error: any) {
//       toast.error(error.response.data.message);
//       if (!error.response) {
//         throw error;
//       }
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const uploadVideoToBunny = createAsyncThunk(
  "uploadVideoToBunny",
  async (
    {
      data,
      videoId,
      setTsuInstance,
      setVideoStatus,
      videoStatus,
      setVideoUploadedProgress,
    }: {
      data: any;
      videoId: any;
      setTsuInstance: any;
      videoStatus: any;
      setVideoStatus: any;
      setVideoUploadedProgress: any;
    },
    { dispatch, rejectWithValue }
  ) => {
    const expiration_time = Math.floor(Date.now() / 1000) + 24 * 60 * 60;

    try {
      setVideoStatus({ ...videoStatus, loading: true });
      // Create a new tus upload
      const res = new tus.Upload(data, {
        uploadUrl: "https://video.bunnycdn.com/tusupload",
        endpoint: "https://video.bunnycdn.com/tusupload",
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
          AuthorizationSignature: sha256(
            BUNNY_VIDEO_LIBRARY_ID + BUNNY_API_KEY + expiration_time + videoId
          ), // SHA256 signature (library_id + api_key + expiration_time + video_id)
          AuthorizationExpire: expiration_time.toString(), // Expiration time as in the signature,
          VideoId: videoId, // The guid of a previously created video object through the Create Video API call
          LibraryId: BUNNY_VIDEO_LIBRARY_ID,
        },
        metadata: {
          filetype: data.type,
          title: data?.name,
          collection: BUNNY_INTERVIEW_PREP_COLLECTION_ID,
        },
        onError: function (error: any) {
          console.log(error, "error");
        },
        onProgress: function (bytesUploaded: any, bytesTotal: any) {
          const percent = Math.floor((bytesUploaded / bytesTotal) * 100);
          setVideoUploadedProgress(percent);
        },
        onSuccess: function () {
          setVideoStatus({ ...videoStatus, success: true, loading: false });
        },
      });
      setTsuInstance(res);
      // Check if there are any previous uploads to continue.
      res.findPreviousUploads().then(function (previousUploads: any) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          res.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        res.start();
      });
      return res;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadAudioToAws = createAsyncThunk(
  "uploadAudioToAws",
  async (
    { url, file }: { url: string; file: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);