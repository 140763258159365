import { MessageSquare } from "react-feather";
import { RootStore, useAppSelector } from "../../../../store/store";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../../helpers/utils/Constants/roles";

const Question = (props: any) => {

  const { question, id, handleConversation, active } = props;
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <div className="question_section_wrapper">
      {" "}
      <div
        className={`question__section ${active && "active"} ${ authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA && "question__section__style "}`}
        role="button"
        aria-hidden
        onClick={() => handleConversation(id)}
      >
        <MessageSquare className="message__square__icon"/>
        <span>{question}</span>
      </div>
    </div>
  );
};

export default Question;
