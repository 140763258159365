import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";


export const getBannersData = createAsyncThunk(
  "getBannersData",
  async (
    undefined,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/banners`
      );
      return res.data;
    } catch (error: any) {
      console.log(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addOrUpdateBanner = createAsyncThunk(
  "addOrUpdateBanner",
  async (
    { data,id }: { data: any,id:any},
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
        console.log(data,id);
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/banners/${id}`,
        data
      );
      REQUIRED__TOASTER(res.data);
      
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

