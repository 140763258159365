import React from "react";
import "./index.scss";

const Active = (props:any) => {
  const {text} =props;
  return (
    <div className="status__active">
      <div className="active__dot">.</div>
      <span className="active__text">{text}</span>
    </div>
  );
};

export default Active;
