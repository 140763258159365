/* eslint-disable react/no-danger */
import React from "react";

const SingleNotification = (props: any) => {
  const { notification, updateNotificationStatus } = props;
  return (
    <div
      className="notification"
      role="button"
      aria-hidden
      onClick={() => {
        updateNotificationStatus(notification);
        // navigate("/interview");
      }}
    >
      <div className="notify">
        <div className="user__profile">
          <div className="user__profile__picture">
            <div className="user__first__letter">{notification?.user_name}</div>
          </div>
          {notification?.status === "unread" && <span />}
        </div>
        <div className="user__comment">
          <div
            className="comment__for"
            dangerouslySetInnerHTML={{
              __html: notification.message,
            }}
          />
          <span className="commented__time">{notification.created_at}</span>
        </div>
      </div>
      <div className="notification__line" />
    </div>
  );
};

export default SingleNotification;
