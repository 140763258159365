import React, {
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import "./index.scss";
import { ArrowRight } from "react-feather";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  adduserQuestionToMessageStack,
  askNewQuestionToAi,
} from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";

const ChatboxInputMessage = () => {
  const dispatch = useAppDispatch();
  const questionRef: any = useRef();
  const [input, setInput] = useState<string>("");
  const [keyCode, setKeycode] = useState<string>("");

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  const resizeTextArea = () => {
    questionRef.current.style.height = "auto";
    questionRef.current.style.height = `${questionRef.current.scrollHeight}px`;
  };
  useEffect(resizeTextArea, [input]);

  const handleInputChange = (e: any) => {
    setInput(e.target.value);
  };

  const sbumitQuestion = () => {
    if (
      aiState.loading.question ||
      aiState.loading.current_answer ||
      input.trim().length === 0
    ) {
      return;
    }

    dispatch(adduserQuestionToMessageStack(input));
    dispatch(askNewQuestionToAi(input));
    setInput("");
  };

  const handleKeycode = (event: any) => {
    if (event.code === "Enter" || event.code === "enter") {
      event.preventDefault();
      sbumitQuestion();
    }
  };

  return (
    <div className="chatbot__message__input__section">
      <textarea
        ref={questionRef}
        className="chatbot__chat__textarea"
        rows={1}
        value={input}
        placeholder="Ask anything..."
        onKeyDown={handleKeycode}
        onChange={handleInputChange}
      >
        {" "}
      </textarea>

      <ArrowRight
        role="button"
        aria-hidden
        className="right_arrow"
        onClick={() => sbumitQuestion()}
      />
    </div>
  );
};

export default ChatboxInputMessage;
