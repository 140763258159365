import React from "react";
import { Edit2, MoreVertical, Star, Trash } from "react-feather";
import Rating from "react-rating";
import AudioPlayer from "../../../../shared/AudioPlayer/AudioPlayer";
import { roles } from "../../../../helpers/utils/Constants/roles";
import { RootStore, useAppSelector } from "../../../../store/store";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const SingleFeedback = (props: any) => {
  const {
    feedback,
    authState,
    handleAction,
    setShowAction,
    actionData,
    showAction,
    setDeleteShowModal,
    setShowReplyBox,
    handleDeleteEditDropdownStyle
  } = props;

  return (
    <div className="single__feedbacK__wrapper">
      <div className="left__section">
        <div className={`avatar__name ${handleDeleteEditDropdownStyle && "avatar__name__style"}`}>{feedback.user.charAt(0)}</div>
      </div>
      <div className="right__section">
        {" "}
        <div className="top__section">
          <div className="user__name__role">
            <span className="user__name">{feedback.user}</span>
            <span className="dot" />
            <span className="user__role">{feedback.user_role}</span>
          </div>
          {authState.tac_user && authState.tac_user.id === feedback.user_id && (
            <MoreVertical
              className="three__dot"
              onClick={() => handleAction(feedback)}
            />
          )}
          <div
            className={`select__section ${handleDeleteEditDropdownStyle && "handleDeleteEditDropdownStyle"} ${
              showAction && actionData.id === feedback.id && "active"
            }` }
          >
            {feedback.type === "text" && (
              <>
                {" "}
                <div
                  className="select__action"
                  role="button"
                  aria-hidden
                  onClick={() => setShowReplyBox(true)}
                >
                  <Edit2 className="action__icon" /> <span> Edit</span>
                </div>
                <div className={`action__divider ${handleDeleteEditDropdownStyle && "action__divider__style__handle"}`} />
              </>
            )}

            <div
              className="select__action"
              role="button"
              aria-hidden
              onClick={() => setDeleteShowModal(true)}
            >
              <Trash className="action__icon" /> <span> Delete</span>
            </div>
          </div>
        </div>
        <div className="content__section">
          {feedback.rating && feedback.user_role !== "User" && (
            /* @ts-ignore */
            <Rating
              quiet
              initialRating={feedback.rating}
              emptySymbol={
                <Star className="rating__star__size" color="#FFC600" />
              }
              readonly
              fullSymbol={
                <Star
                  className="rating__star__size"
                  color="#FFC600"
                  fill="#FFC600"
                />
              }
            />
          )}
          {feedback.type === "text" && <p>{feedback.feedback}</p>}
        </div>
        {feedback.type === "audio" && (
         <div className="mt-1">
           <AudioPlayer duration={feedback.duration} url={feedback.feedback} AudiPlayerStyleHanlde={true}/>
         </div>
        )}
      </div>
    </div>
  );
};

export default SingleFeedback;
