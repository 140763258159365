import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import OrgUsercourses from "../../../components/OrginationUser/UserCourses/OrgUsercourses";

const OrgUserCoursesPage = () => {
  return (
    <AdminPanelLayout>
      <OrgUsercourses />
    </AdminPanelLayout>
  );
};

export default OrgUserCoursesPage;
