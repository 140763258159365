import { Calendar, ChevronDown } from "react-feather";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import { useEffect, useState } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import darkArchiveEmptyImg from "../../../assets/images/archive-dark.svg";
import lightArchiveEmptyImg from "../../../assets/images/archive-light.svg";
import { getClassArchives } from "../../../store/features/UserDashboard/Archives/ArchiveApi";
import {
  filterBydate,
} from "../../../store/features/UserDashboard/Archives/ArchiveSlice";


const ArchiveTable = (props:any) => {
  const {searchByMonthInTopic,setSearchByMonthInTopic} =props;
  const [startDate, setStartDate] = useState(new Date());
  const [emptyDate, setEmptyDate] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const archiveState: any = useAppSelector((store: RootStore) => {
    return store.ClassArchiveSlice;
  });

  const handleChange = (e: any) => {
    setIsOpen(!isOpen);
    setStartDate(e);
    const selectedDate = moment(e).format("YYYY-MM-DD");
    dispatch(filterBydate(selectedDate));
    setEmptyDate(false);
    setSearchByMonthInTopic(null);
  };
  const handleClick = (e: any) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const clearAndClose = () => {
    setIsOpen(false);
    dispatch(filterBydate(""));
    setEmptyDate(true);
  };
  useEffect(() => {
    dispatch(getClassArchives());
  }, []);

  const resetHandle = () => {
    clearAndClose();
  };

  return (
    <div className="archive__class__table__container">
      <div className="search__filter__wrapper secect__section filter__section">
        <div className="date__filter__wrapper">
          <p className="filter__text">Filter by date:</p>
          <div className="title__section">
            <button
              type="button"
              className="example-custom-input"
              onClick={handleClick}
            >
              <Calendar className="down__icon" />
              {emptyDate ? "" : moment(startDate).format("MMMM D, yyyy")}
            </button>
            {isOpen && (
              <DatePicker
                selected={startDate}
                onChange={handleChange}
                closeOnScroll
                inline
              />
            )}
            <div className={`custom_date_clear_section ${isOpen && "active"}`}>
              <span
                className="calendaer__button"
                role="button"
                onClick={clearAndClose}
                aria-hidden="true"
              >
                clear
              </span>
              <span
                className="calendaer__button"
                role="button"
                onClick={() => setIsOpen(false)}
                aria-hidden="true"
              >
                close
              </span>
            </div>
          </div>
          {!emptyDate && (
            <div className="reset__btn">
              <button type="button" onClick={() => resetHandle()}>
                Reset
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="div__talbe__section">
        <div className="table__heading__section">
          <div className="heading__title date">Date</div>
          <div className="heading__title topic">Topic</div>
          <div className="heading__title link">
          Recording Link</div>
        </div>

        <div className="table__body__section custom__scrollbar__new">
          {!archiveState.loading &&
            archiveState.req_success &&
            archiveState.archives.map((archive: any) => {
              return (
                <div
                  className={`table__row__section ${
                    themeState.themeMode === "light" ? "light" : "dark"
                  }`}
                  key={archive.id}
                >
                  <div className="table__data">
                    <div className="width__handle">
                      <span className="date">{archive.date} {moment(archive?.filter_date).format("YYYY")} </span>
                      <span className="time">
                        {archive.time_from} - {archive.time_to}
                      </span>
                    </div>
                  </div>
                  <div className="table__data topic__content">
                    <div className="topic__width__handle">{archive.topic}</div>
                  </div>
                  <div className="table__data">
                    {" "}
                    <div className="link__width__handle">
                      <a
                        href={archive.video_link}
                        target="_blank"
                        rel="noreferrer"
                        className="anchor"
                      >
                        Recording link 
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        {!archiveState.loading &&
          archiveState.req_success &&
          archiveState.archives.length === 0 && (
            <div className="archive__empty__wrapper">
              <div className="archive__empty__container">
                <img
                  src={
                    themeState.themeMode === "dark"
                      ? darkArchiveEmptyImg
                      : lightArchiveEmptyImg
                  }
                  alt=""
                />
                <p>No class archives added. </p>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default ArchiveTable;
