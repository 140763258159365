import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getInvoices,
  getActiveSubscription,
  upgradeAccount,
  cancelSubscription,
  getCardInfo,
  applyCouponCode,
  checkUserPricing,
} from "./PaymentApi";

const initialState: any = {
  loading: {
    invoice: false,
    subscription: false,
    upgrade_account: false,
    cancel_subscription: false,
    card_info: false,
    apply_coupon: false,
    pricing: false,
  },
  req_success: {
    invoice: false,
    subscription: false,
    upgrade_account: false,
    cancel_subscription: false,
    card_info: false,
    apply_coupon: false,
    pricing: false,
  },
  pay_to_reaccess: false,
  current_page: 0,
  clear_stripe: false,
  edit_payment: false,
  single_payment_info: {},
  single_card_info: {},

  invoices: [],
  temp_invoices: [],
  all_invoices: [],
  active_subscription: [],
  price_after_coupon: {},
  pricing: {},
};

const TACPaymentSlice = createSlice({
  name: "Payment",
  initialState,
  reducers: {
    invoicePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.invoices = state.all_invoices.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.invoices = state.all_invoices.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.invoices = state.all_invoices.slice(
          payload * 10,
          10 * payload + 10
        );
      }
    },
    searchInvoices: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_invoices = state.all_invoices.filter((invoice: any) =>
          invoice.invoice_number.toLowerCase().includes(payload.toLowerCase())
        );
        state.invoices = state.temp_invoices.slice(0, 10);
      } else {
        state.invoices = state.all_invoices.slice(
          state.current_page * 10,
          10 * state.current_page + 10
        );
      }
    },
    clearStripe: (state: any) => {
      state.clear_stripe = true;
      state.req_success.apply_coupon = false;
      state.price_after_coupon = {};
    },
    clearStripeFalse: (state: any) => {
      state.clear_stripe = false;
      state.req_success.card_info = false;
    },

    clearCouponAndDiscount: (state: any) => {
      state.req_success.apply_coupon = false;
      state.price_after_coupon = {};
    },

    editUserPayment: (state: any, { payload }: PayloadAction<any>) => {
      state.edit_payment = true;
      state.single_payment_info = payload;
    },

    paytoReAccess: (state: any, { payload }: PayloadAction<any>) => {
      state.pay_to_reaccess = payload;
      state.edit_payment = payload;
    },
    closeEditUserPayment: (state: any) => {
      state.edit_payment = false;
      state.single_payment_info = {};
    },

    clearCoupon: (state: any) => {
      state.edit_payment = false;
      state.single_payment_info = {};
    },
  },
  extraReducers: (builder) => {
    // GET ALL INVOICE
    builder.addCase(getInvoices.pending, (state) => {
      state.loading.invoice = true;
      state.req_success.invoice = false;
    });
    builder.addCase(
      getInvoices.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.invoice = false;
        state.req_success.invoice = true;
        state.invoices = payload.data;
        state.all_invoices = payload.data;
      }
    );
    builder.addCase(getInvoices.rejected, (state) => {
      state.loading.invoice = false;
    });

    // GET ACTIVE SUBSCRIPTION
    builder.addCase(getActiveSubscription.pending, (state) => {
      state.loading.subscription = true;
      state.req_success.subscription = false;
      state.req_success.upgrade_account = false;
    });
    builder.addCase(
      getActiveSubscription.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.subscription = false;
        state.req_success.subscription = true;
        state.active_subscription = payload.data;
      }
    );
    builder.addCase(getActiveSubscription.rejected, (state) => {
      state.loading.subscription = false;
    });

    // UPGRADE ACCOUNT
    builder.addCase(upgradeAccount.pending, (state) => {
      state.clear_stripe = false;

      state.loading.upgrade_account = true;
      state.req_success.upgrade_account = false;
    });
    builder.addCase(
      upgradeAccount.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.upgrade_account = false;
        state.req_success.upgrade_account = true;
        state.edit_payment = false;
        state.req_success.apply_coupon = false;
      }
    );
    builder.addCase(upgradeAccount.rejected, (state) => {
      state.loading.upgrade_account = false;
    });

    // CANCEL SUBSCRIPTION
    builder.addCase(cancelSubscription.pending, (state) => {
      state.loading.cancel_subscription = true;
      state.req_success.cancel_subscription = false;
    });
    builder.addCase(
      cancelSubscription.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.cancel_subscription = false;
        state.req_success.cancel_subscription = true;
        state.req_success.upgrade_account = false;
        state.req_success.card_info = false;
        state.single_payment_info = {};
        state.single_card_info = {};
        state.edit_payment = false;
      }
    );
    builder.addCase(cancelSubscription.rejected, (state) => {
      state.loading.cancel_subscription = false;
    });

    // GET CARD INFROMATION
    builder.addCase(getCardInfo.pending, (state) => {
      state.loading.card_info = true;
      state.req_success.card_info = false;
    });
    builder.addCase(
      getCardInfo.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.card_info = false;
        state.req_success.card_info = true;
        state.single_card_info = payload.data;
      }
    );
    builder.addCase(getCardInfo.rejected, (state) => {
      state.loading.card_info = false;
    });

    // APPLY COUPON CODE TO GET DISCOUNT
    builder.addCase(applyCouponCode.pending, (state) => {
      state.loading.apply_coupon = true;
      state.req_success.apply_coupon = false;
    });
    builder.addCase(
      applyCouponCode.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.apply_coupon = false;
        state.req_success.apply_coupon = true;

        state.price_after_coupon = payload;
      }
    );
    builder.addCase(applyCouponCode.rejected, (state) => {
      state.loading.apply_coupon = false;
    });

    // GET USER PRICING
    builder.addCase(checkUserPricing.pending, (state) => {
      state.loading.pricing = true;
      state.req_success.pricing = false;
    });
    builder.addCase(
      checkUserPricing.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.pricing = false;
        state.req_success.pricing = true;
        state.pricing = payload;
      }
    );
    builder.addCase(checkUserPricing.rejected, (state) => {
      state.loading.pricing = false;
    });
  },
});

export const {
  searchInvoices,
  invoicePaginate,
  clearStripe,
  editUserPayment,
  closeEditUserPayment,
  clearCoupon,
  clearStripeFalse,
  clearCouponAndDiscount,
  paytoReAccess,
} = TACPaymentSlice.actions;

export default TACPaymentSlice.reducer;
