import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

import axiosInstance from "../../../apiConfigs/axiosInstance";
import { AUTH_URL, THINKFIC_BASE_URL } from "../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../helpers/utils/TosterError";
import {
  renderDynamicUrlFromState,
  renderMainUrlFromState,
} from "../../../helpers/utils/urlHelper";

export const loadUser = createAsyncThunk(
  "loadUser",
  async (
    { setAuthFail }: { setAuthFail: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/current-user`);

      return res.data;
    } catch (error: any) {
      setAuthFail(true);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSignIn = createAsyncThunk(
  "userSignIn",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axios.post(`${dynamic_url}/login`, data);

      // if (res.data.user.role === "user") {
      //   window.location.href = `${THINKFIC_BASE_URL}/login/${res.data.email}`;
      // }
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/update-password`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUsername = createAsyncThunk(
  "updateUsername",
  async (
    { userId, data }: { userId: number; data: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/update-user/${userId}`,
        data
      );

      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAvatar = createAsyncThunk(
  "updateAvatar",
  async ({ data }: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/update-avatar`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeAvatar = createAsyncThunk(
  "removeAvatar",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/remove-avatar`);
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "logoutUser",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/logout`);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (
    {
      data,
      isTenant,
      tenant,
    }: { data: any; isTenant: boolean; tenant: string },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.post(
        `${dynamic_url}/password/email`,
        data
      );

      toast.success(res.data.message);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateToken = createAsyncThunk(
  "validateToken",
  async (
    { data, fun }: { data: any; fun: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axios.post(`${dynamic_url}/token/verify`, data);
      return res.data;
    } catch (error: any) {
      fun(true);

      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axios.post(`${dynamic_url}/password/reset`, data);
      toast.success(res.data.message);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const checkTenantReady = createAsyncThunk(
  "checkTenantReady",
  async (
    { setCheckTenantCreating }: { setCheckTenantCreating: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axios.get(`${dynamic_url}/tenant-ready`);
      if (res.data.ready) {
        setCheckTenantCreating(true);
      }
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const checkForDomain = createAsyncThunk(
  "checkForDomain",
  async (
    {
      subdomain,
      setValidated,
      setChecking,
    }: { subdomain: string; setValidated: any; setChecking: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const main_url = renderMainUrlFromState(state);

      const res = await axiosInstance.post(`${main_url}/api/checkDomain`, {
        subdomain,
      });

      if (res.data.exist) {
        setValidated(true);
      }
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    } finally {
      setChecking(false);
    }
  }
);

export const validateMemberSignup = createAsyncThunk(
  "verify-member",
  async (
    { data, fun }: { data: any; fun: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axios.post(`${dynamic_url}/verify-member`, data);
      return res.data;
    } catch (error: any) {
      fun(true);

      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const memberSignup = createAsyncThunk(
  "member-signup",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axios.post(`${dynamic_url}/member/signup`, data);
      toast.success(res.data.message);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const defaultPasswordChange = createAsyncThunk(
  "defaultPasswordChange",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/update-default-password`,
        data
      );
      toast.success(res.data.message);

      return res.data;
    } catch (error: any) {
      error.response.data.errors.password?.forEach((err: string) => {
        toast.error(err);
      });
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
