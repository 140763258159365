// import AcademicUserDashboard from "../../pages/NewAcademicUser/Dashboard/AcademicUserDashboard";
import { lazy } from "react";
import AcademicUserArchive from "../../pages/NewAcademicUser/Archive/AcademicUserArchive";
import UserReference from "../../pages/NewAcademicUser/UserReference/UserReference";
import CourseProgress from "../../pages/NewAcademicUser/CourseProgress/CourseProgress";
import LearningResources from "../../pages/NewAcademicUser/LearningResources/LearningResources";
import ApplicationTracker from "../../pages/NewAcademicUser/ApplicationTracker/ApplicationTracker";
import UserAllCourses from "../../pages/NewAcademicUser/UserAllCourses/UserAllCourses";
import InterviewInstruction from "../../pages/NewAcademicUser/InterviewPreparation/InterviewInstruction/InterviewInstruction";
import InterviewPreparation from "../../pages/NewAcademicUser/InterviewPreparation/InterviewPrepration/InterviewPreparation";
import InterviewDone from "../../pages/NewAcademicUser/InterviewPreparation/InterviewDone/InterviewDone";
import AiAssist from "../../pages/NewAcademicUser/AiAssist/AiAssist";
import AiConservation from "../../pages/NewAcademicUser/AiAssist/AiConservation";
import { ROUTES } from "../../helpers/utils/Constants/route";
import ProductManagerUserDashboard from "../../pages/NewAcademicUser/Dashboard/ProductManagerUserDashboard/ProductManagerUserDashboard";
import MeetWithTeamComp from "../../components/NewAcademicUser/MeetWithTeam/MeetWithTeamComp";
import MeetWithTeam from "../../pages/NewAcademicUser/MeetWithTeam/MeetWithTeam";

const AcademicUserDashboard = lazy(
  () => import("../../pages/NewAcademicUser/Dashboard/AcademicUserDashboard")
);

const RoutesOnlyForTAA: any = [
  {
    id: 1,
    path: ROUTES.ACADEMIC_USER_DASHBOARD,
    component: AcademicUserDashboard,
  },
  {
    id: 2,
    path: ROUTES.INTERVIEW_PREPRATION_INSTRUCTION,
    component: InterviewInstruction,
  },
  { id: 3, path: ROUTES.INTERVIEW_PREPRATION, component: InterviewPreparation },
  { id: 4, path: ROUTES.INTERVIEW_PREPRATION_DONE, component: InterviewDone },
  { id: 5, path: ROUTES.AI_ASSIST, component: AiAssist },
  { id: 6, path: ROUTES.AI_CONVERSATION, component: AiConservation },
  {
    id: 7,
    path: ROUTES.AI_CONVERSATION_WITH_HASHID,
    component: AiConservation,
  },
  {
    id: 8,
    path: ROUTES.USER_LEARNING_RESOURCES,
    component: LearningResources,
  },
  {
    id: 9,
    path: ROUTES.USER_APPLICATION_TRACKING,
    component: ApplicationTracker,
  },
  { id: 10, path: ROUTES.USER_ALL_COURSES, component: UserAllCourses },
  { id: 11, path: ROUTES.ARCHIVES, component: AcademicUserArchive },
  { id: 12, path: ROUTES.COURSE_PROGRESS, component: CourseProgress },
  { id: 13, path: ROUTES.USER_REFERENCE, component: UserReference },
  {
    id: 14,
    path: ROUTES.PRODUCT_MANAGER_DASHBOARD,
    component: ProductManagerUserDashboard,
  },
  {
    id: 15,
    path: ROUTES.MEET_WITH_TEAM,
    component: MeetWithTeam,
  },
];

export default RoutesOnlyForTAA;
