import "./allcoursesbody.scss";

import React, { useEffect, useState } from "react";
import { getAllUserCourses } from "../../../../store/features/UserDashboard/NewUserCoursesAndLearningPath/NewUserCoursesAndLearningPathApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import FilterAllCourses from "../FilterAllCourses/FilterAllCourses";
import NoResultFound from "../NoResultFound/NoResultFound";
import UserSideCourseCard from "../UserSideCourseCard/UserSideCourseCard";

function AllCoursesBody(props: any) {
  const [currSlug, setCurrSlug] = useState("");
  const dispatch = useAppDispatch();
  const courseandpathState: any = useAppSelector((store: RootStore) => {
    return store.NewUserCoursesAndLearningPathSlice;
  });
  useEffect(() => {
    dispatch(getAllUserCourses("user"));
  }, []);


  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  return (
    <div className="allcoursesbody__wrapper">
    <div className="allcoursesbody">
      <div className="filter-left">
        <FilterAllCourses />
      </div>
      <div className="courses-right">
        <h1>All courses</h1>
        <div className="courses-right-list">
          {courseandpathState.courses.map((e: any, i: number) => {
            return (
              <UserSideCourseCard
                key={e.id}
                id={e?.id}
                courseId={e.course_id}
                title={e.name}
                desc={e.description}
                duration={e.duration}
                slug={e.slug}
                percentage={e.percentage_completed}
                currSlug={currSlug}
                setCurrSlug={setCurrSlug}
              />
            );
          })}
          {courseandpathState.filter_active &&
            courseandpathState.courses.length === 0 && <NoResultFound />}
        </div>
      </div>
    </div>
    </div>
  );
}

export default AllCoursesBody;
