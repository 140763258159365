import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddLearningPath,
  listLearningPath,
  deleteLearningPath,
} from "./LearningPathApi";

const initialState: any = {
  loading: false,
  deleteLoading: false,
  adder_loading: false,
  req_success: false,
  delete_success: false,
  added_req_success: false,
  learningPaths: [],
  all_learningPaths: [],
};
// listLearningPath;
const AddLearningPathSlice = createSlice({
  name: "AddLearningPathSlice",
  initialState,
  reducers: {
    searchPath: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.learningPaths = state.all_learningPaths.filter(
          (path: any) =>
            path.name.toLowerCase().includes(payload.toLowerCase()) ||
            path.path_id.toString().includes(payload),
        );
      } else {
        state.learningPaths = state.all_learningPaths;
      }
    },
  },
  extraReducers: (builder) => {
    // ADD LEARNING PATH
    builder.addCase(AddLearningPath.pending, (state) => {
      state.adder_loading = true;
      state.added_req_success = false;
    });
    builder.addCase(
      AddLearningPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.adder_loading = false;
        state.added_req_success = true;
      },
    );
    builder.addCase(AddLearningPath.rejected, (state) => {
      state.adder_loading = false;
    });

    // GET ALL COURSES
    builder.addCase(listLearningPath.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      listLearningPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.learningPaths = payload?.data;
        state.all_learningPaths = payload?.data;
      },
    );
    builder.addCase(listLearningPath.rejected, (state) => {
      state.loading = false;
    });

    // DELETE LEARNING PATH
    builder.addCase(deleteLearningPath.pending, (state) => {
      state.deleteLoading = true;
      state.delete_success = false;
    });
    builder.addCase(
      deleteLearningPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.deleteLoading = false;
        state.delete_success = true;
      },
    );
    builder.addCase(deleteLearningPath.rejected, (state) => {
      state.deleteLoading = false;
    });
  },
});
export const { searchPath } = AddLearningPathSlice.actions;
export default AddLearningPathSlice.reducer;
