import { useState } from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import ReferenceHead from "../../../components/AdminPanel/Reference/ReferenceHead/ReferenceHead";
import ReferenceBody from "../../../components/AdminPanel/Reference/ReferenceBody/ReferenceBody";
import QuickLinksHead from "../../../components/AdminPanel/QuickLinks/QuickLinksHead/QuickLinksHead";
import QuickLinksBody from "../../../components/AdminPanel/QuickLinks/QuickLinksBody/QuickLinksBody";

function QuickLinks(props: any) {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  // const [currID, setCurrID] = useState(-1);
  return (
    <AdminPanelLayout>
      <BoxShadow>
      <div className="mt-7">
      <QuickLinksHead
          openModal={openModal}
          setOpenModal={setOpenModal}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setDeleteShowModal={setDeleteShowModal}
          showDeleteModal={showDeleteModal}
        />

        <QuickLinksBody
          openModal={openModal}
          setOpenModal={setOpenModal}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setDeleteShowModal={setDeleteShowModal}
          showDeleteModal={showDeleteModal}
        />
      </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
}

export default QuickLinks;
