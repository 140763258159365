export const convertStringToDate = (dateString: string) => {
  const dateParts = dateString.split(", ");

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const time: any = new Date(dateParts[0]);
  const localISOTime = new Date(time - tzoffset).toISOString().split("T")[0];

  return localISOTime;
};

export const toHoursAndMinutes = (totalSeconds: number) => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const time = `${hours > 0 ? hours : ""}${hours > 0 ? "h" : ""} ${
    minutes > 0 ? minutes : ""
  }${minutes > 0 ? "m" : ""}`;
  return time;
};

export const getCurrentMinuteAndSecondFromSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const remainingSeconds = seconds - hours * 3600 - minutes * 60;
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};
