import ResetPasswordFrom from "../../components/Auth/AuthForm/ResetPasswordFrom/ResetPasswordFrom";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";

const ResetPassword = (props: any) => {
  return (
    <LandingLayout>
      <AuthLayout>
        <ResetPasswordFrom />
      </AuthLayout>
    </LandingLayout>
  );
};

export default ResetPassword;
