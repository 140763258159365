import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import InviteMember from "../../../components/Orgination/Members/InviteMember/InviteMember";

const InviteNewMembers = () => {
  return (
    <AdminPanelLayout>
      <InviteMember />
    </AdminPanelLayout>
  );
};

export default InviteNewMembers;
