import React, { useEffect, useRef, useState } from "react";
import { Delete, Edit, Edit2, Trash2, X } from "react-feather";
import "./mobileviewchatbot.scss";
import MobileViewChatMessageTextArea from "../MobileViewChatTextArea/MobileViewChatMessageTextArea";
import MobileChatbotAllTabs from "../MobileChatbotAllTabs/MobileChatbotAllTabs";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../store/store";
import { clearConversation } from "../../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import { openNewChat } from "../../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";

const MobileViewChatbot = (props: any) => {
  const { setMobileShowChatBox, mobileChatBoxref } = props;
  const chatbotSection: any = useRef(null);
  const dispatch = useAppDispatch();

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  const handleClickOutSide = (event: any) => {
    if (
      mobileChatBoxref.current &&
      !mobileChatBoxref.current.contains(event.target)
    ) {
      if (
        chatbotSection.current &&
        !chatbotSection.current.contains(event.target)
      ) {
        setMobileShowChatBox(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    // return ()=>{
    //   document.addEventListener("mousedown",handleClickOutSide);
    // }
  }, []);
  return (
    <div className="mobileview__chatbot__wrapper__div">
      <div className="mobileview__chatbot__container__div" ref={chatbotSection}>
        <div
          className="cross__icon"
          aria-hidden
          onClick={() => setMobileShowChatBox(false)}
        >
          <div className="cross__icon__handle">
            <X />
          </div>
        </div>
        {/* <div className="chatbot__controls">
          <div className="modify__controls">
            <Edit className="edit__icon" onClick={() => newChat()} />
            <Trash2
              className="delete__icon"
              onClick={() => clearAllConversation()}
            />
          </div>
        </div> */}
        <MobileChatbotAllTabs />
      </div>
    </div>
  );
};

export default MobileViewChatbot;
