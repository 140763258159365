import "./index.scss";
import ProgressBar from "@ramonak/react-progress-bar";
import {  AlertCircle, X } from "react-feather";
import csvIcon from "../../../../../../assets/resource_icon/csv.svg";

const UploadBulkArchiveFileuploadProgressbar = (props: any) => {
  const {
    progress,
    fileUploadError,
    file,
    progressRef,
    SetFileUpladError,
  } = props;

  const hideProgress = () => {
    SetFileUpladError({});
    progressRef.current.style.display = "none";
  };
  return (
    <div ref={progressRef}>
      <div className="file__upload__progress__section__wrapper ">
        <div className="icon__section">
          <img src={csvIcon} alt="" />
        </div>
        <div className="info__section__wrapper">
          <div className="row__section">
            <span className="file__name">
              {file.name}
              <span className="file__size">
                {" "}
                {(file.size / 1024).toFixed(2)} Kb
              </span>
            </span>

            <div className="file__controls">
              {progress === 100 && (
                <X className="cancel__icon" onClick={hideProgress} />
              )}
            </div>
          </div>
          <div className="test">
            <ProgressBar
              completed={progress}
              height="3px"
              customLabel="."
              baseBgColor={
                fileUploadError?.message
                  ? "rgba(240, 68, 56, 0.2)"
                  : "var( --primary-progress-bar-bg)"
              }
              bgColor={
                fileUploadError?.message
                  ? "#F04438"
                  : "var( --secondary-progress-bar-bg)"
              }
              labelSize="0"
            />
          </div>

          <span className="percentage__uploaded"> {progress}% done</span>
        </div>
      </div>

      {fileUploadError?.message && (
        <div className="file__upload__error__wrapper">
          <div className="file__upload__error__container all__unset">
                <div
                  className={`file__upload__error ${
                    fileUploadError?.message && "error__color"
                  }`}
                >
                  <div className="error__icon ">
                    <AlertCircle />
                  </div>
                  <span>
                    {fileUploadError?.message}
                  </span>
                </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadBulkArchiveFileuploadProgressbar
