import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import AddAssisment from "../../../components/Orgination/Members/AddAssisment/AddAssisment";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const MemberAssignment = () => {
  return (
    <AdminPanelLayout>
      {/* <BoxShadow> */}
      <AddAssisment />
      {/* </BoxShadow> */}
    </AdminPanelLayout>
  );
};

export default MemberAssignment;
