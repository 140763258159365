import "./index.scss";

const IntoText = () => {
  return (
    <div className="into__text__section">
      <span className="ai__title"> AI Assist</span>
      <p>
        {" "}
        Simply input your desired intent and any relevant information, and AI
        Assist does the rest.
      </p>
    </div>
  );
};

export default IntoText;
