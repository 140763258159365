import "./commingsoon.scss";

import React, { useEffect } from "react";

import commingSoon from "../../../assets/images/commingSoon.svg";

function CommingSoon(props: any) {
  const { page } = props;
  return (
    <div className="commingsoon">
      <img src={commingSoon} alt="comming soon" />
      <h1>Coming Soon!</h1>
      <p>The {page} feature will be available soon.</p>
    </div>
  );
}

export default CommingSoon;
