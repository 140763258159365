import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  downloadSubscriberCsvFile,
  downloadSubscriberExcelFile,
  getSubscribers,
  getTacSubscribers,
  downloadTaaSubCSVFile,
  downloadTaaSubXLSXFile,
  getContactMessages,
} from "./SubscriberApi";

const initialState: any = {
  loading: false,
  req_success: false,
  current_page: 0,
  all_subscribers: [],
  temp_subscribers: [],
  subscribers: [],
  contact_messages_data:[],
  all_contact_data:[],
  temp_contact_messsages_data:[],
  downloadLoading: false,
  downloadSuccess: false,
};
// ADD about;
const SubscriberSlice = createSlice({
  name: "SubscriberSlice",
  initialState,
  reducers: {
    subscriberTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.subscribers = state.all_subscribers.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.subscribers = state.all_subscribers.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.subscribers = state.all_subscribers.slice(
          payload * 10,
          10 * payload + 10
        );
      }
    },
    searchSubscriberInTable: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_subscribers = state.all_subscribers.filter(
          (subscriber: any) =>
            subscriber.email.toLowerCase().includes(payload.toLowerCase()) ||
            subscriber?.full_name
              ?.toLowerCase()
              ?.includes(payload?.toLowerCase())
        );
        state.subscribers = state.temp_subscribers.slice(0, 10);
      } else {
        state.subscribers = state.all_subscribers.slice(
          state.current_page * 10,
          10 * state.current_page + 10
        );
      }
    },


    contactMessagesTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.contact_messages_data = state.all_contact_data.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.contact_messages_data = state.all_contact_data.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.contact_messages_data = state.all_contact_data.slice(
          payload * 10,
          10 * payload + 10
        );
      }
    },

    searchContactMessages: (state: any, { payload }: PayloadAction<any>) => {
      console.log(payload);
      if (payload.length > 1) {
        state.temp_contact_messsages_data = state.all_contact_data.filter(
          (item: any) =>
            item?.email?.toLowerCase()?.includes(payload.toLowerCase()) ||
          item?.name
              ?.toLowerCase()
              ?.includes(payload?.toLowerCase())
        );
        state.contact_messages_data = state.temp_contact_messsages_data.slice(0, 10);
        console.log( state.temp_contact_messsages_data,state.contact_messages_data)
      } else {
        state.contact_messages_data = state.all_contact_data.slice(
          state.current_page * 10,
          10 * state.current_page + 10
        );
      }
    },


  },
  extraReducers: (builder) => {
    // GET SUBSCRIBERS
    builder.addCase(getSubscribers.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getSubscribers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_subscribers = payload.data;
        state.subscribers = state.all_subscribers.slice(0, 10);
      }
    );
    builder.addCase(getSubscribers.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTacSubscribers.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getTacSubscribers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_subscribers = payload.data;
        state.subscribers = state.all_subscribers.slice(0, 10);
      }
    );
    builder.addCase(getTacSubscribers.rejected, (state) => {
      state.loading = false;
    });
    // sub csv file for tac
    builder.addCase(downloadSubscriberCsvFile.pending, (state) => {
      state.downloadLoading = true;
      state.downloadSuccess = false;
    });
    builder.addCase(downloadSubscriberCsvFile.fulfilled, (state) => {
      state.downloadLoading = false;
      state.downloadSuccess = true;
    });
    builder.addCase(downloadSubscriberCsvFile.rejected, (state) => {
      state.downloadLoading = false;
    });
    // sub xlxs file for tac
    builder.addCase(downloadSubscriberExcelFile.pending, (state) => {
      state.downloadLoading = true;
      state.downloadSuccess = false;
    });
    builder.addCase(downloadSubscriberExcelFile.fulfilled, (state) => {
      state.downloadLoading = false;
      state.downloadSuccess = true;
    });
    builder.addCase(downloadSubscriberExcelFile.rejected, (state) => {
      state.downloadLoading = false;
    });

    // sub csv file for TAA
    builder.addCase(downloadTaaSubCSVFile.pending, (state) => {
      state.downloadLoading = true;
      state.downloadSuccess = false;
    });
    builder.addCase(downloadTaaSubCSVFile.fulfilled, (state) => {
      state.downloadLoading = false;
      state.downloadSuccess = true;
    });
    builder.addCase(downloadTaaSubCSVFile.rejected, (state) => {
      state.downloadLoading = false;
    });

    // sub xlxs file for TAA
    builder.addCase(downloadTaaSubXLSXFile.pending, (state) => {
      state.downloadLoading = true;
      state.downloadSuccess = false;
    });
    builder.addCase(downloadTaaSubXLSXFile.fulfilled, (state) => {
      state.downloadLoading = false;
      state.downloadSuccess = true;
    });
    builder.addCase(downloadTaaSubXLSXFile.rejected, (state) => {
      state.downloadLoading = false;
    });

        // GET CONTACT MESSAGES
        builder.addCase(getContactMessages.pending, (state) => {
          state.loading = true;
          state.req_success = false;
        });
        builder.addCase(
          getContactMessages.fulfilled,
          (state, { payload }: PayloadAction<any>) => {
            state.loading = false;
            state.req_success = true;
            state.all_contact_data = payload.data;
            state.contact_messages_data = state.all_contact_data.slice(0, 10);
          }
        );
        builder.addCase(getContactMessages.rejected, (state) => {
          state.loading = false;
        });
  },
});
export const { subscriberTablePaginate, searchSubscriberInTable ,searchContactMessages,contactMessagesTablePaginate} =
  SubscriberSlice.actions;
export default SubscriberSlice.reducer;
