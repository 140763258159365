import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Profile from "../Profile/Profile";
import UserSubscription from "../Subscriptions/UserSubscription";
import "./index.scss";
import InvoiceHistory from "../InvoiceHistory/InvoiceHistory";
import { ROUTES } from "../../../../helpers/utils/Constants/route";
import PaginateButton from "../../../../shared/PaginateButton/PaginateButton";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { invoicePaginate } from "../../../../store/features/UserDashboard/Payment/PaymentSlice";

const AllProfileFeatureTab = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useAppDispatch();

  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });
  useEffect(() => {
    if (params.tag === "profile") {
      setTabIndex(0);
    }
    if (params.tag === "subscriptions") {
      setTabIndex(1);
    }
    if (params.tag === "invoice") {
      setTabIndex(2);
    }
  }, []);

  useEffect(() => {
    if (params.tag === "subscriptions") {
      setTabIndex(1);
    }
  }, [params.tag]);

  useEffect(() => {
    if (tabIndex === 0) {
      navigate(`${ROUTES.MY_PROFILE}/profile`);
    }
    if (tabIndex === 1) {
      navigate(`${ROUTES.MY_PROFILE}/subscriptions`);
    }
    if (tabIndex === 2) {
      navigate(`${ROUTES.MY_PROFILE}/invoice`);
    }
  }, [tabIndex]);

  const handlePagination = (num: number) => {
    dispatch(invoicePaginate(num));
  };
  return (
    <div className="all__profile__tab__container mt-7">
      <Tabs
        className="tab__container"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="tab__list__section">
          <Tab className="single__tab ">Profile</Tab>
          <Tab className="single__tab ml-4 ">Subscriptions</Tab>
          <Tab className="single__tab ml-4 ">Invoice History</Tab>
        </TabList>
        <TabPanel>
          <div className="tab__section_no_box">
            <Profile />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab__section">
            <UserSubscription />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="tab__section">
            <InvoiceHistory />
          </div>{" "}
          <div className="invoice__pagination__section">
            <PaginateButton
              paginate={handlePagination}
              totalRows={paymentState?.all_invoices?.length}
            />
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AllProfileFeatureTab;
