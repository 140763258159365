/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import "./offerBanner.scss";
import { useNavigate } from "react-router-dom";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getBannersData } from "../../../../store/features/AdminPanel/Banners/BannersApi";

const OfferBanner = (props: any) => {
  const { inMobileView } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const bannersState: any = useAppSelector(
    (store: RootStore) => store.BannersSlice
  );
  const onlyTacStudentDasboardbannerData = bannersState.banners?.filter(
    (item: any) => item.name === "tac_student_dashboard_banner"
  );
  const parsedContent =
    onlyTacStudentDasboardbannerData.length > 0 &&
    JSON.parse(JSON.parse(onlyTacStudentDasboardbannerData[0]?.content));
  useEffect(() => {
    dispatch(getBannersData());
  }, []);

  const handleNavigation = (link: string, openInNewTab: boolean) => {
    try {
      const url = new URL(link, window.location.origin);

      const extractBaseDomain = (hostname: string) => {
        const parts = hostname.split(".");
        return parts.length > 2 ? parts.slice(-2).join(".") : hostname;
      };

      const linkBaseDomain = extractBaseDomain(url.hostname);
      const currentBaseDomain = extractBaseDomain(window.location.hostname);

      const isSameSite = linkBaseDomain === currentBaseDomain;

      if (!openInNewTab) {
        if (isSameSite) {
          const { pathname } = url;
          const navigationPath =
            pathname === "/" ? `/${url.hostname}` : pathname;
          navigate(navigationPath);
        } else {
          window.location.href = link;
        }
      } 
      else {
        window.open(link, "_blank");
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
  };
  // console.log(parsedContent);

  return onlyTacStudentDasboardbannerData[0]?.status ? (
    <div className={`button__text ${inMobileView && "mobileBtnText"}`}>
      <p>{parsedContent?.text}</p>
      <div
        className="upgradeBtn"
        onClick={() =>
          handleNavigation(
            `${parsedContent?.btn_link}`,
            parsedContent?.open_new_tab
          )
        }
      >
        {parsedContent?.btn_text}
      </div>
    </div>
  ) : null;
};

export default OfferBanner;
