import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BlogSectionAllTab from "../../../components/AdminPanel/Blog/BlogTab/BlogSectionAllTab";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const Blog = () => {
  return (
    <AdminPanelLayout>
      <BlogSectionAllTab />
    </AdminPanelLayout>
  );
};

export default Blog;
