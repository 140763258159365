import React, { useEffect, useState } from "react";
import moment from "moment";
import "./archieveFilter.scss";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import {
  filterByTopic,
  filterByBatch,
  ResetFilter,
} from "../../../../store/features/UserDashboard/Archives/ArchiveSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import SelectDropDown from "../../../../shared/SelectDropDown/SelectDropDown";

const ArchieveFilter = (props: any) => {
  const { searchByMonthInTopic, setSearchByMonthInTopic } = props;
  
  // Get the current year
  const currentYear = new Date().getFullYear();
  const startYear = 2020; // Set the starting year for the dropdown

  // Generate the date data array in descending order
  const dateData = Array.from(
    { length: currentYear - startYear + 1 },
    (v, i) => ({
      id: i + 1,
      name: `${currentYear - i}`,
      value: `${currentYear - i}`,
    })
  );

  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState<any>(dateData[0]);

  const handleReset = () => {
    setSearchText("");
    setSearchByMonthInTopic(null);
    setSelectedValue(dateData[0]);
    dispatch(ResetFilter({}));
  };

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(filterByTopic(searchText));
  }, [searchText]);

  const handleSelectMonth = (month: string) => {
    if (selectedValue) {
      setSearchByMonthInTopic(month);

      const dateAndMonth = {
        date: selectedValue?.value,
        month: month,
        others: month,
      };
      dispatch(filterByBatch(dateAndMonth));
    }
  };

  const handleOption = (data: any) => {
    setSelectedValue(data);
  };

  useEffect(() => {
    setSearchText("");
    setSearchByMonthInTopic(null);
    dispatch(ResetFilter({}));
  }, [selectedValue]);

  const currentMonth = new Date().getMonth(); // 0 for January, 1 for February, etc.

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getMonthsToDisplay = () => {
    if (!selectedValue) return [];

    const selectedYear = parseInt(selectedValue.name, 10);
    if (selectedYear === currentYear) {
      return months.slice(0, currentMonth + 1);
    }
    return months;
  };

  return (
    <div className="filter__wrapper">
      <div className="filter__container">
        <div className="searchbar__width__handle">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="filterby">
          <p className="filterbyText">Filter by Cohort</p>
          <div className="date__filter__dropdown">
            <SelectDropDown
              data={dateData}
              selectedValue={selectedValue?.name}
              handleOption={handleOption}
              isDwopdownBg
            />
          </div>
        </div>
        {searchByMonthInTopic && (
          <button
            type="button"
            className="reset__btn"
            onClick={() => handleReset()}
          >
            Reset
          </button>
        )}

        <div className="filterby__date__month">
          {getMonthsToDisplay()?.map((m: string) => (
            <div className="month__wrapper" key={m}>
              <CheckBox
                label={m}
                labelClassname
                onClick={() => handleSelectMonth(m)}
                border
                CheckboxValue={searchByMonthInTopic === m}
              />
            </div>
          ))}
        </div>
        <div className="other__filter">
          <p className="other__text">Others</p>
          <div className="month__wrapper">
            <CheckBox
              label="Continuous Learning"
              labelClassname
              border
              onClick={() => handleSelectMonth("others")}
              CheckboxValue={searchByMonthInTopic === "others"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchieveFilter;
