import React from "react";
import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import LearningResourcesComp from "../../../components/NewAcademicUser/LearningResources/LearningResource/LearningResourcesComp";

function LearningResources(props: any) {
  return (
  <>
    <Nav normal navBgColorHandle />
    <LearningResourcesComp/>
  </>
  );
}

export default LearningResources
