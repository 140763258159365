import React, { useEffect, useState } from "react";
import RecentAnswerQuestion from "./RecentAnswerQuestion/RecentAnswerQuestion";
import DeleteModal from "../../../../../../shared/DeleteModal/DeleteModal";
import { RootStore, useAppSelector } from "../../../../../../store/store";

const RecentQuestionAnswerFaqContent = () => {
  const { recentlyAnsweredDetails } = useAppSelector((store: RootStore) => {
    return store.InterviewDetailsSlice;
  });

  return (
    <div>
      {recentlyAnsweredDetails?.question_answers?.map((item: any) => (
        <RecentAnswerQuestion key={item?.id} data={item} />
      ))}
    </div>
  );
};

export default RecentQuestionAnswerFaqContent;
