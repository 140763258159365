/* eslint-disable func-names */
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as tus from "tus-js-client";
import { sha256 } from "js-sha256";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";
import { BUNNY_API_KEY, BUNNY_VIDEO_LIBRARY_ID } from "../../../../apiConfigs/urlConfig";
import { ERROR__TOASTER, REQUIRED__TOASTER } from "../../../../helpers/utils/TosterError";

export const getVideoFromBunnyNet = createAsyncThunk(
  "getVideoFromBunnyNet",
  async (
    undefined,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/page/tour-video`
      );
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createVideoIdInBunnyNet = createAsyncThunk(
    "createVideoIdInBunnyNet",
    async ({ data }: { data: any }, { dispatch, rejectWithValue, getState }) => {
      try {
        const res = await axios.post(
          `https://video.bunnycdn.com/library/${BUNNY_VIDEO_LIBRARY_ID}/videos`,
          data,
          {
            headers: {
              AccessKey: BUNNY_API_KEY,
            },
          }
        );
        return res.data;
      } catch (error: any) {
        toast.error(error.response.data.message);
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
);

export const uploadVideoInBackend = createAsyncThunk(
    "uploadVideoInBunnyNet",
    async (content:any, { dispatch, rejectWithValue, getState }) => {
      try {
        const state = getState();
        const dynamic_url = renderDynamicUrlFromState(state);
        const res = await axiosInstance.post(
          `${dynamic_url}/page/tour-video`,{content}
        );
        REQUIRED__TOASTER(res.data);
        return res.data;
      } catch (error: any) {
        ERROR__TOASTER(error);
        return rejectWithValue(error.response.data);
      }
    }
  );

  export const uploadVideoToBunnyNet = createAsyncThunk(
    "uploadVideoToBunnyNet",
    async ({ data, videoId, setTsuInstance, setVideoStatus, videoStatus, setVideoUploadedProgress }: {
      data: any;
      videoId: any;
      setTsuInstance: any;
      videoStatus: any;
      setVideoStatus: any;
      setVideoUploadedProgress: any;
    }, rejectWithValue) => {

      const expiration_time = Math.floor(Date.now() / 1000) + 24 * 60 * 60;
      try {
        setVideoStatus({ ...videoStatus, loading: true });
        // Create a new tus upload
        const res = new tus.Upload(data, {
          uploadUrl: "https://video.bunnycdn.com/tusupload",
          endpoint: "https://video.bunnycdn.com/tusupload",
          retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
          headers: {
            AuthorizationSignature: sha256(
              BUNNY_VIDEO_LIBRARY_ID + BUNNY_API_KEY + expiration_time + videoId
            ), // SHA256 signature (library_id + api_key + expiration_time + video_id)
            AuthorizationExpire: expiration_time.toString(), // Expiration time as in the signature,
            VideoId: videoId, // The guid of a previously created video object through the Create Video API call
            LibraryId: BUNNY_VIDEO_LIBRARY_ID,
          },
          metadata: {
            filetype: data.type,
            title: data?.name,
          },
          onError: function (error: any) {
            console.log(error, "error");
          },
          onProgress: function (bytesUploaded: any, bytesTotal: any) {
            const percent = Math.floor((bytesUploaded / bytesTotal) * 100);
            setVideoUploadedProgress(percent);
          },
          onSuccess: function () {
            setVideoStatus({ ...videoStatus, success: true, loading: false });
          },
        });
        setTsuInstance(res);
        // Check if there are any previous uploads to continue.
        res.findPreviousUploads().then(function (previousUploads: any) {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            res.resumeFromPreviousUpload(previousUploads[0]);
          }
  
          // Start the upload
          res.start();
        });
      } catch (err) {
        setVideoStatus({ loading: false, success: false });
  
      }
    },
  );
  

export const deleteVideoTourVideo = createAsyncThunk("deleteVideoTourVideo",async(_,{rejectWithValue,getState})=>{
  try {
    const state = getState();
    const dynamic_url = renderDynamicUrlFromState(state);
    const res = await axiosInstance.delete(
      `${dynamic_url}/page/tour-video`
    );
    REQUIRED__TOASTER(res.data);
    return res.data;
  } catch (error: any) {
    ERROR__TOASTER(error);
    return rejectWithValue(error.response.data);
  }

})