import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import FaqContent from "../../../components/AdminPanel/Faq/FaqContent/FaqContent";
import FaqHeader from "../../../components/AdminPanel/Faq/FaqHeader/FaqHeader";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

import "./faq.scss";

const Faq = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="mt-7">
          <FaqHeader />
          <FaqContent />
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default Faq;
