import { useRef } from "react";
import { X } from "react-feather";
import "./modal.scss";

const Modal = (props: any) => {
  const { to, width, children, show, hideCloseButton, setShow, onClick,bluishBgModal } =
    props;
  const modalRef = useRef();
  const closeFrom = () => {
    setShow(false);
    onClick();
  };
  return (
    <div className={`modal ${show ? "active" : ""}`}>
      <div className={`modal__content ${to} ${width} ${bluishBgModal && "bluishBgModal"}`}>
        {!hideCloseButton && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
          //   <span >
          //     &times;
          //   </span>
          <X onClick={closeFrom} className="modal__close" />
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;

export const ModalHeader = (props: any) => {
  const { title, options } = props;
  return (
    <div className="modal__header">
      {title} {options && options}
    </div>
  );
};

export const ModalBody = (props: any) => {
  const { children, modalWidth } = props;

  return (
    <div style={{ width: modalWidth }} className="modal__body">
      {children}
    </div>
  );
};

export const ModalFooter = (props: any) => {
  const { children } = props;

  return <div className="modal__footer">{children}</div>;
};
