import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadConfig } from "./ThemeApi";

const initialState: any = {
  themeMode: localStorage.getItem("theme") || "dark",
  config_loading: true,
  config_success: true,
  configs: null,
  theme_name: "",
};

const ThemeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    ChangeThemeMode: (state: any, { payload }: PayloadAction<any>) => {
      state.themeMode = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadConfig.pending, (state) => {
      state.config_loading = true;
      state.config_success = false;
    });
    builder.addCase(
      loadConfig.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.configs = payload.data;
        state.theme_name = payload.theme_name;
        state.config_loading = false;
        state.config_success = true;
      }
    );
    builder.addCase(loadConfig.rejected, (state) => {
      state.config_loading = true;
    });
  },
});

export const { ChangeThemeMode } = ThemeSlice.actions;

export default ThemeSlice.reducer;
