/* eslint-disable jsx-a11y/control-has-associated-label */
import "./managecourse.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { Edit2 } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import * as yup from "yup";

import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import TextField from "../../../shared/Inputs/TextField/TextField";
import DeleteModal from "../../../shared/Modals/DeleteModal/DeleteModal";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import Table, { TableAction, TableHeading } from "../../../shared/Table/Table";
import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import {
  listCourses,
  pullCourses,
  updateCourseDuration,
} from "../../../store/features/AdminPanel/ManageCourse/ManageCourseApi";
import {
  searchCourseForAdmin,
} from "../../../store/features/AdminPanel/ManageCourse/ManageCourseSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import Empty from "../../../shared/Empty/Empty";
import SelectField from "../../../shared/Inputs/SelectField/SelectField";
import { addInstructor, loadInstructors, pullInstructors, updateInstructor } from "../../../store/features/AdminPanel/ManageInstructor/ManageInstructorApi";
import userImage from "../../../assets/icons/user.png";

const ManagingCoursesTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const [courseId, setCourseId] = useState(0);
  const [courseTitle, setCourseTitle] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const courseState: any = useAppSelector((store: RootStore) => {
    return store.ManageCourseSlice;
  });
  const instructorState: any = useAppSelector((store: RootStore) => {
    return store.ManageInstructorSlice;
  });

  const hanldePullCourse = () => {
    dispatch(pullCourses());
  };

  useEffect(() => {
    dispatch(listCourses());
    dispatch(loadInstructors());
  }, []);

  useEffect(() => {
    if (courseState.pull_req_success) {
      dispatch(listCourses());
    }
    if (instructorState.pull_req_success) {
      dispatch(listCourses());
    }
  }, [courseState.pull_req_success, instructorState.pull_req_success]);
  useEffect(() => {
    dispatch(searchCourseForAdmin(searchText));
  }, [searchText]);

  const validationSchema = yup.object().shape({
    hours: yup.string(),
    minutes: yup.string(),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  useEffect(() => {
    setShowModal(false);
    reset();
  }, [courseState.update_success]);

  // ---------------UPDATE COURSE DURATION-----------------------------
  useEffect(() => {
    if (isEdit) {
      const temp = courseState?.courses.filter((e: any) => {
        return e.id === courseId;
      });

      let dur = temp[0].duration;
      let minutes;
      let hours;
      if (dur.includes(".")) {
        dur = dur.split(".");
      } else {
        dur = dur.split(" ");
      }
      if (dur.length === 1) {
        minutes = dur[0].replace("m", "");
        minutes = Number(minutes);
        hours = 0;
      } else {
        hours = dur[0].replace("h", "");
        hours = Number(hours);
        minutes = dur[1].replace("m", "");
        minutes = Number(minutes);
      }

      const instructorIds = temp[0]?.instructors.map((e: any) => {
        return {
          value: e.id,
          label: `${e.first_name} ${e.last_name}`,
        };
      })
      
      setCourseTitle(temp[0].name);
      setValue("hours", hours);
      setValue("minutes", minutes);
      setValue("instructor", instructorIds);
      setValue("course", {
        value: temp[0]?.id,
        label: temp[0].name,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (!showModal) {
      setIsEdit(false);
      setCourseId(0);
    }
  }, [showModal]);

  useEffect(() => {
    if (courseState.update_success) {
      dispatch(listCourses());
      setShowModal(false);
    }
    if (instructorState.update_success || instructorState.req_success) {
      dispatch(listCourses());
      setShowModal(false);
    }
  }, [courseState.update_loading, instructorState.update_loading, instructorState.loading]);

  // ---------------UPDATE COURSE DURATION END-----------------------------

  const onSubmit = (values: any) => {
    if (values.hours === "") {
      values.hours = 0;
    }
    if (values.minutes === "") values.minutes = 0;
    const data = {
      ...values,
    };
    if (isEdit) {
      dispatch(updateInstructor({ course_id: courseId, instructor_ids: values.instructor.map((e: any) => e.value), hours: data.hours, minutes: data.minutes }));
    } else {
      dispatch(addInstructor({ course_id: values.course.value, instructor_ids: values.instructor.map((e: any) => e.value) }));
    }
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const handleAddInstructor = () => {
    setShowModal(!showModal);
    setValue("instructor", []);
    setValue("course", 0);
  };

  const handleChangeCourse = (course: any) => {
    if (course?.value) {
      const temp = courseState?.courses.filter((e: any) => {
        return e.id === course.value;
      });
      const instructorIds = temp[0]?.instructors.map((e: any) => {
        return {
          value: e.id,
          label: `${e.first_name} ${e.last_name}`,
        };
      })
      setValue("instructor", instructorIds);
    }
  }

  const handePullInstructor = () => {
    dispatch(pullInstructors());
  }

  const handleEditDuration = (courseId: any) => {
    setCourseId(courseId);
    setIsEdit(true);
    setShowModal(true);
  };

  return (
    <div className="manage_course_container mt-7">
      <div className="header_container manage-course-header">
        <div className="left__section">
          <TableHeadingTitle>Courses</TableHeadingTitle>
        </div>
        <div className="right__section">
          <div className="search">
            <SearchBar value={searchText} setValue={setSearchText} />
          </div>
          <div
            className="button__witdh"
            style={{ marginRight: "1rem", width: "12rem" }}
          >
            <PrimaryButton
              text="Add Instructor"
              size="sm"
              type="button"
              onClick={handleAddInstructor}
            />
          </div>
          <div  className="button__witdh"
            style={{ marginRight: "1rem", width: "12rem" }}>
             <PrimaryButton
              text="Pull Instructors"
              size="sm"
              type="button"
              loading={instructorState.pull_loading}
              onClick={handePullInstructor}
            />
          </div>
          <div className="button__witdh">
            <PrimaryButton
              text="Pull Courses"
              size="sm"
              type="button"
              loading={courseState.pull_loading}
              onClick={hanldePullCourse}
            />
          </div>
        </div>
      </div>

      <div className="table__container">
        <Table
          heading={
            <TableHeading>
              <th>Course Id</th>
              <th>Name</th>
              <th>Instructors</th>
              <th>Duration</th>
              <th className="hide__text">. </th>
            </TableHeading>
          }
        >
          <tbody className="manage_course_table_body">
            {!courseState.loading &&
              courseState.courses.map((course: any, index: number) => {
                return (
                  <tr key={course.course_id}>
                    <td className="courseid_td">{course.course_id}</td>
                    <td className="course_name_td">{course.name}</td>
                    <td className="instructor_profiles_td">
                      <div className="inst_profiles">
                        {course.instructors.slice(0, 3).map((instructor: any, index: any) => (
                          <>
                            <div className="inst-profile">
                              <img src={instructor.avatar_url?.startsWith("https",0)
                              ? instructor?.avatar_url
                              : userImage} alt={instructor.first_name}/>
                              <span className="span">{`${instructor.first_name} ${instructor.last_name}`}</span>
                            </div>
                            {index === 2 && course.instructors.length > 3 && (
                              <div className="extra-instructors">
                                +{course.instructors.length - 3}
                                <div className="remaining-instructors-tooltip">
                                  {course.instructors.slice(3).map((instructor: any) => <span>{`${instructor.first_name} ${instructor.last_name}`}</span>)}
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </td>

                    <td className="course_duration_td">
                      {course.duration ?? "null"}
                    </td>
                    <td>
                      <Edit2
                        onClick={() => {
                          handleEditDuration(course?.id);
                        }}
                        className="edit__course"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        {!courseState.loading && courseState.courses.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty emptyText="It seems empty." custombg={true} />
          </div>
        )}
      </div>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={false}
        // onClick={deleteUser}
      />
      <Modal show={showModal} setShow={setShowModal} onClick={handleAddInstructor}>
        <ModalHeader
          title={
            isEdit ? `Edit Instructor (${courseTitle})` : "Add New Instructor"
          }
        />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="sm_width ">
              <Controller
                control={control}
                name="course"
                render={({ field }) => (
                  <SelectField
                    type="text"
                    label="Select Course "
                    placeholder="Select Course"
                    handleChange={handleChangeCourse(field.value)}
                    options={courseState.courses.map(((course: any) => ({ label: course.name, value: course.id })))}
                    register={register}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="sm_width ">
              <Controller
                control={control}
                name="instructor"
                render={({ field }) => (
                  <SelectField
                    type="text"
                    label="Select Instructor"
                    options={instructorState.instructors.map(((instructor: any) => ({ label: `${instructor.first_name} ${instructor.last_name}`, value: instructor.id })))}
                    register={register}
                    placeholder="Select Instructor"
                    isClearable={false}
                    isMulti={true}
                    {...field}
                  />
                )}
              />
            </div>

            {isEdit && (
              <div className="form_row form_row-2">
                <TextField
                  type="number"
                  min={0}
                  default={0}
                  label="Hours"
                  name="hours"
                  register={register}
                  // error={errors?.hours}
                />
                <TextField
                  type="number"
                  min={0}
                  max={59}
                  default={0}
                  label="Minutes"
                  name="minutes"
                  register={register}
                  // error={errors?.minutes}
                />
              </div>
            )}

            <div className="button__witdh mt-1">
              <PrimaryButton
                text={isEdit ? "Update" : "Add"}
                type="submit"
                loading={instructorState.update_loading}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ManagingCoursesTable;
