import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import SprintDataLists from "../../../components/AdminPanel/Sprint/SprintDataLists";

const CreateSprint = () => {
  return (
    <AdminPanelLayout>
      <SprintDataLists />
    </AdminPanelLayout>
  );
};

export default CreateSprint;
