/* eslint-disable import/no-extraneous-dependencies */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ChevronLeft, Grid } from "react-feather";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {Icon} from "@iconify/react"
import Topography from "../../../shared/Topography/Topography";
import { PlanButton, PrimaryButton } from "../../../shared/Buttons/Buttons";
import CreateAndUpdateSprint from "./CreateAndUpdateSprint";
import Empty from "../../../shared/Empty/Empty";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  deleteSprint,
  getCoursesForLearningPath,
  getSprintList,
  updateOrder
} from "../../../store/features/AdminPanel/Sprint/SprintApi";
import { changeTempOrder } from "../../../store/features/AdminPanel/Sprint/SprintSlice";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";


const SprintDataLists = () => {
  const [sprintList, setSprintist] = useState<JSX.Element[]>([]);
  const [indexForDelete, setIndexForDelete] = useState();
  const [showModal,setShowModal] = useState(false);
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const bottomRef = useRef<any>(null);

  const sprintState: any = useAppSelector((store: RootStore) => {
    return store.SprintSlice;
  });

  useEffect(() => {
    dispatch(getCoursesForLearningPath({ pathId: Number(params.id) }));
    dispatch(getSprintList({ pathId: Number(params.id) }));
  }, []);
  const onDeleteSprint = (id: number, index: number) => {
    if (!id) {
      const sprints = sprintList.filter(
        (les: any) => sprintList.indexOf(les) !== index
      );
      setSprintist(sprints);
    } else {
      dispatch(deleteSprint({ sprintId: id }));
    }
  };

  const createNewSprint = () => {
    setSprintist(
      sprintList.concat(
        <CreateAndUpdateSprint
          index={sprintList.length}
          key={sprintList.length}
          courses={sprintState.courses}
          onDelete={onDeleteSprint}
          setIndexForDelete={setIndexForDelete}
        />
      )
    );
  };

  const openRearrangeModal = ()=>{
    setShowModal(true);

  }
  const closeModal = ()=>{
    setShowModal(false);
  }

  useEffect(() => {
    if (sprintState.req_success.add) {
      const sprints = sprintList.filter(
        (les: any) => sprintList.indexOf(les) !== indexForDelete
      );
      setSprintist(sprints);
      dispatch(getSprintList({ pathId: Number(params.id) }));
    }
  }, [sprintState.req_success.add]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    if (sprintList.length > 0) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [sprintList]);

  const listenDrag = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //
    const data2 = {
      changeOrder: true,
      sourdeIndex: source.index,
      destinationIndex: destination.index,
      draggableId: draggableId,
    };
    dispatch(changeTempOrder(data2));
    const data = { list: { [draggableId]: destination.index + 1 }, learning_path_id: Number(params.id) };

    dispatch(updateOrder({ data: data }));
  };

  return (
    <div className="sprint__data__list__section mt-7">
      <div className="header__section__sprint">
        <div className="left__sction">
          {" "}
          <ChevronLeft className="back__icon" onClick={() => navigate(-1)} />
          <Topography>Sprint ({location.state.path_name}) </Topography>
        </div>
        <div className="add__rearrange__btn__wrapper">

       
        <div className="plans__button__width">
          {sprintState.req_success.courses && (
            <PlanButton onClick={openRearrangeModal} text="Rearrange Sprints" />
          )}
        </div>
        <div className="add__btn">
          <PrimaryButton text="Add new" type="button" onClick={createNewSprint}/>
        </div>
        </div>
      </div>
      {!sprintState.loading.get &&
        sprintState.req_success.get &&
        sprintState.req_success.courses &&
        sprintState.sprints.map((sprint: any, index: number) => {
          return (
            <CreateAndUpdateSprint
              index={index}
              key={sprint.id}
              courses={sprintState?.courses}
              onDelete={onDeleteSprint}
              sprint={sprint}
              id={sprint.id ?? sprint[0].id}
              setIndexForDelete={setIndexForDelete}
            />
          );
        })}

      {sprintList}
      {sprintState.sprints.length === 0 && sprintList.length === 0 && (
        <div className="">
          <Empty
            custombg
            emptyText="No plans created.  "
            emptyColorFulText="Add a sprint"
          />
        </div>
      )}
      <div ref={bottomRef} />

      <Modal show={showModal} setShow={setShowModal} onClick={closeModal} width="modal__contetn__width__drag">
        <ModalHeader title="Rearrange Sprints"/>
        <p className="subtext">Drag and Drop to arrange the sprints.</p>

        <DragDropContext onDragEnd={listenDrag}>
          <Droppable droppableId="all-list">
            {(provided) => (
              <div className="draggable__lists" {...provided.droppableProps} ref={provided.innerRef}>
                {!sprintState.loading.get &&
                sprintState.req_success.get &&
                sprintState.req_success.courses &&
                sprintState.sprints.map((sprint: any, index: number) => {
                  return (
                    <Draggable
                        draggableId={sprint.id.toString()}
                        key={sprint.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                            <div className="" key={sprint.id} 
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}>
                            <div className="draggable__list">  <Icon icon="ic:outline-drag-indicator" />
                              <p className="draggable__list__name">{sprint.name}</p></div>
                            </div>
                        )}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
              </div>
              )}
            </Droppable>
          </DragDropContext>
       
      </Modal>
    </div>
  );
};

export default SprintDataLists;
