import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getResourcesForUser, getDataInsideFolder } from "./UserResourceApi";

const initialState: any = {
  loading: false,
  req_success: false,

  not_found: false,
  //
  disable_upload_screen: false,
  nested_folder_id: null,
  clean_upload_screen: false,

  current_page: 0,
  show_info: false,
  active_id: 0,
  single_file_folder_info: {},

  // folder
  first_page_all_data: [],
  first_page_data: [],
  temp_data: [],
  searchText: "",

  // notification data
  notification_data: null,
};
// ADD about;
const UserResource = createSlice({
  name: "UserResource",
  initialState,
  reducers: {
    showInfoSlice: (state: any, { payload }: PayloadAction<any>) => {
      state.show_info = true;
      state.single_file_folder_info = payload;
    },
    setActiveSlice: (state: any, { payload }: PayloadAction<any>) => {
      state.active_id = payload;
    },
    closeShowInfoModal: (state: any, { payload }: PayloadAction<any>) => {
      state.show_info = false;
    },
    setNestedFolderId: (state: any, { payload }: PayloadAction<any>) => {
      state.nested_folder_id = payload;
    },

    setCleanScreen: (state: any) => {
      state.first_page_all_data = [];
      state.first_page_data = [];
    },

    searchFilesAndFolder: (state: any, { payload }: PayloadAction<any>) => {
      state.searchText = payload;
      if (payload.length > 1) {
        state.not_found = false;
        state.temp_data = state.first_page_all_data.filter((data: any) =>
          data.resource_name.toLowerCase().includes(payload.toLowerCase())
        );
        state.first_page_data = state.temp_data;
        if (state.first_page_data.length === 0) {
          state.not_found = true;
        }
      } else {
        state.first_page_data = state.first_page_all_data;
      }
    },

    setNotificationDataForUserResource: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      state.notification_data = payload;
    },

    clearNotificationDataForUserResource: (state: any) => {
      state.notification_data = null;
    },
  },
  extraReducers: (builder) => {
    // GET DATA
    builder.addCase(getResourcesForUser.pending, (state) => {
      state.loading = true;
      state.req_success = false;
      state.active_id = 0;
    });
    builder.addCase(
      getResourcesForUser.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.nested_folder_id = null;
        state.first_page_all_data = payload.data;
        state.first_page_data = payload.data;
      }
    );
    builder.addCase(getResourcesForUser.rejected, (state) => {
      state.loading = false;
      state.req_success.get = false;
    });

    // GET DATA INSIDE FOLDER
    builder.addCase(getDataInsideFolder.pending, (state) => {
      state.loading = true;
      state.req_success = false;
      state.active_id = 0;
    });
    builder.addCase(
      getDataInsideFolder.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.first_page_all_data = payload.data;
        state.first_page_data = payload.data;
      }
    );
    builder.addCase(getDataInsideFolder.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  searchFilesAndFolder,
  showInfoSlice,
  closeShowInfoModal,
  setActiveSlice,
  setNestedFolderId,
  setCleanScreen,
  setNotificationDataForUserResource,
  clearNotificationDataForUserResource,
} = UserResource.actions;
export default UserResource.reducer;
