import React from "react";
import InputContainer from "../../Layouts/Input/InputContainer";
import ErrorText from "../ErrorText/ErrorText";
import { InputLabel } from "../InputLabel/InputLabel";
import "./textarea.scss";

export default function TextArea(props: any) {
  const {
    name,
    text,
    label,
    type,
    placeholder,
    error,
    register,
    half,
    height32,
    handleTextAreaStyle,
    ...rest
  } = props;
  const errorMessage = error?.message;
  return (
    <div>
      <InputContainer>
        <InputLabel name={name} text={label} />
        <textarea
          placeholder={placeholder}
          className={`custom__textarea ${error && "error"} ${half && "half"} ${
            height32 && "height__32"
          } ${handleTextAreaStyle && "textarea__custom__style"}`}
          {...(register && register(name))}
          {...rest}
        />
        <ErrorText error={errorMessage} />
      </InputContainer>
    </div>
  );
}
