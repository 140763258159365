import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../../../helpers/utils/formUtils";

const validationSchema = yup.object().shape({
  size: yup
    .number()
    .nullable()
    .min(1)
    .max(100)
    .typeError(REQUIRED_VALIDATION("Company Size")),
});
export const formOptions: any = {
  resolver: yupResolver(validationSchema),
  mode: "onTouched",
  reValidateMode: "onChange",
};
