import React, { useEffect } from "react";
import StudentBanner from "../../../components/AdminPanel/Banner/StudentBanner/StudentBanner";
import LandingPageHaderBanner from "../../../components/AdminPanel/Banner/LandingPageHeaderBanner/LandingPageHaderBanner";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import LandingPagePopup from "../../../components/AdminPanel/Banner/LandingPagePopup/LandingPagePopup";
import { RootStore, useAppDispatch, useAppSelector } from "../../../store/store";
import { getBannersData } from "../../../store/features/AdminPanel/Banners/BannersApi";

const Banners = () => {
  const dispatch = useAppDispatch();
  const bannersState:any = useAppSelector((store:RootStore)=>store.BannersSlice);
  useEffect(()=>{
    dispatch(getBannersData());
  },[]);
  useEffect(() => {
    if (!bannersState?.loading.add && bannersState?.req_success.add) {
      dispatch(getBannersData());
    }
  }, [bannersState?.req_success.add, bannersState?.loading.add]);

  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="mt-7">
          <LandingPageHaderBanner />
          <LandingPagePopup/>
          <StudentBanner />
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default Banners;
