import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import AdminLearningPathCourses from "../../../components/Orgination/Courses/AdminLearningPathCourses";

const AdminLearningPathDetail = () => {
  return (
    <AdminPanelLayout>
      <AdminLearningPathCourses />
    </AdminPanelLayout>
  );
};

export default AdminLearningPathDetail;
