/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prettier/prettier */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import VideoUploader from "../../../../shared/VideoUploader/VideoUploader";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  createVideoIdInBunnyNet,
  getVideoFromBunnyNet,
  uploadVideoInBackend,
  uploadVideoToBunnyNet,
} from "../../../../store/features/AdminPanel/VideoTour/VideoTourApi";
import { editUploadedVideo } from "../../../../store/features/AdminPanel/VideoTour/VideoTourSlice";
import VideoProcessing from "../../AdminInterviewAnswer/AdminInterviewAnswerFaq/FaqContent/VideoProcessing";
import { formOptions } from "./helper";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";
import ThumbnailUploader from "../../../../shared/VideoUploader/ThumbnailUploader/ThumbnailUploader";

const VideoTourHeader = (props: any) => {
  const { showModal, setShowModal } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [hideVideo, setHideVideo] = useState(false);
  const [videoFileInfo, setVideoFileInfo] = useState<any>({});
  const [fileUploadedProgress, setFileUploadedProgress] = useState(0);
  const [videoUploadedProgress, setVideoUploadedProgress] = useState(0);
  const [tsuInstance, setTsuInstance] = useState();
  const [videoError, setVideoError] = useState(false);
  const [videoStatus, setVideoStatus] = useState({
    loading: false,
    success: false,
  });
  const [imageExt, setImageExt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageError, setImageError] = useState("");
  const [selectedVideoFileUrl, setSelectedVideoFileUrl] = useState("");
  const videoRef: any = useRef();

  const dispatch = useAppDispatch();
  const videoTourState: any = useAppSelector(
    (store: RootStore) => store.VideoTourSlice
  );

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState,
    watch,
  } = useForm<any>(formOptions);
  const { errors } = formState;

  // Watch the video_url field
  const videoUrl = watch("video_url");

  const clearForm = () => {
    reset();
    setVideoFileInfo({});
    setImageUrl("");
    setImageFile("");
    setImageError("");
    setVideoError(false);
    setSelectedVideoFileUrl("");
    videoRef?.current?.load();
  };

  const submitForm = (values: any) => {
    if (selectedVideoFileUrl) {
      if (!imageFile) {
        setImageError("Image is required");
        return;
      }
    }

    // const content = {
    //   title: values?.title,
    //   videoId: videoTourState?.bunny_video_id,
    //   thumbnail: imageFile,
    //   extension: imageExt,
    //   show: showVideo,
    //   video_url: videoUrl ? values.video_url : videoTourState?.video_url,
    // };

    // Initialize the content object
    let content;

    // Check if videoUrl is present
    if (videoUrl) {
      content = {
        title: values?.title,
        videoId: null,
        thumbnail: null,
        extension: null,
        show: showVideo,
        video_url: values.video_url,
      };
    } else {
      content = {
        title: values?.title,
        videoId: videoTourState?.bunny_video_id,
        thumbnail: imageFile,
        extension: imageExt,
        show: showVideo,
        video_url: videoTourState?.video_url,
      };
    }
    if (selectedVideoFileUrl !== "" || videoUrl) {
      setVideoError(false);
      dispatch(uploadVideoInBackend(content));
    } else {
      setVideoError(true);
    }
  };

  const clearVideo = () => {
    setVideoFileInfo({});
    // setVideoError(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowVideo(false);
    setHideVideo(false);
    setImageUrl("");
    setImageFile("");
    setImageError("");
    clearForm();
    setVideoError(false);
    dispatch(editUploadedVideo(false));
  };

  useEffect(() => {
    if (videoFileInfo?.file) {
      const data = { title: getValues("title") };
      dispatch(createVideoIdInBunnyNet({ data }));
    }
    setVideoError(false);
  }, [videoFileInfo]);

  useEffect(() => {
    if (videoTourState?.is_success.videoId) {
      dispatch(
        uploadVideoToBunnyNet({
          data: videoFileInfo.file,
          videoId: videoTourState.bunny_video_id,
          setTsuInstance: setTsuInstance,
          setVideoStatus: setVideoStatus,
          videoStatus: videoStatus,
          setVideoUploadedProgress: setVideoUploadedProgress,
        })
      );
    }
  }, [videoTourState?.is_success.videoId]);

  useEffect(() => {
    if (videoTourState.edit_uploaded_video) {
      setShowModal(true);
      setValue("title", videoTourState?.video_data?.content?.title);
      if (videoTourState?.video_data?.content?.video_url) {
        setValue("video_url", videoTourState?.video_data?.content?.video_url);
      } else {
        setSelectedVideoFileUrl(videoTourState?.video_data?.content?.videoId);
        setImageUrl(videoTourState?.video_data?.content?.thumbnail);
        setImageFile(videoTourState?.video_data?.content?.thumbnail);
        setImageExt(videoTourState?.video_data?.content?.extension);
      }

      setShowVideo(videoTourState?.video_data?.content?.show);
      setHideVideo(!videoTourState?.video_data?.content?.show);
    }
    if (
      !videoTourState?.is_loading?.uploadVideo &&
      videoTourState?.is_success.uploadVideo
    ) {
      setShowModal(false);
      setVideoError(false);
      closeModal();
    }
  }, [
    videoTourState.edit_uploaded_video,
    videoTourState?.is_loading?.uploadVideo,
    videoTourState?.is_success.uploadVideo,
  ]);

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Video</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="button__witdh">
          <PrimaryButton
            text="Add Video"
            size="sm"
            type="button"
            disable={videoTourState?.video_data?.content !== null}
            loading={false}
            onClick={() => {
              setShowModal(true);
              dispatch(editUploadedVideo(false));
            }}
          />
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal} onClick={closeModal}>
        <ModalHeader
          title={
            videoTourState.edit_uploaded_video
              ? "Update On Boarding Video"
              : "Add On Boarding Video"
          }
        />
        <ModalBody>
          <form className="" onSubmit={handleSubmit(submitForm)}>
            <TextField
              className="text-field"
              type="text"
              label="Title"
              name="title"
              register={register}
              error={errors?.title}
            />
            {!selectedVideoFileUrl && (
              <div className="form_row">
                <TextField
                  className="text-field"
                  type="text"
                  label="Video URL"
                  name="video_url"
                  register={register}
                />
              </div>
            )}

            {!videoUrl && (
              <>
                <div className="video__uploader">
                  <div className="upload__label">Upload Video</div>
                  <VideoUploader
                    setVideoFileInfo={setVideoFileInfo}
                    clearVideo={clearVideo}
                    videoRef={videoRef}
                    selectedVideoFileUrl={selectedVideoFileUrl}
                    setSelectedVideoFileUrl={setSelectedVideoFileUrl}
                    videoTourState={videoTourState}
                  />
                  {videoError && <ErrorText error="Video is required" />}
                </div>
                <div className="thumbnail__uploader">
                  <div className="thumbnail__label">Upload Thumbnail</div>
                  <ThumbnailUploader
                    imageExt={imageExt}
                    setImageExt={setImageExt}
                    imageUrl={imageUrl}
                    setImageUrl={setImageUrl}
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                    imageError={imageError}
                    setImageError={setImageError}
                  />
                </div>
              </>
            )}

            <div className="show__hide__checkbox">
              <CheckBox
                label="Show"
                value={showVideo}
                onClick={() => {
                  setShowVideo(true);
                  setHideVideo(false);
                }}
              />
            </div>
            <div className="show__hide__checkbox">
              <CheckBox
                label="Hide"
                value={hideVideo}
                onClick={() => {
                  setHideVideo(true);
                  setShowVideo(false);
                }}
              />
            </div>
            <div className="button__witdh mt-1">
              <PrimaryButton
                text={videoTourState.edit_uploaded_video ? "Update" : "Add"}
                type="submit"
                loading={
                  videoTourState?.is_loading?.uploadVideo &&
                  !videoTourState?.is_success.uploadVideo
                }
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VideoTourHeader;
