import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getProductManagerLearningPathCourses,
  getClassArchiveForProductManager,
  enrollToThinkificCourse,
} from "./ProductManagerCoursesApi";

const initialState: any = {
  loading: {
    course: false,
    archiv: false,
    enroll: false,
  },
  req_success: {
    course: false,
    archive: false,
    enroll: false,
  },
  instructors: [],
  courses: [],
  archives: [],
};

const ProductManagerCourse = createSlice({
  name: "ProductManagerCourse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL COURSES TO SHOW IN LEARNIG PATH FOR PRODUCT MANAGER
    builder.addCase(getProductManagerLearningPathCourses.pending, (state) => {
      state.loading.course = true;
      state.req_success.course = false;
    });
    builder.addCase(
      getProductManagerLearningPathCourses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.course = false;
        state.req_success.course = true;
        state.courses = payload.data;
        state.instructors = payload.instructors;
      }
    );
    builder.addCase(getProductManagerLearningPathCourses.rejected, (state) => {
      state.loading.course = false;
    });

    // GET CLASS ARCHIVES
    builder.addCase(getClassArchiveForProductManager.pending, (state) => {
      state.loading.archive = true;
      state.req_success.archive = false;
    });
    builder.addCase(
      getClassArchiveForProductManager.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.archive = false;
        state.req_success.archive = true;
        state.archives = payload.data;
      }
    );
    builder.addCase(getClassArchiveForProductManager.rejected, (state) => {
      state.loading.archive = false;
    });

    // ENROLL TO COURSES
    builder.addCase(enrollToThinkificCourse.pending, (state) => {
      state.loading.enroll = true;
      state.req_success.enroll = false;
    });
    builder.addCase(
      enrollToThinkificCourse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.enroll = false;
        state.req_success.enroll = true;
      }
    );
    builder.addCase(enrollToThinkificCourse.rejected, (state) => {
      state.loading.enroll = false;
    });
  },
});

export default ProductManagerCourse.reducer;
