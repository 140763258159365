import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetLearningPathCard, updateLearningPath } from "./LearningPathCard";

const initialState: any = {
  loading: false,
  update_loading: false,
  req_success: false,
  update_success: false,
  learningPathCard: [],
  post: [],
};

const learningPathCardSlice = createSlice({
  name: "learningPathCard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL LEARNING PATH CARDS
    builder.addCase(GetLearningPathCard.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      GetLearningPathCard.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.learningPathCard = payload?.data;
      },
    );
    builder.addCase(GetLearningPathCard.rejected, (state) => {
      state.loading = false;
    });

    // update learning path card
    builder.addCase(updateLearningPath.pending, (state) => {
      state.update_loading = true;
      state.update_success = false;
    });
    builder.addCase(
      updateLearningPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.update_loading = false;
        state.update_success = true;
        state.learningPathCard = state.learningPathCard.map((course: any) => {
          if (course.id === payload?.data.id) {
            return payload?.data;
          }
          return course;
        });
      },
    );
    builder.addCase(updateLearningPath.rejected, (state) => {
      state.update_loading = false;
    });
  },
});

export default learningPathCardSlice.reducer;
