import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";
import axiosInstance from "../../../../apiConfigs/axiosInstance";

export const getProductManagerLearningPathCourses = createAsyncThunk(
  "getProductManagerLearningPathCourses",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/get-bundle-courses/118280`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getClassArchiveForProductManager = createAsyncThunk(
  "getClassArchiveForProductManager",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/class-archives`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const enrollToThinkificCourse = createAsyncThunk(
  "enrollToThinkificCourse",
  async (
    { courseId }: { courseId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/enroll-to-course/${courseId}`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
