import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import TextField from "../../../../../shared/Inputs/TextField/TextField";
import Modal, {
  ModalHeader,
  ModalBody,
} from "../../../../../shared/Modals/Modal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import { REQUIRED_VALIDATION } from "../../../../../helpers/utils/formUtils";
import {
  closeCategoryEditModal,
  searchCategoryInTable,
} from "../../../../../store/features/AdminPanel/Blog/Category/CategorySlice";
import {
  AddCategory,
  getCategories,
  updateCategory,
} from "../../../../../store/features/AdminPanel/Blog/Category/CategoryApi";
import "./blogCatagoriesHeader.scss";

const BlogCatagoriesHeader = () => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const catState: any = useAppSelector((store: RootStore) => {
    return store.CategorySlice;
  });

  const openModal = () => {
    setShowModal(true);
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Category Name")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  useEffect(() => {
    dispatch(searchCategoryInTable(searchText));
  }, [searchText]);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const clearForm = () => {
    reset();
  };

  useEffect(() => {
    if (catState.editCategory) {
      setValue("name", catState.single_category_info?.name);

      setShowModal(true);
    }
  }, [catState.editCategory]);
  const onSubmit = (values: any) => {
    if (!catState.editCategory) {
      dispatch(AddCategory(values));
    }
    if (catState.editCategory) {
      const data = {
        ...values,
        _method: "put",
      };

      dispatch(
        updateCategory({
          catId: catState.single_category_info?.id,
          data: data,
        }),
      );
    }
  };
  const handleModal = () => {
    dispatch(closeCategoryEditModal(false));
    clearForm();
  };
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (catState.req_success.add) {
      setShowModal(false);
      clearForm();
    }
  }, [catState.req_success.add]);

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  return (
    <div>
      <div className="category_blog_header_container">
        <div className="left__section">
          <TableHeadingTitle>Blog Categories Listing</TableHeadingTitle>
        </div>
        <div className="right__section">
          <div className="search">
            <SearchBar value={searchText} setValue={setSearchText} />
          </div>
          <div className="button__witdh">
            <PrimaryButton
              text="Add"
              size="sm"
              type="button"
              // loading={courseState.pull_loading}
              onClick={openModal}
            />
          </div>
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal} onClick={handleModal}>
        <ModalHeader title="Add New Catagory" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form__group">
              <TextField
                label="Catagory Name"
                name="name"
                type="text"
                register={register}
                error={errors?.name}
              />
            </div>
            <div
              className={`add_button__witdh mt-1 ${
                catState.editCategory ? "update" : ""
              }`}
            >
              <PrimaryButton
                text={catState.editCategory ? "Update" : "Add"}
                type="submit"
                loading={catState.loading.add}
                // onClick={onSubmit}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BlogCatagoriesHeader;
