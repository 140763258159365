import React from "react";
import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import LearningResourcesComp from "../../../components/NewAcademicUser/LearningResources/LearningResource/LearningResourcesComp";
import MeetWithTeamComp from "../../../components/NewAcademicUser/MeetWithTeam/MeetWithTeamComp";

const MeetWithTeam = () => {
  return (
    <>
      <Nav normal navBgColorHandle />
      <MeetWithTeamComp />
    </>
  );
};

export default MeetWithTeam;
