/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../shared/Table/Table";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import "./interview.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  addOrupdateInterviewRoles,
  deleteRole,
  getInterviewRoles,
} from "../../../../store/features/AdminPanel/Interview/Question/InterviewApi";
import Empty from "../../../../shared/Empty/Empty";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import { closeAddInterviewRoleModal, setEditRole } from "../../../../store/features/AdminPanel/Interview/Question/InterviewSlice";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";

const InterviewComp = (props: any) => {
  const { setOpenAddQuestionPage, setRole, backToHomeTab } = props;
  const [AddQuestionRoleModal, setAddQuestionRoleModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [activeId, setActiveId] = useState<string | number>("");
  const dispatch = useAppDispatch();

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewSlice;
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Role ")),
  });

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const { register, handleSubmit, setValue, formState, reset } =
    useForm<any>(formOptions);
  const { errors } = formState;

  const onSubmit = (values: any) => {
    let data:any;
    if(InterviewState?.editRole){
      data = {...values,id:InterviewState?.singleRole?.id}
    }
    else{
      data ={...values}
    }
    dispatch(addOrupdateInterviewRoles({data}));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    backToHomeTab();
    dispatch(getInterviewRoles());
  }, []);

  useEffect(() => {
    if (
      !InterviewState?.loading?.addRole &&
      InterviewState?.req_success?.addRole
    ) {
      setAddQuestionRoleModal(false);
      reset();
      dispatch(closeAddInterviewRoleModal())
    }
  }, [InterviewState?.loading?.addRole, InterviewState?.req_success?.addRole]);

  useEffect(()=>{
    if(!InterviewState?.loading?.deleteRole && InterviewState?.req_success?.deleteRole){
      setDeleteShowModal(false); 
      dispatch(getInterviewRoles());
    }

  },[InterviewState?.loading?.deleteRole,InterviewState?.req_success?.deleteRole])

  useEffect(() => {
    if (InterviewState?.editRole) {
      setAddQuestionRoleModal(true);
      setValue("name", InterviewState?.singleRole?.name);
    }
  }, [InterviewState?.editRole, InterviewState?.singleRole]);

  const openAddQuestionView = (role: any) => {
    setRole(role);
    setOpenAddQuestionPage(true);
  };

  const openDeleteModal = (id: number) => {
    setDeleteShowModal(true);
    setActiveId(id);
  };

  const closeModal = () => {
    setAddQuestionRoleModal(false);
    reset();
    dispatch(closeAddInterviewRoleModal())
  };
  return (
    <>
      <div className="admin__interview__wrapper_header">
        <div className="table_heading  add__question__role__heading">
          <TableHeadingTitle>Interview Preparation</TableHeadingTitle>
          <div className="add__question__role__btn">
            <PrimaryButton
              text="Add  Role"
              type="button"
               size="sm"
              loading={false}
              onClick={() => setAddQuestionRoleModal(true)}
            />
          </div>
        </div>

        <Table
          heading={
            <TableHeading>
              <th className="title">Role name</th>
              <th className="title heading__center"> Number of questions</th>
              <th className="title"> Last updated on</th>
              <th className="hide__text">.</th>
            </TableHeading>
          }
        >
          <TableBody>
            {InterviewState?.all_roles?.length > 0 &&
              InterviewState.all_roles.map((role: any) => {
                return (
                  <tr  key={role.id}>
                    <td
                      role="button"
                      aria-hidden
                      onClick={() => openAddQuestionView(role)}
                    >
                      {role.name}
                    </td>
                    <td className="data text__center__td">
                      {" "}
                      <span>{role.total_questions}</span>
                    </td>
                    <td className="data">{role.last_updated}</td>

                    <TableAction
                      onEdit={() => dispatch(setEditRole(role))}
                      onDelete={() => openDeleteModal(role.id)}
                    />
                  </tr>
                );
              })}
          </TableBody>
        </Table>

        {InterviewState?.all_roles?.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty emptyText="It seems empty." custombg={true} />
          </div>
        )}
      </div>
      <Modal
        show={AddQuestionRoleModal}
        setShow={setAddQuestionRoleModal}
        onClick={closeModal}
      >
        <ModalHeader
          title={InterviewState?.editRole ? "Edit  Role " : "Add  Role "}
        />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form_row">
              <TextField
                type="text"
                label="Role"
                name="name"
                size="sm"
                register={register}
                error={errors?.name}
              />
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton
                text={InterviewState?.editRole ? "Update" : "Add"}
                loading={InterviewState?.loading?.addRole}
                size="sm"
                type="submit"
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={InterviewState.loading.deleteRole}
        onClick={() => dispatch(deleteRole({ id: activeId }))}
      />
    </>
  );
};

export default InterviewComp;
