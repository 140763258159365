import React from "react";
import { Command, Grid, User } from "react-feather";
import faqIcon from "../../../assets/icons/clipboard.svg";

import NewSideBarLink from "../NewSideBarLink/NewSideBarLink";
import { renderLandingPageManagerNavLinks } from "./helper";
import { RootStore, useAppSelector } from "../../../store/store";
import { ROUTES } from "../../../helpers/utils/Constants/route";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";

const SubAdminSidebar = (props: any) => {
  const { smSidebar } = props;
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  return (
    <>
      <NewSideBarLink
        img={faqIcon}
        icon={<Grid />}
        title="Landing Page Manager"
        smSidebar={smSidebar}
        links={[
          {
            id: 1,
            title: "Blog Management ",
            path: ROUTES.BLOG,
          },
        ]}
      />
      <NewSideBarLink
        img={faqIcon}
        icon={<User />}
        title="Individual Members"
        smSidebar={smSidebar}
        links={[
          {
            id: 1,
            title: "Users",
            path: ROUTES.USER,
          },
        ]}
      />
      {
        configState.configs.membership_enabled !== Domain.TAC && (
          <NewSideBarLink
          img={faqIcon}
          icon={<Command />}
          title="User Dashboard"
          smSidebar={smSidebar}
          links={[
            {
              id: 1,
              title: "Quick Link and resources",
              path: ROUTES.QUICK_LINKS,
            },
            { id: 2, title: "Archive", path: ROUTES.ARCHIVE },
            { id: 3, title: "Reference", path: ROUTES.REFERENCE },
          ]}
        />
        )
      }

    </>
  );
};

export default SubAdminSidebar;
