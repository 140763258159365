import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

export const loadSubAdmin = createAsyncThunk(
  "loadSubAdmin",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/get-all-subadmins`);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addSubAdmin = createAsyncThunk(
  "addSubAdmin",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/add-user`, data);
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const rensedInvitationForSubAdmin = createAsyncThunk(
  "rensedInvitationForSubAdmin",
  async (
    { email }: { email: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/resend-user-invite`,
        {
          email: email,
        }
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSubMentor = createAsyncThunk(
  "updateSubMentor",
  async (
    { userId, data }: { userId: any; data: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/update-user/${userId}`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSubAdmin = createAsyncThunk(
  "deleteSubAdmin",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(`${dynamic_url}/users/${data}`);
      REQUIRED__TOASTER(res.data);

      res.data.id = data;

      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.response);
    }
  }
);
