import GoogleLogin from "../../components/Auth/AuthForm/GoogleLogin/GoogleLogin";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";

const GoogleLoginComponent = (props: any) => {
  return (
    <LandingLayout>
      <AuthLayout>
        <GoogleLogin />
      </AuthLayout>
    </LandingLayout>
  );
};

export default GoogleLoginComponent;
