/* eslint-disable prefer-template */
export const prefixHttps = (url: string) => {
  let newUrl = url;
  if (!/^https?:\/\//i.test(newUrl)) {
    newUrl = "https://" + newUrl;
  }
  return newUrl;
};

// Function to extract main domain with protocol from URL
export const getMainDomainWithProtocol = (url: string) => {
  const parsedUrl = new URL(url);
  const hostParts = parsedUrl.hostname.split(".");
  const newUrl = [...hostParts];
  // this is for local env
  if (process.env.NODE_ENV === "development") {
    if (newUrl[0] === "localhost") {
      return "http://localhost:8000/api";
    }
    return `http://localhost:8000/api/${newUrl[0]}`;
  }

  // for staging
  if (newUrl[0] === "staging") {
    const slicedUrl = newUrl.slice(1);
    const joinedUrl = "backend." + slicedUrl.join(".");

    const requiredUrl = `${prefixHttps(joinedUrl)}/api`;
    // if no tenant
    return requiredUrl;
  }

  // for staging and live
  if (newUrl[0] === "academy") {
    const slicedUrl = newUrl.slice(1);
    const joinedUrl = slicedUrl.join(".");
    const requiredUrl = `${prefixHttps(joinedUrl)}/api`;
    // if no tenant
    return requiredUrl;
  }
  const slicedUrl = newUrl.slice(1);
  const joinedUrl = slicedUrl.join(".");
  const requiredUrl = `${prefixHttps(joinedUrl)}/api/${newUrl[0]}`;
  // if no tenant
  return requiredUrl;
};

export const renderDynamicUrlFromState: any = (state: any) => {
  const url = state?.ThemeSlice?.configs?.api_url;
  return url;
};

export const renderMainUrlFromState: any = (state: any) => {
  const url = state?.ThemeSlice?.configs?.site_url;
  return url;
};

export const renderDynamicUrlFromComponentLevelState: any = (state: any) => {
  const url = state?.configs?.api_url;
  return url;
};

export const renderMainUrlFromComponentLevelState: any = (state: any) => {
  const url = state?.configs?.site_url;
  return url;
};
