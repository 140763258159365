import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import ListAllCourses from "../../../components/UserDashboard/ListCourses/ListAllCourses";
import ViewFullCourse from "../../../components/UserDashboard/ViewCourse/ViewFullCourse";
import "./listcourses.scss";

const ListCourses = () => {
  return (
    <AdminPanelLayout>
      <ListAllCourses />
    </AdminPanelLayout>
  );
};

export default ListCourses;
