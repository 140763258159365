export const THEME_NAME = {
  Indigo: "Indigo",
  Azure: "Azure",
  MistyGrey: "MistyGrey",
  default: "default",
};

export const THEME_MODE = {
  indigo_dark: "indigo_dark",
  indigo_light: "indigo_light",
  azure_dark: "azure_dark",
  azure_light: "azure_light",
  mistyGrey_dark: "mistyGrey_dark",
  mistyGrey_light: "mistyGrey_light",
  dark: "dark",
  light: "light",
};
