import userimage from "../../../../assets/icons/user.png";

const MemberList = (props: any) => {
  const { members } = props;
  return (
    <div className="member__section">
      <span className="members">Members</span>
      <div className="member__list">
        {members.map((member: any) => {
          return (
            <div className="team__member__info" key={member.id}>
              <div className="name_and_avatar_section">
                <img
                  src={member.avatar_url ?? userimage}
                  className="dash_team_avatar"
                  alt="name"
                />
                <div className="name__section">
                  <span className="name">{member.name}</span>
                  <span className="user_name">{member.email}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MemberList;
