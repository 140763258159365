import { useLayoutEffect } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import "./landinglayout.scss";
import { useLocation } from "react-router-dom";
import { RootStore, useAppSelector } from "../../../../store/store";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const LandingLayout = (props: any) => {
  const { children } = props;
  const location = useLocation();
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const isLoginPage = location.pathname.includes("/") && configState.configs.membership_enabled === Domain.TAA ;

  useLayoutEffect(() => {
    document.body.classList.remove("light");
    document.body.classList.remove("dark");
    document.body.classList.add("dark");
  }, []);
  return (
    <div className={`landinglayout__container ${isLoginPage && "layout__container"}`}>
      <ToastContainer />
      <div className="landinglayout-content">{children}</div>{" "}
    </div>
  );
};

export default LandingLayout;
