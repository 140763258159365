import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import linkedinIcon from "../../../assets/icons/linkedinIcon.svg";

function TeamCard(props: any) {
  return (
    <div className="team-card">
      <div className="tc-img">
        <img
          src="https://i.etsystatic.com/8734722/r/il/a2e469/2383685186/il_570xN.2383685186_bb1j.jpg"
          alt="team name"
        />
      </div>
      <div className="name">Test Name </div>
      <div className="role">CEO & Co-Founder</div>
      <div className="social-link">
        <Link to="/">
          <img src={linkedinIcon} alt="linkedin" />
        </Link>
      </div>
    </div>
  );
}

export default TeamCard;
