import { DangerButton, MdSmokeButton, PrimaryButton, SmokeButton } from "../Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../Modals/Modal";
import "./deletemodal.scss";

const DeleteModal = (props: any) => {
  const { showModal, setShowModal, loading, onClick, message,deleteAll ,setShowSelectCheckbox,yesBtn} = props;

  const handleSelectAll = ()=>{
    setShowSelectCheckbox(true);
    setShowModal(false);
  }

  return (
    <Modal show={showModal} setShow={setShowModal}>
      <ModalHeader title="Are you sure ?" />
      <ModalBody>
        <span className="notice">
          {message ?? "You won't be able to revert this!"}
        </span>
        <div className="divider" />
        <div className="row">
          <div className=" mt-1 mr-2">
            {
              yesBtn ? ( <PrimaryButton
                text="Yes"
                type="submit"
                loading={loading}
                onClick={onClick}
              />) : ( <PrimaryButton
                text="Yes, delete it"
                type="submit"
                loading={loading}
                onClick={onClick}
              />)
            }
           
          </div>
          {
            deleteAll && (
              <div className=" mt-1 mr-2">
              <MdSmokeButton
                text="Delete Multiple"
                type="submit"
                onClick={handleSelectAll}
              />
            </div>
            )
          }
          <div className=" mt-1">
            <DangerButton
              text="Cancel"
              type="submit"
              loading={false}
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
