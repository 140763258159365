/* eslint-disable jsx-a11y/media-has-caption */

import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";

const VideoModal = (props: any) => {
  const { showModal, setShowModal, videoLink, setVideo } = props;

  const handleVideo = () => {
    setVideo("");
  };
  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      onClick={handleVideo}
      width="modal_width__handle"
    >
      <ModalBody>
        <div className="IAW__video__container">
          {" "}
          <iframe
            src={videoLink}
            // width="960"
            // height="540"
            allow="autoplay; fullscreen; picture-in-picture"
            title=" picture-in-picture"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VideoModal;
