/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./videoLinksHeader.scss";
import React, { useRef, useState } from "react";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import TextField from "../../../shared/Inputs/TextField/TextField";
import TextArea from "../../../shared/Inputs/TextArea/TextArea";

const VideoLinksHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const hiddenFileInput = useRef<any>(null);

  const handleClick = (event: any) => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const removeImage = () => {
    setImageUrl("");
  };
  const validationSchema = yup.object().shape({
    memberName: yup.string().required(REQUIRED_VALIDATION("Member Name")),
    designation: yup.string().required(REQUIRED_VALIDATION("Designation")),
    linkedinProfile: yup
      .string()
      .required(REQUIRED_VALIDATION("Linkedin Profile")),
    order: yup.string().required(REQUIRED_VALIDATION("Order")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>(formOptions);

  const getFiles = (e: any) => {
    const contentFile = e.target.files[0];
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
  };

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Video Links</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal}>
        <ModalHeader title="Add New Video Link" />
        <ModalBody>
          <form className="login__form__container">
            <div className="input_text_section">
              <div className="form_row">
                <TextField
                  className="text-field"
                  type="text"
                  label="Name"
                  name="memberName"
                  register={register}
                  error={errors?.memberName}
                />
              </div>

              <div className="form_row">
                <TextField
                  className="text-field"
                  type="text"
                  label="Designation"
                  name="designation"
                  register={register}
                  error={errors?.designation}
                />
              </div>
            </div>
            <div className="selection">
              <div className="image__selection_section">
                <div className="functions__section">
                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    ref={hiddenFileInput}
                    onChange={(e: any) => getFiles(e)}
                    style={{ display: "none" }}
                  />
                  <span className="upload__image" onClick={handleClick}>
                    Click to add image
                  </span>
                </div>
              </div>
            </div>
            <div className="description">
              <TextArea
                className="description__textarea"
                label="Description"
                name="description"
                register={register}
                error={errors?.description}
              />
            </div>
            <div className="button__witdh mt-1">
              <PrimaryButton text="Add" type="submit" loading={false} />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VideoLinksHeader;
