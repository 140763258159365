/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllTenantcourses, adminCourseDetail } from "./CoursesApi";

const initialState: any = {
  loading: {
    get: false,
    detail: false,
  },
  req_success: {
    get: false,
    detail: false,
  },
  courses: [],
  chapters: [],
  course_detail: {},
  instructor: {},
};

const CourseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL COURSES
    builder.addCase(getAllTenantcourses.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getAllTenantcourses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.courses = payload?.courses;
      },
    );
    builder.addCase(getAllTenantcourses.rejected, (state) => {
      state.loading.get = false;
    });

    // GET ALL COURSES
    builder.addCase(adminCourseDetail.pending, (state) => {
      state.loading.detail = true;
      state.req_success.detail = false;
    });
    builder.addCase(
      adminCourseDetail.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.detail = false;
        state.req_success.detail = true;
        state.chapters = payload?.chapters;
        state.course_detail = payload?.course;
        state.instructor = payload?.instructor;
      },
    );
    builder.addCase(adminCourseDetail.rejected, (state) => {
      state.loading.detail = false;
    });
  },
});

export default CourseSlice.reducer;
