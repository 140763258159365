import { useState } from "react";
import { useParams } from "react-router-dom";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import GroupAction from "../../../components/Orgination/Groups/GroupAction/GroupAction";
import GroupContent from "../../../components/Orgination/Groups/GroupContent/GroupContent";
import GroupHeader from "../../../components/Orgination/Groups/GroupHeader/GroupHeader";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import PaginateButton from "../../../shared/PaginateButton/PaginateButton";
import { groupsPagination } from "../../../store/features/Tenant/Orgination/Group/GroupSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";

function CreateNewGroup(props: any) {
  const dispatch = useAppDispatch();

  return (
    <AdminPanelLayout>
      <BoxShadow>
        <GroupAction />
      </BoxShadow>
    </AdminPanelLayout>
  );
}

export default CreateNewGroup;
