import { useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { loadUser } from "../../store/features/Auth/AuthApi";
import { RootStore, useAppDispatch, useAppSelector } from "../../store/store";
import { roles } from "../../helpers/utils/Constants/roles";
import { ROUTES } from "../../helpers/utils/Constants/route";

/* eslint-disable no-else-return */
const PrivateRoute = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [authFail, setAuthFail] = useState(false);

  const dispatch = useAppDispatch();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  useLayoutEffect(() => {
    dispatch(loadUser({ setAuthFail: setAuthFail }));
  }, []);
  useEffect(() => {
    if (authFail) {
      navigate("/");
    }
  }, [authFail]);

  console.log("private--");

  useLayoutEffect(() => {
    if (authState.authenticated && authState.tac_user) {
      if (!authState.tac_user.default_password_updated) {
        navigate("/default-password-change");
      }
      if (authState.tac_user.role === "user") {
        setUser(true);
      }
      if (authState.tac_user.role === "sys_adm") {
        navigate("/dashboard");
      }
      if (authState.tac_user.role === "owner") {
        navigate("/organization");
      }
      if (authState.tac_user.role === "admin") {
        navigate("/organization");
      }
      if (authState.tac_user.role === "student") {
        navigate("/organization-user-dashboard");
      }
      if (
        authState.tac_user.role === roles.BUISNESS_ANALYST_STUDENT ||
        authState.tac_user.role === roles.SCRUM_MASTER_STUDENT ||
        authState.tac_user.role === roles.PRODUCT_OWNER_STUDENT
      ) {
        navigate(ROUTES.TRIAL_USER_COURSE);
      }
    }
  }, [authState.authenticated]);

  useEffect(() => {
    if (authState.authenticated && authState.tac_user) {
      if (!authState.tac_user.default_password_updated) {
        navigate("/default-password-change");
      }
    }
  }, [authState.authenticated]);

  if (user) {
    return <Outlet />;
  } else {
    return null;
  }
};

export default PrivateRoute;
