import React from "react";
import "./tourPopup.scss";
import { X } from "react-feather";
import image from "../../../../assets/images/interarction.png";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../store/store";
import { closeShowPopup } from "../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";

const TourPopupModal = (props: any) => {
  const dispatch = useAppDispatch();
  const showPopupState:any = useAppSelector((store:RootStore) => store.ShowPopupSlice);

  const closePopUp=()=>{
    dispatch(closeShowPopup());
  }

  return (
    <div className="tour__wrapper">
      <div className="tour__container">
        <div
          className="close__icon"
          onClick={closePopUp}
          role="button"
          aria-hidden
        >
          <X />
        </div>
        <div className="content__wrapper">
          <img src={image} alt="" />
          <h3>Welcome to Interview Preparation</h3>
          <p>
            Let’s get you started with a quick look in the process of interview
            preparation .
          </p>
          <button type="button" onClick={closePopUp}>
            Let’s get started!
          </button>
        </div>
      </div>
    </div>
  );
};

export default TourPopupModal;
