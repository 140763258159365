/* eslint-disable import/no-extraneous-dependencies */
import React from "react"
import { Icon } from "@iconify/react"
import "./index.scss"

interface PurchesbadgeProps {
  text:string;
}
const Purchesbadge:React.FC<PurchesbadgeProps> = ({
  text
}) => {
  return (
    <div className='purches__badge'>
        <Icon icon="icon-park-solid:check-one" />
        <p>{text}</p>
    </div>
  )
}

export default Purchesbadge
