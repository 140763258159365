import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import ManageAssignmentComonent from "../../../components/Orgination/ManageAssignment/ManageAssignmentComonent";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const ManageAssignment = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <ManageAssignmentComonent />
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default ManageAssignment;
