import React, { useEffect, useState } from "react";
import { Clock } from "react-feather";
import { Link } from "react-router-dom";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
// import { THINKFIC_BASE_URL } from "../../../../apiConfigs/urlConfig";
import "./usersidecoursecard.scss";
import {
  enrollToThinkificCourse,
  loginToThinkificAndRedirect,
} from "../../../../store/features/Thinkific/ThikificApi";

function UserSideCourseCard(props: any) {
  const {
    title,
    desc,
    duration,
    slug,
    percentage,
    id,
    courseId,
    currSlug,
    setCurrSlug,
  } = props;
  const dispatch = useAppDispatch();
  // const [currId, setCurrId] = useState(0);

  const thinkificState: any = useAppSelector((store: RootStore) => {
    return store.ThinkificSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const startLearning = (slug: string, courseId: number, id: number) => {
    setCurrSlug(slug);
    dispatch(enrollToThinkificCourse({ courseId: courseId })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: slug,
        })
      ).then((res) => {
        if (res.payload) {
          console.log(res.payload.url);
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };

  return (
    <div className="user_side_course_card">
      <div className="cc-text">
        <h2 className="cc-title">
          <Link to={`/course-progress/${id}`}>{title}</Link>
        </h2>
        <div className="cc-desc">{desc}</div>
      </div>

      <div className="cc-bottom">
        <div className="cc-bottom-left">
          <Clock /> {duration}
        </div>
        {thinkificState.loading && currSlug === slug ? (
          <div className="spinner mr-1" />
        ) : (
          <button
            type="button"
            className="cc-bottom-right"
            onClick={() => startLearning(slug, courseId, id)}
          >
            {percentage < 100 && percentage > 0 && "Resume"}
            {percentage === 0 && "Start"}
            {percentage === 100 && <span>Completed</span>}
          </button>
        )}
      </div>
    </div>
  );
}

export default UserSideCourseCard;
