import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import UserLearningPathCourses from "../../../components/OrginationUser/UserLearningPathCourse/UserLearningPathCourses";

const OrgUserLearningPathCoursePage = () => {
  return (
    <AdminPanelLayout>
      <UserLearningPathCourses />
    </AdminPanelLayout>
  );
};

export default OrgUserLearningPathCoursePage;
