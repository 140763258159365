import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";

const validationSchema = yup.object().shape({
  first_name: yup.string().required(REQUIRED_VALIDATION("First Name")),
  last_name: yup.string().required(REQUIRED_VALIDATION("Last Name")),
  email: yup
    .string()
    .required(REQUIRED_VALIDATION("Email"))
    .email("Email is invalid"),
});

const formOptions: any = (showDuration:any) => {
  let schema = validationSchema;
  if (showDuration) {
    schema = schema.shape({
      duration_value: yup.string().nullable().required(REQUIRED_VALIDATION("Enroll to all the courses for")),
      duration_period: yup.object().required(REQUIRED_VALIDATION("Duration")),
    });
  }
  return {
    resolver: yupResolver(schema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
};

export default formOptions;
