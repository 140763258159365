import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { ROUTES } from "../../../../helpers/utils/Constants/route";
import { invoicePaginate } from "../../../../store/features/UserDashboard/Payment/PaymentSlice";
import UserProfileHeader from "../../../AdminPanel/userProfile/userProfileHeader";
import InvoiceHistory from "../../../UserDashboard/UserProfile/InvoiceHistory/InvoiceHistory";
import PaginateButton from "../../../../shared/PaginateButton/PaginateButton";
import SubscriptionsCard from "../SubscriptionsCard/SubscriptionsCard";
import BillingCard from "../../../UserDashboard/UserProfile/Subscriptions/BillingCard/BillingCard";

const OnwerProfileAllTab = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();

  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });
  useEffect(() => {
    if (params.tag === "profile") {
      setTabIndex(0);
    }

    if (params.tag === "invoice") {
      setTabIndex(1);
    }
  }, []);

  useEffect(() => {
    if (params.tag === "subscriptions") {
      //   setTabIndex(1);
    }
  }, [params.tag]);

  useEffect(() => {
    if (tabIndex === 0) {
      navigate(`${ROUTES.TENANT_OWNER_PROFILE}/profile`);
    }

    if (tabIndex === 1) {
      navigate(`${ROUTES.TENANT_OWNER_PROFILE}/invoice`);
    }
  }, [tabIndex]);

  const handlePagination = (num: number) => {
    dispatch(invoicePaginate(num));
  };
  return (
    <div className="all__profile__tab__container">
      <Tabs
        className="tab__container"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="tab__list__section">
          <Tab className="single__tab ">Profile</Tab>
          <Tab className="single__tab ml-4 ">Invoice History</Tab>
        </TabList>
        <TabPanel>
          <div className="tab__section_no_box">
            <UserProfileHeader />
            <SubscriptionsCard setShowModal={setShowModal} />
          </div>
        </TabPanel>

        <TabPanel>
          <div className="tab__section">
            <InvoiceHistory />{" "}
          </div>{" "}
          <div className="invoice__pagination__section">
            <PaginateButton
              paginate={handlePagination}
              totalRows={paymentState?.all_invoices?.length}
            />
          </div>
        </TabPanel>
      </Tabs>

      <BillingCard showModal={showModal} setShowModal={setShowModal} tenant />
    </div>
  );
};

export default OnwerProfileAllTab;
