/* eslint-disable no-unsafe-optional-chaining */

import React from "react";
import "./recentAnswerFaqHeading.scss";
import { ChevronLeft } from "react-feather";
import { RootStore, useAppSelector } from "../../../../../../store/store";

const RecentAnswerFaqHeading = (props: any) => {
  const { backToHomeTab } = props;

  const { recentlyAnsweredDetails } = useAppSelector((store: RootStore) => {
    return store.InterviewDetailsSlice;
  });

  const { name, avatar_url } = recentlyAnsweredDetails?.user;

  const user_avatar = name?.charAt(0);

  return (
    <div className="faq_container">
      <div className="headings">
        <div className="left_section">
          <ChevronLeft
            style={{ marginLeft: "-6px" }}
            onClick={() => backToHomeTab()}
          />

          {avatar_url ? (
            <img src={avatar_url} alt="userpic" />
          ) : (
            <div className="avatar__name">{user_avatar}</div>
          )}

          <h3>{name}</h3>
        </div>
        <div className="right_section">
          <div className="answerd__question">
            <p>
              Recently answered questions:{" "}
              {recentlyAnsweredDetails?.total_questions}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentAnswerFaqHeading;
