import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

export const getCohortsData = createAsyncThunk(
  "getCohortsData",
  async (
    undefined,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/page/next-cohort-date`
      );
      return res.data;
    } catch (error: any) {
    //   ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addOrUpdateCohorts = createAsyncThunk(
  "addOrUpdateCohorts",
  async (
    { data }: { data: any;},
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
        console.log(data);
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/page/next-cohort-date`,
        data
      );
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCohorts = createAsyncThunk(
  "deleteCohorts",
  async ({ id }: { id: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(
        `${dynamic_url}/page/next-cohort-date/${id}`
      );
      REQUIRED__TOASTER(res.data);

      res.data.id = id;

      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.response);
    }
  }
);
