import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const userApplicationTracker = createAsyncThunk(
  "userApplicationTracker",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(
        `${dynamic_url}/user-application-tracker`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
