/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import "./authLayout.scss";
import { useLocation } from "react-router-dom";

import { RootStore, useAppSelector } from "../../../../store/store";
import UseAuthThemeHook from "../../../../hooks/AuthThemeHook";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const AuthLayout = (props: any) => {
  const { children } = props;
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const theme = UseAuthThemeHook();

  const location = useLocation();
  const isLoginPage = location.pathname.includes("/") && configState.configs.membership_enabled === Domain.TAA ;


  const redirectToLandingPage = () => {
    window.location.href = configState?.configs?.site_url;
  };
  return (
    <div className="authlayout__container">
      <div className="logo__section">
        <img
          src={configState?.configs?.sidebar_open_logo}
          alt="agileAcademyLogo"
          onClick={redirectToLandingPage}
          role="button"
          aria-hidden
        />
      </div>
      <div className={`auth_card_section ${isLoginPage && "auth__card__section__wrapper"}`}>{children}</div>
    </div>
  );
};

export default AuthLayout;
