import { Tooltip } from "react-tooltip";
import "./index.scss";
import { RootStore, useAppSelector } from "../../../../store/store";
import { roles } from "../../../../helpers/utils/Constants/roles";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const Prompt = (props: any) => {
  const { question, fetchHistory, hashId, position } = props;
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <div className="propmt__card__wrapper">
      {" "}
      <div
        id={hashId}
        className={`prompt__card ${ authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA && "prompt__card__style "}`}
        role="button"
        aria-hidden
        onClick={() => fetchHistory(hashId, question)}
      >
        {" "}
        <span>{question} </span>
      </div>
      <Tooltip
        id={position}
        place="bottom"
        classNameArrow="custom-react-tooltip-arrow"
        className="custom-tool-tip"
      />
    </div>
  );
};

export default Prompt;
