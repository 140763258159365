/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Avatar from "../../../shared/Avatar/Avatar";
import "./learningPathSteppers.scss";
import arrowIcon from "../../../assets/icons/downarrow.svg";
import userIcon from "../../../assets/icons/user.png";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  enrollToThinkificCourse,
  loginToThinkificAndRedirect,
} from "../../../store/features/Thinkific/ThikificApi";
import { getCoursesFromBundle } from "../../../store/features/UserDashboard/LearningPath/LearningPathApi";
import { entollToCourse } from "../../../store/features/UserDashboard/ShowCourse/ShowCourseApi";
import { getUserRecentProgress } from "../../../store/features/UserDashboard/MyProgress/MyProgressApi";

import { TACPrimaryButton } from "../../../shared/Buttons/Buttons";

const LearningPathSteppers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathId } = useParams();
  const location: any = useLocation();
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const coursesState: any = useAppSelector((store: RootStore) => {
    return store.LearningPathSlice;
  });
  const showCoursesState: any = useAppSelector((store: RootStore) => {
    return store.ShowCourseSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const startLearning = (course: any) => {
    setSlug(course.slug);
    setLoading(true);
    dispatch(enrollToThinkificCourse({ courseId: course.course_id })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: course.slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          setLoading(false);
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };
  useEffect(() => {
    if (showCoursesState.enroll_success) {
      window.location.href = `${themeState.configs.site_url}/login/${authState.tac_user.email}/${slug}`;
    }
  }, [showCoursesState?.enroll_success]);

  useEffect(() => {
    dispatch(getUserRecentProgress());
    dispatch(getCoursesFromBundle({ bundleId: Number(pathId) }));
  }, []);

  return (
    <div className="LearningPathSteppers">
      <div className="stepper__container">
        <div className="course__title__section">
          <img
            src={arrowIcon}
            alt="."
            onClick={() => navigate(-1)}
            role="button"
            aria-hidden
          />
          <span className="course__title">{location.state.bundle_name}</span>
        </div>
        {!coursesState.bundle_course_loading &&
          coursesState.bundle_courses.map((course: any, index: any) => {
            return (
              <div className="stepper" key={course.course_id}>
                <span
                  className={`counter ${
                    course.percentage_completed > 0 ? "started" : ""
                  } ${
                    Math.floor(course.percentage_completed) === 100
                      ? "completed"
                      : ""
                  }`}
                >
                  {index + 1}
                </span>
                <div className="course__detail">
                  <span className="title">{course.name}</span>
                  <span className="duration">
                    {course.custom_duration ? "Duration " : "Duration"}
                    {course.custom_duration ? course.custom_duration : " - "}
                  </span>
                  <span className="description">{course.description}</span>
                  <div className="button_width_stepper">
                    {" "}
                    {Math.floor(course.percentage_completed) === 0 && (
                      <TACPrimaryButton
                        text="Start Course"
                        onClick={() => startLearning(course)}
                        loading={loading && slug === course.slug ? true : false}
                      />
                    )}
                    {Math.floor(course.percentage_completed) > 0 &&
                      Math.floor(course.percentage_completed) < 100 && (
                        <TACPrimaryButton
                          text="Resume Course"
                          onClick={() => startLearning(course)}
                          loading={loading && slug === course.slug ? true : false}
                        />
                      )}
                    {Math.floor(course.percentage_completed) === 100 && (
                      <TACPrimaryButton
                        text="Course Completed"
                        onClick={() => startLearning(course)}
                        loading={loading && slug === course.slug ? true : false}
                      />
                    )}
                  </div>
                  {coursesState.bundle_courses.length > index + 1 && (
                    <div className="stepper-divider" />
                  )}
                </div>
              </div>
            );
          })}
        {/* */}
      </div>
      <div className="stepper__right__container">
        <div className="stepper__top__section">
          {" "}
          <span className="second_course__title">
            {location.state.bundle_name}
          </span>
          <span className="total__courses">
            {" "}
            {location.state.totalcourse} Courses
          </span>
          <span className="course__description mt-1">
            Learn Agile from the ground up, including popular Agile frameworks;
            Scrum, Kanban, and Scrumban
          </span>
          <div className="divider" />
        </div>
        {/* <span className="title">INSTRUCTORS</span> */}
        {!coursesState.bundle_course_loading &&
          coursesState.bundle_instructors.map((data: any) => {
            return (
              <div className="instructor__profile" key={data.id + 10}>
                <div className="pic">
                  <Avatar
                    imageUrl={
                      data.avatar_url?.startsWith("https", 0)
                        ? data.avatar_url
                        : userIcon
                    }
                  />
                </div>
                <div className="info">
                  <span className="name">
                    {data.first_name} {data.last_name}
                  </span>
                  <span className="occupation"> Instructor</span>
                </div>
              </div>

            );
          })}
      </div>
    </div>
  );
};

export default LearningPathSteppers;
