import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import "./index.scss";
import InterviewsAndWebinarsBox from "./InterviewsAndWebinarsBox";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { getInterviewAndWebminarVideos } from "../../../store/features/UserDashboard/InterviewAndWebminar/InterviewAndWebminarApi";
import VideoModal from "./VideoModal";
import { searchVideoInIAW } from "../../../store/features/UserDashboard/InterviewAndWebminar/InterviewAndWebminarSlice";
import CustomEmptyComp from "../../../shared/Empty/component/CustomEmptyComp";

function ListInterviewsAndWebinars(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [video, setVideo] = useState<any>();
  const dispatch = useAppDispatch();
  const webminar: any = useAppSelector((store: RootStore) => {
    return store.InterviewAndWebminarSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  useEffect(() => {
    dispatch(getInterviewAndWebminarVideos());
  }, []);

  useEffect(() => {
    dispatch(searchVideoInIAW(searchText));
  }, [searchText]);

  const openVideoInModal = (data: any) => {
    setVideo(data.player_embed_url);
    setShowModal(true);
  };

  return (
    <div className={`interviews-and-webinars-container ${configState?.configs?.tenant_name !== null ? "handle__margin" : "  mt-6"}`}>
      <div className="iw-search">
        <SearchBar value={searchText} setValue={setSearchText} />
      </div>
      <div className="iw-section">
        {webminar.req_success.get_videos &&
          webminar.videos.map((video: any) => {
            return (
              <InterviewsAndWebinarsBox
                key={video.link}
                data={video}
                onClick={openVideoInModal}
              />
            );
          })}
      </div>

      {webminar.req_success.get_videos && (
        <CustomEmptyComp
          search={searchText.length > 1 && webminar.videos.length === 0}
          empty={webminar.videos.length === 0}
          tac
        />
      )}
      <VideoModal
        showModal={showModal}
        setShowModal={setShowModal}
        videoLink={video}
        setVideo={setVideo}
      />
    </div>
  );
}

export default ListInterviewsAndWebinars;
