import "./index.scss";
import logo from "../../../../assets/images/half-logo.png";
import { RootStore, useAppSelector } from "../../../../store/store";

const MessageLoading = (props: any) => {
  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  return (
    <div className="center__messages">
      <div className=" message__section ai">
        <div
          className={`center__item loader_item  ${
            aiState.loaderId && "margin_top"
          }`}
        >
          <div className="image__avatar">
            <img src={logo} alt="" />
          </div>
          <div className="snippet" data-title="dot-carousel">
            <div className="stage">
              <div className="dot-carousel" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageLoading;
