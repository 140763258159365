import { LogOut } from "react-feather";
import { PrimaryTextButton } from "../../../shared/Buttons/Buttons";
import { getBase64, getFileExtension } from "../../../helpers/utils/FileHelper";

const FileUploader = (props: any) => {
  const {
    title,
    subTitle,
    description,
    fileInfo,
    onclick,
    inputRef,
    setFileInfo,
  } = props;

  const getFile = async (e: any) => {
    const contentFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(contentFile);
    const extension = getFileExtension(contentFile.name);
    const base64Image: any = await getBase64(contentFile);
    setFileInfo({ imageUrl, extension, base64Image });
  };

  return (
    <div className="sm__logo__upload__container">
      <span> {title}</span>

      <div>
        <div className="file__upload__section">
          <div className="file_fieldd ">
            {!fileInfo && (
              <div
                className="file_input_design"
                onClick={onclick}
                role="button"
                aria-hidden
              >
                <LogOut className="logout_icon" />
                <p className="clik_text">{subTitle}</p>
                <span className="sm__img__size__recomemded">{description}</span>
              </div>
            )}
            {fileInfo && (
              <div className="image__section">
                <img
                  src={fileInfo.imageUrl}
                  alt="logo"
                  className="logo__image"
                />
                <div className="button__section">
                  <PrimaryTextButton
                    type="button"
                    text="Upload New logo"
                    tac
                    onClick={onclick}
                  />
                </div>
              </div>
            )}

            <input
              className="filefield"
              type="file"
              name="image"
              accept="image/*"
              ref={inputRef}
              onChange={getFile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
