import { Edit2, Trash } from "react-feather";
import "./index.scss";

const TacTable = (props: any) => {
  const { children, heading } = props;

  return (
    <div className="tac__table__container">
      <table>
        {heading}
        {children}
      </table>
    </div>
  );
};

export default TacTable;

export const TacTableHeading = (props: any) => {
  const { children } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    // <>
    <thead>
      <tr>{children}</tr>
    </thead>
    // </>
  );
};

export const TacTableBody = (props: any) => {
  const { children } = props;

  return <tbody>{children}</tbody>;
};

export const TacTableAction = (props: any) => {
  const { onEdit, onDelete, padding, mt2 } = props;

  return (
    <td className={`tac__action__icons ${padding ? "increse_padding" : ""} `}>
      <Edit2 className="edit__icon" onClick={onEdit} />
      {/* <Trash className="delete__icon" onClick={onDelete} /> */}
    </td>
  );
};
