import React from "react"
import "./productmanagerArchiveTimeTable.scss";
import ArchiveTable from "../../../Archive/ArchiveTable";

const ProductManagerArchiveTimeTable = () => {
  return (
    <div className="product__manager__archive__table__wrapper">
        <div className="product__manager__archive__table__heading">
            <span>Class Archive</span>
            <p>Vestibulum eget est nec ipsum convallis scelerisque a sed mi. Morbi commodo, velit non malesuada convallis, dolor velit.</p>
        </div>
      <ArchiveTable/>
    </div>
  )
}

export default ProductManagerArchiveTimeTable
