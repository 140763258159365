/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { Download } from "react-feather";
import EmptylearningResourceComp from "../EmptyLearningResource/EmptylearningResourceComp";
import "./learningresource.scss";
import zipIcon from "../../../../assets/images/zip.svg";
import folderIcon from "../../../../assets/images/folder.svg";
import imageIcon from "../../../../assets/images/image.svg";
import pdfIcon from "../../../../assets/images/pdf.svg";
import docIcon from "../../../../assets/images/word.svg";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  getDataInsideFolder,
  getResourcesForUser,
} from "../../../../store/features/UserDashboard/Resources/UserResourceApi";
import {
  clearNotificationDataForUserResource,
  setActiveSlice,
  setCleanScreen,
  setNestedFolderId,
} from "../../../../store/features/UserDashboard/Resources/UserResourceSlice";

const LearningResourcesComp = () => {
  const dispatch = useAppDispatch();
  const isMounted = useRef(false);

  const resourceState: any = useAppSelector((store: RootStore) => {
    return store.UserResourceSlice;
  });

  const loadDataFromNotification = () => {
    dispatch(
      getDataInsideFolder({ id: resourceState.notification_data.parent_id })
    );
    const folderStack = resourceState.notification_data.parents?.map(
      (item: any) => {
        return { id: item.id, name: item.resource_name };
      }
    );
    setFolderStack(folderStack.reverse());
    setNestedFolder(resourceState.notification_data.parents[0].resource_name);
    dispatch(clearNotificationDataForUserResource());
  };

  useEffect(() => {
    if (!isMounted.current) {
      if (
        resourceState.notification_data &&
        resourceState.notification_data.parent_id
      ) {
        loadDataFromNotification();
      } else {
        dispatch(getResourcesForUser());
      }
    } else {
      isMounted.current = true;
    }
  }, []);

  useEffect(() => {
    if (resourceState.notification_data) {
      loadDataFromNotification();
    }
  }, [resourceState.notification_data]);

  const [nestedFolder, setNestedFolder] = useState("");
  const [folderStack, setFolderStack] = useState<any>([]);

  const getDataInsideFolder2 = (data: any) => {
    if (data.resource_type !== "file") {
      dispatch(setCleanScreen());
      setFolderStack([
        ...folderStack,
        { id: data.id, name: data.resource_name },
      ]);
      setNestedFolder(data.resource_name);
      dispatch(setNestedFolderId(data.id));
      dispatch(getDataInsideFolder({ id: data.id }));
    }
  };

  const onButtonClick = (data: any) => {
    fetch(data?.file_path).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${data.resource_name}.${data.resource_extension}`;
        alink.click();
      });
    });
  };

  const onClickBreadcrumb = (data: any) => {
    dispatch(setCleanScreen());
    const index = folderStack.findIndex((item: any) => item.id === data.id);
    const newFolderStack = folderStack.slice(0, index + 1);
    setFolderStack(newFolderStack);
    setNestedFolder(data.name);
    dispatch(getDataInsideFolder({ id: data.id }));
  };

  const clearStack = () => {
    dispatch(setCleanScreen());
    setFolderStack([]);
    setNestedFolder("");
    dispatch(getResourcesForUser());
  };

  const openPdfInNewTab = (data: any) => {
    if (
      data.resource_extension.toLowerCase() === "rar" ||
      data.resource_extension.toLowerCase() === "zip"
    ) {
      return;
    }
    if (
      data.resource_extension.toLowerCase() !== "docx" &&
      data.resource_extension.toLowerCase() !== "doc"
    ) {
      dispatch(setActiveSlice(0));

      window.open(data.file_path, "_blank");
    }
    if (
      data.resource_extension.toLowerCase() === "docx" ||
      data.resource_extension.toLowerCase() === "doc"
    ) {
      dispatch(setActiveSlice(0));

      window.open(
        `https://view.officeapps.live.com/op/embed.aspx?src=${data?.file_path}`,
        "_blank"
      );
    }
  };

  
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  return (
    <div className="Learning">
      <div className="learning_content">
        <div className="heading">
          {!nestedFolder && <p>Learner Resources</p>}
          {!resourceState.loading && nestedFolder && (
            <>
              <p className="blue pointer" onClick={clearStack}>
                Learner Resources
              </p>
              {folderStack.map((item: any, index: number) => {
                if (index !== folderStack.length - 1) {
                  return (
                    <p
                      className="blue pointer"
                      onClick={() => onClickBreadcrumb(item)}
                      key={folderStack.id}
                    >
                      {item.name}
                    </p>
                  );
                }
                return <p key={folderStack.id}>{item.name}</p>;
              })}
            </>
          )}
        </div>
        <div className="learning_item">
          {!resourceState.loading &&
            resourceState.first_page_data &&
            resourceState.first_page_data.map((item: any) => (
              <div className="box">
                <div className="box_content">
                  <div className="content">
                    <div className="icon">
                      {(item.resource_extension === "jpg" ||
                        item.resource_extension === "png" ||
                        item.resource_extension === "jpeg") && (
                        <img src={imageIcon} alt="" />
                      )}
                      {(item.resource_extension === "docx" ||
                        item.resource_extension === "doc") && (
                        <img src={docIcon} alt="" />
                      )}
                      {item.resource_extension === "pdf" && (
                        <img src={pdfIcon} alt="" />
                      )}
                      {(item.resource_extension === "zip" ||
                        item.resource_extension === "rar") && (
                        <img src={zipIcon} alt="" />
                      )}

                      {item.resource_type !== "file" && (
                        <img src={folderIcon} alt="" />
                      )}
                    </div>
                    <div className="title">
                      <p
                        className="resource_name"
                        onDoubleClick={(e) => {
                          if (item.resource_type === "file") {
                            openPdfInNewTab(item);
                          } else {
                            getDataInsideFolder2(item);
                          }
                        }}
                        data-tooltip-id="tool-tip"
                        data-tooltip-content={
                          `${item.resource_name}${
                            item.resource_extension
                              ? `.${item.resource_extension} `
                              : ""
                          }`.length > 20
                            ? `${item.resource_name}${
                                item.resource_extension
                                  ? `.${item.resource_extension}`
                                  : ""
                              }`
                            : ""
                        }
                      >
                        {`${item.resource_name}${
                          item.resource_extension
                            ? `.${item.resource_extension}`
                            : ""
                        }`}
                      </p>
                      <span>
                        {item.files_size}
                        {(item.resource_type !== "file" ||
                          item.resource_extension === "zip") &&
                          `, ${item.inside_files_count} items`}
                      </span>
                    </div>
                  </div>

                  <div className="modified">
                    <p>Last modified: {item.last_modified}</p>
                    {item.resource_type === "file" && (
                      <Download
                        className="download"
                        onClick={() => onButtonClick(item)}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          {!resourceState.loading &&
            resourceState.first_page_data.length === 0 && (
              <EmptylearningResourceComp />
            )}
          <Tooltip
            id="tool-tip"
            place="bottom"
            classNameArrow="custom-react-tooltip-learning-arrow"
            className="custom-tool-tip-learning"
            // events={["click"]}
          />
        </div>
      </div>
    </div>
  );
};

export default LearningResourcesComp;
