/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import { Edit2, Trash } from "react-feather";

import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../../store/store";
import {
  deleteClasses,
  loadClasses,
} from "../../../../store/features/UserDashboard/OurClasses/OurClassApi";
import "./index.scss";
import Table, { TableHeading } from "../../../../shared/Table/Table";
import Empty from "../../../../shared/Empty/Empty";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import { editClassInfo } from "../../../../store/features/UserDashboard/OurClasses/OurClassSlice";

const OurClassesContent = (props: any) => {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [currId, setCurrId] = useState<Number>();

  const dispatch = useAppDispatch();
  const classState: any = useAppSelector((store: RootStore) => {
    return store.OurClassSlice;
  });

  const handleDelete = () => {
    dispatch(deleteClasses(currId!));

    setDeleteShowModal(false);
  };

  const editClass:any = (cls: any) => {
    dispatch(editClassInfo(cls));
  };

  // useEffect(()=>{
  //   if(!classState?.loading && classState?.req_success){
  //     dispatch(loadClasses());
  //   }
  
  // },[classState?.req_success ,classState?.loading])


  return (
    <div>
      <Table
        heading={
          <TableHeading>
            <th>Date</th>
            <th>Time</th>
            <th>Guaranteed to run</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <tbody>
        {!classState.loading &&
          classState.req_success &&
          classState?.classes.map((clas: any, index: number) => {
            return (
              clas.sessions.length > 0 && <tr className="session__tr" key={clas.id}>
                <td className="oc__date__td">{clas.sessions[0].session_formatted_date}</td>
                
                <td className="oc__session__td">
                  {clas.sessions.map((session: any, index: number) => {
                    return (
                      <div className="session__box" key={session.id}>
                        <p className="session__date">{`"Session ${index + 1}" on ${session.session_formatted_date1}`}</p>
                        <p className="session__time">{session.session_formatted_start_time} - {session.session_formatted_end_time} ({clas.timezone})</p>
                      </div>
                    );
                  })}
                </td>
                <td className="oc__confirm__td">{clas.guaranteed}</td>

                <td className="action__wrapper oc__action__wrapper ">
                  <div className="action__container">
                    {" "}
                    <Edit2
                      className="edit__icon"
                      onClick={() => editClass(clas)}
                      
                    />
                    <Trash
                      className="delete__icon"
                      onClick={() => {
                        setDeleteShowModal(true);
                        setCurrId(clas.id);
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      {!classState.loading &&
        classState.req_success &&
        classState?.classes.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty
              emptyText="It seems empty."
              emptyColorFulText="Add Classes"
              emptyText2="now."
              custombg={true}
            />
          </div>
        )}

      <div className="pagination">{/* <PaginateButton /> */}</div>

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        onClick={handleDelete}
      />
    </div>
  );
};

export default OurClassesContent;
