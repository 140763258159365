import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import { REQUIRED__TOASTER } from "../../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const inviteMembers = createAsyncThunk(
  "inviteMembers",
  async (
    { data, setDublicateEmail }: { data: any; setDublicateEmail: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/users`, data);
      setDublicateEmail({});
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      if (error?.response?.data?.errors) {
        setDublicateEmail(error.response.data.errors);
      }
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getAllMembers = createAsyncThunk(
  "getAllMembers",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/users`);
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRemaningUsers = createAsyncThunk(
  "getRemaningUsers",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/users/remaining`);
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const resendInvitation = createAsyncThunk(
  "resendInvitation",
  async (
    { userId }: { userId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/resend-invitation/${userId}`
      );

      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMemberDetails = createAsyncThunk(
  "getMemberDetails",
  async (
    { memberId }: { memberId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/users/${memberId}`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const editMember = createAsyncThunk(
  "editMember",
  async (
    { data, memberId }: { data: object; memberId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.put(
        `${dynamic_url}/users/${memberId}`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const Deletemember = createAsyncThunk(
  "Deletemember",
  async (
    { memberId }: { memberId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(
        `${dynamic_url}/users/${memberId}`
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const courseAssismentUpdateDadeline = createAsyncThunk(
  "courseAssismentUpdateDadeline",
  async (
    { data, memberId }: { data: object; memberId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/users/${memberId}/update-deadline`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteIndividualAssignment = createAsyncThunk(
  "deleteIndividualAssignment",
  async (
    { id, data }: { id: number; data: object },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/users/${id}/delete-assignment`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMembersAssignments = createAsyncThunk(
  "getMembersAssignments",
  async ({ id }: { id: number }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/users/${id}/assignments`
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAssignment = createAsyncThunk(
  "addAssignment",
  async (
    { id, data }: { id: number; data: object },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/users/add-assignment/${id}`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
