import { createSlice } from "@reduxjs/toolkit";
import {
  addOrUpdateDateTime,
  deleteDateTime,
  getDateTime,
} from "./DateTimeApi";

const initialState = {
  dateTime: null,
  isEdit: false,
  get: {
    loading: false,
  },
  save: {
    loading: false,
  },
  delete: {
    loading: false,
  },
};

export const dateTimeSlice = createSlice({
  name: "dateTime",
  initialState,
  reducers: {
    setIsEdit: (state, { payload }) => {
      state.isEdit = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDateTime.pending, (state) => {
      state.get.loading = true;
    });
    builder.addCase(getDateTime.fulfilled, (state, { payload }) => {
      state.get.loading = false;
      state.dateTime = payload.data;
    });
    builder.addCase(getDateTime.rejected, (state) => {
      state.get.loading = false;
    });
    builder.addCase(addOrUpdateDateTime.pending, (state) => {
      state.save.loading = true;
    });
    builder.addCase(addOrUpdateDateTime.fulfilled, (state, { payload }) => {
      state.save.loading = false;
      state.dateTime = payload.data;
    });
    builder.addCase(addOrUpdateDateTime.rejected, (state) => {
      state.save.loading = false;
    });
    builder.addCase(deleteDateTime.pending, (state) => {
      state.delete.loading = true;
    });
    builder.addCase(deleteDateTime.fulfilled, (state, { payload }) => {
      state.delete.loading = false;
      state.dateTime = null;
    });
    builder.addCase(deleteDateTime.rejected, (state) => {
      state.delete.loading = false;
    });
  },
});

export default dateTimeSlice.reducer;
export const { setIsEdit } = dateTimeSlice.actions;
