import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import {
  getInterviewUsers,
  getInterviewAnswerByRoleId,
  addOrUpdateAnswer,
  deleteFeedbackFromAnswers,
  deleteVideoFromAnswer,
  getPresignedUrlForAdminAudioUpload,
  uploadAdminAudioToAws,
  updateShareLink
} from "./InterviewAnswerApi";

const initialState: any = {
  loading: {
    getUser: false,
    get: false,
    add: false,
    delete: false,
    delete_video: false,
    audio_presigned_url: false,
    upload_audio: false,
  },
  req_success: {
    getUser: false,
    get: false,
    add: false,
    delete: false,
    update_url: false,
    delete_video: false,
    audio_presigned_url: false,
    upload_audio: false,
  },
  edit: false,
  active_feedback: {},
  all_users: [],
  users: [],
  total_question: 0,
  user: {},
  role: {},
  question_answers: [],
  all_question_answers: [],
  video_id_for_delete: null,
  temp_answers: [],

  audio_presigned_url: "",
  notificationData: null,
};

const AdminInterview = createSlice({
  name: "AdminInterview",
  initialState,
  reducers: {
    editInterview: (state: any, { payload }: PayloadAction<any>) => {
      state.edit = true;
      state.singleData = payload;
    },
    closeInterviewModal: (state: any) => {
      state.edit = false;
      state.singleData = {};
    },
    setActiveFeedbackForAction: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      state.active_feedback = payload;
    },

    searchInterviewUsers: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.users = state.all_users.filter((user: any) =>
          user.name.toLowerCase().includes(payload.toLowerCase())
        );
      } else {
        state.users = state.all_users;
      }
    },
    setVideoIdForDelete: (state: any, { payload }: PayloadAction<any>) => {
      state.video_id_for_delete = payload;
    },
    setNotificationDataForInterviewAnswer: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      state.notificationData = payload;
    },

    setNotificableQuestionTotop: (state: any) => {
      if (state.notificationData) {
        const clickedData = state.all_question_answers.filter(
          (question: any) => {
            return question.id === state.notificationData?.question_id;
          }
        );
        const remaningData = state.all_question_answers.filter(
          (question: any) => {
            return question.id !== state.notificationData?.question_id;
          }
        );
        state.question_answers = [...clickedData, ...remaningData];
      } else {
        state.question_answers = state.all_question_answers;
      }
    },

    clearInterviewAnswerSliceData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    // // ADD LEARNING PATH

    // GET ALL INTERVIEW USERS
    builder.addCase(getInterviewUsers.pending, (state) => {
      state.loading.getUser = true;
      state.req_success.getUser = false;
    });
    builder.addCase(
      getInterviewUsers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.getUser = false;
        state.req_success.getUser = true;
        state.all_users = payload?.data;
        state.users = payload?.data;
      }
    );
    builder.addCase(getInterviewUsers.rejected, (state) => {
      state.loading.getUser = false;
    });

    // GET ALL QUESTIONS AND ANSWERS BY ROLE ID
    builder.addCase(getInterviewAnswerByRoleId.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
      state.req_success.audio_presigned_url = false;
      state.req_success.upload_audio = false;
      state.audio_presigned_url = "";
    });
    builder.addCase(
      getInterviewAnswerByRoleId.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.user = payload.data.user;
        state.role = payload.data.role;
        state.all_question_answers = payload.data.question_answers;
        if (state.notificationData) {
          const clickedData = payload.data.question_answers.filter(
            (question: any) => {
              return question.id === state.notificationData?.question_id;
            }
          );
          const remaningData = payload.data.question_answers.filter(
            (question: any) => {
              return question.id !== state.notificationData?.question_id;
            }
          );
          state.question_answers = [...clickedData, ...remaningData];
        } else {
          state.question_answers = payload.data.question_answers;
        }
        // state.question_answers = payload.data.question_answers;
        state.total_question = payload.data.question_answers.length;
      }
    );
    builder.addCase(getInterviewAnswerByRoleId.rejected, (state) => {
      state.loading.get = false;
    });

    // ADD OR UPDATE answers
    builder.addCase(addOrUpdateAnswer.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      addOrUpdateAnswer.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
      }
    );
    builder.addCase(addOrUpdateAnswer.rejected, (state) => {
      state.loading.add = false;
    });

    // UPDATE share url
    builder.addCase(updateShareLink.pending, (state) => {
      state.req_success.update_url = false;
    });
    builder.addCase(
      updateShareLink.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.req_success.update_url = true;
        state.all_question_answers = state.all_question_answers.map((question: any) => {
          if (question.id === payload.question_id) {
            question.answers.map((answer: any) => {
              if (answer.id === payload.answer_id) {
                answer.shareable_link = payload.url;
              }
              return answer;
            })
          }
          return question;
        });
        state.question_answers = state.all_question_answers;
      }
    );

    // DELETE FEEDBACK FORM ASNWERS
    builder.addCase(deleteFeedbackFromAnswers.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteFeedbackFromAnswers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
      }
    );
    builder.addCase(deleteFeedbackFromAnswers.rejected, (state) => {
      state.loading.delete = false;
    });

    // DELETE VIDEO FORM ASNWERS
    builder.addCase(deleteVideoFromAnswer.pending, (state) => {
      state.loading.delete_video = true;
      state.req_success.delete_video = false;
    });
    builder.addCase(
      deleteVideoFromAnswer.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete_video = false;
        state.req_success.delete_video = true;
      }
    );
    builder.addCase(deleteVideoFromAnswer.rejected, (state) => {
      state.loading.delete_video = false;
    });

    // GET PRESIGNED URL FOR AUDIO UPLOAD
    builder.addCase(getPresignedUrlForAdminAudioUpload.pending, (state) => {
      state.loading.audio_presigned_url = true;
      state.req_success.audio_presigned_url = false;
    });
    builder.addCase(
      getPresignedUrlForAdminAudioUpload.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.audio_presigned_url = false;
        state.req_success.audio_presigned_url = true;
        state.audio_presigned_url = payload.presignedUrl;
      }
    );
    builder.addCase(getPresignedUrlForAdminAudioUpload.rejected, (state) => {
      state.loading.audio_presigned_url = false;
    });

    //  AUDIO UPLOAD TO AWS
    builder.addCase(uploadAdminAudioToAws.pending, (state) => {
      state.loading.upload_audio = true;
      state.req_success.upload_audio = false;
    });
    builder.addCase(
      uploadAdminAudioToAws.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.upload_audio = false;
        state.req_success.upload_audio = true;
      }
    );
    builder.addCase(uploadAdminAudioToAws.rejected, (state) => {
      state.loading.upload_audio = false;
    });
  },
});
export const {
  editInterview,
  closeInterviewModal,
  searchInterviewUsers,
  setActiveFeedbackForAction,
  setVideoIdForDelete,
  setNotificationDataForInterviewAnswer,
  setNotificableQuestionTotop,
  clearInterviewAnswerSliceData,
} = AdminInterview.actions;
export default AdminInterview.reducer;
