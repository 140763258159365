import React, { useState } from "react";
import "./mobileAlltabs.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import mResetIcon from "../../../../../../assets/images/reset.svg";
import MobileViewChatbotChattingArea from "../MobileViewChatbotChattingArea/MobileViewChatbotChattingArea";
import MobileViewRecentChat from "../MobileViewRecentChat/MobileViewRecentChat";
import MobileViewChatMessageTextArea from "../MobileViewChatTextArea/MobileViewChatMessageTextArea";

const MobileChatbotAllTabs = () => {
  const [newChat, setNewChat] = useState(true);
  const [recentChat, setRecentChat] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <div className="mobile__chatbot__container__wrapper">
      <Tabs
        className="mobile__chatbot__container"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="mobile__chatbot__tab__list__section">
          <Tab className="mobile__chatbot__single__tab ">
            <div className="new__chat">
              <span>Chat</span>
            </div>
          </Tab>
          <Tab className="mobile__chatbot__single__tab m__recent__chat__width__handle">
            <div className="new__chat">
              <span>Recent Conversation</span>
            </div>
          </Tab>
        </TabList>
        <TabPanel>
          <MobileViewChatbotChattingArea />
          <div className="mobile__chat__input__area__section">
            <MobileViewChatMessageTextArea />
          </div>
        </TabPanel>
        <TabPanel>
          <MobileViewRecentChat setTabIndex={setTabIndex} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default MobileChatbotAllTabs;
