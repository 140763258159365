/* eslint-disable func-names */
import { useEffect, useRef, useState } from "react";
import Pusher from "pusher-js";
import { useLocation, useNavigate } from "react-router-dom";

import { Bell } from "react-feather";

import NotificationComp from "./component/NotificationComp";
import { RootStore, useAppDispatch, useAppSelector } from "../../store/store";
import { AUTH_URL } from "../../apiConfigs/urlConfig";
import { REQUIRED__TOASTER } from "../../helpers/utils/TosterError";
import {
  getNotificationsList,
  markAllNotificationsAsRead,
  markNotificationsAsRead,
} from "../../store/features/Notifications/NotificationApi";
import "./index.scss";
import { renderDynamicUrlFromComponentLevelState } from "../../helpers/utils/urlHelper";
import { ROUTES } from "../../helpers/utils/Constants/route";
import { TAGS } from "../../helpers/utils/Constants/tag";
import { setNotificationDataForInterviewAnswer } from "../../store/features/AdminPanel/Interview/Answer/InterviewAnswerSlice";
import { roles } from "../../helpers/utils/Constants/roles";
import { setUserSigngleNotificatioData } from "../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationSlice";
import { setNotificationDataForUserResource } from "../../store/features/UserDashboard/Resources/UserResourceSlice";

const Notification = (props:any) => {
  const {bellIconColor} = props;
  const [showNotificationBox, setShowNotificationBox] = useState(false);
  const bellIconRef: any = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const notificationState: any = useAppSelector((store: RootStore) => {
    return store.NotificationSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewAnswerSlice;
  });
  const dynamic_url = renderDynamicUrlFromComponentLevelState(themeState);

  useEffect(() => {
    const options = {
      broadcaster: "pusher",
      key: "d880ca5fe89ba8426cd5",
      cluster: "ap2",
      forceTLS: true,
    };

    const token = localStorage.getItem("access_token");
    const pusher = new Pusher(options.key, {
      cluster: options.cluster,
      forceTLS: options.forceTLS,
      authEndpoint: `${dynamic_url}/broadcasting/auth`,
      auth: {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    });

    const channel1 = pusher.subscribe(
      `private-App.User.${authState?.tac_user?.id}`
    );

    channel1.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      function (data: any) {
        // REQUIRED__TOASTER({
        //   success: true,
        //   message: data.message.replace(/<\/?[^>]+(>|$)/g, ""),
        // });
        dispatch(getNotificationsList({ page: 1, type: "all", filter: true }));
      }
    );

    dispatch(getNotificationsList({ page: 1, type: "all", filter: true }));
  }, []);

  useEffect(() => {
    // call every time when admin or mentor delete student answer recorded video from interivew prerp  ansewer section
    if (InterviewState.req_success.delete_video) {
      dispatch(getNotificationsList({ page: 1, type: "all", filter: true }));
    }
  }, [InterviewState.req_success.delete_video]);

  const updateAllNotificationStatus = () => {
    dispatch(markAllNotificationsAsRead());
  };

  const notificationHandelForAdmin = (notification: any) => {
    dispatch(setNotificationDataForInterviewAnswer(notification));
    navigate(`${ROUTES.INTERVIEW}/${TAGS.INTERVIEW_ANSWER}`);
  };

  const notificationHandelForUser = (notification: any) => {
    dispatch(setUserSigngleNotificatioData(notification));
    if (notification.of === "interview") {
      navigate(`${ROUTES.INTERVIEW_PREPRATION}`, {
        state: { roleId: notification.interview_role_id },
      });
    } else if (notification.of === "class_archive") {
      navigate(`${ROUTES.ARCHIVES}`);
    } else if (notification.of === "reference") {
      navigate(`${ROUTES.USER_REFERENCE}`);
    } else if (notification.of === "learning_resource") {
      dispatch(setNotificationDataForUserResource(notification));
      navigate(`${ROUTES.USER_LEARNING_RESOURCES}`);
    }
  };

  const notificationHandelForMentor = (notification: any) => {
    dispatch(setNotificationDataForInterviewAnswer(notification));
    // navigate(`${ROUTES.MENTOR_INTERVIEW}`);
    navigate(`${ROUTES.MENTOR_INTERVIEW}/${TAGS.INTERVIEW_ANSWER}`);
  };

  const updateNotificationStatus = (notification: any) => {
    setShowNotificationBox(false);
    dispatch(markNotificationsAsRead({ id: notification.id }));

    if (authState.tac_user?.role === roles.SYSTEM_ADMIN) {
      notificationHandelForAdmin(notification);
    }

    if (authState.tac_user?.role === roles.MENTOR) {
      notificationHandelForMentor(notification);
    }

    if (authState.tac_user?.role === roles.USER) {
      notificationHandelForUser(notification);
    }
  };

  const handleFilter = (type: any) => {
    dispatch(getNotificationsList({ page: 1, type: type, filter: true }));
  };

  const getScrollNotificationData = (page: any, type: any) => {
    dispatch(getNotificationsList({ page: page, type: type, filter: false }));
  };
  return (
    <div className="bell__icon__cotainer" ref={bellIconRef}>
      <div
        role="button"
        aria-hidden
        className="bell__icon"
        onClick={() => setShowNotificationBox(!showNotificationBox)}
      >
        <Bell className={`bell  ${
                themeState.themeMode === "light" && bellIconColor ?  "active" :"" }   ${
                  authState?.tac_user?.role !== roles.USER &&  "non__active" } ${!location.pathname.includes("/academic-user-dashboard") && !location.pathname.includes("/product-manager-user-dashboard") && "bellIconColor"}` } />
        {notificationState.unreadNotificationCount > 0 && (
          <span className="notification__count">
            {notificationState.unreadNotificationCount > 9
              ? "9+"
              : notificationState.unreadNotificationCount}
          </span>
        )}
      </div>
      {showNotificationBox && (
        <NotificationComp
          notificationState={notificationState}
          handleFilter={handleFilter}
          getScrollNotificationData={getScrollNotificationData}
          updateAllNotificationStatus={updateAllNotificationStatus}
          updateNotificationStatus={updateNotificationStatus}
          setShowNotificationBox={setShowNotificationBox}
          bellIconRef={bellIconRef}
          authState={authState}
        />
      )}
    </div>
  );
};

export default Notification;
