import React from "react";
import "./index.scss";

function Error404(props: any) {
  return (
    <div className="error404page-container">
      <h1>404</h1>
      <p>The page you are looking for doesn’t exist!</p>
      <a href="/">Back To Home page</a>
    </div>
  );
}

export default Error404;
