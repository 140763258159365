/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prettier/prettier */
import "./seobody.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { LogOut } from "react-feather";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  getBase64,
  getFileExtension,
} from "../../../../helpers/utils/FileHelper";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import ArrayList from "../../../../shared/Inputs/ArrayList/ArrayList";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import {
  getSiteSettings,
  updateSiteSettings,
} from "../../../../store/features/Site/SiteApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

function SEOBody(props: any) {
  const dispatch = useAppDispatch();
  const siteSettingsState: any = useAppSelector((store: RootStore) => {
    return store.SiteSettingsSlice;
  });
  const [dataArray, setDataArray] = useState<any>([]);
  const [imageExt, setImageExt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageError, setImageError] = useState("");
  const hiddenFileInput = useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const removeImage = () => {};
  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];
    if (contentFile.size > 2 * 1024 * 1024) {
      setImageError("Image size must be less than 2 MB");
      return;
    }
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageError("");
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required(REQUIRED_VALIDATION("Site Title")),
    description: yup.string().required(REQUIRED_VALIDATION("Site Description")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    setValue,
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    let data;

    if (!imageFile) {
      if (!siteSettingsState.data.image) {
        setImageError("Image is required");
        return;
      }
      data = {
        ...values,
        description: values.description,
        keywords: dataArray.join(","),
      };
    }
    if (imageFile) {
      data = {
        ...values,
        image: imageFile,
        extension: imageExt,
        description: values.description,
        keywords: dataArray.join(","),
      };
    }

    dispatch(updateSiteSettings(data));
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const loadData = () => {
    if (!siteSettingsState?.loading) {
      setImageUrl(siteSettingsState.data.image);
      setValue("title", siteSettingsState.data.title);
      setValue("description", siteSettingsState.data.description);
    }
  };
  useEffect(() => {
    loadData();
  }, [siteSettingsState?.loading]);

  useEffect(() => {
    dispatch(getSiteSettings());
  }, []);
  useEffect(() => {
    if (siteSettingsState.req_success) {
      setDataArray(siteSettingsState.data?.keywords?.split(","));
    }
  }, [siteSettingsState.req_success]);

  return (
    <form className="seo_body" onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="seorow">
        <div className="seor1">
          <TextField
            label="Site Title"
            name="title"
            type="text"
            register={register}
            // error={errors?.title}
          />
          <ArrayList
            label="Site Keyword"
            dataArray={dataArray}
            updateDataArray={setDataArray}
          />
        </div>
        <div className="seor2">
          <div className={`file_fieldd ${imageError ? "error" : ""}`}>
            {!imageUrl && (
              <div className="file_input_design">
                <span onClick={handleClick}>
                  <span style={{ display: "flex", justifyContent: "center" }}>
                    <LogOut className="logout_icon" />
                  </span>
                  <p className="clik_text">Click to add Image</p>
                </span>
              </div>
            )}
            {imageUrl && (
              <div className="thumbnail__section">
                <img
                  src={imageUrl}
                  className="thumbnail__image"
                  alt="thumbnail"
                  onClick={handleClick}
                />
              </div>
            )}
            <ErrorText error={imageError} />

            <input
              className="filefield"
              type="file"
              name="image"
              id="file"
              accept="image/*"
              ref={hiddenFileInput}
              onChange={(e: any) => getFiles(e)}
            />
          </div>
        </div>
      </div>
      <div className="seorow seorowbottom">
        <TextArea
          label="Site Description"
          name="description"
          register={register}
        />
      </div>
      <div className="seorow seorowbutton">
        <PrimaryButton
          text="Submit"
          type="submit"
          loading={siteSettingsState?.loading}
        />
      </div>
    </form>
  );
}

export default SEOBody;
