import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";

const TenantTeamHeader = (props: any) => {
  const { searchText, setSearchText } = props;

  return (
    <div className="header_container boxshadow">
      <div className="left__section">
        <TableHeadingTitle>Teams</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
      </div>
    </div>
  );
};

export default TenantTeamHeader;
