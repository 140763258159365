import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getCompanies,
  getCompanyById,
  resendTenantInvitation,
} from "./TenantTeamApi";

const initialState: any = {
  loading: {
    get_all: false,
    get_by_id: false,
    invitation: false,
  },
  req_success: {
    get_all: false,
    get_by_id: false,
  },
  current_page: 0,
  all_tenants: [],
  current_tenant: [],
  temp_tenants: [],
  tenants: [],
};

const TenantSlice = createSlice({
  name: "TenantSlice",
  initialState,
  reducers: {
    tenantTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.tenants = state.all_tenants.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.tenants = state.all_tenants.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.tenants = state.all_tenants.slice(
          payload * 10,
          10 * payload + 10,
        );
      }
    },
    searchTenantInTable: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length >= 1) {
        state.temp_tenants = state.all_tenants.filter((tenant: any) =>
          tenant.name.includes(payload?.toLowerCase()),
        );
        state.tenants = state.temp_tenants.slice(0, 10);
      } else {
        state.tenants = state.all_tenants.slice(
          state.current_page * 10,
          10 * state.current_page + 10,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // GET All Tenants
    builder.addCase(getCompanies.pending, (state) => {
      state.loading.get_all = true;
      state.req_success.get_all = false;
    });
    builder.addCase(
      getCompanies.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_all = false;
        state.req_success.get_all = true;
        state.all_tenants = payload.data;
        state.tenants = state.all_tenants.slice(0, 10);
      },
    );
    builder.addCase(getCompanies.rejected, (state) => {
      state.loading.get_all = false;
    });

    // GET a tenant by Id
    builder.addCase(getCompanyById.pending, (state) => {
      state.loading.get_by_id = true;
      state.req_success.get_by_id = false;
    });
    builder.addCase(
      getCompanyById.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_by_id = false;
        state.req_success.get_by_id = true;
        state.current_tenant = payload.data;
      },
    );
    builder.addCase(getCompanyById.rejected, (state) => {
      state.loading.get_by_id = false;
    });
    builder.addCase(resendTenantInvitation.pending, (state) => {
      state.loading.invitation = true;
    });
    builder.addCase(resendTenantInvitation.fulfilled, (state) => {
      state.loading.invitation = false;
    });
    builder.addCase(resendTenantInvitation.rejected, (state) => {
      state.loading.invitation = false;
    });
  },
});
export const { tenantTablePaginate, searchTenantInTable } = TenantSlice.actions;
export default TenantSlice.reducer;
