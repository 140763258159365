import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

import "./mentorHeader.scss";
import {
  closeMentorEditModal,
  searchMentors,
} from "../../../../store/features/AdminPanel/Mentor/MentorSlice";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  addMentor,
  rensedInvitationForMentor,
  updateMentor,
} from "../../../../store/features/AdminPanel/Mentor/MentorApi";
import MentorFileUploaderModal from "../MentorFileUploaderModal/MentorFileUploaderModal";
import { roles } from "../../../../helpers/utils/Constants/roles";

const MentorHeader = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState();
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  useEffect(() => {
    dispatch(searchMentors(searchText));
  }, [searchText]);

  const dispatch = useAppDispatch();
  const mentorState: any = useAppSelector((store: RootStore) => {
    return store.MentorSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const validationSchema = yup.object().shape({
    first_name: yup.string().required(REQUIRED_VALIDATION("First Name")),
    last_name: yup.string().required(REQUIRED_VALIDATION("Last Name")),
    email: yup
      .string()
      .required(REQUIRED_VALIDATION("Email"))
      .email("Email is invalid"),
  });

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const onSubmit = (values: any) => {
    const data = {
      ...values,
      role: "mentor",
    };
    if (mentorState.editUser) {
      dispatch(updateMentor({ userId: mentorState.single_user_info.id, data }));
    } else {
      dispatch(addMentor(data));
    }
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  const handleModal = () => {
    reset();
    dispatch(closeMentorEditModal(false));
  };

  useEffect(() => {
    if (!mentorState.adderLoading && mentorState.edit_success) {
      dispatch(closeMentorEditModal(false));
      setShowModal(false);
      reset();
    }
    if (!mentorState.adderLoading && mentorState.add_success) {
      setShowModal(false);
      reset();
    }
  }, [mentorState]);
  useEffect(() => {
    if (mentorState.editUser) {
      setShowModal(true);
      setValue("first_name", mentorState.single_user_info.first_name);
      setValue("last_name", mentorState.single_user_info.last_name);
      setValue("email", mentorState.single_user_info.email);
    }
  }, [mentorState?.editUser, mentorState?.single_user_info]);

  return (
    <div className="mentor__header__container">
      <div className="left__section">
        <TableHeadingTitle>All Mentors</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="file__upload__btn" style={{ marginRight: "1rem" }}>
          {/* <PrimaryButton
            text="Upload File"
            size="sm"
            type="button"
            // loading={false}
            onClick={() => setShowFileUploadModal(true)}
          /> */}
        </div>
        {(authState.tac_user?.role === roles.SYSTEM_ADMIN ||
          authState.tac_user?.role === roles.SUB_ADMIN) && (
          <div className="mentor__button__witdh">
            <PrimaryButton
              text="Add New Mentor"
              size="sm"
              type="button"
              // loading={false}
              onClick={() => setShowModal(true)}
            />
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader
          title={mentorState.editUser ? "Update Mentor" : "Add New Mentor"}
        />

        <ModalBody>
          <form
            className="user__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="flex">
              <div className="sm_width">
                <TextField
                  type="text"
                  label="First Name"
                  name="first_name"
                  register={register}
                  error={errors?.first_name}
                />
              </div>
              <div className="sm_width ">
                <TextField
                  type="text"
                  label="Last Name"
                  name="last_name"
                  register={register}
                  error={errors?.last_name}
                />
              </div>
            </div>

            <TextField
              type="text"
              label="Email Address"
              name="email"
              register={register}
              error={errors?.email}
              disabled={mentorState.editUser}
            />
            <div className="modal_footer">
              {!mentorState.single_user_info?.last_active &&
                !mentorState.resend_invitaiton_loading &&
                mentorState.editUser && (
                  <span
                    role="button"
                    aria-hidden
                    className="resend__invitation"
                    onClick={() =>
                      dispatch(
                        rensedInvitationForMentor({
                          email: mentorState.single_user_info.email,
                        })
                      )
                    }
                  >
                    Resend Invitation
                  </span>
                )}
              {mentorState.resend_invitaiton_loading && (
                <div className="spinner spinner-margin" />
              )}
              <div>
                <PrimaryButton
                  text={mentorState.editUser ? "Update" : "Add"}
                  type="submit"
                  loading={mentorState.adderLoading}
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <MentorFileUploaderModal
        showFileUploadModal={showFileUploadModal}
        setShowFileUploadModal={setShowFileUploadModal}
      />
    </div>
  );
};

export default MentorHeader;
