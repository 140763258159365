import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddTermsOfUse, getTermsOfUse } from "./TermsApi";

const initialState: any = {
  loading: false,
  req_success: false,
  terms: {},
};
// ADD TERMS;
const TermsSlice = createSlice({
  name: "TermsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ADD TERMS OF USE
    builder.addCase(AddTermsOfUse.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      AddTermsOfUse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.terms = payload.data;
      },
    );
    builder.addCase(AddTermsOfUse.rejected, (state) => {
      state.loading = false;
    });

    // GET TERMS OF USE
    builder.addCase(getTermsOfUse.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getTermsOfUse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.terms = payload.data;
      },
    );
    builder.addCase(getTermsOfUse.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default TermsSlice.reducer;
