import React, { useEffect, useState } from "react";
import { ChevronLeft, Mic } from "react-feather";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useAppDispatch } from "../../../../store/store";
import {
  getPresignedUrlForAudioUpload,
  submitAnswerFeedback,
  uploadAudioToAws,
} from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationApi";
import AudioFeedback from "../../../../shared/AudioFeedback/AudioFeedback";

const ReplyButton = (props: any) => {
  const {
    interviewState,
    selectedIndex,
    showReplyBox,
    handleAddReply,
    handleBack,
    id,
  } = props;

  const dispatch = useAppDispatch();
  const [duration, setDuration] = useState(0);

  const recorderControls = useAudioRecorder();

  const handleAudioFeedback = () => {
    dispatch(getPresignedUrlForAudioUpload());
  };

  useEffect(() => {
    if (recorderControls.recordingTime !== 0) {
      setDuration(recorderControls.recordingTime);
    }
  }, [recorderControls.recordingTime]);

  useEffect(() => {
    if (interviewState.req_success.audio_presigned_url) {
      dispatch(
        uploadAudioToAws({
          url: interviewState.audio_presigned_url,
          file: recorderControls.recordingBlob,
        })
      );
    }
  }, [interviewState.req_success.audio_presigned_url]);

  useEffect(() => {
    if (interviewState.req_success.upload_audio) {
      if (!interviewState.audio_presigned_url) {
        return;
      }
      const url = interviewState.audio_presigned_url.split("?");

      const data = {
        feedback_message: url[0],
        duration: duration,
      };

      dispatch(
        submitAnswerFeedback({
          data: data,
          id: interviewState.active_answer.answers[selectedIndex - 1].id,
        })
      );
    }
  }, [interviewState.req_success.upload_audio]);

  return (
    <>
      {interviewState.active_answer?.answers &&
        interviewState.active_answer?.answers[selectedIndex - 1]?.feedbacks
          .length > 0 &&
        !showReplyBox && (
          <div className="feedback__button__div">
            <button
              type="button"
              className="reply__to__feedback"
              onClick={() => handleAddReply()}
            >
              Add Reply
            </button>
          </div>
        )}

      {showReplyBox && (
        <div className="feedback__button__div center">
          <div
            className="back__icon__div"
            role="button"
            aria-hidden
            onClick={handleBack}
          >
            <ChevronLeft className="back__icon" />
          </div>
          {!recorderControls.isRecording &&
            !interviewState.loading.audio_presigned_url &&
            !interviewState.loading.upload_audio &&
            !interviewState.loading.add_answer && (
              <button type="submit" className="reply__to__feedback">
                {interviewState.loading.add_answer && (
                  <div className="spinner mr-1" />
                )}
                {!interviewState.loading.add_answer && "Submit"}
              </button>
            )}

          {/* {recorderControls.recordingBlob && (
            <button
              type="button"
              className="reply__to__feedback"
              onClick={handleAudioFeedback}
            >
              {(interviewState.loading.audio_presigned_url ||
                interviewState.loading.upload_audio ||
                interviewState.loading.add_answer) && (
                <div className="spinner mr-1" />
              )}
              {!interviewState.loading.audio_presigned_url &&
                !interviewState.loading.upload_audio &&
                !interviewState.loading.add_answer &&
                "Submit"}
            </button>
          )} */}

          {(interviewState.loading.audio_presigned_url ||
            interviewState.loading.upload_audio ||
            interviewState.loading.add_answer) && (
            <button type="button" className="reply__to__feedback">
              {" "}
              <div className="spinner mr-1" />
            </button>
          )}
          {!recorderControls.isRecording &&
            !interviewState.loading.audio_presigned_url &&
            !interviewState.loading.upload_audio &&
            !interviewState.loading.add_answer && (
              <div
                className="recored__feedback__icon"
                role="button"
                aria-hidden
                onClick={recorderControls.startRecording}
              >
                <Mic className="audio__mic" />
              </div>
            )}

          {recorderControls.isRecording &&
            !interviewState.loading.audio_presigned_url &&
            !interviewState.loading.upload_audio &&
            !interviewState.loading.add_answer && (
              <AudioFeedback
                recorderControls={recorderControls}
                handleAudioFeedback={handleAudioFeedback}
                handleAudioFeedBackStyle={true}
              />
            )}
        </div>
      )}
      {/* <AudioFeedback recorderControls={recorderControls} /> */}
    </>
  );
};

export default ReplyButton;
