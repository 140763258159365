import "./index.scss";

interface ITenantLoadingScreen {
  message: string;
}

const TenantLoadingScreen = (props: ITenantLoadingScreen) => {
  const { message } = props;
  return (
    <div className="tenant__loading__wrapper">
      <div className="tenant_animation__loading__section">
        <div className="tenant_amimation__div">
          <span className="tenant_processing">Processing</span>
          <div className="tenant_spinner2">
            <div className="tenant_bounce1" />
            <div className="tenant_bounce2 m" />
            <div className="bounce3" />
          </div>
        </div>
        <span className="tenant_message">{message}</span>
      </div>
    </div>
  );
};

export default TenantLoadingScreen;
