import { useEffect, useState } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../shared/Table/Table";
import Empty from "../../../shared/Empty/Empty";
import DeleteModal from "../../../shared/DeleteModal/DeleteModal";
import PaginateButton from "../../../shared/PaginateButton/PaginateButton";
import {
  deleteMeetingLink,
  getMeetingLinks,
} from "../../../store/features/AdminPanel/MeetWithUs/MeetWithUsApi";
import {
  editMeeting,
  meetTablePaginate,
} from "../../../store/features/AdminPanel/MeetWithUs/MeetWithUsSlice";

const MeetTable = (props: any) => {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [meetingId, setMeetingId] = useState(0);
  const dispatch = useAppDispatch();

  const meetingState: any = useAppSelector((store: RootStore) => {
    return store.MeetWithUsSlice;
  });

  useEffect(() => {
    dispatch(getMeetingLinks());
  }, [meetingState.isChanged]);

  const handlePagination = (num: number) => {
    dispatch(meetTablePaginate(num));
  };

  const editModalHandler = (meet: any) => {
    dispatch(editMeeting(meet));
  };
  const deleteModalHandler = (id: number) => {
    setMeetingId(id);
    setDeleteShowModal(true);
  };

  const deleteMeeting = () => {
    dispatch(deleteMeetingLink({ id: meetingId }));
  };

  useEffect(() => {
    if (meetingState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [meetingState.req_success.delete]);

  return (
    <div className="tenant__table__wrapper ">
      <div className="user__table__section globl__margin">
        <Table
          heading={
            <TableHeading>
              <th>Name </th>
              <th>Designation</th>
              <th>Meeting Link</th>
              <th className="hide__text ">.</th>
            </TableHeading>
          }
        >
          <TableBody>
            {!meetingState.loading.get &&
              meetingState.req_success.get &&
              meetingState.meetings.map((meet: any) => {
                return (
                  <tr key={meet.id}>
                    <td className="plan_tdddd">{meet.name}</td>
                    <td className="plan_tdddd">{meet.designation} </td>
                    <td className="plan_tdddd">{meet.meeting_link}</td>
                    <TableAction
                      onEdit={() => editModalHandler(meet)}
                      onDelete={() => deleteModalHandler(meet.id)}
                    />
                    {/* <td className="hide__text" /> */}
                  </tr>
                );
              })}
          </TableBody>
        </Table>
        {!meetingState.loading.get && meetingState.meetings.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty emptyText="It seems empty." custombg={true} />
          </div>
        )}
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setDeleteShowModal}
          loading={meetingState.loading.delete}
          onClick={deleteMeeting}
        />
      </div>
      <div className="pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={meetingState.all_meetings.length}
        />
      </div>
    </div>
  );
};

export default MeetTable;
