import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const getInterviewAnswerByRoleId = createAsyncThunk(
  "getInterviewAnswerByRoleId",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/get-user-answers-by-role`,
        data
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInterviewUsers = createAsyncThunk(
  "getInterviewUsers",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/get-interview-users`);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addOrUpdateAnswer = createAsyncThunk(
  "addOrUpdateAnswer",
  async (
    {
      data,
      videoId,
      setLoading,
      loadingId,
    }: { data: any; videoId: number; setLoading: any; loadingId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/submit-answer-feedback/${videoId}`,
        data
      );
      res.data.idForUpdate = loadingId;
      REQUIRED__TOASTER(res.data);
      setLoading(false);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      setLoading(false);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateShareLink = createAsyncThunk(
  "updateShareLink",
  async (
    {
      videoId
    }: { videoId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.post(
        `${dynamic_url}/generateVideoUrl/${videoId}`
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFeedbackFromAnswers = createAsyncThunk(
  "deleteFeedbackFromAnswers",
  async (id: { id: number }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(
        `${dynamic_url}/delete-answer-feedback/${id}`
      );
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteVideoFromAnswer = createAsyncThunk(
  "deleteVideoFromAnswer",
  async (id: { id: number }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(
        `${dynamic_url}/delete-answer-video/${id}`
      );
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPresignedUrlForAdminAudioUpload = createAsyncThunk(
  "getPresignedUrlForAdminAudioUpload",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(
        `${dynamic_url}/get-interview-audio-upload-url`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadAdminAudioToAws = createAsyncThunk(
  "uploadAdminAudioToAws",
  async (
    { url, file }: { url: string; file: any },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const res = await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
