/* eslint-disable class-methods-use-this */
export class LocalDb {
  save(key: string, dataToSave: any) {
    try {
      localStorage.setItem(key, JSON.stringify(dataToSave));
    } catch (error) {
      console.log("Error while saving to Local storage.", error);
    }
  }

  fetch(key: string) {
    const itemGot = localStorage.getItem(key);
    if (itemGot !== null) {
      return JSON.parse(itemGot);
    }
    return null;
  }
}
export default new LocalDb();
