import React, { useEffect, useState } from "react";
import moment from "moment";

import Empty from "../../../../shared/Empty/Empty";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../shared/Table/Table";
import Avatar from "../../../../shared/Avatar/Avatar";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  deleteSubAdmin,
  loadSubAdmin,
} from "../../../../store/features/AdminPanel/SubAdmin/SubAdminApi";
import userImage from "../../../../assets/icons/user.png";
import { editSubAdminInfo } from "../../../../store/features/AdminPanel/SubAdmin/SubAdminSlice";
import { roles } from "../../../../helpers/utils/Constants/roles";

const SubAdminContent = (props: any) => {
  // const {setShowModal,setShowEditModal} = props;
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState<any>("");
  const [userId, setUserId] = useState<any>("");

  const dispatch = useAppDispatch();

  const subAdminState: any = useAppSelector((store: RootStore) => {
    return store.SubAdminSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  useEffect(() => {
    dispatch(loadSubAdmin());
  }, []);

  useEffect(() => {
    if (!subAdminState.deleteLoading && subAdminState.delete_success) {
      setDeleteShowModal(false);
    }
  }, [subAdminState]);

  const openDeleteUser = (userId: any, userEmail: any) => {
    setDeleteShowModal(true);
    setUserId(userId);
    setUserEmail(userEmail);
  };
  const deleteUser = () => {
    dispatch(deleteSubAdmin(userId));
  };

  const editUser = (user: any) => {
    dispatch(editSubAdminInfo(user));
  };

  return (
    <div className="user__table__section">
      <Table
        heading={
          <TableHeading>
            <th>Name</th>
            <th>Email Id</th>
            <th>Sub-Admin Id</th>
            <th>Created At</th>
            <th>Role</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {!subAdminState.loading &&
            subAdminState.users.map((user: any) => {
              return (
                <tr key={user.id}>
                  <td className="flex_td">
                    <div className="avatar__section">
                      <Avatar imageUrl={user.avatar_url ?? userImage} />
                    </div>
                    {user.name}
                  </td>

                  <td>{user.email}</td>
                  <td>{user.id}</td>
                  <td>{moment(user.created_at).format("ll")}</td>
                  <td>Sub Admin</td>
                  <TableAction
                    onEdit={() => editUser(user)}
                    onDelete={() => openDeleteUser(user.id, user.email)}
                    hideDelete={authState.tac_user?.role !== roles.SYSTEM_ADMIN}
                  />
                </tr>
              );
            })}
        </TableBody>
      </Table>

      {!subAdminState.loading && subAdminState.users.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
      )}
      <DeleteModal
        message={`The user with "${userEmail}" email address is about to be
        deleted. Please confirm this action as you won't be able to
        revert this action.`}
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={subAdminState.deleteLoading}
        onClick={deleteUser}
      />
    </div>
  );
};

export default SubAdminContent;
