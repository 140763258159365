import { useState } from "react";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import "./verifyAccountMessage.scss";

const VerifyAccountMessage = (props: any) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className="VerifyAccountMessage__container">
      <span className="verify__account__title">Verify your account </span>
      <span className="verify__account__description mt-4">
        We have sent a link to entered address. Verify your account and start
        your journey
      </span>
      <span className="alert__text mt-8">Didn’t received an email?</span>
      <div className="form_row mt-1">
        <PrimaryButton
          text="Resend link"
          type="button"
          loading={loading}
          onClick={() => setLoading(!loading)}
        />
      </div>
    </div>
  );
};

export default VerifyAccountMessage;
