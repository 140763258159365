import { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";

import AdminPanelLayout from "../../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import {
  AddPrivacyPolicy,
  getPrivacyPolicy,
} from "../../../../store/features/AdminPanel/Setting/Privacy/PrivacyApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

import "../index.scss";

const Privacy = () => {
  const editor = useRef(null);
  const dispatch = useAppDispatch();
  const [content, setContent] = useState("");

  const PrivacyState: any = useAppSelector((store: RootStore) => {
    return store.PrivacySlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const config: any = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    readOnly: false,
    placeholder: "write something here.....",
    height: 700,
    toolbarSticky: false,
    theme: themeState.themeMode === "dark" ? "dark" : "default",
  };
  useEffect(() => {
    dispatch(getPrivacyPolicy());
  }, []);

  useEffect(() => {
    if (PrivacyState.req_success) {
      setContent(PrivacyState?.privacy?.content);
    }
  }, [PrivacyState.req_success]);
  const submitContent = () => {
    dispatch(AddPrivacyPolicy({ data: content }));
  };
  return (
    <AdminPanelLayout>
      <div className="terms_container mt-7">
        <span className="company__setting">Company Settings</span>
        <span className="company__setting__title">Privacy</span>
        <div className="editor-wrapper">
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={(newContent) => setContent(newContent)}
          />
        </div>
        <div className="button_sec">
          <PrimaryButton
            style={{ width: "10rem" }}
            text="Submit"
            label="Submit"
            loading={PrivacyState.loading}
            onClick={submitContent}
          />
        </div>
      </div>
    </AdminPanelLayout>
  );
};

export default Privacy;
