/* eslint-disable import/no-cycle */
import React from "react";
import SubsCriberComp from "../../../components/AdminPanel/SubsCriber/SubsCriberComp";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";

const SubsCriber = () => {
  return (
    <AdminPanelLayout>
     <SubsCriberComp/>
    </AdminPanelLayout>
  );
};

export default SubsCriber;
