import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import "./singnUpFrom.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";

const SignUpFrom = () => {
  const [loading, setLoading] = useState(false);
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(REQUIRED_VALIDATION("Email"))
      .email("Email is invalid"),
    fullname: yup.string().required(REQUIRED_VALIDATION("Full Name")),
    password: yup
      .string()
      .required(REQUIRED_VALIDATION("Password"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_{|}~`])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_{|}~`]{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    // dispatch(userSignIn(values));
  };
  return (
    <form className="singup__from__container" onSubmit={handleSubmit(onSubmit)}>
      <div className="form_row">
        <TextField
          type="text"
          label="Full Name"
          name="fullname"
          register={register}
          error={errors?.fullname}
        />
      </div>
      <div className="form_row">
        <TextField
          type="text"
          label="Email"
          name="email"
          register={register}
          error={errors?.email}
        />
      </div>
      <div className="form_row">
        <TextField
          type="password"
          label="Password"
          name="password"
          register={register}
          error={errors?.password}
        />
      </div>
      <div className="form_row mt-3">
        <PrimaryButton
          text="Sign Up"
          type="submit"
          loading={loading}
          onClick={() => setLoading(!loading)}
        />
      </div>
      <span className="signIn__text">
        Already have an account?
        <span className="coloured__text"> Login.</span>
      </span>
    </form>
  );
};

export default SignUpFrom;
