import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLearningPathcourses } from "../../../store/features/UserDashboard/LearningPath/LearningPathApi";
import { entollToCourse } from "../../../store/features/UserDashboard/ShowCourse/ShowCourseApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import Course from "../Course/Course";
import "./learningPathCourse.scss";

const LearningPathCourse = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const coursesState: any = useAppSelector((store: RootStore) => {
    return store.LearningPathSlice;
  });

  const handleClick = (pathId: number, bundle: string, totalcourse: number) => {
    const bundles = bundle
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    navigate(`/learning-path-stepper/${pathId}/${bundles}`, {
      state: { bundle_name: bundle, totalcourse: totalcourse },
    });
  };
  useEffect(() => {
    dispatch(getLearningPathcourses());
  }, []);
  return (
    <div className="LearningPathCourse">
      {coursesState.req_success &&
        coursesState.courses.map((course: any) => {
          return (
            <Course
              key={course.id}
              onClick={() =>
                handleClick(
                  course.path_id,
                  course.name,
                  course.path_courses.length
                )
              }
              course={course}
              showTotalNumber
            />
          );
        })}
      {coursesState?.loading && (
        <>
          <div className="skeleton-filter-box">
            <div className="skeleton-img skeleton " />
            <div className="skeleton-txt">
              <div className="skeleton-title skeleton" />
              <div className="skeleton-duration skeleton" />
            </div>
          </div>
          {[1, 2, 3, 4].map((e: any) => {
            return (
              <div className="skeleton-filter-box" key={e}>
                <div className="skeleton-img skeleton " />
                <div className="skeleton-txt">
                  <div className="skeleton-title skeleton" />
                  <div className="skeleton-duration skeleton" />
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default LearningPathCourse;
