import React, { useEffect, useState } from "react";
import moment from "moment";
import "./mentorContent.scss";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../shared/Table/Table";
import Avatar from "../../../../shared/Avatar/Avatar";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  deleteMentor,
  loadMentors,
} from "../../../../store/features/AdminPanel/Mentor/MentorApi";
import { editMentorInfo } from "../../../../store/features/AdminPanel/Mentor/MentorSlice";
import userImage from "../../../../assets/icons/user.png";
import Empty from "../../../../shared/Empty/Empty";
import { roles } from "../../../../helpers/utils/Constants/roles";

const MentorContent = (props: any) => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [userId, setUserId] = useState<any>("");
  const [userEmail, setUserEmail] = useState<any>("");

  const mentorState: any = useAppSelector((store: RootStore) => {
    return store.MentorSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  useEffect(() => {
    dispatch(loadMentors());
  }, []);
  useEffect(() => {
    if (!mentorState.deleteLoading && mentorState.delete_success) {
      setDeleteShowModal(false);
    }
  }, [mentorState]);

  const openDeleteUser = (userId: any, userEmail: any) => {
    setDeleteShowModal(true);
    setUserId(userId);
    setUserEmail(userEmail);
  };
  const deleteUser = () => {
    dispatch(deleteMentor(userId));
  };

  const editUser = (user: any) => {
    dispatch(editMentorInfo(user));
  };
  return (
    <div className="user__table__section">
      <Table
        heading={
          <TableHeading>
            <th>Name</th>
            <th>Email Id</th>
            <th>Mentor Id</th>
            <th>Created At</th>
            <th>Role</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {!mentorState.loading &&
            mentorState.users.map((user: any) => {
              return (
                <tr key={user.id}>
                  <td className="flex_td">
                    <div className="avatar__section">
                      <Avatar
                        // imageUrl="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
                        imageUrl={user.avatar_url ?? userImage}
                        //   size={sm}
                      />
                    </div>
                    {user.name}
                  </td>

                  <td>{user.email}</td>
                  <td>{user.id}</td>
                  <td>{moment(user.created_at).format("ll")}</td>
                  <td>Mentor</td>
                  <TableAction
                    onEdit={() => editUser(user)}
                    onDelete={() => openDeleteUser(user.id, user.email)}
                    hideDelete={authState.tac_user?.role !== roles.SYSTEM_ADMIN}
                  />
                </tr>
              );
            })}
        </TableBody>
      </Table>
      {!mentorState.loading && mentorState.users.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
      )}
      <DeleteModal
        message={`The user with "${userEmail}" email address is about to be
        deleted. Please confirm this action as you won't be able to
        revert this action.`}
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={mentorState.deleteLoading}
        onClick={deleteUser}
      />
    </div>
  );
};

export default MentorContent;
