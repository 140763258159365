import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./pmUserprogressSection.scss";
import EmptyProgressCard from "../../ProgressSection/EmptyProgressCard";

const ProgressSection = () => {
  const [isProgress, setIsProgress] = useState(true);
  const [percentage, setPercentage] = useState(10);
  const [slug, setSlug] = useState(true);
  const data = [{}];
  return (
    <div>
      <div className="scroll__position__for__progress" id="myprogress" />
      <div className="pm__user__new__progress__wrapper">
        <div className="pm__user__center_wrapper">
          <h1 className="title"> My Progress </h1>
          <div className="progress__list__sections">
            <div className="progress__cards__box1">
              <div className="progress__card test">
                <div className="name__div">
                  <Link to="/">
                    <h6 className="course__name">
                      Learn Music Production Beginner
                    </h6>
                  </Link>
                </div>
                <div className="progress_card_divider" />
                <div className="row">
                  <div className="progress__div">
                    <div className={`progress__bar  progress-${percentage}`} />
                    <span className="precentage">{percentage}%</span>
                  </div>

                  <span
                    role="button"
                    aria-hidden
                    className={percentage === 100 ? "completed" : "resume"}
                    //   onClick={() => startLearning(data.slug)}
                    aria-disabled
                  >
                    {percentage === 100 ? "Completed" : "Resume"}
                  </span>

                  {/* <div className="spinner " /> */}
                </div>
              </div>
              <div className="progress__card test">
                <div className="name__div">
                  <Link to="/course-progress/12">
                    <h6 className="course__name">
                      Supply Chain Management 101 - Learn The Essentials
                    </h6>
                  </Link>
                </div>
                <div className="progress_card_divider" />
                <div className="row">
                  <div className="progress__div">
                    <div className={`progress__bar  progress-${percentage}`} />
                    <span className="precentage">{percentage}%</span>
                  </div>

                  <span
                    role="button"
                    aria-hidden
                    className={percentage === 100 ? "completed" : "resume"}
                    //   onClick={() => startLearning(data.slug)}
                    aria-disabled
                  >
                    {percentage === 100 ? "Completed" : "Resume"}
                  </span>

                  {/* <div className="spinner " /> */}
                </div>
              </div>
            </div>
            <div className="progress__cards__box2">
              <div className="progress__card test">
                <div className="name__div">
                  <Link to="/course-progress/12">
                    <h6 className="course__name">
                      The Complete Stock Trading Course for 2022 Market
                    </h6>
                  </Link>
                </div>
                <div className="progress_card_divider" />
                <div className="row">
                  <div className="progress__div">
                    <div className={`progress__bar  progress-${percentage}`} />
                    <span className="precentage">{percentage}%</span>
                  </div>

                  <span
                    role="button"
                    aria-hidden
                    className={percentage === 100 ? "completed" : "resume"}
                    //   onClick={() => startLearning(data.slug)}
                    aria-disabled
                  >
                    {percentage === 100 ? "Completed" : "Resume"}
                  </span>

                  {/* <div className="spinner " /> */}
                </div>
              </div>
              <div className="progress__card test">
                <div className="name__div">
                  <Link to="/course-progress/12">
                    <h6 className="course__name">
                      Microsoft Excel Ninja Course
                    </h6>
                  </Link>
                </div>
                <div className="progress_card_divider" />
                <div className="row">
                  <div className="progress__div">
                    <div className={`progress__bar  progress-${percentage}`} />
                    <span className="precentage">{percentage}%</span>
                  </div>

                  <span
                    role="button"
                    aria-hidden
                    className={percentage === 100 ? "completed" : "resume"}
                    //   onClick={() => startLearning(data.slug)}
                    aria-disabled
                  >
                    {percentage === 100 ? "Completed" : "Resume"}
                  </span>

                  {/* <div className="spinner " /> */}
                </div>
              </div>
            </div>
            <div className="progress__cards__box3">
              <div className="progress__card test">
                <div className="name__div">
                  <Link to="/course-progress/12">
                    <h6 className="course__name">
                      Live Coding Masterclass: Beginners To Advanced
                    </h6>
                  </Link>
                </div>
                <div className="progress_card_divider" />
                <div className="row">
                  <div className="progress__div">
                    <div className={`progress__bar  progress-${percentage}`} />
                    <span className="precentage">{percentage}%</span>
                  </div>

                  <span
                    role="button"
                    aria-hidden
                    className={percentage === 100 ? "completed" : "resume"}
                    //   onClick={() => startLearning(data.slug)}
                    aria-disabled
                  >
                    {percentage === 100 ? "Completed" : "Resume"}
                  </span>

                  {/* <div className="spinner " /> */}
                </div>
              </div>
            </div>
          </div>
          {/* <EmptyProgressCard /> */}
        </div>
      </div>
    </div>
  );
};

export default ProgressSection;
