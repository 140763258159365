import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";
import axiosInstance from "../../../../apiConfigs/axiosInstance";

export const allWebguideStepsdata = [
  { id: 1, title:"Choose Your Learning Role", message: "Select a learning role from the options available here." },
  {
    id: 2,
    title:"Start Your Interview Preparation",
    message:
      "Click here to begin your comprehensive interview preparation journey.",
  },
  {
    id: 3,
    title:"Question",
    message:
      "Your question is right here! Take a moment to consider and provide your response.",
  },
  {
    id: 4,
    title:"Recording time",
    message:
      "Each response has a maximum recording time. Be concise and focus on key points. Practice to stay within the time limit for impactful responses.",
  },
  {
    id: 5,
    title:"Show all questions",
    message:
      "Click here to see a list of all the questions. Plan your responses and choose the order in which you'd like to answer.",
  },

  {
    id: 6,
    title:"All Questions",
    message:
      "Here, you can see all the questions which you have to record and respond.",
  },

  {
    id: 7,
    title:"Access Media Response Type",
    message: "Click here to access media response type.",
  },
  {
    id: 8,
    title:"Start Recording Your Response",
    message:
      "Click here to start recording your answer. Speak clearly and concisely. Don't worry, you can retake if needed.",
  },
  {
    id: 9,
    title:"Finish Recording Your Response",
    message:
      "Click here when you are done recording your response. Make sure your message is clear and complete before ending.",
  },
  {
    id: 10,
    title:"Play and Re-record",
    message:
      "After recording, you can 'Re-record' your response if needed. The 'Play' option is currently disabled for audio.",
  },
  {
    id: 11,
    title:"Submit Your Response",
    message:
      "Click here to submit your recorded response. Make sure that you're content with your answers before moving forward.",
  },
  {
    id: 12,
    title:"Delete Response",
    message:
      "You can click on ‘Delete Responses’ to remove this recorded response. Don't worry, you can always re-record this question if needed.",
  },

  {
    id: 13,
    title:"Skip this Question",
    message:
      "Not sure about a question? You can click ‘Skip this question’ to move on. You can always come back and answer it later.",
  },
  {
    id: 14,
    title:"Go Back to Previous Question",
    message:
      "You can click on 'Go Back' to look over and modify your earlier answers. Make sure your responses are well crafted and match your objectives.",
  },

  {
    id: 15,
    title:"Feedbacks",
    message:
      "This is where your mentor's insightful feedback will be showcased after they provide it.",
  },
  // {
  //   id: 16,
  //   title:"Notifications",
  //   message:
  //     "Your notifications are displayed here. Stay updated on important updates and messages.",
  // },
  {
    id: 16,
    title: "Your Interview Ready Journey Begins!",
    message:
      "Congratulations, you've completed the Interview Preparation Onboard Tour!",
  },
];

export const webGuideOnboarding = createAsyncThunk(
  "webGuideOnboarding",
  async (
     data:any,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.put(
        `${dynamic_url}/web-guides/interview`,data
      );
      // console.log(data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
