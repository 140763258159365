import React, { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import moment, { max } from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "./ProductPagesDateTimeHeader.scss";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import SelectDropDown from "../../../../shared/SelectDropDown/SelectDropDown";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { filterArray, filterArrayForTAC } from "./helper";
import {
  addOrUpdateDateTime,
  getDateTime,
} from "../../../../store/features/AdminPanel/DateTime/DateTimeApi";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";
import { setIsEdit } from "../../../../store/features/AdminPanel/DateTime/DateTimeSlice";

const ProductPagesDateTimeHeader = (props: any) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const dateTimeState: any = useAppSelector((store) => store.DateTimeSlice);
  const dispatch = useAppDispatch();

  interface Ivalue {
    id: number;
    name: string;
    value: string;
  }
  const [selectedValue, setSelectedValue] = useState<Ivalue>(
    configState.configs?.membership_enabled === Domain.TAC
      ? filterArrayForTAC[0]
      : filterArray[0]
  );

  const clearForm = () => {
    dispatch(setIsEdit(false));
    reset();
    setShowModal(false);
  };

  const handleOption = (data: Ivalue) => {
    setSelectedValue(data);
  };

  useEffect(() => {
    dispatch(getDateTime(selectedValue.value));
  }, [dispatch, selectedValue]);

  const validationSchema = yup.object().shape({
    start_date: yup.string().required("Start Date is required"),
  });

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = async (data: any) => {
    if (dateTimeState.isEdit) {
      data.id = dateTimeState.dateTime.id;
    }
    setValue("for", selectedValue.value);
    await dispatch(addOrUpdateDateTime(data)).then((res) => {
      if (res.payload.success) {
        clearForm();
      }
    });
  };

  useEffect(() => {
    if (dateTimeState.isEdit) {
      setShowModal(true);
      setValue(
        "start_date",
        moment(dateTimeState.dateTime?.start_date, "DD/MM/YYYY", true).format(
          "YYYY-MM-DD"
        )
      );

      setValue(
        "start_time",
        dateTimeState.dateTime?.start_time
          ? moment(dateTimeState.dateTime?.start_time, "hh:mm A").format(
              "HH:mm"
            )
          : null
      );

      setValue(
        "end_date",
        dateTimeState.dateTime?.end_date
          ? moment(dateTimeState.dateTime?.end_date, "DD/MM/YYYY", true).format(
              "YYYY-MM-DD"
            )
          : null
      );

      setValue(
        "end_time",
        dateTimeState.dateTime?.end_time
          ? moment(dateTimeState.dateTime?.end_time, "hh:mm A").format("HH:mm")
          : null
      );
    } else {
      reset();
    }
  }, [dateTimeState.isEdit, reset, setValue]);

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle> Date & Time For</TableHeadingTitle>
        <div className="dropdown__holder dropdown__holder__handle__width">
          <SelectDropDown
            data={
              configState.configs?.membership_enabled === Domain.TAC
                ? filterArrayForTAC
                : filterArray
            }
            selectedValue={selectedValue.name}
            handleOption={handleOption}
          />
        </div>
      </div>
      <div className="right__section">
        <div className="date__time__btn__width cursor__pointer">
          <PrimaryButton
            type="button"
            text="Add Date & Time"
            disabled={dateTimeState.dateTime}
            onClick={() => handleShowModal()}
          />
        </div>
      </div>
      <Modal
        onClick={clearForm}
        show={showModal}
        setShow={setShowModal}
        to="handle__pmp__modal__width"
      >
        <ModalHeader
          title={`${dateTimeState.isEdit ? "Edit" : "Add"} Date & Time`}
        />
        <ModalBody modalWidth="100%">
          <form className="pmp__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="pmp__row">
              <div className="">
                <TextField
                  label="Start Date"
                  type="date"
                  placeholder="mm/dd/yyyy"
                  name="start_date"
                  register={register}
                  error={errors?.start_date}
                  max="9999-12-31"
                />
              </div>
              <div className="pmp__time__box">
                <span>Start Time</span>
                <input
                  type="time"
                  {...register("start_time")}
                  className={`timeInput ${errors.start_time && "error"}`}
                />
                <ErrorText error={errors.start_time} />
              </div>
              <div className="">
                <TextField
                  label="End Date"
                  type="date"
                  placeholder="mm/dd/yyyy"
                  name="end_date"
                  register={register}
                  error={errors?.end_date}
                  max="9999-12-31"
                />
              </div>
              <div className="pmp__time__box">
                <span>End Time</span>
                <input
                  type="time"
                  {...register("end_time")}
                  className={`timeInput ${errors.end_time && "error"}`}
                />
                <ErrorText error={errors.end_time} />
              </div>
            </div>
            <div className="modal_footer">
              <div className="add_button__witdh mt-2">
                <PrimaryButton
                  loading={dateTimeState.save.loading}
                  text={dateTimeState.isEdit ? "Update" : "Add"}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProductPagesDateTimeHeader;
