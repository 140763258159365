import { useEffect } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getQuickLinks } from "../../../../store/features/UserDashboard/NewUserDashboard/NewUserDashoardApi";

function NavQuickLinks(props: any) {
  const { configState, navBg, normal, handleNavigation, showfilter } = props;
  const dashState: any = useAppSelector((store: RootStore) => {
    return store.NewDashboard;
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getQuickLinks());
  }, []);

  return (
    <div
      className={`option__sections ${showfilter && "active"}  ${
        configState.themeMode === "light" && navBg && "light_mode"
      }  ${configState.themeMode === "light" && normal && "light_mode"}`}
    >
      {!dashState.loading.quick_links &&
        dashState.req_success.quick_links &&
        dashState.quick_links.map((link: any) => {
          return (
            <span
              role="button"
              aria-hidden
              className={` option ${
                configState.themeMode === "light" && navBg && "light_mode"
              }  ${
                configState.themeMode === "light" && normal && "light_mode"
              }`}
              key={link.id}
              onClick={() => handleNavigation(link)}
            >
              {" "}
              {link.title}
            </span>
          );
        })}
    </div>
  );
}

export default NavQuickLinks;
