import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Rating from "react-rating";
import { ChevronLeft, Mic, Star } from "react-feather";
import { useForm } from "react-hook-form";
import { useAudioRecorder } from "react-audio-voice-recorder";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/store";
import { PrimaryButton } from "../../../../../../../shared/Buttons/Buttons";
import TextArea from "../../../../../../../shared/Inputs/TextArea/TextArea";
import ErrorText from "../../../../../../../shared/Inputs/ErrorText/ErrorText";
import { createInterviewDetailsFeedback } from "../../../../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.actions";

import "./recentFeedBack.scss";
import AudioFeedback from "../../../../../../../shared/AudioFeedback/AudioFeedback";
import {
  getPresignedUrlForAdminAudioUpload,
  uploadAdminAudioToAws,
} from "../../../../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerApi";
import { clearInterviewAnswerSliceData } from "../../../../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerSlice";
import isEmpty from "../../../../../../../helpers/utils/IsEmpty";

interface Props {
  id: number;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}
interface FeedbackValues  {
  id?: string | number;
  feedback_message?: string;
  rating?: number;
  duration?: number;
};


const RecentFeedback = ({ id: videoId, setShow }: Props) => {
  const [showAudioFeedback, setShowAudioFeedback] = useState(false);
  const [duration, setDuration] = useState(0);
  const [audioUploadLoading, setAudioUploadLoading] = useState(false);

  const recorderControls = useAudioRecorder();

  const dispatch = useAppDispatch();

  const { selectedFeedback } = useAppSelector((store: RootStore) => {
    return store.InterviewDetailsSlice;
  });

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewAnswerSlice;
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<FeedbackValues>();

  const onSubmit = (values: FeedbackValues) => {
    if (values.feedback_message === "" && isEmpty(values.rating as number)) {
      toast.error("At least one field is required");
      return;
    }

    dispatch(createInterviewDetailsFeedback({ body: values, id: videoId }));
    reset();
    setShow(false);
  };

  useEffect(() => {
    if (selectedFeedback) {
      const data: FeedbackValues = {
        id: selectedFeedback?.id,
        feedback_message: selectedFeedback?.feedback,
        rating: selectedFeedback?.rating,
      };
      reset(data);
    }
  }, [selectedFeedback]);

  useEffect(() => {
    if (recorderControls.recordingTime !== 0) {
      setDuration(recorderControls.recordingTime);
    }
  }, [recorderControls.recordingTime]);

  useEffect(() => {
    if (InterviewState.req_success.audio_presigned_url) {
      dispatch(
        uploadAdminAudioToAws({
          url: InterviewState.audio_presigned_url,
          file: recorderControls.recordingBlob,
        })
      );
    }
  }, [InterviewState.req_success.audio_presigned_url]);

  const ratingValue = watch("rating");

  const ratingHandler = (n: number) => {
    if (ratingValue === 1 && n === 1) {
      setValue("rating", 0);
    } else {
      setValue("rating", n);
    }
  };

  const startRecording = () => {
    setShowAudioFeedback(true);
    recorderControls.startRecording();
  };

  const handleAudioFeedback = () => {
    setShowAudioFeedback(false);
    setAudioUploadLoading(true);
    dispatch(getPresignedUrlForAdminAudioUpload());
  };

  useEffect(() => {
    if (InterviewState.req_success.upload_audio) {
      if (!InterviewState.audio_presigned_url) {
        return;
      }
      const url = InterviewState.audio_presigned_url.split("?");
      const data = {
        feedback_message: url[0],
        duration: duration,
      };

      dispatch(createInterviewDetailsFeedback({ body: data, id: videoId }));
      dispatch(clearInterviewAnswerSliceData());
      // setAudioUploadLoading(false);
      setShow(false);
    }
  }, [InterviewState.req_success.upload_audio]);

  return (
    <form className="right__content" onSubmit={handleSubmit(onSubmit)}>
      <div className="form_content">
        <TextArea
          name="feedback_message"
          className="custom__textarea responsive__text__area"
          register={register}
          error={errors?.feedback_message}
        />

        <div className="admin_rating">
          <p>Rating</p>
          <div className="star mb-1">
            {/*  @ts-ignore */}
            <Rating
              quiet
              initialRating={ratingValue}
              emptySymbol={<Star color="#FFC600" />}
              fullSymbol={<Star color="#FFC600" fill="#FFC600" />}
              onClick={(i) => ratingHandler(i)}
            />
            <ErrorText error={errors.rating?.message} />
          </div>
        </div>
        {!showAudioFeedback && (
          <div className="feedback__footer__section">
            <div
              className="back__icon__div"
              role="button"
              aria-hidden
              onClick={() => setShow(false)}
            >
              <ChevronLeft className="back__icon" />
            </div>
            {audioUploadLoading && (
              <PrimaryButton text="" type="button" loading />
            )}
            {!audioUploadLoading && (
              <PrimaryButton text="Submit" type="submit" />
            )}

            {!audioUploadLoading && (
              <div
                className="recored__feedback__icon"
                role="button"
                aria-hidden
                onClick={startRecording}
              >
                <Mic className="audio__mic" />
              </div>
            )}
          </div>
        )}

        {showAudioFeedback && (
          <AudioFeedback
            recorderControls={recorderControls}
            handleAudioFeedback={handleAudioFeedback}
          />
        )}
      </div>
    </form>
  );
};

export default RecentFeedback;
