/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";

import * as yup from "yup";
import { ChevronLeft, Edit2, UserX, X } from "react-feather";
import userimage from "../../../../assets/icons/user.png";
import { Card25 } from "../../../../shared/Card/Card25/Card25";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import SelectField from "../../../../shared/Inputs/SelectField/SelectField";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import SelectGroupHeader from "./SelectGroupHeader";
import {
  Deletemember,
  editMember,
  getMemberDetails,
} from "../../../../store/features/Tenant/Orgination/Member/MemberApi";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";

const Header = (props: any) => {
  const { memberState, hideEdit } = props;
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice.tac_user;
  });
  const options = [
    { value: "admin", label: "Admin" },
    { value: "student", label: "Student" },
  ];
  // const handleScreen = () => {};

  const validationSchema = yup.object().shape({
    role: yup.object().required(REQUIRED_VALIDATION("Member role")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const onSubmit = (value: any) => {
    const data = {
      role: value.role.value,
      groups: selectedGroupId,
    };
    setReloadPage(true);
    dispatch(editMember({ data: data, memberId: memberState.member.user.id }));
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    setValue("role", {
      value: memberState.member.user.role,
      label:
        memberState.member.user.role.charAt(0).toUpperCase() +
        memberState.member.user.role.slice(1),
    });
  }, []);

  useEffect(() => {
    if (memberState.req_success.edit && reloadPage) {
      setReloadPage(false);
      dispatch(getMemberDetails({ memberId: Number(params.id) }));
    }
  }, [memberState.req_success.edit]);

  useEffect(() => {
    if (memberState.req_success.delete) {
      navigate("/members");
    }
  }, [memberState.req_success.delete]);

  return (
    <div className="member__detail__header__section">
      <div className="naigation_section">
        <ChevronLeft
          className="back__button"
          onClick={() => navigate("/members")}
        />
        <span className="member__detail__topography">Member details</span>
      </div>
      <Card25>
        <div className="card__padding__section">
          <div className="top__section">
            <div className="avatar__aligner">
              {" "}
              <div className="name_and_avatar_section">
                <img
                  src={memberState.member.user.avatar_url ?? userimage}
                  className="dash_team_avatar"
                  alt="name"
                />
                <div className="name__section">
                  <span className="name"> {memberState.member.user.name} </span>
                  <span className="user_name">
                    {memberState.member.user.email}
                  </span>
                </div>
              </div>
            </div>

            <div className="aligner">
              <div className="role__section">
                <span className="simple__title">Role</span>
                <span className="simple__text">
                  {memberState.member.user.role}
                </span>
              </div>

              <div className="group__section">
                <span className="simple__title">Groups</span>
                {memberState.min_group.map((group: any, index: number) => {
                  return (
                    <div className="group__name" key={group.id}>
                      <span className="dot">.</span> <span>{group.name}</span>
                    </div>
                  );
                })}

                {memberState.max_group.length > 0 && (
                  <div className="view__more__groups__section">
                    <span className="view__more__text">View all groups </span>

                    <div className="group__list__card">
                      {memberState.max_group.map((group: any) => {
                        return (
                          <div className="group__name" key={group.id}>
                            <span className="dot">.</span>{" "}
                            <span>{group.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="assigned__course__section">
                <span className="simple__text num">
                  {memberState.member?.assigned_courses}
                </span>
                <span className="simple__title">Assigned courses</span>
              </div>
              <div className="course__porgress__section">
                <span className="total__assigned__course">Course Progress</span>
                <ProgressBar
                  completed={memberState.member.course_progress}
                  height="3px"
                  customLabel="."
                  baseBgColor="var( --primary-progress-bar-bg)"
                  bgColor="var( --secondary-progress-bar-bg)"
                  labelSize="0"
                  width="150px"
                />
                <span className="completed__text__precent">
                  {memberState.member.course_progress}% completed
                </span>
              </div>
            </div>
          </div>
          {!hideEdit && (
            <>
              {" "}
              <div className="header__divider__section" />
              <div className="bottom__section">
                <div
                  className="left__edit__section"
                  onClick={() => setShowMemberModal(true)}
                >
                  <Edit2 className="edit__icon" />
                  Edit member
                </div>
                <div className="right__acive__section">
                  Last active on {memberState.member.user.last_active}
                </div>
              </div>
            </>
          )}
        </div>
      </Card25>

      <Modal
        show={showMemberModal}
        setShow={setShowMemberModal}
        // resetForm={reset}
        // onClick={handleModal}
      >
        <ModalHeader title="Edit member" />
        <ModalBody>
          <form
            // className="user__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <SelectGroupHeader
              title="Group"
              groupState={groupState}
              setSelectedGroupId={setSelectedGroupId}
              memberState={memberState}
            />

            {/* <div className="member__existing__group"> */}
            {/* <span className="existing__groyp__title">Existing group</span> */}
            {/* <div className="existing__group__list">
                {" "}
                <div className="existing__group">
                  <span>Scrum master</span> <X className="x" />
                </div>
                <div className="existing__group">
                  <span>Scrum master</span> <X className="x" />
                </div>
                <div className="existing__group">
                  <span>Scrum master</span> <X className="x" />
                </div>
              </div> */}
            {/* <Tags
                dataArray={dataArray}
                updateDataArray={setDataArray}
                transparent
              /> */}
            {/* </div> */}

            <div className="mt-2" />
            {/* <SelectField
              type="text"
              label="Member role"
              options={options}
              placeholder="Admin"
              register={register}
              error={errors?.role}
              {...field}
            /> */}
            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <SelectField
                  type="text"
                  label="Member role"
                  options={options}
                  register={register}
                  error={errors?.role}
                  {...field}
                />
              )}
            />
            <div className="header__member__modal_footer">
              {authState.role === "owner" ? (
                <div
                  className="remove__access__text"
                  onClick={() => {
                    setDeleteShowModal(true);
                  }}
                >
                  {" "}
                  <UserX className="user_x_red" /> Remove access?
                </div>
              ) : (
                <div />
              )}
              <div>
                <PrimaryButton
                  text="Save"
                  type="submit"
                  loading={memberState.loading.edit}
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={memberState.loading.delete}
        onClick={() =>
          dispatch(Deletemember({ memberId: memberState.member.user.id }))
        }
      />
    </div>
  );
};

export default Header;
