import React, { useEffect, useLayoutEffect, useState } from "react";
import { Moon, Sun } from "react-feather";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  THEME_MODE,
  THEME_NAME,
} from "../../../helpers/utils/Constants/themeName";
import { ChangeThemeMode } from "../../../store/features/ThemeSlice";
import "./themeswitching.scss";
import { setThemeName } from "../../../store/features/Auth/AuthSlice";

const ThemeSwitchingButton = () => {
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const dispatch = useAppDispatch();

  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") || "dark"
  );

  useLayoutEffect(() => {
    const themName: any = localStorage.getItem("officialThemeName");
    dispatch(setThemeName(themName));
  }, []);

  useEffect(() => {
    // for default
    if (authState.theme_name === THEME_NAME.default && themeMode === "light") {
      localStorage.setItem("themeName", THEME_MODE.light);
      localStorage.setItem("officialThemeName", THEME_NAME.default);
    }

    if (authState.theme_name === THEME_NAME.default && themeMode === "dark") {
      localStorage.setItem("themeName", THEME_MODE.dark);
      localStorage.setItem("officialThemeName", THEME_NAME.default);
    }
    // for indigo
    if (authState.theme_name === THEME_NAME.Indigo && themeMode === "light") {
      localStorage.setItem("themeName", THEME_MODE.indigo_light);
      localStorage.setItem("officialThemeName", THEME_NAME.Indigo);
    }
    if (authState.theme_name === THEME_NAME.Indigo && themeMode === "dark") {
      localStorage.setItem("themeName", THEME_MODE.indigo_dark);
      localStorage.setItem("officialThemeName", THEME_NAME.Indigo);
    }

    // for mistGrey
    if (
      authState.theme_name === THEME_NAME.MistyGrey &&
      themeMode === "light"
    ) {
      localStorage.setItem("themeName", THEME_MODE.mistyGrey_light);
      localStorage.setItem("officialThemeName", THEME_NAME.MistyGrey);
    }
    if (authState.theme_name === THEME_NAME.MistyGrey && themeMode === "dark") {
      localStorage.setItem("themeName", THEME_MODE.mistyGrey_dark);
      localStorage.setItem("officialThemeName", THEME_NAME.MistyGrey);
    }

    // for azure

    if (authState.theme_name === THEME_NAME.Azure && themeMode === "light") {
      localStorage.setItem("themeName", THEME_MODE.azure_light);
      localStorage.setItem("officialThemeName", THEME_NAME.Azure);
    }
    if (authState.theme_name === THEME_NAME.Azure && themeMode === "dark") {
      localStorage.setItem("themeName", THEME_MODE.azure_dark);
      localStorage.setItem("officialThemeName", THEME_NAME.Azure);
    }
  }, [themeMode]);

  useEffect(() => {
    const themName: any = localStorage.getItem("themeName");
    document.body.className = "";
    document.body.classList.add(themName);
  }, []);

  const callDarkSetup = (darkMode: string) => {
    localStorage.setItem("theme", "dark");
    setThemeMode("dark");
    dispatch(ChangeThemeMode("dark"));
    document.body.className = "";
    document.body.classList.add(darkMode);
  };

  const callLightSetup = (lightMode: string) => {
    localStorage.setItem("theme", "light");
    setThemeMode("light");
    dispatch(ChangeThemeMode("light"));
    document.body.className = "";
    document.body.classList.add(lightMode);
  };

  const handleThemeMode = () => {
    // for default
    if (authState.theme_name === THEME_NAME.default && themeMode === "light") {
      callDarkSetup(THEME_MODE.dark);
    }
    if (authState.theme_name === THEME_NAME.default && themeMode === "dark") {
      callLightSetup(THEME_MODE.light);
    }
    // for indigo
    if (authState.theme_name === THEME_NAME.Indigo && themeMode === "light") {
      callDarkSetup(THEME_MODE.indigo_dark);
    }
    if (authState.theme_name === THEME_NAME.Indigo && themeMode === "dark") {
      callLightSetup(THEME_MODE.indigo_light);
    }

    // for mistgrey
    if (
      authState.theme_name === THEME_NAME.MistyGrey &&
      themeMode === "light"
    ) {
      callDarkSetup(THEME_MODE.mistyGrey_dark);
    }
    if (authState.theme_name === THEME_NAME.MistyGrey && themeMode === "dark") {
      callLightSetup(THEME_MODE.mistyGrey_light);
    }

    // for azure
    if (authState.theme_name === THEME_NAME.Azure && themeMode === "light") {
      callDarkSetup(THEME_MODE.azure_dark);
    }
    if (authState.theme_name === THEME_NAME.Azure && themeMode === "dark") {
      callLightSetup(THEME_MODE.azure_light);
    }
  };

  return (
    <button
      type="button"
      className={`theme__mode__button ${themeMode}`}
      onClick={handleThemeMode}
    >
      {themeMode === "light" && <Moon className="moon__icon" />}
      {themeMode === "dark" && <Sun className="sun__icon " />}
    </button>
  );
};

export default ThemeSwitchingButton;
