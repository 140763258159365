/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import moment from "moment";

import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../../shared/Table/Table";
import Avatar from "../../../../../shared/Avatar/Avatar";
import userImage from "../../../../../assets/icons/user.png";
import DeleteModal from "../../../../../shared/DeleteModal/DeleteModal";
import EditModal from "./EditModal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  deleteUsers,
  getAllTrialUser,
} from "../../../../../store/features/AdminPanel/User/UserApi";
import { roles } from "../../../../../helpers/utils/Constants/roles";

const TrialUserContent = (props: any) => {
  const userState: any = useAppSelector((store: RootStore) => {
    return store.UserSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const dispatch = useAppDispatch();

  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userId, setUserId] = useState<any>("");
  const [user, setUser] = useState<any>("");
  const [userEmail, setUserEmail] = useState<any>("");

  const openDeleteModal = (userId: number,userEmail: any) => {
    setDeleteShowModal(true);
    setUserId(userId);
    setUserEmail(userEmail);
  };

  const deleteUser = () => {
    dispatch(deleteUsers(userId));
  };

  const editUser = (user: any) => {
    setUser(user);
    setShowEditModal(true);
  };

  useEffect(() => {
    dispatch(getAllTrialUser());
  }, []);
  useEffect(() => {
    if (!userState.deleteLoading && userState.delete_success) {
      setDeleteShowModal(false);
    }
  }, [userState]);

  return (
    <div className="user__table__section__div">
      <Table
        full
        heading={
          <TableHeading>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>LinkedIn Url</th>
            <th>Location</th>
            <th>Role</th>
            <th>Created At</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {!userState.loading &&
            userState.users.map((user: any) => {
              return (
                <tr key={user.id}>
                  <td className="flex_td">
                    <div className="avatar__section">
                      <Avatar
                        imageUrl={user.avatar_url ?? userImage}
                        // size={sm}
                      />
                    </div>
                    {user.name}
                  </td>
                  <td className="email_td">{user.email}</td>
                  <td>{user.phone_number}</td>
                  <td>
                    <a
                      href={user.linkedin_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {user.linkedin_url}
                    </a>
                  </td>
                  <td>{user.location || "-"}</td>
                  <td>
                    {user.user_role.name
                      .split("_")
                      .map(
                        (word: any) =>
                          word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </td>
                  <td>{moment(user.created_at).format("ll")}</td>

                  <TableAction
                    onEdit={() => editUser(user)}
                    onDelete={() => openDeleteModal(user.id, user.email)}
                    hideDelete={authState.tac_user?.role !== roles.SYSTEM_ADMIN}
                  />
                </tr>
              );
            })}
        </TableBody>
      </Table>
      <EditModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        user={user}
      />

      <DeleteModal
        message={`The user with "${userEmail}" email address is about to be deleted. Please confirm this action as you won't be able to revert this action.`}
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        onClick={deleteUser}
        loading={userState.deleteLoading}
      />
    </div>
  );
};
export default TrialUserContent;
