import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import OnwerProfileAllTab from "../../../components/Orgination/OwnerProfile/Tab/OnwerProfileAllTab";

const TenantOwnerProfile = () => {
  return (
    <AdminPanelLayout>
      <OnwerProfileAllTab />
    </AdminPanelLayout>
  );
};

export default TenantOwnerProfile;
