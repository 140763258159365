/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo, useEffect, useState } from "react";
import "./index.scss";
import AudioPlayer from "../../../../../shared/AudioPlayer/AudioPlayer";

const PreviewRecordedAudio = (props: any) => {
  const { blob,audioDuration } = props;
  const url = URL.createObjectURL(blob);
  // const [currentUrl,setCurrentUrl] = useState("");
  // console.log(url);
  // useEffect(()=>{
  //   setCurrentUrl(url);
  // },[blob])
  return (
    <div className="preview__audio__wrapper">
      <div className="preview__audio__container">
        <audio src={url} controls   />
        {/* <div className="audio__controls__wrapper">
          <AudioPlayer duration={audioDuration} url={url}/>
        </div> */}
      </div>
    </div>
  );
};

export default memo(PreviewRecordedAudio);
