import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import OrgUserLearningPath from "../../../components/OrginationUser/UserLearningPath/OrgUserLearningPath";

const OrgUserLearningPathPage = () => {
  return (
    <AdminPanelLayout>
      <OrgUserLearningPath />
    </AdminPanelLayout>
  );
};

export default OrgUserLearningPathPage;
