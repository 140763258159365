import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import PaymentHeader from "../../../components/AdminPanel/PaymentPlan/PaymentHeader/PaymentHeader";
import PaymentTable from "../../../components/AdminPanel/PaymentPlan/PaymentTable/PaymentTable";
import MeetHeader from "../../../components/MeetWithUs/MeetHeader/MeetHeader";
import MeetTable from "../../../components/MeetWithUs/MeetTable/MeetTable";

const MeetWithUs = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="mt-7">
          <MeetHeader />
          <MeetTable />
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default MeetWithUs;
