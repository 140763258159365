import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";

export const FaqArray = [
  {
    id: 1,
    name: "Business Analyst",
    value: "business_analyst",
  },

  {
    id: 2,
    name: "Product Owner",
    value: "product_owner",
  },

  {
    id: 3,
    name: "Scrum Master",
    value: "scrum_master",
  },
  {
    id: 4,
    name: "Landing Page",
    value: "landing_page ",
  },
  {
    id: 5,
    name: "User Dashboard",
    value: "user_dashboard ",
  },
];

export const FAQFilterForTACOnly = [
  {
    id: 1,
    name: "Landing Page",
    value: "landing_page",
  },
  {
    id: 2,
    name: "Product Manager",
    value: "product_manager",
  },
  {
    id: 3,
    name: "ICP",
    value: "icp ",
  },
  {
    id: 4,
    name: "Agile Team Facilitator",
    value: "agile_team_facilitator",
  },
  {
    id: 5,
    name: "ICP-ACC",
    value: "icp_acc",
  },
  {
    id: 6,
    name: "ICP-APO",
    value: "apo"
  },
];

const validationSchema = yup.object().shape({
  question: yup.string().required(REQUIRED_VALIDATION("Question")),
  answer: yup.string().required(REQUIRED_VALIDATION("Answer")),
});

export const formOptions: any = {
  resolver: yupResolver(validationSchema),
  mode: "onTouched",
  reValidateMode: "onChange",
};
