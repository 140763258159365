import React, { useState } from "react";
import VideoTourHeader from "./VideoTourHeader/VideoTourHeader";
import VideoTourBody from "./VideoTourBody/VideoTourBody";

const VideoTourComp = () => {
  const [showModal, setShowModal] = useState(false);
  
  return (
    <div className="mt-7">
      <VideoTourHeader
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <VideoTourBody />
    </div>
  );
};

export default VideoTourComp;
