/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  no-plusplus */
import "./index.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { Clock } from "react-feather";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { convertStringToDate } from "../../../../helpers/utils/dateTime";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { addNewClassArchive } from "../../../../store/features/AdminPanel/Archives/ArchiveApi";
import {
  closeModal,
  setShowModal,
} from "../../../../store/features/AdminPanel/Archives/ArchiveSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../../helpers/utils/Constants/roles";
import UploadBulkArciveModal from "../UploadBulkArchive/UploadBulArciveModal/UploadBulkArciveModal";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";

const ArchiveHeader = () => {
  const [date, setDate] = useState<string | null>("");
  const [batchDate, setBatchDate] = useState<string | null>("");
  const [others, setOthers] = useState(false);
  const [batchAndOthersError, setbatchAndOthersError] = useState("");
  const [startTime, setStartTime] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<string | null>(null);
  const [timeError, setTimeError] = useState<string | null>("");

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const dispatch = useAppDispatch();

  const archiveState: any = useAppSelector((store: RootStore) => {
    return store.ArchiveSlice;
  });

  useEffect(() => {
    setTimeError("");
    if (
      (endTime === null || endTime === "") &&
      (startTime === null || startTime === "") &&
      (date === "" || date === null)
    ) {
      setTimeError("");
      return;
    }
    if (date === "" || date === null) {
      setTimeError("Date field required");
      return;
    }
    setTimeError("");
    if (startTime && (endTime === null || endTime === "")) {
      setTimeError("End Time required");
      return;
    }
    if (endTime && (startTime === null || startTime === "")) {
      setTimeError("Start Time required");
      return;
    }
    if (endTime && startTime) {
      setTimeError("");
      const arr0 = startTime.split(":");
      const arr1 = endTime.split(":");
      const arr0InMinutes = Number(arr0[0]) * 60 + Number(arr0[1]);
      const arr1InMinutes = Number(arr1[0]) * 60 + Number(arr1[1]);
      if (arr0InMinutes >= arr1InMinutes) {
        setTimeError("Invalid time range");
      } else {
        setTimeError("");
      }
    }
  }, [startTime, endTime, date]);

  const handleDateChange = (e: any) => {
    setDate(e.target.value);
  };

  const handleStartTime = (e: any) => {
    setStartTime(e.target.value);
  };

  const handleEndTime = (e: any) => {
    setEndTime(e.target.value);
  };

  const handleBatchDateChange = (e: any) => {
    setBatchDate(e.target.value);
    setOthers(false);
  };

  const handleConinuousChange = () => {
    setOthers(true);
  };

  useEffect(() => {
    if (others) {
      setBatchDate("");
    }
  }, [others]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    document.getElementsByName("date")[0].setAttribute("max", today);
  }, [startTime, endTime]);

  useEffect(() => {
    if (archiveState.isEdit) {
      setValue("date", convertStringToDate(archiveState.current_archive.date));
      setDate(convertStringToDate(archiveState.current_archive.date));
      setStartTime(archiveState.current_archive.time_from);
      setEndTime(archiveState.current_archive.time_to);
      setValue("topic", archiveState.current_archive.topic);
      setValue("video_link", archiveState.current_archive.video_link);

      setBatchDate(archiveState.current_archive?.batch?.slice(0, 7));
      setOthers(archiveState.current_archive?.continuos_learning === 1);
    } else {
      setTimeError("");
    }
  }, [archiveState?.isEdit]);

  const onSubmit = (values: any) => {
    if (timeError!.length) {
      return;
    }

    if (!batchDate && !others) {
      setbatchAndOthersError("Choose at least one field");
      return;
    }
    const data = {
      ...values,
      time_from: startTime,
      time_to: endTime,
       continuos_learning: others,
      batch: batchDate ? `${batchDate}-01` : null,
    };
    console.log(batchDate,data);

    if (archiveState.current_archive?.id && archiveState.isEdit)
      data.id = archiveState.current_archive.id;

    dispatch(addNewClassArchive({ data }));
  };


  const handleModal = () => {
    reset();

    setStartTime(null);
    setEndTime(null);
    setDate("");
    setTimeError("");
    dispatch(closeModal());
    // setActive("");
    setBatchDate(null);
    setOthers(false);
    setbatchAndOthersError("");
  };

  const validationSchema = yup.object().shape({
    topic: yup.string().required(REQUIRED_VALIDATION("Topic")),
    video_link: yup.string().required(REQUIRED_VALIDATION("Video Link")),
    date: yup.string().required(REQUIRED_VALIDATION("Date")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const onError = (error: any) => {
    console.log("ERROR:::", error);
    if (error.date) {
      setTimeError(error.date?.message);
    }
  };


  useEffect(() => {
    if (batchDate || others) {
      setbatchAndOthersError("");
    }
  }, [batchDate, others]);

  useEffect(() => {
    if (archiveState.req_success.post) {
      handleModal();
    }
  }, [archiveState.req_success.post]);

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Class Archive</TableHeadingTitle>
      </div>
      <div className="right__section responseve__style">
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            disable={archiveState.loading.post}
            onClick={() => dispatch(setShowModal())}
          />
        </div>
        {/* {configState.configs?.membership_enabled === Domain.TAA &&
          authState.tac_user?.role === roles.SYSTEM_ADMIN && (
            <div className="file__upload__btn">
              <PrimaryButton
                text="Upload Bulk Archive "
                size="sm"
                type="button"
                loading={false}
                onClick={() => setShowFileUploadModal(true)}
              />
            </div>
          )} */}
      </div>
      <Modal
        show={archiveState.showModal}
        setShow={setShowModal}
        resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader title={archiveState?.isEdit ?"Edit Class Archive":"Add Class Archive"} />
        <ModalBody>
          <form
            className="user__form__container ar-form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="archive__row">
              <div className="date__width">
                <TextField
                  label="Date"
                  type="date"
                  placeholder="mm/dd/yyyy"
                  name="date"
                  onChange={handleDateChange}
                  register={register}
                  onKeyDown={() => false}
                  max={new Date().toISOString().split("T")[0]}

                  // error={errors?.archive_date}
                />
              </div>
              <div className="time__row__new">
                <div className="time-box">
                  <span>Time (From)</span>
                  <input
                    type="time"
                    className="timeInput"
                    name=""
                    id="timeInput"
                    required
                    value={startTime || ""}
                    onChange={handleStartTime}
                  />
                </div>
                <div className="time-box">
                  <span>Time (To)</span>
                  <input
                    type="time"
                    className="timeI  nput"
                    name=""
                    id=""
                    required
                    value={endTime || ""}
                    onChange={handleEndTime}
                  />
                </div>
              </div>
            </div>
            <ErrorText error={timeError} TextYellow />
            <div style={{ height: "1rem" }} />
            <TextField
              type="text"
              label="Topic"
              name="topic"
              register={register}
              error={errors?.topic}
            />

            <TextField
              type="text"
              label="Video Link"
              name="video_link"
              register={register}
              error={errors?.video_link}
            />
            <div className="month__year__wrapper">
              <div className="date__width">
                <TextField
                  label="Batch"
                  type="month"
                  value={batchDate || ""}
                  name="batch"
                  required
                  onChange={handleBatchDateChange}
                  register={register}
                  onKeyDown={() => false}
                  max={new Date().toISOString().split("T")[0]?.slice(0, 7)}
                />
              </div>
              <div className="checBoxWrapper">
                <CheckBox
                  name="others"
                  onClick={() => handleConinuousChange()}
                  label="Continuous Learning"
                  value={others}
                />
              </div>
            </div>
            {batchAndOthersError && <ErrorText error={batchAndOthersError} />}

            <div className="modal_footer">
              <div className="add_button__witdh mt-1">
                <PrimaryButton
                  text={archiveState.isEdit ? "Update" : "Add"}
                  type="submit"
                  loading={archiveState.loading.post}
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <UploadBulkArciveModal
        showFileUploadModal={showFileUploadModal}
        setShowFileUploadModal={setShowFileUploadModal}
      />
    </div>
  );
};

export default ArchiveHeader;
