import React from "react";
import "./navigateIntoInterviewPopup.scss";
import { X } from "react-feather";
import {  useNavigate } from "react-router-dom";
import interviewPopupImg from "../../../../../assets/images/interview-popup.svg";

const NavigateIntoInterviewPopup = (props:any) => {
  const {handlePopUp} = props;
  const navigate = useNavigate();

  const closeNavigateIntoInterviewPopup = ()=>{
    handlePopUp();
  
  }
  const navigateIntoInterview=()=>{
    navigate("/interview-preparation-instruction")
    handlePopUp();

  }
  return (
    <div className="navigate__into__interview__popup__wrapper">
      <div className="popup__close__icon__wrapper">

      <X className="popup__close" onClick={closeNavigateIntoInterviewPopup} />
      </div>
      <div className="popup__container">
       <div className="image__wrapper"> <img src={interviewPopupImg} alt="" /></div>
        <p>Introducing Interview Preparation! </p>
        <span>
          Users can access the platform and choose a specific industries or job
          roles to tailor the interview scenarios to their unique needs. Each
          scenario is presented with detailed context.
        </span>
        <button type="button" onClick={navigateIntoInterview} >Show me</button>
      </div>
    </div>
  );
};

export default NavigateIntoInterviewPopup;
