/* eslint-disable @typescript-eslint/naming-convention */
import { lazy } from "react";

import MyProgressCourse from "../../pages/UserDashboard/MyProgressCourse/MyProgressCourse";
import InterviewsAndWebinars from "../../pages/UserDashboard/InterviewsAndWebinars/InterviewsAndWebinars";
import ViewCourse from "../../pages/UserDashboard/ViewCourse/ViewCourse";
import ListCourses from "../../pages/UserDashboard/ListCourses/ListCourses";
import LearningPath from "../../pages/UserDashboard/LearningPath/LearningPath";
import LearningPathStepper from "../../pages/UserDashboard/LearningPath/LearningPathStepper";
import UserResources from "../../pages/UserDashboard/Resources/UserResources";
import { ROUTES } from "../../helpers/utils/Constants/route";
import Certificates from "../../pages/UserDashboard/Certificate/certificates";
import UserProfile from "../../pages/UserDashboard/UserProfile/UserProfile";
import AudioPodcast from "../../pages/UserDashboard/AudioPodcast/AudioPodcast";

import TacAiAssist from "../../pages/UserDashboard/TacAiAssist/TacAiAssist";
import TacAiConversation from "../../pages/UserDashboard/TacAiAssist/TacAiConversation";
import ProductManagerCourses from "../../pages/ProductManagerUser/ProductManagerCourses/ProductManagerCourses";
import UserProfiles from "../../pages/AdminPanel/User/profile";
import AiAssist from "../../pages/NewAcademicUser/AiAssist/AiAssist";
import AiConservation from "../../pages/NewAcademicUser/AiAssist/AiConservation";

const MyProgress = lazy(
  () => import("../../pages/UserDashboard/MyProgress/MyProgress")
);

const RoutesOnlyForTAC = [
  // { id: 1, path: ROUTES.PROGRESS, component: MyProgress },
  // { id: 2, path: ROUTES.PROGRESS_COURSE, component: MyProgressCourse },
  { id: 3, path: ROUTES.LEARNING_PATH, component: LearningPath },
  {
    id: 4,
    path: ROUTES.LEARNING_PATH_SETPPER,
    component: LearningPathStepper,
  },
  { id: 5, path: ROUTES.LIST_COURSES, component: ListCourses },
  {
    id: 6,
    path: ROUTES.VIEW_COURSES,
    component: ViewCourse,
  },
  { id: 7, path: ROUTES.USER_RESOURCES, component: UserResources },
  {
    id: 8,
    path: ROUTES.INTERVIEW_AND_WEBINARS,
    component: InterviewsAndWebinars,
  },
  // { id: 9, path: ROUTES.TAC_AI_ASSIST, component: TacAiAssist },
  // { id: 10, path: ROUTES.TAC_AI_CONVERSATION, component: TacAiConversation },
  // {
  //   id: 11,
  //   path: ROUTES.TAC_AI_CONVERSATION_WITH_HASHID,
  //   component: TacAiConversation,
  // },
  {
    id: 32,
    path: `${ROUTES.MY_PROFILE}/:tag`,
    component: UserProfile,
  },

  {
    id: 33,
    path: ROUTES.MY_CERTIFICATE,
    component: Certificates,
  },

  // {
  //   id: 33,
  //   path: ROUTES.PODCAST,
  //   component: AudioPodcast,
  // },
];

export const ROUTES_ONLY_FOR_TAC_PRODUCT_MANAGER = [
  { id: 1, path: ROUTES.PROGRESS, component: MyProgress },
  { id: 2, path: ROUTES.PROGRESS_COURSE, component: MyProgressCourse },
  {
    id: 3,
    path: ROUTES.PRODUCT_MANAGER_COURSES,
    component: ProductManagerCourses,
  },
  { id: 5, path: ROUTES.AI_ASSIST, component: AiAssist },
  { id: 6, path: ROUTES.AI_CONVERSATION, component: AiConservation },
  {
    id: 7,
    path: ROUTES.AI_CONVERSATION_WITH_HASHID,
    component: AiConservation,
  },

  // { id: 9, path: ROUTES.TAC_AI_ASSIST, component: TacAiAssist },
  // { id: 10, path: ROUTES.TAC_AI_CONVERSATION, component: TacAiConversation },
  // {
  //   id: 11,
  //   path: ROUTES.TAC_AI_CONVERSATION_WITH_HASHID,
  //   component: TacAiConversation,
  // },
  {
    id: 32,
    path: ROUTES.PRODUCT_MANAGER_PROFILE,
    component: UserProfiles,
  },

  {
    id: 33,
    path: ROUTES.PODCAST,
    component: AudioPodcast,
  },
];

export const  PREMIUM_COURSES_ROUTES = [  
  { id: 35, path: ROUTES.LIST_ALL_COURSES, component: ListCourses },
  {
    id: 36,
    path:ROUTES.ALL_PREMIUM_COURSES,
    component: ListCourses,
  },
]

export default RoutesOnlyForTAC;
