import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import UserCourseDetail from "../../../components/OrginationUser/UserCourseDetail/UserCourseDetail";
import UserLearningPathCourses from "../../../components/OrginationUser/UserLearningPathCourse/UserLearningPathCourses";

const OrgUserCourseDetailPage = () => {
  return (
    <AdminPanelLayout>
      <UserCourseDetail />
    </AdminPanelLayout>
  );
};

export default OrgUserCourseDetailPage;
