/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";

import "./index.scss";
import { removeTags } from "../../../../helpers/utils/StringHelper";
import {
  addOrUpdateWeeklyLearning,
  getWeeklyLearningData,
} from "../../../../store/features/AdminPanel/WeeklyLearning/WeeklyLearningApi";
import {
  closeWeeklyEditModal,
  searchWeeklyPlans,
} from "../../../../store/features/AdminPanel/WeeklyLearning/WeeklyLearningSlice";
import SelectDropDown from "../../../../shared/SelectDropDown/SelectDropDown";
import { filterArray, filterArrayForTAC } from "./helper";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const WeeklyLearningHeader = () => {
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  interface Ivalue {
    id: number;
    name: string;
    value: string;
  }
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState<Ivalue>(
    configState.configs?.membership_enabled === Domain.TAC
      ? filterArrayForTAC[0]
      : filterArray[0]
  );

  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useAppDispatch();
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const weeklyState: any = useAppSelector((store: RootStore) => {
    return store.WeeklySlice;
  });

  const editorConfig: any = useMemo(
    () => ({
      readonly: false,
      height: "300px",
      width: "100%",
      buttons: [
        "source",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "ul",
        "ol",
        "|",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        "|",
        "image",
        "table",
        "link",
        "|",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "undo",
        "redo",
        "|",
        "hr",
        "eraser",
        "fullsize",
      ],
      removeButtons: [
        "brush",
        "file",
        "eraser",
        "hr",
        "redo",
        "undo",
        "justify",
        "right",
        "center",
        "justify",
        "left",
        "link",
        "table",
        "image",
        "paragraph",
        "brush",
        "fontsize",
        "font",
        "source",
        "fullsize",
        "|",
      ],
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: true,
      toolbarAdaptive: false,
      toolbarSticky: false,
      spellcheck: true,
      theme: themeState.themeMode === "dark" ? "dark" : "default",
      i18n: "en",
      // limitWords: 3,
      // autofocus: true,
      // cursorAfterAutofocus: "end",
      // saveSelectionOnBlur: true,
      style: {
        fontFamily: "Montserrat !important",
        textAlign: "justify",
      },
    }),
    []
  );
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const validationSchema = yup.object().shape({
    // week_interval: yup.string().required(REQUIRED_VALIDATION("week interval")),
    title: yup.string().nullable(true),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const validateDescrption = (): boolean => {
    let error = false;
    const char = removeTags(content);
    if (!char || char.length < 1) {
      setErrorMessage("Description is required");
      error = true;
    } else {
      setErrorMessage("");
      error = false;
    }

    return error;
  };

  const onSubmit = (values: any) => {
    const error = validateDescrption();
    if (error) {
      return;
    }

    const data = { ...values };
    data.description = content;
    data.for = selectedValue.value;
    if (weeklyState.edit) {
      data.id = weeklyState.singleData.id;
    }
    dispatch(
      addOrUpdateWeeklyLearning({
        data: data,
        idForUpdate: weeklyState.edit ? weeklyState.singleData.id : 0,
      })
    );
  };

  useEffect(() => {
    dispatch(searchWeeklyPlans(searchText));
  }, [searchText]);

  useEffect(() => {
    if (weeklyState.req_success.add) {
      reset();
      setContent("");
      setErrorMessage("");
      setShowModal(false);
    }
  }, [weeklyState.req_success.add]);

  const onError = (error: any) => {
    validateDescrption();
    console.log("ERROR:::", error);
  };

  const handleOption = (data: Ivalue) => {
    setSelectedValue(data);
    dispatch(getWeeklyLearningData({ params: data.value }));
  };
  useEffect(() => {
    dispatch(getWeeklyLearningData({ params: selectedValue.value }));
  }, []);

  useEffect(() => {
    if (weeklyState.edit) {
      setValue("week_interval", weeklyState.singleData.week_interval);
      setValue("title", weeklyState.singleData.title);
      setContent(weeklyState.singleData.description);
      setShowModal(true);
    }
  }, [weeklyState?.edit, weeklyState?.weeklyState]);

  const handleModal = () => {
    reset();
    setContent("");
    setErrorMessage("");
    dispatch(closeWeeklyEditModal());
  };

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle> Weekly Learning For</TableHeadingTitle>
        <div className="dropdown__holder">
          <SelectDropDown
            data={
              configState.configs?.membership_enabled === Domain.TAC
                ? filterArrayForTAC
                : filterArray
            }
            selectedValue={selectedValue.name}
            handleOption={handleOption}
          />
        </div>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        // resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader title="Weekly Learning " />
        <ModalBody
        // modalWidth="900px"
        >
          <form
            onSubmit={handleSubmit(onSubmit, onError)}
            className="weekly__learning__form"
          >
            <div className="form_row">
              <div className="width__for__interval">
                {" "}
                <TextField
                  type="text"
                  label="Week Interval"
                  name="week_interval"
                  register={register}
                  // error={errors?.week_interval}
                />{" "}
              </div>
              <div className="width__for__title">
                {" "}
                <TextField
                  type="text"
                  label="Title"
                  name="title"
                  register={register}
                  error={errors?.title}
                />
              </div>
            </div>
            <span className="description__title"> Description</span>
            <div className="editor__wrapper">
              <JoditEditor
                ref={editor}
                value={content}
                config={editorConfig}
                onBlur={(newContent) => setContent(newContent)}
              />
              <ErrorText error={errorMessage} />
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton
                text={`${weeklyState.edit ? "Update" : "Submit"}`}
                type="submit"
                loading={weeklyState.loading.add}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default WeeklyLearningHeader;
