import React, { useState } from "react";
import "./productManagerUserDashboard.scss";
import Nav from "../../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import ImgBgSection from "../../../../components/NewAcademicUser/Dashboard/ImgBgSection/ImgBgSection";
import TextAndSelect from "../../../../components/NewAcademicUser/Dashboard/TextAndSelect/TextAndSelect";
import OurCourses from "../../../../components/NewAcademicUser/Dashboard/ProductManagerUserDashboard/OurCourses/OurCourses";
import PmUserProgressSection from "../../../../components/NewAcademicUser/Dashboard/ProductManagerUserDashboard/PmUserProgressSection/PmUserProgressSection";
import ArchiveTable from "../../../../components/NewAcademicUser/Archive/ArchiveTable";
import ProductManagerArchiveTimeTable from "../../../../components/NewAcademicUser/Dashboard/ProductManagerUserDashboard/ProductManagerArchive/ProductManagerArchiveTimeTable";
import Support from "../../../../components/NewAcademicUser/Dashboard/ProductManagerUserDashboard/Support/Support";
import DashboardFaq from "../../../../components/NewAcademicUser/Dashboard/Faq/Faq";

const ProductManagerUserDashboard = () => {

  return (
    <div>
      <Nav/>
      <div className="product__manager__user__wrapper">
        <ImgBgSection />
        <div className="product__manager__user__padding__wrapper">
          <TextAndSelect />
        </div>
        <div className="pm__our__courses__wrapper">
          <OurCourses />
        </div>
        <PmUserProgressSection/>
        <ProductManagerArchiveTimeTable/>
        <Support/>
       <div className="product__manager__dashboard__faq__wrapper">
       <DashboardFaq/>
       </div>
      </div>
    </div>
  );
};

export default ProductManagerUserDashboard;
