import React, { useEffect, useState } from "react";
import moment from "moment";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import Table, { TableBody, TableHeading } from "../../../shared/Table/Table";
import Empty from "../../../shared/Empty/Empty";
import PaginateButton from "../../../shared/PaginateButton/PaginateButton";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { getContactMessages } from "../../../store/features/AdminPanel/Subscribers/SubscriberApi";
import {
  contactMessagesTablePaginate,
  searchContactMessages,
} from "../../../store/features/AdminPanel/Subscribers/SubscriberSlice";
import "./index.scss";


const TrainingAGroupComp = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  const contactMessageState: any = useAppSelector((store: RootStore) => {
    return store.SubscriberSlice;
  });

  const handlePagination = (num: number) => {
    dispatch(contactMessagesTablePaginate(num));
  };
  useEffect(() => {
    dispatch(getContactMessages());
  }, []);

  useEffect(() => {
    dispatch(searchContactMessages(searchText));
  }, [searchText]);

  return (
    <div className="mt-7">
      <div className="subscribers-bottom ">
        <div className="sh-table-top">
          <div className="left">Group Users</div>
          <div className="right subscriber__table__head__right__section">
            <div className="searchbar__wrapper">
              {" "}
              <SearchBar value={searchText} setValue={setSearchText} />
            </div>
            {/* <div
              className="download__user__file__btn__width__handle"
              aria-hidden
              onClick={() => setShowUserFileDownload(!showUserFileDownload)}
              ref={downloadUserFilebtnRef}
            >
              <button className="download__user__file__btn" type="button">
                Download
              </button>
            </div>
            {showUserFileDownload && (
              <div
                className="download__user__file__download__dropdown__wrapper"
                ref={downloadUserFileDropdownRef}
              >
                <div className="download__user__file__download__dropdow">
                  <div className="checkbox__wrapper">
                    {" "}
                    <CheckBox
                      label="CSV"
                      tacCheckBoxvalue={csv}
                      onClick={() => setCsv(!csv)}
                    />
                  </div>
                  <div className="checkbox__wrapper">
                    <CheckBox
                      label="XLS"
                      tacCheckBoxvalue={xls}
                      onClick={() => setXls(!xls)}
                    />
                  </div>
                  <div className="download__btn__width">
                    <PrimaryButton
                      onClick={downloadFileForTAA}
                      text="Download"
                      loading={subscriberState.downloadLoading}
                    />
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className="subscriber__talbe__section">
          <Table
            heading={
              <TableHeading>
                <th>Name</th>
                <th>Email Address</th>
                <th>Message</th>
                <th className="">Mailed At</th>
                <th className="">Course</th>
                <th className="hide__text">.</th>
              </TableHeading>
            }
          >
            <TableBody>
              {contactMessageState?.contact_messages_data &&
                contactMessageState?.contact_messages_data.map((item: any) => {
                  return (
                    <tr key={item}>
                      <td className="subscriber_name">{item?.name}</td>
                      <td className="subscriber_name">{item?.email}</td>
                      <td className="subscriber_name">
                        <div className="contact__message__wrapper">
                          <p>
                            {item?.message.length > 30
                              ? `${item?.message.substring(0, 30)}... `
                              : item?.message}
                          </p>
                          {item?.message.length > 30 && (
                            <span>{item?.message}</span>
                          )}
                        </div>
                      </td>
                      <td className="">
                        {moment(item?.created_at).format("DD/MM/YYYY")}
                      </td>

                      <td className="">{item?.course}</td>
                      <td className="hide__text">.</td>
                    </tr>
                  );
                })}
            </TableBody>
          </Table>

          {contactMessageState?.contact_messages_data.length === 0 && (
            <div className="mt-5 handle__empty__container__div">
              <Empty emptyText="It seems empty." custombg={true} />
            </div>
          )}
        </div>
      </div>
      <div className="pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={contactMessageState?.all_contact_data?.length}
        />
      </div>
    </div>
  );
};

export default TrainingAGroupComp;
