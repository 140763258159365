/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

import { RootStore, useAppSelector } from "../../../store/store";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../helpers/utils/Constants/roles";

function CustomLink(props: any) {
  const {
    icon,
    sm,
    title,
    img,
    yimg,
    route,
    smSidebar,
    active,
    onClick,
    marginLeftimage,
  } = props;
  const navigate = useNavigate();

  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const { pathname } = useLocation();

  const handleRoute = (route: any) => {
    if (onClick) {
      onClick();
    } else {
      navigate(route);
    }
  };
  return (
    <div
      className="link__section2"
      onClick={() => handleRoute(route)}
      role="button"
      aria-hidden
    >
      <div className="image__section">
        {!icon ? (
          <img
            src={route === pathname ? yimg : img}
            alt="."
            className={`${marginLeftimage && "marginLeftimage"}`}
          />
        ) : (
          <Icon
            icon={icon}
            className={`sidebar__iconify__icon ${sm && "sm"} ${
              route === pathname || active ? "active" : ""
            }`}
          />
        )}
      </div>

      {configState.configs.membership_enabled === Domain.TAC &&
      (authState?.tac_user?.role === roles.USER ||
        authState?.tac_user?.role === roles.PRODUCT_MANAGER_STUDENT) ? (
        <span
          className={`linker ${smSidebar ? "hide_linker" : "linker"}  ${
            route === pathname || active ? "tac_active" : ""
          }`}
        >
          {title}
        </span>
      ) : (
        <span
          className={`linker ${smSidebar ? "hide_linker" : "linker"}  ${
            route === pathname || active ? "active" : ""
          }`}
        >
          {title}
        </span>
      )}
    </div>
  );
}

export default CustomLink;
