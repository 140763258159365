import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import UserAllTabs from "../../../components/AdminPanel/User/UserAllTabs/UserAllTabs";

const User = () => {
  return (
    <AdminPanelLayout>
      <UserAllTabs />
    </AdminPanelLayout>
  );
};

export default User;
