/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Upload } from "react-feather";
import { useDropzone } from "react-dropzone";
import { BUNNY_VIDEO_LIBRARY_ID } from "../../apiConfigs/urlConfig";
import "./index.scss";
import { DangerButton } from "../Buttons/Buttons";
import VideoProcessing from "../../components/NewAcademicUser/InterviewPreparation/VideoRecord/VideoProcessing";

const VideoUploader = (props: any) => {
  const {
    setVideoFileInfo,
    clearVideo,
    videoRef,
    selectedVideoFileUrl,
    setSelectedVideoFileUrl,
    videoTourState,
  } = props;
  const [localUrl, setLocalUrl] = useState(false);

  const onDrop = useCallback(async (acceptedFiles: any) => {
    const videoUrl = URL.createObjectURL(acceptedFiles[0]);
    setSelectedVideoFileUrl(videoUrl);
    setVideoFileInfo({ file: acceptedFiles[0] });

    // const extension = getFileExtension(acceptedFiles[0].name);
    // const base64Image = await getBase64(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    videoRef?.current?.load();
  }, [selectedVideoFileUrl]);

  const clearVideoCache = () => {
    clearVideo();
    setSelectedVideoFileUrl("");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      "video/mp4": [
        ".mp4",
        ".MP4",
        ".avi",
        ".AVI",
        ".MKV",
        ".mkv",
        ".mov",
        ".MOV",
      ],
    },
  });

  useEffect(() => {
    if (selectedVideoFileUrl) {
      const isLocalUrl = selectedVideoFileUrl.startsWith("blob:http://") || selectedVideoFileUrl.startsWith("blob:https://");
      setLocalUrl(isLocalUrl);
    }
  }, [selectedVideoFileUrl]);

  return (
    <div className="video__uploader__wrapper">
      {!selectedVideoFileUrl && (
        <div className="video__upload__container" {...getRootProps()}>
          <Upload />
          <span className="video__upload__desc">
            Drop your videos here, or{" "}
            <span className="video__upload__color__text">browse</span>
            <span className="video__upload__second__text">
              Supports MP4, AVI and MOV files
            </span>
          </span>
        </div>
      )}
      {selectedVideoFileUrl && (
        <div className="show__upload__video__section">
          { !localUrl && videoTourState?.video_data?.content?.video_status === 1 && (
            <iframe
              width={380}
              height={180}
              id="bunny-player"
              src={`https://iframe.mediadelivery.net/embed/${BUNNY_VIDEO_LIBRARY_ID}/${selectedVideoFileUrl}?autoplay=false&loop=false&muted=false&preload=true`}
              loading="lazy"
              allowFullScreen
            />
          )}
          {videoTourState?.video_data?.content &&
            videoTourState?.video_data?.content?.video_status === 0 && !localUrl && (
              <div className="video__tour__frame">
                <VideoProcessing />
              </div>
            )}

          {localUrl && (
            <video width="380" height="180" controls ref={videoRef}>
              <source src={selectedVideoFileUrl} type="video/mp4" />
            </video>
          )}

          {(localUrl ||
            videoTourState?.video_data?.content?.video_status === 1) && (
            <div className="remove__uploaded__video">
              <span onClick={() => clearVideoCache()}>Remove Video</span>
            </div>
          )}
        </div>
      )}
      <input {...getInputProps()} />
    </div>
  );
};

export default VideoUploader;
