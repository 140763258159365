import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import LearningPathHeader from "../../../components/AdminPanel/LearningPath/learningPathHeader";
import LearningPathTable from "../../../components/AdminPanel/LearningPath/learningPathTable";

const AddLearningPath = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <LearningPathHeader />
        <LearningPathTable />
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default AddLearningPath;
