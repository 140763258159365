import { Edit, Edit2, MoreVertical, Star, Trash } from "react-feather";
import { useState } from "react";

import "./feedbacklist.scss";
import Rating from "react-rating";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import { setActiveFeedbackForAction } from "../../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerSlice";
import { useAppDispatch } from "../../../../../store/store";
import AudioPlayer from "../../../../../shared/AudioPlayer/AudioPlayer";

const FeedBackLists = (props: any) => {
  const {
    activeAnswer,
    setShowFeedbackAgain,
    showActionButton,
    setShowActionButton,
    actionData,
    setActionData,
    setDeleteShowModal,
    setShowFeedbackForEdit,
    authState,
  } = props;
  const dispatch = useAppDispatch();

  const handleAction = (feedback: any) => {
    setShowActionButton(!showActionButton);
    setActionData(feedback);
    dispatch(setActiveFeedbackForAction(feedback));
  };
  const handleEdit = () => {
    setShowFeedbackForEdit(true);
    setShowFeedbackAgain(true);
  };

  return (
    <div className="right_cotent">
      <div className="scroll__bar custom__scrollbar">
        {activeAnswer.feedbacks.map((feedback: any) => {
          return (
            <div className="single__feedbacK__wrapper" key={feedback.id}>
              <div className="left__section">
                <div className="avatar__name">{feedback.user.charAt(0)}</div>
              </div>
              <div className="right__section">
                {" "}
                <div className="top__section">
                  <div className="user__name__role">
                    <span className="user__name">{feedback.user}</span>
                    <span className="dot" />
                    <span className="user__role">{feedback.user_role}</span>
                  </div>
                  {(feedback.user_id === authState.tac_user?.id ||
                    authState.tac_user?.role === "sys_adm") && (
                    <MoreVertical
                      className="three__dot"
                      onClick={() => handleAction(feedback)}
                    />
                  )}
                  <div
                    className={`select__section ${
                      showActionButton &&
                      actionData.id === feedback.id &&
                      " active"
                    }`}
                  >
                    {feedback.type === "text" && (
                      <>
                        {" "}
                        <div
                          className="select__action"
                          role="button"
                          aria-hidden
                          onClick={() => handleEdit()}
                        >
                          <Edit2 className="action__icon" /> <span> Edit</span>
                        </div>
                        <div className="action__divider" />
                      </>
                    )}
                    <div
                      className="select__action"
                      role="button"
                      aria-hidden
                      onClick={() => setDeleteShowModal(true)}
                    >
                      <Trash className="action__icon" /> <span> Delete</span>
                    </div>
                  </div>
                </div>
                <div className="content__section">
                  {feedback.rating && feedback.user_role !== "User" && (
                    /*  @ts-ignore */
                    <Rating
                      quiet
                      initialRating={feedback.rating}
                      emptySymbol={
                        <Star className="rating__star__size" color="#FFC600" />
                      }
                      fullSymbol={
                        <Star
                          className="rating__star__size"
                          color="#FFC600"
                          fill="#FFC600"
                        />
                      }
                    />
                  )}
                  {feedback.type === "text" && <p>{feedback.feedback}</p>}
                </div>
                {feedback.type === "audio" && (
                  <AudioPlayer
                    duration={feedback.duration}
                    url={feedback.feedback}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="fd_button_width">
        {" "}
        <PrimaryButton
          text="Add Feedback"
          type="button"
          onClick={() => setShowFeedbackAgain(true)}
        />
      </div>
    </div>
  );
};

export default FeedBackLists;
