import { ChevronLeft, LogOut, User } from "react-feather";
import { useNavigate } from "react-router-dom";
import tacLogoImage from "../../../assets/images/theagilecoatchlogo.png";
import "./TacAiAssistNav.scss";

import ThemeSwitchingButton from "../../AdminPanel/AdminPanelHeader/ThemeSwitchingButton";
import ProfileAndLogout from "../../AdminPanel/AdminPanelHeader/ProfileAndLogout";
import { RootStore, useAppSelector } from "../../../store/store";

const TacAiAssistNav = (props: any) => {
  const navigate = useNavigate();

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  return (
    <div className="header__container__tac">
      <div className="toggle__section__tac">
        <ChevronLeft
          className="left__arrow"
          onClick={() => navigate("/progress")}
        />
        <div
          className="tac__logo__wrapper__div"
          onClick={() => navigate("/progress")}
          role="button"
          aria-hidden
        >
          <img
            src={themeState?.configs?.sidebar_open_logo}
            className="tac__logo"
            alt="theagilecoatchlogo"
          />
        </div>
      </div>
      <div className="right__section__tac">
        <ThemeSwitchingButton />
        <ProfileAndLogout />
      </div>
    </div>
  );
};
export default TacAiAssistNav;
