import React from "react";
import Nav from "../../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import InterviewDoneComp from "../../../../components/NewAcademicUser/InterviewPreparation/InterviewDone/InterviewDoneComp";



function InterviewDone(props: any) {
  return (
  <>
    <Nav  normal navBgColorHandle/>
    <InterviewDoneComp/>
  </>
  );
}



export default InterviewDone
