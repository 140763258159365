import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import MemberHeaders from "../../../components/Orgination/Members/Header/MemberHeaders";
import MemberTable from "../../../components/Orgination/Members/Table/MemberTable";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import PaginateButton from "../../../shared/PaginateButton/PaginateButton";
import { membersPaginate } from "../../../store/features/Tenant/Orgination/Member/MemberSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";

const TeamMembers = () => {
  const dispatch = useAppDispatch();
  const memberState: any = useAppSelector((store: RootStore) => {
    return store.MemberSlice;
  });

  const handlePagination = (num: number) => {
    dispatch(membersPaginate(num));
  };
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <MemberHeaders />
        <MemberTable memberState={memberState} />
      </BoxShadow>
      <div className="pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={memberState.all_members.length}
        />
      </div>
    </AdminPanelLayout>
  );
};

export default TeamMembers;
