/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable import/no-extraneous-dependencies */
import { ChevronLeft, ChevronRight } from "react-feather";
import ReactPaginate from "react-paginate";

import "./paginateButton.scss";

function PaginateButton(props: any) {
  const { totalRows, paginate } = props;

  const pageCount = Math.ceil(totalRows / 10);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    paginate(event.selected);
  };
  if (totalRows > 10) {
    return (
      <div className="custom__react__pagination">
        {" "}
        <ReactPaginate
          breakLabel="..."
          nextLabel={<ChevronRight className="next__icon" />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          previousLabel={<ChevronLeft className="next__icon" />}
          renderOnZeroPageCount={null}
          containerClassName="react__paginate__button__container"
          pageClassName="pageClassName"
          pageLinkClassName="pageLinkClassName"
          breakClassName="breakClassName"
          nextClassName="nextClassName"
          previousClassName="previousClassName"
          activeClassName="activeClassName"
        />
      </div>
    );
  }
  return null;
}

export default PaginateButton;
