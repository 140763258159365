/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-syntax */

import { useEffect, useState } from "react";
import tickIcon from "../../../assets/icons/tickIcon.svg";
import { getCoursesForStudent } from "../../../store/features/Tenant/User/Courses/CoursesApi";
import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../store/store";
import Course from "./Course";
import "./index.scss";
import TenantLoadingScreen from "../../Auth/TenantLoading/TenantLoadingScreen";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import DropDownMultiple from "../../../shared/DropDownMultiple/DropDownMultiple";
import { getLearningPathForStudent } from "../../../store/features/Tenant/User/LearningPath/LearningPathApi";
import {
  includesElement,
  mapHoursAndMinutes,
} from "../../../helpers/utils/extras";

const OrgUsercourses = () => {
  const [searchText, setSearchText] = useState("");

  const dispatch = useAppDispatch();

  const courses: any = useAppSelector((store: RootStore) => {
    return store.TenantUserCourseSlice;
  });

  const learningPaths: any = useAppSelector((store: RootStore) => {
    return store.TenantUserLearningPathSlice;
  });

  useEffect(() => {
    dispatch(getCoursesForStudent());
    dispatch(getLearningPathForStudent());
  }, []);

  type Number = number;
  const [filteredCourses, setFilteredCourse] = useState<any[]>([]);
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [statusArray, setStatus] = useState<Number[]>([]);
  const [durationArray, setDuration] = useState<Number[]>([]);
  const [paths, setPaths] = useState<Number[]>([]);

  // A helper function to split the time string into an object with the hours and minutes

  const filterCourses = () => {
    const filteredCourses = [];
    for (const course of courses.all_courses) {
      let status;
      if (course.percentage_completed === 0) {
        status = 0;
      } else if (
        course.percentage_completed > 0 &&
        course.percentage_completed < 100
      ) {
        status = 1;
      } else {
        status = 2;
      }

      // const course_duration = mapHoursAndMinutes(course.duration);
      let duration;
      if (course.duration >= 0 && course.duration < 2) {
        duration = 0;
      } else if (course.duration >= 2 && course.duration < 4) {
        duration = 1;
      } else {
        duration = 2;
      }

      let statusCheck = false;
      let durationCheck = false;
      let pathsCheck = false;

      if (statusArray.length && statusArray.includes(status))
        statusCheck = true;

      if (!statusArray.length) statusCheck = true;

      if (durationArray.length && durationArray.includes(duration))
        durationCheck = true;

      if (!durationArray.length) durationCheck = true;

      if (paths.length && includesElement(paths, course.path_ids))
        pathsCheck = true;

      if (!paths.length) pathsCheck = true;

      if (statusCheck && durationCheck && pathsCheck)
        filteredCourses.push(course);
    }

    let searched = courses.all_courses;

    if (filterActive) searched = filteredCourses;

    if (searchText !== "") {
      searched = searched.filter((course: any) =>
        course.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilteredCourse(searched);
  };

  useEffect(() => {
    if (!courses.loading && courses.req_success)
      setFilteredCourse(courses.all_courses);
  }, [courses.loading]);

  useEffect(() => {
    if (statusArray.length || durationArray.length || paths.length)
      setFilterActive(true);

    if (!statusArray.length && !durationArray.length && !paths.length)
      setFilterActive(false);

    filterCourses();
  }, [
    filterActive,
    searchText,
    statusArray,
    durationArray,
    paths,
    courses.loading,
  ]);

  const handleStatusChange = (e: any) => {
    const temp = statusArray;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setStatus([...temp]);
  };

  const handleDurationChange = (e: any) => {
    const temp = durationArray;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setDuration([...temp]);
  };

  const handlePathClick = (e: number) => {
    const temp = paths;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setPaths([...temp]);
  };

  const resetFilter = () => {
    setStatus(() => []);
    setPaths(() => []);
    setDuration(() => []);
  };

  const [firstFilter, setFirstFilter] = useState(false);
  const [secondFilter, setSecondFilter] = useState(false);
  const [thirdFilter, setThirdFilter] = useState(false);

  const closeOther = (index: number) => {
    if (index === 0) {
      setFirstFilter(true);
      setSecondFilter(false);
      setThirdFilter(false);
    } else if (index === 1) {
      setFirstFilter(false);
      setSecondFilter(true);
      setThirdFilter(false);
    } else {
      setFirstFilter(false);
      setSecondFilter(false);
      setThirdFilter(true);
    }
  };
  return (
    <div>
      <div>
        {!courses.loading && courses.req_success ? (
          <div>
            <div className="filter__section">
              <div className="CourseFilter">
                <div className="left__section">
                  <span className="filter__tag">Filter By</span>

                  <div className="dropdown-container">
                    <DropDownMultiple
                      title="Status"
                      index={0}
                      closeOther={closeOther}
                      isOpen={firstFilter}
                    >
                      <button
                        type="button"
                        className="ddc-options"
                        onClick={() => handleStatusChange(0)}
                      >
                        <div className="checkbox-div">
                          {statusArray.indexOf(0) !== -1 && (
                            <img src={tickIcon} alt="tick icon" />
                          )}
                        </div>
                        Not Started
                      </button>
                      <button
                        type="button"
                        className="ddc-options"
                        onClick={() => handleStatusChange(1)}
                      >
                        <div className="checkbox-div">
                          {statusArray.indexOf(1) !== -1 && (
                            <img src={tickIcon} alt="tick icon" />
                          )}
                        </div>
                        In Progress
                      </button>
                      <button
                        type="button"
                        className="ddc-options"
                        onClick={() => handleStatusChange(2)}
                      >
                        <div className="checkbox-div">
                          {statusArray.indexOf(2) !== -1 && (
                            <img src={tickIcon} alt="tick icon" />
                          )}
                        </div>
                        Completed
                      </button>
                    </DropDownMultiple>

                    <DropDownMultiple
                      title="Learning Path"
                      index={1}
                      closeOther={closeOther}
                      isOpen={secondFilter}
                    >
                      {!learningPaths.loading &&
                        learningPaths.all_learningPaths.map(
                          (path: any, index: number) => {
                            return (
                              <button
                                type="button"
                                className="ddc-options handle__dropdown__options__width"
                                onClick={() => handlePathClick(path.path_id)}
                              >
                                <div className="checkbox-div">
                                  {paths.indexOf(path.path_id) !== -1 && (
                                    <img src={tickIcon} alt="tick icon" />
                                  )}
                                </div>
                                {path.name}
                              </button>
                            );
                          }
                        )}
                    </DropDownMultiple>

                    <DropDownMultiple
                      title="Course Hours"
                      index={2}
                      closeOther={closeOther}
                      isOpen={thirdFilter}
                    >
                      <button
                        type="button"
                        className="ddc-options"
                        onClick={() => handleDurationChange(0)}
                      >
                        <div className="checkbox-div">
                          {durationArray.indexOf(0) !== -1 && (
                            <img src={tickIcon} alt="tick icon" />
                          )}
                        </div>
                        0-2 Hours
                      </button>
                      <button
                        type="button"
                        className="ddc-options"
                        onClick={() => handleDurationChange(1)}
                      >
                        <div className="checkbox-div">
                          {durationArray.indexOf(1) !== -1 && (
                            <img src={tickIcon} alt="tick icon" />
                          )}
                        </div>
                        2-4 Hours
                      </button>
                      <button
                        type="button"
                        className="ddc-options"
                        onClick={() => handleDurationChange(2)}
                      >
                        <div className="checkbox-div">
                          {durationArray.indexOf(2) !== -1 && (
                            <img src={tickIcon} alt="tick icon" />
                          )}
                        </div>
                        4+ Hours
                      </button>
                    </DropDownMultiple>
                  </div>

                  <button
                    className="reset__tag"
                    onClick={resetFilter}
                    type="button"
                  >
                    Reset
                  </button>
                </div>
                <div className="right__section">
                  <div className="search_filter">
                    <SearchBar value={searchText} setValue={setSearchText} />
                  </div>
                </div>
              </div>
            </div>
            <div>
              {filteredCourses && filteredCourses.length === 0 && (
                <div className="empty__div">
                  <span className="empty__text"> Courses not assigned.</span>
                </div>
              )}
              <div className="OrgUsercourses__wrapper">
                {filteredCourses &&
                  filteredCourses.map((course: any) => {
                    return <Course key={course.id} course={course} />;
                  })}
              </div>
            </div>
          </div>
        ) : (
          <TenantLoadingScreen message="" />
        )}
      </div>
    </div>
  );
};

export default OrgUsercourses;
