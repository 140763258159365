import { useEffect } from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import AdminBundle from "../../../components/Orgination/Courses/Bundle";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { TenantAdminLearningPath } from "../../../store/features/Tenant/Orgination/LearningPath/TenantLearningPathApi";
import TenantLoadingScreen from "../../../components/Auth/TenantLoading/TenantLoadingScreen";

const AdminLearningPath = () => {
  const dispatch = useAppDispatch();
  const pathState: any = useAppSelector((store: RootStore) => {
    return store.TenantLearningPathSlice;
  });
  useEffect(() => {
    dispatch(TenantAdminLearningPath());
  }, []);

  return (
    <AdminPanelLayout>
      <>
      <div className="OrgUserLearningPath__wrapper">
        {!pathState.loading.get_path &&
          pathState.req_success.get_path &&
          pathState.learningPaths.map((path: any) => {
            return <AdminBundle key={path.id} data={path} />;
          })}
      </div>
      {
            pathState.loading.get_path && (  <TenantLoadingScreen message="" />)
          }
      </>
   
    </AdminPanelLayout>
  );
};

export default AdminLearningPath;
