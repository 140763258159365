import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { ChevronLeft } from "react-feather";
import Avatar from "../../../shared/Avatar/Avatar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import "./index.scss";
import { getLearningPathDetailForStudent } from "../../../store/features/Tenant/User/LearningPath/LearningPathApi";
import {enrollToThinkificCourse, loginToThinkificAndRedirect} from "../../../store/features/Thinkific/ThikificApi";
import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../store/store";
import TenantLoadingScreen from "../../Auth/TenantLoading/TenantLoadingScreen";
import { renderMainUrlFromComponentLevelState } from "../../../helpers/utils/urlHelper";

const UserLearningPathCourses = () => {
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const params = useParams();

  const learningPath: any = useAppSelector((store: RootStore) => {
    return store.TenantUserLearningPathSlice;
  });

  const courseState: any = useAppSelector((store: RootStore) => {
    return store.TenantUserCourseSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  useEffect(() => {
    dispatch(
      getLearningPathDetailForStudent({ learningPathId: Number(params.id) })
    );
  }, []);

  const startLearning = (course: any) => {
    setSlug(course.slug);
    setLoading(true);
    dispatch(enrollToThinkificCourse({ courseId: course.course_id })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: course.slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          console.log(res.payload.url);
          setLoading(false);
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  useEffect(() => {
    if (courseState.enroll_to_course) {
      const dynamic_url = renderMainUrlFromComponentLevelState(themeState);
      window.location.href = `${dynamic_url}/login/${authState.tac_user.email}/${slug}/${authState.tenant}`;
    }
  }, [courseState.enroll_to_course]);

  return (
    <div>
      {!learningPath.loading && learningPath.req_success ? (
        <div className="UserLearningPathCourses__wrapper">
          <div className="courses__wrapper">
            <div className="header__section">
              <Link to="/organization-user-learningpath">
                <ChevronLeft className="back__icon" />
              </Link>
              <span className="header__title">
                {learningPath.currentPath.name}
              </span>
            </div>
            {!!learningPath?.learningPathDetailCourses &&
              !!learningPath?.learningPathDetailCourses.length && (
                <div className="stepper__container">
                  {learningPath.learningPathDetailCourses.map((course: any) => {
                    return (
                      <div key={course.id} className="stepper">
                        <span
                          className={`counter ${
                            parseInt(course.percentage_completed, 10) > 0 &&
                            parseInt(course.percentage_completed, 10) < 100 &&
                            "started"
                          } ${
                            parseInt(course.percentage_completed, 10) === 100 &&
                            "completed"
                          }`}
                        />
                        <div className="course__detail">
                          <span className="title">{course.name}</span>
                          <span className="duration">
                            Duration {course.custom_duration}
                          </span>
                          <span className="description">
                            {course.description}
                          </span>
                          <div className="button_width_stepper">
                            {parseInt(course.percentage_completed, 10) ===
                              0 && (
                              <PrimaryButton
                                text="Start Course"
                                loading={loading && course.slug === slug}
                                onClick={() => startLearning(course)}
                              />
                            )}

                            {parseInt(course.percentage_completed, 10) > 0 &&
                              parseInt(course.percentage_completed, 10) <
                                100 && (
                                <PrimaryButton
                                  text="Resume Course"
                                  loading={loading && course.slug === slug}
                                  onClick={() => startLearning(course)}
                                />
                              )}

                            {parseInt(course.percentage_completed, 10) ===
                              100 && (
                              <PrimaryButton
                                text="Course Completed"
                                loading={loading && course.slug === slug}
                                onClick={() => startLearning(course)}
                              />
                            )}
                          </div>
                          <div className="stepper-divider" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
          </div>
          <div className="courses__detail__wrapper">
            <div className="card__section">
              <span className="buldle__tile">
                {learningPath.currentPath.name}
              </span>
              <div className="row">
                <span className="coures__count">
                  {learningPath.currentPath.course_count} Courses
                </span>
              </div>
              <ProgressBar
                completed={Math.round(learningPath.currentPath.progress) || 0}
                height="3px"
                customLabel="."
                baseBgColor="var( --primary-progress-bar-bg)"
                bgColor="var( --secondary-progress-bar-bg)"
                labelSize="0"
              />
              <span className="completed__count">
                {Math.round(learningPath.currentPath.progress)}%
              </span>
              <p className="description">
                {learningPath.currentPath.description}
              </p>

              <div className="detail__and__instructor__divider" />
              {!!learningPath?.instructors &&
                !!learningPath?.instructors.length &&
                learningPath?.instructors.map((ins: any) => {
                  return (
                    <div className="instructor__profile" key={ins.id}>
                      <div className="pic">
                        <Avatar imageUrl={ins?.avatar_url} />
                      </div>
                      <div className="info">
                        <span className="name">
                          {ins?.first_name} {ins?.last_name}
                        </span>
                        <span className="occupation"> {ins?.title}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ) : (
        <TenantLoadingScreen message="" />
      )}
    </div>
  );
};

export default UserLearningPathCourses;
