import React, { useEffect, useState } from "react";
import { Book, Clock, Minus, Plus } from "react-feather";
import "./courseProgress.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { getUserCourseDetail, switchRole } from "../../../store/features/UserDashboard/courseDetail/courseDetailApi";
import {  SkyButton, YellowButton } from "../../../shared/Buttons/Buttons";
import {
  enrollToThinkificCourse,
  loginToThinkificAndRedirect,
} from "../../../store/features/Thinkific/ThikificApi";
import NavForTrialUser from "../../../components/NewAcademicUser/Dashboard/Nav/NavForTrialUser";
import { StudentRoles } from "./helper";

function TrialUserCourseDetail(props: any) {

  const [openIndex, setOpenIndex] = useState(-1);
  const [currentRoleIndex,setCurrentRoleIndex] = useState(-1)
  const [btnText, setBtnText] = useState("");
  const [slug, setSlug] = useState("");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const courseState: any = useAppSelector((store: RootStore) => {
    return store.CourseDetailSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const thinkificState: any = useAppSelector((store: RootStore) => {
    return store.ThinkificSlice;
  });


  const { course, instructor, chapters } = courseState.data;

  const startLearning = (courseId: number, slug: any) => {
    setSlug(slug);
    setLoading(true);
    dispatch(enrollToThinkificCourse({ courseId: courseId })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };

  useEffect(() => {
      dispatch(getUserCourseDetail({ courseId: Number(authState?.tac_user?.courseId) }));
  }, []);

  useEffect(() => {
    if (courseState.req_success) {
      const percentage = courseState.data.course.percentage_completed;
      if (percentage < 100 && percentage > 0) {
        setBtnText("Resume path");
      }
      if (percentage === 0) setBtnText("Start Course");
      if (percentage === 100) setBtnText(" Course Completed");
    }
  }, [courseState.loading]);


  const reloadWindowOnRoleSwitch = ()=>{
    if(!courseState.reload_window ){
      window.location.reload()
    }
  }

  const switchRoleOnClick = async(data:any,currentRoleIndex:any)=>{
    setCurrentRoleIndex(currentRoleIndex);
   await dispatch(switchRole(data)).then(()=>reloadWindowOnRoleSwitch())

  }
  const filteredRoles = StudentRoles.filter((item)=>item.role !== authState?.tac_user?.role);

  return (
    <div className="course__progress__wrapper">
      <NavForTrialUser normal navBgColorHandle />
      {!courseState.loading && courseState.req_success && (
        <>
          <div className="course-progress-top">
            <div className="cpt cpt__handle">
              <div className="cpt-left">
                <h1>{course.name}</h1>
                <p>{course.description}</p>
                <div className="button__width">
                  {" "}
                  <YellowButton
                    normal
                    text={btnText}
                    onClick={() => {
                      // redirectToThinkfic(course.slug)
                      startLearning(course.course_id, course.slug);
                    }}
                    animationLoading={thinkificState.loading}
                  />
                </div>
              </div>

              <div className="other__courses__role__wrapper">
                  <div className="other__courses__role__container">
                      <h1>Other Programs</h1>
                      <div className="contents__btn__wrapper">
                          <p className="info__text">Discover diverse roles in our programs - Scrum Master, Business Analyst, and Product Owner. Explore our programs here for a detail overview! </p>
                          <div className="buttons__wrapper">
                          {
                            filteredRoles.map((item,index)=>(
                                <div className="btn__width" key={item.role}>
                                <SkyButton  
                                  text={item.name} 
                                  type="button" 
                                  loading={courseState.reload_window && index === currentRoleIndex} 
                                  onClick={()=>switchRoleOnClick({role:item.role},index)} 
                                />
                                </div>
                              ))
                            }
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className="cpt-line" />
            <div className="cpt-line-bottom">
              <div className="cpt-line-b">
                <div className="cpt-txt">Path progress</div>
                <div className="cpt-icon-txt-container">
                  <div className="cpt-icon-txt">
                    <div className="progress__div">
                      <div
                        className={`progress__bar  progress-${course.percentage_completed}`}
                      />
                    </div>
                    <div className="cpt-text-icon">
                      {course.percentage_completed}%
                    </div>
                  </div>
                </div>
              </div>

              <div className="cpt-line-b">
                <div className="cpt-txt">Duration</div>
                <div className="cpt-icon-txt-container">
                  <div className="cpt-icon-txt">
                    <Clock />

                    <div className="cpt-text-icon">{course.custom_duration}</div>
                  </div>
                </div>
              </div>

              <div className="cpt-line-b">
                <div className="cpt-txt">Number of Lessons</div>
                <div className="cpt-icon-txt-container">
                  <div className="cpt-icon-txt">
                    <Book />
                    <div className="cpt-text-icon">{course.total_lessons}</div>
                  </div>
                </div>
              </div>

              <div className="cpt-line-b">
                <div className="cpt-txt">Instructor</div>
                <div className="cpt-icon-txt-container">
                  {instructor?.length > 0 ? instructor?.map((inst: any) => {
                     return (
                      <div className="cpt-icon-txt">
                        <img
                          src={inst.avatar_url}
                          className="cpt-icon"
                          alt="instructor"
                        />
                        <div className="cpt-text-icon">
                          {inst.first_name} {inst.last_name}
                        </div>
                      </div>);
                  }):(<div className="no-inst">There are no instructors for this course.</div>)}
                </div>
              </div>
            </div>
          </div>
          <div className="course-progress-bottom">
            <div className="cpb">
              <h2>Lessons in this course</h2>
              <div className="cpb-list">
                {chapters.map((chapter: any) => {
                  return (
                    <div className="cpb-container" key={chapter.id}>
                      <button
                        type="button"
                        className="cpb-head"
                        onClick={() => {
                          if (openIndex === chapter.id) {
                            setOpenIndex(-1);
                          } else setOpenIndex(chapter.id);
                        }}
                      >
                        <div className="cpb-title">{chapter.name}</div>
                        <div className="cpb-icon">
                          {openIndex === chapter.id ? <Minus /> : <Plus />}
                        </div>
                      </button>
                      <div
                        className={
                          openIndex === chapter.id
                            ? "cpb-body d-block"
                            : "cpb-body"
                        }
                      >
                        <div className="cpb-line" />

                        <ul className="ull">
                          {chapter.contents.map((content: string) => {
                            return <li>{content}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
}

export default TrialUserCourseDetail;
