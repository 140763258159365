/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-extraneous-dependencies */
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

import { useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  ChevronDown,
  ChevronsUp,
  LogOut,
  Moon,
  Sun,
  User,
} from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ToastContainer } from "react-toastify";

import blackMenu from "../../../../assets/icons/black-menu.svg";
import whiteMenu from "../../../../assets/icons/white-menu.svg";
import chatbotIcon from "../../../../assets/images/bot.svg";
import logo from "../../../../assets/images/logo.svg";
import { logoutUser } from "../../../../store/features/Auth/AuthApi";
import { ChangeThemeMode } from "../../../../store/features/ThemeSlice";
import { handleStateforRedirect } from "../../../../store/features/UserDashboard/NewUserDashboard/ApplicationTracker/ApplicationTrackerSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import MyDrawer from "./MyDrawer";

import ChatBotSection from "../Chatbot/ChatBotSection";
import MobileViewChatbot from "../Chatbot/MobileViewChatbotDesign/MobileViewChatbot/MobileViewChatbot";
import NavQuickLinks from "./NavQuickLinks";
import Notification from "../../../../shared/Notification/Notification";
import NavigateIntoInterviewPopup from "./Navigate/NavigateIntoInterviewPopup";

const Nav = (props: any) => {
  const { navbg, normal, wmode ,navBgColorHandle} = props;
  const [navBg, setNavBg] = useState(navbg);
  const [bellIconColor,setBellIconColor] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false);
  const [scrollButtonVisibility, setScrollButtonVisibility] = useState(false);
  const [hideScrollButton, setHideScrollButton] = useState(false);
  const [drawer, setDrawer] = useState(false);

  const [interviewPopup,setInterviewPopup ]= useState(
    localStorage.getItem("isPopup") || "Yes");
  const [popup,setPopup] = useState(false);

  // ******User Side Notification ******
  const [show, setShow] = useState(false);

  // ****ChatBot******* */
  const [showChatBox, setShowChatBox] = useState(false);
  const [mobileShowChatBox, setMobileShowChatBox] = useState(false);
  const chatBotIconRef: any = useRef(null);
  const mobileChatBoxref: any = useRef(null);

  useEffect(() => {
    if (showChatBox) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [showChatBox]);

  //* ********FOR MOBILE VIEW CHATBOX************ */
  useEffect(() => {
    if (mobileShowChatBox) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [mobileShowChatBox]);

  //* **** FOR PROFILE DETECT OUTSIDE CLICK**************** */
  const userProfileRef: any = useRef(null);
  const dropdownRef: any = useRef(null);

  const handleClickOutSide = (event: any) => {
    if (
      userProfileRef.current &&
      !userProfileRef.current.contains(event.target)
    ) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    // return ()=>{
    //   document.addEventListener("mousedown",handleClickOutSide);
    // }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") || "dark"
  );
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    document.body.classList.remove("light");
    document.body.classList.remove("dark");
    document.body.classList.add(themeMode);
  }, []);
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const tracker: any = useAppSelector((store: RootStore) => {
    return store.UserApplicationTracker;
  });

  useEffect(() => {
    if (authState.logout_success) {
      navigate("/");
    }
  }, [authState?.logout_success]);

  const handleNavigation = (link: any) => {
    setShowfilter(false);
    const myArray = link.url.split("/");

    if (link.open_new_tab) {
      window.open(link.url, "_blank");
    }
    if (!link.open_new_tab) {
      navigate(`/${myArray[myArray.length - 1]}`);
    }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    if(authState?.tac_user?.show_interview_modal){
      localStorage.setItem("isPopup","Yes");
    }
  };

  useEffect(() => {
    if (tracker.req_success.link) {
      window.open(tracker.redirect_url, "_blank");
      dispatch(handleStateforRedirect());
    }
  }, [tracker.req_success.link]);

  useEffect(() => {
    if (location.pathname.includes("/ai-conversation")) {
      setHideScrollButton(true);
    }
  }, []);

  const handlePopUp = ()=>{
    if(authState?.tac_user?.show_interview_modal){
      localStorage.setItem("isPopup","No");
      setInterviewPopup("No");
      setPopup(false);
    }
  }
  
  useEffect(()=>{
    if(authState?.tac_user?.show_interview_modal){
      if(interviewPopup === "Yes"){
        localStorage.setItem("isPopup","Yes");
        setInterviewPopup("Yes")
        setPopup(true);
      }
      else{
        handlePopUp()
      }
  }
  },[interviewPopup]);


  useEffect(()=>{
    if(!location.pathname.includes("/academic-user-dashboard")){
      handlePopUp();
    }
  },[]);

  // console.log(authState);

  const [showfilter, setShowfilter] = useState(false);
  document.body.addEventListener("click", (e: any) => {
    if (
      !e.target.classList.contains("option__sections") &&
      !e.target.classList.contains("active") &&
      !e.target.classList.contains("select__title__section") &&
      !e.target.classList.contains("select__title") &&
      !e.target.classList.contains("down__icon") &&
      !e.target.classList.contains("option")
    ) {
      setShowfilter(false);
      // setShowNotificationBox(false);
    }
  });

  const handleThemeMode = () => {
    if (themeMode === "light") {
      localStorage.setItem("theme", "dark");
      setThemeMode("dark");
      dispatch(ChangeThemeMode("dark"));
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    } else {
      localStorage.setItem("theme", "light");
      setThemeMode("light");
      dispatch(ChangeThemeMode("light"));
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
  };
  const changeNavBg = () => {
    if (window.scrollY > 1) {
      setNavBg(true);
      setBellIconColor(true);
    } else {
      setNavBg(false);
      setBellIconColor(false);
    }
    if (window.scrollY > 1) {
      setScrollButtonVisibility(true);
    } else {
      setScrollButtonVisibility(false);
    }
  };
  const scrolltoTop = () => {
    window.scrollTo(0, 0);
  };
  window.addEventListener("scroll", changeNavBg);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className={`nav__wrapper ${navBg && "active"}  ${normal && "normal"} ${navBgColorHandle && "handleNavBgColor"} ${
          openDrawer && "drawer"
        } ${
          (location.pathname.includes("/ai-conversation") ||
            location.pathname.includes("/ai-assist")) &&
          "ai"
        } `}
      >
        <div className={`left__section ${openDrawer && "showLogo"}`}>
          <Link to="/academic-user-dashboard">
            <img
              src={logo}
              className={`logo__image  ${
                (location.pathname.includes("/ai-conversation") ||
                  location.pathname.includes("/ai-assist")) &&
                "ai"
              }`}
              alt="logo"
            />
          </Link>
        </div>

        <div className={`right__section ${openDrawer && "show__text"}`}>
          {!location.pathname.includes("/product-manager-user-dashboard") ? (
            <span
              className={`link  ${
                configState.themeMode === "light" && navBg && "active"
              } ${normal && "normal"}`}
            >
              <HashLink to="/academic-user-dashboard#myprogress">
                My Courses
              </HashLink>
            </span>
          ) : (
            <span
              className={`link  ${
                configState.themeMode === "light" && navBg && "active"
              } ${normal && "normal"}`}
            >
              <HashLink to="/product-manager-user-dashboard#myprogress">
                My Courses
              </HashLink>
            </span>
          )}
          {!location.pathname.includes("/product-manager-user-dashboard") && (
            <span
              className={`link mr-4 ${
                configState.themeMode === "light" && navBg && "active"
              } ${normal && "normal"}`}
            >
              <Link to="/ai-assist"> AI Assist</Link>
            </span>
          )}

          {!location.pathname.includes("/product-manager-user-dashboard") && (
            <div className="quick__linK__section margin__handle">
              <span
                role="button"
                aria-hidden
                className={`quick__link  ${showfilter && "active"}  ${
                  configState.themeMode === "light" && navBg && "blackcolor"
                } ${normal && "normal"} `}
                onClick={() => setShowfilter(!showfilter)}
              >
                Quick Links
              </span>
              <ChevronDown
                role="button"
                aria-hidden
                className={`down__icon ${showfilter && "active"}  ${
                  configState.themeMode === "light" && navBg && "blackcolor"
                }  ${normal && "normal"}`}
                onClick={() => setShowfilter(!showfilter)}
              />

              <NavQuickLinks
                configState={configState}
                normal={normal}
                navBg={navBg}
                handleNavigation={handleNavigation}
                showfilter={showfilter}
              />
            </div>
          )}
          {location.pathname.includes("/product-manager-user-dashboard") && (
            <span
              className={`link support__link ${
                configState.themeMode === "light" && navBg && "active"
              } ${normal && "normal"}`}
            >
              <Link to="/support">Support</Link>
            </span>
          )}

          {location.pathname.includes("/product-manager-user-dashboard") && (
            <Link to="/myaccount">
              <span
                className={`link my__account ${
                  configState.themeMode === "light" && navBg && "active"
                } ${normal && "normal"}`}
              >
                My Account
              </span>
            </Link>
          )}
          {location.pathname.includes("/product-manager-user-dashboard") && (
            <span
              className={` link my__account ${
                configState.themeMode === "light" && navBg && "active"
              } ${normal && "normal"}`}
              aria-hidden
              onClick={handleLogout}
            >
              Sign Out
            </span>
          )}

          <Notification bellIconColor={bellIconColor} />

          <button
            type="button"
            className={`theme__mode__button ${themeMode}`}
            onClick={handleThemeMode}
          >
            {themeMode === "light" && <Moon className="moon__icon" />}
            {themeMode === "dark" && <Sun className="sun__icon " />}
          </button>
          {!location.pathname.includes("/product-manager-user-dashboard") && (
            <div className="user__profile__container" ref={userProfileRef}>
              <div
                className="user__profile"
                aria-hidden
                onClick={() => setShow(!show)}
              >
                <div className="user__profile__picture">
                  <div className="user__first__letter">
                    {" "}
                    {authState.tac_user && authState.tac_user?.name.charAt(0)}
                  </div>
                </div>
              </div>
              {show && (
                <div ref={dropdownRef} className="dropdown">
                  <div className="drop_item">
                    <Link to="/myaccount">
                      <User className="drop_icon" />
                      <span>My Account</span>
                    </Link>
                  </div>
                  {/* <div className="divider_item" /> */}
                  <div className="drop_item gap__handle">
                    <LogOut className="drop_icon" />
                    <span aria-hidden onClick={handleLogout}>
                      Sign Out
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}

          <img
            src={configState.themeMode === "dark" ? blackMenu : whiteMenu}
            className="menu"
            onClick={() => setDrawer(true)}
            alt="menu"
            aria-hidden="true"
          />
        </div>
      </div>
      <button
        type="button"
        onClick={scrolltoTop}
        className={`scroll__button ${
          !hideScrollButton && scrollButtonVisibility && "visible"
        }`}
      >
        <ChevronsUp className="up__icon" />
      </button>

      {!location.pathname.includes("/ai-conversation") &&
        !location.pathname.includes("/ai-assist") &&
        !location.pathname.includes("/myaccount") && (
          <div
            ref={chatBotIconRef}
            className="chatbot__icon__wrapper"
            aria-hidden
            onClick={() => setShowChatBox(true)}
          >
            <div className="chatbot__div">
              <img src={chatbotIcon} alt="" />
            </div>
          </div>
        )}

      {!location.pathname.includes("/ai-conversation") &&
        !location.pathname.includes("/ai-assist") &&
        !location.pathname.includes("/myaccount") && (
          <div
            ref={mobileChatBoxref}
            className="mobile__chatbot__icon__wrapper"
            aria-hidden
            onClick={() => setMobileShowChatBox(true)}
          >
            <div className="mobile__chatbot__div">
              <img src={chatbotIcon} alt="" />
            </div>
          </div>
        )}
        {
          popup && ( <NavigateIntoInterviewPopup handlePopUp={handlePopUp}/>)
        }
       

      {showChatBox && (
        // <Chatbot/>
        <div className="desktop__chatbot">
          <ChatBotSection
            showChatBox={showChatBox}
            setShowChatBox={setShowChatBox}
            chatBotIconRef={chatBotIconRef}
          />
        </div>
      )}
      {mobileShowChatBox && (
        <div className="mobile__chatbot__wrapper">
          <MobileViewChatbot
            mobileShowChatBox={mobileShowChatBox}
            setMobileShowChatBox={setMobileShowChatBox}
            mobileChatBoxref={mobileChatBoxref}
          />
        </div>
      )}
      <MyDrawer
        drawer={drawer}
        setDrawer={setDrawer}
        handleNavigation={handleNavigation}
        handleLogout={handleLogout}
      />
    </>
  );
};

export default Nav;
