import { Link } from "react-router-dom";
import BlogBox from "../BlogBox/BlogBox";
import "./index.scss";

const ListBlog = () => {
  return (
    <div className="list-blogs-container">
      <div className="section-title">Most Recent Posts</div>
      <div className="most-recent-posts-container">
        <div className="left">
          <Link className="blogbox blogboxbig" to="/blogs/test">
            <div className="bb-img">
              <img
                src="https://cdn.britannica.com/60/182360-050-CD8878D6/Avengers-Age-of-Ultron-Joss-Whedon.jpg"
                alt="blog"
              />
            </div>
            <div className="category-text">Design</div>
            <div className="blog-title">Feugiat adipiscing neque, at diam</div>
            <div className="blog-desc">
              The project manager is on the ground, running the team and keeping
              the daily operations of a project running smoothly. But who looks
              after the project manager
            </div>
            <div className="blog-detail-container">
              <div className="author-img">
                <img
                  src="https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=2000"
                  alt="author"
                />
              </div>
              <div className="author-name">Paul Lomino</div>
              <div className="blog-date">on March 16, 2022</div>
            </div>
          </Link>
        </div>
        <div className="right">
          {[1, 2].map(() => {
            return (
              <BlogBox
                category="Design"
                title="Feugiat adipiscing neque, at diam"
                authorImg="https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=2000"
                authorName="Paul Lomino"
                blogImg="https://images.ctfassets.net/hrltx12pl8hq/7yQR5uJhwEkRfjwMFJ7bUK/dc52a0913e8ff8b5c276177890eb0129/offset_comp_772626-opt.jpg?fit=fill&w=800&h=300"
                date="March 16, 2022"
                slug="/blogs/test-blog"
              />
            );
          })}
        </div>
      </div>
      <div className="section-title">All blog posts</div>
      <div className="all-blog-posts-container">
        <BlogBox
          category="Design"
          title="Feugiat adipiscing neque, at diam"
          authorImg="https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=2000"
          authorName="Paul Lomino"
          blogImg="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
          date="March 16, 2022"
        />
        {[1, 2, 3, 4].map(() => {
          return (
            <BlogBox
              category="Design"
              title="Feugiat adipiscing neque, at diam"
              authorImg="https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg?w=2000"
              authorName="Paul Lomino"
              blogImg="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
              date="March 16, 2022"
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListBlog;
