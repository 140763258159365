import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListAllCertificate } from "./CertificateApi";

const initialState: any = {
  loading: false,
  req_success: false,
  certificates: [],
  temp_certi: [],
};

const CertificateSlice = createSlice({
  name: "CertificateSlice",
  initialState,
  reducers: {
    searchCertificates: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.certificates = state.temp_certi.filter(
          (certi: any) =>
            certi.course_name.toLowerCase().includes(payload.toLowerCase()) ||
            certi.completed_at.toLowerCase().includes(payload.toLowerCase())
        );
      } else {
        state.certificates = state.temp_certi;
      }
    },
  },
  extraReducers: (builder) => {
    // list all certificates
    builder.addCase(ListAllCertificate.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      ListAllCertificate.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.certificates = payload.data;
        state.temp_certi = payload.data;
      }
    );
    builder.addCase(ListAllCertificate.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { searchCertificates } = CertificateSlice.actions;

export default CertificateSlice.reducer;
