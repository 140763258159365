import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import MemberDetails from "../../../components/Orgination/Members/Details/MemberDetails";

const MembersDetails = () => {
  return (
    <AdminPanelLayout>
      <MemberDetails />
    </AdminPanelLayout>
  );
};

export default MembersDetails;
