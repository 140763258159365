import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadFaqs, addFaqs, updateFaqs, deleteFaqs } from "./FaqApi";

const initialState: any = {
  loading: false,
  req_success: false,
  faqs: [],
  all_faqs: [],
  temp: [],
  faq_update_temp: [],
};

const FaqSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    searchFaqs: (state: any, { payload }: PayloadAction<any>) => {
      const temp = state.faqs;

      if (payload.length >= 1) {
        state.temp = state.all_faqs.filter((e: any) =>
          e.question.toLowerCase().includes(payload.toLowerCase()),
        );
        state.faqs = state.temp;
      } else {
        state.faqs = state.all_faqs;
      }
    },
    clearFaqs: (state: any) => {
      state.faqs = [];
    },
    changeTempOrder: (state: any, { payload }: PayloadAction<any>) => {
      state.faq_update_temp = state.all_faqs;
      const a = state.all_faqs[payload.sourdeIndex];

      state.all_faqs.splice(payload.sourdeIndex, 1);

      state.all_faqs.splice(payload.destinationIndex, 0, a);

      state.faqs = state.all_faqs;
      // state.all_faqs = state.temp;
    },
  },
  extraReducers: (builder) => {
    // LOAD FAQS
    builder.addCase(loadFaqs.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      loadFaqs.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_faqs = payload?.data;
        state.faqs = payload?.data;
        //  state.faqs = payload?.data.filter(
        //    (data: any) => data.for === "landing_page",
        //  );
      },
    );
    builder.addCase(loadFaqs.rejected, (state) => {
      state.loading = false;
    });

    // ADD FAQS
    builder.addCase(addFaqs.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      addFaqs.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_faqs.push(payload?.data);
        state.faqs = state.all_faqs;
      },
    );
    builder.addCase(addFaqs.rejected, (state) => {
      state.loading = false;
    });

    // UPDATE FAQS
    builder.addCase(updateFaqs.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      updateFaqs.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.faqs = state.faqs.map((faq: any) => {
          if (faq.id === payload?.data.id) {
            return payload?.data;
          }
          return faq;
        });

        state.all_faqs = state.faqs.map((faq: any) => {
          if (faq.id === payload?.data.id) {
            return payload?.data;
          }
          return faq;
        });
      },
    );
    builder.addCase(updateFaqs.rejected, (state) => {
      state.loading = false;
    });

    // DELETE FAQS
    builder.addCase(deleteFaqs.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      deleteFaqs.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_faqs = state.faqs.filter(
          (faq: any) => faq.id !== payload?.id,
        );

        state.faqs = state.faqs.filter((faq: any) => faq.id !== payload?.id);
      },
    );
    builder.addCase(deleteFaqs.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { searchFaqs, clearFaqs, changeTempOrder } = FaqSlice.actions;

export default FaqSlice.reducer;
