/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addOrUpdateBanner, getBannersData } from "./BannersApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
  },
  req_success: {
    add: false,
    get: false,
  },

  banners: [],
};

const BannersSlice = createSlice({
  name: "BannersSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get banners Data
    builder.addCase(
      getBannersData.pending,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = true;
      }
    );
    builder.addCase(
      getBannersData.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.banners = payload?.data;
        state.loading.get = false;
      }
    );
    builder.addCase(
      getBannersData.rejected,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
      }
    );
    // Add Banners Data
    builder.addCase(
      addOrUpdateBanner.pending,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = true;
        state.req_success.add = false;
      }
    );
    builder.addCase(
      addOrUpdateBanner.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
      }
    );
    builder.addCase(
      addOrUpdateBanner.rejected,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = false;
      }
    );
  },
});

export default BannersSlice.reducer;
