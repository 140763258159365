import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import "./audioPodcast.scss";

const AudioPodcast = () => {
  return (
    <div>
      <AdminPanelLayout>
        <div className="ap__container">
          <iframe
            title="audio-podcast"
            src="https://player.simplecast.com/44f1ae1c-4e70-4b2a-8b5f-4de8816647b8?dark=true&show=true"
          />
        </div>
      </AdminPanelLayout>
    </div>
  );
};

export default AudioPodcast;
