import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

function BlogBox(props: any) {
  const { title, date, authorImg, blogImg, category, authorName, slug } = props;
  return (
    <Link className="blogbox" to={slug || "/blogs/test"}>
      <div className="bb-img">
        <img src={blogImg} alt="blog" />
      </div>
      <div className="category-text">{category}</div>
      <div className="blog-title">{title}</div>
      <div className="blog-detail-container">
        <div className="author-img">
          <img src={authorImg} alt={authorName} />
        </div>
        <div className="author-name">{authorName}</div>
        <div className="blog-date">on {date}</div>
      </div>
    </Link>
  );
}

export default BlogBox;
