import MemberSignupForm from "../../components/Auth/AuthForm/MemberSignupForm/MemberSignupForm";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";

const MemberSignup = (props: any) => {
  return (
    <LandingLayout>
      <AuthLayout>
        <MemberSignupForm />
      </AuthLayout>
    </LandingLayout>
  );
};

export default MemberSignup;
