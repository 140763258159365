/* eslint-disable react/no-danger */
import { useEffect, useRef } from "react";
import { CheckCircle } from "react-feather";
// import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import "./index.scss";
import SingleNotification from "./SingleNotification";
import { roles } from "../../../helpers/utils/Constants/roles";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";
import { RootStore, useAppSelector } from "../../../store/store";

const NotificationComp = (props: any) => {
  const {
    notificationState,
    updateNotificationStatus,
    updateAllNotificationStatus,
    handleFilter,
    getScrollNotificationData,
    bellIconRef,
    setShowNotificationBox,
    authState,
  } = props;
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  // *********** DETECT OUTSIDE CLICK FOR NOTIFICATION*******************
  const feedBackRef: any = useRef(null);
  const handleClickOutSide = (event: any) => {
    if (bellIconRef.current && !bellIconRef.current.contains(event.target)) {
      if (feedBackRef.current && !feedBackRef.current.contains(event.target)) {
        setShowNotificationBox(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    // return ()=>{
    //   document.addEventListener("mousedown",handleClickOutSide);
    // }
  }, []);

   // Validate is TAA user or not 
  const isTAAUser = authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA;
  // console.log(isTAAUser);

  return (
    <div className="notification__wrapper" ref={feedBackRef}>
      <div className={`user__notification__container  ${isTAAUser && "notification__container__style "}`}>
        <div className="notification__header__wrapper">
          <div className="notification__header__container">
            <div className="notification__header__left__section">
              <p>Notifications</p>
              {authState?.tac_user?.role !== roles.USER && (
                <>
                  {/* hide this section for normal user or student  */}

                  {notificationState.type === "all" ? (
                    <span
                      role="button"
                      aria-hidden
                      onClick={() => handleFilter("not_answered")}
                    >
                      Show “Not answered”
                    </span>
                  ) : (
                    <span
                      role="button"
                      aria-hidden
                      onClick={() => handleFilter("all")}
                    >
                      {" "}
                      Show “All”
                    </span>
                  )}
                </>
              )}
            </div>
            {notificationState.unreadNotificationCount > 0 && (
              <div
                className="notification__header__right__section"
                role="button"
                aria-hidden
                onClick={() => updateAllNotificationStatus()}
              >
                <p>Mark all as read</p>
                <div className="mark__done__icon">
                  <CheckCircle />
                </div>
              </div>
            )}
          </div>
        </div>
        {notificationState.all_notifications.length > 0 ? (
          <div className={`notifications__wrapper ${isTAAUser && "notifications__wrapper__style"}`}>
            <div
              className="notifications__container"
              // id="scrollableDiv"
              // style={{
              //   height: "90%",
              //   overflow: "auto",
              // }}
            >
              <InfiniteScroll
                dataLength={notificationState.all_notifications.length}
                next={() =>
                  getScrollNotificationData(
                    notificationState.currentPage + 1,
                    notificationState.type
                  )
                }
                hasMore={notificationState.hasMoreNotification}
                loader={
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                {notificationState.all_notifications.map(
                  (notification: any) => {
                    return (
                      <SingleNotification
                        key={`${notificationState.type}-${notification.id}`}
                        notification={notification}
                        updateNotificationStatus={updateNotificationStatus}
                      />
                    );
                  }
                )}
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <div className={`no__notification ${isTAAUser && "no__notification__style"}`}>
            <span>No notifications found.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationComp;
