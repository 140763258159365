/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { memo, useEffect, useRef, useState } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Download, Volume2 } from "react-feather";
import "./recordedVideo.scss";
import playImage from "../../../../../assets/icons/Play.svg";
import pauseImage from "../../../../../assets/icons/pause.svg";
import { downloadFile } from "../../../../../helpers/utils/downloadHelper";
import { RootStore, useAppSelector } from "../../../../../store/store";
import BunnyIframe from "../BunnyIframe";

const RecordedVideo = (props: any) => {
  const {
    answer,
    selectedTabIndex,
    recordWebcam,
    CameraStatus,
    play,
    setCurrentDuration,
    playPauseVideo,
    formatTime,
    currentDuration,
    handleSliderTime,
    duration,
    setShowVolumeSlider,
    showVolumeSlider,
    handleVolumeChange,
    volume,
    setPlay,
  } = props;
  const [activeAnswer, setActiveAnswer] = useState<any>();

  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });

  useEffect(() => {
    if (
      selectedTabIndex &&
      Object.keys(interviewState.active_answer).length > 0 &&
      interviewState.active_answer?.answers?.length > 0
    ) {
      // videoSourceRef.current.src =
      //   answer.answers[selectedTabIndex - 1].video_link;
      // videoRef.current.load();
      if (play) {
        setPlay(false);
      }
      setActiveAnswer(answer.answers[selectedTabIndex - 1]);
    }
  }, [
    selectedTabIndex,
    interviewState.notificationData,
    interviewState.active_answer,
  ]);

  const handleRecordedVideoTimeUpdate = () => {
    let video: any;
    if (video && video.current.currentTime !== 0) {
      setCurrentDuration(Math.round(video.currentTime));
    }
    if (!video) {
      video = document.getElementById("video3");
      setCurrentDuration(Math.round(video.currentTime));
    }
  };

  const handleFileDownload = (url: string) => {
    if (selectedTabIndex) {
      downloadFile(url);
    } else {
      recordWebcam.download();
    }
  };

  const checkIfVideoEnded = () => {
    if (play) {
      setPlay(false);
    }
  };

  return (
    <>
      {" "}
      <div
        className="recorded__video__bunny__iframe"
        style={{
          display: `${
            // selectedTabIndex ===1 || selectedTabIndex ===2 || selectedTabIndex ===3 ? "block" : "none"
            (selectedTabIndex === 1 ||
              selectedTabIndex === 2 ||
              selectedTabIndex === 3) &&
            activeAnswer?.status === 1
              ? "block"
              : "none"
          }`,
        }}
      >
        <BunnyIframe videoId={activeAnswer?.video_link} />
      </div>
      <video
        className="video__controller2"
        id="video3"
        ref={recordWebcam.previewRef}
        onTimeUpdate={handleRecordedVideoTimeUpdate}
        onEnded={checkIfVideoEnded}
        style={{
          display: `${
            !selectedTabIndex && recordWebcam.status === CameraStatus.PREVIEW
              ? "block"
              : "none"
          }`,
          transform: "scaleX(-1)",
        }}
      />
      <div
        className={`user__prep__video__controller__section ${
          recordWebcam.status !== CameraStatus.PREVIEW && !selectedTabIndex
            ? "hide"
            : ""
        }
        ${
          selectedTabIndex === 1 ||
          selectedTabIndex === 2 ||
          selectedTabIndex === 3
            ? "hide"
            : ""
        }
        `}
      >
        <div className="play__pause__section">
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-content={play ? "Pause" : "Play"}
            data-tooltip-place="top"
          >
            <img
              src={play ? pauseImage : playImage}
              className="play__image"
              alt="play"
              role="button"
              onClick={() => playPauseVideo()}
              aria-hidden
            />
          </a>
        </div>
        <div className="time__slider__section">
          <span className="time mr-1"> {formatTime(currentDuration)} </span>
          <div className="time__progress__section">
            <Slider
              onChange={(nextValues: any) => {
                handleSliderTime(nextValues);
              }}
              value={currentDuration}
              min={0}
              max={selectedTabIndex ? activeAnswer?.duration : duration}
              defaultValue={0}
              step={1}
            />
          </div>
          <span className="time ml-1">
            {formatTime(
              Number(selectedTabIndex ? activeAnswer?.duration : duration)
            )}{" "}
          </span>
        </div>
        <div className="volume__and__download__section">
          {" "}
          <div className="volume__section">
            {" "}
            <a
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Volume"
              data-tooltip-place="top"
            >
              <Volume2
                className="volume___controller"
                onClick={() => setShowVolumeSlider(!showVolumeSlider)}
              />
            </a>
            <div
              className={`volume__slider__div ${showVolumeSlider && "active"}`}
            >
              {" "}
              <Slider
                vertical
                onChange={(nextValues: any) => {
                  handleVolumeChange(nextValues);
                }}
                value={volume}
                min={0}
                max={1}
                defaultValue={1}
                step={0.01}
              />
            </div>
          </div>
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Download"
            data-tooltip-place="top"
          >
            <Download
              className="download__button"
              onClick={() => handleFileDownload(activeAnswer?.video_link)}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default memo(RecordedVideo);
