/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllcourses,
  getAllPremiumCourses,
  getLearningPathForFilter,
} from "./CoursesApi";

const initialState: any = {
  loading: false,
  bundle_course_loading: false,
  bundles: [],
  req_success: false,
  start_filter: false,
  reset_filter: false,
  oneToTwoHours: 0,
  towToFourHours: 0,
  moreThanFourHours: 0,
  all_courses: [],
  temp_courses: [],
  courses: [],
  premium_courses: {
    loading: false,
    req_success: false,
    all_courses: [],
    courses: [],
  },
};

const CourseSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    learningPathFilter: (state: any) => {
      state.start_filter = true;
      state.reset_filter = false;
      if (state.towToFourHours === 0) {
        state.towToFourHours = 4;
      } else {
        state.towToFourHours = 0;
      }
    },
    oneToTwoHours: (state: any) => {
      state.start_filter = true;
      state.reset_filter = false;
      if (state.oneToTwoHours === 0) {
        state.oneToTwoHours = 2;
      } else {
        state.oneToTwoHours = 0;
      }
    },
    towToFourHours: (state: any) => {
      state.start_filter = true;
      state.reset_filter = false;
      if (state.towToFourHours === 0) {
        state.towToFourHours = 4;
      } else {
        state.towToFourHours = 0;
      }
    },
    moreThanFourHours: (state: any) => {
      state.start_filter = true;
      state.reset_filter = false;
      if (state.moreThanFourHours === 0) {
        state.moreThanFourHours = 4;
      } else {
        state.moreThanFourHours = 0;
      }
    },

    searchCoursesByName: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.courses = state.all_courses.filter((course: any) =>
          course.name.toLowerCase().includes(payload.toLowerCase())
        );
        state.premium_courses.courses =
          state.premium_courses.all_courses.filter((course: any) =>
            course.name.toLowerCase().includes(payload.toLowerCase())
          );
      } else {
        state.courses = state.all_courses;
        state.premium_courses.courses = state.premium_courses.all_courses;
      }
    },

    searchByFilter: (state: any) => {
      state.courses = state.all_courses.filter((course: any) => {
        // return (
        //   Number(course.duration) <=
        //   (state.oneToTwoHours && state.towToFourHours)
        // );

        if (
          Number(course.duration) <= state.oneToTwoHours &&
          state.towToFourHours
        ) {
          return course;
        }
        if (
          Number(course.duration) > 2 &&
          Number(course.duration) <= state.towToFourHours
        ) {
          return course;
        }
        if (Number(course.duration) <= state.oneToTwoHours) {
          return course;
        }
        if (Number(course.duration) > state.moreThanFourHours) {
          return course;
        }
      });
    },
    resetFilter: (state: any) => {
      state.reset_filter = true;
      state.oneToTwoHours = 0;
      state.towToFourHours = 0;
      state.moreThanFourHours = 0;
      state.courses = state.all_courses;
    },
  },
  extraReducers: (builder) => {
    // GET ALL COURSES
    builder.addCase(getAllcourses.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getAllcourses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_courses = payload?.data;
        state.courses = payload?.data;
      }
    );
    builder.addCase(getAllcourses.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllPremiumCourses.pending, (state) => {
      state.premium_courses.loading = true;
      state.premium_courses.req_success = false;
    });
    builder.addCase(
      getAllPremiumCourses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.premium_courses.loading = false;
        state.premium_courses.req_success = true;
        state.premium_courses.all_courses = payload?.data;
        state.premium_courses.courses = payload?.data;
      }
    );
    builder.addCase(getAllPremiumCourses.rejected, (state) => {
      state.premium_courses.loading = false;
    });
    // GET COURSES FROM BUNDLE
    builder.addCase(getLearningPathForFilter.pending, (state) => {
      state.bundle_course_loading = true;
    });
    builder.addCase(
      getLearningPathForFilter.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.bundle_course_loading = false;
        state.bundles = payload?.data;
      }
    );
    builder.addCase(getLearningPathForFilter.rejected, (state) => {
      state.bundle_course_loading = false;
    });
  },
});

export const {
  searchCoursesByName,
  oneToTwoHours,
  towToFourHours,
  moreThanFourHours,
  searchByFilter,
  resetFilter,
  learningPathFilter,
} = CourseSlice.actions;

export default CourseSlice.reducer;
