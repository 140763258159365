import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddAuthors,
  getAuthors,
  updateAuthors,
  deleteAuthors,
} from "./AuthorsApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    delete: false,
  },
  req_success: {
    add: false,
    get: false,
    delete: false,
    edit: false,
  },
  editAuthor: false,
  single_author_info: {},
  current_page: 0,
  all_authors: [],
  temp_authors: [],
  authors: [],
};
// ADD about;
const AuthorSlice = createSlice({
  name: "AuthorSlice",
  initialState,
  reducers: {
    editAuthorInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.editAuthor = true;
      state.single_author_info = payload;
    },
    closeAuthorEditModal: (state: any, { payload }: PayloadAction<any>) => {
      state.editAuthor = false;
    },
    authorTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.authors = state.all_authors.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.authors = state.all_authors.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.authors = state.all_authors.slice(
          payload * 10,
          10 * payload + 10,
        );
      }
    },
    searchAuthorsInTable: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_authors = state.all_authors.filter((author: any) =>
          author.name.toLowerCase().includes(payload.toLowerCase()),
        );
        state.authors = state.temp_authors.slice(0, 10);
      } else {
        state.authors = state.all_authors.slice(
          state.current_page * 10,
          10 * state.current_page + 10,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // ADD AUTHORS
    builder.addCase(AddAuthors.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      AddAuthors.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;

        if (payload.data) {
          state.all_authors = [payload?.data, ...state.all_authors];

          if (state.current_page === 0) {
            state.authors = state.all_authors.slice(0, 10);
          } else {
            state.authors = state.all_authors.slice(
              state.current_page * 10,
              state.current_page * 10 + 10,
            );
          }
        }
      },
    );
    builder.addCase(AddAuthors.rejected, (state) => {
      state.loading.add = false;
    });

    // GET AUTHORS
    builder.addCase(getAuthors.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getAuthors.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.all_authors = payload.data.reverse();
        state.authors = state.all_authors.slice(0, 10);
      },
    );
    builder.addCase(getAuthors.rejected, (state) => {
      state.loading.get = false;
    });

    // UPDATE AUTHORS
    builder.addCase(updateAuthors.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      updateAuthors.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
        state.editAuthor = false;

        state.authors = state.authors.map((author: any) => {
          if (author.id === payload?.data.id) {
            return payload?.data;
          }
          return author;
        });
        state.all_authors = state.all_authors.map((author: any) => {
          if (author.id === payload?.data.id) {
            return payload?.data;
          }
          return author;
        });
      },
    );
    builder.addCase(updateAuthors.rejected, (state) => {
      state.loading.add = false;
      state.req_success.add = false;
    });

    // DELETE AUTHORS
    builder.addCase(deleteAuthors.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteAuthors.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_authors = state.all_authors.filter(
          (author: any) => author.id !== payload?.id,
        );
        if (state.current_page === 0) {
          state.authors = state.all_authors.slice(0, 10);
        } else {
          state.authors = state.all_authors.slice(
            state.current_page * 10,
            state.current_page * 10 + 10,
          );
        }
      },
    );
    builder.addCase(deleteAuthors.rejected, (state) => {
      state.loading.delete = false;
    });
  },
});
export const {
  authorTablePaginate,
  searchAuthorsInTable,
  editAuthorInfo,
  closeAuthorEditModal,
} = AuthorSlice.actions;
export default AuthorSlice.reducer;
