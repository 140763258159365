import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  addQuickLinks,
  deleteQuickLinks,
  loadQuickLinks,
  updateQLOrder,
} from "./QuickLinksApi";

// import { loadFaqs, addFaqs, updateFaqs, deleteFaqs } from "./FaqApi";

const initialState: any = {
  loading: false,
  req_success: false,
  order_loading: false,
  order_req_success: false,
  currID: -1,
  quicklinks: [],
  all_quicklinks: [],
};

const QuickLinksSlice = createSlice({
  name: "quicklinks",
  initialState,
  reducers: {
    updateCurrID: (state: any, { payload }: PayloadAction<any>) => {
      state.currID = payload;
    },
    changeQLTempOrder: (state: any, { payload }: PayloadAction<any>) => {
      state.temp_archives = state.all_quicklinks;
      const a = state.all_quicklinks[payload.sourceIndex];

      state.all_quicklinks.splice(payload.sourceIndex, 1);

      state.all_quicklinks.splice(payload.destinationIndex, 0, a);
      // state.isChanged = !state.isChanged;
    },
  },
  extraReducers: (builder) => {
    // LOAD FAQS
    builder.addCase(loadQuickLinks.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      loadQuickLinks.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.quicklinks = payload?.data;
        state.all_quicklinks = payload?.data;
      }
    );
    builder.addCase(loadQuickLinks.rejected, (state) => {
      state.loading = false;
    });

    // ADD / UPDATE QUICKLINKS
    builder.addCase(addQuickLinks.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      addQuickLinks.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.quicklinks = state.quicklinks.filter((e: any) => {
          return e.id !== payload?.data.id;
        });
        state.quicklinks.push(payload?.data);

        state.all_quicklinks = state.quicklinks;
      }
    );
    builder.addCase(addQuickLinks.rejected, (state) => {
      state.loading = false;
    });

    // update ql order
    builder.addCase(updateQLOrder.pending, (state) => {
      state.order_loading = true;
      state.order_req_success = false;
    });
    builder.addCase(
      updateQLOrder.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.order_loading = false;
        state.order_req_success = true;
      }
    );
    builder.addCase(updateQLOrder.rejected, (state) => {
      state.order_loading = false;
      state.order_req_success = false;
    });

    // DELETE QUICKLINKS
    builder.addCase(deleteQuickLinks.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      deleteQuickLinks.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.quicklinks = state.quicklinks.filter(
          (quicklink: any) => quicklink.id !== payload?.id
        );
        state.all_quicklinks = state.quicklinks.filter(
          (quicklink: any) => quicklink.id !== payload?.id
        );
      }
    );
    builder.addCase(deleteQuickLinks.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateCurrID, changeQLTempOrder } = QuickLinksSlice.actions;

export default QuickLinksSlice.reducer;
