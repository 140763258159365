import Teams from "../../pages/AdminPanel/Teams/Teams";
import TenantTeams from "../../pages/AdminPanel/TenantTeams/TenantTeams";
import PaymentPlans from "../../pages/AdminPanel/PaymentPlans/PaymentPlans";
import Faq from "../../pages/AdminPanel/Faq/Faq";
import SEOManagement from "../../pages/AdminPanel/SEO/SEOManagement";
import AddLearningPath from "../../pages/AdminPanel/LearningPath/LearningPath";
import Testimonials from "../../pages/AdminPanel/videolinks/Testimonials";
import Testimonial from "../../pages/AdminPanel/Testimonial/Testimonial";
import ManagingCourses from "../../pages/AdminPanel/ManagingCourses/ManagingCourses";
import LearningPaths from "../../pages/AdminPanel/LearningPaths/LearningPaths";
import LearningPathsCourse from "../../pages/AdminPanel/LearningPaths/LearningPathsCourse";
import Onboarding from "../../pages/AdminPanel/Onboarding/Onboarding";
import Tutions from "../../pages/AdminPanel/Tutions/Tutions";
import TermsOfUse from "../../pages/AdminPanel/settings/Terms of Use/TermsOfUse";
import Privacy from "../../pages/AdminPanel/settings/privacy/privacy";
import LearningResource from "../../pages/AdminPanel/LearningResource/LearningResource";
import AboutUs from "../../pages/AdminPanel/settings/AboutUs/AboutUs";
import Sprint from "../../pages/AdminPanel/Sprint/Sprint";
import CreateSprint from "../../pages/AdminPanel/Sprint/CreateSprint";
import WeeklyLearning from "../../pages/AdminPanel/WeeklyLearning/WeeklyLearning";
import Interview from "../../pages/AdminPanel/AdminInterview/Interview";
import WebinarDateAndTime from "../../pages/AdminPanel/Webinar/WebinarDateAndTime";

import { Domain } from "../../helpers/utils/DomainSwitcher/domains";
import { ROUTES } from "../../helpers/utils/Constants/route";
import SiteManager from "../../pages/AdminPanel/SiteManager/SiteManager";
import MeetWithUs from "../../pages/AdminPanel/MeetWithUs/MeetWithUs";

const AllAdminRouteList = [
  {
    id: 3,
    path: ROUTES.TEAMS,
    component: Teams,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 4,
    path: ROUTES.TENANT_TEAMS,
    component: TenantTeams,
    domain: [Domain.TAC],
  },
  {
    id: 5,
    path: ROUTES.PAYMENT_PLANS,
    component: PaymentPlans,
    domain: [Domain.TAC],
  },
  {
    id: 6,
    path: ROUTES.FAQS,
    component: Faq,
    domain: [Domain.TAA, Domain.TAC],
  },

  {
    id: 8,
    path: ROUTES.SEO,
    component: SEOManagement,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 10,
    path: ROUTES.ADD_LEARNING_PATH,
    component: AddLearningPath,
    domain: [Domain.TAA],
  },
  {
    id: 11,
    path: ROUTES.TESTIMONIALS,
    component: Testimonials,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 12,
    path: ROUTES.TESTIMONIAL,
    component: Testimonial,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 13,
    path: ROUTES.MANAGE_COURSE,
    component: ManagingCourses,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 14,
    path: ROUTES.LIST_LEARNING_COURSE,
    component: LearningPaths,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 16,
    path: ROUTES.LEARNING_PATH_COURSE,
    component: LearningPathsCourse,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 17,
    path: ROUTES.ONBOARDING,
    component: Onboarding,
    domain: [Domain.TAA],
  },
  {
    id: 18,
    path: ROUTES.TUTIONS,
    component: Tutions,
    domain: [Domain.TAA],
  },
  {
    id: 19,
    path: ROUTES.TERMS_OF_USE,
    component: TermsOfUse,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 20,
    path: ROUTES.PRIVACY_POLICY,
    component: Privacy,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 21,
    path: ROUTES.LEARNING_RESOURCE,
    component: LearningResource,
    domain: [Domain.TAA, Domain.TAC],
  },
  {
    id: 22,
    path: ROUTES.ABOUT_US,
    component: AboutUs,
    domain: [Domain.TAA, Domain.TAC],
  },

  {
    id: 24,
    path: ROUTES.SPRINT,
    component: Sprint,
    domain: [Domain.TAA],
  },
  {
    id: 25,
    path: ROUTES.CREATE_SPRINT,
    component: CreateSprint,
    domain: [Domain.TAA],
  },
  {
    id: 26,
    path: ROUTES.WEEKLY_LEARNING,
    component: WeeklyLearning,
    domain: [Domain.TAA, Domain.TAC],
  },
  // {
  //   id: 27,
  //   path: ROUTES.SUBSCRIBERS,
  //   component: Subscribers,
  //   domain: [Domain.TAA, Domain.TAC],
  // },

  {
    id: 30,
    path: `${ROUTES.INTERVIEW}/:tag`,
    component: Interview,
    domain: [Domain.TAA],
  },

  {
    id: 31,
    path: ROUTES.WEBMINAR,
    component: WebinarDateAndTime,
    domain: [Domain.TAA],
  },
  {
    id: 32,
    path: ROUTES.SITE_MANAGER,
    component: SiteManager,
    domain: [Domain.TAC],
  },
  {
    id: 33,
    path: ROUTES.MEET_WITH_US,
    component: MeetWithUs,
    domain: [Domain.TAA],
  },

  // {
  //   id: 32,
  //   path: ROUTES.PROFILE,
  //   component: UserProfile,
  //   domain: [Domain.TAA, Domain.TAC],
  // },
];

export default AllAdminRouteList;
