/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
import ReactHtmlParser from "react-html-parser";

import "./index.scss";
import logo from "../../../../assets/images/half-logo.png";
import MessageLoading from "./MessageLoading";

const Message = (props: any) => {
  const { user, ai, message, handleAiFilter, loading, themeState } = props;
  // console.log("themeState--", themeState.configs.sidebar_closed_logo);
  return (
    <div className="center__messages">
      <div className={`message__section ${user && "user"} ${ai && "ai"}`}>
        <div className="center__item">
          {user && <div className="named__avatar"> A</div>}
          {!loading && ai && (
            <div className="image__avatar">
              <img src={themeState.configs.sidebar_closed_logo} alt="" />
            </div>
          )}
          <div className="message__and__buttons__div">
            {user && (
              <div className="message question__fontWeight">
                {" "}
                {ReactHtmlParser(message?.message)}{" "}
              </div>
            )}

            {!loading && ai && (
              <div className="message">
                {" "}
                {ReactHtmlParser(message?.message)}
              </div>
            )}
            {/* {!loading && ai && (
              <div className="ai_buttons">
                {" "}
                <button
                  type="button"
                  className="ai_message__button"
                  onClick={() =>
                    handleAiFilter(
                      message.question,
                      "summarize",
                      message.questionId
                    )
                  }
                >
                  Summarize
                </button>
                <button
                  type="button"
                  className="ai_message__button"
                  onClick={() =>
                    handleAiFilter(
                      message.question,
                      "elaborate",
                      message.questionId
                    )
                  }
                >
                  Elaborate
                </button>
                <button
                  type="button"
                  className="ai_message__button"
                  onClick={() =>
                    handleAiFilter(
                      message.question,
                      "example",
                      message.questionId
                    )
                  }
                >
                  Example
                </button>{" "}
              </div>
            )} */}
          </div>
        </div>
      </div>
      {loading && !user && <MessageLoading />}
    </div>
  );
};

export default Message;
