/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import "./userReferenceCard.scss";
import { Linkedin } from "react-feather";
import {
  RootStore,
  useAppSelector,
} from "../../../../store/store";
import likedIcon from "../../../../assets/icons/linkedinIcon.svg";

function UserReferenceCard(props: any) {
  const { name, email, phone, img, companyName, designation ,linkedinLink} = props;

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  return (
    <div className="user-reference-card">
      <div className="urc">
        <div className="urc-img">
          <img src={img} alt={name} />
        </div>
        <div className="urc-txt">
          <div className="urc-name" title={name}>
            {name}
          </div>
          <div className="urc-designation">{designation}</div>
          <div className="linkedin__cname">
            <div className="urc-companyname">{companyName}</div>
            {
              linkedinLink && (   <div className="linkedInIcon" title={linkedinLink} onClick={()=>window.open(`${linkedinLink}`)}>
              <img src={likedIcon} alt="" />
            </div>)
            }
         
          </div>
        </div>
      </div>
      <div className="urc-dashed" />
      <div className="urc-email">{email}</div>

      {/* <div className="linkedin__link" onClick={()=>window.open(`${linkedinLink}`)}>{linkedinLink}</div> */}
      <div className="urc-num">{phone}</div>
    </div>
  );
}

export default UserReferenceCard;
