/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import InputContainer from "../../Layouts/Input/InputContainer";
import ErrorText from "../ErrorText/ErrorText";
import { InputLabel } from "../InputLabel/InputLabel";
import "./textField.scss";

export default function TextField(props: any) {
  const {
    name,
    label,
    type,
    placeholder,
    error,
    required,
    register,
    extraError,
    oninput,
    handleInputStyle,
    ...rest
  } = props;

  const errorMessage = error?.message;
  return (
    <div>
      <InputContainer>
        <InputLabel name={name} text={label} required={required} />
        <input
          id={name}
          name={name}
          type={type}
          className={`${error && "error"} ${handleInputStyle && "handleInputStyle"}`}
          placeholder={placeholder}
          onInput={oninput}
          {...(register && register(name))}
          {...rest}
        />

        <ErrorText error={errorMessage} />
        <ErrorText error={extraError} />
      </InputContainer>
    </div>
  );
}
