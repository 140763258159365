/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TenantAdminLearningPath,
  getTenantCoursesFromPath,
  tenantEntollToCourse,
} from "./TenantLearningPathApi";

const initialState: any = {
  loading: {
    get_path: false,
    path_courses: false,
    enroll: false,
  },
  req_success: {
    get_path: false,
    path_courses: false,
    enroll: false,
  },

  bundle_instructors: [],
  data: [],
  learningPaths: [],
};

const TenantLearningPathSlice = createSlice({
  name: "TenantLearningPathSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL Learning Path
    builder.addCase(TenantAdminLearningPath.pending, (state) => {
      state.loading.get_path = true;
      state.req_success.get_path = false;
    });
    builder.addCase(
      TenantAdminLearningPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_path = false;
        state.req_success.get_path = true;
        state.learningPaths = payload?.data;
      },
    );
    builder.addCase(TenantAdminLearningPath.rejected, (state) => {
      state.loading.get_path = false;
    });

    // GET COURSES FROM BUNDLE or path
    builder.addCase(getTenantCoursesFromPath.pending, (state) => {
      state.loading.path_courses = true;
      state.req_success.path_courses = false;
    });
    builder.addCase(
      getTenantCoursesFromPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.path_courses = false;
        state.req_success.path_courses = true;
        state.data = payload;
      },
    );
    builder.addCase(getTenantCoursesFromPath.rejected, (state) => {
      state.loading.path_courses = false;
    });

    // enroll to courses
    builder.addCase(tenantEntollToCourse.pending, (state) => {
      state.loading.enroll = true;
      state.req_success.enroll = false;
    });
    builder.addCase(
      tenantEntollToCourse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.enroll = false;
        state.req_success.enroll = true;
      },
    );
    builder.addCase(tenantEntollToCourse.rejected, (state) => {
      state.loading.enroll = false;
    });
  },
});

// export const { setRecentSignupEmail, invokeLogout } = TenantLearningPathSlice.actions;

export default TenantLearningPathSlice.reducer;
