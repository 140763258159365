import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import AdminCourseDetail from "../../../components/Orgination/Courses/AdminCourseDetail";

const AdminCoursDetails = () => {
  return (
    <AdminPanelLayout>
      <AdminCourseDetail />
    </AdminPanelLayout>
  );
};

export default AdminCoursDetails;
