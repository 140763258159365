import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import TutionsPlans from "../../../components/AdminPanel/Tution/TutionsPlans";

const Tutions = () => {
  return (
    <AdminPanelLayout>
     <div className="mt-7">
     <TutionsPlans /> 
     </div>
    </AdminPanelLayout>
  );
};

export default Tutions;
