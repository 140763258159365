import { ChevronLeft } from "react-feather";
import "./faqheading.scss";
import { RootStore, useAppSelector } from "../../../../../store/store";
import userImg from "../../../../../assets/icons/user.png";

const FaqHeading = (props: any) => {
  const { backToHomeTab } = props;
  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewAnswerSlice;
  });
  return (
    <div className="faq_container">
      <div className="headings">
        <div className="left_section">
          <ChevronLeft
            style={{ marginLeft: "-6px" }}
            onClick={() => backToHomeTab()}
          />

          <div className="avatar__name">
            {InterviewState.user?.name && InterviewState.user?.name.charAt(0)}
          </div>

          <h3>{InterviewState.user?.name}</h3>
        </div>
        <div className="right_section">
          <div className="role">
            <p>Role: {InterviewState.role?.name}</p>
          </div>
          <div className="question_no">
            <p>No. of questions: {InterviewState.total_question}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqHeading;
