import React from "react";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";

const ReRecordModal = (props: any) => {
  const { showModal, setShowModal, loading, onClick } = props;
  const handleModal = () => {};

  return (
    <Modal show={showModal} setShow={setShowModal} onClick={handleModal} bluishBgModal>
      <ModalHeader title="Are you sure you want to continue ?" />
      <ModalBody>
        <span className="notice">
          Your previous response has not been saved yet, and your current response
          will be lost if you re-record it without saving.
        </span>
        <div className="delete__divider divider__div" />
        <div className="row">
          <div className=" mt-1 mr-2">
            <button
              className="custom__button feedback__delete__button "
              type="button"
              onClick={(e)=>onClick(e)}
              disabled={loading}
            >
              Yes, re-record it
            </button>
          </div>
          <div className=" mt-1">
            <button
              className="custom__button feedback__cancel__button "
              type="button"
              onClick={() => setShowModal(false)}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ReRecordModal;
