/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { Check } from "react-feather";
import { useForm } from "react-hook-form";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import "./index.scss";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { addOrUpdateBanner } from "../../../../store/features/AdminPanel/Banners/BannersApi";
import { studentBannerFormOptions } from "../helper";

const StudentBanner = () => {
  const [hideShow, setHideShow] = useState("hide");
  const [loading, setLoading] = useState(false);
  const [openInNewTab, setOpenInNewTab] = useState(false);
  const dispatch = useAppDispatch();
  const bannersState: any = useAppSelector(
    (store: RootStore) => store.BannersSlice
  );
  const { handleSubmit, register, setValue, formState } = useForm(
    studentBannerFormOptions
  );
  const { errors } = formState;
  const onlyTacStudentDasboardbannerData = bannersState.banners?.filter(
    (item: any) => item.name === "tac_student_dashboard_banner"
  );
  const submitForm = async (values: any) => {
    try {
      setLoading(true);
      const tempData = {
        ...values,
        open_new_tab: openInNewTab,
      };
      console.log(tempData);
      const data = {
        content: JSON.stringify(tempData),
        status: hideShow === "show" ? 1 : 0,
      };
      await dispatch(
        addOrUpdateBanner({
          data,
          id: onlyTacStudentDasboardbannerData[0]?.id,
        })
      );
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      onlyTacStudentDasboardbannerData.length > 0 &&
      onlyTacStudentDasboardbannerData[0]?.content
    ) {
      try {
        const parsedContent = JSON.parse(
          JSON.parse(onlyTacStudentDasboardbannerData[0]?.content)
        );
        console.log("Parsed content",parsedContent);
        setValue("text", parsedContent?.text || "");
        setValue("btn_text", parsedContent?.btn_text || "");
        setValue("btn_link", parsedContent?.btn_link || "");
        setHideShow(
          onlyTacStudentDasboardbannerData[0]?.status ? "show" : "hide"
        );
        setOpenInNewTab(parsedContent?.open_new_tab);
      } catch (error) {
        console.error("Error parsing banner content:", error);
      }
    }
  }, [onlyTacStudentDasboardbannerData[0], setValue]);

  return (
    <div className="banner__header__content ">
      <div className="header">
        <span className="lessons">Dashboard Side Banner</span>
      </div>
      <form className="banner__wrapper" onSubmit={handleSubmit(submitForm)}>
        <div className="banner__input__text__field">
          <TextField
            type="text"
            name="text"
            register={register}
            error={errors?.text}
          />
        </div>
        <div className="grid__input">
          <TextField
            label="Button Name"
            type="text"
            name="btn_text"
            register={register}
            error={errors?.btn_text}
          />
          <TextField
            label="Button Link"
            type="text"
            name="btn_link"
            register={register}
            error={errors?.btn_link}
          />
        </div>
        <div className="button__section">
          <div className="checkboxes__modal">
            <div className="checkbox__sec">
              <span>Status</span>
              <div className="checks">
                <CheckBox
                  className="checkboxx"
                  label="Show"
                  labelClassname
                  value={hideShow === "show"}
                  onClick={() => setHideShow("show")}
                />
                <CheckBox
                  className="checkboxx"
                  label="Hide"
                  value={hideShow === "hide"}
                  onClick={() => setHideShow("hide")}
                  labelClassname
                />
                <CheckBox
                  className="checkboxx"
                  label="Open in new tab"
                  value={openInNewTab}
                  onClick={() => setOpenInNewTab(!openInNewTab)}
                  labelClassname
                />
              </div>
            </div>
          </div>
          <div className="addBtn">
            <PrimaryButton
              text={`${
                onlyTacStudentDasboardbannerData[0]?.content ? "Update" : "Add"
              }`}
              type="submit"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default StudentBanner;
