/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { Eye, EyeOff, User } from "react-feather";
import InputContainer from "../../Layouts/Input/InputContainer";
import ErrorText from "../ErrorText/ErrorText";
import HelperText from "../HelperText/HelperText";
import { InputLabel } from "../InputLabel/InputLabel";
import "./index.scss";

export default function PasswordField(props: any) {
  const {
    name,
    label,
    type,
    helperText,
    placeholder,
    error,
    register,
    handleInputStyle,
    ...rest
  } = props;
  const [eyeState, setEyeState] = useState(false);
  const toggleEye = () => {
    setEyeState(!eyeState);
  };
  const errorMessage = error?.message;
  return (
    <div>
      <InputContainer>
        <InputLabel name={name} text={label} />
        <div className="password__field__section">
          {" "}
          <input
            id={name}
            name={name}
            type={eyeState ? "text" : "password"}
            className={`${error && "error"} ${handleInputStyle && "handleInputStyle"}`}
            placeholder={placeholder}
            {...(register && register(name))}
            {...rest}
          />
          <div onClick={toggleEye}>
            {eyeState ? (
              <Eye className={`eye_icon ${handleInputStyle && "eye__icon"}`} />
            ) : (
              <EyeOff className={`eye_icon ${handleInputStyle && "eye__icon"}`} />
            )}
          </div>
        </div>
        <ErrorText error={errorMessage} />
        {!errorMessage && helperText && <HelperText text={helperText} />}
      </InputContainer>
    </div>
  );
}
