import { useState, useEffect, RefObject, SetStateAction } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import { setAllResposneSucessToFalse } from "../../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationSlice";
import isEmpty from "../../../../../helpers/utils/IsEmpty";

const UseNotificationQuestion = (
  setCurrentIndex: {
    (value: SetStateAction<number>): void;
    (arg0: number): void;
  },
  recordWebcam: any,
  CAMERA_STATUS: any,
  resetTimer: { (index: any): void; (arg0: any): void },
  stopCamera: () => void
) => {
  const dispatch = useAppDispatch();
  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });

  useEffect(() => {
    if (
      interviewState.req_success.questions &&
      interviewState.notificationData
    ) {
      let questionIndex: any = "";

      interviewState.questions.forEach((question: any, index: number) => {
        if (question.id === interviewState.notificationData.question_id) {
          questionIndex = index;
        }
      });

      if (!isEmpty(questionIndex as number)) {
        setCurrentIndex(questionIndex);
      }
      if (
        recordWebcam.status !== CAMERA_STATUS.OPEN &&
        recordWebcam.status !== CAMERA_STATUS.CLOSED
      ) {
        stopCamera();
      }
      resetTimer(questionIndex);
      dispatch(setAllResposneSucessToFalse());
    }
  }, [interviewState.req_success.questions, interviewState.notificationData]);
};

export default UseNotificationQuestion;
