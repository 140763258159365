import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import ApplyNowHeader from "../../../components/AdminPanel/ApplyNow/ApplyNowHeader/ApplyNowHeader";
import ApplyNowTable from "../../../components/AdminPanel/ApplyNow/ApplyNowTable/ApplyNowTable";
import { RootStore, useAppSelector } from "../../../store/store";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";

const ApplyNow = () => {
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <div>
      {configState.configs?.membership_enabled === Domain.TAA && (
        <>
          <ApplyNowHeader />
          <ApplyNowTable />
        </>
      )}

      {configState.configs?.membership_enabled === Domain.TAC && (
        <AdminPanelLayout>
          <BoxShadow>
            <div className="mt-7">
            <ApplyNowHeader />
            <ApplyNowTable />
            </div>
          </BoxShadow>
        </AdminPanelLayout>
      )}
    </div>
  );
};

export default ApplyNow;
