/* eslint-disable no-useless-escape */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import "./quicklinkshead.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { LogOut } from "react-feather";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import {
  getBase64,
  getFileExtension,
} from "../../../../helpers/utils/FileHelper";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import {
  addQuickLinks,
  deleteQuickLinks,
  loadQuickLinks,
} from "../../../../store/features/UserDashboard/QuickLinks/QuickLinksApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

/* eslint-disable jsx-a11y/click-events-have-key-events */
function QuickLinksHead(props: any) {
  const {
    isEdit,
    setIsEdit,
    openModal,
    setOpenModal,
    showDeleteModal,
    setDeleteShowModal,
  } = props;

  const [imageExt, setImageExt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageError, setImageError] = useState("");
  const [openInNewTab, setOpenInNewTab] = useState(false);
  const hiddenFileInput = useRef<any>(null);

  const dispatch = useAppDispatch();
  const qlState: any = useAppSelector((store: RootStore) => {
    return store.QuickLinksSlice;
  });

  const handleClick = () => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const removeImage = () => {};
  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];
    if (contentFile.size > 2 * 1024 * 1024) {
      setImageError("Icon size must be less than 2 MB");
      setImageUrl("");
      setImageFile("");
      setImageExt("");
      return;
    }
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageError("");
  };

  const validationSchema = yup.object().shape({
    title: yup.string().required(REQUIRED_VALIDATION("Title")),
    description: yup.string().required(REQUIRED_VALIDATION("Description")),
    url: yup.string().required(REQUIRED_VALIDATION("URL")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    setValue,
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    let data;
    // Add
    if (!imageFile) {
      const temp = qlState.all_quicklinks.filter((e: any) => {
        return e.id === qlState.currID;
      });

      if (!temp[0].icon) {
        setImageError("Icon is required !");
        return;
      }
      data = {
        ...values,
        description: values.description,
        open_new_tab: openInNewTab,
      };
    }
    if (imageFile) {
      data = {
        ...values,
        icon: imageFile,
        extension: imageExt,
        description: values.description,
        open_new_tab: openInNewTab,
      };
    }

    if (isEdit) {
      dispatch(addQuickLinks({ ...data, id: qlState.currID }));
      dispatch(loadQuickLinks("dashboard"));
    } else {
      dispatch(addQuickLinks(data));
    }
  };

  const deleteQuickLink = () => {
    dispatch(deleteQuickLinks(qlState.currID!));
  };

  useEffect(() => {
    if (!qlState.loading && qlState.req_success) {
      setValue("title", "");
      setValue("description", "");
      setValue("url", "");
      setImageUrl("");
      setImageFile("");
      setOpenModal(false);
      setDeleteShowModal(false);
      reset();
      setImageError("");
      setOpenInNewTab(false);
    }
  }, [qlState.loading]);
  useEffect(() => {
    if (!openModal) {
      setValue("title", "");
      setValue("description", "");
      setValue("url", "");
      setImageUrl("");
      setImageFile("");
      setOpenModal(false);
      setDeleteShowModal(false);
      reset();
      setImageError("");
      setOpenInNewTab(false);
    }
  }, [openModal]);

  useEffect(() => {
    if (isEdit) {
      const temp = qlState.all_quicklinks.filter((e: any) => {
        return e.id === qlState.currID;
      });
      setValue("title", temp[0].title);
      setValue("description", temp[0].description);
      setValue("url", temp[0].url);
      setImageUrl(temp[0].icon);
      setOpenInNewTab(temp[0].open_new_tab !== 0);
    }
  }, [isEdit]);

  useEffect(() => {
    if (!openModal) {
      setIsEdit(false);
    }
  }, [openModal]);

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  return (
    <>
      <div className="quicklinkhead">
        <h1>Quick Links & Resources</h1>
        <PrimaryButton
          text="Add New"
          size="sm"
          type="button"
          onClick={() => {
            setOpenModal(true);
            setIsEdit(false);
          }}
          loading={false}
        />
      </div>
      <Modal show={openModal} setShow={setOpenModal}>
        <ModalHeader
          title={
            isEdit
              ? "Edit Quick Links & Resources"
              : "Add Quick Links & Resources"
          }
        />
        <ModalBody>
          <form
            className="referenceform"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="form_row">
              <TextField
                type="text"
                label="Title"
                name="title"
                register={register}
                error={errors?.title}
              />
            </div>
            <div className="form_row">
              <TextArea
                type="text"
                label="Description"
                name="description"
                register={register}
                error={errors?.description}
              />
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="URL"
                name="url"
                register={register}
                error={errors?.url}
              />
            </div>
            <div className="form_fieldd">
              {imageUrl && (
                <>
                  <div className="form-label-ql">Icon</div>
                  <div className="ql-thumbnail-section">
                    <div className="ql-image-section">
                      <img
                        src={imageUrl}
                        alt="thumbnail"
                        onClick={handleClick}
                      />
                    </div>
                    <button type="button" onClick={handleClick}>
                      Update Icon
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="form_fieldd hidden-input">
              <input
                className="filefield"
                type="file"
                name="image"
                id="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={(e: any) => getFiles(e)}
              />
            </div>

            {!imageUrl && (
              <div className="form_row img-up">
                <div className={`file_fieldd ${imageError ? "error" : ""}`}>
                  {!imageUrl && (
                    <div className="file_input_design">
                      <span onClick={handleClick}>
                        <span
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <LogOut className="logout_icon" />
                        </span>
                        <p className="clik_text">Click to add Icon</p>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
            <ErrorText error={imageError} />

            <div className="form_row">
              <CheckBox
                label="Open in new tab"
                fullwidth
                value={openInNewTab}
                onClick={() => {
                  setOpenInNewTab(!openInNewTab);
                }}
              />
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton
                text={isEdit ? "Update" : "Add"}
                type="submit"
                loading={qlState.loading}
                onClick={() => {
                  if (!isEdit && !imageFile) {
                    setImageError("Icon is required.");
                  }
                }}
                disabled={qlState.loading}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={qlState.loading}
        onClick={deleteQuickLink}
      />
    </>
  );
}

export default QuickLinksHead;
