import React, { useState } from "react"
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout"
import BoxShadow from "../../../shared/BoxShadow/BoxShadow"
import OurClassesHeader from "../../../components/AdminPanel/OurClasses/OurClassesHeader/OurClassesHeader"
import OurClassesContent from "../../../components/AdminPanel/OurClasses/OurClassesContent/OurClassesContent"

const OurClasses = () => {
    const [showModal, setShowModal] = useState(false);
  return (
    <AdminPanelLayout>
    <BoxShadow>
      <div className="mt-7">
        <OurClassesHeader showModal={showModal} setShowModal={setShowModal}/>
        <OurClassesContent/>
      
    </div>
    </BoxShadow>
    </AdminPanelLayout>
  )
}

export default OurClasses
