/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prettier/prettier */
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import "./videoLinksHeader.scss";
import React, { useEffect, useRef, useState } from "react";
import { LogOut, Star } from "react-feather";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import TextField from "../../../shared/Inputs/TextField/TextField";
import TextArea from "../../../shared/Inputs/TextArea/TextArea";
import ErrorText from "../../../shared/Inputs/ErrorText/ErrorText";
import CheckBox from "../../../shared/Inputs/CheckBox/CheckBox";
import TestimonialRating from "./TestimonialRating";
import { getBase64, getFileExtension } from "../../../helpers/utils/FileHelper";
import {
  CreateTestimonials,
  EditTestimonials,
} from "../../../store/features/AdminPanel/Testimonial/TestimonialApi";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { searchTestimonials } from "../../../store/features/AdminPanel/Testimonial/TestimonialSlice";

const TestimonialHeader = (props: any) => {
  const dispatch = useAppDispatch();
  const testimonialsState: any = useAppSelector((store: RootStore) => {
    return store.TestimonialsSlice;
  });
  const { showModal, setShowModal, editModal, setEditModal, currId } = props;
  const [imageExt, setImageExt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const hiddenFileInput = useRef<any>(null);
  const [imageError, setImageError] = useState("");
  const [atTop, setAtTop] = useState(false);
  const [star, setStar] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleClick = (event: any) => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const removeImage = () => {
    setImageUrl("");
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Member Name")),
    designation: yup.string().required(REQUIRED_VALIDATION("Designation")),
    videourl: yup.string().url().required(REQUIRED_VALIDATION("Video URL")),
    description: yup.string().required(REQUIRED_VALIDATION("Description")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];
    if (contentFile.size > 2 * 1024 * 1024) {
      setImageError("Image size must be less than 2 MB");
      return;
    }
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageError("");
  };
  const clearAll = () => {
    reset();
    setStar(0);
    setImageError("");
    setImageExt("");
    setImageFile("");
    setImageUrl("");
    setAtTop(false);
  };

  const onSubmit = (values: any) => {
    let data;
    if (editModal) {
      if (imageFile) {
        data = {
          ...values,
          name: values.name,
          video_url: values.videourl,
          description: values.description,
          designation: values.designation,
          rating: star,
          is_top: atTop,
          image: imageFile,
          extension: imageExt,
        };
      } else {
        data = {
          ...values,
          name: values.name,
          video_url: values.videourl,
          description: values.description,
          designation: values.designation,
          rating: star,
          is_top: atTop,
        };
      }
      dispatch(EditTestimonials({ id: currId, data: data }));
    } else {
      if (!imageFile) {
        setImageError("Image is required");
        data = {};
        return;
      }
      data = {
        ...values,
        name: values.name,
        video_url: values.videourl,
        description: values.description,
        designation: values.designation,
        rating: star,
        is_top: atTop,
        image: imageFile,
        extension: imageExt,
      };
      dispatch(CreateTestimonials(data));
    }
  };
  useEffect(() => {
    if (testimonialsState.req_success) {
      clearAll();
      setShowModal(false);
      setEditModal(false);
    }
  }, [testimonialsState.req_success]);

  useEffect(() => {
    dispatch(searchTestimonials(searchText));
  }, [searchText]);

  useEffect(() => {
    if (editModal) {
      const data = testimonialsState.data.filter((e: any) => {
        return e.id === currId;
      });
      setValue("name", data[0].name);
      setValue("designation", data[0].designation);
      setValue("videourl", data[0].video_url);
      setImageUrl(data[0].image);
      setValue("description", data[0].description);
      setStar(data[0].rating);
      if (data[0].is_top) {
        setAtTop(true);
      } else {
        setAtTop(false);
      }
    }
  }, [editModal, currId, showModal]);
  const onError = () => {};
  const closeModalandResetFrom = () => {
    setAtTop(false);
  };
  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Testimonials Video - Listing</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add"
            size="sm"
            type="button"
            loading={false}
            onClick={() => {
              setShowModal(true);
              setEditModal(false);
              clearAll();
            }}
          />
        </div>
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        onClick={closeModalandResetFrom}
      >
        <ModalHeader
          title={editModal ? "Edit New Video Link" : "Add New Video Link"}
        />
        <ModalBody>
          <form
            className="login__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="input_text_section">
              <div className="form_row fr-c2 ">
                <TextField
                  className="text-field"
                  type="text"
                  label="Name"
                  name="name"
                  register={register}
                  error={errors?.name}
                />
                <TextField
                  className="text-field"
                  type="text"
                  label="Designation"
                  name="designation"
                  register={register}
                  error={errors?.designation}
                />
              </div>

              <div className="form_row">
                <TextField
                  className="text-field"
                  type="text"
                  label="Video URL"
                  name="videourl"
                  register={register}
                  error={errors?.videourl}
                />
              </div>
            </div>
            <div className="image_row">
              <div className={`file_fieldd ${imageError ? "error" : ""}`}>
                {!imageUrl && (
                  <div className="file_input_design">
                    <span onClick={handleClick}>
                      <span
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <LogOut className="logout_icon" />
                      </span>
                      <p className="clik_text">Click to add Image</p>
                    </span>
                  </div>
                )}
                {imageUrl && (
                  <div className="thumbnail__section">
                    <img
                      src={imageUrl}
                      className="thumbnail__image"
                      alt="thumbnail"
                      onClick={handleClick}
                    />
                  </div>
                )}
                <ErrorText error={imageError} />

                <input
                  className="filefield"
                  type="file"
                  name="image"
                  id="file"
                  accept="image/*"
                  ref={hiddenFileInput}
                  onChange={(e: any) => getFiles(e)}
                />
              </div>
            </div>
            <div className="description">
              <TextArea
                label="Description"
                name="description"
                register={register}
                error={errors?.description}
              />
            </div>
            {/* <div className="rating">
              Rating <TestimonialRating value={star} setValue={setStar} />
            </div> */}
            <div className="select-top-testimonial">
              <CheckBox
                label="Set as top testimonial"
                value={atTop}
                onClick={() => {
                  setAtTop(!atTop);
                }}
              />
              <div className="stt-text">
                (Only one item can be set as top testimonial)
              </div>
            </div>
            <div className="top_testiominal__error">
              {testimonialsState.hasTop && (
                <ErrorText error="Top testimonial has already been set. Check if you want to replace." />
              )}
            </div>
            <div className="button__witdh mt-1">
              <PrimaryButton
                text={editModal ? "Update" : "Add"}
                type="submit"
                loading={testimonialsState.loading}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TestimonialHeader;
