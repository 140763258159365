import ListAllBlogs from "../pages/UserDashboard/Blog/ListAllBlogs";
import ViewSingleBlog from "../pages/UserDashboard/Blog/ViewSingleBlog";
import MyAccount from "../pages/NewAcademicUser/MyAccount/MyAccount";
import { ROUTES } from "../helpers/utils/Constants/route";

const AllPrivateRoute = [
  { id: 3, path: ROUTES.BLOGS, component: ListAllBlogs },
  { id: 4, path: ROUTES.VIEW_SINGLE_BLOG, component: ViewSingleBlog },
  { id: 12, path: ROUTES.MY_ACCOUNT, component: MyAccount },
];

export default AllPrivateRoute;
