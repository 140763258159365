/* eslint-disable react/prop-types */
import { BUNNY_VIDEO_LIBRARY_ID } from "../../../../apiConfigs/urlConfig";

const BunnyIframe = ({ videoId }:any) => {
  return (
    <div className="Bunny__ifreame__content">
    <div className="bunny__frame__wrapper" >
      <iframe
        src={`https://iframe.mediadelivery.net/embed/${BUNNY_VIDEO_LIBRARY_ID}/${videoId}?autoplay=false&loop=false&muted=false&preload=true`}
        loading="lazy"
        style={{border:"none",height: "100%", width: "100%" }}
        allowFullScreen
        title="Video Player"
      />
    </div>
    </div>
  );
};
export default BunnyIframe;