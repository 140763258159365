/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Topography from "../../../shared/Topography/Topography";
import "./index.scss";
import { getSprintLearningPath } from "../../../store/features/AdminPanel/Sprint/SprintApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";

const Sprints = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sprintState: any = useAppSelector((store: RootStore) => {
    return store.SprintSlice;
  });
  useEffect(() => {
    dispatch(getSprintLearningPath());
  }, []);

  return (
    <div className="admin__sprint__wrapper mt-7">
      <Topography> Learning path</Topography>
      <div className="sprint__table">
        <div className="header">
          <span className="title"> Path name</span>
          <span className="title"> Number of courses</span>
        </div>
        <div className="table__body">
          {!sprintState.loading.get_learning_path &&
            sprintState.req_success.get_learning_path &&
            sprintState.learning_paths.map((path: any) => {
              return (
                <div className="row" key={path.id}>
                  <span
                    className="data"
                    onClick={() =>
                      navigate(`/create-sprint/${path.id}`, {
                        state: { path_name: path.name },
                      })
                    }
                  >
                    {path.name}
                  </span>
                  <span className="data"> {path.path_courses_count}</span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Sprints;
