import { DangerButton, PrimaryButton } from "../../Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../Modal";
import "./deletemodal.scss";

const DeleteModal = (props: any) => {
  const { showModal, setShowModal, loading, onClick } = props;

  return (
    <Modal show={showModal} setShow={setShowModal}>
      <ModalHeader title="Are you sure ?" />
      <ModalBody>
        <span className="notice">You won't be able to revert this!</span>
        <div className="delete__divider" />
        <div className="row">
          <div className=" mt-1 mr-2">
            <PrimaryButton
              text="Yes, delete it"
              type="submit"
              loading={loading}
              onClick={onClick}
            />
          </div>
          <div className=" mt-1">
            <DangerButton
              text="Cancel"
              type="submit"
              loading={false}
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
