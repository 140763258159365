import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import LearningPathsCourseDesign from "../../../components/AdminPanel/LearningPaths/LearningPathsCourseDesign";

const LearningPathsCourse = () => {
  return (
    <AdminPanelLayout>
      {/* <BoxShadow> */}
      <LearningPathsCourseDesign />
      {/* </BoxShadow> */}
    </AdminPanelLayout>
  );
};

export default LearningPathsCourse;
