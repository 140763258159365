import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import { pullCourses } from "../../../store/features/AdminPanel/ManageCourse/ManageCourseApi";
import {
  useAppDispatch,
  useAppSelector,
  RootStore,
} from "../../../store/store";
import { searchInLearningCourseTable } from "../../../store/features/AdminPanel/ViewCourses/ViewCoursesSlice";

const LearningPathCourseHeader = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/list-learning-course");
  };

  const location: any = useLocation();

  const courseState: any = useAppSelector((store: RootStore) => {
    return store.ManageCourseSlice;
  });

  const hanldePullCourse = () => {
    dispatch(pullCourses());
  };

  useEffect(() => {
    dispatch(searchInLearningCourseTable(searchText));
  }, [searchText]);
  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>
          <span style={{ display: "flex", marginLeft: "-2rem" }}>
            <span>
              <ChevronLeft onClick={handleNavigate} />
            </span>
            <span> {location.state.name}</span>
          </span>
        </TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        {/* <div className="button__witdh">
          <PrimaryButton
            style={{ width: "11rem" }}
            text="Pull Courses"
            size="sm"
            type="button"
            loading={courseState.pull_loading}
            onClick={hanldePullCourse}
          />
        </div> */}
      </div>
    </div>
  );
};

export default LearningPathCourseHeader;
