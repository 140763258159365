import {
  DangerButton,
  PrimaryButton,
} from "../../../../../shared/Buttons/Buttons";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../../shared/Modals/Modal";
import "./index.scss";

const FeedbackDeleteModal = (props: any) => {
  const { showModal, setShowModal, loading, onClick } = props;
  return (
    <Modal show={showModal} setShow={setShowModal}>
      <ModalHeader title="Are you sure ?" />
      <ModalBody>
        <span className="notice">You won't be able to revert this!</span>
        <div className="delete__divider" />
        <div className="row">
          <div className=" mt-1 mr-2">
            <button
              className="custom__button feedback__delete__button "
              type="button"
              onClick={onClick}
              disabled={loading}
            >
              {loading && <div className="spinner mr-1" />}
              {!loading && "Yes, delete it"}
            </button>
          </div>
          <div className=" mt-1">
            <button
              className="custom__button feedback__cancel__button "
              type="button"
              onClick={() => setShowModal(false)}
              disabled={loading}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FeedbackDeleteModal;
