/* eslint-disable import/no-useless-path-segments */
import React from "react";
// import banner2 from "../../../../assets/images/topbanner.svg"; 
// import banner from "../../../../assets/images/aa-dashboard-banner.png";
import banner from "../../../../assets/images/aa-dashboard.png";
import "./index.scss";

const ImgBgSection = () => {
  return (
    <div className="banner__section">
      <img src={banner} alt="banner" className="banner__image" />
    </div>
  );
};

export default ImgBgSection;
