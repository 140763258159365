/* eslint-disable no-useless-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./index.scss";
import blackUploadIcon from "../../../../assets/images/upload-black.svg";
import whiteUploadIcon from "../../../../assets/images/upload-white.svg";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { UploadFile } from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerApi";
import UploadProgressBar from "../UploadProgressBar/UploadProgressBar";
import { disableUploadScreen } from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerSlice";

const FileUploader = (props: any) => {
  const { resourceState, totalUploadedFile, setTotalUploadedFile } = props;
  const dispatch = useAppDispatch();

  const [progress, setProgress] = useState<any>({
    name: "",
    progress: 0,
    id: 0,
  });

  useEffect(() => {
    localStorage.setItem("parent_id", resourceState.nested_folder_id);
  }, [resourceState.nested_folder_id]);

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const onDrop = useCallback((files: any) => {
    setTotalUploadedFile(0);
    setProgress({ name: "", progress: 0, id: 0 });
    dispatch(disableUploadScreen(true));
    const parentid: any = localStorage.getItem("parent_id");

    if (files.length > 0) {
      files.forEach((element: any, i: number) => {
        const formData = new FormData();
        formData.append("file", element);
        if (parentid > 0) {
          formData.append("parent_id", parentid);
        }
        dispatch(
          UploadFile({
            file: formData,
            name: element.name,
            setProgress: setProgress,
            progress: progress,
          })
        );
      });
    }
  }, []);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [
        ".png",
        ".PNG",
        ".jpg",
        ".JPG",
        ".JPEG",
        ".jpeg",
        ".svg",
        ".bmp",
      ],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.rar": [".rar"],
      "application/zip": [".zip"],
    },
    disabled: resourceState.disable_upload_screen,
  });
  useEffect(() => {
    if (Object.keys(resourceState.uploaded_file).length > 0) {
      if (totalUploadedFile !== acceptedFiles.length) {
        setTotalUploadedFile(totalUploadedFile + 1);
      }
    }
  }, [resourceState.uploaded_file]);

  useEffect(() => {
    if (totalUploadedFile === acceptedFiles.length) {
      dispatch(disableUploadScreen(false));
    }
  }, [totalUploadedFile]);

  useEffect(() => {
    if (resourceState.clean_upload_screen) {
         // @ts-ignore 
      acceptedFiles.length = 0;
         // @ts-ignore 
      acceptedFiles.splice(0, acceptedFiles.length);
    }
  }, [resourceState.clean_upload_screen]);

  return (
    <div className="file__upload__container padding">
      <div className="uploader__card " {...getRootProps()}>
        <div className="circle_bg">
          <img
            src={
              themeState.themeMode === "dark"
                ? blackUploadIcon
                : whiteUploadIcon
            }
            alt="upload"
          />
        </div>

        <input {...getInputProps()} id="upl" />

        <span className="upload_description mt-1">
          Drag and drop files, or browse
        </span>
        <span className="upload_description">
          {" "}
          Supports PDF, doc, docx, image, rar and zip files.
        </span>
      </div>
      <span className="uploader__counter m-1">
        Upload Files {totalUploadedFile}/{acceptedFiles.length} Files
      </span>
      <div className="progress_section">
        {acceptedFiles.map((file) => {
          return (
            <UploadProgressBar
              key={file.name}
              progress={progress}
              file={file}
              fileName={file.name}
              resourceState={resourceState}
              acceptedFiles={acceptedFiles}
              themeState={themeState}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FileUploader;
