import "./index.scss";
import whiteEmptyIcon from "../../assets/images/empty-light.svg";
import blackEmptyIcon from "../../assets/images/empty-black.svg";
import { RootStore, useAppSelector } from "../../store/store";

const Empty = (props: any) => {
  const {
    empty,
    search,
    userResourceEmpty,
    custombg,
    emptyGroup,
    emptyMember,
    emptyText,
    emptyColorFulText,
    emptyText2,
  } = props;

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <div className={`empty_container ${custombg && "custombg"}`}>
      <img
        src={themeState.themeMode === "dark" ? blackEmptyIcon : whiteEmptyIcon}
        alt="ëmpty"
      />
      {empty && (
        <span className="empty_description mt-2">
          It seems empty.<span className="high_light_text"> Add Files</span>{" "}
          now.
        </span>
      )}
      {emptyGroup && (
        <span className="empty_description mt-2">
          It seems empty.
          <span className="high_light_text"> Create Groups</span> now.
        </span>
      )}
      {emptyMember && (
        <span className="empty_description mt-2">
          It seems empty.
          <span className="high_light_text"> Add Members</span> now.
        </span>
      )}
      {search && (
        <span className="empty_description mt-2">
          No results found.
          <span className="high_light_text"> Please try searching </span> with
          another term.
        </span>
      )}
      {userResourceEmpty && (
        <span className="empty_description mt-2">It seems empty.</span>
      )}
      {emptyText && (
        <span className="empty_description mt-2">
          {emptyText}
          <span className="high_light_text"> {emptyColorFulText} </span>{" "}
          {emptyText2}
        </span>
      )}
    </div>
  );
};

export default Empty;
