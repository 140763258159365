/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import { intersect } from "../../../../helpers/utils/MathHelper";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import TagForCourses from "../../../../shared/Tags/TagForCourses";
import { getAllCoursesAndLearningPath } from "../../../../store/features/Tenant/Orgination/Group/GroupApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";

const AssignCourses = (props: any) => {
  const {
    // groupState,
    addAssignment,
    setSelectedCourses,
    checkedLearningPathId,
    setCheckedLearningPathId,
    notitle,
    memberState,
    assismentState,
    editAssignmnet,
  } = props;
  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });
  const dispatch = useAppDispatch();

  const [learningPath, setLearningPath] = useState<any>([]);
  const [courses, setcourses] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [selectedPath, setSelectedPath] = useState<any>([]);
  const [indiviudalCoursesId, setIndiviudalCoursesId] = useState([]);

  // check edit
  const [checkedEditedlearningPath, setCheckedEditedlearningPath] =
    useState(false);
  const [checkedEditedCourse, setCheckedEditedCourse] = useState(false);

  useEffect(() => {
    dispatch(getAllCoursesAndLearningPath());
  }, []);
  useEffect(() => {
    if (memberState.req_success.add) {
      setSelectedData([]);
      setSelectedPath([]);
      setLearningPath(groupState.learning_paths);
      setcourses(groupState.courses);
    }
  }, [memberState.req_success.add]);
  useEffect(() => {
    if (assismentState && assismentState.req_success.add && !editAssignmnet) {
      setSelectedData([]);
      setSelectedPath([]);
      setLearningPath(groupState.learning_paths);
      setcourses(groupState.courses);
    }
  }, [assismentState?.req_success?.add]);
  useEffect(() => {
    if (groupState.req_success.get) {
      setLearningPath(groupState.learning_paths);
      setcourses(groupState.courses);
    }
  }, [groupState.req_success.get]);

  const clearAllSection = () => {
    setLearningPath(groupState.learning_paths);
    setcourses(groupState.courses);
    setSelectedData([]);
    setCheckedLearningPathId([]);
    setSelectedCourses([]);
  };

  useEffect(() => {
    const array: any = [];

    const selcted = courses?.filter((c: any) => {
      return c.checked;
    });
    courses.map((c: any) => {
      const res = intersect([...checkedLearningPathId], c.learning_path_ids);
      if (res.length === 0 && c.checked) {
        array.push(c.id);
      }
    });
    setIndiviudalCoursesId(array);
    setSelectedCourses(selcted);
  }, [courses]);

  const getSelectData = (data: any) => {
    if (data.checked) {
      const index = checkedLearningPathId.indexOf(data.id);
      if (index > -1) {
        checkedLearningPathId.splice(index, 1);
      }
      setCheckedLearningPathId(checkedLearningPathId);

      const path = learningPath.map((path: any) => {
        if (data.id === path.id) {
          return { ...path, checked: false };
        }
        return path;
      });

      const a = courses.map((course: any) => {
        const intersection = intersect(
          course.learning_path_ids,
          checkedLearningPathId,
        );
        const res = intersect([course.id], indiviudalCoursesId);

        if (intersection.length === 0 && res.length === 0) {
          return { ...course, checked: false };
        }
        return course;
      });
      setcourses(a);

      setLearningPath(path);
    }

    if (!data.checked) {
      setCheckedLearningPathId([...checkedLearningPathId, data.id]);
      const path = learningPath.map((path: any) => {
        if (data.id === path.id) {
          return { ...path, checked: true };
        }
        return path;
      });
      const a = courses.map((course: any) => {
        if (data.course_ids.includes(course.id)) {
          return { ...course, checked: true };
        }
        return course;
      });
      setcourses(a);

      setLearningPath(path);
    }
  };
  const checkUnchedCourse = (course: any) => {
    if (course.checked) {
      const filtercourses = courses.map((c: any) => {
        if (c.id === course.id) {
          return { ...c, checked: false };
        }
        return c;
      });

      setcourses(filtercourses);
      const filteredData = filtercourses.filter((c: any) => {
        return c.checked;
      });

      setSelectedData(filteredData);
    }

    if (!course.checked) {
      const filtercourses = courses.map((c: any) => {
        if (c.id === course.id) {
          return { ...c, checked: true };
        }
        return c;
      });
      setcourses(filtercourses);
      const arrayOfArray = filtercourses.filter((data: any) => {
        return data.checked;
      });

      setSelectedData(arrayOfArray);
    }
  };
  useEffect(() => {
    const checkedData = learningPath.filter((data: any) => {
      return data.checked;
    });
    setSelectedPath(checkedData);

    const slectedCourse = courses.filter((c: any) => {
      return c.checked;
    });

    setSelectedData(slectedCourse);
  }, [learningPath]);

  // for assignment
  useEffect(() => {
    if (
      !editAssignmnet &&
      addAssignment &&
      memberState.req_success.get_assignment &&
      groupState.req_success.get &&
      learningPath.length > 0 &&
      !checkedEditedlearningPath
    ) {
      const checkidId: any = [];
      memberState.single_member_data.learning_paths.forEach((u: any) => {
        checkidId.push(u.id);
      });
      const path = learningPath.map((path: any) => {
        if (checkidId.includes(path.id)) {
          return { ...path, checked: true };
        } else {
          return path;
        }
      });
      setLearningPath(path);
      setCheckedLearningPathId(checkidId);

      setCheckedEditedlearningPath(true);
    }
  }, [
    groupState.req_success.get,
    memberState.req_success.get_assignment,
    learningPath,
  ]);

  useEffect(() => {
    if (
      !editAssignmnet &&
      addAssignment &&
      memberState.req_success.get_assignment &&
      groupState.req_success.get &&
      courses.length > 0 &&
      !checkedEditedCourse
    ) {
      const checkidId: any = [];
      memberState.single_member_data.all_courses.forEach((u: any) => {
        checkidId.push(u.id);
      });
      const course = courses.map((course: any) => {
        if (checkidId.includes(course.id)) {
          return { ...course, checked: true };
        } else {
          return course;
        }
      });
      setcourses(course);
      setCheckedEditedCourse(true);
    }
  }, [
    groupState.req_success.get,
    memberState.req_success.get_assignment,
    courses,
  ]);

  // end for learning path

  // for edit individual assignment from member detail
  useEffect(() => {
    if (
      !addAssignment &&
      editAssignmnet &&
      assismentState.req_success.get &&
      groupState.req_success.get &&
      learningPath.length > 0 &&
      !checkedEditedlearningPath
    ) {
      const checkidId: any = [];
      assismentState.data.learning_paths.forEach((u: any) => {
        checkidId.push(u.id);
      });
      const path = learningPath.map((path: any) => {
        if (checkidId.includes(path.id)) {
          return { ...path, checked: true };
        } else {
          return path;
        }
      });
      setLearningPath(path);
      setCheckedLearningPathId(checkidId);

      setCheckedEditedlearningPath(true);
    }
  }, [
    groupState.req_success.get,
    assismentState?.req_success?.get,
    learningPath,
  ]);

  useEffect(() => {
    if (
      !addAssignment &&
      editAssignmnet &&
      assismentState.req_success.get &&
      groupState.req_success.get &&
      courses.length > 0 &&
      !checkedEditedCourse
    ) {
      const checkidId: any = [];
      assismentState.data.lp_courses.forEach((u: any) => {
        checkidId.push(u.id);
      });
      const course = courses.map((course: any) => {
        if (checkidId.includes(course.id)) {
          return { ...course, checked: true };
        } else {
          return course;
        }
      });
      setcourses(course);
      setCheckedEditedCourse(true);
    }
  }, [groupState.req_success.get, assismentState?.req_success?.get, courses]);

  return (
    <div className="select__course__section__assign">
      {!notitle && (
        <span className="select_path__title mb-1">Add assignment</span>
      )}
      <div className="header">
        {" "}
        <span className="select_path__title">Select a course or path</span>
        <span className="clear" onClick={clearAllSection}>
          Clear all selection
        </span>
      </div>
      <div className="according__content__row">
        <div className="content__column__left">
          <span className="content__title"> Learning path</span>
          <div className="left__scrollabe__content custom__scrollbar">
            {!groupState.loading.get &&
              groupState.req_success.get &&
              learningPath.map((learning_path: any) => {
                return (
                  <div className="content__margin" key={learning_path.id}>
                    <CheckBox
                      label={learning_path.name}
                      fullwidth
                      value={learning_path.checked}
                      onClick={() => getSelectData(learning_path)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        {/* <div className="content__column">..</div> */}
        <div className="content__column__right">
          <span className="content__title">
            {" "}
            Individual courses <span className="red_astrick">*</span>
          </span>
          <div className="scrollable_row custom__scrollbar">
            {courses.map((course: any, index: number) => {
              return (
                <div className="content__margin extra" key={course.id}>
                  <CheckBox
                    label={course.name}
                    fullwidth
                    value={course.checked}
                    onClick={() => checkUnchedCourse(course)}
                  />
                </div>
              );
            })}

            {/* <div className="content__margin extra">
              <CheckBox label="business intelligence analyst" fullwidth value />
            </div> */}
          </div>
        </div>
      </div>

      <div className="selected__course__section">
        <div className="selected__header">
          {" "}
          <span className="selected__path__topography">
            Selected path and related courses
          </span>
          <span className="selected__path__topography__thin">
            (individual courses can be remove manually)
          </span>
        </div>
        {(selectedPath.length > 0 || selectedData.length > 0) && (
          <div className="tags__div">
            {selectedPath.length > 0 && (
              <>
                {" "}
                <TagForCourses
                  title="Learning path"
                  dataArray={selectedPath}
                  learningPath
                  checkedLearningPathId={checkedLearningPathId}
                  setCheckedLearningPathId={setCheckedLearningPathId}
                  allcourses={courses}
                  setAllCourses={setcourses}
                  updateDataArray={setSelectedPath}
                  originalData={learningPath}
                  setOriginalData={setLearningPath}
                  nobg
                />
                <div className="tag__divider" />
              </>
            )}
            {selectedData.length > 0 && (
              <TagForCourses
                title="Individual courses"
                dataArray={selectedData}
                indiviudalCourses
                updateDataArray={setSelectedData}
                originalData={courses}
                setOriginalData={setcourses}
                nobg
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignCourses;
