import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateFolder,
  getData,
  UploadFile,
  deleteFileAndFolder,
  getDataInsideFolder,
  renameResource,
} from "./ResourceManagerApi";

const initialState: any = {
  loading: {
    folder: {
      add: false,
      get: false,
      delete: false,
    },
    data: {
      get: false,
      add: false,
    },
    rename: false,
  },
  req_success: {
    folder: {
      add: false,
      get: false,
      delete: false,
      edit: false,
    },
    data: {
      get: false,
      add: false,
    },
    rename: false,
  },
  uploaded_file: {},

  not_found: false,
  disable_upload_screen: false,
  nested_folder_id: null,
  clean_upload_screen: false,
  current_page: 0,
  show_info: false,
  active_id: 0,
  single_file_folder_info: {},

  // folder
  first_page_all_data: [],
  first_page_data: [],
  temp_data: [],
};
// ADD about;
const AuthorSlice = createSlice({
  name: "AuthorSlice",
  initialState,
  reducers: {
    showInfoSlice: (state: any, { payload }: PayloadAction<any>) => {
      state.show_info = true;
      state.single_file_folder_info = payload;
    },
    setActiveSlice: (state: any, { payload }: PayloadAction<any>) => {
      state.active_id = payload;
    },
    closeShowInfoModal: (state: any, { payload }: PayloadAction<any>) => {
      state.show_info = false;
    },
    setNestedFolderId: (state: any, { payload }: PayloadAction<any>) => {
      state.nested_folder_id = payload;
    },

    disableUploadScreen: (state: any, { payload }: PayloadAction<any>) => {
      state.disable_upload_screen = payload;
    },

    setCleanScreen: (state: any) => {
      state.req_success.data.get = false;
      state.first_page_all_data = [];
      state.first_page_data = [];
    },
    setUploaderScreen: (state: any) => {
      state.clean_upload_screen = true;
    },

    searchFilesAndFolder: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.not_found = false;
        state.temp_data = state.first_page_all_data.filter((data: any) =>
          data.resource_name.toLowerCase().includes(payload.toLowerCase()),
        );
        state.first_page_data = state.temp_data;
        if (state.first_page_data.length === 0) {
          state.not_found = true;
        }
      } else {
        state.first_page_data = state.first_page_all_data;
      }
    },
  },
  extraReducers: (builder) => {
    // ADD folder
    builder.addCase(CreateFolder.pending, (state) => {
      state.loading.folder.add = true;
      state.req_success.folder.add = false;
      state.active_id = 0;
    });
    builder.addCase(
      CreateFolder.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.folder.add = false;
        state.req_success.folder.add = true;

        if (payload.data) {
          state.first_page_all_data = [
            payload?.data,
            ...state.first_page_all_data,
          ];
          state.first_page_data = [payload?.data, ...state.first_page_data];
        }
      },
    );
    builder.addCase(CreateFolder.rejected, (state) => {
      state.loading.folder.add = false;
    });

    // ADD files
    builder.addCase(UploadFile.pending, (state) => {
      state.loading.data.add = true;
      state.req_success.data.add = false;
      state.clean_upload_screen = false;
      state.active_id = 0;
    });
    builder.addCase(
      UploadFile.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.data.add = false;
        state.req_success.data.add = true;

        if (payload.data) {
          state.uploaded_file = payload.data;
          state.first_page_all_data = [
            payload?.data,
            ...state.first_page_all_data,
          ];
          state.first_page_data = [payload?.data, ...state.first_page_data];
        }
      },
    );
    builder.addCase(UploadFile.rejected, (state) => {
      state.loading.data.add = false;
    });

    // GET DATA
    builder.addCase(getData.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
      state.active_id = 0;
    });
    builder.addCase(
      getData.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.data.get = false;
        state.req_success.data.get = true;
        state.nested_folder_id = null;
        state.first_page_all_data = payload.data;
        state.first_page_data = payload.data;
      },
    );
    builder.addCase(getData.rejected, (state) => {
      state.loading.data.get = false;
    });

    // GET DATA INSIDE FOLDER
    builder.addCase(getDataInsideFolder.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
      state.active_id = 0;
    });
    builder.addCase(
      getDataInsideFolder.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.data.get = false;
        state.req_success.data.get = true;
        state.first_page_all_data = payload.data;
        state.first_page_data = payload.data;
      },
    );
    builder.addCase(getDataInsideFolder.rejected, (state) => {
      state.loading.data.get = false;
    });

    // RENAME FOLDER AND FILES
    builder.addCase(renameResource.pending, (state) => {
      state.loading.rename = true;
      state.req_success.rename = false;
      state.active_id = 0;
    });
    builder.addCase(
      renameResource.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.rename = false;
        state.req_success.rename = true;
        state.first_page_data = state.first_page_data.map((data: any) => {
          if (data.id === payload?.data.id) {
            return payload?.data;
          }
          return data;
        });
        state.first_page_all_data = state.first_page_all_data.map(
          (data: any) => {
            if (data.id === payload?.data.id) {
              return payload?.data;
            }
            return data;
          },
        );
      },
    );
    builder.addCase(renameResource.rejected, (state) => {
      state.loading.rename = false;
      state.req_success.rename = false;
    });

    // DELETE FILES AND FOLDER
    builder.addCase(deleteFileAndFolder.pending, (state) => {
      state.loading.folder.delete = true;
      state.req_success.folder.delete = false;
      state.active_id = 0;
    });
    builder.addCase(
      deleteFileAndFolder.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.folder.delete = false;
        state.req_success.folder.delete = true;

        state.first_page_all_data = state.first_page_all_data.filter(
          (resource: any) => resource.id !== payload?.id,
        );
        state.first_page_data = state.first_page_data.filter(
          (resource: any) => resource.id !== payload?.id,
        );
      },
    );
    builder.addCase(deleteFileAndFolder.rejected, (state) => {
      state.loading.folder.delete = false;
    });
  },
});

export const {
  searchFilesAndFolder,
  showInfoSlice,
  closeShowInfoModal,
  setActiveSlice,
  setNestedFolderId,
  setCleanScreen,
  setUploaderScreen,

  disableUploadScreen,
} = AuthorSlice.actions;
export default AuthorSlice.reducer;
