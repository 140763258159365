/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import Modal, { ModalBody } from "../../../../shared/Modals/Modal";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  defaultProgress,
  setcurrentStep,
} from "../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";

const SelectAudioVideoModal = (props: any) => {
  const {
    showModal,
    setShowModal,
    setResponseType,
    showRecordbtnForSelectResponseType,
    setShowRecordbtnForSelectResponseType,
    recordWebcam,
    setSelectedTabIndex,
    selectedTabIndex,
  } = props;
  const [type, setType] = useState("video");
  // For interview tour popup
  const dispatch = useAppDispatch();
  const { currentStep } = useAppSelector(
    (store: RootStore) => store.ShowPopupSlice
  );
  const handleModal = () => {
    setShowModal(false);
  };
  const handleNextBtnAction = () => {
    setResponseType(type);
    handleModal();
    setShowRecordbtnForSelectResponseType(false);
    if (type === "video") {
      recordWebcam.open();
    }
    setSelectedTabIndex(null);
    // set the current step of interviewprep tour popup
    dispatch(setcurrentStep(8));
    dispatch(defaultProgress(currentStep));
  };
  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      onClick={handleModal}
      bluishBgModal
      width="response__type__modal__width"
    >
      <ModalBody>
        <div className="audio__video__response__radio">
          <div className="response__type__wrapper">
            <div
              className="response__type"
              role="button"
              onClick={() => setType("audio")}
            >
              <div className="out__circle">
                {type === "audio" && <div className="inner__circle" />}
              </div>
              <label htmlFor="audio">Audio only Response</label>
            </div>
            <div
              className="response__type"
              role="button"
              onClick={() => setType("video")}
            >
              <div className="out__circle">
                {type === "video" && <div className="inner__circle" />}
              </div>
              <label htmlFor="video">Video and Audio Response</label>
            </div>
          </div>
          <div className="audio__video__response__footer">
            <button type="button" onClick={() => handleNextBtnAction()}>
              Next
            </button>
            <button
              type="button"
              onClick={() => handleModal()}
              className="cancelBtn"
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SelectAudioVideoModal;
