/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";

import { ChevronLeft } from "react-feather";
import userimage from "../../../../assets/icons/user.png";
import { Card25 } from "../../../../shared/Card/Card25/Card25";

import "./header.scss";

const MemberDetailHeader = (props: any) => {
  const { data } = props;

  const navigate = useNavigate();

  return (
    <div className="member__detail__header__section">
      <div className="naigation_section">
        <ChevronLeft
          className="back__button"
          onClick={() => navigate("/members")}
        />
        <span className="member__detail__topography">Member details</span>
      </div>
      <Card25>
        <div className="card__padding__section">
          <div className="top__section">
            <div className="avatar__aligner">
              {" "}
              <div className="name_and_avatar_section">
                <img
                  src={data.user.avatar_url ?? userimage}
                  className="dash_team_avatar"
                  alt="name"
                />
                <div className="name__section">
                  <span className="name"> {data.user.name} </span>
                  <span className="user_name">{data.user.email}</span>
                </div>
              </div>
            </div>

            <div className="aligner">
              <div className="role__section">
                <span className="simple__title">Role</span>
                <span className="simple__text">{data.user.role}</span>
              </div>

              <div className="group__section">
                <span className="simple__title">Groups</span>
                {data.min_group.map((group: any, index: number) => {
                  return (
                    <div className="group__name" key={group.id}>
                      <span className="dot">.</span> <span>{group.name}</span>
                    </div>
                  );
                })}

                {data.max_group.length > 0 && (
                  <div className="view__more__groups__section">
                    <span className="view__more__text">View all groups </span>

                    <div className="group__list__card">
                      {data.max_group.map((group: any) => {
                        return (
                          <div className="group__name" key={group.id}>
                            <span className="dot">.</span>{" "}
                            <span>{group.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="assigned__course__section">
                <span className="simple__text num">
                  {data?.assigned_courses}
                </span>
                <span className="simple__title">Assigned courses</span>
              </div>
              <div className="course__porgress__section">
                <span className="total__assigned__course">Course Progress</span>
                <ProgressBar
                  completed={data.course_progress}
                  height="3px"
                  customLabel="."
                  baseBgColor="var( --primary-progress-bar-bg)"
                  bgColor="var( --secondary-progress-bar-bg)"
                  labelSize="0"
                  width="150px"
                />
                <span className="completed__text__precent">
                  {data.course_progress}% completed
                </span>
              </div>
            </div>
          </div>
        </div>
      </Card25>
    </div>
  );
};

export default MemberDetailHeader;
