/* eslint-disable no-else-return */
import { useEffect, useLayoutEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { loadUser } from "../../store/features/Auth/AuthApi";
import { RootStore, useAppDispatch, useAppSelector } from "../../store/store";
import { Domain } from "../../helpers/utils/DomainSwitcher/domains";
import { ROUTES } from "../../helpers/utils/Constants/route";
import { roles } from "../../helpers/utils/Constants/roles";

const AdminAndSubAdminRoute = () => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);
  const [authFail, setAuthFail] = useState(false);
  const dispatch = useAppDispatch();

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  useLayoutEffect(() => {
    dispatch(loadUser({ setAuthFail: setAuthFail }));
  }, []);
  useEffect(() => {
    if (authFail) {
      navigate("/");
    }
  }, [authFail]);

  useLayoutEffect(() => {
    if (authState.authenticated && authState.tac_user) {
      if (
        authState.tac_user.role === "user" &&
        configState.configs.membership_enabled === Domain.TAA
      ) {
        navigate(ROUTES.ACADEMIC_USER_DASHBOARD);
      }
      if (
        authState.tac_user.role === "user" &&
        configState.configs.membership_enabled === Domain.TAC
      ) {
        navigate(ROUTES.PROGRESS);
      }
      if (authState.tac_user.role === "owner") {
        navigate("/organization");
      }
      if (authState.tac_user.role === "admin") {
        navigate("/organization");
      }
      if (authState.tac_user.role === "sys_adm") {
        setAdmin(true);
      }

      if (
        authState.tac_user.role === roles.SUB_ADMIN &&
        !authState?.tac_user?.default_password_updated
      ) {
        navigate("/default-password-change");
      }

      if (
        authState.tac_user.role === roles.SUB_ADMIN &&
        authState?.tac_user?.default_password_updated
      ) {
        setAdmin(true);
      }
    }
  }, [authState.authenticated]);

  if (admin) {
    return <Outlet />;
  } else {
    return null;
  }
};

export default AdminAndSubAdminRoute;
