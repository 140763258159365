import React, { useEffect, useState } from "react";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../../store/store";

import Modal, { ModalBody, ModalHeader } from "../../../../../shared/Modals/Modal";
import { Domain } from "../../../../../helpers/utils/DomainSwitcher/domains";
import { PlanButton, PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import "./index.scss"
import UploadBulkArchiveFileUploader from "../FileUploader/UploadBulkArchiveFileUploader/UploadBulkArchiveFileUploader";
import { cleanArchiveUploaderScreen } from "../../../../../store/features/AdminPanel/Archives/ArchiveSlice";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromComponentLevelState } from "../../../../../helpers/utils/urlHelper";


const UploadBulkArciveModal = (props: any) => {
  const {
    showFileUploadModal,
    setShowFileUploadModal,
  } = props;
  const [downloadCsvLoading, setDownloadCsvLoading] = useState(false);
  const dispatch = useAppDispatch();

  const archiveState: any = useAppSelector((store: RootStore) => {
    return store.ArchiveSlice;
  });
  const [fileUploadError, SetFileUpladError] = useState<any>({});

  const [progress, setProgress] = useState<any>({
    name: "",
    progress: 0,
    id: 0,
  });

  const handleModal = () => {
    setShowFileUploadModal(false);
    SetFileUpladError({});
    dispatch(cleanArchiveUploaderScreen());
  };

  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const downloadSampleCsv = async () => {
    setDownloadCsvLoading(true);
    const dynamic_url = renderDynamicUrlFromComponentLevelState(configState);
    await axiosInstance.get(`${dynamic_url}/class-archives/sample-csv-url`).then((res) => {
      window.location.href = res.data.data;
    });
    setDownloadCsvLoading(false);
  };

  return (
    <Modal
      show={showFileUploadModal}
      setShow={setShowFileUploadModal}
      onClick={handleModal}
    >
      <ModalHeader title="File Upload" />
      <ModalBody>
        <UploadBulkArchiveFileUploader
           fileUploadError={fileUploadError}
           SetFileUpladError={SetFileUpladError}
           progress={progress}
           setProgress={setProgress}
         />
        <div className="csv_uploader_foooter__section">
          {archiveState.uploaderLoading ? (
            <div className="waiting__screen">
              <div className="waiting_spinner" />
              <span className="text"> Please Wait...</span>
            </div>
          ) : (
            configState.configs?.membership_enabled === Domain.TAA && (
              <div className="file__upload__btn">
                <PlanButton
                  text="Download Sample"
                  size="sm"
                  type="button"
                  onClick={()=>downloadSampleCsv()}
                  loading={downloadCsvLoading}
                />
              </div>
            )
          )}
          <div className="">
            <PrimaryButton
              text="Done"
              type="submit"
              onClick={handleModal}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UploadBulkArciveModal
