import { createAsyncThunk } from "@reduxjs/toolkit";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { ERROR__TOASTER } from "../../../../helpers/utils/TosterError";

export const getApplyNowList = createAsyncThunk(
  "getApplyNowList",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/apply-now-details`);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getApplyNowDetails = createAsyncThunk(
  "getApplyNowDetails",
  async (id: string, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/apply-now-details/${id}`
      );
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


export const downloadApplyNow = createAsyncThunk(
  "downloadUserCsvFile",
  async (
    { format, role }: { format: string; role?: string },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const response = await axiosInstance.get(
        `${dynamic_url}/download-apply-now/${format}?role=${role}`,
        {
          responseType: "blob",
        }
      );
      const roleForName = role?.toLowerCase().replace(" ", "_");
      const fileURL = window.URL.createObjectURL(response.data);
      const alink = document.createElement("a");
      alink.href = fileURL;
      const now = new Date();
      alink.download = `apply_now_${roleForName}_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}.${format}`;
      alink.click();
      URL.revokeObjectURL(fileURL);
      return true;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
