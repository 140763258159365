import { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";
import AdminPanelLayout from "../../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import "../index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  AddAboutUs,
  getAboutUs,
} from "../../../../store/features/AdminPanel/Setting/About/AboutApi";

const AboutUs = () => {
  const editor = useRef(null);

  const dispatch = useAppDispatch();
  const [content, setContent] = useState("");

  const AboutState: any = useAppSelector((store: RootStore) => {
    return store.AboutSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const config: any = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    readOnly: false,
    placeholder: "write something here.....",
    height: 700,
    toolbarSticky: false,
    theme: themeState.themeMode === "dark" ? "dark" : "default",
  };
  useEffect(() => {
    dispatch(getAboutUs());
  }, []);

  useEffect(() => {
    if (AboutState.req_success) {
      setContent(AboutState?.about?.content);
    }
  }, [AboutState.req_success]);
  const submitContent = () => {
    dispatch(AddAboutUs({ data: content }));
  };
  return (
    <AdminPanelLayout>
      <div className="terms_container mt-7">
        <span className="company__setting">Company Settings</span>
        <span className="company__setting__title">About Us</span>

        <div className="editor-wrapper">
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={(newContent) => setContent(newContent)}
          />
        </div>
        <div className="button_sec">
          <PrimaryButton
            style={{ width: "10rem" }}
            text="Submit"
            label="Submit"
            loading={AboutState.loading}
            onClick={submitContent}
          />
        </div>
      </div>
    </AdminPanelLayout>
  );
};

export default AboutUs;
