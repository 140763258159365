import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Header from "./Header";
import "./index.scss";
import Assignments from "./Assignments/Assignments";
import { getMemberDetails } from "../../../../store/features/Tenant/Orgination/Member/MemberApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import TenantLoadingScreen from "../../../Auth/TenantLoading/TenantLoadingScreen";

const MemberDetails = (props: any) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const memberState: any = useAppSelector((store: RootStore) => {
    return store.MemberSlice;
  });
  useEffect(() => {
    dispatch(getMemberDetails({ memberId: Number(params.id) }));
  }, []);
  return (
    <>
    <div className="member__details__wrapper">
      {!memberState.loading.detail && memberState.req_success.detail && (
        <>
          <Header memberState={memberState} />
          <div className="mt-3">
            <Assignments
              title="Learning path assignments"
              assignmentState={
                memberState.member.assignments.learning_path_assignments
              }
              yesPath
            />
          </div>
          <div className="mt-3">
            <Assignments
              title="Course assignments"
              assignmentState={
                memberState.member.assignments.course_assignments
              }
              YesCourse
            />
          </div>
        </>
      )}
    </div>
    {
      memberState.loading.detail && !memberState.req_success.detail && (<TenantLoadingScreen message=""/>)
    }
    </>
  );
};

export default MemberDetails;
