import { useEffect } from "react";

import Modal, { ModalBody } from "../../../../../shared/Modals/Modal";

// import timer from "../../../../assets/images/timer.svg";
import warn from "../../../../../assets/images/warn.svg";
import { RootStore, useAppSelector } from "../../../../../store/store";

import "./index.scss";

const RecordingLimitModal = (props: any) => {
  const { showModal, setShowModal } = props;
  const handleModal = () => {};

  return (
    <Modal show={showModal} setShow={setShowModal} onClick={handleModal} bluishBgModal>
      <ModalBody modalWidth="431px" className="res__modal__body__width__handle">
        <div className="redording__limit__modal__wrapper">
          <div className="alert__image__section">
            {" "}
            <img className="alert__image" src={warn} alt="timer" />
          </div>
          <span className="time_limit">Recording limit reached!</span>
          <span className="limit__desc">
            Responses recording limit has been reached. You will not be able to
            record any additional responses until admin have deleted some of your
            recorded response.
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RecordingLimitModal;
