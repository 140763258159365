import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../apiConfigs/urlConfig";
import { renderDynamicUrlFromState } from "../../../helpers/utils/urlHelper";

export const getNotificationsList = createAsyncThunk(
  "getNotificationsList",
  async (
    { page, type, filter }: { page: any; type: any; filter: boolean },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/notifications?page=${page}&type=${type}`
      );
      res.data.type = type;
      res.data.filter = filter;
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const markNotificationsAsRead = createAsyncThunk(
  "markNotificationsAsRead",
  async ({ id }: { id: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/notification/${id}/markAsRead`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const markAllNotificationsAsRead = createAsyncThunk(
  "markAllNotificationsAsRead",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/notifications/markAllAsRead`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
