import { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import AdminPanelHeader from "../AdminPanelHeader/AdminPanelHeader";
import AdminPanelSidebar from "../AdminPanelSidebar/AdminPanelSidebar";
import "react-toastify/dist/ReactToastify.css";
import "./adminPanelLayout.scss";
import { RootStore, useAppDispatch, useAppSelector } from "../../../store/store";
import useMediaQuery from "../../../hooks/MediaQuery";
import OfferBanner from "../AdminPanelHeader/OfferBanner/OfferBanner";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../helpers/utils/Constants/roles";
import { getBannersData } from "../../../store/features/AdminPanel/Banners/BannersApi";

function AdminPanelLayout(props: any) {
  const { children } = props;
  const [showSidebarInSmDevice, setShowSidebarInSmDevice] = useState(false);
  const [smSidebar, setSmSidebar] = useState(false);
  const dispatch = useAppDispatch();

  const inMobileView = useMediaQuery(1100);

      //* **** FOR Admin PROFILE DETECT OUTSIDE CLICK**************** */
      const mobileSidebarRef: any = useRef(null);
      const iconRef: any = useRef(null);

      const configState: any = useAppSelector((store: RootStore) => {
        return store.ThemeSlice;
      });
      const authState: any = useAppSelector((store: RootStore) => {
        return store.AuthSlice;
      });
    
      const handleClickOutSideAdminPannelSidebar = (event: any) => {
        if (
          mobileSidebarRef.current && !mobileSidebarRef.current.contains(event.target)
        ) {
          if (iconRef.current && !iconRef.current.contains(event.target)) {
            setShowSidebarInSmDevice(false);
          }
        }
      };
      useEffect(() => {
        document.addEventListener("mousedown", handleClickOutSideAdminPannelSidebar);
      }, []);
// console.log(inMobileView);
const isInSmDevice = authState.tac_user?.plan === "free" && configState.configs?.membership_enabled === Domain.TAC &&
authState.tac_user?.role !== roles.SUB_ADMIN && authState.tac_user?.role !== roles.SYSTEM_ADMIN &&authState.tac_user?.role !== roles.TENANT_ADMIN  && inMobileView
  return (
    <div className="adminPanelLayout__container">
      <div className="sidebar">
        <AdminPanelSidebar smSidebar={smSidebar} setSmSidebar={setSmSidebar} showSidebarInSmDevice={showSidebarInSmDevice} setShowSidebarInSmDevice={setShowSidebarInSmDevice} mobileSidebarRef={mobileSidebarRef} />
      </div>
      <div
        className={`${
          smSidebar ? "content__container sm" : "content__container"
        }`}
      >
        <div className="header__section">
          <AdminPanelHeader smSidebar={smSidebar} setSmSidebar={setSmSidebar} showSidebarInSmDevice={showSidebarInSmDevice} setShowSidebarInSmDevice={setShowSidebarInSmDevice}  iconRef={iconRef} inMobileView={inMobileView}/>
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover  
          />
        </div>
        <div className={`content__section ${configState?.configs?.tenant_name === null || inMobileView ? "" : "mt-7"}`}>
          {isInSmDevice &&  <OfferBanner inMobileView={inMobileView}/>}
          {children}
          </div>
      </div>
    </div>
  );
}

export default AdminPanelLayout;
