import React, { useEffect } from "react";
import "./usertable.scss";
import Table, {
  TableBody,
  TableHeading,
} from "../../../../../shared/Table/Table";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";

const UserTable = () => {
  const dispatch = useAppDispatch();

  const webinarState: any = useAppSelector((store: RootStore) => {
    return store.WebinarSlice;
  });

  return (
    <div>
      <div className="webinar_user_table">
        <Table
          heading={
            <TableHeading>
              <th> Full Name</th>
              <th> Email Address</th>
              <th> Linkedin Url</th>
              <th> Date</th>
              <th> Time</th>
              {/* <th className="hide__text">.</th> */}
            </TableHeading>
          }
        >
          <TableBody>
            {webinarState.req_success.get &&
              webinarState.webinar_users.length > 0 &&
              webinarState.webinar_users.map((user: any) => {
                return (
                  <tr key={user.id} className="table_row_handle">
                    <td>{user.name ?? "-"}</td>
                    <td className="webinar_user_td">{user.email}</td>
                    <td className={user.linkedin && "webinar_user_td"}>
                      {user.linkedin ?? "-"}
                    </td>
                    <td>{user.webinar_date ?? "-"}</td>
                    <td className="time_padiing_handle_td">
                      {user.webinar_time ?? "-"}
                    </td>
                  </tr>
                );
              })}
            {webinarState.req_success.get &&
              webinarState.webinar_users.length === 0 && (
                <tr>
                  <td colSpan={5} className="padding_empty">
                    No user has registered for the webinar.
                  </td>
                </tr>
              )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default UserTable;
