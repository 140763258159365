/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import TagForCourses from "../../../../shared/Tags/TagForCourses";
import "./index.scss";

const SelectCourse = (props: any) => {
  const {
    groupState,
    setPathForIndiviudalDeadline,
    checkedLearningPathId,
    setCheckedLearningPathId,
    edit,
  } = props;

  const [learningPath, setLearningPath] = useState<any>([]);
  const [courses, setcourses] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [selectedPath, setSelectedPath] = useState<any>([]);
  // check edit
  const [checkedEditedlearningPath, setCheckedEditedlearningPath] =
    useState(false);
  const [checkedEditedCourse, setCheckedEditedCourse] = useState(false);
  const [indiviudalCoursesId, setIndiviudalCoursesId] = useState([]);
  const [clearUpdate, setClearUpdate] = useState(false);

  useEffect(() => {
    if (groupState.req_success) {
      setLearningPath(groupState.learning_paths);
      setcourses(groupState.courses);
    }
  }, [groupState.req_success]);

  const clearAllSection = () => {
    setLearningPath(groupState.learning_paths);
    setcourses(groupState.courses);
    setSelectedPath([]);
    setSelectedData([]);
    setCheckedLearningPathId([]);
    setPathForIndiviudalDeadline([]);
    setCheckedEditedlearningPath(false);
    setCheckedEditedCourse(false);
  };
  const clearAssignment = () => {
    setClearUpdate(true);
    setLearningPath(groupState.learning_paths);
    setcourses(groupState.courses);
    setSelectedPath([]);
    setSelectedData([]);
    setCheckedLearningPathId([]);
    setPathForIndiviudalDeadline([]);
  };
  useEffect(() => {
    if (groupState.req_success.add) {
      clearAllSection();
    }
  }, [groupState.req_success.add]);
  useEffect(() => {
    const array: any = [];
    const selcted = courses?.filter((c: any) => {
      return c.checked;
    });
    courses.map((c: any) => {
      const res = intersect([...checkedLearningPathId], c.learning_path_ids);
      if (res.length === 0 && c.checked) {
        array.push(c.id);
      }
    });
    setIndiviudalCoursesId(array);
    setPathForIndiviudalDeadline(selcted);
  }, [courses]);

  function intersect(a: any, b: any) {
    let setA: any = new Set(a);
    let setB: any = new Set(b);
    let intersection = new Set([...setA].filter((x) => setB.has(x)));
    return Array.from(intersection);
  }

  const getSelectData = (data: any) => {
    if (data.checked) {
      const index = checkedLearningPathId.indexOf(data.id);
      if (index > -1) {
        checkedLearningPathId.splice(index, 1);
      }
      setCheckedLearningPathId(checkedLearningPathId);

      const path = learningPath.map((path: any) => {
        if (data.id === path.id) {
          return { ...path, checked: false };
        }
        return path;
      });

      const a = courses.map((course: any) => {
        const intersection = intersect(
          course.learning_path_ids,
          checkedLearningPathId,
        );

        const res = intersect([course.id], indiviudalCoursesId);
        if (intersection.length === 0 && res.length === 0) {
          return { ...course, checked: false };
        }
        return course;
      });
      setcourses(a);

      setLearningPath(path);
    }

    if (!data.checked) {
      setCheckedLearningPathId([...checkedLearningPathId, data.id]);
      const path = learningPath.map((path: any) => {
        if (data.id === path.id) {
          return { ...path, checked: true };
        }
        return path;
      });
      const a = courses.map((course: any) => {
        if (data.course_ids.includes(course.id)) {
          return { ...course, checked: true };
        }
        return course;
      });
      setcourses(a);

      setLearningPath(path);
    }
  };
  const checkUnchedCourse = (course: any) => {
    if (course.checked) {
      const filtercourses = courses.map((c: any) => {
        if (c.id === course.id) {
          return { ...c, checked: false };
        }
        return c;
      });

      setcourses(filtercourses);
      const filteredData = filtercourses.filter((c: any) => {
        return c.checked;
      });

      setSelectedData(filteredData);
    }

    if (!course.checked) {
      const filtercourses = courses.map((c: any) => {
        if (c.id === course.id) {
          return { ...c, checked: true };
        }
        return c;
      });
      setcourses(filtercourses);
      const arrayOfArray = filtercourses.filter((data: any) => {
        return data.checked;
      });

      setSelectedData(arrayOfArray);
    }
  };
  useEffect(() => {
    const checkedData = learningPath.filter((data: any) => {
      return data.checked;
    });
    setSelectedPath(checkedData);

    const slectedCourse = courses.filter((c: any) => {
      return c.checked;
    });

    setSelectedData(slectedCourse);
  }, [learningPath]);

  useEffect(() => {
    if (
      groupState.req_success.single_get &&
      groupState.req_success.get &&
      learningPath.length > 0 &&
      !checkedEditedlearningPath &&
      edit
    ) {
      const checkidId: any = [];
      groupState.single_group_info.learning_paths.forEach((u: any) => {
        checkidId.push(u.id);
      });
      const path = learningPath.map((path: any) => {
        if (checkidId.includes(path.id)) {
          return { ...path, checked: true };
        } else {
          return path;
        }
      });
      setLearningPath(path);
      setCheckedLearningPathId(checkidId);
      setCheckedEditedlearningPath(true);
    }
  }, [groupState.req_success, learningPath]);

  useEffect(() => {
    if (
      groupState.req_success.single_get &&
      groupState.req_success.get &&
      courses.length > 0 &&
      !checkedEditedCourse &&
      edit &&
      !clearUpdate
    ) {
      const checkidId: any = [];
      groupState.single_group_info.all_courses.forEach((u: any) => {
        checkidId.push(u.id);
      });
      const course = courses.map((course: any) => {
        if (checkidId.includes(course.id)) {
          return { ...course, checked: true };
        } else {
          return course;
        }
      });
      setcourses(course);
      setCheckedEditedCourse(true);
    }
  }, [groupState.req_success, courses]);

  // end for learning path

  //  for courses
  // end for courses
  return (
    <div className="select__course__section">
      <span className="select_path__title mb-1">Add assignment</span>
      <div className="header">
        {" "}
        <span className="select_path__title">Select a course or path</span>
        <span className="clear" onClick={clearAssignment}>
          Clear all selection
        </span>
      </div>
      <div className="according__content__row">
        <div className="content__column__left">
          <span className="content__title"> Learning path</span>
          <div className="left__scrollabe__content custom__scrollbar">
            {!groupState.loading.get &&
              groupState.req_success.get &&
              learningPath.map((learning_path: any, index: number) => {
                return (
                  <div className="content__margin" key={learning_path.id}>
                    <CheckBox
                      label={learning_path.name}
                      fullwidth
                      value={learning_path.checked}
                      onClick={() => getSelectData(learning_path)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        {/* <div className="content__column">..</div> */}
        <div className="content__column__right">
          <span className="content__title">
            {" "}
            Individual courses <span className="red_astrick">*</span>
          </span>
          <div className="scrollable_row custom__scrollbar">
            {courses.map((course: any, index: number) => {
              return (
                <div className="content__margin extra" key={course.id}>
                  <CheckBox
                    label={course.name}
                    fullwidth
                    value={course.checked}
                    onClick={() => checkUnchedCourse(course)}
                  />
                </div>
              );
            })}
            {/* <div className="content__margin extra">
              <CheckBox label="business intelligence analyst" fullwidth value />
            </div> */}
          </div>
        </div>
      </div>

      <div className="selected__course__section">
        <div className="selected__header">
          {" "}
          <span className="selected__path__topography">
            Selected path and related courses
          </span>
          <span className="selected__path__topography__thin">
            (individual courses can be remove manually)
          </span>
        </div>
        {(selectedPath.length > 0 || selectedData.length > 0) && (
          <div className="tags__div">
            {selectedPath.length > 0 && (
              <>
                {" "}
                <TagForCourses
                  title="Learning path"
                  dataArray={selectedPath}
                  learningPath
                  checkedLearningPathId={checkedLearningPathId}
                  setCheckedLearningPathId={setCheckedLearningPathId}
                  allcourses={courses}
                  setAllCourses={setcourses}
                  updateDataArray={setSelectedPath}
                  originalData={learningPath}
                  setOriginalData={setLearningPath}
                  nobg
                />
                <div className="tag__divider" />
              </>
            )}
            {selectedData.length > 0 && (
              <TagForCourses
                title="Individual courses"
                dataArray={selectedData}
                indiviudalCourses
                updateDataArray={setSelectedData}
                originalData={courses}
                setOriginalData={setcourses}
                nobg
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectCourse;
