import React from "react";
import UpcomingSessionsUsersComp from "../../../components/AdminPanel/UpcomingSessionsUsers/UpcomingSessionsUsersComp";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const UpcomingSessionsUsers = () => {
  return (
    <AdminPanelLayout>
        <UpcomingSessionsUsersComp />
    </AdminPanelLayout>
  );
};

export default UpcomingSessionsUsers;
