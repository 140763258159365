import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

export const getWeeklyLearningData = createAsyncThunk(
  "getWeeklyLearningData",
  async (
    { params }: { params: string },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/weekly-learnings?for=${params}`
      );
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addOrUpdateWeeklyLearning = createAsyncThunk(
  "addOrUpdateWeeklyLearning",
  async (
    { data, idForUpdate }: { data: any; idForUpdate: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/weekly-learnings`,
        data
      );
      REQUIRED__TOASTER(res.data);
      res.data.idForUpdate = idForUpdate;

      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteWeeklyLearning = createAsyncThunk(
  "deleteWeeklyLearning",
  async ({ id }: { id: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(
        `${dynamic_url}/weekly-learnings/${id}`
      );
      REQUIRED__TOASTER(res.data);

      res.data.id = id;

      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.response);
    }
  }
);
