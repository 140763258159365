import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import AllInterviewTab from "../../../components/AdminPanel/AdminInterview/Tab/AllInterviewTab";
import AdminPanelLoyoutWithoutSidebar from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLoyoutWithoutSidebar";

const MentorInterview = () => {
  return (
    <AdminPanelLoyoutWithoutSidebar>
      <AllInterviewTab />
    </AdminPanelLoyoutWithoutSidebar>
  );
};

export default MentorInterview;
