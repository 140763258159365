import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import AllProfileFeatureTab from "../../../components/UserDashboard/UserProfile/Tab/AllProfileFeatureTab";

const UserProfile = () => {
  return (
    <AdminPanelLayout>
      <AllProfileFeatureTab />
    </AdminPanelLayout>
  );
};

export default UserProfile;
