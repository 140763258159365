import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import SelectField from "../../../../shared/Inputs/SelectField/SelectField";
import {
  addNewPayment,
  getPayments,
  updatePayment,
} from "../../../../store/features/AdminPanel/Payment/PaymentApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  closeModal,
  searchPaymentInTable,
  setShowModal,
} from "../../../../store/features/AdminPanel/Payment/PaymentSlice";

const PaymentHeader = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  const options = [
    { value: "monthly", label: "Monthly" },
    { value: "annually", label: "Annually" },
  ];
  const validationSchema = yup.object().shape({
    title: yup.string().required(REQUIRED_VALIDATION("Title is required")),
    min_seat: yup
      .string()
      .required(REQUIRED_VALIDATION("Minimum seat is required")),
    max_seat: yup
      .string()
      .required(REQUIRED_VALIDATION("Maximum seat is required")),
    price: yup.string().required(REQUIRED_VALIDATION("Price is required")),
    payment_duration: yup
      .object()
      .required(REQUIRED_VALIDATION("Payment Duration is required")),
  });

  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.AdminPaymentSlice;
  });

  const onSubmit = (values: any) => {
    const { payment_duration } = values;
    const data = {
      ...values,
      payment_duration: payment_duration.value,
    };
    if (!paymentState.isEdit) dispatch(addNewPayment({ data }));
    else dispatch(updatePayment({ id: paymentState.current_payment.id, data }));
    handleModal();
  };

  useEffect(() => {
    if (paymentState.isEdit) {
      setValue("title", paymentState.current_payment.title);
      setValue("min_seat", paymentState.current_payment.min_seat);
      setValue("max_seat", paymentState.current_payment.max_seat);
      setValue("price", paymentState.current_payment.price);
      setValue("payment_duration", {
        value: paymentState.current_payment.duration_type,
        label:
          paymentState.current_payment.duration_type.charAt(0).toUpperCase() +
          paymentState.current_payment.duration_type.slice(1),
      });
    }
  }, [paymentState?.isEdit]);

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const handleModal = () => {
    reset();
    setValue("payment_duration", {
      label: "Select Option",
    });
    dispatch(closeModal());
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    dispatch(searchPaymentInTable(searchText));
  }, [searchText]);

  return (
    <div className="header_container boxshadow">
      <div className="left__section">
        <TableHeadingTitle>Payment Plan Listing</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={() => dispatch(setShowModal())}
          />
        </div>
      </div>
      <Modal
        show={paymentState.showModal}
        setShow={setShowModal}
        resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader title="Add New Payment Plan" />
        <ModalBody>
          <form
            className="user__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <TextField
              type="text"
              label="Title"
              name="title"
              register={register}
              error={errors?.title}
            />

            <TextField
              type="number"
              label="Minimum Seat"
              name="min_seat"
              disabled={paymentState.isEdit}
              register={register}
              error={errors?.min_seat}
            />

            <TextField
              type="number"
              label="Maximum Seat"
              name="max_seat"
              disabled={paymentState.isEdit}
              register={register}
              error={errors?.max_seat}
            />
            <TextField
              type="number"
              label="Price ($)"
              name="price"
              disabled={paymentState.isEdit}
              register={register}
              error={errors?.price}
            />
            <Controller
              control={control}
              name="payment_duration"
              render={({ field }) => (
                <SelectField
                  type="text"
                  label="Select Duration"
                  options={options}
                  register={register}
                  isDisabled={paymentState.isEdit}
                  error={errors?.payment_duration}
                  {...field}
                />
              )}
            />
            <div className="modal_footer">
              <div className="button__witdh">
                <PrimaryButton
                  text="Submit"
                  type="submit"
                  loading={
                    paymentState.loading.create || paymentState.loading.patch
                  }
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentHeader;
