import PaginateButton from "../../../../shared/PaginateButton/PaginateButton";
import { usersPaginate } from "../../../../store/features/AdminPanel/User/UserSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./userFooter.scss";

const UserFooter = () => {
  const dispatch = useAppDispatch();
  const userState: any = useAppSelector((store: RootStore) => {
    return store.UserSlice;
  });
  const handlePagination = (num: number) => {
    dispatch(usersPaginate(num));
  };
  //   usersPaginate;
  return (
    <div className="pagination__section">
      <PaginateButton
        paginate={handlePagination}
        totalRows={userState.all_users.length}
      />
    </div>
  );
};

export default UserFooter;
