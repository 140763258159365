/* eslint-disable no-unsafe-optional-chaining */

import React, { useEffect, useRef, useState } from "react";
import { Edit2, MoreVertical, Star, Trash } from "react-feather";
import Rating from "react-rating";
import DeleteModal from "../../../../../../../shared/DeleteModal/DeleteModal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/store";
import { deleteInterviewAnswerFeedback } from "../../../../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.actions";
import { setSelectedFeedback } from "../../../../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.slice";
import AudioPlayer from "../../../../../../../shared/AudioPlayer/AudioPlayer";

const RecentSingleFeedBack = (props: any) => {
  const { data, setShow } = props;

  const [deleteId, setDeleteId] = useState("");
  const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
  const [showAction, setShowAction] = useState(false);

  const selectSectionRef: any = useRef(null);
  const threedotRef: any = useRef(null);

  const dispatch = useAppDispatch();

  const UserDetail = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const isAdmin =
    UserDetail?.tac_user?.role === data?.user_role?.toLowerCase() ||
    UserDetail?.tac_user?.role === "sys_adm";

  const handleClickOutSide = (event: any) => {
    if (threedotRef.current && !threedotRef.current.contains(event.target)) {
      if (
        selectSectionRef.current &&
        !selectSectionRef.current.contains(event.target)
      ) {
        setShowAction(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);

  const hideShowToggle = () => {
    setShowAction(!showAction);
  };

  const user_avatar = data?.user?.charAt(0);

  const handleFeedbackDelete = () => {
    dispatch(deleteInterviewAnswerFeedback(deleteId));
    setShowUserDeleteModal(false);
  };

  const handleEditFeedback = () => {
    dispatch(setSelectedFeedback(data));
    setShow(true);
  };

  return (
    <>
      <div className="single__feedbacK__wrapper__div">
        <div className="left__section__div">
          <div className="avatar__name">{user_avatar}</div>
        </div>
        <div className="right__section__div">
          {" "}
          <div className="top__section__wrapper">
            <div className="user__name__role__div">
              <span className="user__name">{data?.user}</span>
              <span className="dot" />
              <span className="user__role">{data?.user_role}</span>
            </div>

            {isAdmin && (
              <div ref={threedotRef}>
                <MoreVertical className="three__dot" onClick={hideShowToggle} />
              </div>
            )}

            {showAction && (
              <div className="select__section__wrapper " ref={selectSectionRef}>
                {data?.type === "text" && (
                  <>
                    <div
                      className="select__action"
                      role="button"
                      aria-hidden
                      onClick={handleEditFeedback}
                    >
                      <Edit2 className="action__icon" /> <span> Edit</span>
                    </div>
                    <div className="action__divider" />
                  </>
                )}

                <div
                  className="select__action"
                  role="button"
                  aria-hidden
                  onClick={() => {
                    setDeleteId(data?.id);
                    setShowUserDeleteModal(true);
                  }}
                >
                  <Trash className="action__icon" /> <span> Delete</span>
                </div>
              </div>
            )}
          </div>
          <div className="content__section">
            {data?.rating /*  @ts-ignore */ && (
              <Rating
                quiet
                initialRating={data?.rating}
                emptySymbol={
                  <Star className="rating__star__size" color="#FFC600" />
                }
                fullSymbol={
                  <Star
                    className="rating__star__size"
                    color="#FFC600"
                    fill="#FFC600"
                  />
                }
              />
            )}
            {data?.type === "text" && <p>{data?.feedback}</p>}
            {data?.type === "audio" && (
              <div className="mt-1">
                <AudioPlayer duration={data?.duration} url={data?.feedback} />
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        showModal={showUserDeleteModal}
        setShowModal={setShowUserDeleteModal}
        onClick={handleFeedbackDelete}
      />
    </>
  );
};

export default RecentSingleFeedBack;
