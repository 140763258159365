/* eslint-disable import/no-extraneous-dependencies */
import { Icon } from "@iconify/react";
import "./radiobutton.scss";

const RadioButton = (props: any) => {
  const { label, checkedValue, onClick, tac } = props;
  return (
    <button type="button" className="custom__radio_button" onClick={onClick}>
      {!tac && (
        <div className="radio__icon">
          {checkedValue && <span className="tick" />}
        </div>
      )}

      {tac && (
        <div className={`radio__icon tac ${checkedValue && "active"}`}>
          {checkedValue && (
            <Icon
              icon="material-symbols:radio-button-unchecked"
              className="checked__icon_yes"
            />
          )}
        </div>
      )}
      {label}
    </button>
  );
};

export default RadioButton;
