import "./index.scss";
import { toHoursAndMinutes } from "../../../helpers/utils/dateTime";

function InterviewsAndWebinarsBox(props: any) {
  const { data, onClick } = props;
  return (
    <div
      className="iw-box"
      role="button"
      aria-hidden
      onClick={() => onClick(data)}
    >
      <div className="iw-img">
        <img src={data.pictures.base_link} alt="iw" />
      </div>
      <div className="iw-text">
        <div className="iw-title">{data.name}</div>
        <div className="iw-duration">
          Duration {toHoursAndMinutes(data.duration)}
        </div>
      </div>
    </div>
  );
}

export default InterviewsAndWebinarsBox;
