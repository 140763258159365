import { lazy } from "react";

// import OrginationDashboard from "";
import Groups from "../pages/Orgination/Groups/Groups";
import ViewGroup from "../pages/Orgination/Groups/ViewGroup";
import CreateNewGroup from "../pages/Orgination/Groups/CreateNewGroup";
import TeamMembers from "../pages/Orgination/Members/TeamMembers";
import InviteNewMembers from "../pages/Orgination/Members/InviteNewMembers";
import MembersDetails from "../pages/Orgination/Members/MembersDetails";
import AdminLearningPath from "../pages/Orgination/LearningPath/AdminLearningPath";
import AdminLearningPathDetail from "../pages/Orgination/LearningPath/AdminLearningPathDetail";
import ListAdminCourses from "../pages/Orgination/Courses/ListAdminCourses";
import AdminCoursDetails from "../pages/Orgination/Courses/AdminCoursDetails";
import MemberAssignment from "../pages/Orgination/Members/MemberAssignment";
import UpdateAssignment from "../pages/Orgination/ManageAssisment/UpdateAssignment";
import ManageAssignment from "../pages/Orgination/ManageAssisment/ManageAssignment";
import AudioPodcast from "../pages/UserDashboard/AudioPodcast/AudioPodcast";
import InterviewsAndWebinars from "../pages/UserDashboard/InterviewsAndWebinars/InterviewsAndWebinars";
import { ROUTES } from "../helpers/utils/Constants/route";

const OrginationDashboard = lazy(
  () => import("../pages/Orgination/Dashboard/OrginationDashboard")
);

const AllTenantRoute = [
  {
    id: 1,
    path: ROUTES.ORGANIZATION_DASHBOARD,
    component: OrginationDashboard,
  },
  { id: 2, path: ROUTES.GROUPS, component: Groups },
  { id: 3, path: ROUTES.VIEW_GROUPS, component: ViewGroup },
  { id: 4, path: ROUTES.CREATE_NEW_GROUPS, component: CreateNewGroup },
  { id: 5, path: ROUTES.EDIT_GROUPS, component: CreateNewGroup },
  { id: 6, path: ROUTES.MEMBERS, component: TeamMembers },
  { id: 7, path: ROUTES.INVITE_NEW_MEMBERS, component: InviteNewMembers },
  { id: 8, path: ROUTES.MEMBERS_DETAILS, component: MembersDetails },
  { id: 9, path: ROUTES.ADMIN_LEARNING_PATH, component: AdminLearningPath },
  { id: 10, path: ROUTES.ADMIN_COURSE_DETAIL, component: AdminCoursDetails },
  {
    id: 11,
    path: ROUTES.ADMIN_LEARNING_PATH_COURSE,
    component: AdminLearningPathDetail,
  },
  { id: 12, path: ROUTES.ADMIN_COURSES, component: ListAdminCourses },
  { id: 13, path: ROUTES.MEMBERS_ADD_ASSIGNMENT, component: MemberAssignment },
  {
    id: 14,
    path: ROUTES.UPDATE_ASSIGNMENT,
    component: UpdateAssignment,
  },
  {
    id: 15,
    path: ROUTES.MANAGE_ASSIGNMENT,
    component: ManageAssignment,
  },
  {
    id: 16,
    path: ROUTES.PODCASTS,
    component: AudioPodcast,
  },
  {
    id: 17,
    path: ROUTES.WEBINARS,
    component: InterviewsAndWebinars,
  },
];

export default AllTenantRoute;
