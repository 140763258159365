import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { searchGroups } from "../../../../store/features/Tenant/Orgination/Group/GroupSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";

function GroupHeader(props: any) {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchGroups(searchText));
  }, [searchText]);

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Groups</TableHeadingTitle>
      </div>

      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh" style={{ width: "130px" }}>
          <PrimaryButton
            text="Create a Group"
            size="sm"
            type="button"
            loading={false}
            onClick={() => navigate("/create-new-groups")}
          />
        </div>
      </div>
    </div>
  );
}

export default GroupHeader;
