import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getClassArchiveById,
  getClassArchives,
  deleteClassArchive,
  addNewClassArchive,
  updateOrder,
  UploadArchiveCSVFile,
} from "./ArchiveApi";

const initialState: any = {
  loading: {
    get_all: false,
    get_by_id: false,
    delete: false,
    post: false,
  },
  req_success: {
    get_all: false,
    get_by_id: false,
    delete: false,
    post: false,
  },
  current_page: 0,
  all_archives: [],
  current_archive: [],
  temp_archives: [],
  archives: [],
  isEdit: false,
  isChanged: false,
  showModal: false,
  order_loading: false,
  order_req_success: false,

  clean_upload_screen: false,
  uploaderLoading: false,
  uploaderSuccess: false,
  downloadLoading: false,
  downloadSuccess: false,
};

const ArchiveSlice = createSlice({
  name: "ArchiveSlice",
  initialState,
  reducers: {
    editArchiveInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.isEdit = true;
      state.current_archive = payload;
    },
    closeModal: (state: any) => {
      state.isEdit = false;
      state.showModal = false;
      state.current_archive = null;
    },
    setShowModal: (state: any) => {
      state.showModal = true;
    },
    changeTempOrder: (state: any, { payload }: PayloadAction<any>) => {
      state.temp_archives = state.all_archives;
      const a = state.all_archives[payload.sourceIndex];

      state.all_archives.splice(payload.sourceIndex, 1);

      state.all_archives.splice(payload.destinationIndex, 0, a);

      // state.isChanged = !state.isChanged;
    },
    cleanArchiveUploaderScreen: (state: any) => {
      state.clean_upload_screen = true;
    },
  },
  extraReducers: (builder) => {
    // GET All Archives
    builder.addCase(getClassArchives.pending, (state) => {
      state.loading.get_all = true;
      state.req_success.get_all = false;
    });
    builder.addCase(
      getClassArchives.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_all = false;
        state.req_success.get_all = true;
        state.all_archives = payload.data;
      }
    );
    builder.addCase(getClassArchives.rejected, (state) => {
      state.loading.get_all = false;
    });

    // GET a Class Archive by Id
    builder.addCase(getClassArchiveById.pending, (state) => {
      state.loading.get_by_id = true;
      state.req_success.get_by_id = false;
    });
    builder.addCase(
      getClassArchiveById.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_by_id = false;
        state.req_success.get_by_id = true;
        state.current_archive = payload.data;
      }
    );
    builder.addCase(getClassArchiveById.rejected, (state) => {
      state.loading.get_by_id = false;
    });

    // update order
    builder.addCase(updateOrder.pending, (state) => {
      state.order_loading = true;
      state.order_req_success = false;
    });
    builder.addCase(
      updateOrder.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.order_loading = false;
        state.order_req_success = true;
      }
    );
    builder.addCase(updateOrder.rejected, (state) => {
      state.order_loading = false;
      state.order_req_success = false;
    });

    // Delete a Archive
    builder.addCase(deleteClassArchive.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteClassArchive.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_archives = state.all_archives.filter(
          (pay: any) => pay.id !== payload?.id
        );
        state.isChanged = !state.isChanged;
        state.current_archive = null;
      }
    );
    builder.addCase(deleteClassArchive.rejected, (state) => {
      state.loading.delete = false;
    });

    // Create or update a Arcive
    builder.addCase(addNewClassArchive.pending, (state) => {
      state.loading.post = true;
      state.req_success.post = false;
    });
    builder.addCase(
      addNewClassArchive.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.post = false;
        state.req_success.post = true;
        state.isEdit = false;

        if (payload && !payload.id) {
          state.all_archives = [payload, ...state.all_archives];
        }

        if (payload && payload.id) {
          state.all_archives = state.all_archives.map((arch: any) => {
            if (arch.id === payload?.id) {
              return payload;
            }
            return arch;
          });
        }

        state.isChanged = !state.isChanged;
      }
    );
    builder.addCase(addNewClassArchive.rejected, (state) => {
      state.loading.post = false;
    });
    // ADD Archive CSV files
    builder.addCase(UploadArchiveCSVFile.pending, (state) => {
      state.uploaderLoading = true;
      state.uploaderSuccess = false;
      state.clean_upload_screen = false;
    });
    builder.addCase(
      UploadArchiveCSVFile.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.uploaderLoading = false;
        state.uploaderSuccess = true;
      }
    );
    builder.addCase(UploadArchiveCSVFile.rejected, (state) => {
      state.uploaderLoading = false;
    });
  },
});
export const { editArchiveInfo, closeModal, setShowModal, changeTempOrder ,cleanArchiveUploaderScreen} =
  ArchiveSlice.actions;
export default ArchiveSlice.reducer;
