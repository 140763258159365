import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const AddCategory = createAsyncThunk(
  "AddCategory",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/blog-categories`,
        data
      );

      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCategories = createAsyncThunk(
  "getCategories",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/blog-categories`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (
    { catId }: { catId: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(
        `${dynamic_url}/blog-categories/${catId}`
      );
      res.data.id = catId;
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (
    { catId, data }: { catId: any; data: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/blog-categories/${catId}`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
