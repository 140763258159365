/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addOrUpdateWeeklyLearning,
  getWeeklyLearningData,
  deleteWeeklyLearning,
} from "./WeeklyLearningApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    delete: false,
  },
  req_success: {
    add: false,
    get: false,
    delete: false,
  },
  edit: false,
  weeklyPlans: [],
  singleData: {},
  all_Plans: [],
};

const WeeklySlice = createSlice({
  name: "weeklyslice",
  initialState,
  reducers: {
    editWeekly: (state: any, { payload }: PayloadAction<any>) => {
      state.edit = true;
      state.singleData = payload;
    },
    closeWeeklyEditModal: (state: any) => {
      state.edit = false;
      state.singleData = {};
    },

    searchWeeklyPlans: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.weeklyPlans = state.all_Plans.filter((plan: any) =>
          plan.title.toLowerCase().includes(payload.toLowerCase())
        );
      } else {
        state.weeklyPlans = state.all_Plans;
      }
    },
  },
  extraReducers: (builder) => {
    // LOAD weeklyPlans
    builder.addCase(getWeeklyLearningData.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getWeeklyLearningData.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.weeklyPlans = payload.data;
        state.all_Plans = payload.data;
      }
    );
    builder.addCase(getWeeklyLearningData.rejected, (state) => {
      state.loading.get = false;
    });

    // ADD  or update weekly plan
    builder.addCase(addOrUpdateWeeklyLearning.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      addOrUpdateWeeklyLearning.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
        state.edit = false;
        if (payload?.idForUpdate > 0) {
          state.weeklyPlans = state.weeklyPlans.map((plan: any) => {
            if (plan.id === payload?.data.id) {
              return payload?.data;
            }
            return plan;
          });
        }

        if (payload?.idForUpdate === 0) {
          state.weeklyPlans = [...state.weeklyPlans, payload?.data];
        }

        state.all_Plans = state.weeklyPlans;
      }
    );
    builder.addCase(addOrUpdateWeeklyLearning.rejected, (state) => {
      state.loading.add = false;
    });

    //  DELETE WEEKLY PLANS
    builder.addCase(deleteWeeklyLearning.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteWeeklyLearning.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.weeklyPlans = state.weeklyPlans.filter(
          (week: any) => week.id !== payload?.id
        );
        state.all_Plans = state.weeklyPlans;
      }
    );
    builder.addCase(deleteWeeklyLearning.rejected, (state) => {
      state.loading.delete = false;
    });
  },
});

export const { editWeekly, closeWeeklyEditModal, searchWeeklyPlans } =
  WeeklySlice.actions;

export default WeeklySlice.reducer;
