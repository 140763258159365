import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import UserProfileHeader from "../../../components/AdminPanel/userProfile/userProfileHeader";
import UserProfileSubscription from "../../../components/AdminPanel/userProfile/userProfileSubscription";

const UserProfile = (props: any) => {
  return (
    <AdminPanelLayout>
      <UserProfileHeader />
      {/* <UserProfileSubscription /> */}
    </AdminPanelLayout>
  );
};

export default UserProfile;
