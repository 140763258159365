import { useEffect, useRef, useState } from "react";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import "./subscribers.scss";
import Table, { TableBody, TableHeading } from "../../../shared/Table/Table";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  downloadSubscriberCsvFile,
  getTacSubscribers,
  downloadSubscriberExcelFile,
} from "../../../store/features/AdminPanel/Subscribers/SubscriberApi";
import {
  searchSubscriberInTable,
  subscriberTablePaginate,
} from "../../../store/features/AdminPanel/Subscribers/SubscriberSlice";
import PaginateButton from "../../../shared/PaginateButton/PaginateButton";
import CheckBox from "../../../shared/Inputs/CheckBox/CheckBox";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import Empty from "../../../shared/Empty/Empty";

function TacSubscriber(props: any) {
  const [searchText, setSearchText] = useState("");
  const [showUserFileDownload, setShowUserFileDownload] = useState(false);
  const [csv, setCsv] = useState(false);
  const [xls, setXls] = useState(false);

  const downloadUserFilebtnRef: any = useRef(null);
  const downloadUserFileDropdownRef: any = useRef(null);

  const dispatch = useAppDispatch();

  const subscriberState: any = useAppSelector((store: RootStore) => {
    return store.SubscriberSlice;
  });

  useEffect(() => {
    dispatch(getTacSubscribers());
  }, []);

  useEffect(() => {
    dispatch(searchSubscriberInTable(searchText));
  }, [searchText]);
  const handlePagination = (num: number) => {
    dispatch(subscriberTablePaginate(num));
  };

  useEffect(() => {
    if (subscriberState.downloadSuccess) {
      setCsv(false);
      setXls(false);
      setShowUserFileDownload(false);
    }
  }, [subscriberState.downloadSuccess]);

  const downloadFile = () => {
    if (csv) {
      dispatch(downloadSubscriberCsvFile());
    }
    if (xls) {
      dispatch(downloadSubscriberExcelFile());
    }
  };

  const handleClickOutSide = (event: any) => {
    if (
      downloadUserFileDropdownRef.current &&
      !downloadUserFileDropdownRef.current.contains(event.target)
    ) {
      if (
        downloadUserFilebtnRef.current &&
        !downloadUserFilebtnRef.current.contains(event.target)
      ) {
        setShowUserFileDownload(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);

  return (
    <div>
      <BoxShadow>
        <div className="subscribers-bottom ">
          <div className="sh-table-top">
            <div className="left">Subscribers</div>
            <div className="right subscriber__table__head__right__section">
              <div className="searchbar__wrapper">
                {" "}
                <SearchBar value={searchText} setValue={setSearchText} />
              </div>
     
                  <div
                    className="download__user__file__btn__width__handle"
                    aria-hidden
                    onClick={() =>
                      setShowUserFileDownload(!showUserFileDownload)
                    }
                    ref={downloadUserFilebtnRef}
                  >
                    <button className="download__user__file__btn" type="button">
                      Download User File
                    </button>
                  </div>
                  {showUserFileDownload && (
                    <div
                      className="download__user__file__download__dropdown__wrapper"
                      ref={downloadUserFileDropdownRef}
                    >
                      <div className="download__user__file__download__dropdow">
                        <div className="checkbox__wrapper">
                          {" "}
                          <CheckBox
                            label="CSV"
                            tacCheckBoxvalue={csv}
                            onClick={() => setCsv(!csv)}
                          />
                        </div>
                        <div className="checkbox__wrapper">
                          <CheckBox
                            label="XLS"
                            tacCheckBoxvalue={xls}
                            onClick={() => setXls(!xls)}
                          />
                        </div>
                        <div className="download__btn__width">
                          <PrimaryButton
                            onClick={downloadFile}
                            text="Download"
                            loading={subscriberState.downloadLoading}
                          />
                        </div>
                      </div>
                    </div>
              )}
            </div>
          </div>
          <div className="subscriber__talbe__section">
            <Table
              heading={
                <TableHeading>
                  <th>User Id</th>
                  <th>Email Address</th>
                  <th className="">Subscribed At</th>
                  <th className="hide__text">.</th>
                  <th className="hide__text">.</th>
                </TableHeading>
              }
            >
              <TableBody>
                {!subscriberState.loading &&
                  subscriberState.req_success &&
                  subscriberState.subscribers.map((sub: any) => {
                    return (
                      <tr key={sub.id}>
                        <td className="subscriber_name">
                          {sub.id ?? "-"}
                        </td>
                        <td className="subscriber_name">{sub.email}</td>
                        <td className="">{sub.created_at}</td>
                        <td className="hide__text">.</td>
                        <td className="hide__text">.</td>
                      </tr>
                    );
                  })}
              </TableBody>
            </Table>
            {!subscriberState.loading &&
              subscriberState.subscribers.length === 0 && (
                <div className="mt-5 handle__empty__container__div">
                  <Empty emptyText="It seems empty." custombg={true} />
                </div>
              )}
          </div>
        </div>
      </BoxShadow>
      <div className="pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={subscriberState.all_subscribers.length}
        />
      </div>
    </div>
  );
}


export default TacSubscriber
