/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogOut, Moon, Sun, User } from "react-feather";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { ChangeThemeMode } from "../../../store/features/ThemeSlice";
import { logoutUser } from "../../../store/features/Auth/AuthApi";
import userImage from "../../../assets/icons/user.png";
import "./index.scss";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";
import Notification from "../../../shared/Notification/Notification";
import { roles } from "../../../helpers/utils/Constants/roles";
import ThemeSwitchingButton from "./ThemeSwitchingButton";

const AdminHeaderWithLogoOnly = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  useEffect(() => {
    if (authState.logout_success) {
      navigate("/");
    }
  }, [authState?.logout_success]);

  const handleLogout = () => {
    dispatch(logoutUser());
    // navigate("/");
  };

  return (
    <div className="only__logo__header__container">
      <div className="toggle__section">
        <img src={themeState?.configs?.sidebar_open_logo} alt="logo" />
      </div>
      <div className="right__section">
        {configState.configs?.membership_enabled === Domain.TAA && (
          <Notification />
        )}
        <ThemeSwitchingButton />
        <div className="avatar_image_div">
          <img
            src={authState.tac_user?.avatar_url ?? userImage}
            className="avatar_img"
            alt="."
            onClick={() => setShow(!show)}
          />
          {show && (
            <div className="dropdown">
              {authState.tac_user?.role !== roles.MENTOR && (
                <>
                  <div
                    className="drop_item"
                    role="button"
                    aria-hidden
                    onClick={() => navigate("/profile")}
                  >
                    <User className="drop_icon" />
                    <span>Profile</span>
                  </div>
                  <div className="divider_item" />
                </>
              )}
              <div
                className="drop_item"
                role="button"
                aria-hidden
                onClick={handleLogout}
              >
                <LogOut className="drop_icon" />
                <span>Logout</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminHeaderWithLogoOnly;
