import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "react-tabs/style/react-tabs.css";
import "./index.scss";
import InterviewComp from "../Interview/InterviewComp";
import InterviewAnswer from "../../AdminInterviewAnswer/InterviewAnswer/InterviewAnswer";
import InterviewQuestionsComp from "../InterviewQuestions/InterviewQuestionsComp";
import FaqHeading from "../../AdminInterviewAnswer/AdminInterviewAnswerFaq/FaqHeading/FaqHeading";
import QuestionAnswerFaqContent from "../../AdminInterviewAnswer/AdminInterviewAnswerFaq/FaqContent/QuestionAnswerFaqContent";
import RecentAnswer from "../RecentlyAnswered/RecentAnswer/RecentAnswer";
import RecentAnswerFaqHeading from "../RecentlyAnswered/RecentAnserFaq/RecentAnswerFaqHeading/RecentAnswerFaqHeading";
import RecentQuestionAnswerFaqContent from "../RecentlyAnswered/RecentAnserFaq/RecentAnswerFaqContent/RecentQuestionAnswerFaqContent";
import { ROUTES } from "../../../../helpers/utils/Constants/route";
import { TAGS } from "../../../../helpers/utils/Constants/tag";
import { clearRecentlyAnsweredDetails } from "../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.slice";
import { getRecentlyAnsweredDetails } from "../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.actions";
import { roles } from "../../../../helpers/utils/Constants/roles";

const AllInterviewTab = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [tabIndex, setTabIndex] = useState(0);

  const [openAddQuestionPage, setOpenAddQuestionPage] = useState(false);
  const [openAnswerPage, setOpenAnswerPage] = useState(false);
  const [OpenRecentAnserPage, setOpenRecentAnserPage] = useState(false);
  const [role, setRole] = useState({});
  const [selectedUser, setSelectedUser] = useState("");

  const InterviewAnswerState: any = useAppSelector((store: RootStore) => {
    return store.InterviewAnswerSlice;
  });

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const { update_delete: isInterviewDetailModify } = useAppSelector(
    (store: RootStore) => {
      return store.InterviewDetailsSlice;
    }
  );

  const dispatch = useAppDispatch();

  const backToHomeTab = () => {
    setOpenAddQuestionPage(false);
    setOpenAnswerPage(false);
    setOpenRecentAnserPage(false);
    setSelectedUser("");
    dispatch(clearRecentlyAnsweredDetails());
  };

  // track the tab changes
  useEffect(() => {
    if (params.tag === TAGS.RECENTLY_ANSWERED) {
      setTabIndex(0);
    }
    if (params.tag === TAGS.INTERVIEW_PREPRATION) {
      setTabIndex(1);
    }
    if (params.tag === TAGS.INTERVIEW_ANSWER) {
      setTabIndex(2);
    }
  }, []);

  useEffect(() => {
    // handle the notification click and open the selected tab
    if (params.tag === TAGS.INTERVIEW_ANSWER) {
      setTabIndex(2);
    }
  }, [params.tag]);

  useEffect(() => {
    // for role admin
    // change the url params according to tab clicks or changes
    if (authState.tac_user.role === roles.SYSTEM_ADMIN) {
      if (tabIndex === 0) {
        navigate(`${ROUTES.INTERVIEW}/${TAGS.RECENTLY_ANSWERED}`);
      }
      if (tabIndex === 1) {
        navigate(`${ROUTES.INTERVIEW}/${TAGS.INTERVIEW_PREPRATION}`);
      }
      if (tabIndex === 2) {
        navigate(`${ROUTES.INTERVIEW}/${TAGS.INTERVIEW_ANSWER}`);
      }
    }

    if (authState.tac_user.role === roles.MENTOR) {
      // for role mentor
      // change the url params according to tab clicks or changes
      if (tabIndex === 0) {
        navigate(`${ROUTES.MENTOR_INTERVIEW}/${TAGS.RECENTLY_ANSWERED}`);
      }
      if (tabIndex === 1) {
        navigate(`${ROUTES.MENTOR_INTERVIEW}/${TAGS.INTERVIEW_PREPRATION}`);
      }
      if (tabIndex === 2) {
        navigate(`${ROUTES.MENTOR_INTERVIEW}/${TAGS.INTERVIEW_ANSWER}`);
      }
    }
  }, [tabIndex]);

  useEffect(() => {
    if (InterviewAnswerState.notificationData) {
      setOpenAddQuestionPage(false);
      setOpenAnswerPage(true);
      setOpenRecentAnserPage(false);
      setRole({
        id: InterviewAnswerState.notificationData.interview_role_id,
        userId: InterviewAnswerState.notificationData.user_id,
      });
    }
  }, [InterviewAnswerState.notificationData]);

  useEffect(() => {
    if (selectedUser || isInterviewDetailModify) {
      dispatch(getRecentlyAnsweredDetails(selectedUser));
    }
  }, [selectedUser, isInterviewDetailModify]);

  return (
    <div className="interview__tabs__container mt-7">
      <Tabs
        className="tab__container"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="tab__list__section">
          <Tab className="single__tab ">Interview Preparation</Tab>
          <Tab className="single__tab mll ">Recently Answered</Tab>
          <Tab className="single__tab mll">Interview Answers</Tab>
        </TabList>

        <TabPanel>
          <div className="box__shadow">
            {!openAddQuestionPage && (
              <InterviewComp
                setOpenAddQuestionPage={setOpenAddQuestionPage}
                setRole={setRole}
                backToHomeTab={backToHomeTab}
              />
            )}
            {openAddQuestionPage && (
              <InterviewQuestionsComp
                role={role}
                backToHomeTab={backToHomeTab}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="box__shadow">
            {!OpenRecentAnserPage && (
              <RecentAnswer
                setOpenRecentAnserPage={setOpenRecentAnserPage}
                backToHomeTab={backToHomeTab}
                setSelectedUser={setSelectedUser}
              />
            )}
            {OpenRecentAnserPage && (
              <>
                <RecentAnswerFaqHeading backToHomeTab={backToHomeTab} />
                {/* <QuestionAnswerFaqContent role={role} /> */}
                <RecentQuestionAnswerFaqContent />
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="box__shadow">
            {" "}
            {!openAnswerPage && (
              <InterviewAnswer
                setRole={setRole}
                setOpenAnswerPage={setOpenAnswerPage}
                backToHomeTab={backToHomeTab}
              />
            )}
            {openAnswerPage && (
              <>
                <FaqHeading backToHomeTab={backToHomeTab} />
                <QuestionAnswerFaqContent role={role} />
              </>
            )}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default AllInterviewTab;
