/* eslint-disable guard-for-in */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { ChevronDown, ChevronLeft } from "react-feather";
import "react-accessible-accordion/dist/fancy-example.css";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import { Card } from "../../../../shared/Card/Card";
import { InputLabel } from "../../../../shared/Inputs/InputLabel/InputLabel";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import "./index.scss";
import Tags from "../../../../shared/Tags/Tags";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  getRemaningUsers,
  inviteMembers,
} from "../../../../store/features/Tenant/Orgination/Member/MemberApi";
import SelectGroup from "../SelectGroup/SelectGroup";
import AssignCourses from "../AssignCourses/AssignCourses";
import { intersect } from "../../../../helpers/utils/MathHelper";

const InviteMember = (props: any) => {
  const [openAccording, setOpenAccording] = useState(false);

  // logic for radio buttons
  const [singleDeadline, setSingleDeadline] = useState(false);
  const [individualDeadline, setIndividualDeadline] = useState(false);

  const [admin, setAdmin] = useState(false);
  const [teamMember, setTeamember] = useState(true);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const memberState: any = useAppSelector((store: RootStore) => {
    return store.MemberSlice;
  });
  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });

  const [emails, setEmails] = useState([]);
  const indvCourse: any = [];
  const indvCourseWithDeadline: any = [];
  const [checkedLearningPathId, setCheckedLearningPathId] = useState<any>([]);
  const [selectedGroupId, setSelectedGroupId] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [dublicateEmail, setDublicateEmail] = useState<any>({});

  const navigate = useNavigate();

  const activeAdmin = () => {
    if (!admin) {
      setAdmin(true);
      setTeamember(false);
    }
  };

  const activeTeam = () => {
    if (!teamMember) {
      setTeamember(true);
      setAdmin(false);
    }
  };
  const activesingleDeadline = () => {
    if (singleDeadline) {
      setSingleDeadline(false);
    } else {
      setSingleDeadline(true);
      setIndividualDeadline(false);
    }
  };
  const activeIndividualDeadline = () => {
    if (individualDeadline) {
      setIndividualDeadline(false);
    } else {
      setSingleDeadline(false);
      setIndividualDeadline(true);
    }
  };

  // logic end for radio buttons

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>();
  const onSubmit = (values: any) => {
    if (emails.length === 0) {
      setError("Email Field is Required");
      return;
    }
    const data: any = {
      emails: emails,
      role: admin ? "admin" : "student",
      message: values.message,
      groups: selectedGroupId,
    };

    if (teamMember) {
      if (
        checkedLearningPathId.length > 0 ||
        indvCourse.length > 0 ||
        indvCourseWithDeadline.length > 0 ||
        selectedCourses.length > 0
      ) {
        //
      } else {
        toast.error("Either Learning Path or Course is required");
        return;
      }
    }

    if (singleDeadline) {
      data.single_deadline = values.single_deadline;
    }
    if (!individualDeadline) {
      const obj: any = {};
      checkedLearningPathId.forEach((element: any) => {
        const arr: any = [];
        selectedCourses.forEach((course: any) => {
          if (course.learning_path_ids.includes(element)) {
            arr.push(course.id);
          }
        });
        obj[element] = arr;
      });
      data.learning_paths = obj;

      selectedCourses.forEach((course: any) => {
        const res = intersect(course.learning_path_ids, checkedLearningPathId);
        if (res.length === 0) {
          indvCourse.push(course.id);
        }
      });
      data.individual_courses = indvCourse;
    }

    if (individualDeadline) {
      const obj: any = [];
      checkedLearningPathId.forEach((element: any) => {
        const arr: any = [];
        selectedCourses.forEach((course: any) => {
          if (course.learning_path_ids.includes(element)) {
            arr.push(course.id);
          }
        });
        obj.push({ [element]: arr });
      });
      data.learning_path_with_deadlines = obj;

      const generateInsideData = (value: any, val: any) => {
        const keys: any = Object.keys(val);
        const insideData: any = {};
        val.map((elem: any, index: number) => {
          return (insideData[elem] = values[elem]);
        });

        return insideData;
      };

      const result = data.learning_path_with_deadlines.reduce(function (
        items: any,
        item: any
      ) {
        const fixed: any = {};
        const keys: any = Object.keys(item);

        keys.forEach(function (key: any) {
          return (fixed[key] = generateInsideData(item, item[key]));
        });
        items.push(fixed);
        return items;
      },
      []);
      data.learning_path_with_deadlines = Object.assign({}, ...result);

      selectedCourses.forEach((course: any) => {
        const res = intersect(course.learning_path_ids, checkedLearningPathId);
        if (res.length === 0) {
          for (const key in values) {
            if (key == course.id) {
              indvCourseWithDeadline.push({ [course.id]: values[key] });
            }
          }
        }
      });
      data.individual_course_deadlines = Object.assign(
        {},
        ...indvCourseWithDeadline
      );
    }

    // reset();

    dispatch(
      inviteMembers({ data: data, setDublicateEmail: setDublicateEmail })
    );
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    dispatch(getRemaningUsers());
  }, []);

  useEffect(() => {
    if (memberState.req_success.add) {
      reset();
      setEmails([]);
      setError("");
      setSingleDeadline(false);
      setIndividualDeadline(false);
      setAdmin(false);
      setTeamember(true);
      dispatch(getRemaningUsers());
    }
  }, [memberState.req_success.add]);

  return (
    <form
      className="invite__member__section"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <div className="navigation__section">
        <ChevronLeft
          className="back__button"
          onClick={() => navigate("/members")}
        />
        <span className="inivte__member__page__topography">
          Invite a new member
        </span>
      </div>
      <Card>
        <div className="card__input__sections">
          <span className="inivte__member__page__topography uppercase">
            Member information{" "}
            <span className="invited__member__text">
              {" "}
              ( You have {`${memberState.remaning_user}`} invitation remaining.
              )
            </span>
          </span>

          <div className="email__input__section">
            <InputLabel name="Email address" text="Email address" required />

            <Tags
              dataArray={emails}
              updateDataArray={setEmails}
              input
              error={error}
              setError={setError}
              Enablehover
            />
            {Object.keys(dublicateEmail).length !== 0 &&
              dublicateEmail.emails[0].map((error: any) => {
                return <span className="error__text">{error}</span>;
              })}
            <span className="helper__text">
              Hit ENTER to invite multiple members
            </span>
          </div>
          <div className="row">
            <div className="left__section">
              <span className="inivte__member__page__topography uppercase mb_2">
                user role & permissions
              </span>

              <InputLabel name="Select role" text="Select role" required />

              <button type="button" className="radio" onClick={activeAdmin}>
                <div className="radio__icon">
                  {admin && <span className="tick" />}
                </div>{" "}
                Admin
              </button>
              <span className="hint">
                Admin has full access to the account except for billing and
                removing members. Admins can create and manage users. Admins can
                assign courses and view team progress.
              </span>
              <button type="button" className="radio" onClick={activeTeam}>
                <div className="radio__icon">
                  {teamMember && <span className="tick">.</span>}
                </div>{" "}
                Team member
              </button>
              <span className="hint">
                Team members have full access to course content and their
                individual course statistics. Team members cannot create users
                or view team metrics.
              </span>
            </div>
            <div className="right__section">
              {teamMember && (
                <>
                  {" "}
                  <span className="inivte__member__page__topography uppercase mb_2">
                    add to group
                  </span>
                  <SelectGroup
                    title="Group"
                    groupState={groupState}
                    setSelectedGroupId={setSelectedGroupId}
                    memberState={memberState}
                  />
                </>
              )}

              <span className="inivte__member__page__topography uppercase mb_2 mt_2">
                personalize your invite
              </span>
              <TextArea
                name="message"
                label="Message"
                type="textarea"
                half
                register={register}
                placeholder="Write a message..."
              />
            </div>
          </div>

          {teamMember && (
            <div className="footer__section">
              <div className="assignment__section">
                <div className="according__header">
                  {" "}
                  <span className="inivte__member__page__topography uppercase mb-2">
                    Add assignment
                  </span>
                  <div
                    className="according__spinner"
                    onClick={() => setOpenAccording(!openAccording)}
                  >
                    <ChevronDown
                      className={`down_spinner ${
                        openAccording ? "active" : ""
                      }`}
                    />
                  </div>
                </div>
                <div
                  className={`member__according__content ${
                    openAccording ? "active" : ""
                  }`}
                >
                  <div className="mt-2">
                    <AssignCourses
                      checkedLearningPathId={checkedLearningPathId}
                      setCheckedLearningPathId={setCheckedLearningPathId}
                      groupState={groupState}
                      setSelectedCourses={setSelectedCourses}
                      notitle
                      memberState={memberState}
                    />
                  </div>
                </div>
              </div>

              <div className="date__selection__wrapper">
                <span className="inivte__member__page__topography uppercase mb-3">
                  set deadline
                </span>
                <div className="single__deadline__container">
                  <button
                    type="button"
                    className="radio"
                    onClick={activesingleDeadline}
                  >
                    <div className="radio__icon">
                      {singleDeadline && <span className="tick">.</span>}
                    </div>{" "}
                    Set a single deadline
                  </button>
                  {singleDeadline && (
                    <div className="single__date__wrapper">
                      <div className="date__section">
                        <div className="calendar__width">
                          <TextField
                            type="date"
                            name="single_deadline"
                            placeholder="mm/dd/yyyy"
                            register={register}
                            min={new Date().toISOString().split("T")[0]}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="multiple__deadline__container">
                  <button
                    type="button"
                    className="radio"
                    onClick={activeIndividualDeadline}
                  >
                    <div className="radio__icon">
                      {individualDeadline && <span className="tick">.</span>}
                    </div>{" "}
                    Set individual deadlines
                  </button>
                  {individualDeadline && (
                    <div className="multiple__date__wrapper">
                      {selectedCourses.length > 0 &&
                        selectedCourses.map((course: any) => {
                          return (
                            <div key={course.id.toString()}>
                              <div className="date__section">
                                <span className="date__label">
                                  {" "}
                                  {course.name} (Course)
                                </span>
                                <div className="calendar__width">
                                  <TextField
                                    type="date"
                                    placeholder="mm/dd/yyyy"
                                    name={course.id.toString()}
                                    register={register}
                                    min={new Date().toISOString().split("T")[0]}
                                    // error={errors?.path.name}
                                  />
                                </div>
                              </div>
                              <div className="date__divider" />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="button__top__divider" />
          <div className="button__section">
            <PrimaryButton
              text="Send Invitation"
              type="submit"
              loading={memberState.loading.add}
            />
          </div>
        </div>
      </Card>
    </form>
  );
};

export default InviteMember;
