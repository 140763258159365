/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserRecentProgress, getUserCourseProgress } from "./MyProgressApi";

const initialState: any = {
  recent_progress_loading: false,
  course_progress_loading: false,
  recent_progress_success: false,
  course_progress_success: false,

  recent_progress: [],
  recent_progres: false,
  courses_progress: [],
};

const LearningPathSlice = createSlice({
  name: "learningPath",
  initialState,
  reducers: {
    // },
  },
  extraReducers: (builder) => {
    // GET ALL COURSES
    builder.addCase(getUserRecentProgress.pending, (state) => {
      state.recent_progress_loading = true;
      state.recent_progress_success = false;
    });
    builder.addCase(
      getUserRecentProgress.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.recent_progress_loading = false;
        state.recent_progress_success = true;
        state.courses_progress = payload?.data?.course_progress;
        state.recent_progress = payload?.data?.recent_courses;
        if (state.recent_progress.length > 0) {
          state.recent_progres = true;
        }
      },
    );
    builder.addCase(getUserRecentProgress.rejected, (state) => {
      state.recent_progress_loading = false;
    });

    // GET COURSES FROM BUNDLE
    builder.addCase(getUserCourseProgress.pending, (state) => {
      state.course_progress_loading = true;
      state.course_progress_success = false;
    });
    builder.addCase(
      getUserCourseProgress.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.course_progress_loading = false;
        state.course_progress_success = true;
        state.courses_progress = payload?.data;
      },
    );
    builder.addCase(getUserCourseProgress.rejected, (state) => {
      state.course_progress_loading = false;
    });
  },
});

// export const { setRecentSignupEmail, invokeLogout } = LearningPathSlice.actions;

export default LearningPathSlice.reducer;
