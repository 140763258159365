import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  enrollToThinkificCourse,
  enrollToThinkificCourse2,
  enrollToThinkificCourse3,
  loginToThinkificAndRedirect,
} from "./ThikificApi";

const initialState: any = {
  loading: false,
  req_success: false,
  loading2: false,
  loading3: false,
  req_succes2: false,
  req_success3: false,
  login_req_success: false,
};

const ThinkificSlice = createSlice({
  name: "ThinkificSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ENROLL TO COURSES
    builder.addCase(enrollToThinkificCourse.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      enrollToThinkificCourse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
      }
    );
    builder.addCase(enrollToThinkificCourse.rejected, (state) => {
      state.loading = false;
    });

    // ENROLL TO COURSES
    builder.addCase(enrollToThinkificCourse2.pending, (state) => {
      state.loading2 = true;
      state.req_success2 = false;
    });
    builder.addCase(
      enrollToThinkificCourse2.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading2 = false;
        state.req_success2 = true;
      }
    );
    builder.addCase(enrollToThinkificCourse2.rejected, (state) => {
      state.loading2 = false;
    });

    // ENROLL TO COURSES
    builder.addCase(enrollToThinkificCourse3.pending, (state) => {
      state.loading3 = true;
      state.req_success2 = false;
    });
    builder.addCase(
      enrollToThinkificCourse3.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading3 = false;
        state.req_success3 = true;
      }
    );
    builder.addCase(enrollToThinkificCourse3.rejected, (state) => {
      state.loading3 = false;
    });
    builder.addCase(loginToThinkificAndRedirect.pending, (state) => {
      state.loading = true;
      state.loading2 = true;
      state.login_req_success = false;
    });
    builder.addCase(
      loginToThinkificAndRedirect.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.loading2 = false;
        state.login_req_success = true;
      }
    );
    builder.addCase(loginToThinkificAndRedirect.rejected, (state) => {
      state.loading = false;
      state.loading2 = false;
      state.login_req_success = false;
    });
  },
});

export default ThinkificSlice.reducer;
