/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { ChevronLeft, Download, Eye, File, FolderMinus } from "react-feather";
import Empty from "../../../../shared/Empty/Empty";
import {
  getResourcesForUser,
  getDataInsideFolder,
} from "../../../../store/features/UserDashboard/Resources/UserResourceApi";

import {
  setActiveSlice,
  setCleanScreen,
  setNestedFolderId,
  showInfoSlice,
} from "../../../../store/features/UserDashboard/Resources/UserResourceSlice";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import UserResourceActionButton from "../Action/UserResourceActionButton";
import pdfIcon from "../../../../assets/resource_icon/pdf.svg";
import folderIcon from "../../../../assets/resource_icon/folder.svg";
import docxIcon from "../../../../assets/resource_icon/docx.svg";
import imgIcon from "../../../../assets/resource_icon/img.svg";
import rarIcon from "../../../../assets/resource_icon/rar.svg";
import searchEmptyDarkImg from "../../../../assets/images/searchDarkEmptyImg.svg";
import searchEmptyLightImg from "../../../../assets/images/searchLightEmptyImg.svg";

import whiteEmptyIcon from "../../../../assets/images/empty-light.svg";
import blackEmptyIcon from "../../../../assets/images/empty-black.svg";

import "./index.scss";
import CustomEmptyComp from "../../../../shared/Empty/component/CustomEmptyComp";

const ListData = (props: any) => {
  const { setFile, setShowPreviewModal } = props;
  const dispatch = useAppDispatch();
  const [nestedFolder, setNestedFolder] = useState("");
  const [folderStack, setFolderStack] = useState<any>([]);
  const resourceState: any = useAppSelector((store: RootStore) => {
    return store.UserResourceSlice;
  });

  const showInfo = (data: any) => {
    dispatch(showInfoSlice(data));
  };
  const setActiveId = (id: number) => {
    dispatch(setActiveSlice(id));
  };
  const getDataInsideFolder2 = (data: any) => {
    if (data.resource_type !== "file") {
      dispatch(setCleanScreen());
      setFolderStack([
        ...folderStack,
        { id: data.id, name: data.resource_name },
      ]);
      setNestedFolder(data.resource_name);
      dispatch(setNestedFolderId(data.id));
      dispatch(getDataInsideFolder({ id: data.id }));
    }
  };
  const onButtonClick = (data: any) => {
    fetch(data?.file_path).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${data.resource_name}.${data.resource_extension}`;
        alink.click();
      });
    });
  };
  const handleBackNavigation = () => {
    dispatch(setCleanScreen());
    folderStack.pop();

    if (folderStack.length > 0) {
      const openFolder = folderStack.slice(-1);
      setNestedFolder(openFolder[0].name);
      dispatch(getDataInsideFolder({ id: openFolder[0].id }));
    } else {
      setNestedFolder("");
      dispatch(getResourcesForUser());
    }
  };
  const openPdfInNewTab = (data: any) => {
    if (
      data.resource_extension.toLowerCase() === "rar" ||
      data.resource_extension.toLowerCase() === "zip"
    ) {
      return;
    }
    if (
      data.resource_extension.toLowerCase() !== "docx" &&
      data.resource_extension.toLowerCase() !== "doc"
    ) {
      dispatch(setActiveSlice(0));

      window.open(data.file_path, "_blank");
    }
    if (
      data.resource_extension.toLowerCase() === "docx" ||
      data.resource_extension.toLowerCase() === "doc"
    ) {
      dispatch(setActiveSlice(0));

      window.open(
        `https://view.officeapps.live.com/op/embed.aspx?src=${data?.file_path}`,
        "_blank"
      );
    }
  };

  return (
    <div className="user_resource_list_file_and_folder_section">
      {resourceState.active_id > 0 && (
        <div
          className="whole__window_cover"
          onClick={() => dispatch(setActiveSlice(0))}
        />
      )}
      {nestedFolder && (
        <div className="nested_folder_name_section">
          <ChevronLeft
            className="nested_folder_back_icon"
            onClick={handleBackNavigation}
          />
          <span className="nested_folder_name">{nestedFolder}</span>
        </div>
      )}
      <table className="user_list_file_table_section">
        <thead>
          <tr>
            <th>Name</th>
            <th>No. of Files</th>
            <th>File Size</th>
            <th>Last Modified</th>
            <th className="hide_text_action">.</th>
          </tr>
        </thead>
        <tbody>
          {!resourceState.loading &&
            resourceState.req_success &&
            resourceState.first_page_data.map((data: any) => {
              return (
                <tr key={data.id}>
                  <td>
                    <div className="text_and_icons">
                      {data.resource_extension === "pdf" && (
                        <img
                          className="talbe_icons"
                          src={pdfIcon}
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                          alt="pdf"
                        />
                      )}
                      {(data.resource_extension?.toLowerCase() === "docx" ||
                        data.resource_extension?.toLowerCase() === "doc") && (
                        <img
                          className="talbe_icons"
                          src={docxIcon}
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                          alt="pdf"
                        />
                      )}
                      {(data.resource_extension?.toLowerCase() === "rar" ||
                        data.resource_extension?.toLowerCase() === "zip") && (
                        <img
                          className="talbe_icons"
                          src={rarIcon}
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                          alt="pdf"
                        />
                      )}
                      {(data.resource_extension?.toLowerCase() === "jpg" ||
                        data.resource_extension?.toLowerCase() === "jpeg" ||
                        data.resource_extension?.toLowerCase() === "png" ||
                        data.resource_extension?.toLowerCase() === "svg") && (
                        <img
                          className="talbe_icons"
                          src={imgIcon}
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                          alt="img"
                        />
                      )}
                      {data.resource_type !== "file" && (
                        <img
                          src={folderIcon}
                          className="talbe_icons"
                          onDoubleClick={() => getDataInsideFolder2(data)}
                          alt="folder"
                        />
                      )}

                      {data.resource_type !== "file" && (
                        <span
                          className="resource__file__name"
                          onDoubleClick={() => getDataInsideFolder2(data)}
                        >
                          {data.resource_name}
                        </span>
                      )}

                      {data.resource_type === "file" && (
                        <span
                          className="resource__file__name"
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                        >
                          {`${data.resource_name}.${data.resource_extension}`}
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    {data.resource_type === "file" && "-"}
                    {data.resource_type !== "file" && data.inside_files_count}
                  </td>

                  <td>{data.files_size}</td>
                  <td>{data.last_modified}</td>

                  <td className="user_resoure_action_td">
                    {data.resource_type === "file" && (
                      <>
                        {/* {data.resource_extension.toLowerCase() !== "rar" &&
                          data.resource_extension.toLowerCase() !== "zip" && (
                            <div className="eye_parent_section">
                              <Eye
                                className="user_resoure_icon"
                                onClick={(e) => openPdfInNewTab(data)}
                              />
                              <div className="eye_tool_tip">Preview</div>
                            </div>
                          )}
                        {(data.resource_extension.toLowerCase() === "rar" ||
                          data.resource_extension.toLowerCase() === "zip") && (
                          <Eye className="user_resoure_icon_tp" />
                        )} */}
                        {/* <div className="eye_parent_section">
                          <div className="eye_tool_tip">Preview</div>
                          <div className="preview_tool">.</div>
                        </div> */}
                        <div className="parent_of_tool_action">
                          <div className="tool_section_for_hover">
                            <Eye
                              id="eye"
                              className="user_resoure_icon eye_icon_hover tac_eye_hover"
                              onClick={(e) => openPdfInNewTab(data)}
                            />

                            <span className="preview_tool_tip">Preview</span>
                            <div className="preview_arrow_tool_tip" />
                          </div>
                          <div className="tool_section_for_hover">
                            <Download
                              className="user_resoure_icon ml download_icon_hover tac_download_hover"
                              onClick={() => onButtonClick(data)}
                            />
                            <span className="download_tool_tip">Download</span>
                            <div className="download_arrow_tool_tip" />
                          </div>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {resourceState.req_success && (
        <CustomEmptyComp
          search={
            resourceState.searchText.length > 1 &&
            resourceState.first_page_data.length === 0
          }
          empty={resourceState.first_page_data.length === 0}
          tac
        />
      )}
    </div>
  );
};

export default ListData;
