import { useEffect } from "react";
import { getLearningPathForStudent } from "../../../store/features/Tenant/User/LearningPath/LearningPathApi";
import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../store/store";
import TenantLoadingScreen from "../../Auth/TenantLoading/TenantLoadingScreen";
import Bundle from "./Bundle";
import "./index.scss";

const OrgUserLearningPath = () => {
  const dispatch = useAppDispatch();

  const learningPaths: any = useAppSelector((store: RootStore) => {
    return store.TenantUserLearningPathSlice;
  });

  useEffect(() => {
    dispatch(getLearningPathForStudent());
  }, []);
  return (
    <div>
      {!learningPaths.loading && learningPaths.req_success ? (
        <div>
          {learningPaths && learningPaths.all_learningPaths.length === 0 && (
            <div className="empty__div">
              <span className="empty__text"> Path not assigned.</span>
            </div>
          )}
          <div className="OrgUserLearningPath__wrapper">
            {learningPaths &&
              learningPaths.all_learningPaths &&
              learningPaths.all_learningPaths.map((path: any, id: number) => {
                return <Bundle key={path.id} path={path} />;
              })}
          </div>
        </div>
      ) : (
        <TenantLoadingScreen message="" />
      )}
    </div>
  );
};

export default OrgUserLearningPath;
