/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
/* eslint-disable-next-line no-plusplus */
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Check, X } from "react-feather";

import SearchBar from "../../../shared/SearchBar/SearchBar";
import {
  getAllPremiumCourses,
  getAllcourses,
  getLearningPathForFilter,
} from "../../../store/features/UserDashboard/Courses/CoursesApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import Course from "../Course/Course";
import CourseFilter from "../CourseFilter/CourseFilter";
import "./listCourses.scss";
import { searchCoursesByName } from "../../../store/features/UserDashboard/Courses/CoursesSlice";
import tickIcon from "../../../assets/icons/tickIcon.svg";
import DropDownMultiple from "../../../shared/DropDownMultiple/DropDownMultiple";
import CustomEmptyComp from "../../../shared/Empty/component/CustomEmptyComp";
import lightInfoImg from "../../../assets/icons/inform-light.svg";
import darkInfoImg from "../../../assets/icons/inform-dark.svg";
import PremiumCourseWarning from "./PremiumCourseWarning";

const ListAllCourses = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isCertified, setIsCertified] = useState(false);
  const coursesState: any = useAppSelector((store: RootStore) => {
    return store.CoursesSlice;
  });

  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname.includes("/all-premium-courses")) {
      setIsCertified(true);
      dispatch(getAllPremiumCourses("certified"));
    } else {
      setIsCertified(false);
      dispatch(getAllcourses());
    }

    return () => {
      // clear all states
      setFilterActive(false);
      setFilteredJson([]);
      setFilteredCourseId([]);
      setStatus([]);
      setDuration([]);
      setBundles([]);
      setFirstFilter(false);
      setSecondFilter(false);
      setThirdFilter(false);
    };
  }, [pathname]);
  useEffect(() => {
    dispatch(getLearningPathForFilter());
  }, []);
  const handleClick = (id: number) => {
    navigate(
      `/view-course/${id}?from=${
        isCertified ? "certified" : "all"
      }`
    );
  };

  // _____________________________________________________________________

  type Number = number;
  const [coursesStateSave, setCoursesStateSave] = useState(coursesState);
  const [filterActive, setFilterActive] = useState(false);
  const [filteredJson, setFilteredJson] = useState<any>([]);
  const [filteredCourseId, setFilteredCourseId] = useState<Number[]>([]);
  const [status, setStatus] = useState<Number[]>([]);
  const [duration, setDuration] = useState<Number[]>([]);
  const [bundles, setBundles] = useState<Number[]>([]);

  useEffect(() => {
    const temp = [];
    const courses = isCertified
      ? coursesState.premium_courses.courses
      : coursesState.courses;
    for (let index = 0; index < courses.length; index++) {
      for (let j = 0; j < filteredCourseId.length; j++) {
        if (courses[index].course_id === filteredCourseId[j]) {
          temp.push(courses[index]);
        }
      }
    }
    setFilteredJson([...temp]);
  }, [filteredCourseId]);

  useEffect(() => {
    let temp = [];
    let durationTemp: number[] = [];
    const statusTemp = [];
    const courses = isCertified
      ? coursesState.premium_courses.courses
      : coursesState.courses;

    if (bundles.length === 0 && status.length === 0 && duration.length === 0) {
      setFilterActive(false);
      return;
    }
    setFilterActive(true);

    // checking if bundles are selected
    if (bundles.length) {
      if (coursesState.bundles.length) {
        for (let i = 0; i < coursesState.bundles.length; i++) {
          for (let j = 0; j < bundles.length; j++)
            if (coursesState.bundles[i].path_id === bundles[j]) {
              for (
                let k = 0;
                k < coursesState.bundles[j].path_courses.length;
                k++
              ) {
                temp.push(coursesState?.bundles[i]?.path_courses[k]?.course_id);
              }
            }
        }
      }
      // removing duplicate data here
      temp = temp.filter((elem: number, index: number, self: any) => {
        return index === self.indexOf(elem);
      });
    } else {
      for (let i = 0; i < courses.length; i++) {
        temp.push(courses[i].course_id);
      }
    }

    // checking if status are selected
    if (status.length) {
      const statusTemp = [];
      for (let i = 0; i < status.length; i++) {
        for (let j = 0; j < courses.length; j++) {
          if (status[i] === 0 && courses[j].percentage_completed === 0) {
            statusTemp.push(courses[j].course_id);
          }
          if (
            status[i] === 1 &&
            courses[j].percentage_completed > 0 &&
            courses[j].percentage_completed < 100
          ) {
            statusTemp.push(courses[j].course_id);
          }
          if (status[i] === 2 && courses[j].percentage_completed === 100) {
            statusTemp.push(courses[j].course_id);
          }
        }
      }
      const tempForRemovingDuplicate = temp;
      temp = [];
      for (let i = 0; i < tempForRemovingDuplicate.length; i++) {
        for (let j = 0; j < statusTemp.length; j++) {
          if (tempForRemovingDuplicate[i] === statusTemp[j]) {
            temp.push(statusTemp[j]);
          }
        }
      }
    }

    // checking if duration are selected
    if (duration.length) {
      for (let i = 0; i < duration.length; i++) {
        if (duration[i] === 0) {
          // for 1-2 hours
          for (let j = 0; j < courses.length; j++) {
            for (let k = 0; k < temp.length; k++) {
              if (
                Number(courses[j].duration) >= 0 &&
                Number(courses[j].duration) < 2
              )
                if (courses[j].course_id === temp[k]) {
                  durationTemp.push(courses[j].course_id);
                }
            }
          }
        } else if (duration[i] === 1) {
          // for 2-4 hours
          for (let j = 0; j < courses.length; j++) {
            for (let k = 0; k < temp.length; k++) {
              if (
                Number(courses[j].duration) >= 2 &&
                Number(courses[j].duration) < 4
              )
                if (courses[j].course_id === temp[k]) {
                  durationTemp.push(courses[j].course_id);
                }
            }
          }
        } else if (duration[i] === 2) {
          // for 4+hours
          for (let j = 0; j < courses.length; j++) {
            for (let k = 0; k < temp.length; k++) {
              if (Number(courses[j].duration) >= 4)
                if (courses[j].course_id === temp[k]) {
                  durationTemp.push(courses[j].course_id);
                }
            }
          }
        }
      }
    } else {
      durationTemp = temp;
    }

    setFilteredCourseId([...durationTemp]);
  }, [duration, bundles, status]);

  const handleStatusChange = (e: any) => {
    const temp = status;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setStatus([...temp]);
  };

  const handleDurationChange = (e: any) => {
    const temp = duration;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setDuration([...temp]);
  };

  const handleBundleClick = (e: number) => {
    const temp = bundles;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setBundles([...temp]);
  };

  const resetFilter = () => {
    setStatus(() => []);
    setBundles(() => []);
    setDuration(() => []);
  };

  const [searchText, setSearchText] = useState("");
  const [firstFilter, setFirstFilter] = useState(false);
  const [secondFilter, setSecondFilter] = useState(false);
  const [thirdFilter, setThirdFilter] = useState(false);
  const [showInfoWarning, setShowInfoWarning] = useState(true);

  useEffect(() => {
    dispatch(searchCoursesByName(searchText));
  }, [searchText, isCertified]);
  const closeOther = (index: number) => {
    if (index === 0) {
      setFirstFilter(true);
      setSecondFilter(false);
      setThirdFilter(false);
    } else if (index === 1) {
      setFirstFilter(false);
      setSecondFilter(true);
      setThirdFilter(false);
    } else {
      setFirstFilter(false);
      setSecondFilter(false);
      setThirdFilter(true);
    }
  };
  return (
    <div className="mt-7">
      <div className="filter__section">
        <div className="CourseFilter handle__res__course">
          <div className="left__section">
            <span className="filter__tag">Filter By</span>

            <div className="dropdown-container">
              <DropDownMultiple
                title="Status"
                index={0}
                closeOther={closeOther}
                isOpen={firstFilter}
              >
                <button
                  type="button"
                  className="ddc-options"
                  onClick={() => handleStatusChange(0)}
                >
                  <div className="checkbox-div">
                    {status.indexOf(0) !== -1 && (
                      // <img src={tickIcon} alt="tick icon" />
                      <Check className="tick__icon" />
                    )}
                  </div>
                  Not Started
                </button>
                <button
                  type="button"
                  className="ddc-options"
                  onClick={() => handleStatusChange(1)}
                >
                  <div className="checkbox-div">
                    {status.indexOf(1) !== -1 && (
                      // <img src={tickIcon} alt="tick icon" />
                      <Check className="tick__icon" />
                    )}
                  </div>
                  In Progress
                </button>
                <button
                  type="button"
                  className="ddc-options"
                  onClick={() => handleStatusChange(2)}
                >
                  <div className="checkbox-div">
                    {status.indexOf(2) !== -1 && (
                      // <img src={tickIcon} alt="tick icon" />
                      <Check className="tick__icon" />
                    )}
                  </div>
                  Completed
                </button>
              </DropDownMultiple>

              <DropDownMultiple
                title="Learning Path"
                index={1}
                closeOther={closeOther}
                isOpen={secondFilter}
              >
                {!coursesState.bundle_course_loading &&
                  coursesState.bundles.map((bundle: any, index: number) => {
                    return (
                      <button
                        key={bundle.id}
                        type="button"
                        className="ddc-options handle__dropdown__options__width"
                        onClick={() => handleBundleClick(bundle.path_id)}
                      >
                        <div className="checkbox__div__wrapper">
                          <div className="checkbox-div">
                            {bundles.indexOf(bundle.path_id) !== -1 && (
                              // <img src={tickIcon} alt="tick icon" />
                              <Check className="tick__icon" />
                            )}
                          </div>
                        </div>
                        {bundle.name}
                      </button>
                    );
                  })}
              </DropDownMultiple>

              <DropDownMultiple
                title="Course Hours"
                index={2}
                closeOther={closeOther}
                isOpen={thirdFilter}
              >
                <button
                  type="button"
                  className="ddc-options"
                  onClick={() => handleDurationChange(0)}
                >
                  <div className="checkbox-div">
                    {duration.indexOf(0) !== -1 && (
                      // <img src={tickIcon} alt="tick icon" />
                      <Check className="tick__icon" />
                    )}
                  </div>
                  0-2 Hours
                </button>
                <button
                  type="button"
                  className="ddc-options"
                  onClick={() => handleDurationChange(1)}
                >
                  <div className="checkbox-div">
                    {duration.indexOf(1) !== -1 && (
                      // <img src={tickIcon} alt="tick icon" />
                      <Check className="tick__icon" />
                    )}
                  </div>
                  2-4 Hours
                </button>
                <button
                  type="button"
                  className="ddc-options"
                  onClick={() => handleDurationChange(2)}
                >
                  <div className="checkbox-div">
                    {duration.indexOf(2) !== -1 && (
                      // <img src={tickIcon} alt="tick icon" />
                      <Check className="tick__icon" />
                    )}
                  </div>
                  4+ Hours
                </button>
              </DropDownMultiple>
            </div>

            <button className="reset__tag" onClick={resetFilter} type="button">
              Reset
            </button>
          </div>
          <div className="right__section search__right_section">
            <div className="search_filter">
              <SearchBar value={searchText} setValue={setSearchText} />
            </div>
          </div>
        </div>
      </div>
      {showInfoWarning && isCertified && (
        <PremiumCourseWarning setShowInfoWarning={setShowInfoWarning} />
      )}

      <div className="list__all__courses">
        {!isCertified &&
          !filterActive &&
          !coursesState?.loading &&
          coursesState?.courses.map((course: any) => {
            return (
              <Course
                key={course.id}
                course={course}
                onClick={() =>
                  handleClick(course.course_id)
                }
              />
              // <p>FILTER OFF</p>
            );
          })}

        {isCertified &&
          !filterActive &&
          !coursesState?.premium_courses.loading &&
          coursesState?.premium_courses.courses.map((course: any) => {
            return (
              <Course
                key={course.id}
                course={course}
                onClick={() =>
                  handleClick(course.course_id)
                }
              />
              // <p>FILTER OFF</p>
            );
          })}

        {filterActive &&
          filteredJson.map((e: any) => {
            return (
              <Course
                key={e.id}
                course={e}
                onClick={() => handleClick(e.course_id)}
              />
            );
          })}

        {((!isCertified && coursesState?.loading) ||
          (isCertified && coursesState.premium_courses.loading)) && (
          <>
            <div className="skeleton-filter-box">
              <div className="skeleton-img skeleton " />
              <div className="skeleton-txt">
                <div className="skeleton-title skeleton" />
                <div className="skeleton-duration skeleton" />
              </div>
            </div>
            {[1, 2, 3, 4].map((e: any) => {
              return (
                <div className="skeleton-filter-box" key={e}>
                  <div className="skeleton-img skeleton " />
                  <div className="skeleton-txt">
                    <div className="skeleton-title skeleton" />
                    <div className="skeleton-duration skeleton" />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
      {((!isCertified && coursesState.req_success) ||
        (isCertified && coursesState.premium_courses.req_success)) && (
        <div className="courses_empty_screen_wrapper">
          <CustomEmptyComp
            search={
              (searchText.length > 1 &&
                (!isCertified
                  ? coursesState.courses.length === 0
                  : coursesState.premium_courses.courses.length === 0)) ||
              (filterActive && filteredJson.length === 0)
            }
            empty={
              !filterActive &&
              (!isCertified
                ? coursesState.courses.length === 0
                : coursesState.premium_courses.courses.length === 0)
            }
            tac
          />
        </div>
      )}
    </div>
  );
};

export default ListAllCourses;
