import React, { useEffect, useRef, useState } from "react";
import "./chatbotsection.scss";
import ChatboxInputMessage from "./ChatbotInputMessage/ChatboxInputMessage";
import ChatboxQuestionSidebarBox from "./ChatbotQuestionBox/ChatboxQuestionSidebarBox";
import ChatbotHeader from "./ChatbotHeader/ChatbotHeader";
import MobileChatQuestionSidebar from "./ChatbotQuestionBox/MobileChatQuestionSidebar";
import ChatbotConversation from "./ChatbotConversation/ChatbotConversation";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  clearConversation,
  fetchConversation,
  getRecentSearch,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";

const ChatBotSection = (props: any) => {
  const { setShowChatBox, chatBotIconRef } = props;
  const chatbotSection: any = useRef(null);
  const dispatch = useAppDispatch();

  const [showChatQuestionSlider, setShowQuestionChatSlider] = useState(false);

  const handleClickOutSide = (event: any) => {
    if (
      chatBotIconRef.current &&
      !chatBotIconRef.current.contains(event.target)
    ) {
      if (
        chatbotSection.current &&
        !chatbotSection.current.contains(event.target)
      ) {
        setShowChatBox(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);

  useEffect(() => {
    dispatch(getRecentSearch());
  }, []);

  return (
    <div className="chatbot__section__wrapper">
      <div className="chatbot__section__container">
        <div ref={chatbotSection} className="chatbot__section__div">
          <div className="chatbot__section__div__container">
            <ChatboxQuestionSidebarBox />

            <MobileChatQuestionSidebar
              showChatQuestionSlider={showChatQuestionSlider}
              setShowQuestionChatSlider={setShowQuestionChatSlider}
            />

            <div className="chatbot__chatting__area">
              <ChatbotHeader
                setShowChatBox={setShowChatBox}
                showChatQuestionSlider={showChatQuestionSlider}
                setShowQuestionChatSlider={setShowQuestionChatSlider}
              />

              <ChatbotConversation />

              <div className="chat__input__area__section">
                <ChatboxInputMessage />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBotSection;
