/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable no-return-assign */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Card25 } from "../../../shared/Card/Card25/Card25";
import "./index.scss";
import TextField from "../../../shared/Inputs/TextField/TextField";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import AssignCourses from "../Members/AssignCourses/AssignCourses";
import SelectGroup from "../Members/SelectGroup/SelectGroup";
import SelectMembers from "../Groups/SelectMembers/SelectMembers";
import {
  getSingePathDataForUpdate,
  manageAssignments,
} from "../../../store/features/Tenant/Orgination/Assisment/ManageAssismentApi";
import { intersect } from "../../../helpers/utils/MathHelper";

const ManageAssignmentComonent = () => {
  const [singleDeadline, setSingleDeadline] = useState(false);
  const [individualDeadline, setIndividualDeadline] = useState(false);
  const dispatch = useAppDispatch();
  const parms = useParams();
  const assismentState: any = useAppSelector((store: RootStore) => {
    return store.AssismentSlice;
  });
  const memberState: any = useAppSelector((store: RootStore) => {
    return store.MemberSlice;
  });
  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });
  const indvCourse: any = [];
  const indvCourseWithDeadline: any = [];
  const [checkedLearningPathId, setCheckedLearningPathId] = useState<any>([]);
  const [selectedGroupId, setSelectedGroupId] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState<any>([]);
  const [memberError, setMemberError] = useState("");
  const navigate = useNavigate();

  const activesingleDeadline = () => {
    if (singleDeadline) {
      setSingleDeadline(false);
    } else {
      setSingleDeadline(true);
      setIndividualDeadline(false);
    }
  };
  const activeIndividualDeadline = () => {
    if (individualDeadline) {
      setIndividualDeadline(false);
    } else {
      setSingleDeadline(false);
      setIndividualDeadline(true);
    }
  };

  useEffect(() => {
    if (parms.pathId && parms.userId) {
      dispatch(
        getSingePathDataForUpdate({
          userId: Number(parms.userId),
          pathId: Number(parms.pathId),
        }),
      );
    }
  }, [parms]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>();
  const onSubmit = (values: any) => {
    const data: any = {
      users: selectedUserId,
      groups: selectedGroupId,
    };
    if (parms.pathId && parms.userId) {
      data.edit = true;
    }

    if (
      checkedLearningPathId.length > 0 ||
      indvCourse.length > 0 ||
      indvCourseWithDeadline.length > 0 ||
      selectedCourses.length > 0
    ) {
      //
    } else {
      toast.error("Either Learning Path or Course is required");
      return;
    }
    if (selectedUserId.length < 1 && selectedGroupId.length < 1) {
      toast.error("Either Member or Group is required");
      return;
    }
    if (singleDeadline) {
      data.single_deadline = values.single_deadline;
    }
    if (!individualDeadline) {
      const obj: any = {};
      checkedLearningPathId.forEach((element: any) => {
        const arr: any = [];
        selectedCourses.forEach((course: any) => {
          if (course.learning_path_ids.includes(element)) {
            arr.push(course.id);
          }
        });
        obj[element] = arr;
      });
      data.learning_paths = obj;

      selectedCourses.forEach((course: any) => {
        const res = intersect(course.learning_path_ids, checkedLearningPathId);
        if (res.length === 0) {
          indvCourse.push(course.id);
        }
      });
      data.individual_courses = indvCourse;
    }

    if (individualDeadline) {
      const obj: any = [];
      checkedLearningPathId.forEach((element: any) => {
        const arr: any = [];
        selectedCourses.forEach((course: any) => {
          if (course.learning_path_ids.includes(element)) {
            arr.push(course.id);
          }
        });
        obj.push({ [element]: arr });
      });
      data.learning_path_with_deadlines = obj;

      const generateInsideData = (value: any, val: any) => {
        const keys: any = Object.keys(val);
        const insideData: any = {};
        val.map((elem: any, index: number) => {
          return (insideData[elem] = values[elem]);
        });

        return insideData;
      };

      const result = data.learning_path_with_deadlines.reduce(function (
        items: any,
        item: any,
      ) {
        const fixed: any = {};
        const keys: any = Object.keys(item);

        keys.forEach(function (key: any) {
          return (fixed[key] = generateInsideData(item, item[key]));
        });
        items.push(fixed);
        return items;
      },
      []);
      data.learning_path_with_deadlines = Object.assign({}, ...result);

      selectedCourses.forEach((course: any) => {
        const res = intersect(course.learning_path_ids, checkedLearningPathId);
        if (res.length === 0) {
          for (const key in values) {
            if (key == course.id) {
              indvCourseWithDeadline.push({ [course.id]: values[key] });
            }
          }
        }
      });
      data.individual_course_deadlines = Object.assign(
        {},
        ...indvCourseWithDeadline,
      );
    }
    dispatch(manageAssignments({ data: data }));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (assismentState.req_success.add && !parms.pathId && !parms.userId) {
      reset();
      // setError("");
      setSingleDeadline(false);
      setIndividualDeadline(false);
    }
  }, [assismentState.req_success.add]);
  // useEffect(() => {
  //   if (assismentState.req_success.add && parms.pathId && parms.userId) {
  //     dispatch(
  //       getSingePathDataForUpdate({
  //         userId: Number(parms.userId),
  //         pathId: Number(parms.pathId),
  //       }),
  //     );
  //   }
  // }, [assismentState.req_success.add]);

  useEffect(() => {
    if (assismentState.req_success.get && parms.pathId && parms.userId) {
      if (assismentState.data.users[0].is_single_deadline === 0) {
        setIndividualDeadline(true);
        setSingleDeadline(false);
      }
      if (assismentState.data.users[0].is_single_deadline === null) {
        setIndividualDeadline(false);
        setSingleDeadline(false);
      }
      if (assismentState.data.users[0].is_single_deadline === 1) {
        setSingleDeadline(true);
        setIndividualDeadline(false);
        setValue(
          "single_deadline",
          assismentState.data.lp_courses[0]?.deadline,
        );
      }

      assismentState.data.lp_courses.forEach((e: any) => {
        setValue(e.id.toString(), e.deadline);
      });
      setSelectedUserId([assismentState.data.users[0].id]);
    }
  }, [assismentState.req_success.get]);
  return (
    <form
      className="manage__assignment__wrapper"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Card25 title="Manage assignment" baw>
        <AssignCourses
          checkedLearningPathId={checkedLearningPathId}
          setCheckedLearningPathId={setCheckedLearningPathId}
          groupState={groupState}
          setSelectedCourses={setSelectedCourses}
          notitle
          memberState={memberState}
          assismentState={assismentState}
          editAssignmnet={parms.pathId && parms.userId}
        />

        {!parms.pathId && !parms.userId && (
          <div className="select__member__and__group">
            <div className="member__colums">
              <SelectMembers
                title="Select member(s)"
                groupState={groupState}
                setSelectedUserId={setSelectedUserId}
                // required
                error={memberError}
                setMemberError={setMemberError}
                assismentState={assismentState}
                allReset
                editAssignmnet={parms.pathId && parms.userId}
              />
            </div>
            <div className="member__colums">
              <SelectGroup
                title="Select group(s)"
                groupState={groupState}
                setSelectedGroupId={setSelectedGroupId}
                memberState={memberState}
                assismentState={assismentState}
                allReset
              />
            </div>
          </div>
        )}
        {assismentState.req_success.get && parms.pathId && parms.userId && (
          <div className="select__member__and__group">
            <span className="user_name">
              {assismentState.data.users[0].name}
            </span>
          </div>
        )}

        <div className="date__selection__wrapper">
          <div className="single__deadline__container">
            <button
              type="button"
              className="radio"
              onClick={activesingleDeadline}
            >
              <div className="radio__icon">
                {singleDeadline && <span className="tick">.</span>}
              </div>{" "}
              Set a single deadline
            </button>
            {singleDeadline && (
              <div className="single__date__wrapper">
                <div className="date__section">
                  {/* <span className="date__label">
                    Data visualization expert (Path)
                  </span> */}
                  <div className="calendar__width">
                    <TextField
                      type="date"
                      placeholder="mm/dd/yyyy"
                      name="single_deadline"
                      register={register}
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="multiple__deadline__container">
            <button
              type="button"
              className="radio"
              onClick={activeIndividualDeadline}
            >
              <div className="radio__icon">
                {individualDeadline && <span className="tick">.</span>}
              </div>{" "}
              Set individual deadlines
            </button>
            {individualDeadline && (
              <div className="multiple__date__wrapper">
                {selectedCourses.length > 0 &&
                  selectedCourses.map((course: any) => {
                    return (
                      <div key={course.id}>
                        <div className="date__section">
                          <span className="date__label">
                            {" "}
                            {course.name} (Course)
                          </span>
                          <div className="calendar__width">
                            <TextField
                              type="date"
                              placeholder="mm/dd/yyyy"
                              name={course.id.toString()}
                              register={register}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        </div>
                        <div className="date__divider" />
                      </div>
                    );
                  })}
                {/* <div className="date__section">
                  <span className="date__label">
                    {" "}
                    Data visualization expert (Path)
                  </span>
                  <div className="calendar__width">
                    <TextField
                      type="date"
                      placeholder="mm/dd/yyyy"
                      // register={register}
                      // error={errors?.last_name}
                    />
                  </div>
                </div>
                <div className="date__divider" /> */}
              </div>
            )}
          </div>
        </div>
        <div className="manage__assignment__divider" />
        <div className="button__section">
          <PrimaryButton
            type="submit"
            text="Assign"
            loading={assismentState.loading.add}
          />
        </div>
      </Card25>
    </form>
  );
};

export default ManageAssignmentComonent;
