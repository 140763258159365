import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadInstructors, addInstructor, updateInstructor, pullInstructors } from "./ManageInstructorApi";

const initialState: any = {
  loading: false,
  pull_loading: false,
  update_loading: false,
  req_success: false,
  pull_req_success: false,
  update_success: false,
  instructors: [],
  all_instructors: [],
  temp: [],
  instructor_update_temp: [],
};

const ManageInstructorSlice = createSlice({
  name: "instructors",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // PULL INSTRUCTOR
    builder.addCase(pullInstructors.pending, (state) => {
      state.pull_loading = true;
      state.pull_req_success = false;
    });
    builder.addCase(
      pullInstructors.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.pull_loading = false;
        state.pull_req_success = true;
      },
    );
    builder.addCase(pullInstructors.rejected, (state) => {
      state.pull_loading = false;
    });
    // LOAD INSTRUCTORS
    builder.addCase(loadInstructors.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      loadInstructors.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_instructors = payload?.data;
        state.instructors = payload?.data;
      },
    );
    builder.addCase(loadInstructors.rejected, (state) => {
      state.loading = false;
    });

    // ADD INSTRUCTOR
    builder.addCase(addInstructor.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      addInstructor.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
      },
    );
    builder.addCase(addInstructor.rejected, (state) => {
      state.loading = false;
    });

    // UPDATE INSTRUCTOR
    builder.addCase(updateInstructor.pending, (state) => {
      state.update_loading = true;
      state.update_success = false;
    });
    builder.addCase(
      updateInstructor.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.update_loading = false;
        state.update_success = true;
      },
    );
    builder.addCase(updateInstructor.rejected, (state) => {
      state.update_loading = false;
    });
  },
});

export default ManageInstructorSlice.reducer;
