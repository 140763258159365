/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import "./siteManager.scss";

import ThemePreviewModal from "./ThemePreviewModal/ThemePreviewModal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { themeData } from "./helper";
import FileUploader from "./FileUploader";
import SelectTheme from "./SelectTheme";
import {
  getSiteManagerSetting,
  updateSiteManager,
} from "../../../store/features/AdminPanel/SiteManager/SiteManagerApi";
import { TACPrimaryButton } from "../../../shared/Buttons/Buttons";

const SiteManagerComp = (props: any) => {
  const siteManager: any = useAppSelector((store: RootStore) => {
    return store.SiteManagerSlice;
  });

  console.log(siteManager);

  const [showModal, setShowModal] = useState(false);
  const [activeId, setActiveId] = useState();
  const [logofile, setLogoFile] = useState<any>("");
  const [compactLogoFile, setCompactLogoFile] = useState<any>("");
  const [themeName, setThemename] = useState<String>("");

  const siteLogoHiddenFileInput = useRef<any>(null);
  const compactLogoHiddenFileInput = useRef<any>(null);
  const dispatch = useAppDispatch();

  const handleClick = (event: any) => {
    siteLogoHiddenFileInput && siteLogoHiddenFileInput?.current?.click();
  };

  const handleCompactLogoClick = (even: any) => {
    compactLogoHiddenFileInput && compactLogoHiddenFileInput?.current?.click();
  };

  const handlePreview = (e: any, id: any) => {
    e.stopPropagation();
    setActiveId(id);
    setShowModal(true);
  };

  useEffect(() => {
    dispatch(getSiteManagerSetting());
  }, []);

  useEffect(() => {
    if (siteManager.req_success.get) {
      setThemename(siteManager.activeThemeName);
      setCompactLogoFile({ imageUrl: siteManager.compact_logo });
      setLogoFile({ imageUrl: siteManager.site_logo });
    }
  }, [siteManager.req_success.get]);

  useEffect(() => {
    if (siteManager.req_success.update) {
      window.location.reload();
    }
  }, [siteManager.req_success.update]);

  const updateThemeAndLogo = () => {
    const data: any = {};

    data.themeName = themeName;
    if (logofile) {
      data.site_logo = logofile.base64Image;
      data.site_logo_extension = logofile.extension;
    }

    if (compactLogoFile) {
      data.compact_logo = compactLogoFile.base64Image;
      data.compact_logo_extension = compactLogoFile.extension;
    }
    dispatch(updateSiteManager({ data }));
  };

  return (
    <>
      <div className="sitemanager__wrapper mt-7">
        <form className="sitemanager__container">
          <div className="sitemanager__heading">
            <span>Site Manager</span>
          </div>
          <div className="sitemanager__logo__upload__section">
            <FileUploader
              title="Site logo"
              subTitle="Click to add logo"
              description="Recommended Resolution Size is 250 x 150 px"
              fileInfo={logofile}
              inputRef={siteLogoHiddenFileInput}
              onclick={handleClick}
              setFileInfo={setLogoFile}
            />
            <FileUploader
              title="Compact logo"
              subTitle="Click to add logo"
              description=" Recommended Resolution Size is 250 x 150 px"
              fileInfo={compactLogoFile}
              inputRef={compactLogoHiddenFileInput}
              onclick={handleCompactLogoClick}
              setFileInfo={setCompactLogoFile}
            />
          </div>
          <SelectTheme
            handlePreview={handlePreview}
            themeName={themeName}
            setThemename={setThemename}
            activeThemeName={siteManager.activeThemeName}
          />
          <div className="sm__theme__divide__line" />
          <div className="sm__theme__submit__button">
            <TACPrimaryButton
              type="button"
              text="Submit"
              loading={siteManager.loading.update}
              onClick={updateThemeAndLogo}
            />
          </div>
        </form>
      </div>
      {showModal && (
        <ThemePreviewModal
          showModal={showModal}
          setShowModal={setShowModal}
          data={themeData}
          id={activeId}
          activeThemeName={siteManager.activeThemeName}
        />
      )}
    </>
  );
};

export default SiteManagerComp;
