import React from "react";
import { ActiveToggleButton } from "../../../shared/Buttons/Buttons";
import "./index.scss";

function GetStarted(props: any) {
  return (
    <div className="getstarted-container">
      <div className="getstarted-container-inner">
        <div className="studynow">Study now, anywhere</div>
        <div className="getready">Ready to get started?</div>
        <div className="getstarted-button">
          <ActiveToggleButton active text="Get started" />
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
