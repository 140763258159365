import React, { useEffect, useRef, useState } from "react";
import { RefreshCcw, StopCircle } from "react-feather";
import ReactHtmlParser from "react-html-parser";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import "./conversation.scss";
import logo from "../../../../../assets/images/half-logo.png";
import tickDoneIcon from "../../../../../assets/images/tickdone.svg";
import wtickDoneIcon from "../../../../../assets/images/wtickDoneimg.svg";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  addAnswerToMessageStack,
  addConversationToSidebar,
  askNewQuestionToAi,
  setActiveConversationId,
  setLoadingCurrentAnswer,
  setResponseGenerated,
} from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromComponentLevelState } from "../../../../../helpers/utils/urlHelper";
import CurrentResponse from "./CurrentResponse";
import { stopGenerating } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";

const ChatbotConversation = () => {
  const dispatch = useAppDispatch();
  const bottomRef = useRef<any>(null);
  const [showResponseBtn, setShowResponseBtn] = useState(true);
  const [messageStack, setMessageStack] = useState<any>([]);
  const [currentResponse, setCurrentResponse] = useState("");
  const [currentQuestionId, setCurrentQuestionId] = useState("");
  const [stopResponse, setStopResponse] = useState<boolean>(false);

  const eventSourceRef = useRef<EventSource | null>(null);

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const dynamic_url = renderDynamicUrlFromComponentLevelState(themeState);

  const askQuestionWithAiNew = async () => {
    const data: any = { question: aiState.question };
    if (aiState.active_conversation_id) {
      data.conversation_id = aiState.active_conversation_id;
    }
    dispatch(setLoadingCurrentAnswer(true));

    const res = await axiosInstance.post(
      `${dynamic_url}/ai-stream-question`,
      data
    );

    dispatch(addConversationToSidebar(res.data.data));
    dispatch(setActiveConversationId(res.data.data.conversation_id));

    const eventSource: any = new EventSource(
      `${dynamic_url}/ai-stream-chat?question_id=${res.data.data.id}`
    );

    setCurrentQuestionId(res.data.data.id);

    eventSourceRef.current = eventSource;

    let sentence = "";

    eventSource.onmessage = (e: any) => {
      if (e.data === "[DONE]") {
        eventSource.close();

        dispatch(setResponseGenerated(true));
        dispatch(setLoadingCurrentAnswer(false));

        if (sentence) {
          dispatch(addAnswerToMessageStack(sentence));
          setStopResponse(false);
          setCurrentQuestionId("");
          setCurrentResponse("");
        }
      } else {
        const txt = JSON.parse(e.data).choices[0].delta.content;

        if (txt !== undefined) {
          sentence += txt;

          if (sentence.length > 250) {
            setStopResponse(true);
          }

          setCurrentResponse(sentence);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    };
    eventSource.onerror = (e: any) => {
      toast.error(e);
      dispatch(setLoadingCurrentAnswer(false));
      eventSource.close();
    };
  };

  useEffect(() => {
    if (aiState.question) {
      askQuestionWithAiNew();
      // dispatch(askQuestionWithAi(data));

      setMessageStack([
        ...messageStack,
        { id: uuidv4(), message: aiState.question, user: true, ai: false },
      ]);

      dispatch(askNewQuestionToAi(""));
      dispatch(setResponseGenerated(false));
    }
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [aiState.question]);

  const handleStopResponse = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      dispatch(setResponseGenerated(true));
      dispatch(setLoadingCurrentAnswer(false));

      if (currentResponse) {
        dispatch(addAnswerToMessageStack(currentResponse));
        dispatch(
          stopGenerating({
            question_id: currentQuestionId,
            answer: currentResponse,
          })
        );
        setStopResponse(false);
        setCurrentQuestionId("");
        setCurrentResponse("");
      }
    }
  };

  return (
    <div className="chatting__area">
      <div className="message__area">
        {aiState.messagesStack.map((message: any) => {
          if (message.user) {
            return (
              <div className="user__question" key={message.id}>
                <div className="user__question__wrapper">
                  <div className="user__first__letter__handle__div">
                    <div className="user__avatar">
                      <div className="first__letter">
                        {authState.tac_user?.first_name?.charAt(0)}
                      </div>
                    </div>
                  </div>
                  <div className="user__question__div__handle">
                    <div className="user__question__div">
                      {/* <p></p> */}
                      {ReactHtmlParser(message?.message)}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return (
            <div className="chatbot__answer" key={message.id}>
              <div className="chatbot__answer__wrapper">
                <div className="image__width__handle">
                  <div className="image__avatar">
                    <img src={logo} alt="" />
                  </div>
                </div>
                <div className="chatbot__answer__handle">
                  <div className="chatbot__answer">
                    {/* <p>  </p> */}
                    {ReactHtmlParser(message?.message)}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {currentResponse && currentResponse.length > 0 && (
          <CurrentResponse currentResponse={currentResponse} />
        )}
        <div ref={bottomRef} />
      </div>

      <div className="fixed__button__wrapper">
        {(aiState.loading.question || aiState.loading.current_answer) && (
          <div className="fixed__response__stop__button">
            {stopResponse ? (
              <button type="button" onClick={handleStopResponse}>
                <StopCircle className="refresh__icon" /> Stop generating
                response
              </button>
            ) : (
              <button type="button">
                <RefreshCcw className="refresh__icon" /> Generating response..
              </button>
            )}
          </div>
        )}
        {aiState.response_generated && !aiState.loading.current_answer && (
          <div className="fixed__response__stop__button">
            <button type="button">
              <img
                src={
                  themeState.themeMode === "dark" ? tickDoneIcon : wtickDoneIcon
                }
                alt="done"
              />
              Response generated!
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatbotConversation;
