import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";

import LearningPathComp from "../../../components/UserDashboard/LearningPathComp/LearningPathComp";

const ProductManagerCourses = () => {
  return (
    <AdminPanelLayout>
      <div className="mt-5">
        <LearningPathComp />
      </div>
    </AdminPanelLayout>
  );
};

export default ProductManagerCourses;
