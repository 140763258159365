/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { memo } from "react";
import { setAllResposneSucessToFalse } from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationSlice";
import {
  goToNextStep,
  goToPrevStep,
  setShowInnerPopup,
  setcurrentStep,
} from "../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import TourInfoPopup from "../Tour/TourInfo/TourInfoPopup";
import "./questiondrawer.scss";

const QuestionDrawerComp = (props: any) => {
  const {
    toggleActiveClass,
    toggleAllQuestionSidebar,
    setShowSidebar,
    setCurrentIndex,
    resetTimer,
    recordWebcam,
    cameraStatus,
    setSelectedTabIndex,
    setShowReplyBox,
    setAudioOrVideoResponseTye,
    audioOrVideoResponseType,
    recorderControls,
    setShowAudioPreview,
    showRecordbtnForSelectResponseType,
    setShowRecordbtnForSelectResponseType,
  } = props;
  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  // Check if the user is came for the first time interview preparation
  const isFirstTime = authState?.tac_user?.onboarding?.interview?.is_completed;

  // For interview tour popup
  const { currentStep, steps, showPopupInInterviewPrepQuestionSidebar } =
    useAppSelector((store: RootStore) => store.ShowPopupSlice);
  const singleStepData = steps.filter((item: any) => item.id === currentStep);
  //  console.log(currentStep);
  const dispatch = useAppDispatch();

  const changeQuestion = (e: any, index: number) => {
    e.stopPropagation();
    setSelectedTabIndex(null);
    setShowReplyBox(false);
    setCurrentIndex(index);
    setShowSidebar(false);
    setShowAudioPreview(false);

    if (recordWebcam.status !== cameraStatus.OPEN) {
      // recordWebcam.retake();

      if (isFirstTime === 0) {
        dispatch(setcurrentStep(8));
        dispatch(setShowInnerPopup());
      }
    }
    if(audioOrVideoResponseType === "video"){
      recordWebcam.close()
    }
  

    setAudioOrVideoResponseTye("video");
    setShowRecordbtnForSelectResponseType(true);

    resetTimer(index);
    dispatch(setAllResposneSucessToFalse());
  };

  const handleNext = () => {
    dispatch(goToNextStep());
  };

  const handlePrev = () => {
    dispatch(goToPrevStep());
  };

  return (
    <div
      id="sidbar"
      className={`${toggleActiveClass}`}
      onClick={toggleAllQuestionSidebar}
    >
      <div className="allQuestionSection">
        <div className="allQuestion">
          <div className="headings">
            <h4>All Questions</h4>
            <p>Choose one to attempt</p>
          </div>
        </div>
        <div className="questions">
          {interviewState.req_success.questions &&
            interviewState.questions.map((item: any, index: number) => (
              <div
                className="question"
                key={item.id}
                onClick={(e) => changeQuestion(e, index)}
              >
                <p>{item.question}</p>
              </div>
            ))}
        </div>
      </div>
      
      {   showPopupInInterviewPrepQuestionSidebar && ( <TourInfoPopup title={singleStepData[0].title} message={singleStepData[0].message} handleOnClickBack={handlePrev} handleOnClickNext={handleNext} id={singleStepData[0].id} />)}
    </div>
  );
};

export default memo(QuestionDrawerComp);
