import { useAppSelector } from "../../../../store/store";
import "./viewApplyNowDetails.scss";
import ViewDetailsHeader from "./ViewDetailsHeader/ViewDetailsHeader";
import ViewDetailsTable from "./ViewDetailsTable/ViewDetailsTable";

const ViewApplyNowDetails = () => {
  const applyNowState: any = useAppSelector((state) => state.ApplyNowSlice);
  return (
    <div className="view__apply__now__detais">
      {!applyNowState.loading && applyNowState.singleApplyNow && (
        <>
          <ViewDetailsHeader />
          <ViewDetailsTable />
        </>
      )}
    </div>
  );
};

export default ViewApplyNowDetails;
