import React from "react";
import ReactHtmlParser from "react-html-parser";

import logo from "../../../../../assets/images/half-logo.png";

const CurrentResponse = (props: any) => {
  const { currentResponse } = props;

  return (
    <div className="chatbot__answer">
      <div className="chatbot__answer__wrapper">
        <div className="image__width__handle">
          <div className="image__avatar">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="chatbot__answer__handle">
          <div className="chatbot__answer">
            {/* <p></p> */}
            {ReactHtmlParser(currentResponse)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentResponse;
