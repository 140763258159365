/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addMentor,
  deleteMentor,
  loadMentors,
  updateMentor,
  rensedInvitationForMentor,
} from "./MentorApi";

const initialState: any = {
  loading: false,
  resend_invitaiton_loading: false,
  adderLoading: false,
  deleteLoading: false,
  updateLoading: false,
  editUser: false,
  req_success: false,
  resend_invitation_success: false,
  delete_success: false,
  edit_success: false,
  add_success: false,
  single_user_info: {},
  current_page: 0,
  all_users: [],
  temp_users: [],
  users: [],
};

const mentorSlice = createSlice({
  name: "mentors",
  initialState,
  reducers: {
    editMentorInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.editUser = true;
      state.single_user_info = payload;
    },
    closeMentorEditModal: (state: any, { payload }: PayloadAction<any>) => {
      state.editUser = false;
      state.edit_success = false;
    },
    refreshMentor: (state: any) => {
      state.users = state.all_users.slice(0, 10);
    },
    mentorPaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.users = state.all_users.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.users = state.all_users.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.users = state.all_users.slice(payload * 10, 10 * payload + 10);
      }
    },

    searchMentors: (state: any, { payload }: PayloadAction<any>) => {
      if (payload?.length > 1) {
        state.temp_users = state.all_users.filter(
          (user: any) =>
            user.name.toLowerCase().includes(payload.toLowerCase()) ||
            user.email.toLowerCase().includes(payload.toLowerCase())
        );
        state.users = state.temp_users.slice(0, 10);
      } else {
        state.users = state.all_users.slice(
          state.current_page * 10,
          10 * state.current_page + 10
        );
      }
    },
  },
  extraReducers: (builder) => {
    // LOAD USERS
    builder.addCase(loadMentors.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      loadMentors.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_users = payload?.data.reverse();
        state.users = state.all_users.slice(0, 10);
      }
    );
    builder.addCase(loadMentors.rejected, (state) => {
      state.loading = false;
    });

    // ADD USERS
    builder.addCase(addMentor.pending, (state) => {
      state.adderLoading = true;
      state.add_success = false;
    });
    builder.addCase(
      addMentor.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.adderLoading = false;
        state.add_success = true;
        if (payload.data) {
          state.all_users = [payload?.data, ...state.all_users];

          if (state.current_page === 0) {
            state.users = state.all_users.slice(0, 10);
          } else {
            state.users = state.all_users.slice(
              state.current_page * 10,
              state.current_page * 10 + 10
            );
          }
        }
      }
    );
    builder.addCase(addMentor.rejected, (state) => {
      state.adderLoading = false;
      state.add_success = false;
    });

    // RESEND INVITATION
    builder.addCase(rensedInvitationForMentor.pending, (state) => {
      state.resend_invitaiton_loading = true;
      state.resend_invitation_success = false;
      state.edit_success = false;
    });
    builder.addCase(
      rensedInvitationForMentor.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.resend_invitaiton_loading = false;
        state.resend_invitation_success = true;
        state.edit_success = true;
        state.editUser = false;
      }
    );
    builder.addCase(rensedInvitationForMentor.rejected, (state) => {
      state.resend_invitaiton_loading = false;
    });

    // update USERS
    builder.addCase(updateMentor.pending, (state) => {
      state.adderLoading = true;
      state.edit_success = false;
    });
    builder.addCase(
      updateMentor.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.adderLoading = false;
        state.edit_success = true;
        state.users = state.users.map((user: any) => {
          if (user.id === payload?.data.id) {
            return payload?.data;
          }
          return user;
        });

        state.all_users = state.all_users.map((user: any) => {
          if (user.id === payload?.data.id) {
            return payload?.data;
          }
          return user;
        });
      }
    );
    builder.addCase(updateMentor.rejected, (state) => {
      state.adderLoading = false;
      state.edit_success = false;
    });

    // DELETE USERS
    builder.addCase(deleteMentor.pending, (state) => {
      state.deleteLoading = true;
      state.delete_success = false;
    });
    builder.addCase(
      deleteMentor.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.deleteLoading = false;
        state.delete_success = true;
        state.all_users = state.all_users.filter(
          (user: any) => user.id !== payload?.id
        );
        if (state.current_page === 0) {
          state.users = state.all_users.slice(0, 10);
        } else {
          state.users = state.all_users.slice(
            state.current_page * 10,
            state.current_page * 10 + 10
          );
        }

        // state.users = state.users.filter(
        //   (user: any) => user.id !== payload?.id,
        // );
      }
    );
    builder.addCase(deleteMentor.rejected, (state) => {
      state.delete_success = false;
      state.deleteLoading = false;
    });
  },
});

// export const { setRecentSignupEmail, invokeLogout } = mentorSlice.actions;
export const {
  editMentorInfo,
  mentorPaginate,
  searchMentors,
  closeMentorEditModal,
  refreshMentor,
} = mentorSlice.actions;
export default mentorSlice.reducer;
