import React from "react";
import Skleton from "../../../../../../shared/Skelton/Skleton";
import "./index.scss";

const SingleBillingSkleton = () => {
  return (
    <div className="single__billing__skleton">
      <Skleton />
    </div>
  );
};

const CheckoutBillingSkleton = () => {
  return (
    <div className="checkout__billing__skleton">
      <SingleBillingSkleton />
      <SingleBillingSkleton />
    </div>
  );
};

const PaymentMethodSingleSkleton = () => {
  return (
    <div className="single__payment__method__skleton">
      <Skleton />
    </div>
  );
};

const PaymentMethodSkleton = () => {
  return (
    <div className="payment__method__all__skleton">
      <div className="mb-1">
        {" "}
        <PaymentMethodSingleSkleton />
      </div>
      <PaymentMethodSingleSkleton />
    </div>
  );
};

const SinglePricingDetailSkleton = () => {
  return (
    <div className="single__pricing__detail__skleton">
      <Skleton />
    </div>
  );
};

const PricingDetailSkleton = () => {
  return (
    <div className="pricing__detail__skleton__section">
      <div className="mb-1 mt-1">
        <SinglePricingDetailSkleton />
      </div>
      <div className="mb-1">
        <SinglePricingDetailSkleton />
      </div>
      <div>
        <SinglePricingDetailSkleton />
      </div>
    </div>
  );
};

export {
  CheckoutBillingSkleton,
  SingleBillingSkleton,
  PaymentMethodSkleton,
  PricingDetailSkleton,
};
