// import UserProfileNavbar from "../../../components/AdminPanel/userProfile/userProfileNavbar";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import CertificatesTable from "../../../components/AdminPanel/userProfile/certificates/certificatesTable";

const Certificates = (props: any) => {
  return (
    // <div className="certificates-main-container">
    <AdminPanelLayout>
      <BoxShadow>
        {/* <UserProfileNavbar /> */}
        <CertificatesTable />
      </BoxShadow>
    </AdminPanelLayout>
    // </div>
  );
};

export default Certificates;
