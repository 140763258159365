import React, { useState } from "react";
import TeamCard from "../../../components/Landingpage/TeamCard/TeamCard";
import LandingLayout from "../../../components/Misc/Layout/LandingLayout/LandingLayout";
import { ActiveToggleButton } from "../../../shared/Buttons/Buttons";
import GetStarted from "../../../components/Landingpage/GetStarted/GetStarted";
import "./index.scss";
import LayoutLanding from "../../../components/Landingpage/Layout/LayoutLanding";

function OurTeam(props: any) {
  const [isindustryMentor, setIndustryMentor] = useState(false);
  return (
    <LayoutLanding>
      <div className="ourteam-container">
        <h1>Our team</h1>
        <div className="meettheteam">Meet the team of Agile Academy</div>
        <div className="smallhrline" />
        <div className="our-team-toggle-container">
          <ActiveToggleButton
            text="Our Agile Team"
            active={!isindustryMentor}
            onClick={() => {
              setIndustryMentor(false);
            }}
          />
          <ActiveToggleButton
            text="Industry Mentors"
            active={isindustryMentor}
            onClick={() => {
              setIndustryMentor(true);
            }}
          />
        </div>
        <div className="teams-display-container">
          {!isindustryMentor &&
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => {
              return <TeamCard />;
            })}
          {isindustryMentor &&
            [1, 2, 3, 4].map(() => {
              return <TeamCard />;
            })}
        </div>
        <GetStarted />
      </div>
    </LayoutLanding>
  );
}

export default OurTeam;
