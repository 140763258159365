/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-plusplus */
export const removeTags = (str: any) => {
  if (str === null || str === "") {
    return false;
  }
  return str.replace(/(<([^>]+)>)/gi, "");
};
export function formatOpenAiResponseAsHtml(response: string): string {
  // const cres = response.replace(/1./gi, "\n\j");
  const listRegex = /^[0-9]+[.)] /gm;
  const lines = response.split("\n");
  // const lines = cres.split("\n");

  let html = "";

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();

    if (line.match(listRegex)) {
      const items = [];
      let j = i;
      while (j < lines.length && lines[j].match(listRegex)) {
        items.push(`<li>${lines[j].replace(listRegex, "")}</li>`);
        j++;
      }
      html += `<ol>${items.join("")}</ol>`;
      i = j - 1;
    } else {
      html += `<p>${line}</p>`;
    }
  }

  // html = html.replace(/\n/g, "<br>");

  return html;
}

export const dotTruncate = (data: any, length: number) => {
  return data?.slice(0, length) + (data?.length > length ? "..." : "");
};

export const formatAiResponse = (data: any) => {
  const res = data?.replace(/(?:\r\n|\r|\n)/g, "<br>");
  return res;
};
