/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ChevronsUp, Minus, Plus } from "react-feather";
import "./index.scss";
import { useEffect, useState } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getFaqForNewDashboard } from "../../../../store/features/UserDashboard/NewUserDashboard/NewUserDashoardApi";

const DashboardFaq = () => {
  const [active, setActive] = useState(0);

  const dispatch = useAppDispatch();
  const dashState: any = useAppSelector((store: RootStore) => {
    return store.NewDashboard;
  });
  useEffect(() => {
    dispatch(getFaqForNewDashboard());
  }, []);

  const handleActiveFaq = (id: number): void => {
    if (active === 0) {
      setActive(id);
    }
    if (active === id) {
      setActive(0);
    }
    if (id !== active) {
      setActive(id);
    }
  };

  return (
    <div className="dashboard__far__wrapper">
      <div className="center__wrapper">
        <h1 className="title">Frequently Asked Questions</h1>
        <div className="faq__list__sections">
          {!dashState.loading.faq &&
            dashState.req_success.faq &&
            dashState.faq.map((data: any) => {
              return (
                <div
                  className="faq__card"
                  key={data.id}
                  onClick={() => handleActiveFaq(data.id)}
                >
                  <div className="header__section">
                    <span className="faq__questions">{data.question}</span>
                    {active !== data.id && <Plus className="plus__icon" />}
                    {active === data.id && <Minus className="plus__icon" />}
                  </div>
                  <div
                    className={`faq__content ${active === data.id && "active"}`}
                  >
                    <div className="faq__divider" />

                    <p className="content">{data.answer}</p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default DashboardFaq;
