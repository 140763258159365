import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import "./newsidebarlink.scss";
import { ChevronDown, ChevronUp } from "react-feather";
import {
  useAppDispatch,
  useAppSelector,
  RootStore,
} from "../../../store/store";
import {
  addActiveLinks,
  removeActiveLinks,
} from "../../../store/features/UI/UISlice";
import chrownIcon from "../../../assets/icons/crown-icon.png"

function NewSideBarLink(props: any) {
  const { title, img, smSidebar, links, icon,isTacUser } = props;
  const [active, setActive] = useState(false);
  const [activeLink, setActiveLink] = useState<String>("");
  const dispatch = useAppDispatch();
  const location:any = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const from = searchParams.get("from");
  const uiState: any = useAppSelector((store: RootStore) => {
    return store.UISlice;
  });

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const handleRoute = (route: any) => {
    navigate(route);
  };
  useLayoutEffect(() => {
    uiState?.activeLinks?.forEach((e: any) => {
      if (title === e) {
        setActive(true);
      }
    });
    links.forEach((e: any) => {
      if (e.path === pathname) {
        setActive(true);
        dispatch(addActiveLinks(title));
        setActiveLink(pathname);
      }
    });
  }, []);
  return (
    <div className={`new-sidebar-link-container ${isTacUser && "tac__user__sidebar__link__container"}`}>
      <button
        type="button"
        className={`nslc-title ${active && "nslc-title-active"}`}
        onClick={() => {
          setActive(!active);
          if (!active) {
            dispatch(addActiveLinks(title));
          } else {
            dispatch(removeActiveLinks(title));
          }
        }}
      >
        <div className="nslc-icon-text">
          {icon || <img src={img} alt="icon" />}
          {!smSidebar && title}
        </div>
        {active && !smSidebar ? <ChevronUp /> : <ChevronDown />}
      </button>
      <div
        className={`
          ${active
            ? "nslc-row-container"
            : "nslc-row-container nslc-row-container-none"} 
        ` }
      >
        {!smSidebar &&
          links.map((e: any) => {
            return (
              <div className="nslc-link" key={e.id}>
                <div className="bar" />
                <button
                  className={`${ !isTacUser && e.path === activeLink ? "active-nslc-link" : ""}  ${isTacUser && e.path === pathname || e.from === from ?"active-nslc-link":""}`}
                  type="button"
                  onClick={() => {
                    handleRoute(e.path);
                  }}
                >
                  {e.title} {e?.isLinkIcon && <img src={chrownIcon} alt=""/>}
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default NewSideBarLink;
