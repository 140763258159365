import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import LearningResourceHeader from "../../../components/AdminPanel/learningResource/Header/LearningResourceHeader";
import ListFileAndFolder from "../../../components/AdminPanel/learningResource/ListFileAndFolder/ListFileAndFolder";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import "./index.scss";

const LearningResource = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="learning_page__resource_container mt-7">
          <LearningResourceHeader />
          <ListFileAndFolder />
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default LearningResource;
