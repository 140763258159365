/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import couseImg from "../../../assets/icons/OnlineCourse.jpg";

const Course = (props: any) => {
  const { data } = props;
  const navigate = useNavigate();
  return (
    <div
      className="course__wapper"
      onClick={() => navigate(`/admin-courses-detail/${data.id}`)}
    >
      <img
        src={data.course_card_image_url}
        className="cover__img"
        alt="cover"
      />
      <div className="course__info__section">
        <span className="course__title">{data.name}</span>
        <div className="course__row">
          <span className="course__durarion">
            Duration {data.custom_duration}
          </span>

          {/* <span className="course__deadline">Deadline: 19/11/2022</span> */}
        </div>
        <p className="course__description">{data.description}</p>
        <div className="progress__section">
          <ProgressBar
            completed={Math.floor(data.percentage_completed)}
            height="3px"
            customLabel="."
            baseBgColor="var( --primary-progress-bar-bg)"
            bgColor="var( --secondary-progress-bar-bg)"
            labelSize="0"
          />
        </div>
        <span className="completed__text">
          {" "}
          {Math.floor(data.percentage_completed)}% completed
        </span>
      </div>
    </div>
  );
};

export default Course;
