import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GetOurTeams,
  deleteTeamApi,
  addTeamApi,
  updateTeamApi,
  getExperts,
} from "./ourTeamsApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    delete: false,
  },
  req_success: {
    add: false,
    get: false,
    delete: false,
    edit: false,
  },
  current_page: 0,
  editTeam: false,
  single_team_info: {},
  teams: [],
  all_teams: [],
  temp_teams: [],
  temp_update_teams: [],
};

const ourTeamsSlice = createSlice({
  name: "ourTeams",
  initialState,
  reducers: {
    editTeamsInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.editTeam = true;
      state.single_team_info = payload;
    },
    closeTeamEditModal: (state: any, { payload }: PayloadAction<any>) => {
      state.editTeam = false;
    },
    changeTempOrder: (state: any, { payload }: PayloadAction<any>) => {
      state.temp_update_teams = state.all_teams;
      const a = state.all_teams[payload.sourdeIndex];

      state.all_teams.splice(payload.sourdeIndex, 1);

      state.all_teams.splice(payload.destinationIndex, 0, a);

      state.teams = state.all_teams;
    },
    cleanScreen: (state: any) => {
      state.all_teams = [];
      state.teams = [];
    },
    teamTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.teams = state.all_teams.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.teams = state.all_teams.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.teams = state.all_teams.slice(payload * 10, 10 * payload + 10);
      }
    },
    searchTeamsInTable: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_teams = state.all_teams.filter((team: any) =>
          team.name.toLowerCase().includes(payload.toLowerCase())
        );
        state.teams = state.temp_teams;
      } else {
        state.teams = state.all_teams;
      }
    },
  },
  extraReducers: (builder) => {
    // GET ALL TEAMS MEMBERS

    builder.addCase(GetOurTeams.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      GetOurTeams.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.all_teams = payload.data;
        state.teams = payload.data;
      }
    );
    builder.addCase(GetOurTeams.rejected, (state) => {
      state.loading.get = false;
    });

    // GET ALL Insturctors i.e Our Expert

    builder.addCase(getExperts.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getExperts.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.all_teams = payload.data;
        state.teams = payload.data;
      }
    );
    builder.addCase(getExperts.rejected, (state) => {
      state.loading.get = false;
    });

    // delete TEAMS

    builder.addCase(deleteTeamApi.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteTeamApi.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_teams = state.all_teams.filter(
          (team: any) => team.id !== payload?.id
        );
        state.teams = state.all_teams;
      }
    );
    builder.addCase(deleteTeamApi.rejected, (state) => {
      state.loading.delete = false;
    });

    // add teams
    builder.addCase(addTeamApi.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      addTeamApi.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;

        if (payload.data) {
          state.all_teams = [...state.all_teams, payload?.data];
          state.teams = state.all_teams;
        }
      }
    );
    builder.addCase(addTeamApi.rejected, (state) => {
      state.loading.add = false;
    });

    // update TEAMS
    builder.addCase(updateTeamApi.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      updateTeamApi.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
        state.editTeam = false;

        state.teams = state.teams.map((team: any) => {
          if (team.id === payload?.data.id) {
            return payload?.data;
          }
          return team;
        });
        state.all_teams = state.all_teams.map((team: any) => {
          if (team.id === payload?.data.id) {
            return payload?.data;
          }
          return team;
        });
      }
    );
    builder.addCase(updateTeamApi.rejected, (state) => {
      state.loading.add = false;
      state.req_success.add = false;
    });
  },
});
export const {
  editTeamsInfo,
  closeTeamEditModal,
  teamTablePaginate,
  searchTeamsInTable,
  cleanScreen,
  changeTempOrder,
} = ourTeamsSlice.actions;
export default ourTeamsSlice.reducer;
