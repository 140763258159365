import ForgetPasswordFrom from "../../components/Auth/AuthForm/ForgetPasswordFrom/ForgetPasswordFrom";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";

const ForgetPassword = (props: any) => {
  return (
    <LandingLayout>
      <AuthLayout>
        <ForgetPasswordFrom />
      </AuthLayout>
    </LandingLayout>
  );
};

export default ForgetPassword;
