/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { Edit2, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import Table, { TableHeading } from "../../../../shared/Table/Table";
import {
  deleteGroup,
  getAllGroups,
} from "../../../../store/features/Tenant/Orgination/Group/GroupApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";
import Empty from "../../../../shared/Empty/Empty";

function GroupContent(props: any) {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [currentGroupId, setCurrentGroupId] = useState(0);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });

  useEffect(() => {
    dispatch(getAllGroups());
  }, []);
  useEffect(() => {
    if (!groupState.loading.delete && groupState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [groupState]);

  const handleEdit = (id: number) => {
    navigate(`/edit-groups/${id}`);
  };
  return (
    <>
      <div style={{ minHeight: "450px" }}>
        <Table
          heading={
            <TableHeading>
              <th>Name</th>
              <th>Members</th>
              <th>Created on</th>
              <th className="hide__text">.</th>
            </TableHeading>
          }
        >
          <tbody>
            {!groupState.loading.list &&
              groupState.req_success.list &&
              groupState.groups.map((group: any) => {
                return (
                  <tr
                    className="faq_tr"
                    style={{ height: "72px" }}
                    key={group.id}
                  >
                    <td
                      onClick={() => navigate(`/view-groups/${group.id}`)}
                      className="group__name__td"
                    >
                      {group.name}
                    </td>

                    <td className="member__count">{group.users_count}</td>
                    <td>{group.created_at}</td>

                    <td className="drag__faq__action__icons ">
                      <div
                        className="faq_drag_test_4544"
                        style={{ justifyContent: "flex-end" }}
                      >
                        {" "}
                        <Edit2
                          className="edit__icon"
                          onClick={() => {
                            handleEdit(group.id);
                          }}
                        />
                        <Trash
                          className="delete__icon"
                          onClick={() => {
                            setDeleteShowModal(true);
                            setCurrentGroupId(group.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>

        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setDeleteShowModal}
          loading={groupState.loading.delete}
          onClick={() => dispatch(deleteGroup({ groupId: currentGroupId }))}
        />
        {!groupState.loading.list &&
          groupState.req_success.list &&
          groupState.groups.length === 0 && <Empty custombg emptyGroup />}
      </div>
      {/* <div>test</div> */}
    </>
  );
}

export default GroupContent;
