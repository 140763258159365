import React from "react";

const VideoProcessing = () => {
  return (
    <div className="camera-video">
      <div className="video-spinner" />
      <p className="text-center">
        Your response is currently loading. Thank you for your patience,
        <br /> it may take a few minutes to load
      </p>
    </div>
  );
};

export default VideoProcessing;
