import React from "react";
import { MessageSquare, Plus, X } from "react-feather";
import "./mobilechatsidebar.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  clearConversation,
  fetchConversation,
} from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import { openNewChat } from "../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";

const MobileChatQuestionSidebar = (props: any) => {
  const { showChatQuestionSlider, setShowQuestionChatSlider } = props;
  const dispatch = useAppDispatch();

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  const handleConversation = (id: string) => {
    if (aiState.loading.question) {
      return;
    }
    dispatch(fetchConversation({ id: id }));
    setShowQuestionChatSlider(false);
  };

  const clearAllConversation = () => {
    dispatch(clearConversation());
  };

  const openNewChatAndCloseSidebar = () => {
    dispatch(openNewChat());
    setShowQuestionChatSlider(false);
  };

  return (
    <div
      className={`mobile__chat__question__sidebar ${
        showChatQuestionSlider ? "active" : ""
      }`}
    >
      <X
        className="cancel__icon"
        onClick={() => {
          setShowQuestionChatSlider(false);
        }}
      />
      <div className="mobile__question__sidebar">
        <div className="question__sidebar__wrapper">
          <div className="question__header">Recent Conversations</div>
          <div className="question__sidebar__container">
            <div className="question__wrapper mobile__view__question__wrapper">
              {aiState.req_success.all_conversation &&
                aiState.all_conversation.map((conversation: any) => {
                  return (
                    <div className="question__div" key={conversation.uniqueId}>
                      <div
                        className="question__item"
                        role="button"
                        aria-hidden
                        onClick={() =>
                          handleConversation(conversation.uniqueId)
                        }
                      >
                        <div className="message__icon__handle">
                          <MessageSquare className="square__messagebox" />
                        </div>
                        <span>{conversation.question} </span>
                      </div>
                      <div className="question__underline" />
                    </div>
                  );
                })}
            </div>
            {aiState.all_conversation.length > 0 && (
              <div className="clear__all">
                <button
                  type="button"
                  onClick={clearAllConversation}
                  disabled={
                    aiState.loading.question || aiState.loading.current_answer
                  }
                >
                  Clear conversations
                </button>
              </div>
            )}

            <div className="button__wrapper">
              <button
                type="button"
                disabled={
                  aiState.loading.current_answer || aiState.loading.question
                }
                onClick={() => openNewChatAndCloseSidebar()}
              >
                <Plus />
                New Chat
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileChatQuestionSidebar;
