/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import InputContainer from "../../Layouts/Input/InputContainer";
import ErrorText from "../ErrorText/ErrorText";
import { InputLabel } from "../InputLabel/InputLabel";
import "./arraylist.scss";

// const Tag = (props: any) => {
//   const { text } = props;
//   return (
//     <div className="al_tag">
//       {text}{" "}
//       <button type="button">
//         <X size={15} />
//       </button>
//     </div>
//   );
// };

function ArrayList(props: any) {
  const {
    name,
    label,
    error,
    register,
    dataArray,
    updateDataArray,
    noinput,
    ...rest
  } = props;
  const [txt, setTxt] = useState("");
  // const [data, updateDataArray] = useState(dataArray);
  const errorMessage = error?.message;
  const inputRef = useRef<any>(null);

  const handleChange = (e: any) => {
    setTxt(e.target.value);
  };
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (txt.trim().length) {
        if (dataArray) {
          updateDataArray([...dataArray, txt.trim()]);
        } else {
          updateDataArray([txt.trim()]);
        }

        setTxt("");
      }
    }
  };
  const removeTag = (index: any) => {
    const newArr = dataArray.filter((e: any, i: any) => {
      return i !== index;
    });
    updateDataArray(newArr);
  };
  const handleContainerClick = (e: any) => {
    inputRef.current!.focus();
    e.stopPropagation();
  };

  return (
    <div>
      <InputContainer>
        <InputLabel name={name} text={label} />
        <div className="al_container" onClick={handleContainerClick}>
          {dataArray !== null &&
            dataArray?.map((e: any, i: any) => {
              return (
                <div className="al_tag">
                  {e}{" "}
                  <button type="button" onClick={() => removeTag(i)}>
                    <X size={15} />
                  </button>
                </div>
              );
            })}
          {!noinput && (
            <input
              type="text"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={txt}
              ref={inputRef}
            />
          )}
        </div>

        <ErrorText error={errorMessage} />
      </InputContainer>
    </div>
  );
}

export default ArrayList;
