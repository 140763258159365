/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
import { useEffect, useRef, useState } from "react";
import JoditEditor from "jodit-react";

import { PlanButton, PrimaryButton } from "../../../shared/Buttons/Buttons";
import TutionsPlan from "./TutionsPlan";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  addOrUpdateDescription,
  getDescription,
  getTutionPlans,
  deletePlans,
} from "../../../store/features/AdminPanel/TutionPlans/TutionPlanApi";
import DeleteModal from "../../../shared/Modals/DeleteModal/DeleteModal";
import ErrorText from "../../../shared/Inputs/ErrorText/ErrorText";
import { removeTags } from "../../../helpers/utils/StringHelper";

const TutionsPlans = () => {
  const dispatch = useAppDispatch();
  const tutionState: any = useAppSelector((store: RootStore) => {
    return store.TutionPlansSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const config: any = {
    readOnly: false,
    placeholder: "write something here.....",
    height: 300,
    toolbarSticky: false,
    buttons: "bold,italic,underline,strikethrough,fontsize,link",
    theme: themeState.themeMode === "dark" ? "dark" : "default",
  };
  const editor = useRef(null);
  const bottomRef = useRef<any>(null);
  const [content, setContent] = useState("");
  const [plans, setPlans] = useState<any>([]);
  const [editDescription, setEditDescription] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [deletePlanId, setDeletePlanId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handlePlans = () => {
    setPlans([...plans, 1]);
  };

  const submitDescription = () => {
    const char = removeTags(content);
    if (char.length < 1) {
      setErrorMessage("Description is required");
      return;
    } else {
      setErrorMessage("");
    }
    let data: any = {};
    if (editDescription) {
      data = { content: content, id: tutionState.description.id };
    } else {
      data = { content: content };
    }

    dispatch(addOrUpdateDescription(data));
  };

  useEffect(() => {
    dispatch(getDescription());
    dispatch(getTutionPlans());
  }, []);
  useEffect(() => {
    if (tutionState.req_success.get_description) {
      setContent(tutionState.description.content);
    }
    setEditDescription(true);
  }, [tutionState.req_success.get_description]);
  useEffect(() => {
    if (tutionState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [tutionState.req_success.delete]);
  useEffect(() => {
    if (tutionState.req_success.add) {
      const newArr = plans.filter((e: any, i: any) => {
        return i !== index;
      });
      setPlans(newArr);
      setIndex(0);
    }
  }, [tutionState.req_success.add]);
  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    if (plans.length > 0) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [plans]);
  return (
    <div className="tutions__plans__section">
      <div className="plans__card">
        {" "}
        <span className="title mb_3">Section ‘Tuition Options’</span>
        <span className="description__title"> Description</span>
        <span className="description__title__hint">
          {" "}
          Reflected below section title
        </span>
        <div className="editor__wrapper">
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={(newContent) => setContent(newContent)}
          />
          <ErrorText error={errorMessage} />
        </div>
        <div className="desc__buttons">
          <PrimaryButton
            onClick={submitDescription}
            text={tutionState?.description?.content ? "Update" : "Submit"}
            type="button"
            loading={tutionState.loading.add_description}
          />
        </div>
      </div>
      <div className="title_and_button_section">
        <span className="title">Plans</span>
        <div className="plans__button__width">
          <PlanButton text="Add new" onClick={handlePlans} />
        </div>
      </div>

      {tutionState.req_success.get &&
        tutionState.plans.map((plan: any) => {
          return (
            <TutionsPlan
              key={plan.id}
              data={plan}
              setDeleteShowModal={setDeleteShowModal}
              setDeletePlanId={setDeletePlanId}
            />
          );
        })}

      {plans.map((plan: any, i: number) => {
        return (
          <TutionsPlan
            key={i + 10}
            setDeleteShowModal={setDeleteShowModal}
            setDeletePlanId={setDeletePlanId}
            setIndex={setIndex}
            i={i}
            plans={plans}
            setPlans={setPlans}
          />
        );
      })}
      <div ref={bottomRef} />

      {plans.length === 0 && tutionState.plans.length === 0 && (
        <div className="plans__card empty">
          <span className="empty__text">
            No plans created.<span className="colorful_text"> Add a plan.</span>{" "}
          </span>
        </div>
      )}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={tutionState.loading.delete}
        onClick={() => dispatch(deletePlans({ id: deletePlanId }))}
      />
    </div>
  );
};

export default TutionsPlans;
