import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

import FileUploader from "../Uploader/FileUploader";
import "./index.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  CreateFolder,
  getData,
} from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerApi";
import {
  closeShowInfoModal,
  searchFilesAndFolder,
  setActiveSlice,
  setUploaderScreen,
} from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerSlice";

const LearningResourceHeader = (props: any) => {
  const dispatch = useAppDispatch();
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [totalUploadedFile, setTotalUploadedFile] = useState(0);

  const resourceState: any = useAppSelector((store: RootStore) => {
    return store.ResourceManagerSlice;
  });
  useEffect(() => {
    dispatch(searchFilesAndFolder(searchText));
  }, [searchText]);
  const validationSchema = yup.object().shape({
    folder_name: yup.string().required(REQUIRED_VALIDATION("Folder Name")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    const data = { ...values, parent_id: resourceState.nested_folder_id };

    dispatch(CreateFolder(data));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  const handleShowInfoModal = () => {
    dispatch(closeShowInfoModal(false));
    dispatch(setActiveSlice(0));
  };

  useEffect(() => {
    dispatch(getData());
  }, []);

  useEffect(() => {
    if (resourceState.req_success.folder.add) {
      setShowFolderModal(false);
      reset();
    }
    if (resourceState.req_success.data.add) {
      setShowFolderModal(false);
      reset();
    }
  }, [
    resourceState.req_success.folder.add,
    resourceState.req_success.data.add,
  ]);

  useEffect(() => {
    if (resourceState.show_info) {
      setShowModal2(true);
    }
  }, [resourceState.show_info]);

  const clearUploader = () => {
    setShowModal3(false);
    setTotalUploadedFile(0);
    dispatch(setUploaderScreen());
  };
  const handleModal = () => {
    reset();
    setTotalUploadedFile(0);
    dispatch(setUploaderScreen());
  };

  return (
    <div className="resource__manager__header">
      {" "}
      <div className="left__section">
        <TableHeadingTitle>Learning Resources</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__section">
          <div className="button__witdh_sm">
            <PrimaryButton
              id="sec-btn-dsgn"
              text="Upload File"
              size="sm"
              type="button"
              loading={false}
              onClick={() => setShowModal3(true)}
            />
          </div>
          <div className="button__witdh">
            <PrimaryButton
              text="Create a Folder"
              size="sm"
              type="button"
              loading={false}
              onClick={() => setShowFolderModal(true)}
            />
          </div>
        </div>
      </div>
      <Modal
        show={showFolderModal}
        setShow={setShowFolderModal}
        onClick={handleModal}
      >
        <ModalHeader title="Create Folder" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form_row">
              <TextField
                type="text"
                label="Folder Name"
                name="folder_name"
                register={register}
                error={errors?.folder_name}
              />
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton
                text="Create"
                type="submit"
                loading={resourceState.loading.folder.add}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        show={showModal2}
        setShow={setShowModal2}
        onClick={handleShowInfoModal}
      >
        <ModalHeader
          title={
            resourceState.single_file_folder_info.resource_type === "file"
              ? "File Info"
              : "Folder Info"
          }
        />
        <ModalBody>
          <div className="file_info__section">
            <div className="detail_row">
              <span className="property_title">Modified Date :</span>
              <span className="property_detail">
                {resourceState.single_file_folder_info.last_modified}
              </span>
            </div>
            <div className="detail_row">
              <span className="property_title">Size :</span>
              <span className="property_detail">
                {resourceState.single_file_folder_info.files_size}
              </span>
            </div>
            {resourceState.single_file_folder_info.resource_type === "file" && (
              <div className="detail_row">
                <span className="property_title">Extension : </span>
                <span className="property_detail">
                  {" "}
                  {
                    resourceState.single_file_folder_info.resource_extension
                  }{" "}
                </span>
              </div>
            )}
            <div className="detail_row">
              <span className="property_title">Name : </span>
              <span className="property_detail">
                {resourceState.single_file_folder_info.resource_name}{" "}
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        show={showModal3}
        setShow={setShowModal3}
        onClick={handleModal}
        hideCloseButton={resourceState.disable_upload_screen}
      >
        <ModalHeader title="File Upload" />
        <ModalBody>
          <FileUploader
            resourceState={resourceState}
            totalUploadedFile={totalUploadedFile}
            setTotalUploadedFile={setTotalUploadedFile}
          />
          <div className="button__witdh mt-2 sm-8">
            {!resourceState.disable_upload_screen && (
              <PrimaryButton
                text="Done"
                type="submit"
                loading={false}
                onClick={clearUploader}
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LearningResourceHeader;
