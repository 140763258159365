import React from "react";
import { ToastContainer } from "react-toastify";

import AdminPanelHeader from "../AdminPanelHeader/AdminPanelHeader";
import "./index.scss";
import AdminHeaderWithLogoOnly from "../AdminPanelHeader/AdminHeaderWithLogoOnly";

const AdminPanelLoyoutWithoutSidebar = (props: any) => {
  const { children } = props;
  return (
    <div className="adminPanelLayout__without__sidebar__container">
      {/* <div className="sidebar"></div> */}
      <div className="content__container">
        <div className="header__section">
          <AdminHeaderWithLogoOnly />
          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        <div className="content__section">{children}</div>
      </div>
    </div>
  );
};

export default AdminPanelLoyoutWithoutSidebar;
