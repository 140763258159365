/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import "./archivedatetime.scss";
import { Trash } from "react-feather";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import DeleteModal from "../../../../../shared/DeleteModal/DeleteModal";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../../shared/Table/Table";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import { deleteWebinar } from "../../../../../store/features/AdminPanel/Webinar/WebinarApi";
import { changeWebinarType } from "../../../../../store/features/AdminPanel/Webinar/WebinarSlice";

const ArchiveDateTimeTable = () => {
  const dispatch = useAppDispatch();

  const webinarState: any = useAppSelector((store: RootStore) => {
    return store.WebinarSlice;
  });

  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [deletingId, setDeletingId] = useState(0);

  const openDeleteModal = (data: any) => {
    setDeleteShowModal(true);
    setDeletingId(data);
  };

  const deleteWebinarHandler = () => {
    dispatch(changeWebinarType("archive"));
    dispatch(deleteWebinar({ id: deletingId }));
  };

  useEffect(() => {
    if (webinarState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [webinarState.loading.delete]);

  return (
    <div className="archive_date_time">
      <div className="webinar_date_time_header_container">
        <div className="left__section">
          <TableHeadingTitle>Date & Time (Archive)</TableHeadingTitle>
        </div>
      </div>
      <div>
        <Table
          heading={
            <TableHeading>
              <th className="width_handler_th"> Date</th>
              <th> Time</th>
              <th className="hide__text ">.</th>
            </TableHeading>
          }
        >
          <TableBody>
            {webinarState.req_success.get &&
              webinarState.archive_webinars.length > 0 &&
              webinarState.archive_webinars.map((webinar: any) => {
                return (
                  <tr key={webinar.id}>
                    <td>{webinar.date}</td>
                    <td>{webinar.time}</td>
                    <td className="delete_icon_td">
                      <Trash
                        className="delete_icon"
                        onClick={() => openDeleteModal(webinar.id)}
                      />
                    </td>
                  </tr>
                );
              })}
            {webinarState.req_success.get &&
              webinarState.archive_webinars.length === 0 && (
                <tr>
                  <td className="padding_empty" colSpan={3}>
                    <p>No Archive Webinars.</p>
                  </td>
                </tr>
              )}
          </TableBody>
        </Table>

        <DeleteModal
          showModal={showDeleteModal}
          loading={webinarState.loading.delete}
          setShowModal={setDeleteShowModal}
          onClick={deleteWebinarHandler}
        />
      </div>
    </div>
  );
};

export default ArchiveDateTimeTable;
