import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { viewCourseApi } from "../../../store/features/AdminPanel/ViewCourses/ViewCoursesApi";
import Table, { TableHeading } from "../../../shared/Table/Table";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import "./LearningPathCourseTable.scss";
import Empty from "../../../shared/Empty/Empty";

const LearningPathCourseTable = () => {
  const params: any = useParams();

  const dispatch = useAppDispatch();

  const viewCourseState: any = useAppSelector((store: RootStore) => {
    return store.ViewCoursesSlice;
  });

  useEffect(() => {
    dispatch(viewCourseApi({ path_id: Number(params.path_id) }));
  }, []);

  return (
    <div className="learning_table__container">
      <Table
        heading={
          <TableHeading>
            <th>Course Name</th>
            <th>Status</th>
          </TableHeading>
        }
      >
        <tbody className="learning_course_table_body">
          {!viewCourseState.loading &&
            viewCourseState.viewCourse?.map((course: any, index: number) => (
              <tr key={course.id}>
                <td className="learning_course_name_td">
                  {course.course_name}
                </td>

                <td
                  className={` ${
                    course.is_published
                      ? "learning_course_status_td  "
                      : " learning_course_status_td "
                  }`}
                >
                  <div
                    className={` ${
                      course.is_published ? "  published" : "  un_published"
                    }`}
                  >
                    <span className="dot">.</span>{" "}
                    <span>{course.is_published ? "Published" : "Draft"}</span>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {! viewCourseState.loading &&
            viewCourseState.viewCourse?.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
         )}
    </div>
  );
};

export default LearningPathCourseTable;
