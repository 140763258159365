import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getAllUserCourses, getLps } from "./NewUserCoursesAndLearningPathApi";

const initialState: any = {
  loading: false,
  req_success: false,
  filter_active: false,
  learningPaths: [],
  all_learningPaths: [],
  courses: [],
  all_courses: [],
};
// listLearningPath;
const NewUserCoursesAndLearningPathSlice = createSlice({
  name: "NewUserCoursesAndLearningPathSlice",
  initialState,
  reducers: {
    searchPath: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.learningPaths = state.all_learningPaths.filter(
          (path: any) =>
            path.name.toLowerCase().includes(payload.toLowerCase()) ||
            path.path_id.toString().includes(payload)
        );
      } else {
        state.learningPaths = state.all_learningPaths;
      }
    },
    updateCoursesList: (state: any, { payload }: PayloadAction<any>) => {
      state.courses = payload;
    },
    updateFilterActive: (state: any, { payload }: PayloadAction<any>) => {
      state.filter_active = payload;
    },
  },
  extraReducers: (builder) => {
    // GET ALL Learning Path
    builder.addCase(getLps.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getLps.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_learningPaths = payload?.data;
        state.learningPaths = payload?.data;
      }
    );
    builder.addCase(getLps.rejected, (state) => {
      state.loading = false;
    });

    // GET ALL COURSES
    builder.addCase(getAllUserCourses.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getAllUserCourses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_courses = payload?.data;
        state.courses = payload?.data;
      }
    );
    builder.addCase(getAllUserCourses.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const { searchPath, updateCoursesList, updateFilterActive } =
  NewUserCoursesAndLearningPathSlice.actions;
export default NewUserCoursesAndLearningPathSlice.reducer;
