import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAdminDashboardStats } from "./DashboardApi";

const initialState: any = {
  loading: false,
  req_success: false,
  data: {},
};

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET dashboard stats
    builder.addCase(getAdminDashboardStats.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getAdminDashboardStats.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.data = payload.data;
      },
    );
    builder.addCase(getAdminDashboardStats.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default DashboardSlice.reducer;
