/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  ChevronDown,
  ChevronsDown,
  Delete,
  RefreshCw,
  Trash,
  Trash2,
} from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import CheckBox from "../../../../../shared/Inputs/CheckBox/CheckBox";
import SelectField from "../../../../../shared/Inputs/SelectField/SelectField";
import TextField from "../../../../../shared/Inputs/TextField/TextField";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../../shared/Modals/Modal";
import { REQUIRED_VALIDATION } from "../../../../../helpers/utils/formUtils";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  courseAssismentUpdateDadeline,
  deleteIndividualAssignment,
  getMemberDetails,
} from "../../../../../store/features/Tenant/Orgination/Member/MemberApi";
import DeleteModal from "../../../../../shared/Modals/DeleteModal/DeleteModal";
import ErrorText from "../../../../../shared/Inputs/ErrorText/ErrorText";

const SingleAssignment = (props: any) => {
  const {
    title,
    deleteIcon,
    emptyTitle,
    filter,
    addAssignment,
    assignmentState,
    yesPath,
    YesCourse,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [assignmentDetail, setAssignmentDetail] = useState<any>({});
  const [showFilter, setShowFilter] = useState(false);
  const [selectedOption, setSelection] = useState("All");
  const [checkUpdateCondation, setCheckUpdateCondation] = useState("");
  const [override, setOverride] = useState(false);
  const [overrideError, setOverrideError] = useState("");
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);

  const [activeCourse, setActiveCourse] = useState<any>("");
  const [activeIdForDelete, setActiveIdForDelete] = useState<any>("");
  const [groupPaths, setGroupPaths] = useState(
    addAssignment && yesPath ? [] : assignmentState.group[0].learning_paths,
  );
  const [coursesAssignments, setCoursesAssignments] = useState(
    addAssignment && YesCourse ? [] : assignmentState.group[0].courses,
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const memberState: any = useAppSelector((store: RootStore) => {
    return store.MemberSlice;
  });

  const handleModal = () => {
    // setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const optionsFilter = (group: any) => {
    setSelection(group.name);
    setShowFilter(false);
    if (yesPath) setGroupPaths(group.learning_paths);
    if (YesCourse) setCoursesAssignments(group.courses);

    //
  };

  const validationSchema = yup.object().shape({
    deadline: yup.string().required(REQUIRED_VALIDATION("Date")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const onSubmit = (value: any) => {
    if (!override) {
      setOverrideError("Override option is required");
      return;
    }
    const data: any = {
      deadline: value.deadline,
      course_id: activeCourse.id,
      override: override ? 1 : 0,
    };
    if (checkUpdateCondation === "course_group") {
      data.group_id = activeCourse.group_id;
    }

    dispatch(
      courseAssismentUpdateDadeline({
        data: data,
        memberId: memberState.member.user.id,
      }),
    );
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  const openCourseUpdateModal = (course: any, arg: string) => {
    setShowModal(true);
    setReloadPage(true);
    setCheckUpdateCondation(arg);
    setValue("deadline", course.deadline);
    setActiveCourse(course);
  };

  useEffect(() => {
    if (
      (memberState.req_success.deadline_update ||
        memberState.req_success.individual_delete) &&
      reloadPage
    ) {
      setReloadPage(false);
      dispatch(getMemberDetails({ memberId: Number(params.id) }));
    }
  }, [memberState.req_success]);

  const handleOverrideOption = () => {
    setOverrideError("");
    setOverride(!override);
  };
  const openDetailModel = (course: any) => {
    setShowDetailModal(true);
    setAssignmentDetail(course);
  };

  return (
    <div className="single__assignment__section">
      <div className="title__section">
        <span className="title">{title}</span>
        {filter && (
          <div className="filter__section">
            <span className="show__filter__text">Show</span>

            <div className="filter__box">
              <span className="filter__title">{selectedOption}</span>
              <ChevronDown
                className="down__icon"
                onClick={() => setShowFilter(!showFilter)}
              />

              {showFilter && (
                <div className="filter__option__card">
                  {assignmentState.group.map((group: any, index: number) => {
                    return (
                      <span
                        className="filter__item"
                        key={group.id}
                        onClick={() => optionsFilter(group)}
                      >
                        {group.name}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {addAssignment && (
          <span
            className="add__assignment__text"
            onClick={() => navigate("/manage-assignments")}
          >
            Add assignment
          </span>
        )}
      </div>

      {/* <div className="empty__text">{emptyTitle}</div> */}

      <div className="data__section">
        <div className={`heading__section ${deleteIcon && "align__heading"}`}>
          <span>
            {yesPath && "Path Name"} {YesCourse && "Course Name"}
          </span>{" "}
          <span>Deadline</span>
        </div>
        {!deleteIcon &&
          yesPath &&
          groupPaths.length > 0 &&
          groupPaths.map((path: any) => {
            return (
              <div key={path.id}>
                <div className="data__divider" />
                <div className="single__data__item" key={path.id}>
                  <div className="row">
                    <span className="course__name">{path.name}</span>
                  </div>
                  <div className="row ">
                    <div className="course__porgress__section">
                      <ProgressBar
                        completed={path.avg_progress}
                        height="3px"
                        customLabel="."
                        baseBgColor="var( --primary-progress-bar-bg)"
                        bgColor="var( --secondary-progress-bar-bg)"
                        labelSize="0"
                        width="150px"
                      />
                      <RefreshCw
                        className="sync__icon"
                        onClick={() =>
                          navigate(`/edit-groups/${path.group_id}`)
                        }
                      />
                    </div>
                  </div>
                  <span className="completed__text__precent">
                    {Math.floor(path.avg_progress)}% completed
                  </span>
                </div>
              </div>
            );
          })}
        {deleteIcon &&
          yesPath &&
          assignmentState.individual.length > 0 &&
          assignmentState.individual.map((course: any) => {
            return (
              <div key={course.id} className="align__assignment">
                <div className="data__divider" />
                <div className="single__data__item" key={course.id}>
                  <div className="row">
                    <span className="course__name">{course.name}</span>
                  </div>
                  <div className="row">
                    <div className="course__porgress__section">
                      <ProgressBar
                        completed={course.avg_progress}
                        height="3px"
                        customLabel="."
                        baseBgColor="var( --primary-progress-bar-bg)"
                        bgColor="var( --secondary-progress-bar-bg)"
                        labelSize="0"
                        width="150px"
                      />
                      <div className="icon__section">
                        <div className="tool_section_for_hover">
                          <RefreshCw
                            id="eye"
                            className="sync__icon user_resoure_icon eye_icon_hover"
                            onClick={() => openDetailModel(course)}
                          />

                          <span className="preview_tool_tip">Update</span>
                          <div className="preview_arrow_tool_tip" />
                        </div>

                        <div className="tool_section_for_hover">
                          <Trash
                            className="user_resoure_icon  download_icon_hover trash__icon"
                            onClick={() => {
                              setDeleteShowModal(true);
                              setCheckUpdateCondation("course_group");
                              setActiveIdForDelete(course.id);
                              setReloadPage(true);
                            }}
                          />
                          <span className="download_tool_tip">Delete</span>
                          <div className="download_arrow_tool_tip" />
                        </div>
                        {/* <Trash
                          className="trash__icon"
                          onClick={() => {
                            setDeleteShowModal(true);
                            setCheckUpdateCondation("course_group");
                            setActiveIdForDelete(course.id);
                            setReloadPage(true);
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <span className="completed__text__precent">
                    {Math.floor(course.avg_progress)}% completed
                  </span>
                </div>
              </div>
            );
          })}

        {!deleteIcon &&
          YesCourse &&
          coursesAssignments.length > 0 &&
          coursesAssignments.map((path: any) => {
            return (
              <div key={path.id}>
                <div className="data__divider" />

                <div className="single__data__item" key={path.id}>
                  <div className="row">
                    <span className="course__name">{path.name}</span>
                    <div className="date__and__delte__section">
                      <span className="course__name">{path.deadline}</span>
                      {deleteIcon && <Trash className="trash__icon" />}
                    </div>
                  </div>
                  <div className="row ">
                    <div className="course__porgress__section margin-top">
                      <ProgressBar
                        completed={path.percentage_completed}
                        height="3px"
                        customLabel="."
                        baseBgColor="var( --primary-progress-bar-bg)"
                        bgColor="var( --secondary-progress-bar-bg)"
                        labelSize="0"
                        width="150px"
                      />
                      <RefreshCw
                        className="sync__icon"
                        onClick={() =>
                          openCourseUpdateModal(path, "course_group")
                        }
                      />
                    </div>
                  </div>
                  <span className="completed__text__precent">
                    {Math.floor(path.percentage_completed)}% completed
                  </span>
                </div>
              </div>
            );
          })}

        {deleteIcon &&
          YesCourse &&
          assignmentState.individual.length > 0 &&
          assignmentState.individual.map((course: any) => {
            return (
              <div key={course.id}>
                <div className="data__divider" />
                <div className="single__data__item" key={course.id}>
                  <div className="row">
                    <span className="course__name">{course.name}</span>
                    <div className="date__and__delte__section">
                      <span className="course__name">{course.deadline}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="course__porgress__section margin-top">
                      <ProgressBar
                        completed={course.percentage_completed}
                        height="3px"
                        customLabel="."
                        baseBgColor="var( --primary-progress-bar-bg)"
                        bgColor="var( --secondary-progress-bar-bg)"
                        labelSize="0"
                        width="150px"
                      />
                      {/* <div className="icon__section">
                        <RefreshCw
                          className="sync__icon"
                          onClick={() =>
                            openCourseUpdateModal(course, "indivisual_course")
                          }
                        />
                        <Trash
                          className="trash__icon"
                          onClick={() => {
                            setDeleteShowModal(true);
                            setCheckUpdateCondation("indivisual_course");
                            setActiveIdForDelete(course.id);
                            setReloadPage(true);
                          }}
                        />
                      </div> */}
                      <div className="icon__section">
                        <div className="tool_section_for_hover">
                          <RefreshCw
                            id="eye"
                            className="sync__icon user_resoure_icon eye_icon_hover"
                            onClick={() =>
                              openCourseUpdateModal(course, "indivisual_course")
                            }
                          />

                          <span className="preview_tool_tip">Update</span>
                          <div className="preview_arrow_tool_tip" />
                        </div>

                        <div className="tool_section_for_hover">
                          <Trash
                            className="user_resoure_icon  download_icon_hover trash__icon"
                            onClick={() => {
                              setDeleteShowModal(true);
                              setCheckUpdateCondation("indivisual_course");
                              setActiveIdForDelete(course.id);
                              setReloadPage(true);
                            }}
                          />
                          <span className="download_tool_tip">Delete</span>
                          <div className="download_arrow_tool_tip" />
                        </div>
                        {/* <Trash
                          className="trash__icon"
                          onClick={() => {
                            setDeleteShowModal(true);
                            setCheckUpdateCondation("course_group");
                            setActiveIdForDelete(course.id);
                            setReloadPage(true);
                          }}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <span className="completed__text__precent">
                    {Math.floor(course.percentage_completed)}% completed
                  </span>
                </div>
              </div>
            );
          })}
      </div>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={memberState.loading.individual_delete}
        onClick={() => {
          const data: any = {};
          if (checkUpdateCondation === "course_group") {
            data.learning_path_id = activeIdForDelete;
          }
          if (checkUpdateCondation === "indivisual_course") {
            // data = "course_id";
            data.course_id = activeIdForDelete;
          }
          dispatch(
            deleteIndividualAssignment({
              id: memberState.member.user.id,
              data: data,
            }),
          );
        }}
      />
      <Modal
        show={showModal}
        setShow={setShowModal}
        // resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader title="Update deadline" />
        <ModalBody>
          <form
            className="user__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <span className="member__course__detail"> Course detail</span>
            <span className="member__course__name"> {activeCourse?.name}</span>
            <TextField
              type="date"
              label="Set a deadline"
              name="deadline"
              register={register}
              error={errors?.deadline}
              min={new Date().toISOString().split("T")[0]}
            />
            <div className="date__override__section">
              <CheckBox
                name="ovveride"
                label="Override existing deadline"
                value={override}
                fullwidth
                sm
                onClick={handleOverrideOption}
              />
              <ErrorText error={overrideError} />
            </div>

            <div className="member__modal_footer">
              <div>
                <PrimaryButton
                  text="Update"
                  type="submit"
                  loading={memberState.loading.deadline_update}
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        show={showDetailModal}
        setShow={setShowDetailModal}
        // resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader title={assignmentDetail.name} />
        <ModalBody>
          <div className="assignment__modal__detail__wrapper">
            <div className="assignment__header__section">
              <span className="title"> Course Name </span>
              <span className="title"> Deadline </span>
            </div>
            <div className="divider" />
            <div className="content__wrapper">
              {showDetailModal &&
                assignmentDetail.courses.map((course: any) => {
                  return (
                    <div className="single__content" key={course.id}>
                      <div className="content">
                        <span className="course__title">{course.name}</span>
                        <span className="course__title">
                          {course.deadline ?? "-"}
                        </span>
                      </div>
                      <div className="divider" />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="member__modal_footer">
            <div>
              <PrimaryButton
                text="Update"
                type="submit"
                onClick={() =>
                  navigate(
                    `/update-assignments/${params.id}/${assignmentDetail.id}`,
                  )
                }
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SingleAssignment;
