import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import OurExpertsHeader from "../../../components/AdminPanel/OurExperts/OurExpertsHeader";
import OurExpertTable from "../../../components/AdminPanel/OurExperts/OurExpertTable/OurExpertTable";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const OurExperts = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
     <div className="mt-7">
     <OurExpertsHeader />
        <OurExpertTable />
     </div>
      </BoxShadow>
      {/* <TeamsFooter /> */}
    </AdminPanelLayout>
  );
};

export default OurExperts;
