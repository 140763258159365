import React, { useEffect, useState } from "react";
import "react-tabs/style/react-tabs.css";
import "./webinar.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import WebinarDateTimeHeader from "../WebinarHeader/WebinarDateTimeHeader/WebinarDateTimeHeader";
import DateTimeTable from "../WebinarDateTimeTable/DateTimeTable/DateTimeTable";
import UserTable from "../WebinarDateTimeTable/UserTable/UserTable";
import UserHeader from "../WebinarHeader/UserHeader/UserHeader";
import ArchiveDateTimeTable from "../WebinarDateTimeTable/DateTimeTable/ArchiveDateTimeTable";
import {
  getWebinars,
  getWebinarUsers,
} from "../../../../store/features/AdminPanel/Webinar/WebinarApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

const WebinarDateTimeComp = () => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();

  const webinarState: any = useAppSelector((store: RootStore) => {
    return store.WebinarSlice;
  });

  useEffect(() => {
    dispatch(getWebinars());
    dispatch(getWebinarUsers());
  }, []);
  return (
    <div className="webinar_date_time__container mt-7">
      <Tabs className="webinar_date_time_tab__container">
        <TabList className="webinar_date_time_tab__list__section">
          <Tab className="webinar_date_time_single__tab ">Date & Time</Tab>
          <Tab className="webinar_date_time_single__tab ml-3">Users</Tab>
        </TabList>

        <TabPanel>
          <div className="webinar_date_time__header">
            <WebinarDateTimeHeader
              upcoming_webinar={webinarState?.upcoming_webinar}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </div>
          <DateTimeTable
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            showModal={showModal}
            setShowModal={setShowModal}
          />
          <ArchiveDateTimeTable />
        </TabPanel>
        <TabPanel>
          <div className="webinar_date_time__header">
            <UserHeader />
          </div>
          <UserTable />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default WebinarDateTimeComp;
