import React, { useEffect, useState } from "react";
import "./toursInfoPopup.scss";
import { ArrowLeft, ArrowUp, ChevronLeft, X } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import Store, { RootStore, useAppDispatch, useAppSelector } from "../../../../../store/store";
import { closeShowPopup, defaultProgress, goTostepOne, setShowInnerPopupFalse, setShowPopup, setcurrentStep } from "../../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";
import { webGuideOnboarding } from "../../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnBoardingPopupApi";
import { loadUser } from "../../../../../store/features/Auth/AuthApi";

const TourInfoPopup = (props: any) => {
  const[autheFail,setAuthFail] = useState(false)
  const {title,message,handleOnClickBack,handleOnClickNext, id, selectedRoleId, selectedRole,showWarning,interviewState} = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { currentStep,second_step ,progress} = useAppSelector((store: RootStore) => store.ShowPopupSlice);
  // For now If path is /interview-preparation and selected role is undefined and currentStep===1 the set currentStep 1 to 3
useEffect(()=>{
  if(location.pathname.includes("/interview-preparation") && selectedRole=== undefined && currentStep===1){
    dispatch(setcurrentStep(3));
    dispatch(defaultProgress(currentStep))
  }

},[location.pathname.includes("/interview-preparation") && selectedRole=== undefined && currentStep===1]);

useEffect(()=>{
  dispatch(defaultProgress(currentStep));
},[]);

useEffect(() => {
  const handlePopstate = () => {
    dispatch(goTostepOne());
    dispatch(defaultProgress(currentStep));
  };
  window.addEventListener("popstate", handlePopstate);
}, []);

const closeTourPopup = ()=>{
  dispatch(setShowInnerPopupFalse());
  dispatch(closeShowPopup());
  dispatch(webGuideOnboarding(
    {current_step: currentStep,
      is_completed: true}
)).then(()=>dispatch(loadUser({setAuthFail:setAuthFail})));

}

useEffect(()=>{
  if(currentStep !== 1){
    dispatch(webGuideOnboarding({current_step: currentStep,is_completed: false}
  ));
  }
},[currentStep]);



const withoutWarning = showWarning && interviewState?.active_answer?.answers && interviewState?.active_answer?.answers.length > 1;

const desableBackButton = currentStep===1 || currentStep===3 || second_step || currentStep===6 || currentStep===7 || currentStep===8 || currentStep===9 || currentStep===10 || currentStep===12

const disableNextButton = currentStep===1 || currentStep===2 || currentStep===5   || currentStep===7 || currentStep===8 || currentStep===9 || currentStep===11


const showAnimation = currentStep === 1 || currentStep===2 || currentStep===6 || currentStep===7 || currentStep===8 || currentStep===9  || currentStep===11;

const showStepFiveAnimation = currentStep===5;

  return (
    <div className="container__div" key={id}>
      <div
        className={`wrpper__div
         ${currentStep === 2 && "step__two"} 
         ${currentStep === 3 && "step__three"} 
         ${ currentStep === 4 && showWarning &&  "step__fourth" }
         ${ currentStep === 4 && !showWarning &&  "step__fourth__without__warning" } 
         ${ currentStep === 4 && showWarning && !withoutWarning &&"step__fourth__without__warning" } 
         ${ currentStep === 5 && showWarning && "step__fifth"}
         ${ currentStep === 5 && !showWarning && "step__fifth__without__warning"}
        ${ currentStep === 5 && showWarning && !withoutWarning && "step__fifth__without__warning"} 
        ${ currentStep === 6 && "sixth__step"}  
        ${ currentStep === 7 && "seventh__step"} 
        ${ currentStep === 8 && "eight__step"} 
        ${ currentStep === 9 && "ninth__step"} 
        ${ currentStep === 10 && "tenth__step"} 
        ${ currentStep === 11 && "eleventh__step"} 
        ${ currentStep === 12 && "twelveth__step"} 
        ${ currentStep === 13 && "thirtheenth__step"} 
        ${ currentStep === 14 && "fourteen__step"} 
        ${ currentStep === 15 && "fifteen__step"} 
        ${ currentStep === 16 && "sixteen__step"}
        ${ currentStep === 16 && !showWarning && "sixteen__step__without__warning"}
        ${ currentStep === 16 && showWarning && !withoutWarning && "sixteen__step__without__warning"}
        `}>
        <div className="close__icon"><X  onClick={closeTourPopup} role="button"/></div>
        <span className="tour__title">{title}</span>
        <div className="click__here__text__animation__step__five__wrapper">
          {
            showStepFiveAnimation && (
              <div className="click__here__text__animation__step__five__container">
                <ArrowUp className="arrow__up"/>
                <p>Click here</p>
            </div>

            )
          }
        
        </div>
        <p>{message}</p>
        <div className="click__here__text__animation__wrapper">
          {
            showAnimation && (
              <div className={`click__here__text__animation__container
               ${currentStep===7 && "seven__step__animation"}
                ${currentStep===11 && "eleventh__step__animation"}`}>
                <ArrowLeft className="arrow__left"/>
                <p>Click here</p>
            </div>

            )
          }
        
        </div>

        <div className="buttons__section">
          <button
            type="button"
            className={`${desableBackButton ? "disable__button":"back__button"}`}
            onClick={handleOnClickBack}
            disabled={currentStep===3 || second_step || currentStep===6 || currentStep===7 || currentStep===8 || currentStep===9 || currentStep===10 || currentStep===12 }
          >
            Back
          </button>
          <button
            type="button"
            className={`${ disableNextButton ? "disable__button" :"next__button"}`}
            onClick={handleOnClickNext}
            disabled={ currentStep===1 || currentStep===2 || currentStep===5   || currentStep===7 || currentStep===8 || currentStep===9 || currentStep===11}
          >
            Next
          </button>
        </div>

        <div className="progress__section__div">
          <div className="progress__bar__div" style={{width:`${progress.toFixed(2)}%`}} />
        </div>
        <div className="bottom__div" />
      </div>
    </div>
  );
};

export default TourInfoPopup;
