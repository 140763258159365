import { X } from "react-feather";
import { useState } from "react";

import "./index.scss";
import darkInfo from "../../../../assets/images/infodark.svg";
import whiteInfo from "../../../../assets/images/infoligh.svg";

const GoogleLoginWarning = (props: any) => {
  const { themeMode, setShowInfo } = props;
  return (
    <div className="goole__login__info">
      <div className="info__icon__section">
        <img
          src={themeMode === "dark" ? darkInfo : whiteInfo}
          alt="info"
          className="info__image"
        />
      </div>

      <div className="info__info__section">
        <div className="top__section">
          <span> Info</span>
          <X className="close__icon" onClick={() => setShowInfo(false)} />
        </div>
        <p>
          {" "}
          Since you signed in with your Google account, you do not have a
          current password for this account.{" "}
        </p>
      </div>
    </div>
  );
};

export default GoogleLoginWarning;
