import { useLayoutEffect } from "react";
import { RootStore, useAppSelector } from "../store/store";
import { THEME_MODE, THEME_NAME } from "../helpers/utils/Constants/themeName";

const UseAuthThemeHook = () => {
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  useLayoutEffect(() => {
    // for default
    if (configState.theme_name === THEME_NAME.default) {
      localStorage.setItem("themeName", THEME_MODE.light);
      document.body.className = "";
      document.body.classList.add("dark");
    }

    // for indigo
    if (configState.theme_name === THEME_NAME.Indigo) {
      document.body.className = "";
      document.body.classList.add("indigo_dark");
      // document.body.style.background = "red";
    }

    // for mistGrey
    if (configState.theme_name === THEME_NAME.MistyGrey) {
      document.body.className = "";
      document.body.classList.add("mistyGrey_dark");
    }

    // for azure

    if (configState.theme_name === THEME_NAME.Azure) {
      document.body.className = "";
      document.body.classList.add("azure_dark");
    }
  }, []);
};
export default UseAuthThemeHook;
