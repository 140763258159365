/* eslint-disable eqeqeq */
/* eslint-disable func-names */
/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from "react";
import { ChevronLeft } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import { Card25 } from "../../../../shared/Card/Card25/Card25";

import TextField from "../../../../shared/Inputs/TextField/TextField";
import {
  checkGroupName,
  createGroup,
  getAllCoursesAndLearningPath,
  getSingleGroupInfo,
  updateGroup,
} from "../../../../store/features/Tenant/Orgination/Group/GroupApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import SelectCourse from "../SelectCourse/SelectCourse";
import SelectMembers from "../SelectMembers/SelectMembers";

import "./groupaction.scss";
import { intersect } from "../../../../helpers/utils/MathHelper";

function GroupAction(props: any) {
  const [singleDeadline, setSingleDeadline] = useState(false);
  const [individualDeadline, setIndividualDeadline] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState([]);
  const [memberError, setMemberError] = useState("");
  const [nameError, setNameError] = useState("");
  const [pathForIndiviudalDeadline, setPathForIndiviudalDeadline] = useState(
    [],
  );
  const indvCourse: any = [];
  const indvCourseWithDeadline: any = [];

  const [checkedLearningPathId, setCheckedLearningPathId] = useState<any>([]);

  const dispatch = useAppDispatch();
  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });
  const navigate = useNavigate();
  const params = useParams();
  const loaction = useLocation();
  useEffect(() => {
    dispatch(getAllCoursesAndLearningPath());
  }, []);

  const activesingleDeadline = () => {
    if (singleDeadline) {
      setSingleDeadline(false);
    } else {
      setSingleDeadline(true);
      setIndividualDeadline(false);
    }
  };
  const activeIndividualDeadline = () => {
    if (individualDeadline) {
      setIndividualDeadline(false);
    } else {
      setSingleDeadline(false);
      setIndividualDeadline(true);
    }
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Group Name")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  useEffect(() => {
    if (params.id) dispatch(getSingleGroupInfo({ groupId: Number(params.id) }));
  }, []);

  useEffect(() => {
    if (
      groupState.req_success.single_get &&
      loaction.pathname !== "/create-new-groups"
    ) {
      if (groupState.single_group_info.group.is_single_deadline === 0) {
        setIndividualDeadline(true);
        setSingleDeadline(false);
      }
      if (groupState.single_group_info.group.is_single_deadline === null) {
        setIndividualDeadline(false);
        setSingleDeadline(false);
      }
      if (groupState.single_group_info.group.is_single_deadline === 1) {
        setSingleDeadline(true);
        setIndividualDeadline(false);
        setValue(
          "single_deadline",
          groupState.single_group_info?.all_courses[0]?.deadline,
        );
      }

      setValue("name", groupState.single_group_info.group.name);
      groupState.single_group_info.all_courses.forEach((e: any) => {
        setValue(e.id.toString(), e.deadline);
      });
    }
  }, [groupState.req_success.single_get]);

  useEffect(() => {
    if (groupState.req_success.add) {
      reset();
    }
  }, [groupState.req_success.add]);
  useEffect(() => {
    if (groupState.req_success.update) {
      reset();
      navigate("/groups");
    }
  }, [groupState.req_success.update]);
  const onSubmit = (values: any) => {
    if (nameError) {
      return;
    }
    if (selectedUserId.length === 0) {
      setMemberError("Member is required");
      return;
    }
    if (
      checkedLearningPathId.length > 0 ||
      indvCourse.length > 0 ||
      indvCourseWithDeadline.length > 0 ||
      pathForIndiviudalDeadline.length > 0
    ) {
      //
    } else {
      toast.error("Either Learning Path or Course is required");
      return;
    }
    const data: any = {
      name: values.name,
      users: selectedUserId,
    };
    if (singleDeadline) {
      data.single_deadline = values.single_deadline;
    }
    if (!individualDeadline) {
      const obj: any = {};
      checkedLearningPathId.forEach((element: any) => {
        const arr: any = [];
        pathForIndiviudalDeadline.forEach((course: any) => {
          if (course.learning_path_ids.includes(element)) {
            arr.push(course.id);
          }
        });
        obj[element] = arr;
      });
      data.learning_paths = obj;

      pathForIndiviudalDeadline.forEach((course: any) => {
        const res = intersect(course.learning_path_ids, checkedLearningPathId);
        if (res.length === 0) {
          indvCourse.push(course.id);
        }
      });
      data.individual_courses = indvCourse;
    }

    if (individualDeadline) {
      const obj: any = [];
      checkedLearningPathId.forEach((element: any) => {
        const arr: any = [];
        pathForIndiviudalDeadline.forEach((course: any) => {
          if (course.learning_path_ids.includes(element)) {
            arr.push(course.id);
          }
        });
        obj.push({ [element]: arr });
      });
      data.learning_path_with_deadlines = obj;

      const generateInsideData = (value: any, val: any) => {
        const keys: any = Object.keys(val);
        const insideData: any = {};
        val.map((elem: any, index: number) => {
          return (insideData[elem] = values[elem]);
        });

        return insideData;
      };

      const result = data.learning_path_with_deadlines.reduce(function (
        items: any,
        item: any,
      ) {
        const fixed: any = {};
        const keys: any = Object.keys(item);

        keys.forEach(function (key: any) {
          return (fixed[key] = generateInsideData(item, item[key]));
        });
        items.push(fixed);
        return items;
      },
      []);
      data.learning_path_with_deadlines = Object.assign({}, ...result);

      pathForIndiviudalDeadline.forEach((course: any) => {
        const res = intersect(course.learning_path_ids, checkedLearningPathId);
        if (res.length === 0) {
          for (const key in values) {
            if (key == course.id) {
              indvCourseWithDeadline.push({ [course.id]: values[key] });
            }
          }
        }
      });
      data.individual_course_deadlines = Object.assign(
        {},
        ...indvCourseWithDeadline,
      );
    }

    data.individual_courses = indvCourse;
    if (!params.id) {
      dispatch(createGroup({ data: data }));
    } else {
      dispatch(updateGroup({ groupId: Number(params.id), data: data }));
    }
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  const checkName = (e: any) => {
    if (loaction.pathname === "/create-new-groups") {
      dispatch(
        checkGroupName({ name: e.target.value, setNameError: setNameError }),
      );
    }
    if (
      loaction.pathname !== "/create-new-groups" &&
      groupState.single_group_info.group.name !== e.target.value
    ) {
      dispatch(
        checkGroupName({ name: e.target.value, setNameError: setNameError }),
      );
    } else {
      setNameError("");
    }
  };

  return (
    <form
      className="group__manage__assignment__wrapper"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Card25 baw>
        <div className="group__nav__section">
          <ChevronLeft
            className="goback__icon"
            onClick={() => navigate("/groups")}
          />
          <span className="title">
            {" "}
            {params.id ? "Edit group" : "Create a new group "}
          </span>
        </div>
        <div className="select__member__and__group">
          <div className="member__colums">
            <TextField
              type="text"
              label="Group name"
              name="name"
              register={register}
              error={errors?.name}
              extraError={nameError}
              onBlur={checkName}
              required
            />
          </div>
          <div className="member__colums m">
            <SelectMembers
              title="Select member(s)"
              groupState={groupState}
              setSelectedUserId={setSelectedUserId}
              required
              error={memberError}
              setMemberError={setMemberError}
            />
          </div>
        </div>

        <div className="group__section__divider" />
        <SelectCourse
          checkedLearningPathId={checkedLearningPathId}
          setCheckedLearningPathId={setCheckedLearningPathId}
          groupState={groupState}
          setPathForIndiviudalDeadline={setPathForIndiviudalDeadline}
          edit={params.id}
        />

        <div className="date__selection__wrapper">
          <span className="deadline__title">set deadline</span>
          <div className="single__deadline__container">
            <button
              type="button"
              className="radio"
              onClick={activesingleDeadline}
            >
              <div className="radio__icon">
                {singleDeadline && <span className="tick">.</span>}
              </div>{" "}
              Set a single deadline
            </button>
            {singleDeadline && (
              <div className="single__date__wrapper">
                <div className="date__section">
                  <div className="calendar__width">
                    <TextField
                      type="date"
                      placeholder="mm/dd/yyyy"
                      register={register}
                      name="single_deadline"
                      min={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="multiple__deadline__container">
            <button
              type="button"
              className="radio"
              onClick={activeIndividualDeadline}
            >
              <div className="radio__icon">
                {individualDeadline && <span className="tick">.</span>}
              </div>{" "}
              Set individual deadlines
            </button>
            {individualDeadline && (
              <div className="multiple__date__wrapper">
                {pathForIndiviudalDeadline.length > 0 &&
                  pathForIndiviudalDeadline.map((path: any) => {
                    return (
                      <div key={path.id.toString()}>
                        <div className="date__section">
                          <span className="date__label">
                            {" "}
                            {path.name} (Course)
                          </span>
                          <div className="calendar__width">
                            <TextField
                              type="date"
                              placeholder="mm/dd/yyyy"
                              name={path.id.toString()}
                              register={register}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        </div>
                        <div className="date__divider" />
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>

        <div className="group__section__divider bottom__bala" />
        <div className="button__section">
          <PrimaryButton
            text={`${params.id ? "Update" : "Send"} Invitation`}
            type="submit"
            loading={
              params.id ? groupState.loading.update : groupState.loading.add
            }
          />
        </div>
      </Card25>
    </form>
  );
}

export default GroupAction;
