import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import "./subAdmin.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  closeSubAdminEditModal,
  searchSubAdmin,
} from "../../../../store/features/AdminPanel/SubAdmin/SubAdminSlice";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  addSubAdmin,
  rensedInvitationForSubAdmin,
  updateSubMentor,
} from "../../../../store/features/AdminPanel/SubAdmin/SubAdminApi";
import { roles } from "../../../../helpers/utils/Constants/roles";

const SubAdminHeader = (props: any) => {
  // const { showEditModal, setShowEditModal } = props;
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();
  const subAdminState: any = useAppSelector((store: RootStore) => {
    return store.SubAdminSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  useEffect(() => {
    dispatch(searchSubAdmin(searchText));
  }, [searchText]);

  const validationSchema = yup.object().shape({
    first_name: yup.string().required(REQUIRED_VALIDATION("First Name")),
    last_name: yup.string().required(REQUIRED_VALIDATION("Last Name")),
    email: yup
      .string()
      .required(REQUIRED_VALIDATION("Email"))
      .email("Email is invalid"),
  });

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const handleModal = () => {
    reset();
    setShowModal(false);
    dispatch(closeSubAdminEditModal(false));
  };

  const openModal = () => {
    setShowModal(true);
    // setShowEditModal(false);
  };

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      role: "sub_admin",
    };
    if (subAdminState.editUser) {
      dispatch(
        updateSubMentor({ userId: subAdminState.single_user_info.id, data })
      );
    } else {
      dispatch(addSubAdmin(data));
    }
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (!subAdminState.adderLoading && subAdminState.edit_success) {
      setShowModal(false);
      dispatch(closeSubAdminEditModal(false));

      reset();
    }
    if (!subAdminState.adderLoading && subAdminState.add_success) {
      setShowModal(false);
      reset();
    }
  }, [subAdminState]);

  useEffect(() => {
    if (subAdminState.editUser) {
      setShowModal(true);
      setValue("first_name", subAdminState.single_user_info.first_name);
      setValue("last_name", subAdminState.single_user_info.last_name);
      setValue("email", subAdminState.single_user_info.email);
    }
  }, [subAdminState?.editUser, subAdminState?.single_user_info]);

  return (
    <div className="subadmin__header__container">
      <div className="left__section">
        <TableHeadingTitle>Sub-Admin</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>

        {(authState.tac_user?.role === roles.SYSTEM_ADMIN ||
          authState.tac_user?.role === roles.SUB_ADMIN) && (
          <div className="subadmin__button__witdh">
            <PrimaryButton
              text="Add New"
              size="sm"
              type="button"
              onClick={() => openModal()}
            />
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader
          title={subAdminState.editUser ? "Edit Sub-Admin" : "Add Sub-Admin"}
        />

        <ModalBody>
          <form
            className="user__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="flex">
              <div className="sm_width">
                <TextField
                  type="text"
                  label="First Name"
                  name="first_name"
                  register={register}
                />
              </div>
              <div className="sm_width ">
                <TextField
                  type="text"
                  label="Last Name"
                  name="last_name"
                  register={register}
                />
              </div>
            </div>

            <TextField
              type="text"
              label="Email Address"
              name="email"
              register={register}
              disabled={subAdminState.editUser}
            />
            <div className="modal_footer">
              {!subAdminState.single_user_info?.last_active &&
                !subAdminState.resend_invitaiton_loading &&
                subAdminState.editUser && (
                  <span
                    role="button"
                    aria-hidden
                    className="resend__invitation"
                    onClick={() =>
                      dispatch(
                        rensedInvitationForSubAdmin({
                          email: subAdminState.single_user_info.email,
                        })
                      )
                    }
                  >
                    Resend Invitation
                  </span>
                )}
              {subAdminState.resend_invitaiton_loading && (
                <div className="spinner spinner-margin" />
              )}
              <div>
                <PrimaryButton
                  text={subAdminState.editUser ? "Update" : "Add"}
                  type="submit"
                  loading={subAdminState.adderLoading}
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SubAdminHeader;
