/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-lonely-if */
import "./passwordchangeform.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import PasswordField from "../../../../shared/Inputs/PasswordField/PasswordField";
import { defaultPasswordChange } from "../../../../store/features/Auth/AuthApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../../helpers/utils/Constants/roles";
import { ROUTES } from "../../../../helpers/utils/Constants/route";

function PasswordChangeForm(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .trim()
      .required(REQUIRED_VALIDATION("Password"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_{|}~`])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_{|}~`]{8,}$/,
        "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 special case Character"
      ),
    password_confirmation: yup
      .string()
      .trim()
      .required(REQUIRED_VALIDATION("Confirm Password"))
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
useEffect(() => {
  if (authState.default_pass_req_success) {
    const { tac_user } = authState;
    if (tac_user) {
      switch (tac_user.role) {
        case "sys_adm":
        case roles.SUB_ADMIN:
          navigate("/dashboard");
          break;
        case "owner":
        case "admin":
          navigate("/organization");
          break;
        case "student":
          navigate("/organization-user-dashboard");
          break;
        case "mentor":
          navigate("/mentor/interview/recently-answered");
          break;
        case "user":
          if (
            tac_user.all_roles?.includes(roles.ICP_STUDENT) ||
            tac_user.all_roles?.includes(roles.APO_STUDENT)
          ) {
            navigate("/all-premium-courses"); 
          }
           else {
            if (configState.configs.membership_enabled === Domain.TAA) {
              navigate("/academic-user-dashboard");
            } else if (configState.configs.membership_enabled === Domain.TAC) {
              navigate("/progress");
            }
          }
          break;
        default:
          break;
      }
    }
  }
}, [authState.default_pass_req_success]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>(formOptions);
  const handlePassword = (values: any) => {
    dispatch(defaultPasswordChange(values));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const location = useLocation();
  const isLoginPage =
    location.pathname.includes("/") &&
    configState.configs.membership_enabled === Domain.TAA;

  return (
    <form
      className="passwordchange-form"
      onSubmit={handleSubmit(handlePassword, onError)}
    >
      <h1>Change Password</h1>
      <p className={`${isLoginPage && "change__password__text__tag"}`}>
        You're required to change the default password for security purpose.
      </p>
      <div className="form_row">
        <PasswordField
          label="New password"
          name="password"
          register={register}
          error={errors?.password}
          handleInputStyle={isLoginPage}
        />
      </div>
      <div className="form_row">
        <PasswordField
          label="Confirm new password"
          name="password_confirmation"
          register={register}
          error={errors?.password_confirmation}
          handleInputStyle={isLoginPage}
        />
      </div>
      <div className="form_row mt-1">
        <PrimaryButton
          text="Submit"
          type="submit"
          loading={authState?.loading}
        />
      </div>
    </form>
  );
}

export default PasswordChangeForm;
