import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  pullCourses,
  listCourses,
  updateCourse,
  updateCourseDuration,
} from "./ManageCourseApi";

const initialState: any = {
  loading: false,
  pull_loading: false,
  update_loading: false,
  req_success: false,
  update_success: false,
  pull_req_success: false,
  single_course_info: {},
  current_page: 0,
  courses: [],
  all_courses: [],
  temp_courses: [],
};
// listLearningPath;
const ManageCourseSlice = createSlice({
  name: "ManageCourseSlice",
  initialState,
  reducers: {
    searchCourseForAdmin: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_courses = state.all_courses.filter(
          (course: any) =>
            course.name.toLowerCase().includes(payload.toLowerCase()) ||
            course.course_id.toString().includes(payload),
        );
        state.courses = state.temp_courses;
      } else {
        state.courses = state.all_courses;
      }
    },

    manageCourseTempOrder: (state: any, { payload }: PayloadAction<any>) => {
      const a = state.all_courses[payload.sourdeIndex];

      state.all_courses.splice(payload.sourdeIndex, 1);

      state.all_courses.splice(payload.destinationIndex, 0, a);

      state.courses = state.all_courses;
    },
    // adminCoursePaginate: (state: any, { payload }: PayloadAction<any>) => {
    //   if (payload === 0) {
    //     state.courses = state.all_courses.slice(payload, 10);
    //   } else if (payload === 10) {
    //     state.current_page = 10;

    //     state.courses = state.all_courses.slice(payload * 10, 10 * 2);
    //     state.current_page = payload;
    //   } else {
    //     state.courses = state.all_courses.slice(
    //       payload * 10,
    //       10 * payload + 10,
    //     );
    //   }
    // },

    adminCoursePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.courses = state.all_courses.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.courses = state.all_courses.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.courses = state.all_courses.slice(
          payload * 10,
          10 * payload + 10,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // PULL COURSES
    builder.addCase(pullCourses.pending, (state) => {
      state.pull_loading = true;
      state.pull_req_success = false;
    });
    builder.addCase(
      pullCourses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.pull_loading = false;
        state.pull_req_success = true;
        // state.courses = payload?.data;
        // state.all_courses = payload?.data;
      },
    );
    builder.addCase(pullCourses.rejected, (state) => {
      state.pull_loading = false;
    });

    // LIST COURSES
    builder.addCase(listCourses.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      listCourses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_courses = payload?.data;
        state.courses = payload?.data;
      },
    );
    builder.addCase(listCourses.rejected, (state) => {
      state.loading = false;
    });

    // u[date] COURSES
    builder.addCase(updateCourse.pending, (state) => {
      state.update_loading = true;
      state.update_success = false;
    });
    builder.addCase(
      updateCourse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.update_loading = false;
        state.update_success = true;

        state.courses = state.courses.map((course: any) => {
          if (course.id === payload?.data.id) {
            return payload?.data;
          }
          return course;
        });

        state.all_courses = state.all_courses.map((course: any) => {
          if (course.id === payload?.data.id) {
            return payload?.data;
          }
          return course;
        });
      },
    );
    builder.addCase(updateCourse.rejected, (state) => {
      state.update_loading = false;
    });

    // update course duration
    builder.addCase(updateCourseDuration.pending, (state) => {
      state.update_loading = true;
      state.update_success = false;
    });
    builder.addCase(
      updateCourseDuration.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.update_loading = false;
        state.update_success = true;

        state.courses = state.courses.map((course: any) => {
          if (course.id === payload?.id) {
            return payload?.data;
          }
          return course;
        });

        state.all_courses = state.all_courses.map((course: any) => {
          if (course.id === payload?.id) {
            return payload?.data;
          }
          return course;
        });
      },
    );
    builder.addCase(updateCourseDuration.rejected, (state) => {
      state.update_loading = false;
    });
  },
});
export const {
  searchCourseForAdmin,
  adminCoursePaginate,
  manageCourseTempOrder,
} = ManageCourseSlice.actions;
export default ManageCourseSlice.reducer;
