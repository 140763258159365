import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSiteManagerSetting, updateSiteManager } from "./SiteManagerApi";

const initialState: any = {
  loading: {
    get: false,
    update: false,
  },
  req_success: {
    get: false,
    update: false,
  },
  activeThemeName: "",
  compact_logo: "",
  site_logo: "",
};

const SiteManagerSlice = createSlice({
  name: "SiteManagerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET THEME SETTINGS
    builder.addCase(getSiteManagerSetting.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getSiteManagerSetting.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.activeThemeName = payload.data.activeTheme;
        state.compact_logo = payload.data.compact_logo;
        state.site_logo = payload.data.site_logo;
      }
    );
    builder.addCase(getSiteManagerSetting.rejected, (state) => {
      state.loading.get = false;
    });

    // UPDATE THEME SETTINGS

    builder.addCase(updateSiteManager.pending, (state) => {
      state.loading.update = true;
      state.req_success.update = false;
    });
    builder.addCase(
      updateSiteManager.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.update = false;
        state.req_success.update = true;
        state.update = payload.data;
      }
    );
    builder.addCase(updateSiteManager.rejected, (state) => {
      state.loading.update = false;
    });
  },
});

export default SiteManagerSlice.reducer;
