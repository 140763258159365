import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKey } from "../../apiConfigs/StripeKey";
import CheckoutForm from "../../components/UserDashboard/UserProfile/Subscriptions/CheckoutForm/CheckoutForm";
import { RootStore, useAppDispatch, useAppSelector } from "../../store/store";
import "./payToGetAccess.scss";
import { getCardInfo } from "../../store/features/UserDashboard/Payment/PaymentApi";
import {
  clearStripeFalse,
  paytoReAccess,
} from "../../store/features/UserDashboard/Payment/PaymentSlice";
import { TENANTPLANSTATUS } from "../../helpers/utils/Constants/tenantPlanStatus";

const stripePromise: any = loadStripe(stripeKey());

const PayToGetAccess = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const tenantData: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice.tenant_pay_details;
  });

  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  useEffect(() => {
    if (authState.tenant_pay_details?.status === TENANTPLANSTATUS.PAID) {
      navigate("/not-found");
    }
    dispatch(paytoReAccess(true));
    dispatch(clearStripeFalse());
    dispatch(getCardInfo());
  }, []);

  useEffect(() => {
    if (
      paymentState.pay_to_reaccess &&
      paymentState.req_success.upgrade_account
    ) {
      dispatch(paytoReAccess(false));
      navigate("/organization");
    }
  }, [
    paymentState.pay_to_reaccess && paymentState.req_success.upgrade_account,
  ]);

  return (
    <div className="pay__first__wrapper">
      {" "}
      <div className="payment__section__width">
        <span className="main__title"> Your account has expired</span>
        <span className="sub__title">
          {" "}
          Please complete the payment process to proceed further.
        </span>
        <Elements stripe={stripePromise}>
          <CheckoutForm
            tenant
            size={tenantData?.seat_count}
            billingType={tenantData?.billing_type}
          />
        </Elements>
      </div>
    </div>
  );
};

export default PayToGetAccess;
