/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addOrUpdateCohorts, getCohortsData } from "./CohortsApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    delete: false,
  },
  req_success: {
    add: false,
    get: false,
    delete: false,
  },
  edit: false,
  chorts: {},
};

const CohortsSlice = createSlice({
  name: "CohortsSlice",
  initialState,
  reducers: {
    editCohort: (state: any) => {
      state.edit = true;
    },
    closeCoohortModal: (state: any) => {
      state.edit = false;

    },
  },
  extraReducers: (builder) => {
    // Get Cohorts Data 
    builder.addCase(getCohortsData.pending,(state,{payload}:PayloadAction<any>)=>{
        state.loading.get = true;

    });
    builder.addCase(getCohortsData.fulfilled,(state,{payload}:PayloadAction<any>)=>{
        state.chorts = payload?.data;
        // state.all_chorts=payload?.data;
        state.loading.get = false;

    });
    builder.addCase(getCohortsData.rejected,(state,{payload}:PayloadAction<any>)=>{
        state.loading.get = false;
    });

    // Add or Update Cohorts Data 
    builder.addCase(addOrUpdateCohorts.pending,(state,{payload}:PayloadAction<any>)=>{
        state.loading.add = true;

    });
    builder.addCase(addOrUpdateCohorts.fulfilled,(state,{payload}:PayloadAction<any>)=>{
        state.chorts = payload?.data
        state.loading.add = false;
        state.edit = false;
    });
    builder.addCase(addOrUpdateCohorts.rejected,(state,{payload}:PayloadAction<any>)=>{
        state.loading.add = false;
    });


  },
});

export const { editCohort, closeCoohortModal } =
CohortsSlice.actions;

export default CohortsSlice.reducer;
