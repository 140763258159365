export const downloadFile = (link: string) => {
  fetch(link).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      const alink = document.createElement("a");
      alink.href = fileURL;
      // alink.download = `${data.resource_name}.${data.resource_extension}`;
      alink.download = "video.mp4";
      alink.click();
    });
  });
};
