import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddAboutUs, getAboutUs } from "./AboutApi";

const initialState: any = {
  loading: false,
  req_success: false,
  about: {},
};
// ADD about;
const TermsSlice = createSlice({
  name: "TermsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ADD about OF USE
    builder.addCase(AddAboutUs.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      AddAboutUs.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.about = payload.data;
      },
    );
    builder.addCase(AddAboutUs.rejected, (state) => {
      state.loading = false;
    });

    // GET about OF USE
    builder.addCase(getAboutUs.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getAboutUs.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.about = payload.data;
      },
    );
    builder.addCase(getAboutUs.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default TermsSlice.reducer;
