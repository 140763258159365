import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Edit, Edit2, Trash } from "react-feather";
import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../../store/store";
import "./quicklinksbody.scss";
import Table, { TableHeading } from "../../../../shared/Table/Table";
import {
  loadQuickLinks,
  updateQLOrder,
} from "../../../../store/features/UserDashboard/QuickLinks/QuickLinksApi";
import {
  changeQLTempOrder,
  updateCurrID,
} from "../../../../store/features/UserDashboard/QuickLinks/QuickLinksSlice";
import Empty from "../../../../shared/Empty/Empty";

function QuickLinksBody(props: any) {
  const {
    isEdit,
    setIsEdit,
    openModal,
    setOpenModal,
    showDeleteModal,
    setDeleteShowModal,
  } = props;
  const dispatch = useAppDispatch();
  const qlState: any = useAppSelector((store: RootStore) => {
    return store.QuickLinksSlice;
  });

  useEffect(() => {
    dispatch(loadQuickLinks("dashboard"));
  }, []);

  const listenDrag = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //
    const data2 = {
      changeOrder: true,
      sourceIndex: source.index,
      destinationIndex: destination.index,
      draggableId: draggableId,
    };
    dispatch(changeQLTempOrder(data2));
    const data = { list: { [draggableId]: destination.index + 1 } };
    // console.log("Now call update QL order");
    dispatch(updateQLOrder({ data: data }));

    // dispatch(loadQuickLinks("dashboard"));
  };

  useEffect(() => {
    if (qlState.order_req_success) {
      dispatch(loadQuickLinks("dashboard"));
    }
  }, [qlState.order_loading]);

  return (
    <div className="quicklinkbody ">
      {/* <DragDropContext onDragEnd={listenDrag}>
        <Droppable droppableId="239999">
          {(provided) => (
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Icon</th>
                  <th>URL</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody ref={provided.innerRef}>
                {qlState.all_quicklinks?.map((e: any, index: any) => {
                  return (
                    <Draggable
                      draggableId={`${e?.id}`}
                      index={index}
                      key={`${e?.id}`}
                    >
                      {(provided) => {
                        return (
                          <tr
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <td>{e?.title}</td>
                            <td>{e?.description}</td>
                            <td>
                              <img src={e?.icon} alt="sdafasd" />
                            </td>
                            <td>
                              <a
                                title={e?.url}
                                href={e?.url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {e?.url}
                              </a>
                            </td>
                            <td>
                              <div className="action-container">
                                <button
                                  type="button"
                                  onClick={() => {
                                    dispatch(updateCurrID(e.id));
                                    setIsEdit(true);
                                    setOpenModal(true);
                                  }}
                                >
                                  <Edit2 />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    dispatch(updateCurrID(e.id));
                                    setDeleteShowModal(true);
                                  }}
                                >
                                  <Trash />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext> */}

      <div className="custom-table-wrapper custom__scrollbar">
        <DragDropContext onDragEnd={listenDrag}>
          <Droppable droppableId="239999">
            {(provided) => (
              <div className="custom-table ">
                <div className="ct-head">
                  <div className="ct-title">Title</div>
                  <div className="ct-description">Description</div>
                  <div className="ct-icon">Icon</div>
                  <div className="ct-url">URL</div>
                  <div className="ct-action">Action</div>
                </div>
                <div className="ct-body" ref={provided.innerRef}>
                  {qlState.all_quicklinks?.map((e: any, index: any) => {
                    return (
                      <Draggable
                        draggableId={`${e?.id}`}
                        index={index}
                        key={`${e?.id}`}
                      >
                        {(provided) => {
                          return (
                            <div
                              className="ct-row"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div className="ct-title">{e?.title}</div>
                              <div className="ct-description">
                                {e?.description}
                              </div>
                              <div className="ct-icon">
                                {" "}
                                <img src={e?.icon} alt="icon" />
                              </div>
                              <div className="ct-url">
                                <a
                                  title={e?.url}
                                  href={e?.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {e?.url}
                                </a>
                              </div>
                              <div className="ct-action">
                                <div className="action-container">
                                  <button
                                    aria-hidden
                                    type="button"
                                    onClick={() => {
                                      dispatch(updateCurrID(e.id));
                                      setIsEdit(true);
                                      setOpenModal(true);
                                    }}
                                  >
                                    <Edit2 />
                                  </button>
                                  <button
                                    aria-hidden
                                    type="button"
                                    onClick={() => {
                                      dispatch(updateCurrID(e.id));
                                      setDeleteShowModal(true);
                                    }}
                                  >
                                    <Trash />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {qlState.all_quicklinks?.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty emptyText="It seems empty." custombg={true} />
          </div>
        )}
      </div>
    </div>
  );
}

export default QuickLinksBody;
