import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { searchMembers } from "../../../../store/features/Tenant/Orgination/Member/MemberSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";

const MemberHeaders = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  const memberState: any = useAppSelector((store: RootStore) => {
    return store.MemberSlice;
  });

  useEffect(() => {
    dispatch(searchMembers(searchText));
  }, [searchText]);
  return (
    <div className="team__menber__header">
      {" "}
      <div className="left__section">
        <TableHeadingTitle>
          Members{" "}
          <span className="invited__member__text">
            {" "}
            ({memberState.member_limit?.totalUsers} of{" "}
            {memberState.member_limit?.companySize} members invited)
          </span>
        </TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Invite Member"
            size="sm"
            type="button"
            loading={false}
            onClick={() => navigate("/invite-new-members")}
          />
        </div>
      </div>
    </div>
  );
};

export default MemberHeaders;
