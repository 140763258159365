import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import "./userProfileHeader.scss";
import TextField from "../../../shared/Inputs/TextField/TextField";
import {
  PrimaryButton,
  TACPrimaryButton,
} from "../../../shared/Buttons/Buttons";
import UserProfileNavbar from "./userProfileNavbar";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  changePassword,
  logoutUser,
  updateUsername,
} from "../../../store/features/Auth/AuthApi";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../helpers/utils/Constants/roles";

const UserDetailForm = (props: any) => {
  const { configState } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  // Validate is TAA user or not 
  const isTAAUser = authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA;

  // validate is Trial user or not 
 const isTrialUser = 
 (authState.tac_user?.role === roles.BUISNESS_ANALYST_STUDENT ||
   authState.tac_user?.role === roles.SCRUM_MASTER_STUDENT ||
   authState.tac_user?.role === roles.PRODUCT_OWNER_STUDENT) &&
 authState?.tac_user?.default_password_updated &&
 configState.configs.membership_enabled === Domain.TAA

  const validationSchema = yup.object().shape({
    first_name: yup.string().required(REQUIRED_VALIDATION("First Name")),
    last_name: yup.string().required(REQUIRED_VALIDATION("Last Name")),
    email: yup.string().required(REQUIRED_VALIDATION("Email Address")),
    // linkedinProfile: yup
    //   .string()
    //   .required(REQUIRED_VALIDATION("Linkedin Profile")),
    // order: yup.string().required(REQUIRED_VALIDATION("Order")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>(formOptions);

  useEffect(() => {
    if (authState.load_user_success) {
      setValue("first_name", authState.tac_user?.first_name);
      setValue("last_name", authState.tac_user?.last_name);
      setValue("email", authState.tac_user?.email);
    }
  }, [authState?.load_user_success]);

  const handleProfile = (values: any) => {
    const datas = {
      ...values,
      user_id: authState.tac_user.id,
    };
    dispatch(updateUsername({ data: values, userId: authState.tac_user.id }));
  };
  //   useEffect(() => {
  //     if (authState.pass_req_success) {
  //       reset();
  //       dispatch(logoutUser());
  //     }
  //   }, [authState.pass_req_success]);
  // console.log(configState.configs.membership_enabled === Domain.TAA,configState,authState);

  return (
    <form
      className="profile_card__container"
      onSubmit={handleSubmit(handleProfile)}
    >
      <div className={`card_wrapper mt-0 ${  isTAAUser || isTrialUser ? "card__wrapper__div " :""}`}>
        {" "}
        <span className="profile__topography">Profile</span>
        <div className="input_text_section">
          <div className="form_row">
            <TextField
              type="text"
              label="First Name"
              name="first_name"
              register={register}
              error={errors?.first_name}
              handleInputStyle={isTAAUser || isTrialUser}
            />
          </div>

          <div className="form_row">
            <TextField
              type="text"
              label="Last Name"
              name="last_name"
              register={register}
              error={errors?.last_name}
              handleInputStyle={isTAAUser || isTrialUser}
            />
          </div>
          <div className="form_row">
            <TextField
              type="text"
              label="Email Address"
              name="email"
              register={register}
              error={errors?.email}
              handleInputStyle={isTAAUser || isTrialUser}
              readOnly
            />
          </div>
        </div>
        <div className="button__witdh mt-1">
          {configState.configs.membership_enabled === Domain.TAC &&
          authState.tac_user?.role === roles.USER ? (
            <TACPrimaryButton
              text="Update"
              type="submit"
              loading={authState.update_user_loading}
            />
          ) : (
            <PrimaryButton
              text="Update"
              type="submit"
              loading={authState.update_user_loading}
            />
          )}
        </div>
      </div>
    </form>
  );
};

export default UserDetailForm;
