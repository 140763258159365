import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import LearningPathComp from "../../../components/UserDashboard/LearningPathComp/LearningPathComp";
import LearningPathCourse from "../../../components/UserDashboard/LearningPathCourse/LearningPathCourse";

const LearningPath = () => {
  return (
    <AdminPanelLayout>
     <div className="mt-5">
     <LearningPathCourse />
      {/* <LearningPathComp/> */}
     </div>
    </AdminPanelLayout>
  );
};

export default LearningPath;
