import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import { useAppDispatch } from "../../../../store/store";
import { renameResource } from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerApi";

const FileNameEditor = (props: any) => {
  const { data } = props;
  const dispatch = useAppDispatch();
  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Folder Name")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm<any>(formOptions);

  useEffect(() => {
    setValue("name", data.resource_name);
  }, []);

  const onSubmit = (values: any) => {
    const datas = { ...values, id: data.id };
    dispatch(renameResource(datas));
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  return (
    <form
      className="editor_name_form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <input
        type="text"
        {...(register && register("name"))}
        className="remane__editor"
      />
      {errors?.name && (
        <span className="rename_error"> File name cannot be empty.</span>
      )}
    </form>
  );
};

export default FileNameEditor;
