import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";

const validationSchema = yup.object().shape({
    month_year: yup.string().required(REQUIRED_VALIDATION("Month")),
    // day: yup.number()
    // .typeError("Day is required")
    // .required("Day is required")
    // .min(1, "Day cannot be less than 1")
    // .max(31, "Day cannot be greater than 31"),
  });
  export const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  
   