/* eslint-disable react/require-default-props */
import React from "react";
import { Mic, Pause, Play, Send } from "react-feather";
import { recorderControls } from "react-audio-voice-recorder/dist/hooks/useAudioRecorder";

import waveImage from "../../assets/icons/wave.svg";
import "./index.scss";
import { getCurrentMinuteAndSecondFromSeconds } from "../../helpers/utils/dateTime";
import blackPlay from "../../assets/images/blackPlay.svg";
import whitePlay from "../../assets/images/whiteplay.svg";
import whitePause from "../../assets/images/whitepause.svg";
import blackPause from "../../assets/images/blackpause.svg";
import { RootStore, useAppSelector } from "../../store/store";

interface Iprops {
  recorderControls: recorderControls;
  handleAudioFeedback: () => void;
  handleAudioFeedBackStyle?:boolean;
}

const AudioFeedback = (props: Iprops) => {
  const { recorderControls, handleAudioFeedback ,handleAudioFeedBackStyle} = props;

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const submitAudio = () => {
    recorderControls.stopRecording();
    handleAudioFeedback();
  };

  return (
    <div className={`recored__feedback__section ${handleAudioFeedBackStyle && "handleAudioFeedBackStyle"}`}>
      <div
        className={`play__pasue__section ${handleAudioFeedBackStyle && "Play__pause__button"}`}
        role="button"
        aria-hidden
        onClick={recorderControls.togglePauseResume}
      >
        {/* {!recorderControls.isPaused && <Pause className="pause__icon" />} */}
        {/* {recorderControls.isPaused && <Play className="play__icon" />} */}
        {!recorderControls.isPaused && (
          <img
            className="pause__icon"
            src={themeState.themeMode === "dark" ? whitePause : blackPause}
            alt="pause"
          />
        )}
        {recorderControls.isPaused && (
          <img
            className="play__icon"
            src={themeState.themeMode === "dark" ? whitePlay : blackPlay}
            alt="play"
          />
        )}
      </div>
      <div className="wave__image__section">
        <img src={waveImage} className="wave__image" alt="wave" />
      </div>
      <div className="time__and__send__section">
        {" "}
        <div className="timer__section">
          {getCurrentMinuteAndSecondFromSeconds(recorderControls.recordingTime)}
        </div>
        <Send className="send__icon" onClick={() => submitAudio()} />
      </div>
    </div>
  );
};

export default AudioFeedback;
