/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import "./themePreviewModal.scss";
import Modal, { ModalBody } from "../../../../shared/Modals/Modal";

const ThemePreviewModal = (props: any) => {
  const { showModal, setShowModal,data,id } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const Images = data.filter((item:any)=>item.id === id);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const closeModal =()=>{
    setShowModal(false)

  }

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === Images[0].images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? Images[0].images.length - 1 : prevIndex - 1
    );
  };

  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      onClick={closeModal}
      width={`theme__switch__modal__width__handle ${isChecked && "light"}`}
    >
      <ModalBody>
        <div className="preview__theme">
          <div className="preview__theme__switch">
            <span className="darklight">dark</span>
            <label className="switch" role="button">
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <span className="slider" />
            </label>
            <span className="darklight">light</span>
          </div>

          <div className="theme__preview__image__section">
            <button
              type="button"
              className={`image__slider__button left__button ${currentIndex === 0 && "disable__btn"}`}
              onClick={goToPrevSlide}
              disabled={currentIndex === 0}
            >
              <ChevronLeft />
            </button>
            <div className="theme__preview__image__wrapper">
              <div className="theme__preview__image">
                <img
                  src={
                    isChecked
                      ? Images[0].images[currentIndex].light
                      : Images[0].images[currentIndex].dark
                  }
                  alt=""
                />
              </div>
            </div>
            <button
              type="button"
              className={`image__slider__button right__button ${currentIndex === Images[0].images.length - 1 && "disable__btn"}`}
              onClick={goToNextSlide}
              disabled={currentIndex === Images[0].images.length - 1}
            >
              <ChevronRight />
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ThemePreviewModal;
