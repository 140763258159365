/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import { Edit2, Trash } from "react-feather";
import Table, { TableHeading } from "../../../../shared/Table/Table";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../store/store";
import { deleteVideoTourVideo, getVideoFromBunnyNet } from "../../../../store/features/AdminPanel/VideoTour/VideoTourApi";
import { editUploadedVideo } from "../../../../store/features/AdminPanel/VideoTour/VideoTourSlice";

function VideoTourBody(props: any) {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const videoTourState:any = useAppSelector((store:RootStore)=>store.VideoTourSlice);
  
  useEffect(()=>{
    dispatch(getVideoFromBunnyNet())
  },[]);

  useEffect(()=>{
    if(!videoTourState?.is_loading.delete && videoTourState?.is_success.delete){
      setDeleteShowModal(false);
      dispatch(getVideoFromBunnyNet())
    }

  },[videoTourState])


const handleEditVideo = ()=>{
  dispatch(editUploadedVideo(true))
}

const handleDeleteVideo = ()=>{
  dispatch(deleteVideoTourVideo());
}

const truncateString = (str: any, num: any) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)  }...`;
};


  return (
    <div className="testimonialbody">
      <Table
        heading={
          <TableHeading>
            <th>Title</th>
            <th>Video Url/ID</th>
            <th className="">Status</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        {
          videoTourState?.video_data?.content !==null  && videoTourState?.video_data !==null && (   <tbody>
            <tr className="ttr">
              <td className="ttd ttd_name">
                {videoTourState?.video_data?.content?.title}
              </td>
              <td className="ttd">
                {truncateString(videoTourState?.video_data?.content?.video_url ? videoTourState?.video_data?.content?.video_url : videoTourState?.video_data?.content?.videoId?.toString(), 100)}
              </td>
              <td className="">{videoTourState?.video_data?.content?.show ? "Active":"Not Active"}</td>
              <td className="ttd ttd_action">
                <Edit2 className="edit__icon" onClick={()=>handleEditVideo()} />
                <Trash className="delete__icon" onClick={()=>setDeleteShowModal(true)} />
              </td>
            </tr>
          </tbody>)
        }
     
      </Table>

      {
        videoTourState?.video_data?.content === null && videoTourState?.video_data ===null && (<p style={{textAlign:"center",marginTop:"2rem"}}>No tour video added yet!</p>)
      }

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        onClick={handleDeleteVideo}
        loading={videoTourState?.is_loading.delete && !videoTourState?.is_success.delete}
      />
    </div>
  );
}

export default VideoTourBody;
