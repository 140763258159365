import { useEffect, useState } from "react";
import { SmokeButton } from "../../../../../shared/Buttons/Buttons";
import ErrorText from "../../../../../shared/Inputs/ErrorText/ErrorText";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import { applyCouponCode } from "../../../../../store/features/UserDashboard/Payment/PaymentApi";
import { clearCouponAndDiscount } from "../../../../../store/features/UserDashboard/Payment/PaymentSlice";
import PriceDetails from "./PriceDetails";
import { PricingDetailSkleton } from "./Skleton/CheckoutSkleton";

const CouponSection = (props: any) => {
  const { annualy, monthly, coupon, setCouponCode, companySize, tenant } =
    props;
  const [couponError, setCouponError] = useState("");

  const dispatch = useAppDispatch();
  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });

  const getCoupon = (e: any) => {
    setCouponCode(e.target.value);
    setCouponError("");
  };

  const applyForCoupon = () => {
    if (coupon.length === 0) {
      setCouponError("Your coupon code is invalid");
      return;
    }

    const data: any = {
      coupon: coupon,
      billing_type: annualy ? "annual" : "monthly",
    };
    if (tenant && companySize) {
      data.size = companySize;
    }
    dispatch(applyCouponCode({ data: data, setCouponError: setCouponError }));
  };

  useEffect(() => {
    if (paymentState.req_success.upgrade_account) {
      setCouponCode("");
      setCouponError("");
    }
  }, [paymentState.req_success.upgrade_account]);

  useEffect(() => {
    if (coupon && coupon.length > 0) {
      setCouponCode("");
      setCouponError("");
      dispatch(clearCouponAndDiscount());
    }
  }, [annualy, monthly]);

  useEffect(() => {
    if (paymentState.clear_stripe) {
      setCouponCode("");
      setCouponError("");
    }
  }, [paymentState.clear_stripe]);

  // loading.pricing;
  return (
    <>
      {" "}
      <div className="coupon__input__section">
        <input
          id="coupon"
          name="coupon"
          type="text"
          placeholder="Enter coupon code if you have any"
          className={`${couponError && "error"}`}
          onChange={(e) => getCoupon(e)}
          value={coupon}
          readOnly={paymentState.req_success.apply_coupon}
        />
        <div className="apply__button__section">
          <SmokeButton
            text="Apply"
            onClick={() => applyForCoupon()}
            loading={paymentState.loading.apply_coupon}
          />{" "}
        </div>
      </div>
      <ErrorText error={couponError} />
      {paymentState.req_success.apply_coupon && (
        <PriceDetails
          subTotal={paymentState.price_after_coupon.subTotal}
          coupon={coupon}
          discount={paymentState.price_after_coupon.discount}
          totalPrice={paymentState.price_after_coupon.totalPrice}
        />
      )}
      {/* {paymentState.loading.apply_coupon && <PricingDetailSkleton />} */}
      {tenant &&
        !paymentState.req_success.apply_coupon &&
        paymentState.req_success.pricing && (
          <PriceDetails
            subTotal={paymentState.pricing.subTotal}
            coupon={coupon}
            discount={paymentState.pricing.discount}
            totalPrice={paymentState.pricing.totalPrice}
          />
        )}
      {tenant && paymentState.loading.pricing && <PricingDetailSkleton />}
    </>
  );
};

export default CouponSection;
