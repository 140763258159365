import Select from "react-select";
import { RootStore, useAppSelector } from "../../../store/store";
import InputContainer from "../../Layouts/Input/InputContainer";
import ErrorText from "../ErrorText/ErrorText";
import { InputLabel } from "../InputLabel/InputLabel";
import "./selectField.scss";

interface Options {
  id: number;
  label: string;
  value: string;
}

export default function SelectField(props: any) {
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const {
    options,
    name,
    label,
    error,
    handleChange,
    register,
    placeholder,
    isSearchable = false,
    ...rest
  } = props;
  const errorMessage = error?.message;
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: themeState.themeMode === "dark" ? "#1C1C1C" : "#F9FAFB",
      minHeight: "4.9rem",
      maxHeight:"30rem",
      marginBottom: "0.4rem",
      fontSize: "14px",
      cursor: "pointer",
      borderRadius: "1rem",
      boxShadow: "none",
      border: "1px solid transparent",
      "&:hover": {
        border: "1px solid var(--primary-hover-color)",
      },
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      // height: "2.81rem",
      padding: "0 0.375rem",
      display: "flex",
      // justifyContent: "center",
      // justifyItems: "center",
      alignContent: "center",
      paddingLeft: "1rem",
      overflow:"unset",
      whiteSpace:"wrap",
      height:"auto",
      maxHeight:"30rem"
    }),

    input: (provided: any) => ({
      ...provided,
      margin: "0rem",
      padding: "2px",
    }),
    menu: (provided: any) => ({
      ...provided,
      background: themeState.themeMode === "dark" ? "#303030" : "F9FAFB",
      borderRadius: "1rem",
      fontSize: "1.4rem",
      zIndex: "1000",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
    }),
    singleValue:(provided:any)=>({
      ...provided,
      whiteSpace:"pre-wrap"
    }),
    multiValue:(provided:any)=>({
      ...provided,
      backgroundColor: "#d0d5dd",
      color:"#121212",
      borderRadius:"5px",
      overflow:"hidden"
    }),
    multiValueLabel:(provided:any)=>({
      ...provided,
      backgroundColor: "#d0d5dd",
      color:"#121212"
    }),
    multiValueRemove:(provided:any)=>({
      ...provided,
      color:"#121212",
      backgroundColor: "#d0d5dd",
      ":hover": {
        backgroundColor: "#d0d5dd",
        color: "#121212",
      },
    })
  };
  return (
    <div>
      <InputContainer>
        <InputLabel name={name} text={label} />

        <Select<Options>
          className="basic-single white"
          placeholder={placeholder || "Select Option"}
          name={name}
          isSearchable={isSearchable}
          options={options}
          onChange={handleChange}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25:
                themeState.themeMode === "dark" ? "#1C1C1C" : "#F9FAFB",
              primary: themeState.themeMode === "dark" ? "#1C1C1C" : "#444444",
              neutral80: themeState.themeMode === "dark" ? "#fff" : "#444444",
              neutral50: themeState.themeMode === "dark" ? "#fff" : "#101828",
            },
          })}
          {...rest}
        />
        <ErrorText error={errorMessage} />
      </InputContainer>
    </div>
  );
}
