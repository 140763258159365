import React, { useState } from "react";
import "./webinardatetimeheader.scss";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import AddWebinarDateTimeModal from "../../WebinarDateTimeTable/DateTimeTable/AddWebinarDateTimeModal/AddWebinarDateTimeModal";

const WebinarDateTimeHeader = (props: any) => {
  const { isEdit, setIsEdit, showModal, setShowModal, upcoming_webinar } =
    props;

  return (
    <div>
      <div className="webinar_date_time_header_container">
        <div className="left__section">
          <TableHeadingTitle>Date & Time</TableHeadingTitle>
        </div>
        <div className="right__section">
          <div className="add_">
            <PrimaryButton
              disabled={upcoming_webinar}
              text="Add Date & Time"
              size="sm"
              type="button"
              onClick={() => setShowModal(true)}
            />
          </div>
        </div>
      </div>
      <AddWebinarDateTimeModal
        showModal={showModal}
        setShowModal={setShowModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default WebinarDateTimeHeader;
