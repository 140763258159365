/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllTeamMebersMeetLinks,
  getDescriptionByUrl,
} from "./MeetWithTeamApi";

const initialState: any = {
  loading: {
    get: false,
    desc: false,
  },
  req_success: {
    get: false,
    desc: false,
  },
  all_members: [],
  desc: "",
};

const MeetWithTeamSlice = createSlice({
  name: "MeetWithTeamSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL MEMBERS MEETING LINKS
    builder.addCase(getAllTeamMebersMeetLinks.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getAllTeamMebersMeetLinks.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.all_members = payload?.data;
      }
    );
    builder.addCase(getAllTeamMebersMeetLinks.rejected, (state) => {
      state.loading.get = false;
    });

    // GET DESC

    builder.addCase(getDescriptionByUrl.pending, (state) => {
      state.loading.desc = true;
    });
    builder.addCase(
      getDescriptionByUrl.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.desc = false;
        state.req_success.desc = true;
        state.desc = payload?.data;
      }
    );

    builder.addCase(getDescriptionByUrl.rejected, (state) => {
      state.loading.desc = false;
    });
  },
});

export default MeetWithTeamSlice.reducer;
