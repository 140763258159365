import UserProfileHeader from "../../../components/AdminPanel/userProfile/userProfileHeader";
import NavForTrialUser from "../../../components/NewAcademicUser/Dashboard/Nav/NavForTrialUser";
import "./myaccount.scss"

function TrailUserProfile(props: any) {
  return (
    <>
      <NavForTrialUser normal navBgColorHandle/>
      <div className="myaccount__wrapper">
      <div className="myaccount">
        <h1>My Account</h1>
        <UserProfileHeader />
      </div>
      </div>
    </>
  );
}

export default TrailUserProfile;
