import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../../shared/Modals/Modal";
import TextField from "../../../../../shared/Inputs/TextField/TextField";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import "./editModal.scss";
import SelectField from "../../../../../shared/Inputs/SelectField/SelectField";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  getAllTrialUser,
  updateUsers,
} from "../../../../../store/features/AdminPanel/User/UserApi";

const EditModal = (props: any) => {
  const { showEditModal, setShowEditModal, user } = props;
  const dispatch = useAppDispatch();
  const userState: any = useAppSelector((store: RootStore) => {
    return store.UserSlice;
  });

  const options = [
    { value: "paid", label: "Paid" },
    { value: "trial", label: "Trial" },
  ];
  const {
    register,
    handleSubmit,
    setValue,

    control,
    formState: { errors },
  } = useForm<any>();
  const onSubmit = (values: any) => {
    const data = {
      ...values,
      plan: values.plan.value,
    };
    if (values.plan.value === "paid") {
      data.role = 3;
    }
    dispatch(updateUsers({ userId: user.id, data }));
  };

  const handleModal = () => {
    setShowEditModal(false);
  };

  useEffect(() => {
    if (user) {
      setValue("first_name", user.first_name);
      setValue("last_name", user.last_name);
      setValue("email", user.email);
      setValue("plan", {
        value: "trial",
        label: "Trial",
      });
    }
  }, [user]);

  useEffect(() => {
    if (userState.edit_success) {
      dispatch(getAllTrialUser());
      setShowEditModal(false);
    }
  }, [userState.edit_success]);

  return (
    <Modal
      show={showEditModal}
      setShow={setShowEditModal}
      onClick={handleModal}
    >
      <ModalHeader title="Edit Trial User" />
      <ModalBody>
        <form
          className="trial__user__form__container"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex">
            <div className="sm_width">
              <TextField
                type="text"
                label="First Name"
                name="first_name"
                register={register}
              />
            </div>
            <div className="sm_width ">
              <TextField
                type="text"
                label="Last Name"
                name="last_name"
                register={register}
              />
            </div>
          </div>

          <TextField
            type="text"
            label="Email Address"
            name="email"
            register={register}
            disabled={true}
          />
          <div className="flex">
            <div className="select__option__width sm_width">
              <Controller
                control={control}
                name="plan"
                render={({ field }) => (
                  <SelectField
                    type="text"
                    label="Subscription Type"
                    options={options}
                    register={register}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="modal_footer">
            <div className="add_button__witdh mt-1">
              <PrimaryButton
                text="Update"
                type="submit"
                loading={userState?.adderLoading}
              />
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default EditModal;
