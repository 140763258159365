import PaginateButton from "../../../../shared/PaginateButton/PaginateButton";
import { mentorPaginate } from "../../../../store/features/AdminPanel/Mentor/MentorSlice";
import { usersPaginate } from "../../../../store/features/AdminPanel/User/UserSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";

const MentorFooter = () => {
  const dispatch = useAppDispatch();
  const userState: any = useAppSelector((store: RootStore) => {
    return store.MentorSlice;
  });
  const handlePagination = (num: number) => {
    dispatch(mentorPaginate(num));
  };
  return (
    <div className="mentor__pagination__section">
      <PaginateButton
        paginate={handlePagination}
        totalRows={userState.all_users.length}
      />
    </div>
  );
};

export default MentorFooter;
