import { v4 as uuidv4 } from "uuid";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  apiDefaultPropmts,
  filterQuestion,
  fetchConversation,
  getRecentSearch,
  clearConversation,
} from "./AiAssistApi";

const initialState: any = {
  loading: {
    prompts: false,
    question: false,
    conversation: false,
    all_conversation: false,
    clear_conversation: false,
    current_answer: false,
  },
  req_success: {
    prompts: false,
    question: false,
    conversation: false,
    all_conversation: false,
    clear_conversation: false,
    current_answer: false,
  },
  response_generated: false,
  prompts: [],
  left_prompts: [],
  right_prompts: [],
  conversations: [],
  active_conversation_id: null,
  all_conversation: [],
  loaderId: null,

  messagesStack: [],
  question: "",
};

const AiSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    adduserQuestionToMessageStack: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      state.messagesStack = [
        ...state.messagesStack,
        { id: uuidv4(), message: payload, user: true },
      ];
    },
    addAnswerToMessageStack: (state: any, { payload }: PayloadAction<any>) => {
      state.messagesStack = [
        ...state.messagesStack,
        {
          id: uuidv4(),
          message: payload,
          ai: true,
          // questionId: payload.data.id,
          // question: payload.data.question,
        },
      ];
    },
    openNewChat: (state: any) => {
      state.messagesStack = [];
      state.active_conversation_id = null;
      state.response_generated = false;
    },
    setLoaderId: (state: any, { payload }: PayloadAction<any>) => {
      state.loaderId = payload;
    },
    askNewQuestionToAi: (state: any, { payload }: PayloadAction<any>) => {
      state.question = payload;
    },
    setActiveConversationId: (state: any, { payload }: PayloadAction<any>) => {
      state.active_conversation_id = payload;
    },
    setLoadingCurrentAnswer: (state: any, { payload }: PayloadAction<any>) => {
      state.loading.current_answer = payload;
    },
    setResponseGenerated: (state: any, { payload }: PayloadAction<any>) => {
      state.response_generated = payload;
    },
    clearChattingScreen: (state: any) => {
      state.messagesStack = [];
      state.all_conversation = [];
      state.active_conversation_id = null;
      state.response_generated = false;
    },

    addConversationToSidebar: (state: any, { payload }: PayloadAction<any>) => {
      let exist = false;
      state.all_conversation.forEach((conv: any) => {
        if (conv.uniqueId === payload.uniqueId) {
          exist = true;
        }
      });
      if (!exist) {
        const latestConv: any = {
          question: payload.question,
          uniqueId: payload.uniqueId,
        };
        state.all_conversation = [latestConv, ...state.all_conversation];
      }
    },
  },
  extraReducers: (builder) => {
    // GET DEFAULT PROMPTS AND RECENT CONVERSATION
    builder.addCase(apiDefaultPropmts.pending, (state) => {
      state.loading.prompts = true;
      state.req_success.prompts = false;
    });
    builder.addCase(
      apiDefaultPropmts.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.prompts = false;
        state.req_success.prompts = true;
        const leftPrompts: any = [];
        const rightPrompts: any = [];
        state.prompts = payload.data;
        payload?.data.prompts.forEach((prompt: any, index: number) => {
          if (index === 0 || index % 2 === 0) {
            leftPrompts.push(prompt);
          } else {
            rightPrompts.push(prompt);
          }
        });
        state.left_prompts = leftPrompts;
        state.right_prompts = rightPrompts;
        state.messagesStack = [];
        state.conversations = [];
        state.active_conversation_id = null;
        state.all_conversation = [];
      }
    );
    builder.addCase(apiDefaultPropmts.rejected, (state) => {
      state.loading.prompts = false;
    });

    // FILTER QUESTION WITH AI

    builder.addCase(filterQuestion.pending, (state) => {
      state.loading.question = true;
      state.req_success.question = false;
    });
    builder.addCase(
      filterQuestion.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.req_success.question = true;

        const filteredAnser = {
          id: uuidv4(),
          conversation_id: payload.data.conversation_id,
          message: payload.data.answer,
          ai: true,
          questionId: payload.data.id,
          question: payload.data.question,
        };
        state.messagesStack = state.messagesStack.map((message: any) => {
          if (message.questionId === filteredAnser.questionId && message.ai) {
            return filteredAnser;
          }
          return message;
        });

        state.loading.question = false;
        state.loaderId = null;
      }
    );
    builder.addCase(filterQuestion.rejected, (state) => {
      state.loading.question = false;
    });

    //  GET CONVERSATION BY CONVERSATION ID
    builder.addCase(fetchConversation.pending, (state) => {
      state.loading.conversation = true;
      state.req_success.conversation = false;
    });
    builder.addCase(
      fetchConversation.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.messagesStack = [];

        state.loading.conversation = false;
        state.req_success.conversation = true;

        state.active_conversation_id =
          payload.data[0].conversations[0].conversation_id;
        const temp: any = [];
        payload.data[0].conversations.forEach((conv: any) => {
          const userdata = {
            id: uuidv4(),
            user: true,
            questionId: conv.id,
            message: conv.question,
          };
          temp.push(userdata);
          const aidata = {
            id: uuidv4(),
            ai: true,
            questionId: conv.id,
            message: conv.answer,
            question: conv.question,
            conversation_id: conv.conversation_id,
          };
          temp.push(aidata);
        });
        state.messagesStack = [...temp];
        state.conversations = payload?.data;
      }
    );
    builder.addCase(fetchConversation.rejected, (state) => {
      state.loading.conversation = false;
    });

    // get recent_search data or conversation
    builder.addCase(getRecentSearch.pending, (state) => {
      state.loading.all_conversation = true;
      state.req_success.all_conversation = false;
    });
    builder.addCase(
      getRecentSearch.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.all_conversation = false;
        state.req_success.all_conversation = true;
        state.all_conversation = payload.data;
      }
    );
    builder.addCase(getRecentSearch.rejected, (state) => {
      state.loading.all_conversation = false;
    });

    // CLEAR ALL SEARCH DATA OR CONVERSATION
    builder.addCase(clearConversation.pending, (state) => {
      state.loading.clear_conversation = true;
      state.req_success.clear_conversation = false;
    });
    builder.addCase(
      clearConversation.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.clear_conversation = false;
        state.req_success.clear_conversation = true;
        state.messagesStack = [];
        state.all_conversation = [];
        state.active_conversation_id = null;
        state.response_generated = false;
      }
    );
    builder.addCase(clearConversation.rejected, (state) => {
      state.loading.clear_conversation = false;
    });
  },
});
export const {
  adduserQuestionToMessageStack,
  openNewChat,
  setLoaderId,
  askNewQuestionToAi,
  addAnswerToMessageStack,
  setActiveConversationId,
  setLoadingCurrentAnswer,
  addConversationToSidebar,
  setResponseGenerated,
  clearChattingScreen,
} = AiSlice.actions;
export default AiSlice.reducer;
