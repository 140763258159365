import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import TrainingAGroupComp from "../../../components/AdminPanel/TrainingAGroup/TrainingAGroupComp";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const TrainingAGroup = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <TrainingAGroupComp />
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default TrainingAGroup;
