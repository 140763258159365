import "./index.scss";
import certiDesign from "../../../../../assets/images/Shape.svg";
import agileLogo from "../../../../../assets/images/taclogo.svg";
import cetiClipoPath from "../../../../../assets/images/certi-clip-path.svg";
import vivek from "../../../../../assets/fonts/images/sign.png";
import pabitra from "../../../../../assets/fonts/images/sign1.png";

function Certificate(props: any) {
  const { certificate } = props;
  return (
    <div className="certificate">
      <div className="c-inner">
        <div className="big-title">CERTIFICATE OF ACHIEVEMENT</div>
        <div className="certi__ribbon">
          <img src={cetiClipoPath} alt="agile Logo" />

          <span> THIS CERTIFICATE IS PROUDLY PRESENTED TO</span>
        </div>
        <div className="name">{certificate.user_name}</div>
        <div className="certi__divider_holder">
          <div className="certi__divider" />
        </div>{" "}
        <div className="desc">
          We give this certificate to {certificate.user_name} for successfully
          completing the course{" "}
          <b>
            <i>“{certificate.course_name}”</i>
          </b>{" "}
          on {certificate.completed_at}
        </div>
        <div className="img">
          <img src={agileLogo} alt="agile Logo" />
        </div>
        <div className="sign-row">
          <div className="sign-column">
            <img src={vivek} alt="vivke" className="vivek__signature" />
            <div className="hrline" />
            <div className="signature">Co-Founder & Instructor</div>
          </div>
          <div className="sign-column">
            <img src={pabitra} alt="pabitra" className="pabitra__signature" />

            <div className="hrline" />
            <div className="signature">Co-Founder & Instructor</div>
          </div>
        </div>
        <div className="certidesign certidesign1">
          <img src={certiDesign} alt="certi design" />
        </div>
        <div className="certidesign certidesign2">
          <img src={certiDesign} alt="certi design" />
        </div>
        <div className="certidesign certidesign3">
          <img src={certiDesign} alt="certi design" />
        </div>
        <div className="certidesign certidesign4">
          <img src={certiDesign} alt="certi design" />
        </div>
      </div>
    </div>
  );
}

export default Certificate;
