/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect } from "react";

import Modal, { ModalBody } from "../../../../shared/Modals/Modal";

import timer from "../../../../assets/images/time-up-dark-img.svg";
import whitetimer from "../../../../assets/images/whitetimer.svg";
import { RootStore, useAppSelector } from "../../../../store/store";

const TimeOutModal = (props: any) => {
  const { showModal, setShowModal } = props;

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const handleModal = () => {};

  return (
    <Modal show={showModal} setShow={setShowModal} onClick={handleModal} bluishBgModal>
      <ModalBody modalWidth="332px">
        <div className="timer__modal__wrapper">
          <img
            className="alarm__image"
            src={themeState.themeMode === "dark" ? timer : whitetimer}
            alt="timer"
          />
          <span className="time_up">Times up!</span>
          <span className="time__desc">
            Maximum recording time limit has reached.
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default TimeOutModal;
