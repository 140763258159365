import React, { useState } from "react"
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout"
import BoxShadow from "../../../shared/BoxShadow/BoxShadow"
import WeeklyLearningHeader from "../../../components/AdminPanel/WeeklyLearning/Header/WeeklyLearningHeader"
import WeeklyLearningTable from "../../../components/AdminPanel/WeeklyLearning/Table/WeeklyLearningTable"
import ProductPagesDateTimeHeader from "../../../components/AdminPanel/ProductPagesDateTime/ProductPagesDateTimeHeader/ProductPagesDateTimeHeader"
import ProductPagesDateTimeTable from "../../../components/AdminPanel/ProductPagesDateTime/ProductPagesDateTimeTable/ProductPagesDateTimeTable"

const ProductPagesDateTime = () => {
  const [showEditModal,setShowEditModal] = useState(false);
  const [showModal,setShowModal]= useState(false);

  const handleEditModal = (id:any)=>{
    setShowModal(true);
    setShowEditModal(true);
  }

  return (
    <AdminPanelLayout>
    <BoxShadow>
      <div className="mt-7">
        <ProductPagesDateTimeHeader showModal={showModal} setShowModal={setShowModal} showEditModal={showEditModal} setShowEditModal={setShowEditModal}/>
        <ProductPagesDateTimeTable showEditModal={showEditModal} setShowEditModal={setShowEditModal}  handleEditModal={handleEditModal}/>
      </div>
    </BoxShadow>
  </AdminPanelLayout>
  )
}

export default ProductPagesDateTime
