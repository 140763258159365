import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import "./chortsHeader.scss";

import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import TextField from "../../../shared/Inputs/TextField/TextField";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { addOrUpdateCohorts } from "../../../store/features/AdminPanel/Cohorts/CohortsApi";
import { formOptions } from "./helper";
import { closeCoohortModal } from "../../../store/features/AdminPanel/Cohorts/CohortsSlice";

const CohortsHeader = () => {
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();
  const cohortState = useAppSelector((store: RootStore) => store.CohortsSlice);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const clearForm = () => {
    reset();
  };

  const onSubmit = (values: any) => {
    const year = values?.month_year.split("-")[0]
    const month = values?.month_year.split("-")[1];
    const day = values?.day;

    const data: any = {
      year: year,
      month: month,
      day: day==="" ? null :day,
    };
    dispatch(
      addOrUpdateCohorts({
        data: { content: data },
      })
    );
  };
  const handleModal = () => {
    clearForm();
    dispatch(closeCoohortModal())
  };

  useEffect(() => {
    if (!cohortState.loading.add) {
      setShowModal(false);
      clearForm();
      dispatch(closeCoohortModal())
    }
  }, [cohortState.loading.add]);

  useEffect(() => {
    if (cohortState?.edit) {
      setShowModal(true);

      const cohortContent = cohortState?.chorts?.content;

      // Parse the content if it's a valid JSON string, otherwise set it to null
      let parsedContent = null;
      if (typeof cohortContent === "string" && cohortContent !== "null") {
        try {
          parsedContent = JSON.parse(cohortContent);
          // const year = new Date().getFullYear();
          const year = parsedContent?.year;
          const month = parsedContent?.month;
          const day = parsedContent?.day;
          const editDate = `${year}-${month}`;
          setValue("month_year", editDate);
          setValue("day",day);
        } catch (error) {
          console.error("Error parsing cohort content:", error);
       }
      }
    }
  }, [cohortState?.edit, setValue]);

  return (
    <div className="team__header__container">
      <div className="left__section">
        <TableHeadingTitle>Cohort</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            disable={
              typeof cohortState?.chorts?.content === "string" &&
              cohortState?.chorts?.content !== "null"
            }
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal} onClick={handleModal}>
        <ModalHeader title="Add Cohort" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="flex__month__day">
              <div className="input__box">
                <TextField
                  label="Month"
                  type="month"
                  name="month_year"
                  register={register}
                  onKeyDown={() => false}
                  error={errors?.month_year}
                  min={new Date().toISOString().split("T")[0]?.slice(0, 7)}
                />
              </div>
              <div className="input__box">
                <TextField
                  label="Day"
                  type="number"
                  name="day"
                  register={register}
                  error={errors?.day}
                  onKeyDown={() => false}
                />
              </div>
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton
                text="Add"
                type="submit"
                loading={cohortState.loading.add}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CohortsHeader;
