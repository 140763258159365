/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import "../UserNotification/userNotification.scss";

import { useEffect, useLayoutEffect } from "react";
import { Icon } from "@iconify/react";

import { useNavigate } from "react-router-dom";
import { RootStore, useAppSelector } from "../../../store/store";
import "./adminPanelHeader.scss";
import useMediaQuery from "../../../hooks/MediaQuery";
import blackMenu from "../../../assets/icons/black-menu.svg";
import whiteMenu from "../../../assets/icons/white-menu.svg";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";
import Notification from "../../../shared/Notification/Notification";
import ThemeSwitchingButton from "./ThemeSwitchingButton";
import ProfileAndLogout from "./ProfileAndLogout";
import { roles } from "../../../helpers/utils/Constants/roles";
import OfferBanner from "./OfferBanner/OfferBanner";

const AdminPanelHeader = (props: any) => {
  const {
    smSidebar,
    setSmSidebar,
    showSidebarInSmDevice,
    setShowSidebarInSmDevice,
    iconRef,
    inMobileView,
  } = props;
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const navigate = useNavigate();
  const smDvice = useMediaQuery(820);
  const smDeviceMenuLeftIcon = useMediaQuery(820);

  // useLayoutEffect(() => {
  //   document.body.classList.remove("light");
  //   document.body.classList.remove("dark");
  //   document.body.classList.add(themeMode);
  // }, []);

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  return (
    <div className={`header__container ${smSidebar ? "layout__header__width__handle":"header__width__handle"}`}>
      <div className="toggle__section">
        <div className="logo__wrapper">
          <img
            onClick={() => {
              navigate("/");
            }}
            src={themeState?.configs?.sidebar_open_logo}
            className="logo"
            alt="theagilecoatchlogo"
          />
        </div>

        {!smDvice && (
          <Icon
            icon="gg:menu-left-alt"
            className="menu__button"
            onClick={() => setSmSidebar(!smSidebar)}
          />
        )}
      </div>
      {
        authState.tac_user?.plan === "free" && configState.configs?.membership_enabled === Domain.TAC &&
        authState.tac_user?.role !== roles.SUB_ADMIN && authState.tac_user?.role !== roles.SYSTEM_ADMIN &&authState.tac_user?.role !== roles.TENANT_ADMIN  && !inMobileView  &&(
          <>
            <OfferBanner />
            <div className="hide__text">.</div>
          </>
        )
      }
    
      <div className="right__section">
        {configState.configs?.membership_enabled === Domain.TAA &&
          authState.tac_user?.role !== roles.SUB_ADMIN && <Notification />}
        <ThemeSwitchingButton />
        <ProfileAndLogout />

        {smDeviceMenuLeftIcon && (
          <img
            ref={iconRef}
            src={themeState.themeMode === "dark" ? blackMenu : whiteMenu}
            className="menu__button"
            onClick={() => setShowSidebarInSmDevice(!showSidebarInSmDevice)}
            alt="menu"
          />
        )}
      </div>
    </div>
  );
};

export default AdminPanelHeader;
