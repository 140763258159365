/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Card } from "../../../shared/Card/Card";
import "./course.scss";
import onlineCourse from "../../../assets/icons/OnlineCourse.jpg";
import badgeIcon from "../../../assets/icons/badge-icon.png";
import AgilleCertifiedbadge from "./AgileCertifiedBadge/AgilleCertifiedbadge";
import Purchesbadge from "./AgileCertifiedBadge/PurchesBadge";
import { renderDynamicUrlFromComponentLevelState } from "../../../helpers/utils/urlHelper";
import { RootStore, useAppSelector } from "../../../store/store";
import axiosInstance from "../../../apiConfigs/axiosInstance";

const Course = (props: any) => {
  const { course, onClick, showTotalNumber } = props;
  const [loading, setLoading] = useState(false);
  const location: any = useLocation();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  // console.log(authState.tac_user?.plan,authState.tac_user?.plan !=="paid");
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const createStripeSession = async (e: any, courseId: any) => {
    e.stopPropagation();
    console.log(e);
    setLoading(true);
    try {
      const dynamic_url = renderDynamicUrlFromComponentLevelState(configState);
      const res = await axiosInstance.post(`${dynamic_url}/checkout-session`, {
        billing_type: "one-time",
        coupon: null,
        courseId: Number(courseId),
      });
      if (res.data.url) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      console.error("Error creating Stripe session:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="course_section" onClick={onClick}>
      <Card>
        <div className="course">
          <div className="image__wrapper">
            {location.pathname === "/all-premium-courses" && (
              <img src={badgeIcon} alt="" className="premium__image" />
            )}

            {showTotalNumber && (
              <img
                src={
                  course.bundle_card_image_url?.startsWith("https", 0)
                    ? course?.bundle_card_image_url
                    : onlineCourse
                }
                alt="."
                className="course__thumbnail"
              />
            )}
            {!showTotalNumber && (
              <img
                src={
                  course.course_card_image_url?.startsWith("https", 0)
                    ? course?.course_card_image_url
                    : onlineCourse
                }
                alt="."
                className="course__thumbnail"
              />
            )}
          </div>

          <div
            className={`course__info ${
              location.pathname === "/all-premium-courses" &&
              "course__info__wrapper"
            }`}
          >
            <div className="name__tag">
            <span className="course__title">{course.name}</span>
              {course?.purchased ||
                (course?.individual_course_purchased && (
                  <div className="badge__wrapper">
                    <Purchesbadge text="Purchased" />
                  </div>
                ))}
      
            </div>

            <span className="course_secondary__title">
              {!showTotalNumber && `Duration ${course.custom_duration}`}
              {showTotalNumber && ` ${course.path_courses.length} Courses`}
            </span>

            <span className="course__description">{course.description}</span>
            {location.pathname === "/all-premium-courses" && (
              <div
                className={`premium__paid__info 
              ${course?.description === null && "premium__badge"}`}
              >
                <AgilleCertifiedbadge text="Agile Certified" />
              </div>
            )}
            {(authState.tac_user?.plan !== "paid" && (!course?.individual_course_purchased  &&
              course?.individual_course_price !== false &&
              location.pathname === "/list-all-courses" )) && (
                <div className="premium__paid__info">
                  <button
                    type="button"
                    className="buy__now__btn"
                    onClick={(e: any) =>
                      createStripeSession(e, course?.course_id)
                    }
                  >
                    {loading ? <div className="spinner mr-1" /> : "Buy Now"}
                  </button>
                  <p className="premium__price">
                    $ {course?.individual_course_price}
                  </p>
                </div>
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Course;
