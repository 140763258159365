import React from "react";
import courseDarkFile from "../../../../assets/images/darkLearningResourceEmptyImg.svg";
import courseLightFile from "../../../../assets/images/courseLightFile.svg";
import "./noresultfound.scss";

function NoResultFound(props: any) {
  return (
    <div className="noresult-found">
      <div className="nrf-img">
        <img className="nrf-image-dark" src={courseDarkFile} alt="file" />
        <img className="nrf-image-light" src={courseLightFile} alt="file" />
      </div>
      <div className="nrf-text">No results found.</div>
    </div>
  );
}

export default NoResultFound;
