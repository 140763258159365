import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import ListBlog from "../../../components/UserDashboard/Blog/ListBlog/ListBlog";

const ListAllBlogs = () => {
  return (
    <div>
      {" "}
      <AdminPanelLayout>
        <ListBlog />
      </AdminPanelLayout>
    </div>
  );
};

export default ListAllBlogs;
