/* eslint-disable @typescript-eslint/naming-convention */
const STRIPE_KEY = "pk_live_LEwSa3aGCmrIXeLk0mK9CFrl";
const STRIPE_TEST_KEY = "pk_test_cWMf2NCJ3817W7b3zKYLa2Gz";
export const stripeKey = (): string => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return STRIPE_TEST_KEY;
  }
  if (
    window.location.href.includes("http://academy.theagilecoach.com") ||
    window.location.href.includes("https://academy.theagilecoach.com")
  ) {
    return STRIPE_KEY;
  }

  return STRIPE_TEST_KEY;
};
