import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLearningPathForStudent,
  getLearningPathDetailForStudent,
} from "./LearningPathApi";

const initialState: any = {
  loading: false,
  req_success: false,
  all_learningPaths: [],
  currentPath: {},
  learningPathDetailCourses: [],
  instructors: [],
};
// listLearningPath;
const tenantUserLearningPathSlice = createSlice({
  name: "learningPathSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Student Learning Path
    builder.addCase(getLearningPathForStudent.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getLearningPathForStudent.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_learningPaths = payload?.data;
      },
    );
    builder.addCase(getLearningPathForStudent.rejected, (state) => {
      state.loading = false;
    });

    // Student Learning Path Courses Detail
    builder.addCase(getLearningPathDetailForStudent.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getLearningPathDetailForStudent.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.currentPath = payload?.learning_path;
        state.learningPathDetailCourses = payload?.courses;
        state.instructors = payload?.instructors;
      },
    );
    builder.addCase(getLearningPathDetailForStudent.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default tenantUserLearningPathSlice.reducer;
