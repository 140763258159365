import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getMeetingLinks,
  getPaymentById,
  updateMeeting,
  deleteMeetingLink,
  AddMeetingLink,
} from "./MeetWithUsApi";

const initialState: any = {
  loading: {
    get: false,
    get_by_id: false,
    delete: false,
    add: false,
  },
  req_success: {
    get: false,
    get_by_id: false,
    delete: false,
    add: false,
  },
  current_page: 0,
  all_meetings: [],
  current_payment: [],
  temp_meetings: [],
  meetings: [],
  isEdit: false,
  isChanged: false,
  active_meeting: null,
};

const MeetWithUsSlice = createSlice({
  name: "MeetWithUsSlice",
  initialState,
  reducers: {
    editMeeting: (state: any, { payload }: PayloadAction<any>) => {
      state.isEdit = true;
      state.active_meeting = payload;
    },
    closeMeetingModal: (state: any) => {
      state.isEdit = false;
      state.active_meeting = null;
    },

    meetTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.meetings = state.all_meetings.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.meetings = state.all_meetings.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.meetings = state.all_meetings.slice(
          payload * 10,
          10 * payload + 10
        );
      }
    },
    searchMeetingInTable: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length >= 1) {
        state.temp_meetings = state.all_meetings.filter((meeting: any) =>
          meeting.name.includes(payload?.toLowerCase())
        );
        state.meetings = state.temp_meetings.slice(0, 10);
      } else {
        state.meetings = state.all_meetings.slice(
          state.current_page * 10,
          10 * state.current_page + 10
        );
      }
    },
  },
  extraReducers: (builder) => {
    // GET All MEETING LINKS
    builder.addCase(getMeetingLinks.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getMeetingLinks.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.all_meetings = payload.data;
        state.meetings = state.all_meetings.slice(0, 10);
      }
    );
    builder.addCase(getMeetingLinks.rejected, (state) => {
      state.loading.get = false;
    });

    // Update a meeting
    builder.addCase(updateMeeting.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      updateMeeting.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
        state.isEdit = false;
        state.active_meeting = null;
        state.all_meetings = state.all_meetings.map((meet: any) => {
          if (meet.id === payload?.data.id) {
            return payload.data;
          }
          return meet;
        });

        state.meetings = state.all_meetings.map((meet: any) => {
          if (meet.id === payload?.data.id) {
            return payload.data;
          }
          return meet;
        });
      }
    );
    builder.addCase(updateMeeting.rejected, (state) => {
      state.loading.add = false;
    });

    // Delete a meeting
    builder.addCase(deleteMeetingLink.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteMeetingLink.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_meetings = state.all_meetings.filter(
          (meet: any) => meet.id !== payload?.id
        );
        if (state.current_page === 0) {
          state.meetings = state.all_meetings.slice(0, 10);
        } else {
          state.meetings = state.all_meetings.slice(
            state.current_page * 10,
            state.current_page * 10 + 10
          );
        }
      }
    );
    builder.addCase(deleteMeetingLink.rejected, (state) => {
      state.loading.delete = false;
    });

    // ADD MEETING LINKS
    builder.addCase(AddMeetingLink.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      AddMeetingLink.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;

        if (payload) {
          state.all_meetings = [payload.data, ...state.all_meetings];

          if (state.current_page === 0) {
            state.meetings = state.all_meetings.slice(0, 10);
          } else {
            state.meetings = state.all_meetings.slice(
              state.current_page * 10,
              state.current_page * 10 + 10
            );
          }
        }
        // state.isChanged = !state.isChanged;
      }
    );
    builder.addCase(AddMeetingLink.rejected, (state) => {
      state.loading.add = false;
    });
  },
});
export const {
  meetTablePaginate,
  searchMeetingInTable,
  editMeeting,
  closeMeetingModal,
} = MeetWithUsSlice.actions;
export default MeetWithUsSlice.reducer;
