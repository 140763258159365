import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getRecentlyAnsweredInterviews,
  getRecentlyAnsweredDetails,
  deleteInterviewAnswerVideo,
  deleteInterviewAnswerFeedback,
  createInterviewDetailsFeedback,
  updateRecentShareLink
} from "./InterviewDetails.actions";

type RecentlyAnswered = {
  id: number;
  name: string;
  last_updated: string;
};

type RecentlyAnsweredDetails = {
  user: any;
  total_questions: number;
  question_answers: any;
};

type State = {
  loading: boolean;
  update_url_loading: boolean;
  update_delete: boolean;
  recentlyAnswered: RecentlyAnswered[];
  recentlyAnsweredUsers:RecentlyAnswered[],
  recentlyAnsweredDetails: RecentlyAnsweredDetails;
  selectedFeedback: any;
};

const initialState: State = {
  loading: false,
  update_url_loading: false,
  update_delete: false,
  recentlyAnswered: [],
  recentlyAnsweredUsers:[],
  recentlyAnsweredDetails: {
    user: {},
    total_questions: 0,
    question_answers: [],
  },
  selectedFeedback: null,
};

const InterviewDetailsSlice = createSlice({
  name: "InterviewDetailsSlice",
  initialState,
  reducers: {
    clearRecentlyAnsweredDetails: (state: State) => {
      state.recentlyAnsweredDetails = initialState.recentlyAnsweredDetails;
    },
    setSelectedFeedback: (state: State, { payload }) => {
      state.selectedFeedback = payload;
    },
    searchRecentlyAnswerdInterviewUsers: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.recentlyAnswered = state.recentlyAnsweredUsers.filter((user: any) =>
          user.name.toLowerCase().includes(payload.toLowerCase())
        );
      } else {
        state.recentlyAnswered = state.recentlyAnsweredUsers;
      }
    },
  },
  extraReducers: (builder) => {
    // GET RECENTLY ANSWERED INTERVIEWS
    builder.addCase(getRecentlyAnsweredInterviews.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getRecentlyAnsweredInterviews.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.recentlyAnswered = payload?.data;
        state.recentlyAnsweredUsers = payload?.data;
        // console.log(state.recentlyAnswered,state.recentlyAnsweredUsers)
      }
    );
    builder.addCase(getRecentlyAnsweredInterviews.rejected, (state) => {
      state.loading = false;
    });

    // GET RECENTLY ANSWERED INTERVIEWS DETAILS
    builder.addCase(getRecentlyAnsweredDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getRecentlyAnsweredDetails.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.recentlyAnsweredDetails = payload?.data;
      }
    );
    builder.addCase(getRecentlyAnsweredDetails.rejected, (state) => {
      state.loading = false;
    });

    // UPDATE share url
    builder.addCase(updateRecentShareLink.pending, (state) => {
      state.update_url_loading = false;
    });
    builder.addCase(
      updateRecentShareLink.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.update_url_loading = true;
        state.recentlyAnsweredDetails.question_answers = state.recentlyAnsweredDetails.question_answers.map((question: any) => {
          if (question.id === payload.question_id) {
            question.answers.map((answer: any) => {
              if (answer.id === payload.answer_id) {
                answer.shareable_link = payload.url;
              }
              return answer;
            })
          }
          return question;
        });
      }
    );

    // REMOVE INTERVIW ANSWER VIDEO
    builder.addCase(deleteInterviewAnswerVideo.pending, (state) => {
      state.loading = true;
      state.update_delete = true;
    });
    builder.addCase(
      deleteInterviewAnswerVideo.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.update_delete = false;
      }
    );
    builder.addCase(deleteInterviewAnswerVideo.rejected, (state) => {
      state.loading = false;
      state.update_delete = false;
    });

    // REMOVE INTERVIW ANSWER FEEDBACK
    builder.addCase(deleteInterviewAnswerFeedback.pending, (state) => {
      state.loading = true;
      state.update_delete = true;
    });
    builder.addCase(
      deleteInterviewAnswerFeedback.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.update_delete = false;
      }
    );
    builder.addCase(deleteInterviewAnswerFeedback.rejected, (state) => {
      state.loading = false;
      state.update_delete = false;
    });

    // ADD INTERVIW ANSWER FEEDBACK
    builder.addCase(createInterviewDetailsFeedback.pending, (state) => {
      state.loading = true;
      state.update_delete = true;
    });
    builder.addCase(
      createInterviewDetailsFeedback.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.update_delete = false;
        state.selectedFeedback = null;
      }
    );
    builder.addCase(createInterviewDetailsFeedback.rejected, (state) => {
      state.loading = false;
      state.update_delete = false;
    });
  },
});

export const { clearRecentlyAnsweredDetails, setSelectedFeedback ,searchRecentlyAnswerdInterviewUsers} =
  InterviewDetailsSlice.actions;

export default InterviewDetailsSlice.reducer;
