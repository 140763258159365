import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadClasses, addClasses, updateClasses, deleteClasses } from "./OurClassApi";

const initialState: any = {
  loading: false,
  req_success: false,
  add:false,
  update:false,
  classes: [],
  all_classes: [],
  temp: [],
  class_update_temp: [],
  editClass: false,
  single_class_info:{}
};

const OurClassSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    editClassInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.editClass = true;
      state.single_class_info = payload;
      
    },
    closeClassEditModal: (state: any, { payload }: PayloadAction<any>) => {
      state.editClass = payload;
    },
  },
  extraReducers: (builder) => {
    // LOAD CLASSES
    builder.addCase(loadClasses.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      loadClasses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_classes = payload?.data;
        state.classes = payload?.data;
        //  state.faqs = payload?.data.filter(
        //    (data: any) => data.for === "landing_page",
        //  );
      },
    );
    builder.addCase(loadClasses.rejected, (state) => {
      state.loading = false;
    });

    // ADD CLASSES
    builder.addCase(addClasses.pending, (state) => {
      state.loading = true;
      state.req_success = false;
      state.add=false;
      state.update=false;
    });
    builder.addCase(
      addClasses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.add=true;
        state.update=false;
        state.all_classes.push(payload?.data);
        state.classes = state.all_classes;
      },
    );
    builder.addCase(addClasses.rejected, (state) => {
      state.loading = false;
      state.add=false;
      state.update=false;
    });

    // UPDATE CLASSES
    builder.addCase(updateClasses.pending, (state) => {
      state.loading = true;
      state.req_success = false;
      state.add=false;
      state.update=false;
    });
    builder.addCase(
      updateClasses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.add=false;
        state.update=true;
        state.classes = state.classes.map((clas: any) => {
          if (clas.id === payload?.data.id) {
            return payload?.data;
          }
          return clas;
        });

        state.all_classes = state.classes.map((clas: any) => {
          if (clas.id === payload?.data.id) {
            return payload?.data;
          }
          return clas;
        });
      },
    );
    builder.addCase(updateClasses.rejected, (state) => {
      state.loading = false;
      state.add=false;
      state.update=false;
    });

    // DELETE CLASSES
    builder.addCase(deleteClasses.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      deleteClasses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_classes = state.classes.filter(
          (clas: any) => clas.id !== payload?.id,
        );

        state.classes = state.classes.filter((clas: any) => clas.id !== payload?.id);
      },
    );
    builder.addCase(deleteClasses.rejected, (state) => {
      state.loading = false;
    });
  },
});
export const {editClassInfo,closeClassEditModal} = OurClassSlice.actions;
export default OurClassSlice.reducer;



