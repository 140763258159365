import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../store/store";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import ViewApplyNowDetails from "../../../components/AdminPanel/ApplyNow/ViewDetails/ViewApplyNowDetails";
import { getApplyNowDetails } from "../../../store/features/AdminPanel/ApplyNow/ApplyNowApi";

const ViewDetails = () => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getApplyNowDetails(id));
  }, [dispatch, id]);
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="mt-7">
          <ViewApplyNowDetails />
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default ViewDetails;
