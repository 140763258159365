import { BASE_HOST } from "../../apiConfigs/urlConfig";

export const checkTenant = () => {
  const user = {
    isNormal: false,
    isTenant: false,
    tenant: "",
  };

  const host: any = window.location.hostname;
  const checkhost = host.split(".");
  if (checkhost[0] === BASE_HOST) {
    user.isNormal = true;
  } else {
    user.isTenant = true;
    const host = checkhost[0];
    user.tenant = host;
  }
  return user;
};
