/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import Modal, {
  ModalHeader,
  ModalBody,
} from "../../../../../shared/Modals/Modal";
import TextField from "../../../../../shared/Inputs/TextField/TextField";
import SearchBar from "../../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import "./BlogTableHeader.scss";
import { REQUIRED_VALIDATION } from "../../../../../helpers/utils/formUtils";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  AddAuthors,
  getAuthors,
  updateAuthors,
} from "../../../../../store/features/AdminPanel/Blog/Authors/AuthorsApi";
import {
  closeAuthorEditModal,
  searchAuthorsInTable,
} from "../../../../../store/features/AdminPanel/Blog/Authors/AuthorSlice";
import {
  getBase64,
  getFileExtension,
} from "../../../../../helpers/utils/FileHelper";
import ErrorText from "../../../../../shared/Inputs/ErrorText/ErrorText";

const BlogAuthorHeader = () => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageExt, setImageExt] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [searchText, setSearchText] = useState("");
  const [errorText, setErrorText] = useState("");
  const hiddenFileInput = useRef<any>(null);
  const authorState: any = useAppSelector((store: RootStore) => {
    return store.AuthorSlice;
  });
  const handleClick = (event: any) => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const openModal = () => {
    setShowModal(true);
  };

  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];
    if (contentFile.size > 2 * 1024 * 1024) {
      setErrorText("Image size must be less than 2 MB");
      return;
    }
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const result = URL.createObjectURL(contentFile);

    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageUrl(result);
    setErrorText("");
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Author Name")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  useEffect(() => {
    dispatch(searchAuthorsInTable(searchText));
  }, [searchText]);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const clearForm = () => {
    reset();
    setImageUrl("");
    setImageExt("");
    setImageFile("");
    setErrorText("");
  };
  const removeImage = (e: any) => {
    setImageUrl("");
    setImageExt("");
    setImageFile("");
    setErrorText("Image is required");
  };
  useEffect(() => {
    if (authorState.editAuthor) {
      setValue("name", authorState.single_author_info?.name);
      setImageUrl(authorState.single_author_info?.avatar_url);

      setShowModal(true);
    }
  }, [authorState.editAuthor]);
  const onSubmit = (values: any) => {
    if (!authorState.editAuthor) {
      if (!imageFile) {
        setErrorText("Image is required");
        return;
      }
      const data = {
        ...values,
        avatar_url: imageFile,
        extension: imageExt,
      };
      dispatch(AddAuthors(data));
    }
    if (authorState.editAuthor) {
      let data;
      if (imageFile) {
        data = {
          ...values,
          avatar_url: imageFile,
          extension: imageExt,
          _method: "put",
        };
      }
      if (!imageFile) {
        data = {
          ...values,
          _method: "put",
        };
      }

      dispatch(
        updateAuthors({
          authorId: authorState.single_author_info?.id,
          data: data,
        }),
      );
    }
  };
  const handleModal = () => {
    dispatch(closeAuthorEditModal(false));
    clearForm();
  };
  useEffect(() => {
    dispatch(getAuthors());
  }, []);

  useEffect(() => {
    if (authorState.req_success.add) {
      setShowModal(false);
      clearForm();
    }
  }, [authorState.req_success.add]);

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  return (
    <div>
      <div className="author_blog_header_container">
        <div className="left__section">
          <TableHeadingTitle>Author Listing</TableHeadingTitle>
        </div>
        <div className="right__section">
          <div className="search">
            <SearchBar value={searchText} setValue={setSearchText} />
          </div>
          <div className="add_">
            <PrimaryButton
              text="Add"
              size="sm"
              type="button"
              // loading={courseState.pull_loading}
              onClick={openModal}
            />
          </div>
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal} onClick={handleModal}>
        <ModalHeader title="Edit Author" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form__group">
              <TextField
                label="Author Name"
                name="name"
                type="text"
                register={register}
                error={errors?.name}
              />
            </div>
            <div className="form__group">
              <p className="label_author">Avatar</p>
              <div className="upload_avatar_sec">
                <img
                  className="author_img"
                  src={imageUrl || "https://via.placeholder.com/150"}
                  alt="avatar"
                />
                <span>
                  <p className="upload_avatar" onClick={handleClick}>
                    Upload new avatar
                  </p>
                  {!authorState.editAuthor && (
                    <p className="remove_avatar" onClick={removeImage}>
                      Remove
                    </p>
                  )}
                </span>
              </div>
              <input
                type="file"
                id="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={(e: any) => getFiles(e)}
                style={{ display: "none" }}
              />
            </div>
            <ErrorText error={errorText} />

            <div
              className={`add_button__witdh mt-1 ${
                authorState.editAuthor ? "update" : ""
              }`}
            >
              <PrimaryButton
                text={authorState.editAuthor ? "Update" : "Add"}
                type="submit"
                loading={authorState.loading.add}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BlogAuthorHeader;
