/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import "./index.scss";
import { Check, Copy } from "react-feather";

import {
  useAppDispatch,
  useAppSelector,
  RootStore
} from "../../../../../../../../store/store";
import { updateShareLink } from "../../../../../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerApi";
import { updateRecentShareLink } from "../../../../../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.actions";
import Modal, {
  ModalHeader,
} from "../../../../../../../../shared/Modals/Modal";
import { PrimaryButton } from "../../../../../../../../shared/Buttons/Buttons";

const LinkGenerateModal = (props: any) => {
  const { showModal, setShowModal, closeModal, videoId, questionId, shareableLink, recentAnswerSection = false } = props;
  const [link, setLink] = useState("");
  const [copied, setCopied] = useState(false);

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewAnswerSlice;
  });

  const InterviewDetailState = useAppSelector((store: RootStore) => {
    return store.InterviewDetailsSlice;
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLink(shareableLink ?? "");
  }, [videoId]);

  function conyGeneratedShareLink() {
    navigator.clipboard.writeText(link);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  useEffect(() => {
    if (InterviewDetailState.update_url_loading) {
      InterviewDetailState.recentlyAnsweredDetails.question_answers.map((data: any) => {
        if (data.id === questionId) {
          data.answers.map((answer: any) => {
            if (answer.id === videoId) {
              setLink(answer.shareable_link);
            }
            return true;
          })
        }
        return true;
      })
    }
  }, [InterviewDetailState.update_url_loading]);

  useEffect(() => {
    if (InterviewState.req_success.update_url) {
      InterviewState.question_answers.map((data: any) => {
        if (data.id === questionId) {
          data.answers.map((answer: any) => {
            if (answer.id === videoId) {
              setLink(answer.shareable_link);
            }
            return true;
          })
        }
        return true;
      })
    }
  }, [InterviewState.req_success.update_url]);

  const generateShareLink = () => {
    if (recentAnswerSection)  {
      dispatch(updateRecentShareLink({
        videoId: videoId
      }));
    } else {
      dispatch(updateShareLink({
        videoId: videoId
      }));
    }
  }

  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      onClick={closeModal}
      width="modal__contetn__width__handle__generate__link"
    >
      <ModalHeader title="Share Response" />
      <p className="generate__link__text">
        Please click the "Generate Link" button to obtain the shareable link for
        the response.
      </p>

      <div className="generate__link__buttn">
        {/* for Disable button add disable={true}  */}
        <PrimaryButton text="Generate Link" type="button" onClick={generateShareLink} />
      </div>
      <div className="link__input">
        <label htmlFor="link">URL</label>
        <input type="text" value={link} readOnly />
        <div className="copy__icon" onClick={()=>conyGeneratedShareLink()}>
          {
            copied ? <Check/>  :<Copy/>
          }
        </div>
      </div>

      <div className="done__button" >
        <div className="done__button__width">
          <PrimaryButton text="Done" type="button" onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default LinkGenerateModal;
