const GetSuperText = (props: any) => {
  const { number } = props;
  const getsuperScriptlabel = () => {
    if (number === 2) {
      return "nd";
    }
    if (number === 3) {
      return "rd";
    }
    return "st";
  };

  return (
    <div className="sup__test">
      {number}
      <sup>{getsuperScriptlabel()}</sup> Response
    </div>
  );
};
export default GetSuperText;
