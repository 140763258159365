import React, { useEffect } from "react";
import "./learningPathComp.scss";
import LearningPathArchive from "./LearningPathArchive/LearningPathArchive";
import SupportBox from "./SupportBox/SupportBox";
import InstructorsInfo from "./InstructorsInfo/InstructorsInfo";
import LearningPathCourseSteppers from "./LearningPathCourseSteppers/LearningPathCourseSteppers";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  getClassArchiveForProductManager,
  getProductManagerLearningPathCourses,
} from "../../../store/features/ProductManagerUser/ProductManagerCourses/ProductManagerCoursesApi";

const LearningPathComp = () => {
  const dispatch = useAppDispatch();

  const learningPathState: any = useAppSelector((store: RootStore) => {
    return store.TACProductManagerSlice;
  });

  useEffect(() => {
    dispatch(getProductManagerLearningPathCourses());
    dispatch(getClassArchiveForProductManager());
  }, []);
  return (
    <div className="learning__path__steppers__wrapper mt-7 ">
      <LearningPathCourseSteppers />

      <div className="learning__path__steppers__right__section">
        {learningPathState.req_success.course && (
          <InstructorsInfo instructors={learningPathState.instructors} />
        )}
        {learningPathState.req_success.course &&
          learningPathState.req_success.archive && (
            <LearningPathArchive archives={learningPathState.archives} />
          )}

        {learningPathState.req_success.archive &&
          learningPathState.req_success.course && <SupportBox />}
      </div>
    </div>
  );
};

export default LearningPathComp;
