import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const getInterviewQuestionByRoleId = createAsyncThunk(
  "getInterviewQuestionByRoleId",
  async ({ role_id }: { role_id: number }, { dispatch, rejectWithValue ,getState}) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/interview-questions-by-role/${role_id}`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInterviewRoles = createAsyncThunk(
  "getInterviewRoles",
  async (undefined, { dispatch, rejectWithValue,getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/interview-roles`);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const addOrupdateInterviewRoles = createAsyncThunk(
  "addOrupdateInterviewRoles",
  async ({data}:{data:any}, { dispatch, rejectWithValue,getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/interview-role`,data);
      REQUIRED__TOASTER(res.data);
      if(data?.id){
        res.data.idForUpdate = data?.id;

      }
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteRole = createAsyncThunk(
  "deleteRole",
  async ({id}:{id:any}, { dispatch, rejectWithValue,getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(`${dynamic_url}/interview-role/${id}`);
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addOrUpdateQuestions = createAsyncThunk(
  "addOrUpdateQuestions",
  async (
    { data, idForUpdate }: { data: any; idForUpdate: number },
    { dispatch, rejectWithValue,getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/interview-questions`,
        data
      );
      REQUIRED__TOASTER(res.data);
      res.data.idForUpdate = idForUpdate;
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "deleteQuestion",
  async ({ id }: { id: any }, { dispatch, rejectWithValue,getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(
        `${dynamic_url}/interview-questions/${id}`
      );
      REQUIRED__TOASTER(res.data);
      res.data.id = id;
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMultipleQuestion = createAsyncThunk(
  "deleteMultipleQuestion",
  async ({ ids }: { ids: any }, { dispatch, rejectWithValue,getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/interview-questions/bulk-delete`,
        {
          ids: ids
        }
      );
      REQUIRED__TOASTER(res.data);
      res.data.ids = ids;
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);