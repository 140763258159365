import "./filterallcourses.scss";

import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

import {
  includesElement,
  mapHoursAndMinutes,
} from "../../../../helpers/utils/extras";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { getLps } from "../../../../store/features/UserDashboard/NewUserCoursesAndLearningPath/NewUserCoursesAndLearningPathApi";
import {
  updateCoursesList,
  updateFilterActive,
} from "../../../../store/features/UserDashboard/NewUserCoursesAndLearningPath/NewUserCoursesAndLearningPathSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-restricted-syntax */
function FilterAllCourses(props: any) {
  const [isOpen, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredCourses, setFilteredCourse] = useState<any[]>([]);
  const [filterActive, setFilterActive] = useState<boolean>(false);
  const [statusArray, setStatus] = useState<Number[]>([]);
  const [durationArray, setDuration] = useState<Number[]>([]);
  const [paths, setPaths] = useState<Number[]>([]);

  const dispatch = useAppDispatch();
  const courseandpathState: any = useAppSelector((store: RootStore) => {
    return store.NewUserCoursesAndLearningPathSlice;
  });

  const handleStatusChange = (e: any) => {
    const temp = statusArray;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setStatus([...temp]);
  };

  const handleDurationChange = (e: any) => {
    const temp = durationArray;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setDuration([...temp]);
  };

  const handlePathClick = (e: number) => {
    const temp = paths;
    const val = Number(e);
    if (temp.includes(val)) {
      const index = temp.indexOf(val);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else {
      temp.push(val);
    }
    setPaths([...temp]);
  };

  const resetFilter = () => {
    setStatus(() => []);
    setPaths(() => []);
    setDuration(() => []);
    setSearchText("");
  };

  const filterCourses = () => {
    const filteredCourses = [];
    for (const course of courseandpathState.all_courses) {
      let status;
      if (course.percentage_completed === 0) {
        status = 0;
      } else if (
        course.percentage_completed > 0 &&
        course.percentage_completed < 100
      ) {
        status = 1;
      } else {
        status = 2;
      }

      const course_duration = mapHoursAndMinutes(course.duration);
      let duration;
      if (course_duration >= 0 && course_duration < 2) {
        duration = 0;
      } else if (course_duration >= 2 && course_duration < 4) {
        duration = 1;
      } else {
        duration = 2;
      }

      let statusCheck = false;
      let durationCheck = false;
      let pathsCheck = false;

      if (statusArray.length && statusArray.includes(status))
        statusCheck = true;

      if (!statusArray.length) statusCheck = true;

      if (durationArray.length && durationArray.includes(duration))
        durationCheck = true;

      if (!durationArray.length) durationCheck = true;

      if (paths) {
        if (course.path_ids) {
          if (paths.length && includesElement(paths, course.path_ids))
            pathsCheck = true;
        }
      }

      if (!paths.length) pathsCheck = true;

      if (statusCheck && durationCheck && pathsCheck)
        filteredCourses.push(course);
    }

    let searched = courseandpathState.all_courses;

    if (filterActive) searched = filteredCourses;

    if (searchText !== "") {
      searched = searched.filter((course: any) =>
        course.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilteredCourse(searched);
  };
  useEffect(() => {
    dispatch(updateCoursesList(filteredCourses));
  }, [filteredCourses]);

  useEffect(() => {
    if (!courseandpathState.loading && courseandpathState.req_success)
      setFilteredCourse(courseandpathState.all_courses);
  }, [courseandpathState.loading]);

  useEffect(() => {
    if (
      statusArray.length ||
      durationArray.length ||
      paths.length ||
      searchText.length
    ) {
      setFilterActive(true);
      dispatch(updateFilterActive(true));
    }
    if (
      !statusArray.length &&
      !durationArray.length &&
      !paths.length &&
      searchText.length < 0
    ) {
      setFilterActive(false);
      dispatch(updateFilterActive(false));
    }
    filterCourses();
  }, [
    filterActive,
    searchText,
    statusArray,
    durationArray,
    paths,
    courseandpathState.loading,
  ]);

  const setStateValues = () => {
    if (window.innerWidth < 1100) {
      setIsMobile(true);
      setOpen(false);
    } else {
      setIsMobile(false);
      setOpen(true);
    }
  };

  window.addEventListener("resize", () => {
    setStateValues();
  });

  window.addEventListener("load", () => {
    setStateValues();
  });
  useEffect(() => {
    setStateValues();
    dispatch(getLps("user"));
  }, []);
  return (
    <>
      {/* <div className="filter-search">
        <Search />
        <input type="text" placeholder="Search" value={searchText} />
      </div> */}
      <SearchBar value={searchText} setValue={setSearchText} selectSearch/>
      <div className="filter-row">
        <div
          className="fr-left"
          onClick={() => {
            if (isMobile) {
              setOpen(!isOpen);
            }
          }}
        >
          Filter by {isMobile && (isOpen ? <ChevronUp /> : <ChevronDown />)}
        </div>
        <button type="button" onClick={resetFilter}>
          Reset
        </button>
      </div>
      {isOpen && (
        <div className="filter-bottom">
          <div className="filter-option-container">
            <h3>Status</h3>
            <div className="filter-options">
              <CheckBox
                label="Ready to start"
                fullwidth
                CheckboxValue={statusArray.indexOf(0) !== -1}
                onClick={() => handleStatusChange(0)}
                border
              />
              <CheckBox
                label="Resume"
                fullwidth
                CheckboxValue={statusArray.indexOf(1) !== -1}
                onClick={() => handleStatusChange(1)}
                border
              />
              <CheckBox
                label="Completed"
                fullwidth
                CheckboxValue={statusArray.indexOf(2) !== -1}
                onClick={() => handleStatusChange(2)}
                border
              />
            </div>
          </div>
          <div className="filter-option-container">
            <h3>Learning paths</h3>
            <div className="filter-options">
              {courseandpathState.learningPaths.map((path: any) => {
                return (
                  <CheckBox
                    key={path.path_id}
                    label={path.name.replace("Learning Path", "")}
                    fullwidth
                    CheckboxValue={paths.indexOf(path.path_id) !== -1}
                    onClick={() => handlePathClick(path.path_id)}
                    border
                  />
                );
              })}
            </div>
          </div>
          <div className="filter-option-container">
            <h3>Course hours</h3>
            <div className="filter-options">
              <CheckBox
                label="0-2 hours"
                fullwidth
                CheckboxValue={durationArray.indexOf(0) !== -1}
                onClick={() => handleDurationChange(0)}
                border
              />
              <CheckBox
                label="2-4 hours"
                fullwidth
                CheckboxValue={durationArray.indexOf(1) !== -1}
                onClick={() => handleDurationChange(1)}
                border
              />
              <CheckBox
                label="4+ hours"
                fullwidth
                CheckboxValue={durationArray.indexOf(2) !== -1}
                onClick={() => handleDurationChange(2)}
                border
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FilterAllCourses;
