import React, { useEffect, useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import { useForm } from "react-hook-form";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromComponentLevelState } from "../../../../../helpers/utils/urlHelper";

import { InputLabel } from "../../../../../shared/Inputs/InputLabel/InputLabel";
import RadioButton from "../../../../../shared/Inputs/RadioButton/RadioButton";
import StripeCard from "../Stripe/StripeCard";

import {
  DangerButton,
  TACPrimaryButton,
} from "../../../../../shared/Buttons/Buttons";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  checkUserPricing,
  upgradeAccount,
} from "../../../../../store/features/UserDashboard/Payment/PaymentApi";
import {
  activatePaidPlanForUser,
  deActivatePaidPlanForUser,
} from "../../../../../store/features/Auth/AuthSlice";
import CancelSubscriptionModal from "../CancelSubscription/CancelSubscriptionModal";
import CouponSection from "./CouponSection";
import BillingTypeButton from "./BillingTypeButton";
import { formOptions } from "./helper";
import TextField from "../../../../../shared/Inputs/TextField/TextField";
import { TENANTPLANSTATUS } from "../../../../../helpers/utils/Constants/tenantPlanStatus";
import Skleton from "../../../../../shared/Skelton/Skleton";
import {
  CheckoutBillingSkleton,
  PaymentMethodSkleton,
} from "./Skleton/CheckoutSkleton";

const CheckoutForm = (props: any) => {
  const { setShowModal, tenant, size, billingType } = props;
  const [monthly, setMonthly] = useState(false);
  const [annualy, setAnnualy] = useState(false);
  const [loading,setLoading] = useState(false);
  const [newPaymentMethod, setNewPaymentMethod] = useState(true);
  const [previousPaymentMethod, setpreviousPaymentMethod] = useState(false);
  const [showCancelModel, setShowCancelModel] = useState(false);
  const [coupon, setCouponCode] = useState("");
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState("");
  const [cardIsEmpty, setCardIsEmpty] = useState(true);
  const [companySize, setCompanySize] = useState(size ?? 1);
  const [debouncedValue] = useDebounce(companySize, 500);

  const dispatch = useAppDispatch();
  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const stripe = useStripe();
  const elements: any = useElements();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const activeAnnualPackage = () => {
    setAnnualy(true);
    setMonthly(false);
  };
  const activeMonthlyPackage = () => {
    setAnnualy(false);
    setMonthly(true);
  };

  const activeNewPaymentMethod = () => {
    setNewPaymentMethod(true);
    setpreviousPaymentMethod(false);
  };
  const activPreviousPaymentMethod = () => {
    setpreviousPaymentMethod(true);
    setNewPaymentMethod(false);
  };

  const callForNewPaymentMethod = async () => {
    setProcessingTo(true);
    const cardElement: any = elements?.getElement("card");

    try {
      const res: any = await stripe?.createToken(cardElement);

      if (res.error) {
        setProcessingTo(false);
        setCheckoutError(res.error.message);
        return;
      }
      if (res.token) {
        const data: any = {
          payment_token: res.token.id,
          billing_type: annualy ? "annual" : "monthly",
        };
        // this is only for tenant or company
        if (tenant && size) {
          data.size = companySize;
        }
        if (paymentState.req_success.apply_coupon) {
          data.coupon = coupon;
        }
        dispatch(upgradeAccount({ data, setProcessingTo: setProcessingTo }));
      }
    } catch (err: any) {
      toast.error(err);
      setProcessingTo(false);
    }
  };

  const callFoPreviousPaymentMethod = async () => {
    const data: any = {
      card_id: paymentState.single_card_info.id,
      billing_type: annualy ? "annual" : "monthly",
    };
    // this is only for tenant or company
    if (tenant && size) {
      data.size = companySize;
    }
    if (paymentState.req_success.apply_coupon) {
      data.coupon = coupon;
    }

    dispatch(upgradeAccount({ data, setProcessingTo: setProcessingTo }));
  };

  const onSubmit = (values: any) => {
    if (checkoutError) {
      return;
    }

    if (!paymentState.edit_payment && cardIsEmpty) {
      setCheckoutError("your card number is invalid");
      return;
    }
    if (paymentState.edit_payment && newPaymentMethod && cardIsEmpty) {
      setCheckoutError("your card number is invalid");
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (newPaymentMethod) {
      callForNewPaymentMethod();
    }
    if (previousPaymentMethod) {
      callFoPreviousPaymentMethod();
    }
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const clearStipe = () => {
    setCheckoutError("");
    const cardElement: any = elements?.getElement("card");
    cardElement?.clear();
  };

  useEffect(() => {
    if (tenant && size) {
      setCompanySize(size);
      setValue("size", size);
    }
  }, []);

  useEffect(() => {
    if (
      !tenant &&
      !paymentState.pay_to_reaccess &&
      !paymentState.edit_payment
    ) {
      setAnnualy(true);
    }
  }, []);

  useEffect(() => {
    if (paymentState.pay_to_reaccess) {
      if (tenant && authState.tenant_pay_details?.billing_type === "annual") {
        activeAnnualPackage();
      }
      if (tenant && authState.tenant_pay_details?.billing_type === "monthly") {
        activeMonthlyPackage();
      }
    }
  }, [paymentState.pay_to_reaccess]);

  const createStripeSession = async () => {
    setLoading(true);  
    try {
      const dynamic_url = renderDynamicUrlFromComponentLevelState(configState);
      const res = await axiosInstance.post(`${dynamic_url}/checkout-session`, {
        billing_type: monthly ? "monthly" : "annual",
        coupon: paymentState.req_success.apply_coupon ? coupon : null,
      });
      if (res.data.url) {
        window.location.href = res.data.url;
      }
    } catch (error) {
      console.error("Error creating Stripe session:", error);
    } finally {
      setLoading(false);  
    }
  };

  useEffect(() => {
    if (
      companySize > 0 &&
      companySize <= 100 &&
      size &&
      debouncedValue &&
      tenant &&
      billingType
    ) {
      const data = {
        billing_type: monthly ? "monthly" : "annual",
        size: companySize,
      };
      dispatch(checkUserPricing({ data }));
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (annualy && companySize > 0 && companySize <= 100 && size && tenant) {
      const data = {
        billing_type: "annual",
        size: companySize,
      };
      dispatch(checkUserPricing({ data }));
    }
  }, [annualy]);

  useEffect(() => {
    if (monthly && companySize > 0 && companySize <= 100 && size && tenant) {
      const data = {
        billing_type: "monthly",
        size: companySize,
      };
      dispatch(checkUserPricing({ data }));
    }
  }, [monthly]);
// console.log(authState);
  useEffect(() => {
    if (
      authState.tenant_pay_details?.status !== TENANTPLANSTATUS.EXPIRED &&
      paymentState.req_success.upgrade_account
    ) {
      clearStipe();
      setShowModal(false);
      setAnnualy(true);
      setProcessingTo(false);
      dispatch(activatePaidPlanForUser());
    }
  }, [paymentState.req_success.upgrade_account]);

  useEffect(() => {
    if (!paymentState.pay_to_reaccess && paymentState.edit_payment) {
      setShowModal(true);
      activPreviousPaymentMethod();
      setCheckoutError("");
      if (
        !tenant &&
        paymentState.single_payment_info?.plan_name === "Annual Subscription"
      ) {
        activeAnnualPackage();
      }

      if (
        !tenant &&
        paymentState.single_payment_info?.plan_name === "Monthly Subscription"
      ) {
        activeMonthlyPackage();
      }

      if (tenant && authState.tenant_pay_details?.billing_type === "annual") {
        activeAnnualPackage();
      }
      if (tenant && authState.tenant_pay_details?.billing_type === "monthly") {
        activeMonthlyPackage();
      }
    }
  }, [paymentState.edit_payment]);

  useEffect(() => {
    if (paymentState.req_success.cancel_subscription) {
      clearStipe();
      setShowModal(false);
      setShowCancelModel(false);
      // activeMonthlyPackage();
      activeAnnualPackage()
      
      activeNewPaymentMethod();
      dispatch(deActivatePaidPlanForUser());
    }
  }, [paymentState.req_success.cancel_subscription]);

  useEffect(() => {
    if (paymentState.clear_stripe) {
      setShowModal(false);
      if (!tenant) {
        // activeMonthlyPackage();
        activeAnnualPackage()
      }
      setCardIsEmpty(true);
      clearStipe();
      if (tenant && size) {
        reset();
        setCompanySize(size);
        setValue("size", size);
      }
    }
  }, [paymentState.clear_stripe]);

  const oninput = (e: any) => {
    setCompanySize(e.target.value);
  };
  console.log(monthly);

  return (
    <>
      {" "}
      <form
        className="billing__card__container"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {/* show if user is tenant owner */}
        {tenant && (
          <div className="company__size__container">
            <TextField
              type="number"
              label="Company Size"
              name="size"
              min={1}
              max={100}
              register={register}
              oninput={oninput}
              error={errors?.size}
            />
          </div>
        )}
        <div className="biling__type">
          <InputLabel name="Billing Type" text="Billing Type" />

          {paymentState.req_success.pricing && (
            <div className="billing__button__section">
              <BillingTypeButton
                checkedValue={monthly}
                onClick={activeMonthlyPackage}
                price={paymentState.pricing.monthlyPrice}
                type="Monthly"
                subtype={tenant ? " per user per month" : "/month"}
                desc="Billed per month"
              />
              <BillingTypeButton
                checkedValue={annualy}
                onClick={activeAnnualPackage}
                price={paymentState.pricing.yearlyPrice}
                type="Yearly"
                subtype={tenant ? " per user per year" : "/year"}
                desc="Billed annually Save Upto 35%"
              />
            </div>
          )}
          {paymentState.loading.pricing && <CheckoutBillingSkleton />}
        </div>

        {tenant && <div className="payment__method">
          <InputLabel name="Payment Method" text="Payment Method" />
          {paymentState.req_success.card_info &&
            paymentState.single_card_info && (
              <RadioButton
                tac
                label={`${paymentState.single_card_info.card_no} (Expires ${paymentState.single_card_info.expires})`}
                checkedValue={previousPaymentMethod}
                onClick={activPreviousPaymentMethod}
              />
            )}
          {paymentState.req_success.card_info && (
            <RadioButton
              tac
              label="Add New Payment Method"
              checkedValue={newPaymentMethod}
              onClick={activeNewPaymentMethod}
            />
          )}

          {paymentState.loading.card_info && <PaymentMethodSkleton />}
        </div>}

        {tenant && newPaymentMethod && (
          <StripeCard
            setCheckoutError={setCheckoutError}
            checkoutError={checkoutError}
            setCardIsEmpty={setCardIsEmpty}
          />
        )}
        <div className="coupon__section">
          <span className="title">Coupon </span>

          <CouponSection
            annualy={annualy}
            monthly={monthly}
            coupon={coupon}
            setCouponCode={setCouponCode}
            tenant={tenant}
            companySize={companySize}
          />
          <div className="button__section">
            {!tenant && paymentState.edit_payment && (
              <div className="cancel__button">
                <DangerButton
                  text="Cancel Subscription"
                  type="button"
                  onClick={() => setShowCancelModel(true)}
                />
              </div>
            )}

            {tenant &&
              (authState.tenant_pay_details?.status === TENANTPLANSTATUS.PAID ||
                authState.tenant_pay_details?.status ===
                  TENANTPLANSTATUS.TRIAL) &&
              paymentState.edit_payment && (
                <div className="cancel__button">
                  <DangerButton
                    text="Cancel Subscription"
                    type="button"
                    onClick={() => setShowCancelModel(true)}
                  />
                </div>
              )}
            {tenant && <div className="upgrade__subscription__button">
              <TACPrimaryButton
                text="Upgrade Subscription"
                type="submit"
                loading={isProcessing || paymentState.loading.upgrade_account}
              />
            </div>}
          </div>
        </div>
        {!tenant && authState.tac_user?.plan !== "paid" && <div className="upgrade__subscription__button" style={{ marginTop: "20px" }}>
              <TACPrimaryButton
                text="Proceed to Payment"
                type="button"
                onClick={createStripeSession}
                loading={isProcessing || paymentState.loading.upgrade_account || loading}
              />
            </div>}
      </form>
      {showCancelModel && (
        <CancelSubscriptionModal
          showModal={showCancelModel}
          setShowModal={setShowCancelModel}
        />
      )}
    </>
  );
};

export default CheckoutForm;
