/* eslint-disable no-else-return */
import React, { useEffect, useState } from "react";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import Tags from "../../../../shared/Tags/Tags";
import { getAllGroups } from "../../../../store/features/Tenant/Orgination/Group/GroupApi";
import { useAppDispatch } from "../../../../store/store";

const SelectGroup = (props: any) => {
  const {
    title,
    groupState,
    setSelectedGroupId,
    memberState,
    assismentState,
    allReset,
  } = props;
  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [originalData, setOriginalData] = useState<any>([]);
  const [tempData, setTempData] = useState<any>([]);
  const [tempSelected, setTempSelected] = useState<any>([]);
  const [editedUserChecked, setEditedUserChecked] = useState(false);
  const [selectedData, setSelectedData] = useState<any>([]);

  useEffect(() => {
    dispatch(getAllGroups());
  }, []);

  useEffect(() => {
    if (memberState.req_success.add) {
      setSelectedData([]);
      setOriginalData(groupState.all_groups);
      setTempData(groupState.all_groups);
    }
  }, [memberState.req_success.add]);
  useEffect(() => {
    if (assismentState && assismentState.req_success.add) {
      setSelectedData([]);
      setOriginalData(groupState.all_groups);
      setTempData(groupState.all_groups);
    }
  }, [assismentState?.req_success?.add]);
  useEffect(() => {
    if (searchText.length > 0) {
      const original = tempData.filter((group: any) => {
        return group.name.toLowerCase().includes(searchText.toLowerCase());
      });

      setTempData(original);
    } else {
      setTempData(originalData);
    }
  }, [searchText]);

  useEffect(() => {
    const array: any = [];
    selectedData.forEach((element: any) => {
      array.push(element.id);
    });
    setSelectedGroupId(array);
  }, [selectedData]);

  useEffect(() => {
    if (groupState.req_success.list) {
      setOriginalData(groupState.all_groups);
      setTempData(groupState.all_groups);
    }
  }, [groupState.req_success.list]);

  const openSelect = () => {
    if (!active) {
      setActive(true);
    }
  };

  document.body.addEventListener("click", (e: any) => {
    if (
      !e.target.classList.contains("select__members__wrapper") &&
      !e.target.classList.contains("title") &&
      !e.target.classList.contains("custom__tags_container") &&
      !e.target.classList.contains("al_tag") &&
      !e.target.classList.contains("tag__list__button") &&
      !e.target.classList.contains("delete__tag__icon") &&
      !e.target.classList.contains("tag__list__button") &&
      !e.target.classList.contains("hide__and__show__section") &&
      !e.target.classList.contains("selection__container") &&
      !e.target.classList.contains("search__box") &&
      !e.target.classList.contains("searchbar") &&
      !e.target.classList.contains("search__box") &&
      !e.target.classList.contains("search__icon") &&
      !e.target.classList.contains("search__input") &&
      !e.target.classList.contains("options__section") &&
      !e.target.classList.contains("content__margin") &&
      !e.target.classList.contains("checkbox__container") &&
      !e.target.classList.contains("checkbox") &&
      !e.target.classList.contains("tickiconforcheckbox") &&
      !e.target.classList.contains("label") &&
      // !e.target.classList.contains("content__margin") &&
      !e.target.classList.contains("checkbox__icon") &&
      !e.target.classList.contains("selection__container") &&
      !e.target.classList.contains("checkbox-div")
    ) {
      setActive(false);
    }
  });

  const getSelectData = (data: any) => {
    if (data.checked) {
      const user = originalData.map((user: any) => {
        if (data.id === user.id) {
          return { ...user, checked: false };
        }
        return user;
      });
      const users = tempData.map((user: any) => {
        if (data.id === user.id) {
          return { ...user, checked: false };
        }
        return user;
      });
      setTempData(users);

      setOriginalData(user);
    }

    if (!data.checked) {
      const user = originalData.map((user: any) => {
        if (data.id === user.id) {
          return { ...user, checked: true };
        }
        return user;
      });

      const users = tempData.map((user: any) => {
        if (data.id === user.id) {
          return { ...user, checked: true };
        }
        return user;
      });
      setTempData(users);

      setOriginalData(user);
    }
  };
  useEffect(() => {
    const checkedData = originalData.filter((data: any) => {
      return data.checked === true;
    });
    setSelectedData(checkedData);
  }, [originalData]);

  useEffect(() => {
    if (
      !allReset &&
      groupState.req_success.single_get &&
      groupState.req_success.get &&
      originalData.length > 0 &&
      !editedUserChecked
    ) {
      const checkidId: any = [];
      groupState.single_group_info.users.forEach((u: any) => {
        checkidId.push(u.id);
      });
      const user = originalData.map((user: any) => {
        if (checkidId.includes(user.id)) {
          return { ...user, checked: true };
        } else {
          return user;
        }
      });
      setOriginalData(user);
      setTempData(user);
      setEditedUserChecked(true);
    }
  }, [groupState.req_success, originalData]);
  return (
    <div className="select__members__wrapper">
      <span className="title">{title}</span>

      <Tags
        // tempData={tempData}
        dataArray={selectedData}
        updateDataArray={setSelectedData}
        onClick={openSelect}
        originalData={originalData}
        setOriginalData={setOriginalData}
        setTempData={setTempData}
        tempData={tempData}
        Enablehover
      />

      <div className="hide__and__show__section">
        <div className={`selection__container ${active && "active"}`}>
          <div className="search__box">
            <SearchBar value={searchText} setValue={setSearchText} />
          </div>
          <div className="options__section custom__scrollbar">
            {tempData.map((d: any) => {
              return (
                <div key={d.id * 1000} className="content__margin">
                  <CheckBox
                    label={d.name}
                    fullwidth
                    value={d.checked}
                    onClick={() => getSelectData(d)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectGroup;
