import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import ListData from "../../../components/UserDashboard/Resource/ListData/ListData";
import UserResourceHeader from "../../../components/UserDashboard/Resource/ResourceHeader/UserResourceHeader";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import "./index.scss";

const UserResources = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="user__page__resource__conainer mt-7">
          <UserResourceHeader />
          <ListData />
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default UserResources;
