export const filterArrayForTAC = [
  {
    id: 1,
    name: "Become a Product Manager",
    value: "Product Manager",
  },
  {
    id: 2,
    name: "Agile Fundamental (ICP) Certification",
    value: "ICP",
  },
  {
    id: 3,
    name: "Agile Team Facilitation (ATF) Certification",
    value: "Agile Team Facilitator",
  },
  {
    id: 4,
    name: "ICP/ACC Certification",
    value: "ICP-ACC",
  },
];

export const filterArray = [
  {
    id: 1,
    name: "Business Analyst",
    value: "Business Analyst",
  },

  {
    id: 2,
    name: "Product Owner",
    value: "Product Owner",
  },

  {
    id: 3,
    name: "Scrum Master",
    value: "Scrum Master",
  },
];
