import VerifyAccountMessage from "../../components/Auth/VerifyAccountMessage/VerifyAccountMessage";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";

const VerifyYourEmail = (props: any) => {
  return (
    <LandingLayout>
      <AuthLayout>
        <VerifyAccountMessage />
      </AuthLayout>
    </LandingLayout>
  );
};

export default VerifyYourEmail;
