/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLatestProgress,
  getFaqForNewDashboard,
  getOnBoarding,
  getLearningPathForSprintFilter,
  getFilterdSprintByPathId,
  getQuickLinks,
  updateSelectedLearningPath,
} from "./NewUserDashoardApi";

const initialState: any = {
  loading: {
    progress: false,
    quick_links: false,
    faq: false,
    onboarding: false,
    get_path: false,
    filter_course: false,
  },
  req_success: {
    progress: false,
    quick_links: false,
    faq: false,
    onboarding: false,
    get_path: false,
    filter_course: false,
  },
  progress: [],
  quick_links: [],
  faq: [],
  all_archives: [],
  archives: [],
  learning_path_for_filter: [],
  left_sprint: [],
  right_sprint: [],
  all_sprints: [],

  onboarding: {},
};

const NewDashSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET MY PROGRESS
    builder.addCase(getLatestProgress.pending, (state) => {
      state.loading.progress = true;
      state.req_success.progress = false;
    });
    builder.addCase(
      getLatestProgress.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.progress = false;
        state.req_success.progress = true;
        state.progress = payload?.data;
      },
    );
    builder.addCase(getLatestProgress.rejected, (state) => {
      state.loading.progress = false;
    });

    // GET QUICK LINKS
    builder.addCase(getQuickLinks.pending, (state) => {
      state.loading.quick_links = true;
      state.req_success.quick_links = false;
    });
    builder.addCase(
      getQuickLinks.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.quick_links = false;
        state.req_success.quick_links = true;
        state.quick_links = payload?.data;
      },
    );
    builder.addCase(getQuickLinks.rejected, (state) => {
      state.loading.quick_links = false;
    });
    // get faq for latest dashboard
    builder.addCase(getFaqForNewDashboard.pending, (state) => {
      state.loading.faq = true;
      state.req_success.faq = false;
    });
    builder.addCase(
      getFaqForNewDashboard.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.faq = false;
        state.req_success.faq = true;
        state.faq = payload?.data;
      },
    );
    builder.addCase(getFaqForNewDashboard.rejected, (state) => {
      state.loading.faq = false;
    });

    // get onboarding data
    builder.addCase(getOnBoarding.pending, (state) => {
      state.loading.onboarding = true;
      state.req_success.onboarding = false;
    });
    builder.addCase(
      getOnBoarding.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.onboarding = false;
        state.req_success.onboarding = true;
        state.onboarding = payload;
      },
    );
    builder.addCase(getOnBoarding.rejected, (state) => {
      state.loading.onboarding = false;
    });

    // get learning path for filter sprint
    builder.addCase(getLearningPathForSprintFilter.pending, (state) => {
      state.loading.get_path = true;
      state.req_success.get_path = false;
    });
    builder.addCase(
      getLearningPathForSprintFilter.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_path = false;
        state.req_success.get_path = true;
        state.learning_path_for_filter = payload.data;
      },
    );
    builder.addCase(getLearningPathForSprintFilter.rejected, (state) => {
      state.loading.get_path = false;
    });

    // update selected learning path
    // builder.addCase(updateSelectedLearningPath.pending, (state) => {
    //   state.loading.get_path = true;
    //   state.req_success.get_path = false;
    // });
    // builder.addCase(
    //   updateSelectedLearningPath.fulfilled,
    //   (state, { payload }: PayloadAction<any>) => {
    //     state.loading.get_path = false;
    //     state.req_success.get_path = true;
    //   },
    // );
    // builder.addCase(updateSelectedLearningPath.rejected, (state) => {
    //   state.loading.get_path = false;
    // });

    // get filtered courses for  sprint
    builder.addCase(getFilterdSprintByPathId.pending, (state) => {
      state.loading.filter_course = true;
      state.req_success.filter_course = false;
      state.left_sprint = [];
      state.right_sprint = [];
    });
    builder.addCase(
      getFilterdSprintByPathId.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.filter_course = false;
        state.req_success.filter_course = true;
        state.left_sprint = [];
        state.right_sprint = [];
        state.all_sprints = payload.data;
        payload.data.forEach((sprint: any, index: number) => {
          if ((index + 1) % 2 !== 0) {
            state.left_sprint.push(sprint);
          } else {
            state.right_sprint.push(sprint);
          }
        });
      },
    );
    builder.addCase(getFilterdSprintByPathId.rejected, (state) => {
      state.loading.filter_course = false;
    });
  },
});

export default NewDashSlice.reducer;
