import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CreateTestimonials,
  GetTestimonials,
  DeleteTestimonials,
  EditTestimonials,
} from "./TestimonialApi";

const initialState: any = {
  loading: false,
  req_success: false,
  data: [],
  filterdata: [],
  temp: [],
  filteractive: false,
  hasTop: false,
};

const testimonialsSlice = createSlice({
  name: "testimonialsSlice",
  initialState,
  reducers: {
    searchTestimonials: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length >= 1) {
        state.filteractive = true;
        state.temp = state.data.filter((e: any) =>
          e.name.toLowerCase().includes(payload.toLowerCase()),
        );
        state.filterdata = state.temp;
      } else {
        state.filteractive = false;
      }
    },
  },
  extraReducers: (builder) => {
    // create
    builder.addCase(CreateTestimonials.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      CreateTestimonials.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        if (payload.data?.is_top) {
          state.data = state.data?.map((e: any) => {
            return { ...e, is_top: 0 };
          });
        }
        let TopTestimonail=false;
        if (payload.data.is_top) {
          // state.hasTop = true;
          state.data = [payload?.data, ...state.data];
        } else {
          state.data = [...state.data, payload?.data];
        }
        state.data.forEach((e: any) => {
          if (e.is_top) {
            TopTestimonail=true;
          }
        });
        state.hasTop = TopTestimonail;
      },
    );
    builder.addCase(CreateTestimonials.rejected, (state) => {
      state.loading = false;
      state.req_success = false;
    });

    // list
    builder.addCase(GetTestimonials.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      GetTestimonials.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;

        state.data = [...payload.data];
        let TopTestimonail=false;
        state.data.forEach((e: any) => {
          if (e.is_top) {
            TopTestimonail=true;
          }
        });
        state.hasTop = TopTestimonail;
      },
    );
    builder.addCase(GetTestimonials.rejected, (state) => {
      state.loading = false;
      state.req_success = false;
    });

    // delete
    builder.addCase(DeleteTestimonials.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      DeleteTestimonials.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.data = state.data.filter((e: any) => e.id !== payload?.id);
        state.data.forEach((e: any) => {
          if (e.is_top) {
            state.hasTop = true;
          } else {
            state.hasTop = false;
          }
        });
      },
    );
    builder.addCase(DeleteTestimonials.rejected, (state) => {
      state.loading = false;
      state.req_success = false;
    });

    // edit
    builder.addCase(EditTestimonials.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      EditTestimonials.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        if (payload.data?.is_top) {
          state.data = state.data?.map((e: any) => {
            return { ...e, is_top: 0 };
          });
        }
        if (payload.data.is_top) {
          state.hasTop = true;
          state.data = state.data?.filter(
            (e: any) => e.id !== payload.data?.id,
          );

          state.data = [payload?.data, ...state.data];
        } else {
          state.hasTop = false;

          state.data = state.data.map((e: any) => {
            if (e.id === payload?.data.id) {
              return payload?.data;
            }
            return e;
          });
        }
      },
    );
    builder.addCase(EditTestimonials.rejected, (state) => {
      state.loading = false;
      state.req_success = false;
    });
  },
});

export const { searchTestimonials } = testimonialsSlice.actions;
export default testimonialsSlice.reducer;
