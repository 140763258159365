/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useNavigate } from "react-router-dom";
import couseImg from "../../../assets/icons/OnlineCourse.jpg";

const AdminBundle = (props: any) => {
  const navigate = useNavigate();
  const { data } = props;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className="bundle__wapper"
      onClick={() => navigate(`/admin-learningpath-course/${data.path_id}`)}
    >
      <img
        src={data.bundle_card_image_url}
        className="cover__img"
        alt="cover"
      />
      <div className="bundle__info__section">
        <span className="bundle__title">{data.name}</span>
        <span className="bundle__count">{data.path_courses_count} Courses</span>
        <p className="bundle__description">{data.description}</p>
        {/* <div className="bundle__divider" />
        <span className="bundle__deadline">Individual deadline</span> */}
      </div>
    </div>
  );
};

export default AdminBundle;
