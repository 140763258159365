import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import SEOBody from "../../../components/AdminPanel/SEO/SEOBody/SEOBody";
import SEOHeader from "../../../components/AdminPanel/SEO/SEOHeader/SEOHeader";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const SEOManagement = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
       <div className="mt-7">
       <SEOHeader />
        <SEOBody />
       </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default SEOManagement;
