import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Check, Trash2 } from "react-feather";
import {
  DangerTextButton,
  PrimaryButton,
} from "../../../shared/Buttons/Buttons";
import { Card } from "../../../shared/Card/Card";
import TextField from "../../../shared/Inputs/TextField/TextField";
import { InputLabel } from "../../../shared/Inputs/InputLabel/InputLabel";
import CheckBox from "../../../shared/Inputs/CheckBox/CheckBox";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { ceateOrUpdateSprint, getSprintList } from "../../../store/features/AdminPanel/Sprint/SprintApi";
import TextArea from "../../../shared/Inputs/TextArea/TextArea";

const CreateAndUpdateSprint = (props: any) => {
  const {
    id = null,
    onDelete,
    index,
    courses,
    sprint,
    setIndexForDelete,
  } = props;

  const [allCourses, setAllCourses] = useState(courses);
  const [loading, setLoading] = useState<any>();
  const [sid, setSid] = useState(null);

  const params = useParams();
  const dispatch = useAppDispatch();

  const sprintState: any = useAppSelector((store: RootStore) => {
    return store.SprintSlice;
  });
  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Sprint Name")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const getSelectedCourseId = (values: any): Array<object> => {
    const arrayOfId: Array<object> = [];
    allCourses.forEach((course: any) => {
      if (course.checked) {
        arrayOfId.push({
          id: course.id,
          description: values[`course_${course.id}`]
        });
      }
    });
    return arrayOfId;
  };
  const onSubmit = (values: any) => {
    const data = {
      ...values,
      learning_path_id: Number(params.id),
      courses: getSelectedCourseId(values),
    };
    console.log(data);
    if (sid) {
      data.id = sid;
    }
    setLoading(index);
    setIndexForDelete(index);
    dispatch(ceateOrUpdateSprint(data));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const getArrayOfId = (): Array<number> => {
    const arrayOfId: Array<number> = [];

    sprint.courses.forEach((course: any) => {
      arrayOfId.push(course.id);
    });
    return arrayOfId;
  };

  const setSelectedCourse = () => {
    const courseIds = getArrayOfId();
    const selectedCourses = allCourses.map((course: any) => {
      if (courseIds.includes(course.id)) {
        return { ...course, checked: true };
      }
      return course;
    });
    setAllCourses(selectedCourses);
  };

  useEffect(() => {
    if (sprint) {
      setValue("name", sprint.name);
      setValue("description", sprint.description);
      sprint.courses.forEach((course: any) => {
        setValue(`course_${course.id}`, course.description);
      })
      setSelectedCourse();
      setSid(sprint.id);
    }
  }, [sprint]);

  const checkUnchedCourse = (course: any) => {
    if (course.checked) {
      const filtercourses = allCourses.map((c: any) => {
        if (c.id === course.id) {
          return { ...c, checked: false };
        }
        return c;
      });
      setAllCourses(filtercourses);
    }

    if (!course.checked) {
      const filtercourses = allCourses.map((c: any) => {
        if (c.id === course.id) {
          return { ...c, checked: true };
        }
        return c;
      });
      setAllCourses(filtercourses);
    }
  };
  const onDeleteTrigger = (id: number, index: number) => {
    setLoading(id);
    onDelete(id, index);
  };
  return (
    <div className="mb-3">
      {" "}
      <Card>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <div className="input__width">
            <TextField
              type="text"
              label="Sprint name"
              name="name"
              register={register}
              error={errors?.name}
            />
          </div>
          <div className="description__textarea">
            <TextArea
              label="Description"
              name="description"
              register={register}
              half
            />
          </div>
          <InputLabel name="Select Course" text="Select Course" />
          <div className="select__course__section  custom__scrollbar">
            {allCourses.map((course: any) => {
              return (
                <div className="content__margin" key={course.id}>
                  <CheckBox
                    label={course.name}
                    fullwidth
                    value={course?.checked}
                    onClick={() => checkUnchedCourse(course)}
                  />
                </div>
              );
            })}
          </div>

          <div className="divder__line" />
          <p className="course__lessons">Lessons in the course</p>
          <div className="selected__courses__desc__box custom__scrollbar">
            {
              allCourses.map((course:any)=>course?.checked ? (
                <div className="course__desc__box">
                <div className="desc__input">
                  <TextField
                    type="text"
                    label={course?.name}
                    name={`course_${course?.id}`}
                    register={register}
                  />
                </div>
                {/* <div className="right__deletebox">
                  <div className="right__box">
                    <Check />
                  </div>
                  <div className="delete__icon__div">
                    <Trash2 />
                  </div>
                </div> */}
              </div>

              ):null
              )
            }
          
    
          </div>

          <div className="footer__section">
            <DangerTextButton
              type="button"
              onClick={() => onDeleteTrigger(id, index)}
              text="Remove"
              loading={sprintState.loading.delete && id === loading}
            />
            <PrimaryButton
              type="submit"
              text={sid ? "Update" : "Submit"}
              width="submit"
              loading={sprintState.loading.add && loading === index}
            />
          </div>
        </form>
      </Card>
    </div>
  );
};

export default CreateAndUpdateSprint;
