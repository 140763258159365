/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from "react";
import { LogOut } from "react-feather";
import { getBase64, getFileExtension } from "../../../helpers/utils/FileHelper";
import ErrorText from "../../Inputs/ErrorText/ErrorText";
import "./index.scss"

const ThumbnailUploader = (props:any) => {
    const  {imageExt,setImageExt,imageUrl,setImageUrl,imageFile,setImageFile,imageError,setImageError} = props;

  const hiddenFileInput = useRef<any>(null);
  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];
    if (contentFile.size > 2 * 1024 * 1024) {
      setImageError("Image size must be less than 2 MB");
      return;
    }
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageError("");
     // Clear the file input value so the same file can be uploaded again
     e.target.value = "";
  };
  const handleClick = (event: any) => {
    hiddenFileInput?.current?.click();
  };
  const removeImage = () => {
    setImageUrl("");
    setImageFile("");
    setImageError("Image is required");
  };
  return (
    <div className="thumbnail__container">
      <div className={`file_fieldd 
        // ${imageError ? "error" : ""}
        `}>
        {!imageUrl && (
          <div className="file_input_design" onClick={handleClick}>
            <LogOut className="logout_icon" />
            <p className="clik_text">Click to add Image</p>
            <span className="blog__img__size__recomemded">
              Recommended size 1280*720  px
            </span>
          </div>
        )}
        {imageUrl && (
          <div className="thumbnail__section">
            <img
              src={imageUrl}
              className="thumbnail__image"
              alt="thumbnail"
              onClick={handleClick}
            />

           <div className="thumbnail__actions__btn">
           <span className="remove__image" onClick={removeImage}>
              Remove
            </span>
           </div>
          </div>
        )}
        <ErrorText error={imageError} />

        <input
          className="filefield"
          type="file"
          name="image"
          id="file"
          accept="image/*"
          ref={hiddenFileInput}
          onChange={(e: any) => getFiles(e)}
        />
      </div>
    </div>
  );
};

export default ThumbnailUploader;
