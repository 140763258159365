/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from "react";
import { Loader } from "react-feather";
import { Helmet } from "react-helmet";

import "./styles/global.scss";

import { RootStore, useAppDispatch, useAppSelector } from "./store/store";
import { loadConfig } from "./store/features/ThemeApi";
import AllRoutes from "./routes/AllRoutes";

function App() {
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const dispatch = useAppDispatch();

  // here i need to access all url configs varaiable, make it dynamic based on api response and then export it
  // for all cases where urls are accessed
  useEffect(() => {
    dispatch(loadConfig());
    return () => {};
  }, []);

  return (
    <div>
      {configState?.config_loading ? (
        <div className="loader-div">
          <Loader />
        </div>
      ) : (
        <>
          <AllRoutes />
          <Helmet>
            <title>{configState.configs?.site_name}</title>
            <link
              rel="apple-touch-icon"
              href={configState.configs?.sidebar_closed_logo}
            />
            <link rel="icon" href={configState.configs?.sidebar_closed_logo} />
          </Helmet>
        </>
      )}
    </div>
  );
}
export default App;
