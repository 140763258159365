import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUserCourseDetail, switchRole } from "./courseDetailApi";

const initialState: any = {
  loading: false,
  req_success: false,
  reload_window:false,
  data: [],
};

const CourseDetailSlice = createSlice({
  name: "course",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get course detail
    builder.addCase(getUserCourseDetail.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getUserCourseDetail.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.data = payload;
      },
    );
    builder.addCase(getUserCourseDetail.rejected, (state) => {
      state.loading = false;
    });

       // Switch  Role
       builder.addCase(switchRole.pending, (state) => {
        state.reload_window = true;
      });
      builder.addCase(
        switchRole.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.reload_window = false;
         
        },
      );
      builder.addCase(switchRole.rejected, (state) => {
        state.reload_window = false;
      });
  },
});

export default CourseDetailSlice.reducer;
