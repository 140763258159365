import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import logo from "../../../assets/images/agileAcademyLogo.svg";
import linkedinIconLight from "../../../assets/icons/fbIcon.svg";
import fbIcon from "../../../assets/icons/linkedinIconLight.svg";
import instaIcon from "../../../assets/icons/instaIcon.svg";

function Footer(props: any) {
  return (
    <footer className="footer">
      <div className="foot1">
        <Link to="/" className="footer-logo">
          <img src={logo} alt="logo" />
        </Link>
        <div className="foot-social">
          <Link to="/#">
            <img src={fbIcon} alt="facebook" />
          </Link>
          <Link to="/#">
            <img src={instaIcon} alt="instagram" />
          </Link>
          <Link to="/#" className="linkedin-img">
            <img src={linkedinIconLight} alt="linkedin" />
          </Link>
        </div>
        <div className="copyright">
          © Copyright 2022, All Rights Reserved by Agile Academy.
        </div>
      </div>
      <div className="foot2">
        <div className="foot-title">Quick links</div>
        <div className="foot-links">
          <Link to="/#">Self-paced courses</Link>
          <Link to="/#">Live training</Link>
          <Link to="/#">Blog</Link>
          <Link to="/#">Our team</Link>
          <Link to="/#">Contact</Link>
          <Link to="/#">Pricing</Link>
        </div>
      </div>
      <div className="foot3">
        <div className="foot-title">get inspired</div>
        <div className="gi-text">Want updates sent straight to your Inbox?</div>
        <div className="footer-form">
          <input type="text" name="email" id="" />
          <button type="button" className="footer-btn">
            Submit
          </button>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
