import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import TextField from "../../../shared/Inputs/TextField/TextField";
import CheckBox from "../../../shared/Inputs/CheckBox/CheckBox";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  AddLearningPath,
  listLearningPath,
} from "../../../store/features/AdminPanel/LearningPath/LearningPathApi";
import { searchPath } from "../../../store/features/AdminPanel/LearningPath/LearningPathSlice";

const LearningPathHeader = () => {
  const dispatch = useAppDispatch();

  const learningPathState: any = useAppSelector((store: RootStore) => {
    return store.AdminLearningPathSlice;
  });

  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    dispatch(searchPath(searchText));
  }, [searchText]);
  const validationSchema = yup.object().shape({
    path_id: yup
      .number()
      .typeError("Path Id must be a number")
      .required(REQUIRED_VALIDATION("Path Id")),
    learning_role: yup.number(),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const onSubmit = (values: any) => {
    dispatch(AddLearningPath(values));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (
      learningPathState.added_req_success ||
      learningPathState.delete_success
    ) {
      dispatch(listLearningPath());
      setShowModal(false);
      reset();
    }
  }, [learningPathState?.added_req_success, learningPathState?.delete_success]);

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Learning Paths</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal}>
        <ModalHeader title="Add Learning Path" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div style={{ margin: "10px 0" }} className="form_row">
              <TextField
                type="text"
                label="Path Id"
                name="path_id"
                register={register}
                error={errors?.path_id}
              />
            </div>
            <p>Learning Path role </p>
            <input
              type="text"
              style={{ display: "none" }}
              value={3}
              {...(register && register("learning_role"))}
            />
            {/* <div style={{ margin: "10px 0" }} className="form_row">
              <CheckBox
                type="text"
                label="Sys_Admin"
                name="sysadmin"
                register={register}
                error={errors?.sysAdmin}
              />
            </div>
            <div style={{ margin: "10px 0" }} className="form_row">
              <CheckBox
                type="text"
                label="Company_Admin"
                name="companyadmin"
                register={register}
                error={errors?.companyAdmin}
              />
            </div> */}
            <div style={{ margin: "10px 0" }} className="form_row">
              <CheckBox
                type="text"
                label="User"
                name="user"
                register={register}
                error={errors?.user}
                value
              />
            </div>
            {/* <div style={{ margin: "10px 0" }} className="form_row">
              <CheckBox
                type="text"
                label="Training_User"
                name="traininguser"
                register={register}
                error={errors?.trainingUser}
              />
            </div> */}

            <div className="button__witdh mt-1">
              <PrimaryButton
                text="Submit"
                type="submit"
                loading={learningPathState.adder_loading}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LearningPathHeader;
