import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import Sprints from "../../../components/AdminPanel/Sprint/Sprints";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const Sprint = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <Sprints />
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default Sprint;
