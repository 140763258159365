import React, { useEffect, useLayoutEffect, useState } from "react";
import { ChevronLeft, Trash2 } from "react-feather";

import "./interviewquestions.scss";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../shared/Table/Table";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  deleteQuestion,
  deleteMultipleQuestion,
  getInterviewQuestionByRoleId,
} from "../../../../store/features/AdminPanel/Interview/Question/InterviewApi";
import AddQuestionModal from "../AddQuestionModal/AddQuestionModal";
import {
  editInterview,
  searchInterview,
} from "../../../../store/features/AdminPanel/Interview/Question/InterviewSlice";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";

const InterviewQuestionsComp = (props: any) => {
  const { role, backToHomeTab } = props;

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [activeId, setActiveId] = useState<string | number>("");
  const [showSelectAllDeleteModal,setShowSelectAllDeleteModal] = useState(false);
  const [showCheckbox,setShowCheckbox] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedQuestions, setCheckedQuestions] = useState<number[]>([]);

  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewSlice;
  });

  useEffect(() => {
    dispatch(getInterviewQuestionByRoleId({ role_id: role.id }));
  }, []);

  useEffect(() => {
    dispatch(searchInterview(searchText));
  }, [searchText]);

  useEffect(() => {
    if (InterviewState.req_success.delete) {
      setDeleteShowModal(false);
      setShowSelectAllDeleteModal(false);
      dispatch(getInterviewQuestionByRoleId({ role_id: role.id }));
    }
  }, [InterviewState.req_success.delete]);

  const openDeleteModal = (id: number) => {
    setDeleteShowModal(true);
    setActiveId(id);
  };

  const showSelectAllCheckboxes = ()=>{
    setShowCheckbox(true);
  }

  const handleCancel =()=>{
    setShowCheckbox(false);
  }

  const handleCheckboxChange = (questionId: number) => {
    if (checkedQuestions.includes(questionId)) {
      // Remove the questionId if already checked
      setCheckedQuestions((prev) => prev.filter((id) => id !== questionId));
    } else {
      // Add the questionId if not checked
      setCheckedQuestions((prev) => [...prev, questionId]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      // Uncheck all questions
      setCheckedQuestions([]);
    } else {
      // Check all questions
      const allQuestionIds = InterviewState.questions.map(
        (question: any) => question.id
      );
      setCheckedQuestions(allQuestionIds);
    }

    // Toggle the state of "Select All" checkbox
    setSelectAllChecked((prev) => !prev);
  };
  
  return (
    <>
      <div className="custom_admin__interview__wrapper">
        <div className="table_headings">
          <div className="left_control">
            <ChevronLeft
              style={{ marginLeft: "-6px", cursor: "pointer" }}
              onClick={() => backToHomeTab(false)}
            />
            <TableHeadingTitle>{role.name}</TableHeadingTitle>
          </div>
          <div className="right_control table__heading__right__section">
            <div className="search">
              <SearchBar value={searchText} setValue={setSearchText} />
            </div>

            <div className="md_btn">
              <PrimaryButton
                text="Add Question"
                type="button"
                loading={false}
                onClick={() => setShowModal(true)}
              />
            </div>
            <div className="vertical__line" />
            <div className="deleteall__cancel__btn">
              {
                !showCheckbox && (
                  <button type="button" className="deleteall__button" onClick={()=>showSelectAllCheckboxes()}>
                  <Trash2 size={16} className="delete__icon"/>
                    <p>Delete Questions</p>
                </button>
                )
              }
            
             {
              showCheckbox && (
                <>
                <button type="button" className="deleteall__button"   onClick={()=>setShowSelectAllDeleteModal(true)}>
                <Trash2 size={16} className="delete__icon"/>
                  <p>Delete Questions</p>
              </button>

              <button type="button" className="cancel__button" onClick={()=>handleCancel()}>Cancel</button>
              </>
              )
             }
            </div>
          </div>
        </div>
        <div className="interview_question__table">
          <Table
            heading={
              <TableHeading>
                <th className="title title__wrapper">
                  {
                    showCheckbox && (<CheckBox 
                      labelClassname 
                      value={selectAllChecked}
                      onClick={handleSelectAllChange}
                     
                      />)
                  }
                  <span>Questions</span>
                  </th>
                <th className="title">Max recording time limit (min)</th>
              </TableHeading>
            }
          >
            {InterviewState.req_success.get &&
            InterviewState.questions.length > 0 ? (
              <TableBody>
                {InterviewState.questions.map((question: any) => (
                  <tr className="row" key={question.id}>
                    <td className="question_td question__td__wrapper">
                      {
                      showCheckbox && (<CheckBox labelClassname
                        value={checkedQuestions.includes(question.id)}
                        onClick={() => handleCheckboxChange(question.id)}
                      />)
                    }
                      <span>{question.question}</span>
                      </td>
                    <td className="time_td">{question.max_recording_time}</td>
                    <TableAction
                      onEdit={() => dispatch(editInterview(question))}
                      onDelete={() => openDeleteModal(question.id)}
                    />
                  </tr>
                ))}
              </TableBody>
            ) : (
              <tbody className="emptyquestion">
                <tr className="empty">
                  <td>
                    No questions created. <span> Add question.</span>{" "}
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>

      <AddQuestionModal
        showModal={showModal}
        setShowModal={setShowModal}
        role={role}
      />

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={InterviewState.loading.delete}
        onClick={() => dispatch(deleteQuestion({ id: activeId }))}
      />
        <DeleteModal
        showModal={showSelectAllDeleteModal}
        setShowModal={setShowSelectAllDeleteModal}
        loading={InterviewState.loading.delete}
        onClick={() => dispatch(deleteMultipleQuestion({ ids: checkedQuestions }))}
      />
    </>
  );
};

export default InterviewQuestionsComp;
