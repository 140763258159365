/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import ErrorText from "../Inputs/ErrorText/ErrorText";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import InputContainer from "../Layouts/Input/InputContainer";
import "./index.scss";

// const Tag = (props: any) => {
//   const { text } = props;
//   return (
//     <div className="al_tag">
//       {text}{" "}
//       <button type="button">
//         <X size={15} />
//       </button>
//     </div>
//   );
// };
function intersect(a: any, b: any) {
  let setA: any = new Set(a);
  let setB: any = new Set(b);
  let intersection = new Set([...setA].filter((x) => setB.has(x)));
  return Array.from(intersection);
}
function TagForCourses(props: any) {
  const {
    nobg,
    title,
    checkedLearningPathId,
    setCheckedLearningPathId,
    learningPath,
    indiviudalCourses,
    allcourses,
    setAllCourses,
    name,
    label,
    courseIndex,
    error,
    Enablehover,
    setError,
    register,
    transparent,
    dataArray,
    originalData,
    setOriginalData,
    updateDataArray,
    boldFirstIndex,
    onClick,
    input,
    ...rest
  } = props;

  const removeTag = (data: any, index: any, learningPath: boolean) => {
    const newArr = dataArray.filter((e: any, i: any) => {
      return i !== index;
    });
    updateDataArray(newArr);
    if (learningPath) {
      const index = checkedLearningPathId.indexOf(data.id);
      if (index > -1) {
        checkedLearningPathId.splice(index, 1);
      }
      setCheckedLearningPathId(checkedLearningPathId);

      const path = originalData.map((path: any) => {
        if (data.id === path.id) {
          return { ...path, checked: false };
        }
        return path;
      });
      const a = allcourses.map((course: any) => {
        const intersection = intersect(
          course.learning_path_ids,
          checkedLearningPathId,
        );
        if (intersection.length === 0) {
          return { ...course, checked: false };
        }
        return course;
      });
      setAllCourses(a);
      setOriginalData(path);
    }
    if (indiviudalCourses) {
      const allCourses = originalData.map((c: any) => {
        if (c.id === data.id) {
          return { ...c, checked: false };
        }
        return c;
      });
      setOriginalData(allCourses);
    }
  };

  return (
    <div
      className={`custom__tags_container ${nobg && "nobg"}  ${
        transparent && "transparent"
      } ${input && "input"}   ${error && "error"}  ${Enablehover && "input"}`}
      onClick={onClick}
    >
      <div className={`al_tag button__title ${transparent && "transparent"} `}>
        {title}
      </div>
      {dataArray !== null &&
        !input &&
        dataArray?.map((e: any, i: any) => {
          return (
            <div
              key={e.id}
              className={`al_tag ${transparent && "transparent"}`}
            >
              {e.name}{" "}
              <button
                type="button"
                className={`tag__list__button ${transparent && "transparent"}`}
                onClick={() => removeTag(e, i, learningPath)}
              >
                <X size={15} className="delete__tag__icon" />
              </button>
            </div>
          );
        })}
    </div>
  );
}

export default TagForCourses;
