/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { X } from "react-feather";
import ProgressBar from "@ramonak/react-progress-bar";
import "./index.scss";
import { useAppDispatch } from "../../../../store/store";
import { deleteFileAndFolder } from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerApi";
import { getFileExtension } from "../../../../helpers/utils/FileHelper";
import pdfIcon from "../../../../assets/resource_icon/pdf.svg";
import docxIcon from "../../../../assets/resource_icon/docx.svg";
import imgIcon from "../../../../assets/resource_icon/img.svg";
import rarIcon from "../../../../assets/resource_icon/rar.svg";

const UploadProgressBar = (props: any) => {
  const dispatch = useAppDispatch();
  const [singleFileProgress, setSinfleFileProgress] = useState(0);
  const [uploadedFileId, setUploadedFileId] = useState(0);

  const { progress, file, fileName, themeState } = props;
  const ext: any = getFileExtension(fileName);
  useEffect(() => {
    if (fileName === progress?.name) {
      setSinfleFileProgress(progress?.progress);
      setUploadedFileId(progress?.id);
    }
  }, [progress]);

  const deleteUploadedFile = () => {
    dispatch(deleteFileAndFolder({ id: uploadedFileId }));
    const b: any = document.getElementById(uploadedFileId?.toString());
    b.style.display = "none";
  };
  return (
    <div
      className="file_upload_progress_section "
      id={uploadedFileId?.toString()}
    >
      <div className="icon_section">
        {/* <File className="icon" /> */}
        {(ext.toLowerCase() === "jpg" ||
          ext.toLowerCase() === "jpeg" ||
          ext.toLowerCase() === "png" ||
          ext.toLowerCase() === "svg") && <img src={imgIcon} alt="" />}

        {ext.toLowerCase() === "pdf" && <img src={pdfIcon} alt="" />}

        {(ext.toLowerCase() === "docx" || ext.toLowerCase() === "doc") && (
          <img src={docxIcon} alt="" />
        )}

        {(ext.toLowerCase() === "rar" || ext.toLowerCase() === "zip") && (
          <img src={rarIcon} alt="" />
        )}
      </div>
      <div className="info__section__div">
        <div className="row_section">
          {" "}
          <span className="file_name">
            {file.name}
            <span className="file_size">
              {" "}
              {(file.size / (1024 * 1024)).toFixed(2)} Mb
            </span>
          </span>
          {singleFileProgress === 100 && (
            <X className="cancel__icon" onClick={() => deleteUploadedFile()} />
          )}
        </div>
        <div className="test">
          <ProgressBar
            completed={singleFileProgress}
            height="3px"
            customLabel="."
            baseBgColor="var( --primary-progress-bar-bg)"
            bgColor="var( --secondary-progress-bar-bg)"
            labelSize="0"
          />
        </div>

        <span className="percentage_uploaded"> {singleFileProgress}% done</span>
      </div>
    </div>
  );
};

export default UploadProgressBar;
