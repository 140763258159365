/* eslint-disable consistent-return */
import { Route } from "react-router-dom";
import PrivateRoute from "../routes/Middleware/PrivateRoute";
import AllPrivateRoute from "../routes/AllPrivateRoute";
import RoutesOnlyForTAC from "../routes/DomainSpecific/RoutesOnlyForTAC";
import RoutesOnlyForTAA from "../routes/DomainSpecific/RoutesOnlyForTAA";

const CheckRouteAccess = (
  membership_enabled: string
  // component: JSX.Element
) => {
  switch (membership_enabled) {
    case "0":
      return (
        <Route element={<PrivateRoute />}>
          {RoutesOnlyForTAA.map((route: any) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
      );
    case "1":
      return (
        <Route element={<PrivateRoute />}>
          {RoutesOnlyForTAC.map((route: any) => (
            <Route
              key={route.id}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
      );

    default:
      break;
  }
};

export default CheckRouteAccess;
