import React from "react";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import DoubleLoginSetup from "../../components/Auth/DoubleLogin/DoubleLoginSetup";

const DoubleLogin = () => {
  return (
    <LandingLayout>
      <AuthLayout>
        <DoubleLoginSetup />
      </AuthLayout>
    </LandingLayout>
  );
};

export default DoubleLogin;
