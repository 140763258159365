/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { ChevronLeft, File, FolderMinus } from "react-feather";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import {
  deleteFileAndFolder,
  getData,
  getDataInsideFolder,
} from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerApi";
import {
  setActiveSlice,
  setCleanScreen,
  setNestedFolderId,
  showInfoSlice,
} from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import ActionMenu from "../ActionMenu/ActionMenu";
import Empty from "../../../../shared/Empty/Empty";
import FileNameEditor from "./FileNameEditor";
import pdfIcon from "../../../../assets/resource_icon/pdf.svg";
import folderIcon from "../../../../assets/resource_icon/folder.svg";
import docxIcon from "../../../../assets/resource_icon/docx.svg";
import imgIcon from "../../../../assets/resource_icon/img.svg";
import rarIcon from "../../../../assets/resource_icon/rar.svg";
import "./index.scss";

const ListFileAndFolder = (props: any) => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [resourceId, setResourceId] = useState(0);
  const [editFolderId, setEditFolderId] = useState(0);
  const [nestedFolder, setNestedFolder] = useState("");
  const [folderStack, setFolderStack] = useState<any>([]);
  const resourceState: any = useAppSelector((store: RootStore) => {
    return store.ResourceManagerSlice;
  });
  const showInfo = (data: any) => {
    dispatch(showInfoSlice(data));
  };
  const setActiveId = (id: number) => {
    dispatch(setActiveSlice(id));
  };
  const deleteResource = () => {
    dispatch(deleteFileAndFolder({ id: resourceId }));
  };
  const openDeleteResource = (resourceId: any) => {
    setDeleteShowModal(true);
    setResourceId(resourceId);
  };

  const getDataInsideFolder2 = (data: any) => {
    if (data.resource_type !== "file") {
      dispatch(setCleanScreen());
      setFolderStack([
        ...folderStack,
        { id: data.id, name: data.resource_name },
      ]);
      setNestedFolder(data.resource_name);
      dispatch(setNestedFolderId(data.id));
      dispatch(getDataInsideFolder({ id: data.id }));
    }
  };

  useEffect(() => {
    if (resourceState.req_success.rename) {
      setEditFolderId(0);
    }
  }, [resourceState.req_success.rename]);

  useEffect(() => {
    if (
      !resourceState.loading.folder.delete &&
      resourceState.req_success.folder.delete
    ) {
      setDeleteShowModal(false);
    }
  }, [resourceState.loading.folder.delete]);

  const handleBackNavigation = () => {
    dispatch(setCleanScreen());
    folderStack.pop();

    if (folderStack.length > 0) {
      const openFolder = folderStack.slice(-1);
      setNestedFolder(openFolder[0].name);
      dispatch(getDataInsideFolder({ id: openFolder[0].id }));
    } else {
      setNestedFolder("");
      dispatch(getData());
    }
  };
  const openPdfInNewTab = (data: any) => {
    if (
      data.resource_extension.toLowerCase() === "rar" ||
      data.resource_extension.toLowerCase() === "zip"
    ) {
      return;
    }
    if (
      data.resource_extension.toLowerCase() !== "docx" &&
      data.resource_extension.toLowerCase() !== "doc"
    ) {
      dispatch(setActiveSlice(0));

      window.open(data.file_path, "_blank");
    }
    if (
      data.resource_extension.toLowerCase() === "docx" ||
      data.resource_extension.toLowerCase() === "doc"
    ) {
      dispatch(setActiveSlice(0));

      window.open(
        `https://view.officeapps.live.com/op/embed.aspx?src=${data?.file_path}`,
        "_blank",
      );
    }
  };

  return (
    <div className="list_file_and_folder_section">
      {resourceState.active_id > 0 && (
        <div
          className="whole__window_cover"
          onClick={() => dispatch(setActiveSlice(0))}
        />
      )}
      {nestedFolder && (
        <div className="nested_folder_name_section">
          <ChevronLeft
            className="nested_folder_back_icon"
            onClick={handleBackNavigation}
          />
          <span className="nested_folder_name">{nestedFolder}</span>
        </div>
      )}
      {/* <span className="resource__topography mt-2">Your Files</span> */}
      <table className="list_file_table_section">
        <thead>
          <tr>
            <th>Name</th>
            <th>No. of Files</th>
            <th>File Size</th>
            <th>Last Modified</th>
          </tr>
        </thead>
        <tbody>
          {!resourceState.loading.data.get &&
            resourceState.req_success.data.get &&
            resourceState.first_page_data.length > 0 &&
            resourceState.first_page_data.map((data: any) => {
              return (
                <tr key={data.id}>
                  <td>
                    <div className="text_and_icons">
                      {" "}
                      {data.resource_extension === "pdf" && (
                        <img
                          className="talbe_icons"
                          src={pdfIcon}
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                          alt="pdf"
                        />
                      )}
                      {(data.resource_extension?.toLowerCase() === "docx" ||
                        data.resource_extension?.toLowerCase() === "doc") && (
                        <img
                          className="talbe_icons"
                          src={docxIcon}
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                          alt="pdf"
                        />
                      )}
                      {(data.resource_extension?.toLowerCase() === "rar" ||
                        data.resource_extension?.toLowerCase() === "zip") && (
                        <img
                          className="talbe_icons"
                          src={rarIcon}
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                          alt="pdf"
                        />
                      )}
                      {(data.resource_extension?.toLowerCase() === "jpg" ||
                        data.resource_extension?.toLowerCase() === "jpeg" ||
                        data.resource_extension?.toLowerCase() === "png" ||
                        data.resource_extension?.toLowerCase() === "svg") && (
                        <img
                          className="talbe_icons"
                          src={imgIcon}
                          onDoubleClick={(e) => openPdfInNewTab(data)}
                          alt="img"
                        />
                      )}
                      {data.resource_type !== "file" && (
                        <img
                          src={folderIcon}
                          className="talbe_icons"
                          onDoubleClick={() => getDataInsideFolder2(data)}
                          alt="folder"
                        />
                      )}
                      {editFolderId !== data.id &&
                        data.resource_type !== "file" && (
                          <span
                            className="resource__file__name"
                            onDoubleClick={() => getDataInsideFolder2(data)}
                          >
                            {data.resource_name}
                          </span>
                        )}
                      {editFolderId !== data.id &&
                        data.resource_type === "file" && (
                          <span
                            className="resource__file__name"
                            onDoubleClick={(e) => openPdfInNewTab(data)}
                          >
                            {`${data.resource_name}.${data.resource_extension}`}
                          </span>
                        )}
                      {editFolderId === data.id && (
                        <FileNameEditor
                          data={data}
                          resourceState={resourceState}
                        />
                      )}
                    </div>
                  </td>
                  <td>
                    {data.resource_type === "file" && "-"}
                    {data.resource_type !== "file" && data.inside_files_count}
                  </td>
                  <td>{data.files_size}</td>
                  <ActionMenu
                    data={data}
                    showInfo={showInfo}
                    setActiveId={setActiveId}
                    activeId={resourceState.active_id}
                    deleteResource={openDeleteResource}
                    setEditFolderId={setEditFolderId}
                    dispatch={dispatch}
                  />
                </tr>
              );
            })}
        </tbody>
      </table>
      {!resourceState.loading.data.get &&
        resourceState.req_success.data.get &&
        resourceState.first_page_data.length <= 0 && (
          <Empty
            // search={resourceState.not_found}
            // empty={!resourceState.not_found}
            emptyText="It seems empty."
          />
        )}
      {/* empty={searchText.lenth <= 0} */}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={resourceState.loading.folder.delete}
        onClick={deleteResource}
      />
      {/* <div className="empty_section">
        <Empty />
      </div> */}
    </div>
  );
};

export default ListFileAndFolder;
