import PaginateButton from "../../../../shared/PaginateButton/PaginateButton";
import { subAdminPaginate } from "../../../../store/features/AdminPanel/SubAdmin/SubAdminSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";

const SubAdminFooter = () => {
  const dispatch = useAppDispatch();
  const userState: any = useAppSelector((store: RootStore) => {
    return store.SubAdminSlice;
  });
  const handlePagination = (num: number) => {
    dispatch(subAdminPaginate(num));
  };
  return (
    <div className="mentor__pagination__section">
      <PaginateButton
        paginate={handlePagination}
        totalRows={userState.all_users.length}
      />
    </div>
  );
};

export default SubAdminFooter;
