import React, { useEffect, useState } from "react";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import { PlanButton, PrimaryButton } from "../../../../shared/Buttons/Buttons";
import FileUploader from "../../../../shared/FilesAndFolderUplader/FileUploader/FileUploader";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { cleanUploaderScreen } from "../../../../store/features/AdminPanel/User/UserSlice";
import "./index.scss";
import { loadUsers } from "../../../../store/features/AdminPanel/User/UserApi";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const UserFileUploaderModal = (props: any) => {
  const {
    showFileUploadModal,
    setShowFileUploadModal,
    downloadSampleCsv,
    downloadCsvLoading,
  } = props;
  const dispatch = useAppDispatch();
  const userState: any = useAppSelector((store: RootStore) => {
    return store.UserSlice;
  });
  const [fileUploadError, SetFileUpladError] = useState<any>({});

  const [progress, setProgress] = useState<any>({
    name: "",
    progress: 0,
    id: 0,
  });

  const clearUploader = () => {
    setShowFileUploadModal(false);
    SetFileUpladError({});
    dispatch(cleanUploaderScreen());
  };
  const handleModal = () => {
    setShowFileUploadModal(false);
    SetFileUpladError({});
    dispatch(cleanUploaderScreen());
  };

  useEffect(() => {
    if (fileUploadError?.created_users?.length > 0) {
      dispatch(loadUsers());
    }
  }, [fileUploadError?.created_users]);

  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  return (
    <Modal
      show={showFileUploadModal}
      setShow={setShowFileUploadModal}
      onClick={handleModal}
    >
      <ModalHeader title="File Upload" />
      <ModalBody>
        <FileUploader
          fileUploadError={fileUploadError}
          SetFileUpladError={SetFileUpladError}
          progress={progress}
          setProgress={setProgress}
        />
        <div className="csv_uploader_foooter__section">
          {progress.progress === 100 && userState.uploaderLoading ? (
            <div className="waiting__screen">
              <div className="waiting_spinner" />
              <span className="text"> Please Wait...</span>
            </div>
          ) : (
            configState.configs?.membership_enabled === Domain.TAA && (
              <div className="file__upload__btn">
                <PlanButton
                  text="Download Sample"
                  size="sm"
                  type="button"
                  onClick={downloadSampleCsv}
                  loading={downloadCsvLoading}
                />
              </div>
            )
          )}
          <div className="">
            <PrimaryButton
              text="Done"
              type="submit"
              onClick={clearUploader}
              disable={userState.uploaderLoading}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UserFileUploaderModal;
