import { roles } from "../../../helpers/utils/Constants/roles";

 export  const StudentRoles = [
    {
      name:"Scrum Master",
      role:roles.SCRUM_MASTER_STUDENT
    },
    {
      name: "Product Owner",
      role:roles.PRODUCT_OWNER_STUDENT
    },
    {
      name: "Business Analyst",
      role:roles.BUISNESS_ANALYST_STUDENT
    }

  ]

  