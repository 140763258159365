/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addUsers,
  deleteUsers,
  loadUsers,
  getAllTrialUser,
  updateUsers,
  rensedInvitation,
  UploadCSVFile,
  downloadUserExcelFile,
  downloadUserCsvFile,
  deleteMultipleUsers,
  getusersByFilter,
} from "./UserApi";

const initialState: any = {
  loading: false,
  resend_invitaiton_loading: false,
  adderLoading: false,
  deleteLoading: false,
  updateLoading: false,
  editUser: false,
  req_success: false,
  resend_invitation_success: false,
  delete_success: false,
  edit_success: false,
  add_success: false,
  single_user_info: {},
  current_page: 0,
  all_users: [],
  temp_users: [],
  users: [],

  clean_upload_screen: false,
  uploaderLoading: false,
  uploaderSuccess: false,
  downloadLoading: false,
  downloadSuccess: false,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    editUserInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.editUser = true;
      state.single_user_info = payload;
    },
    closeEditModal: (state: any, { payload }: PayloadAction<any>) => {
      state.editUser = false;
    },
    refreshUser: (state: any) => {
      state.users = state.all_users.slice(0, 10);
    },
    usersPaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.users = state.all_users.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.users = state.all_users.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.users = state.all_users.slice(payload * 10, 10 * payload + 10);
      }
    },

    searchUsers: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_users = state.all_users.filter(
          (user: any) =>
            user.name.toLowerCase().includes(payload.toLowerCase()) ||
            user.email.toLowerCase().includes(payload.toLowerCase())
        );
        state.users = state.temp_users.slice(0, 10);
      } else {
        state.users = state.all_users.slice(
          state.current_page * 10,
          10 * state.current_page + 10
        );
      }
    },

    cleanUploaderScreen: (state: any) => {
      state.clean_upload_screen = true;
    },
  },
  extraReducers: (builder) => {
    // LOAD USERS
    builder.addCase(loadUsers.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      loadUsers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_users = payload?.data.reverse();
        state.users = state.all_users.slice(0, 10);
      }
    );
    builder.addCase(loadUsers.rejected, (state) => {
      state.loading = false;
    });

    // GET USERS BY FILTER
    builder.addCase(getusersByFilter.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getusersByFilter.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_users = payload?.data.reverse();
        state.users = state.all_users.slice(0, 10);
      }
    );
    builder.addCase(getusersByFilter.rejected, (state) => {
      state.loading = false;
    });

    // GET ALL TRIAL OR TRANNIG USERS
    builder.addCase(getAllTrialUser.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getAllTrialUser.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_users = payload?.data.reverse();
        state.users = state.all_users.slice(0, 10);
      }
    );
    builder.addCase(getAllTrialUser.rejected, (state) => {
      state.loading = false;
    });

    // ADD USERS
    builder.addCase(addUsers.pending, (state) => {
      state.adderLoading = true;
      state.add_success = false;
    });
    builder.addCase(
      addUsers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.adderLoading = false;
        state.add_success = true;
        if (payload.data) {
          state.all_users = [payload?.data, ...state.all_users];

          if (state.current_page === 0) {
            state.users = state.all_users.slice(0, 10);
          } else {
            state.users = state.all_users.slice(
              state.current_page * 10,
              state.current_page * 10 + 10
            );
          }
        }
      }
    );
    builder.addCase(addUsers.rejected, (state) => {
      state.adderLoading = false;
      state.add_success = false;
    });

    // RESEND INVITATION
    builder.addCase(rensedInvitation.pending, (state) => {
      state.resend_invitaiton_loading = true;
      state.resend_invitation_success = false;
      state.edit_success = false;
    });
    builder.addCase(
      rensedInvitation.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.resend_invitaiton_loading = false;
        state.resend_invitation_success = true;
        state.edit_success = true;
        state.editUser = false;
      }
    );
    builder.addCase(rensedInvitation.rejected, (state) => {
      state.resend_invitaiton_loading = false;
    });

    // update USERS
    builder.addCase(updateUsers.pending, (state) => {
      state.adderLoading = true;
      state.edit_success = false;
    });
    builder.addCase(
      updateUsers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.adderLoading = false;
        state.edit_success = true;
        state.users = state.users.map((user: any) => {
          if (user.id === payload?.data.id) {
            return payload?.data;
          }
          return user;
        });

        state.all_users = state.all_users.map((user: any) => {
          if (user.id === payload?.data.id) {
            return payload?.data;
          }
          return user;
        });
      }
    );
    builder.addCase(updateUsers.rejected, (state) => {
      state.adderLoading = false;
      state.edit_success = false;
    });

    // DELETE USERS
    builder.addCase(deleteUsers.pending, (state) => {
      state.deleteLoading = true;
      state.delete_success = false;
    });
    builder.addCase(
      deleteUsers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.deleteLoading = false;
        state.delete_success = true;
        state.all_users = state.all_users.filter(
          (user: any) => user.id !== payload?.id
        );
        if (state.current_page === 0) {
          state.users = state.all_users.slice(0, 10);
        } else {
          state.users = state.all_users.slice(
            state.current_page * 10,
            state.current_page * 10 + 10
          );
        }

        // state.users = state.users.filter(
        //   (user: any) => user.id !== payload?.id,
        // );
      }
    );
    builder.addCase(deleteUsers.rejected, (state) => {
      state.delete_success = false;
      state.deleteLoading = false;
    });

    // DELETE MULTIPLE USERS
    builder.addCase(deleteMultipleUsers.pending, (state) => {
      state.deleteLoading = true;
      state.delete_success = false;
    });
    builder.addCase(
      deleteMultipleUsers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.deleteLoading = false;
        state.delete_success = true;
        state.all_users = state.all_users.filter(
          (user: any) => !payload?.ids.includes(user.id)
        );
        if (state.current_page === 0) {
          state.users = state.all_users.slice(0, 10);
        } else {
          state.users = state.all_users.slice(
            state.current_page * 10,
            state.current_page * 10 + 10
          );
        }
      }
    );
    builder.addCase(deleteMultipleUsers.rejected, (state) => {
      state.delete_success = false;
      state.deleteLoading = false;
    });

    // ADD CSV files
    builder.addCase(UploadCSVFile.pending, (state) => {
      state.uploaderLoading = true;
      state.uploaderSuccess = false;
      state.clean_upload_screen = false;
    });
    builder.addCase(
      UploadCSVFile.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.uploaderLoading = false;
        state.uploaderSuccess = true;
        console.log("payload---", payload);
        // if (payload.data) {

        // }
      }
    );
    builder.addCase(UploadCSVFile.rejected, (state) => {
      state.uploaderLoading = false;
    });
    builder.addCase(downloadUserExcelFile.pending, (state) => {
      state.downloadLoading = true;
      state.downloadSuccess = false;
    });
    builder.addCase(
      downloadUserExcelFile.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.downloadLoading = false;
        state.downloadSuccess = true;
      }
    );
    builder.addCase(downloadUserExcelFile.rejected, (state) => {
      state.downloadLoading = false;
      state.downloadSuccess = false;
    });
    builder.addCase(downloadUserCsvFile.pending, (state) => {
      state.downloadSuccess = false;
      state.downloadLoading = true;
    });
    builder.addCase(
      downloadUserCsvFile.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.downloadLoading = false;
        state.downloadSuccess = true;
      }
    );
    builder.addCase(downloadUserCsvFile.rejected, (state) => {
      state.downloadLoading = false;
      state.downloadSuccess = false;
    });
  },
});

// export const { setRecentSignupEmail, invokeLogout } = userSlice.actions;
export const {
  editUserInfo,
  usersPaginate,
  searchUsers,
  closeEditModal,
  refreshUser,
  cleanUploaderScreen,
} = userSlice.actions;
export default userSlice.reducer;
