import React from "react";
import { RootStore, useAppSelector } from "../../../../store/store";
import darkModeImg from "../../../../assets/images/darkLearningResourceEmptyImg.svg";
import LightModeImg from "../../../../assets/images/learnerlight.svg";


import "./index.scss"

const EmptylearningResourceComp = () => {
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <div className="lerner_empty">

      <div className="img">
        <img
          src={themeState.themeMode === "light" ? LightModeImg : darkModeImg}
          alt="emptypic"
        />
        <p>No learner resource files added. </p>
      </div>

    </div>
  );
};

export default EmptylearningResourceComp;
