import { useState } from "react";
import BillingCard from "./BillingCard/BillingCard";
import SubscriptionBox from "./SubscriptionBox/SubscriptionBox";
import SubscriptionTable from "./Table/SubscriptionTable";
import { RootStore, useAppSelector } from "../../../../store/store";

const UserSubscription = () => {
  const [showModal, setShowModal] = useState(false);
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  return (
    <div className="user__subscription__container">
      <BillingCard showModal={showModal} setShowModal={setShowModal} />

      {authState.tac_user?.plan === "free" && (
        <>
          {" "}
          <SubscriptionBox setShowModal={setShowModal} />
        </>
      )}
      {authState.tac_user?.plan === "paid" && <SubscriptionTable />}
    </div>
  );
};

export default UserSubscription;
