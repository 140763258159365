/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import Table, {
  TableBody,
  TableAction,
  TableHeading,
} from "../../../../../shared/Table/Table";
import DeleteModal from "../../../../../shared/Modals/DeleteModal/DeleteModal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import { deleteCategory } from "../../../../../store/features/AdminPanel/Blog/Category/CategoryApi";
import {
  CategoryTablePaginate,
  editCategoryInfo,
} from "../../../../../store/features/AdminPanel/Blog/Category/CategorySlice";
import PaginateButton from "../../../../../shared/PaginateButton/PaginateButton";
import "./index.scss";
import Empty from "../../../../../shared/Empty/Empty";

const BlogCatagoryTable = () => {
  const [catId, setCatId] = useState(0);
  const dispatch = useAppDispatch();

  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const deleteModalHandler = (catId: number) => {
    setCatId(catId);
    setDeleteShowModal(true);
  };
  const removeCat = () => {
    dispatch(deleteCategory({ catId: catId }));
  };
  const editModalHandler = (author: any) => {
    dispatch(editCategoryInfo(author));
  };
  const catState: any = useAppSelector((store: RootStore) => {
    return store.CategorySlice;
  });
  useEffect(() => {
    if (catState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [catState.req_success.delete]);
  const handlePagination = (num: number) => {
    dispatch(CategoryTablePaginate(num));
  };

  return (
    <>
      <div className="category_table">
        <Table
          heading={
            <TableHeading>
              <th> Name</th>
              <th> </th>
            </TableHeading>
          }
        >
          <TableBody>
            {!catState.loading.get &&
              catState.req_success.get &&
              catState.categories.map((cat: any) => {
                return (
                  <tr key={cat.id}>
                    <td>{cat.name}</td>
                    <TableAction
                      onEdit={() => editModalHandler(cat)}
                      onDelete={() => deleteModalHandler(cat.id)}
                    />
                  </tr>
                );
              })}
          </TableBody>
        </Table>
        {!catState.loading.get &&
              catState.req_success.get &&
              catState.categories.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
         )}
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setDeleteShowModal}
          loading={catState.loading.delete}
          onClick={removeCat}
        />
      </div>
      <div className="category__pagination__section">
        <PaginateButton
          rowPerView={6}
          paginate={handlePagination}
          totalRows={catState.all_category.length}
        />
      </div>
    </>
  );
};

export default BlogCatagoryTable;
