import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  getWebinars,
  addOrUpdateWebinar,
  deleteWebinar,
  getWebinarUsers,
} from "./WebinarApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    delete: false,
  },
  req_success: {
    add: false,
    get: false,
    delete: false,
    edit: false,
  },
  current_webinar_type: "upcoming",
  isEdit: false,
  current_webinar: null,
  archive_webinars: [],
  upcoming_webinar: null,
  all_webinar_users: [],
  webinar_users: [],
};

const webinarSlice = createSlice({
  name: "webinars",
  initialState,
  reducers: {
    editWebinarInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.isEdit = true;
      state.showModal = true;
      state.current_webinar = payload;
    },
    setShowModal: (state: any) => {
      state.showModal = true;
    },
    closeModal: (state: any) => {
      state.isEdit = false;
      state.showModal = false;
      state.current_webinar = null;
    },
    changeWebinarType: (state: any, { payload }: PayloadAction<any>) => {
      state.current_webinar_type = payload;
    },
    filterUser: (state: any, { payload }: PayloadAction<any>) => {
      state.webinar_users = state.all_webinar_users.filter((user: any) => {
        if (!payload.date) {
          return true;
        }
        return (
          moment(user.webinar_date, "MM/DD/YYYY, dddd").format("YYYY-MM-DD") ===
          payload.date
        );
      });

      state.webinar_users = state.webinar_users.filter((user: any) => {
        if (payload.search === "") {
          return true;
        }
        return (
          user.name?.toLowerCase().includes(payload.search.toLowerCase()) ||
          user.email?.toLowerCase().includes(payload.search.toLowerCase())
        );
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebinars.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });

    builder.addCase(
      getWebinars.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.upcoming_webinar = payload.data.upcoming_webinar;
        state.archive_webinars = payload.data.archive_webinars;
      }
    );
    builder.addCase(getWebinars.rejected, (state) => {
      state.loading.get = false;
    });

    builder.addCase(deleteWebinar.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });

    builder.addCase(
      deleteWebinar.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        if (state.current_webinar_type === "upcoming") {
          state.upcoming_webinar = null;
        }

        if (state.current_webinar_type === "archive") {
          state.archive_webinars = state.archive_webinars.filter(
            (webinar: any) => webinar.id !== payload
          );
        }
      }
    );

    builder.addCase(deleteWebinar.rejected, (state) => {
      state.loading.delete = false;
    });

    builder.addCase(addOrUpdateWebinar.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });

    builder.addCase(
      addOrUpdateWebinar.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;

        if (payload.data) {
          state.upcoming_webinar = payload?.data;
        }
      }
    );

    builder.addCase(addOrUpdateWebinar.rejected, (state) => {
      state.loading.add = false;
    });

    builder.addCase(getWebinarUsers.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });

    builder.addCase(
      getWebinarUsers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.all_webinar_users = payload.data;
        state.webinar_users = payload.data;
      }
    );

    builder.addCase(getWebinarUsers.rejected, (state) => {
      state.loading.get = false;
    });
  },
});

export const {
  editWebinarInfo,
  setShowModal,
  closeModal,
  changeWebinarType,
  filterUser,
} = webinarSlice.actions;
export default webinarSlice.reducer;
