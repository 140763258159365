import React from "react";
import GetStarted from "../../../components/Landingpage/GetStarted/GetStarted";
import LayoutLanding from "../../../components/Landingpage/Layout/LayoutLanding";

function Contact(props: any) {
  return (
    <LayoutLanding>
      <div className="contactpage-container">
        <GetStarted />
      </div>
    </LayoutLanding>
  );
}

export default Contact;
