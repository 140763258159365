import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import UserHeader from "../UserHeader/UserHeader";
import UserContent from "../UserContent/UserContent";
import UserFooter from "../UserFooter/UserFooter";
import "./usertabs.scss";
import MentorHeader from "../../Mentors/MentorHeader/MentorHeader";
import MentorContent from "../../Mentors/MentorContent/MentorContent";
import MentorFooter from "../../Mentors/MentorFooter/MentorFooter";
import { RootStore, useAppSelector, useAppDispatch } from "../../../../store/store";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromComponentLevelState } from "../../../../helpers/utils/urlHelper";
import TrialUserHeader from "../TrialUser/TrialUserHeader/TrialUserHeader";
import TrialUserContent from "../TrialUser/TrialUserContent/TrialUserContent";
import Subscribers from "../../../../pages/AdminPanel/Subscribers/Subscribers";
import ApplyNow from "../../../../pages/AdminPanel/ApplyNow/ApplyNow";
import SubAdminHeader from "../../SubAdmin/SubAdminHeader/SubAdminHeader";
import SubAdminContent from "../../SubAdmin/SubAdminContent/SubAdminContent";
import SubAdminFooter from "../../SubAdmin/SubAdminFooter/SubAdminFooter";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import { deleteMultipleUsers, loadUsers } from "../../../../store/features/AdminPanel/User/UserApi";

const UserAllTabs = () => {
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [downloadCsvLoading, setDownloadCsvLoading] = useState(false);
  const [showSelectCheckbox,setShowSelectCheckbox] = useState(false);
  const [deleteAllUserModal,setDeleteAllUserModal] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState<number[]>([]);
  const location: any = useLocation();

  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const downloadSampleCsv = async () => {
    setDownloadCsvLoading(true);
    const dynamic_url = renderDynamicUrlFromComponentLevelState(configState);

    await axiosInstance.get(`${dynamic_url}/get-sample-csv-url`).then((res) => {
      window.location.href = res.data.data;
    });
    setDownloadCsvLoading(false);
  };

  useEffect(() => {
    if (location?.state?.tag === "apply-now") {
      setTabIndex(4);
    }
  }, [location?.state?.tag]);

  const handleCheckboxChange = (e:any,userId: number) => {
    e.stopPropagation();
    if (checkedUsers.includes(userId)) {
      // Remove the userId if already checked
      setCheckedUsers((prev) => prev.filter((id) => id !== userId));
    } else {
      // Add the userId if not checked
      setCheckedUsers((prev) => [...prev, userId]);
    }
  };

  const userState: any = useAppSelector((store: RootStore) => {
    return store.UserSlice;
  });


  useEffect(() => {
    if (!userState.deleteLoading && userState.delete_success) {
      setDeleteAllUserModal(false);
    }
  }, [userState]);

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      // Uncheck all users
      setCheckedUsers([]);
    } else {
      // Check all users
      const allUserIds = userState.users.map(
        (question: any) => question.id
      );
      setCheckedUsers(allUserIds);
    }

    // Toggle the state of "Select All" checkbox
    setSelectAllChecked((prev) => !prev);
  };

  const deleteUser = () => {
    dispatch(deleteMultipleUsers({ids: checkedUsers})).then(()=>{
      setShowSelectCheckbox(false);
      setCheckedUsers([]);
    });
  };

  const isActiveDeleteAllUsers = showSelectCheckbox && checkedUsers?.length !==0;

  return (
    <div className="user__tabs__container mt-7">
      <Tabs
        className="user__tab__container"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="user__tab__list__section">
          <Tab className="user__single__tab ">All Users</Tab>
          <Tab className="user__single__tab mll">Sub-Admin</Tab>

          {configState.configs?.membership_enabled === Domain.TAA && (
            <>
              {" "}
              <Tab className="user__single__tab mll">All Mentors</Tab>
             
              <Tab className="user__single__tab mll">Trial Users</Tab>
              <Tab className="user__single__tab mll">Subscribers</Tab>
              <Tab className="user__single__tab mll">Apply Now</Tab>
            </>
          )}
        </TabList>
        <TabPanel>
          <div className="box__shadow">
            <UserHeader
              downloadSampleCsv={downloadSampleCsv}
              downloadCsvLoading={downloadCsvLoading}
              showSelectCheckbox={showSelectCheckbox}
              setShowSelectCheckbox={setShowSelectCheckbox}
              setDeleteAllUserModal={setDeleteAllUserModal}
              checkedUsers={checkedUsers}
              setCheckedUsers={setCheckedUsers}
              setSelectAllChecked={setSelectAllChecked}
              isActiveDeleteAllUsers={isActiveDeleteAllUsers}
            />
            <UserContent  
            showSelectCheckbox={showSelectCheckbox}
            setShowSelectCheckbox={setShowSelectCheckbox} 
            checkedUsers={checkedUsers}
            handleCheckboxChange={handleCheckboxChange}
            selectAllChecked={selectAllChecked}
            handleSelectAllChange={handleSelectAllChange}
            setCheckedUsers={setCheckedUsers}
            />
          </div>
          <UserFooter />
        </TabPanel>
        <TabPanel>
              <div className="box__shadow">
                <SubAdminHeader />
                <SubAdminContent />
              </div>
              <SubAdminFooter />
            </TabPanel>
        {configState.configs?.membership_enabled === Domain.TAA && (
          <>
            <TabPanel>
              <div className="box__shadow">
                <MentorHeader />
                <MentorContent />
              </div>
              <MentorFooter />
            </TabPanel>


            <TabPanel>
              <div className="box__shadow">
                <TrialUserHeader />
                <TrialUserContent />
              </div>
              <MentorFooter />
            </TabPanel>
            <TabPanel>
              <div className="box__shadow">
                <Subscribers />
              </div>
            </TabPanel>
            <TabPanel>
              <div className="box__shadow">
                <ApplyNow />
              </div>
            </TabPanel>
          </>
        )}
      </Tabs>
        <DeleteModal
        showModal={deleteAllUserModal}
        setShowModal={setDeleteAllUserModal}
        loading={userState.deleteLoading}
        onClick={deleteUser}
        message ={`You want to delete ${checkedUsers.length} users ? This action cannot be undone.`}
        />
    </div>
  );
};

export default UserAllTabs;
