import LearningPathCourseTable from "./LearningPathCourseTable";
import LearningPathCourseHeader from "./LearningPathCourseHeader";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { learningCoursePaginate } from "../../../store/features/AdminPanel/ViewCourses/ViewCoursesSlice";
import PaginateButton from "../../../shared/PaginateButton/PaginateButton";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const LearningPathsCourseDesign = () => {
  const dispatch = useAppDispatch();
  const courseState: any = useAppSelector((store: RootStore) => {
    return store.ViewCoursesSlice;
  });
  const handlePagination = (num: number) => {
    dispatch(learningCoursePaginate(num));
  };
  return (
    <div>
      <BoxShadow>
      <div className="mt-7">
      <LearningPathCourseHeader />
        <LearningPathCourseTable />
      </div>
      </BoxShadow>

      <div className="pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={courseState.all_viewCourse.length}
        />
      </div>
    </div>
  );
};
export default LearningPathsCourseDesign;
