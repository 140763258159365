import "./card.scss";

export const Card = (props: any) => {
  const { title, children, mt1 } = props;
  return (
    <div className="card">
      {title && <span className={`card_title ${mt1 && "mt1"}`}>{title}</span>}
      {children}
    </div>
  );
};
