import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import CohortsHeader from "../../../components/AdminPanel/Cohorts/CohortsHeader";
import CohortsTable from "../../../components/AdminPanel/Cohorts/CohortsTable/CohortsTable";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const LpCohorts = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="mt-7">
          <CohortsHeader />
          <CohortsTable />
        </div>
      </BoxShadow>
      {/* <TeamsFooter /> */}
    </AdminPanelLayout>
  );
};
export default LpCohorts;
