import { lazy } from "react";
import User from "../../pages/AdminPanel/User/User";
import Blog from "../../pages/AdminPanel/Blog/Blog";
import Archive from "../../pages/AdminPanel/Archive/Archive";
import Reference from "../../pages/AdminPanel/Reference/Reference";
import QuickLinks from "../../pages/AdminPanel/QuickLinks/QuickLinks";
import { Domain } from "../../helpers/utils/DomainSwitcher/domains";
import { ROUTES } from "../../helpers/utils/Constants/route";

const Dashboard = lazy(
  () => import("../../pages/AdminPanel/Dashboard/Dashboard")
);

const AllAdminAndSubAdminRouteList = [
  {
    id: 1,
    path: ROUTES.DASHBOARD,
    component: Dashboard,
    domain: [Domain.TAA, Domain.TAC],
  },

  {
    id: 2,
    path: ROUTES.USER,
    component: User,
    domain: [Domain.TAA, Domain.TAC],
  },

  {
    id: 3,
    path: ROUTES.BLOG,
    component: Blog,
    domain: [Domain.TAA, Domain.TAC],
  },

  {
    id: 4,
    path: ROUTES.ARCHIVE,
    component: Archive,
    domain: [Domain.TAA, Domain.TAC],
  },

  {
    id: 5,
    path: ROUTES.REFERENCE,
    component: Reference,
    domain: [Domain.TAA],
  },
  {
    id: 7,
    path: ROUTES.QUICK_LINKS,
    component: QuickLinks,
    domain: [Domain.TAA],
  },
];

export default AllAdminAndSubAdminRouteList;
