import { Search } from "react-feather";
import "./searchBar.scss";

const SearchBar = (props: any) => {
  const { value, setValue, selectSearch, onInput } = props;
  return (
    <div className="searchbar">
      <Search className={`search__icon ${selectSearch && "Search__icon__style__handle"} `} />
      <input
        id="name"
        className={`search__input ${selectSearch && "select__search"}`}
        name="name"
        type="search"
        placeholder="Search"
        value={value}
        onInput={onInput}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
