/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Download,
  Edit,
  Edit2,
  Info,
  MoreVertical,
  Trash,
} from "react-feather";
import { setActiveSlice } from "../../../../store/features/AdminPanel/ResourceManager/ResourceManagerSlice";

const ActionMenu = (props: any) => {
  const {
    data,
    showInfo,
    setActiveId,
    activeId,
    deleteResource,
    setEditFolderId,
    dispatch,
  } = props;

  const closeActionTool = () => {
    dispatch(setActiveSlice(0));
  };

  const onButtonClick = () => {
    fetch(data?.file_path).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        const alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${data.resource_name}.${data.resource_extension}`;
        alink.click();
      });
    });
  };
  return (
    <td>
      <div className="text_and_icons ">
        <span>{data.last_modified}</span>
        <MoreVertical
          className="action__icons"
          onClick={() => setActiveId(data.id)}
        />

        <div
          className={`action_dropdown  ${activeId === data.id ? "active" : ""}`}
        >
          <div className="action_list" onClick={() => setEditFolderId(data.id)}>
            <Edit2 className="action_list_icon" /> <span>Edit</span>
          </div>
          <div className="actioin_divider" />

          {data.resource_extension !== "rar" &&
            data.resource_type === "file" &&
            data.resource_extension !== "zip" && (
              <>
                <div className="action_list" onClick={closeActionTool}>
                  <Edit className="action_list_icon" />
                  {data.resource_extension.toLowerCase() !== "docx" &&
                    data.resource_extension.toLowerCase() !== "doc" && (
                      <a href={data.file_path} target="_blank" rel="noreferrer">
                        <span>Preview</span>
                      </a>
                    )}
                  {(data.resource_extension.toLowerCase() === "docx" ||
                    data.resource_extension.toLowerCase() === "doc") && (
                    <a
                      href={`https://view.officeapps.live.com/op/embed.aspx?src=${data.file_path}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Preview</span>
                    </a>
                  )}
                </div>
                <div className="actioin_divider" />
              </>
            )}

          <div className="action_list" onClick={() => deleteResource(data.id)}>
            <Trash className="action_list_icon" /> <span>Delete</span>
          </div>
          <div className="actioin_divider" />
          {data.resource_type === "file" && (
            <>
              <div className="action_list" onClick={onButtonClick}>
                <Download className="action_list_icon" /> <span>Download</span>
              </div>
              <div className="actioin_divider" />
            </>
          )}
          <div className="action_list" onClick={() => showInfo(data)}>
            <Info className="action_list_icon" /> <span>Info</span>
          </div>
        </div>
      </div>
    </td>
  );
};

export default ActionMenu;
