import React from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft } from "react-feather";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { RootStore, useAppSelector } from "../../../../../store/store";
import { Domain } from "../../../../../helpers/utils/DomainSwitcher/domains";

const ViewDetailsHeader = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/user", { state: { tag: "apply-now" } });
  };
  const NavigateInApplyNow = () => {
    navigate("/apply-now");
  };
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const applyNowState: any = useAppSelector((state) => state.ApplyNowSlice);

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>
          <div style={{ display: "flex", marginLeft: "-1rem" }}>
            <span style={{ cursor: "pointer" }}>
              <ChevronLeft onClick={configState.configs?.membership_enabled === Domain.TAC ? NavigateInApplyNow : handleNavigate} />
            </span>
            <span>{applyNowState.singleApplyNow?.role}</span>
          </div>
        </TableHeadingTitle>
      </div>
    </div>
  );
};

export default ViewDetailsHeader;
