import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  activeLinks: [],
};
// ADD about;
const UISlice = createSlice({
  name: "UISlice",
  initialState,
  reducers: {
    addActiveLinks: (state: any, { payload }: PayloadAction<any>) => {
      state.activeLinks.push(payload);
    },
    removeActiveLinks: (state: any, { payload }: PayloadAction<any>) => {
      const temp = state.activeLinks.filter((e: any) => {
        return e !== payload;
      });
      state.activeLinks = temp;
    },
  },
  extraReducers: (builder) => {},
});

export const { addActiveLinks, removeActiveLinks } = UISlice.actions;
export default UISlice.reducer;
