/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from "react";
import { ChevronDown, ChevronLeft } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Avatar from "../../../shared/Avatar/Avatar";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  adminCourseDetail,
  getAllTenantcourses,
} from "../../../store/features/Tenant/Orgination/Courses/CoursesApi";
import {
  enrollToThinkificCourse,
  loginToThinkificAndRedirect,
} from "../../../store/features/Thinkific/ThikificApi";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import { tenantEntollToCourse } from "../../../store/features/Tenant/Orgination/LearningPath/TenantLearningPathApi";
import TenantLoadingScreen from "../../Auth/TenantLoading/TenantLoadingScreen";
// import { THINKFIC_BASE_URL } from "../../../apiConfigs/urlConfig";

const AdminCourseDetail = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [slug, setSlug] = useState("");

  const courseState: any = useAppSelector((store: RootStore) => {
    return store.TanentCoursesSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const pathState: any = useAppSelector((store: RootStore) => {
    return store.TenantLearningPathSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const [active, setActive] = useState(0);

  useEffect(() => {
    dispatch(adminCourseDetail({ courseId: Number(params.id) }));
  }, []);
  const startLearning = (course: any) => {
    setSlug(course.slug);
    dispatch(enrollToThinkificCourse({ courseId: course.course_id })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: course.slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          console.log(res.payload.url);
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };
  useEffect(() => {
    if (pathState.req_success.enroll) {
      window.location.href = `${themeState.configs.site_url}/login/${authState.tac_user.email}/${slug}/${authState.tenant}`;
    }
  }, [pathState.req_success.enroll]);
  return (
    <>
     {!courseState.loading.detail && courseState.req_success.detail && (
    <div className="UserCourseDetail__wrapper">
    
          <div className="course__detail__section">
            <div className="header__section">
              <ChevronLeft
                className="back__icon"
                onClick={() => navigate("/admin-courses")}
              />
              <span className="header__title">Lessons in this course </span>
            </div>
            <div className="custom__according__section">
              {" "}
              <Accordion allowZeroExpanded>
                {courseState.chapters.map((chapter: any) => {
                  return (
                    <AccordionItem key={chapter.id}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                        <div  className="accordian__content__wrapper">
                          <span
                            className={`test ${
                              active === chapter.id ? "active" : ""
                            }`}
                            onClick={() => setActive(chapter.id)}
                          >
                            {chapter.name}
                          </span>
                          <ChevronDown/>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        {chapter.contents.map((content: any, i: number) => {
                          return (
                            <div key={i} className="content__list">
                              <span className="dot" />
                              <span className="course__unit">{content}</span>
                            </div>
                          );
                        })}
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </div>
          <div className="course__instructor__section">
            <div className="card__section">
              {courseState.course_detail.percentage_completed === 0 && (
                <PrimaryButton
                  type="button"
                  text="Start Course"
                  loading={courseState.course_detail.slug === slug}
                  onClick={() => startLearning(courseState.course_detail)}
                />
              )}
              {courseState.course_detail.percentage_completed > 0 &&
                courseState.course_detail.percentage_completed < 100 && (
                  <PrimaryButton
                    type="button"
                    text="Resume Course"
                    loading={courseState.course_detail.slug === slug}
                    onClick={() => startLearning(courseState.course_detail)}
                  />
                )}

              {Math.floor(courseState.course_detail.percentage_completed) ===
                100 && (
                <PrimaryButton
                  type="button"
                  text="Course Completed"
                  loading={courseState.course_detail.slug === slug}
                  onClick={() => startLearning(courseState.course_detail)}
                />
              )}

              <span className="buldle__tile mt-2">
                {courseState.course_detail.name}
              </span>
              <div className="row">
                {/* <span className="coures__count"> 10 Corses</span>
                <span className="deadline"> Deadline: 28/11/2022</span> */}
                <span className="duration">
                  Duration {courseState.course_detail.custom_duration}{" "}
                </span>
              </div>
              <ProgressBar
                completed={Math.floor(
                  courseState.course_detail.percentage_completed
                )}
                height="3px"
                customLabel="."
                baseBgColor="var( --primary-progress-bar-bg)"
                bgColor="var( --secondary-progress-bar-bg)"
                labelSize="0"
              />
              <span className="completed__count">
                {Math.floor(courseState.course_detail.percentage_completed)}%
              </span>
              <p className="description">
                {courseState.course_detail.description}
              </p>

              <div className="detail__and__instructor__divider" />
              
              {courseState?.instructor &&
                courseState?.instructor.length &&
                courseState?.instructor.map((ins: any) => {
                  return (
                    <div className="instructor__profile" key={ins.id}>
                      <div className="pic">
                        <Avatar imageUrl={ins?.avatar_url} />
                      </div>
                      <div className="info">
                        <span className="name">
                          {ins?.first_name} {ins?.last_name}
                        </span>
                        <span className="occupation"> {ins?.title}</span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>{" "}
    </div>
      )}

    {
      courseState.loading.detail && !courseState.req_success.detail && (<TenantLoadingScreen message=""/>)
    }

    </>
  );
};

export default AdminCourseDetail;
