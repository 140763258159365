import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  viewCourseApi,
  deleteCourseApi,
  updateCourseApi,
} from "./ViewCoursesApi";

const initialState: any = {
  loading: false,
  adder_loading: false,
  req_success: false,
  added_req_success: false,
  current_page: 0,
  viewCourse: [],
  all_viewCourse: [],
  temp_courses: [],
};

const viewCourseSlice = createSlice({
  name: "viewCourseSlice",
  initialState,
  reducers: {
    learningCoursePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.viewCourse = state.all_viewCourse.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.viewCourse = state.all_viewCourse.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.viewCourse = state.all_viewCourse.slice(
          payload * 10,
          10 * payload + 10,
        );
      }
    },
    searchInLearningCourseTable: (
      state: any,
      { payload }: PayloadAction<any>,
    ) => {
      if (payload.length > 1) {
        state.temp_courses = state.all_viewCourse.filter((course: any) =>
          course.course_name.toLowerCase().includes(payload.toLowerCase()),
        );
        state.viewCourse = state.temp_courses.slice(0, 10);
      } else {
        state.viewCourse = state.all_viewCourse.slice(
          state.current_page * 10,
          10 * state.current_page + 10,
        );
      }
    },

    manageLearningTempOrder: (state: any, { payload }: PayloadAction<any>) => {
      const a = state.all_viewCourse[payload.sourdeIndex];

      state.all_viewCourse.splice(payload.sourdeIndex, 1);

      state.all_viewCourse.splice(payload.destinationIndex, 0, a);

      state.viewCourse = state.all_viewCourse;
    },
  },
  extraReducers: (builder) => {
    // GET ALL courses bundle
    builder.addCase(viewCourseApi.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      viewCourseApi.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_viewCourse = payload?.data;
        state.viewCourse = state.all_viewCourse.slice(0, 10);
      },
    );
    builder.addCase(viewCourseApi.rejected, (state) => {
      state.loading = false;
    });

    // delete course
    builder.addCase(deleteCourseApi.pending, (state) => {
      state.deleteLoading = true;
      state.delete_success = false;
    });
    builder.addCase(
      deleteCourseApi.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.deleteLoading = false;
        state.delete_success = true;

        state.viewCourse = state.viewCourse.filter(
          (c: any) => c.id !== payload?.id,
        );
        state.all_viewCourse = state.all_viewCourse.filter(
          (c: any) => c.id !== payload?.id,
        );
      },
    );
    builder.addCase(deleteCourseApi.rejected, (state) => {
      state.delete_success = false;
      state.deleteLoading = false;
    });

    // update course
    builder.addCase(updateCourseApi.pending, (state) => {
      state.update_loading = true;
      state.update_success = false;
    });
    builder.addCase(
      updateCourseApi.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.update_loading = false;
        state.update_success = true;
        state.viewCourse = state.viewCourse.map((course: any) => {
          if (course.id === payload?.data.id) {
            return payload?.data;
          }
          return course;
        });
        state.all_viewCourse = state.all_viewCourse.map((course: any) => {
          if (course.id === payload?.data.id) {
            return payload?.data;
          }
          return course;
        });
      },
    );
    builder.addCase(updateCourseApi.rejected, (state) => {
      state.update_loading = false;
    });
  },
});
export const {
  learningCoursePaginate,
  searchInLearningCourseTable,
  manageLearningTempOrder,
} = viewCourseSlice.actions;
export default viewCourseSlice.reducer;
