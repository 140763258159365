import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import VideoTourComp from "../../../components/AdminPanel/VideoTourComp/VideoTourComp";

const VideoTour = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <VideoTourComp />
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default VideoTour;
