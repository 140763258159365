import { lazy } from "react";

// import OrgUserDashboardPage from "../pages/OrginationUser/UserDashboard/OrgDashboardPage";
import OrgUserLearningPathPage from "../pages/OrginationUser/UserLearningPath/OrgUserLearningPathPage";
import OrgUserCoursesPage from "../pages/OrginationUser/UserCourses/OrgUserCoursesPage";

import OrgUserLearningPathCoursePage from "../pages/OrginationUser/UserLearningPathCourses/OrgUserLearningPathCoursePage";
import OrgUserCourseDetailPage from "../pages/OrginationUser/UserViewCourseDetail/OrgUserViewDetailPage";
import { ROUTES } from "../helpers/utils/Constants/route";

const OrgUserDashboardPage = lazy(
  () => import("../pages/OrginationUser/UserDashboard/OrgDashboardPage")
);

const AllTenantUserRoute = [
  {
    id: 1,
    path: ROUTES.ORGANIZATION_USER_DASHBOARD,
    component: OrgUserDashboardPage,
  },
  {
    id: 2,
    path: "/",
    component: OrgUserLearningPathPage,
  },
  {
    id: 3,
    path: ROUTES.ORGANIZATION_USER_LEARNING_PATH,
    component: OrgUserLearningPathPage,
  },
  {
    id: 4,
    path: ROUTES.ORGANIZATION_USER_COURSE_DETAIL,
    component: OrgUserCourseDetailPage,
  },
  {
    id: 5,
    path: ROUTES.ORGANIZATION_USER_LEARNING_PATH_COURSE,
    component: OrgUserLearningPathCoursePage,
  },
  {
    id: 6,
    path: ROUTES.ORGANIZATION_USER_COURSES,
    component: OrgUserCoursesPage,
  },
];

export default AllTenantUserRoute;
