/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import { Edit2, Trash } from "react-feather";

import TestimonialEmpty from "./TestimonialEmpty";
import {
  CreateTestimonials,
  DeleteTestimonials,
  GetTestimonials,
} from "../../../store/features/AdminPanel/Testimonial/TestimonialApi";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import Table, { TableHeading } from "../../../shared/Table/Table";
import "./testimonial.scss";
import DeleteModal from "../../../shared/Modals/DeleteModal/DeleteModal";

function TestimonialBody(props: any) {
  const dispatch = useAppDispatch();
  const testimonialsState: any = useAppSelector((store: RootStore) => {
    return store.TestimonialsSlice;
  });
  const {
    showModal,
    setShowModal,
    editModal,
    setEditModal,
    currId,
    setCurrId,
  } = props;
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  // const [currId, setCurrId] = useState(0);
  const handleDelete = () => {
    dispatch(DeleteTestimonials(currId));
    if (!testimonialsState.loading && testimonialsState.req_success) {
      setDeleteShowModal(false);
    }
  };

  useEffect(() => {
    dispatch(GetTestimonials("landing_page"));
  }, []);
  return (
    <div className="testimonialbody">
      {testimonialsState?.data?.length ? (
        <Table
          heading={
            <TableHeading>
              <th>Name</th>
              <th>Designation</th>
              <th className="hide__text">.</th>
              <th className="hide__text">.</th>
            </TableHeading>
          }
        >
          <tbody>
            {testimonialsState.filteractive &&
              testimonialsState.filterdata.map((e: any) => {
                return (
                  <tr className="ttr" key={e.id}>
                    <td className="ttd ttd_name">
                      <div className="ttd_image">
                        <img src={e.image} alt="user" />
                      </div>
                      {e.name}
                    </td>
                    <td className="ttd">{e.designation}</td>{" "}
                    <td className="ttd ttd_top">
                      {e.is_top ? "Top testimonial" : ""}
                    </td>
                    <td className="ttd ttd_action">
                      <Edit2
                        className="edit__icon"
                        onClick={() => {
                          setEditModal(true);
                          setShowModal(true);
                          setCurrId(e.id);
                        }}
                      />
                      <Trash
                        className="delete__icon"
                        onClick={() => {
                          setDeleteShowModal(true);
                          setCurrId(e.id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            {!testimonialsState.filteractive &&
              testimonialsState.data.map((e: any) => {
                return (
                  <tr className="ttr" key={e.id}>
                    <td className="ttd ttd_name">
                      <div className="ttd_image">
                        <img src={e.image} alt="user" />
                      </div>
                      {e.name}
                    </td>
                    <td className="ttd">{e.designation}</td>{" "}
                    <td className="ttd ttd_top">
                      {e.is_top ? "Top testimonial" : ""}
                    </td>
                    <td className="ttd ttd_action">
                      <Edit2
                        className="edit__icon"
                        onClick={() => {
                          setEditModal(true);
                          setShowModal(true);
                          setCurrId(e.id);
                        }}
                      />
                      <Trash
                        className="delete__icon"
                        onClick={() => {
                          setDeleteShowModal(true);
                          setCurrId(e.id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
        <TestimonialEmpty
          showModal={showModal}
          setShowModal={setShowModal}
          setEditModal={setEditModal}
        />
      )}

      {/* {!testimonialsState.data?.length && (
        <TestimonialEmpty
          showModal={showModal}
          setShowModal={setShowModal}
          setEditModal={setEditModal}
        />
      )} */}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={testimonialsState.loading}
        onClick={handleDelete}
      />
    </div>
  );
}

export default TestimonialBody;
