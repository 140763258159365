/* eslint-disable import/no-named-as-default */
import { Card25 } from "../../../../../shared/Card/Card25/Card25";
import "./index.scss";
import SingleAssignment from "./SingleAssignment";

const Assignments = (props: any) => {
  const { title, assignmentState, YesCourse, yesPath } = props;
  return (
    <div className="assignment__section">
      <Card25 title={title}>
        <div className="single__assignment__row">
          <SingleAssignment
            title="Group assignment"
            emptyTitle="You have not been added to any groups."
            filter
            assignmentState={assignmentState}
            YesCourse={YesCourse}
            yesPath={yesPath}
          />
          <SingleAssignment
            title="individual assignment"
            emptyTitle="You have not been assigned to any course."
            deleteIcon
            addAssignment
            assignmentState={assignmentState}
            YesCourse={YesCourse}
            yesPath={yesPath}
          />
        </div>
      </Card25>
    </div>
  );
};

export default Assignments;
