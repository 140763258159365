import { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Edit2, Trash } from "react-feather";

import Table, {
  TableAction,
  TableHeading,
} from "../../../../shared/Table/Table";

import "./faqcontent.scss";

import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../../store/store";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";

import {
  deleteFaqs,
  loadFaqs,
  updateFaqs,
  updateOrder,
} from "../../../../store/features/UserDashboard/Faq/FaqApi";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import { changeTempOrder } from "../../../../store/features/UserDashboard/Faq/FaqSlice";
import Empty from "../../../../shared/Empty/Empty";

const FaqContent = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [currId, setCurrId] = useState<Number>();
  const dispatch = useAppDispatch();
  const faqState: any = useAppSelector((store: RootStore) => {
    return store.FaqSlice;
  });

  const validationSchema = yup.object().shape({
    question: yup.string().required(REQUIRED_VALIDATION("Question")),
    answer: yup.string().required(REQUIRED_VALIDATION("Answer")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>(formOptions);
  const onSubmit = (values: any) => {
    dispatch(updateFaqs({ ...values, id: currId }));
    setShowModal(false);

    reset();
  };
  const handleDelete = () => {
    dispatch(deleteFaqs(currId!));

    setDeleteShowModal(false);
  };
  const handleEdit = (id: number) => {
    setShowModal(true);
    const data = faqState.faqs.filter((e: any) => {
      return e.id === id;
    });
    setValue("question", data[0].question);
    setValue("answer", data[0].answer);
  };
  const listenDrag = (result: any) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    //
    const data2 = {
      changeOrder: true,
      sourdeIndex: source.index,
      destinationIndex: destination.index,
      draggableId: draggableId,
    };
    dispatch(changeTempOrder(data2));
    const data = { list: { [draggableId]: destination.index + 1 } };
    dispatch(updateOrder({ data: data }));
  };

  return (
    <div>
      <DragDropContext onDragEnd={listenDrag}>
        <Droppable droppableId="239999">
          {(provided) => (
            <Table
              heading={
                <TableHeading>
                  <th>Question</th>
                  <th>Answer</th>
                  <th className="hide__text">.</th>
                </TableHeading>
              }
            >
              <tbody ref={provided.innerRef}>
                {!faqState.loading &&
                  faqState.req_success &&
                  faqState?.faqs.map((e: any, index: number) => {
                    return (
                      <Draggable
                        draggableId={e.id.toString()}
                        key={e.id.toString()}
                        index={index}
                      >
                        {(provided) => {
                          return (
                            <tr
                              className="faq_tr"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <td className="question_td">{e.question}</td>

                              <td className="answer_td">{e.answer}</td>

                              <td className="drag__faq__action__icons ">
                                <div className="faq_action__section__action">
                                  <div className="faq_drag_test_4544">
                                    {" "}
                                    <Edit2
                                      className="edit__icon"
                                      onClick={() => {
                                        handleEdit(e.id);
                                        setCurrId(e.id);
                                      }}
                                    />
                                    <Trash
                                      className="delete__icon"
                                      onClick={() => {
                                        setDeleteShowModal(true);
                                        setCurrId(e.id);
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
      {!faqState.loading &&
        faqState.req_success &&
        faqState?.faqs.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty
              emptyText="It seems empty."
              emptyColorFulText="Add FAQ"
              emptyText2="now."
              custombg={true}
            />
          </div>
        )}
      <div className="pagination">{/* <PaginateButton /> */}</div>
      <Modal show={showModal} setShow={setShowModal}>
        <ModalHeader title="Update FAQ" />
        <ModalBody>
          <form
            className="login__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <div className="form_row">
              <TextField
                type="text"
                label="Question"
                name="question"
                register={register}
                error={errors?.question}
              />
            </div>

            <div className="form_row">
              <TextArea
                type="text"
                label="Answer"
                name="answer"
                register={register}
                error={errors?.answer}
              />
            </div>

            <div className="button__witdh mt-1">
              <PrimaryButton text="Update" type="submit" loading={false} />
            </div>
          </form>
        </ModalBody>
      </Modal>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        // loading={viewCourseState.loading}
        onClick={handleDelete}
      />
      {/* <DeleteModal */}
    </div>
  );
};

export default FaqContent;
