import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import AllInterviewTab from "../../../components/AdminPanel/AdminInterview/Tab/AllInterviewTab";

const Interview = () => {
  return (
    <AdminPanelLayout>
      <AllInterviewTab />
    </AdminPanelLayout>
  );
};

export default Interview;
