import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const AddTermsOfUse = createAsyncThunk(
  "AddTermsOfUse",
  async (
    { data }: { data: string },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/page/terms-of-use`, {
        content: data,
      });
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const getTermsOfUse = createAsyncThunk(
  "getTermsOfUse",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/page/terms-of-use`);
      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
