/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { Link, Minus, Plus, Trash2 } from "react-feather";

import "./recentQuestionAnser.scss";

import RecentFeedBackList from "../RecentFeedBackList/RecentFeedBackList";
import RecentVideoCard from "../RecentVideoCard/RecentVideoCard";
import dCheckEvaluateIcon from "../../../../../../../assets/icons/check-evaluate.svg";
import whiteCheckEvaluateIcon from "../../../../../../../assets/icons/wcheck-evaluate.svg";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/store";
import DeleteModal from "../../../../../../../shared/DeleteModal/DeleteModal";
import { deleteInterviewAnswerVideo } from "../../../../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.actions";
import BunnyIframe from "../../../../../../NewAcademicUser/InterviewPreparation/VideoRecord/BunnyIframe";
import VideoProcessing from "../../../../../AdminInterviewAnswer/AdminInterviewAnswerFaq/FaqContent/VideoProcessing";
import LinkGenerateModal from "./LinkGenerateModal/LinkGenerateModal";

const RecentAnswerQuestion = (props: any) => {
  const { data } = props;

  const [showVideoDeleteModal, setshowVideoDeleteModal] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [openSharableModal,setopenshareableModal] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [shareableLink, setShareableLink] = useState("");

  const [activeAnswer, setactiveAnswer] = useState(0);

  const hideShowToggle = () => {
    setIsActive(!isActive);
  };
  const videoRef: any = useRef();
  const videoSourceRef: any = useRef();

  const dispatch = useAppDispatch();

  const UserDetail = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const handleDeleteVideo = () => {
    try {
      dispatch(deleteInterviewAnswerVideo(deleteId));
      setshowVideoDeleteModal(false);
    } catch (error) {
      toast.error("Failed to delete video");
    }
  };

  const isEvaluated = data?.evaluated;

  const hasDeleteAccess = UserDetail?.tac_user?.role !== "mentor";

  const closeModal = ()=>{
    setopenshareableModal(false);
  }

  const openShareModal = (id: any, shareableLink: any) => {
    setopenshareableModal(true);
    setVideoId(id);
    setShareableLink(shareableLink);
  }

  return (
    <div className="recent__question__container">
      <div aria-hidden onClick={hideShowToggle}>
        <div className="recent__question__wrapper">
          <div className="recent__question">
            {isEvaluated && (
              <a
                data-tooltip-id="evaluate-my-tooltip"
                data-tooltip-content="Evaluated"
                data-tooltip-place="bottom"
              >
                <div className="evaluate__done">
                  <img
                    src={
                      themeState.themeMode === "dark"
                        ? dCheckEvaluateIcon
                        : whiteCheckEvaluateIcon
                    }
                    alt="evaluate"
                  />
                </div>
              </a>
            )}

            <p>{data?.role}</p>
          </div>
          <div className="recent__attempt__time">
            <p>Answered : {data?.attempted_on}</p>
            {isActive ? <Minus /> : <Plus />}
          </div>
        </div>
        <div className="question__div">{data?.question}</div>
      </div>

      {isActive && (
        <div className="recent__answer__content">
          <div className="left__content">
            <h3>Recorded responses</h3>
            <div className="video__title">
              {data?.answers?.map((item: any, index: number) => {
                return (
                  <div className="video__counter" key={item?.id}>
                    {" "}
                    <span
                      className={`${
                        index === activeAnswer && "active__class__color"
                      }`}
                      aria-hidden
                      onClick={() => setactiveAnswer(index)}
                    >
                      {index + 1}
                      <sup>
                        {" "}
                        {index + 1 === 1 && "st"} {index + 1 === 2 && "nd"}
                        {index + 1 === 3 && "rd"}
                        {index + 1 > 3 && "th"}
                      </sup>{" "}
                      Response
                    </span>
                    <div className="generate__sharable__link"  onClick={() => openShareModal(item?.id, item?.shareable_link)}>
                      <Link/>
                    </div>
                    {hasDeleteAccess && (
                      <Trash2
                        className="delete__icon"
                        onClick={() => {
                          setDeleteId(item?.id);
                          setshowVideoDeleteModal(true);
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Video section  */}
          {data?.answers[activeAnswer] &&
            data?.answers[activeAnswer]?.status === 1 && (
              // <RecentVideoCard
              //   videoRef={videoRef}
              //   videoSourceRef={videoSourceRef}
              //   activeAnswer={data?.answers[activeAnswer]}
              // />
              <BunnyIframe videoId={data?.answers[activeAnswer].video_link} />
            )}

          {data?.answers[activeAnswer]?.status === 0 && <VideoProcessing />}

          {/* Show All feedback list Component */}
          <RecentFeedBackList data={data?.answers[activeAnswer]} />
        </div>
      )}

      <Tooltip
        id="evaluate-my-tooltip"
        place="bottom"
        classNameArrow="evaluate-custom-react-tooltip-arrow"
        className="evaluate-custom-tool-tip"
      />

      <DeleteModal
        showModal={showVideoDeleteModal}
        setShowModal={setshowVideoDeleteModal}
        onClick={handleDeleteVideo}
      />

      <LinkGenerateModal showModal={openSharableModal} setShowModal={setopenshareableModal}
        closeModal={closeModal}
        shareableLink={shareableLink}
        questionId={data.id}
        videoId={videoId}
        recentAnswerSection={true}
      />
    </div>
  );
};

export default RecentAnswerQuestion;
