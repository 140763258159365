import React from "react";

const CourseDetail = (props: any) => {
  const { learningPaths, allCourses } = props;
  return (
    <div className="course__detail__section">
      <div className="path__section">
        <span className="colorful__title"> Learning path</span>
        <div className="content__list__section">
          {learningPaths.map((path: any) => {
            return (
              <div className="content" key={path.id}>
                <span className="dot" />
                <span className="name"> {path.name}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="course__section">
        <span className="colorful__title">Courses</span>
        <div className="content__list__section">
          {allCourses.map((course: any) => {
            return (
              <div className="content" key={course.id}>
                <span className="dot" />
                <span className="name"> {course.name}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;
