import { useEffect, useState } from "react";
import DeleteModal from "../../../shared/DeleteModal/DeleteModal";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../shared/Table/Table";
import {
  deleteLearningPath,
  listLearningPath,
} from "../../../store/features/AdminPanel/LearningPath/LearningPathApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import "./learningPath.scss";

const LearningPathTable = () => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [learningPathId, setLearningPathId] = useState<any>("");

  const learningPathState: any = useAppSelector((store: RootStore) => {
    return store.AdminLearningPathSlice;
  });
  useEffect(() => {
    dispatch(listLearningPath());
  }, []);
  useEffect(() => {
    if (!learningPathState.deleteLoading && learningPathState.delete_success) {
      setDeleteShowModal(false);
    }
  }, [learningPathState]);
  const deleteUser = () => {
    dispatch(deleteLearningPath(learningPathId));
  };
  const openDeleteLearningPath = (learningPathId: any) => {
    setDeleteShowModal(true);
    setLearningPathId(learningPathId);
  };
  return (
    <div className="table__container">
      <Table
        heading={
          <TableHeading>
            <th>Path ID</th>
            <th className="hide__text">.</th>
            <th className="hide__text">.</th>
            <th>Name</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {learningPathState.req_success &&
            learningPathState.learningPaths.map((path: any) => {
              return (
                <tr key={path.path_id}>
                  <td>{path.path_id}</td>
                  <td className="hide__text">.</td>
                  <td className="hide__text">.</td>
                  <td>{path.name}</td>
                  <TableAction
                    onDelete={() => openDeleteLearningPath(path.id)}
                  />
                </tr>
              );
            })}
          {/* {learningPathState?.loading &&
            [1, 2, 3, 4, 5, 6, 7].map(() => {
              return (
                <tr className="skeleton skeleton-table-row">
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              );
            })} */}
        </TableBody>
      </Table>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={learningPathState.deleteLoading}
        onClick={deleteUser}
      />
    </div>
  );
};

export default LearningPathTable;
