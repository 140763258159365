import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  addReferenceMembers,
  deleteReferenceMembers,
  getDescpritionFromUrl,
  loadReferenceMembers,
} from "./ReferenceApi";

// import { loadFaqs, addFaqs, updateFaqs, deleteFaqs } from "./FaqApi";

const initialState: any = {
  loading: false,
  req_success: false,
  currID: -1,
  description: "",
  reference_members: [],
  all_reference_members: [],
};

const ReferenceSlice = createSlice({
  name: "reference",
  initialState,
  reducers: {
    updateReferenceCurrID: (state: any, { payload }: PayloadAction<any>) => {
      state.currID = payload;
    },
  },
  extraReducers: (builder) => {
    // LOAD FAQS
    builder.addCase(loadReferenceMembers.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      loadReferenceMembers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.reference_members = payload?.data;
        state.all_reference_members = payload?.data;
      }
    );
    builder.addCase(loadReferenceMembers.rejected, (state) => {
      state.loading = false;
    });

    // ADD / UPDATE Reference members
    builder.addCase(addReferenceMembers.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      addReferenceMembers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.reference_members = state.reference_members.filter((e: any) => {
          return e.id !== payload?.data.id;
        });
        state.reference_members.push(payload?.data);

        state.all_reference_members = state.reference_members;
      }
    );
    builder.addCase(addReferenceMembers.rejected, (state) => {
      state.loading = false;
    });

    // DELETE Reference
    builder.addCase(deleteReferenceMembers.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      deleteReferenceMembers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.reference_members = state.reference_members.filter(
          (quicklink: any) => quicklink.id !== payload?.id
        );
        state.all_reference_members = state.reference_members.filter(
          (quicklink: any) => quicklink.id !== payload?.id
        );
      }
    );
    builder.addCase(deleteReferenceMembers.rejected, (state) => {
      state.loading = false;
    });

    // get description
    builder.addCase(getDescpritionFromUrl.pending, (state) => {});
    builder.addCase(
      getDescpritionFromUrl.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.description = payload.data;
      }
    );
    builder.addCase(getDescpritionFromUrl.rejected, (state) => {});
  },
});

export const { updateReferenceCurrID } = ReferenceSlice.actions;

export default ReferenceSlice.reducer;
