import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import { allWebguideStepsdata, webGuideOnboarding } from "./ShowOnBoardingPopupApi";
import { loadUser } from "../../Auth/AuthApi";

const initialState = {
  showPopup: true,
  showInnerPopup: true,
  showDonePopupModal:false,
  showPopupInInterviewPrepQuestionSidebar:false,
  currentStep: 1,
  second_step:false,
  steps:allWebguideStepsdata,
  progress:0,
  totalItems:allWebguideStepsdata.length,
  web_guide_data:null,
  loading:false,
  webGuideDataInfo:null
};

const showPoupSlice = createSlice({
  name: "showPopupSlice",
  initialState: initialState,
  reducers: {
    closeShowPopup: (state) => {
      state.showPopup = false;
    },
    closeTourDonePopupModal:(state)=>{
      state.showInnerPopup = false;
      state.showDonePopupModal = false;
      state.showPopup = false;

    },

    setShowPopup:(state)=>{
      state.showPopup = true;
      state.showInnerPopup=true;

    },
    setShowInnerPopupFalse:(state)=>{
      state.showInnerPopup = false;

    },
    
    setShowInnerPopup: (state) => {
      state.showInnerPopup = true;
      state.showPopupInInterviewPrepQuestionSidebar = false;
    },
    goToNextStep: (state) => {
      state.currentStep = Math.min(state.currentStep + 1, state.steps.length);
      if(state.currentStep === 2){
        state.second_step = true;
      }
      if(state.currentStep === 3){
        state.second_step = false;
      }
      if(state.currentStep === 16){
        state.showInnerPopup = false;
        state.showDonePopupModal = true;
      }
      if(state.currentStep < state.totalItems){
        // In our case the last data was a TourDonePopupModal data so i added 1 in current state to calculate proper progress
        state.progress = (((state.currentStep+1 ) / state.totalItems) * 100);

      }
    },
    goToPrevStep: (state) => {
      state.currentStep = Math.max(state.currentStep - 1, 1);
      if(state.currentStep > 1){
        state.progress = (((state.currentStep ) / state.totalItems) * 100);

      }
    },
    defaultProgress:(state,action)=>{
      state.progress = (((action.payload) / state.totalItems) * 100);

    },
    goTostepOne:(state)=>{
      state.currentStep = 1;
      state.second_step = false;
    },
    setcurrentStep:(state,action)=>{
      state.currentStep = action.payload;
    },

    PopupInfoInQuestionSidebar:(state)=>{
      if(state.currentStep===6){
        state.showInnerPopup = false;
        state.showPopupInInterviewPrepQuestionSidebar=true;
      }

    },
   
  },
  extraReducers:(builder)=>{
    builder.addCase(webGuideOnboarding.pending,(state,action)=>{
      state.loading = true;
      
    });
    builder.addCase(webGuideOnboarding.fulfilled,(state:any,{payload}:PayloadAction<any>)=>{

      state.loading = false;
      state.web_guide_data = payload.data;
      
    });
    builder.addCase(webGuideOnboarding.rejected,(state)=>{
      state.loading = false;
      
    });
    builder.addCase(
      loadUser.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.webGuideDataInfo = payload.data.user.onboarding;
        
      });
  }
});

export const { closeShowPopup, goToNextStep, goToPrevStep,goTostepOne,setcurrentStep ,PopupInfoInQuestionSidebar,setShowInnerPopup,closeTourDonePopupModal,defaultProgress,setShowPopup,setShowInnerPopupFalse} =
  showPoupSlice.actions;

export default showPoupSlice.reducer;
