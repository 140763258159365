/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from "react";
import { Edit2, Trash } from "react-feather";
import Table, { TableHeading } from "../../../../shared/Table/Table";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import "./cohortsTable.scss";
import Empty from "../../../../shared/Empty/Empty";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  addOrUpdateCohorts,
  getCohortsData,
} from "../../../../store/features/AdminPanel/Cohorts/CohortsApi";
import { editCohort } from "../../../../store/features/AdminPanel/Cohorts/CohortsSlice";

const CohortsTable = () => {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const cohortState = useAppSelector((store: RootStore) => store.CohortsSlice);

  useEffect(() => {
    dispatch(getCohortsData());
  }, [dispatch]);

  const openDeleteModal = () => {
    setDeleteShowModal(true);
  };

  useEffect(() => {
    if (!cohortState.loading.add) {
      setDeleteShowModal(false);
      dispatch(getCohortsData());
    }
  }, [cohortState.loading.add]);

  const getMonthByNumber = (monthNumber: number) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthNumber - 1] || "";
  };

  const cohortContent = cohortState?.chorts?.content;

  // Parse the content if it's a valid JSON string, otherwise set it to null
  let parsedContent = null;
  if (typeof cohortContent === "string" && cohortContent !== "null") {
    try {
      parsedContent = JSON.parse(cohortContent);
    } catch (error) {
      console.error("Error parsing cohort content:", error);
    }
  }

  return (
    <div className="team_table_container">
      <Table
        heading={
          <TableHeading>
            <th>Month</th>
            <th>Day</th>
            <th className="hide__text">.</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <tbody className="team_tbody">
          {parsedContent && (
            <tr className="table_row_text">
              <td className="user_td_section">
                {getMonthByNumber(Number(parsedContent.month))}
              </td>
              <td className="team_designation__section">{parsedContent.day? parsedContent.day :"-"}</td>
              <td className="team_member__section hide__text">.</td>
              <td className="drag__team__action__icons">
                <div className="action__section__action">
                  <div className="drag_test_4544">
                    <Edit2
                      className="edit__icon"
                      onClick={() => dispatch(editCohort())}
                    />
                    <Trash className="delete__icon" onClick={openDeleteModal} />
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!parsedContent && (
        <div className="mt-5 handle__empty__container__div">
          <Empty
            emptyText="It seems empty."
            emptyColorFulText="Add Cohort"
            emptyText2="now."
            custombg={true}
          />
        </div>
      )}

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={cohortState.loading.add}
        onClick={() =>
          dispatch(addOrUpdateCohorts({ data: { content: "null" } }))
        }
      />
    </div>
  );
};

export default CohortsTable;
