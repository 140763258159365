import { AlertCircle, X } from "react-feather";
import "./warning.scss";
import { RootStore, useAppSelector } from "../../../../store/store";

const Warning = (props: any) => {
  const { setShowWarning } = props;
  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });

  const hideWarning = () => {
    setShowWarning(false);
  };

  return (
    <div className="feedback__warning__wrapper">
      <AlertCircle className="alert__icon" />
      <span>
        {" "}
        {interviewState.active_answer?.answers.length === 2 &&
          "You have only one response submission attempt remaining. "}{" "}
        {interviewState.active_answer?.answers.length === 3 &&
          "You don’t have any response submission attempt remaining. "}{" "}
      </span>
      <X className="cancel__icon" onClick={() => hideWarning()} />
    </div>
  );
};

export default Warning;
