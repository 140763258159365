import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  manageAssignments,
  getSingePathDataForUpdate,
} from "./ManageAssismentApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
  },
  req_success: {
    get: false,
  },
  data: {},
};

const assisment = createSlice({
  name: "assisment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // manage assisments
    builder.addCase(manageAssignments.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      manageAssignments.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
      },
    );
    builder.addCase(manageAssignments.rejected, (state) => {
      state.loading.add = false;
    });
    // get singe path data
    builder.addCase(getSingePathDataForUpdate.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getSingePathDataForUpdate.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.data = payload.data;
      },
    );
    builder.addCase(getSingePathDataForUpdate.rejected, (state) => {
      state.loading.get = false;
    });
  },
});
export default assisment.reducer;
