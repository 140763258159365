import { useEffect, useState } from "react";
import "./learningPathCourseSteppers.scss";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { enrollToThinkificCourse } from "../../../../store/features/ProductManagerUser/ProductManagerCourses/ProductManagerCoursesApi";
import { loginToThinkificAndRedirect } from "../../../../store/features/Thinkific/ThikificApi";

const LearningPathCourseSteppers = (props: any) => {
  const dispatch = useAppDispatch();
  const [slug, setSlug] = useState("");

  const learningPathState: any = useAppSelector((store: RootStore) => {
    return store.TACProductManagerSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const startLearning = (course: any) => {
    setSlug(course.slug);
    dispatch(enrollToThinkificCourse({ courseId: course.course_id })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: course.slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          console.log(res.payload.url);
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };
  useEffect(() => {
    if (learningPathState.req_success.enroll) {
      window.location.href = `${themeState.configs.site_url}/login/${authState.tac_user.email}/${slug}`;
    }
  }, [learningPathState.req_success.enroll]);
  return (
    <div className="learning__path__steppers__container">
      <div className="course__title__section">
        <span className="course__title">Product Manager Learning Path</span>
      </div>
      {learningPathState.req_success.course &&
        learningPathState.courses.map((course: any, index: number) => {
          return (
            <div className="stepper" key={course.id}>
              <span className="counter ">{index + 1}</span>
              <div className="course__detail">
                <span className="title">{course.name}</span>
                <span className="duration">
                  {course.custom_duration ? course.custom_duration : "-"}
                </span>
                <span className="description">{course.description}</span>

                {/* <PrimaryButton text="Start Course" width="satrt__course__btn" /> */}
                {Math.floor(course.percentage_completed) === 0 && (
                  <div className="start__course__btn__width__handle__div">
                    <PrimaryButton
                      text="Start Course"
                      width="satrt__course__btn"
                      onClick={() => startLearning(course)}
                      loading={slug === course.slug}
                    />
                  </div>
                )}
                {Math.floor(course.percentage_completed) > 0 &&
                  Math.floor(course.percentage_completed) < 100 && (
                    <div className="resume__course__btn__width__handle__div">
                      <PrimaryButton
                        text="Resume Course"
                        width="resume__course__btn"
                        onClick={() => startLearning(course)}
                        loading={slug === course.slug}
                      />
                    </div>
                  )}
                {Math.floor(course.percentage_completed) === 100 && (
                  <div className="complete__course__btn__width__handle__div">
                    <PrimaryButton
                      text="Course Completed"
                      width="complete__course__btn"
                      onClick={() => startLearning(course)}
                      loading={slug === course.slug}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default LearningPathCourseSteppers;
