import React from "react";

const PriceDetails = (props: any) => {
  const { subTotal, discount, totalPrice, coupon } = props;
  return (
    <div className="coupon__calculation__section">
      <div className="row">
        <span className="coupon__topography">Subtotal </span>
        <span className="coupon__topography">$ {subTotal}</span>
      </div>
      <div className="row">
        <span className="coupon__blod__topography">{coupon} </span>
        <span className="coupon__topography">-{discount}</span>
      </div>
      <div className="coupon__divider" />
      <div className="row">
        <span className="coupon__blod__topography">Total </span>
        <span className="coupon__blod__topography">${totalPrice}</span>
      </div>
    </div>
  );
};

export default PriceDetails;
