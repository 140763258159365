import React, { useState } from "react";
import "./productPagesDateTimeTable.scss";
import DeleteModal from "../../../../shared/DeleteModal/DeleteModal";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../shared/Table/Table";
import { useAppDispatch, useAppSelector } from "../../../../store/store";
import { deleteDateTime } from "../../../../store/features/AdminPanel/DateTime/DateTimeApi";
import { setIsEdit } from "../../../../store/features/AdminPanel/DateTime/DateTimeSlice";
import Empty from "../../../../shared/Empty/Empty";

const ProductPagesDateTimeTable = (props: any) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dateTimeState: any = useAppSelector((store) => store.DateTimeSlice);
  const dispatch = useAppDispatch();
  const handleDeleteDateTime = (id: any) => {
    setShowDeleteModal(true);
  };

  const deleteHandler = async () => {
    await dispatch(deleteDateTime(dateTimeState.dateTime.id)).then((res) => {
      if (res.payload.success) {
        setShowDeleteModal(false);
      }
    });
  };

  const handleEdit = () => {
    dispatch(setIsEdit(true));
  };

  return (
    <div className="user__table__section__div">
      <Table
        heading={
          <TableHeading>
            <th>Start Date</th>
            <th>Start Time</th>
            <th>End Date</th>
            <th>End Time</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {!dateTimeState.get.loading && dateTimeState.dateTime && (
            <tr>
              <td>{dateTimeState.dateTime?.start_date}</td>
              <td>{dateTimeState.dateTime.start_time ?? ""}</td>
              <td>{dateTimeState.dateTime.end_date ?? ""}</td>
              <td>{dateTimeState.dateTime.end_time ?? ""}</td>

              <TableAction
                onEdit={handleEdit}
                onDelete={() => handleDeleteDateTime(dateTimeState.dateTime.id)}
              />
            </tr>
          )}

        </TableBody>
      </Table>
      {!dateTimeState.get.loading && dateTimeState.dateTime === null && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." emptyColorFulText="Add date and time" emptyText2="now" custombg={true} />
        </div>
          )}
         
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onClick={deleteHandler}
        loading={dateTimeState.delete.loading}
      />
    </div>
  );
};

export default ProductPagesDateTimeTable;
