import React, { useEffect, useState } from "react";
import { Download } from "react-feather";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getInvoices } from "../../../../store/features/UserDashboard/Payment/PaymentApi";
import { searchInvoices } from "../../../../store/features/UserDashboard/Payment/PaymentSlice";
import TacTable, {
  TacTableBody,
  TacTableHeading,
} from "../../../../shared/TacTable/TacTable";
import CustomEmptyComp from "../../../../shared/Empty/component/CustomEmptyComp";

const InvoiceHistory = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();
  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });

  useEffect(() => {
    dispatch(getInvoices());
  }, []);
  useEffect(() => {
    dispatch(searchInvoices(searchText));
  }, [searchText]);

  return (
    <div className="user__invoice__history__container">
      <div className="user__invoice__history__header__section">
        <div className="left__section">
          <TableHeadingTitle>Invoice History</TableHeadingTitle>
        </div>
        <div className="right__section">
          <div className="search__box">
            <SearchBar value={searchText} setValue={setSearchText} />
          </div>
        </div>
      </div>
      <div className="user__invoice__history__table">
        {" "}
        <TacTable
          heading={
            <TacTableHeading>
              <th>Amount</th>
              <th>Invoice Number</th>
              <th>Billing Type</th>
              <th>Invoice Date</th>

              <th className="hide__text">.</th>
            </TacTableHeading>
          }
        >
          <TacTableBody>
            {paymentState.req_success.invoice &&
              paymentState.invoices.map((invoice: any) => {
                return (
                  <tr key={invoice.id}>
                    <td>{invoice.total_price}</td>
                    <td>{invoice.invoice_number}</td>
                    <td>{invoice.type}</td>
                    <td>{invoice.invoice_created_at}</td>
                    <td className="download__td">
                      {/* <a href={invoice.invoice_url} download>
                        <Download className="download__button" />
                      </a> */}
                    </td>
                  </tr>
                );
              })}
          </TacTableBody>
        </TacTable>
        {paymentState.req_success.invoice && (
          <CustomEmptyComp
            search={searchText.length > 1 && paymentState.invoices.length === 0}
            empty={paymentState?.invoices?.length === 0}
          />
        )}
      </div>
    </div>
  );
};

export default InvoiceHistory;
