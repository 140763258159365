/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "./index.scss";
import moment from "moment";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SelectDropDown from "../../../../shared/SelectDropDown/SelectDropDown";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { PlanButton, PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import {
  combineValidationSchemas,
  TestimonialFilterForTAAOnly,
  TestimonialFilterForTACOnly,
  timeZoneOptions,
} from "./helper";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../store/store";
import {
  addClasses,
  loadClasses,
  updateClasses,
} from "../../../../store/features/UserDashboard/OurClasses/OurClassApi";
import SelectField from "../../../../shared/Inputs/SelectField/SelectField";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import { closeClassEditModal } from "../../../../store/features/UserDashboard/OurClasses/OurClassSlice";
import ErrorText from "../../../../shared/Inputs/ErrorText/ErrorText";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const OurClassesHeader = (props: any) => {
  const { showModal, setShowModal } = props;
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const [guarantee, setGuarantee] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    configState.configs?.membership_enabled === Domain.TAC
    ?TestimonialFilterForTACOnly[0]
    : TestimonialFilterForTAAOnly[0]
  );
  const [sessionTime, setSessionTime] = useState([1]);
  const [timezone, setTimezone] = useState(null);

  const dispatch = useAppDispatch();

  const classState: any = useAppSelector((store: RootStore) => {
    return store.OurClassSlice;
  });

  const today = new Date().toISOString().split("T")[0];
  
  useEffect(() => {
    localStorage.setItem("sessionTimes", "1");
    dispatch(loadClasses(selectedValue.value));
  }, []);

  const options = timeZoneOptions.map((data) => ({
    label: data.name,
    value: data.zone,
  }));
  // Define session count
  const sessionCount = sessionTime.length;

  const createSessionValidationSchema = (sessionTime:any) => {
    const sessionSchema:any = {};
  
    sessionTime.forEach((sessionId:any) => {
      sessionSchema[`session_date${sessionId}`] = yup
        .date()
        .typeError(`Session ${sessionId} date is required`)
        .required(`Session ${sessionId} date is required`);

      // Add validation rules for start and end time fields
      sessionSchema[`session_start_time${sessionId}`] = yup
        .string()
        .required(`Session ${sessionId} start time is required`);
        
      sessionSchema[`session_end_time${sessionId}`] = yup
        .string()
        .required(`Session ${sessionId} end time is required`);
    });
  
    return sessionSchema;
  };
  const baseValidationSchema = yup.object().shape({
    timezone: yup.object().shape({
      value: yup.string().required("Timezone is required"),
      label: yup.string().required("Timezone is required")
    })
  });


  const createValidationSchema = (sessionTime:any) => {
    return yup.object().shape({
      ...baseValidationSchema.fields,
      ...createSessionValidationSchema(sessionTime)
    });
  };


  const [validationSchema, setValidationSchema] = useState(createValidationSchema(sessionTime));


  // Initialize form with combined validation schema
  const formOptions = {
    resolver: yupResolver(combineValidationSchemas(sessionCount)),
  };
  const { control, register, unregister, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });
  const { sessions } = watch() || { sessions: [] };
  const onSubmit = (values: any) => {
    const data = { ...values, guaranteed: guarantee, timezone: timezone };
    data.for = selectedValue.value;

    // Now you can do whatever you want with the formatted sessions data
    const formattedData: any = transformFormData(data);
    if(classState?.editClass){
      dispatch(updateClasses({...formattedData,id:classState?.single_class_info?.id,}));
    }
    else{
      dispatch(addClasses(formattedData));
    }
    onClick();
  };

  const transformFormData = (formData: any) => {
    const { for: forValue, timezone, guaranteed, ...sessions } = formData;

    const sessionKeys = Object.keys(sessions).filter((key) =>
        key.startsWith("session_date")
    );

    const formattedSessions = sessionKeys.map((key) => {
        const index = key.replace("session_date", "");
        const sessionDate = sessions[`session_date${index}`];

        // Use moment to handle the session date and time
        const sessionDateMoment = moment(sessionDate); 
        const formattedSessionDate = sessionDateMoment.format("YYYY-MM-DD");
        
        return {
            session_date: formattedSessionDate,
            session_start_time: sessions[`session_start_time${index}`],
            session_end_time: sessions[`session_end_time${index}`],
        };
    });

    return {
        for: forValue,
        timezone,
        guaranteed,
        sessions: formattedSessions,
    };
};
  // Usage
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const handleOption = (data: any) => {
    // console.log(data);
    setSelectedValue(data);
    dispatch(loadClasses(data.value));
  };

  // console.log(selectedValue);
  const onClick = () => {
    setShowModal(false);
    dispatch(closeClassEditModal(false));
    reset()
    setGuarantee(false);
    setValue("timezone",{label:"Select Time Zone",value:""});
    setSessionTime([1]);
    setValidationSchema(createValidationSchema([1]));
  };

  const handleGuarantee = () => {
    setGuarantee(!guarantee);
  };

  const handleTimezone = (data: any) => {
    if (data) {
      setTimezone(data?.value);
    }
  };

  const removeSession = (id:any, index:any) => {
    const updatedSessionTime = sessionTime.filter((_, i) => i !== index);
    setSessionTime(updatedSessionTime);
    unregister(`session_date${id}`);
    unregister(`session_start_time${id}`);
    unregister(`session_end_time${id}`);
    setValidationSchema(createValidationSchema(updatedSessionTime));
  };

 

  useEffect(() => {
    if (classState?.editClass && classState?.single_class_info) {
      setShowModal(true);
  
      // Set the timezone
      const timezoneOption = timeZoneOptions.find(
        (option) => option.zone === classState?.single_class_info.timezone
      );
      if (timezoneOption) {
        setValue("timezone", {
          label: timezoneOption.name,
          value: timezoneOption.zone,
        });
        setTimezone(classState?.single_class_info.timezone);
      }
  
      setGuarantee(classState?.single_class_info.guaranteed === 1);
  
      // Populate session data and initialize sessionTime state
      const sessions = classState?.single_class_info.sessions || [];
      sessions.forEach((session: { session_date: any; session_start_time: any; session_end_time: any; }, index: number) => {
        // Populate form fields for each session
        setValue(`session_date${index + 1}`, session.session_date);
        setValue(`session_start_time${index + 1}`, session.session_start_time);
        setValue(`session_end_time${index + 1}`, session.session_end_time);
      });
  
      // Set sessionTime to match the number of sessions
      setSessionTime(sessions.map((_: any, index: number) => index + 1));
    }
  }, [classState?.editClass, classState?.single_class_info, setShowModal, setValue]);
  
  const handleSessionTime = () => {
    const newSessionId = sessionTime.length + 1;
    const updatedSessionTime = [...sessionTime, newSessionId];
    setSessionTime(updatedSessionTime);
    setValidationSchema(createValidationSchema(updatedSessionTime));
    return newSessionId;
  };
  

useEffect(()=>{
  // for add and update 
  if(classState?.add && !classState?.update || !classState?.add && classState?.update){
    dispatch(loadClasses(selectedValue.value));
    onClick()
  }

},[classState?.update,classState?.add]);


  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Our Classes For </TableHeadingTitle>
        <div className="dropdwn__apo__session">
          <SelectDropDown
            data={    configState.configs?.membership_enabled === Domain.TAC
              ?TestimonialFilterForTACOnly
              : TestimonialFilterForTAAOnly}
            selectedValue={selectedValue?.name}
            handleOption={handleOption}
          />
        </div>
      </div>
      <div className="right__section">
        {/* <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div> */}
        <div className="button__witdh">
          <PrimaryButton
            text="Add Class"
            size="sm"
            type="button"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal} onClick={onClick}>
        <ModalHeader title={classState?.editClass ? "Edit Class" : "Add Class"} />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="timezone__select">
           

              <Controller
                control={control}
                name="timezone"
                render={({ field }) => (
                  <SelectField
                    type="select"
                    label="Time Zone"
                    placeholder="Select Time Zone"
                    // isSearchable
                    handleChange={handleTimezone(field.value)}
                    options={options}
                    isSearchable={true}
                     // @ts-ignore
                    error={errors?.timezone?.value}
                    register={register}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="date__session__boxes">
              {sessionTime.map((e: any, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="date__session__box__wrapper">
                    <div className="date__session__box">
                      <div className="date__input">
                        <TextField
                          label={`“Session ${index + 1}” Date`}
                          type="date"
                          min={today}
                          name={`session_date${e}`}
                          register={register}
                          // error={`${errors?.session_date}${e}.message`}
                          error={errors[`session_date${e}`]}
                        />
                      </div>
                      <div className="start__time">
                        <div className="time__box">
                          <span>Start Time</span>
                          <input
                            type="time"
                            className="timeInput"
                            {...(register &&
                              register(`session_start_time${e}`))}
                          />
                        </div>
                         <ErrorText error={errors[`session_start_time${e}`]?.message}/> 
                      </div>
                      <div className="end__time">
                        <div className="time__box">
                          <span>End Time</span>
                          <input
                            type="time"
                            className="timeInput"
                            {...(register && register(`session_end_time${e}`))}
                          />
                        </div>
                        <ErrorText error={errors[`session_end_time${e}`]?.message}/> 
                      </div>
                    </div>
                    <div className="remove__button">
                      <button
                        type="button"
                        onClick={() => removeSession(e, index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="modal__footer__div">
              <div className="guarantee__checkbox">
                <CheckBox
                  label="Set as guaranteed to run"
                  fullwidth
                  onClick={handleGuarantee}
                  value={guarantee}
                />
              </div>
              <div className="buttons">
                <div className="">
                  <PlanButton
                    text="Add Session"
                    onClick={() =>
                      setSessionTime([...sessionTime, handleSessionTime()])
                    }
                  />
                </div>
                <div className="button__witdh">
                  <PrimaryButton text={classState?.editClass ? "Edit Class" : "Add Class"} type="submit" />
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default OurClassesHeader;
