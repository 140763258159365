import {
  PrimaryButton,
  TACPrimaryButton,
} from "../../../../../shared/Buttons/Buttons";
import { Card } from "../../../../../shared/Card/Card";
import { Card25 } from "../../../../../shared/Card/Card25/Card25";
import { clearStripeFalse } from "../../../../../store/features/UserDashboard/Payment/PaymentSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import "./index.scss";

const SubscriptionBox = (props: any) => {
  const { setShowModal } = props;

  const dispatch = useAppDispatch();
  const openBillingModal = () => {
    dispatch(clearStripeFalse());
    setShowModal(true);
  };
  return (
    <Card title="Subscriptions" mt1>
      <div className="subscriptions_info__section">
        You are currently on the FREE subscription plan which allows limited
        access to course and path content. If you wish to access the full Agile
        Coach course library simply upgrade your account below.
      </div>
      <div className="upgrade__button">
        <TACPrimaryButton
          text="Upgrade My Account"
          onClick={() => openBillingModal()}
        />
      </div>
    </Card>
  );
};

export default SubscriptionBox;
