/* eslint-disable import/no-extraneous-dependencies */
import { Icon } from "@iconify/react";
import "./customEmptyComp.scss";
import searchEmptyDarkImg from "../../../assets/images/searchDarkEmptyImg.svg";
import searchEmptyLightImg from "../../../assets/images/searchLightEmptyImg.svg";

import whiteEmptyIcon from "../../../assets/images/empty-light.svg";
import blackEmptyIcon from "../../../assets/images/empty-black.svg";
import { RootStore, useAppSelector } from "../../../store/store";

const CustomEmptyComp = (props: any) => {
  const { search, className, empty, tac } = props;

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <div
      className={`custom__empty__container ${className} ${
        search || empty ? "show_empty_box" : "hide_empty_box"
      }`}
    >
      {search && (
        <>
          {!tac ? (
            <img
              src={
                themeState.themeMode === "dark"
                  ? searchEmptyDarkImg
                  : searchEmptyLightImg
              }
              alt="empty"
            />
          ) : (
            <Icon icon="iconoir:file-not-found" className="file__not__found" />
          )}
          <span className="empty__description__text mt-2 ">
            No search result found.
          </span>
        </>
      )}

      {!search && empty && (
        <>
          {!tac ? (
            <img
              src={
                themeState.themeMode === "dark"
                  ? blackEmptyIcon
                  : whiteEmptyIcon
              }
              alt="empty"
            />
          ) : (
            <Icon icon="iconoir:file-not-found" className="file__not__found" />
          )}
          <span className="empty__description__text mt-2 ">
            It seems empty.
          </span>
        </>
      )}
    </div>
  );
};

export default CustomEmptyComp;
