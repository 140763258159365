import React from "react";
// import Nav from "../../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import QuestEmptyComp from "../../../../components/NewAcademicUser/InterviewPreparation/InterviewQuestEmpty/QuestEmptyComp";

function InterviewQuestEmpty(props: any) {
  return (
  <>
    {/* <Nav normal navBgColorHandle  /> */}
    <QuestEmptyComp/>
  </>
  );
}
export default InterviewQuestEmpty
