import { createSlice } from "@reduxjs/toolkit";
import { disConnectWithGoHighlevel, getGoHighlevelInfo } from "./ConnectWithGoHighlevelApi";

const initialState = {
    data: null,
    loading:false,
    disconnect:false,
    disSuccess:false
  };

export const connectWithGoHighlevelSlice = createSlice({
    name:"connectWithGoHighlevelSlice",
    initialState,
    reducers:{

    },
    extraReducers:(builder)=>{
        builder.addCase(getGoHighlevelInfo.pending,(state)=>{
            state.loading = true
        });
        builder.addCase(getGoHighlevelInfo.fulfilled,(state,{payload})=>{
            state.loading = false;
            state.data = payload.data
        });
        builder.addCase(getGoHighlevelInfo.rejected,(state)=>{
            state.loading = false;
        });

        builder.addCase(disConnectWithGoHighlevel.pending,(state)=>{
            state.disconnect = true;
            state.disSuccess = false;
        });
        builder.addCase(disConnectWithGoHighlevel.fulfilled,(state,{payload})=>{
            state.disconnect = false;
            state.disSuccess = true;
        });
        builder.addCase(disConnectWithGoHighlevel.rejected,(state)=>{
            state.disconnect = false;
            state.disSuccess = false;
        });
    }
});

export default connectWithGoHighlevelSlice.reducer;