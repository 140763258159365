/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
/* eslint-disable react/require-default-props */
import ReactHtmlParser from "react-html-parser";

import "./index.scss";
import logo from "../../../../assets/images/half-logo.png";
import MessageLoading from "./MessageLoading";
import { formatOpenAiResponseAsHtml } from "../../../../helpers/utils/StringHelper";

interface Props {
  user?: boolean;
  ai?: boolean;
}

const CurrentResponse = (props: any) => {
  const { message, themeState } = props;
  return (
    <div className="center__messages">
      <div className="message__section  ai">
        <div className="center__item">
          <div className="image__avatar">
            <img src={themeState.configs.sidebar_closed_logo} alt="" />
          </div>
          <div className="message__and__buttons__div">
            <div
              className="message"
              // dangerouslySetInnerHTML={{
              //   __html: formatOpenAiResponseAsHtml(message),
              // }}
            >
              {" "}
              {ReactHtmlParser(message)}
            </div>
            {/* {!loading && ai && (
              <div className="ai_buttons">
                {" "}
                <button
                  type="button"
                  className="ai_message__button"
                  onClick={() =>
                    handleAiFilter(
                      message.question,
                      "summarize",
                      message.questionId
                    )
                  }
                >
                  Summarize
                </button>
                <button
                  type="button"
                  className="ai_message__button"
                  onClick={() =>
                    handleAiFilter(
                      message.question,
                      "elaborate",
                      message.questionId
                    )
                  }
                >
                  Elaborate
                </button>
                <button
                  type="button"
                  className="ai_message__button"
                  onClick={() =>
                    handleAiFilter(
                      message.question,
                      "example",
                      message.questionId
                    )
                  }
                >
                  Example
                </button>{" "}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentResponse;
