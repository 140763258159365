import { useState } from "react";
import ChatSidebar from "../ChatSidebar/ChatSidebar";
import ChatingArea from "../ChatingArea/ChatingArea";
import "./index.scss";
import MobileSidebar from "../ChatSidebar/MobileSidebar";

const Conversation = () => {
  const [hashId, setHashId] = useState<string>("111-1111-1111-111-111");
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  return (
    <div className="conversation__wrappper">
      <ChatSidebar setHashId={setHashId} />
      <MobileSidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        setHashId={setHashId}
      />

      <div className="margin__for__chating">
        <ChatingArea hashId={hashId} setShowSideBar={setShowSideBar} />
      </div>
    </div>
  );
};

export default Conversation;
