import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import ViewGroupDetail from "../../../components/Orgination/Groups/ViewGroupDetail/ViewGroupDetail";

const ViewGroup = () => {
  return (
    <AdminPanelLayout>
      <ViewGroupDetail />
    </AdminPanelLayout>
  );
};

export default ViewGroup;
