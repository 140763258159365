import React, { useEffect } from "react";
import "./interviewdone.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import space from "../../../../assets/images/space.svg";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getInterviewRoles } from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationApi";

const InterviewDoneComp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location:any = useLocation();
  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });

  useEffect(() => {
    dispatch(getInterviewRoles());
  }, []);

  return (
    <section className="interviewDone">
      <div className="done">
        <h1>Interview preparation</h1>
        <div className="done__line" />
        <div className="img">
          <img src={space} alt="donpic" />
        </div>
        <div className="congrats">
          <p>
            {" "}
            Thank You for your time and patience! Your response has been
            submitted.{" "}
          </p>
        </div>
        <div className="whatnext">
          <Link to="/interview-preparation-instruction" className="btn">
            <button type="submit">Done</button>
          </Link>
          <p>
            What next? We will review your response and respond as soon as
            possible. In the meantime, you can try another interview.
          </p>
        </div>

        <div className="buttons">
          {interviewState.req_success.role &&
            interviewState.roles.map((role: any) => {
              return (
                <button
                  key={role.id}
                  type="button"
                  className="interview__category__button"
                  style={{
                    display: location.state.role === role.name ? "none" : "",
                  }}
                  onClick={() =>
                    navigate("/interview-preparation", {
                      state: { roleId: role.id, role: role.name },
                    })
                  }
                >
                  {role.name}
                </button>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default InterviewDoneComp;
