import React from "react";
import emptyFolder from "../../../assets/icons/emptyFolder.svg";

function TestimonialEmpty(props: any) {
  const { showModal, setShowModal, setEditModal } = props;
  return (
    <div className="t-empty">
      <img src={emptyFolder} alt="empty folder" />
      <div>
        It seems empty.{" "}
        <button
          type="button"
          onClick={() => {
            setShowModal(true);
            setEditModal(false);
          }}
        >
          Add testimonials
        </button>{" "}
        now.
      </div>
    </div>
  );
}

export default TestimonialEmpty;
