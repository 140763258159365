import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AddPrivacyPolicy, getPrivacyPolicy } from "./PrivacyApi";

const initialState: any = {
  loading: false,
  req_success: false,
  privacy: {},
};
// ADD PRIVACY;
const PrivacySlice = createSlice({
  name: "PrivacySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ADD PRIVACY OF USE
    builder.addCase(AddPrivacyPolicy.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      AddPrivacyPolicy.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.privacy = payload.data;
      },
    );
    builder.addCase(AddPrivacyPolicy.rejected, (state) => {
      state.loading = false;
    });

    // GET privacy OF USE
    builder.addCase(getPrivacyPolicy.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getPrivacyPolicy.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.privacy = payload.data;
      },
    );
    builder.addCase(getPrivacyPolicy.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default PrivacySlice.reducer;
