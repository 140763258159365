/* eslint-disable global-require */
import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

import logo from "../../assets/fonts/images/logo1.png";
import sign from "../../assets/fonts/images/sign.png";
import sign1 from "../../assets/fonts/images/sign1.png";
import wrapper from "../../assets/fonts/images/wrapper.png";
import corner from "../../assets/fonts/images/topLeft.png";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: require("../../assets/fonts/pdf/Inter-Medium.ttf"),
      fontStyle: "normal",
    },
    {
      src: require("../../assets/fonts/pdf/Inter-Italic.otf"),
      fontStyle: "italic",
    },
    {
      src: require("../../assets/fonts/pdf/Inter-SemiBold.ttf"),
      fontWeight: "bold",
    },
    {
      src: require("../../assets/fonts/pdf/Inter-Bold.ttf"),
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "TimesNewRoman",
  fonts: [
    {
      src: require("../../assets/fonts/pdf/Tinos-Regular.ttf"),
      fontStyle: "normal",
    },
  ],
});

const CertificateTemplate = (props: any) => {
  const { certificate } = props;
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.body}>
        <View style={styles.container}>
          <View style={styles.certificate}>
            <Text style={styles.certificateTitle}>
              CERTIFICATE OF ACHIEVEMENT
            </Text>
            <View style={styles.imageWrapper}>
              <Image src={wrapper} style={styles.wrapperImage} />
              <Text style={styles.imageText}>
                THIS CERTIFICATE IS PROUDLY PRESENTED TO
              </Text>
            </View>
            <View style={styles.card}>
              <Text style={styles.cardTitle}>{certificate.user_name}</Text>
              <View style={styles.cardDivider} />

              <Text style={styles.cardText} wrap>
                We give this certificate to {certificate.user_name} for
                successfully completing the course
                <Text style={styles.cardSubText}>
                  {" "}
                  “{certificate.course_name}”{" "}
                </Text>
                on {certificate.completed_at}
              </Text>
            </View>
            <View style={styles.footer}>
              <View style={styles.footerCard}>
                <Image src={sign} style={styles.signatureImage} />
                <View style={styles.footerDivider} />
                <Text style={styles.signature}>Co-Founder & Instructor</Text>
              </View>
              <Image src={logo} style={styles.footerLogo} />
              <View style={styles.footerCard}>
                <Image src={sign1} style={styles.signatureImage} />
                <View style={styles.footerDivider} />
                <Text style={styles.signature}>Co-Founder & Instructor</Text>
              </View>
            </View>
          </View>
          <Image src={corner} style={styles.topLeft} />
          <Image src={corner} style={styles.topRight} />
          <Image src={corner} style={styles.buttomLeft} />
          <Image src={corner} style={styles.buttomRight} />
        </View>
      </Page>
    </Document>
  );
};

export default CertificateTemplate;

const styles = StyleSheet.create({
  // most of the units are converted to pt
  body: {
    width: "100vw",
    height: "100vh",
    padding: 23,
    display: "flex",
  },
  container: {
    display: "flex",
    flex: "1",
    border: "5px solid #cfcfcf",
    padding: "40px 60px",
    position: "relative",
  },
  certificate: {
    fontFamily: "Inter",
    margin: "auto",
    display: "flex",
    flex: "1",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  certificateTitle: {
    fontWeight: 700,
    fontSize: 33.75,
    textAlign: "center",
    color: "#232323",
  },
  imageWrapper: {
    width: 560,
    height: 38.25,
    // marginTop: 22.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
  },
  wrapperImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: "-1",
  },
  imageText: {
    fontWeight: 600,
    fontSize: 18.75,
    textAlign: "center",
    color: "#ffffff",
  },
  card: {
    // marginTop: 30,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: 15,
  },
  cardTitle: {
    fontFamily: "TimesNewRoman",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 45,
    // lineHeight: 51.75,
    color: "#1c1c1c",
  },
  cardDivider: {
    width: 490.5,
    border: "1px solid #e3e3e3",
  },
  cardText: {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 18.75,
    color: "#1c1c1c",
  },

  cardSubText: {
    fontStyle: "italic",
    fontWeight: 500,
  },

  footer: {
    // marginTop: 75,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 100,
    alignItems: "flex-end",
  },
  footerCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "4px",
  },

  signatureImage: {
    width: "134px",
    height: "50px",
  },
  signature: {
    fontWeight: 600,
    fontSize: 12,
    color: "#485463",
  },

  footerLogo: {
    width: "120px",
    height: "53px",
    marginBottom: 30,
  },
  footerDivider: {
    width: "100%",
    backgroundColor: "#5c5c5c",
    height: "1px",
  },

  // side images
  topLeft: {
    width: "40px",
    height: "40px",
    position: "absolute",
    top: 0,
    left: 0,
  },
  topRight: {
    width: "40px",
    height: "40px",
    position: "absolute",
    transform: "rotate(90deg)",
    top: 0,
    right: 0,
  },
  buttomLeft: {
    width: "40px",
    height: "40px",
    position: "absolute",
    left: 0,
    transform: "rotate(-90deg)",
    bottom: 0,
  },
  buttomRight: {
    width: "40px",
    height: "40px",
    position: "absolute",
    right: 0,
    transform: "rotate(180deg)",
    bottom: 0,
  },
});
