import React, { useState } from "react";
import { themeData } from "./helper";
import { RootStore, useAppSelector } from "../../../store/store";

const SelectTheme = (props: any) => {
  const { handlePreview, themeName, setThemename, activeThemeName } = props;

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const handleSelectTheme = (theme: String) => {
    setThemename(theme);
  };

  return (
    <div className="sm__theme__wrapper__section">
      <div className="sm__theme__container">
        <span>Select your site theme</span>

        <div className="sm__theme__card__container">
          <div className="sm__theme__cards">
            {themeData.map((item, index) => (
              <div
                key={item.id}
                className={`sm__theme__card ${
                  themeName === item.theme_name && "sm__theme__active__card"
                }`}
                onClick={() => handleSelectTheme(item.theme_name)}
                role="button"
                aria-hidden
              >
                <div className="sm__theme__card__heading">
                  <p>{item.theme_name}</p>
                  {activeThemeName === item.theme_name && (
                    <span>Active Theme</span>
                  )}
                </div>
                <p className="sm__theme__text">Light and Dark mode</p>
                <div className="sm__theme__image">
                  <img
                    src={
                      themeState.themeMode === "dark"
                        ? item.image.dark
                        : item.image.light
                    }
                    alt="MistyGrey"
                  />
                </div>
                <button
                  type="button"
                  className="sm__theme__preview__btn"
                  onClick={(e) => handlePreview(e, item.id)}
                >
                  Preview{" "}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTheme;
