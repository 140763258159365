import React from "react"
import waveImg from "../../../../../assets/images/waveImage.svg";
import "./index.scss";
import RecordingAnimation from "./RecordingAnimation";

const AudioRecording = (props:any) => {
  return (
    <div className='audio__recording__wrapper'>
        <div className="audio__recording__container">
          <RecordingAnimation/>
        </div>
    </div>
  )
}

export default AudioRecording
