import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

export const getLps = createAsyncThunk(
  "getLps",
  async (page: string, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/get-lps`);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllUserCourses = createAsyncThunk(
  "getAllUserCourses",
  async (page: string, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state); // ;
      const res = await axiosInstance.get(`${dynamic_url}/all-user-courses`);
      return res.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
