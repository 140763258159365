import { useEffect, useState } from "react";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../shared/Table/Table";
import PaginateButton from "../../../../shared/PaginateButton/PaginateButton";
import "./index.scss";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import {
  deletePayment,
  getPayments,
} from "../../../../store/features/AdminPanel/Payment/PaymentApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  editPaymentInfo,
  paymentTablePaginate,
  setShowModal,
} from "../../../../store/features/AdminPanel/Payment/PaymentSlice";
import Empty from "../../../../shared/Empty/Empty";

const PaymentTable = (props: any) => {
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [paymentId, setPaymentId] = useState(0);
  const dispatch = useAppDispatch();

  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.AdminPaymentSlice;
  });

  useEffect(() => {
    dispatch(getPayments());
  }, [paymentState.isChanged]);

  const handlePagination = (num: number) => {
    dispatch(paymentTablePaginate(num));
  };

  const editModalHandler = (pay: any) => {
    dispatch(editPaymentInfo(pay));
    dispatch(setShowModal());
  };
  const deleteModalHandler = (id: number) => {
    setPaymentId(id);
    setDeleteShowModal(true);
  };

  const deletePlan = () => {
    dispatch(deletePayment({ id: paymentId }));
    setDeleteShowModal(false);
  };

  return (
    <div className="tenant__table__wrapper ">
      <div className="user__table__section globl__margin">
        <Table
          heading={
            <TableHeading>
              <th>Title </th>
              <th>Min seat</th>
              <th>Max seat</th>
              <th>Price</th>
              <th>Duration Type</th>
              <th className="hide__text ">.</th>
            </TableHeading>
          }
        >
          <TableBody>
            {!paymentState.loading.get_all &&
              paymentState.req_success.get_all &&
              paymentState.payments.map((pay: any) => {
                return (
                  <tr>
                    <td className="plan_td">{pay.title}</td>
                    <td className="plan_td">{pay.min_seat} </td>
                    <td className="plan_td">{pay.max_seat}</td>
                    <td className="plan_td">{pay.price}</td>
                    <td className="plan_td">{pay.duration_type} </td>
                    <TableAction
                      onEdit={() => editModalHandler(pay)}
                      onDelete={() => deleteModalHandler(pay.id)}
                    />
                    {/* <td className="hide__text" /> */}
                  </tr>
                );
              })}
          </TableBody>
        </Table>
        {!paymentState.loading.get_all  &&
            paymentState.payments.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
         )}
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setDeleteShowModal}
          loading={paymentState.loading.delete}
          onClick={deletePlan}
        />
      </div>
      <div className="pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={paymentState.all_payments.length}
        />
      </div>
    </div>
  );
};

export default PaymentTable;
