import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import Assist from "../../../components/NewAcademicUser/AiAssist/Assist/Assist";
import TacAiAssistNav from "../../../components/UserDashboard/TacAiAssistNav/TacAiAssistNav";
import { RootStore, useAppSelector } from "../../../store/store";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";

const AiAssist = () => {
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <>
      {configState.configs.membership_enabled === Domain.TAC && (
        <>
          <TacAiAssistNav />
          <div className="mt-7">
            <Assist />
          </div>
        </>
      )}
      {configState.configs.membership_enabled === Domain.TAA && (
        <>
          <Nav normal navBgColorHandle />
          <Assist />
        </>
      )}
    </>
  );
};

export default AiAssist;
