/* eslint-disable no-else-return */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";

import "./resetPasswordForm.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import PasswordField from "../../../../shared/Inputs/PasswordField/PasswordField";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  resetPassword,
  validateToken,
} from "../../../../store/features/Auth/AuthApi";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const ResetPasswordFrom = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const location = useLocation();
  const email = new URLSearchParams(location?.search).get("email");
  const navigate = useNavigate();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  //  .matches(
  //       /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //       "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
  //     )
  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(REQUIRED_VALIDATION("New Password"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_{|}~`])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_{|}~`]{8,}$/,
        "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 special case Character"
      ),
    password_confirmation: yup
      .string()
      .required(REQUIRED_VALIDATION("Confirm Password"))
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      token: token,
      email: email,
    };
    dispatch(resetPassword(data));
  };
  useLayoutEffect(() => {
    const data = {
      email: email,
      token: token,
    };
    dispatch(validateToken({ data: data, fun: setInvalidToken }));
  }, []);
  useEffect(() => {
    if (authState.reset_password_success) {
      navigate("/");
    }
  }, [authState?.reset_password_success]);

  // const location = useLocation();
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const isLoginPage = location.pathname.includes("/") && configState.configs.membership_enabled === Domain.TAA ;


  return (
    <form className="resetform__container" onSubmit={handleSubmit(onSubmit)}>
      {invalidToken && <div>invalid token</div>}
      {!authState.token_loading && authState.token_success && (
        <>
          <span className="choose__password">Choose a new password</span>
          <div className="form_row">
            <input type="text" style={{ display: "none" }} />
            <PasswordField
              label="New password"
              name="password"
              register={register}
              helperText="Must be at least 8 characters and contain at least 1 number and 1 uppercase letter and  1 lowercase letter and 1 special character."
              error={errors?.password}
              handleInputStyle={isLoginPage}
            />
          </div>

          <div className="form_row">
            <PasswordField
              label="Confirm new password"
              name="password_confirmation"
              register={register}
              error={errors?.password_confirmation}
              handleInputStyle={isLoginPage}
            />
          </div>

          <div className="form_row mt-3">
            <PrimaryButton
              text="Submit"
              type="submit"
              loading={authState?.reset_password_loading}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default ResetPasswordFrom;
