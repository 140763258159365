/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getLearningPathcourses,
  getCoursesFromBundle,
  getCourseInstructors,
  getCourseInstructor,
} from "./LearningPathApi";

const initialState: any = {
  loading: false,
  bundle_course_loading: false,
  bundle_instructors_loading: false,
  bundle_instructors_success: false,
  bundle_course_success: false,
  req_success: false,
  bundle_courses: [],
  bundle_instructors: [],
  courses: [],
};

const LearningPathSlice = createSlice({
  name: "learningPath",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL COURSES
    builder.addCase(getLearningPathcourses.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getLearningPathcourses.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.courses = payload?.data;
      },
    );
    builder.addCase(getLearningPathcourses.rejected, (state) => {
      state.loading = false;
    });

    // GET COURSES FROM BUNDLE
    builder.addCase(getCoursesFromBundle.pending, (state) => {
      state.bundle_course_loading = true;
      state.bundle_course_success = false;
      state.bundle_instructors_success = false;
    });
    builder.addCase(
      getCoursesFromBundle.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.bundle_course_loading = false;
        state.bundle_courses = payload?.data;
        state.bundle_instructors = payload?.instructors;
        state.bundle_course_success = true;
      },
    );
    builder.addCase(getCoursesFromBundle.rejected, (state) => {
      state.bundle_course_loading = false;
    });
  },
});

// export const { setRecentSignupEmail, invokeLogout } = LearningPathSlice.actions;

export default LearningPathSlice.reducer;
