import React, { useEffect, useState } from "react";
import { BarChart, Book, Clock, Minus, Plus } from "react-feather";
import { useParams } from "react-router-dom";
import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import "./courseProgress.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { getUserCourseDetail } from "../../../store/features/UserDashboard/courseDetail/courseDetailApi";
import { YellowButton } from "../../../shared/Buttons/Buttons";
import {
  enrollToThinkificCourse,
  loginToThinkificAndRedirect,
} from "../../../store/features/Thinkific/ThikificApi";
import userImage from "../../../assets/icons/user.png";

function CourseProgress(props: any) {
  const [openIndex, setOpenIndex] = useState(-1);
  const [btnText, setBtnText] = useState("");
  const [slug, setSlug] = useState("");
  const dispatch = useAppDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const courseState: any = useAppSelector((store: RootStore) => {
    return store.CourseDetailSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const thinkificState: any = useAppSelector((store: RootStore) => {
    return store.ThinkificSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const { course, instructor, chapters } = courseState.data;
  // console.log(courseState);


  const startLearning = (courseId: number, slug: any) => {
    setSlug(slug);
    setLoading(true);
    dispatch(enrollToThinkificCourse({ courseId: courseId })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };

  useEffect(() => {
    dispatch(getUserCourseDetail({ courseId: Number(params.id) }));
  }, []);

  useEffect(() => {
    if (courseState.req_success) {
      const percentage = courseState.data.course.percentage_completed;
      if (percentage < 100 && percentage > 0) {
        setBtnText("Resume path");
      }
      if (percentage === 0) setBtnText("Start Course");
      if (percentage === 100) setBtnText(" Course Completed");
    }
  }, [courseState.loading]);
  console.log(courseState)
  return (
    <div className="course__progress__wrapper">
      <Nav normal navBgColorHandle />

      {!courseState.loading && courseState.req_success && (
        <>
          <div className="course-progress-top">
            <div className="cpt-section-wrapper">
              <div className="cpt-left">
                <h1>{course.name}</h1>
                <p>{course.description}</p>
                <div className="button__width">
                  {" "}
                  <YellowButton
                    normal
                    text={btnText}
                    onClick={() => {
                      // redirectToThinkfic(course.slug)
                      startLearning(course.course_id, course.slug);
                    }}
                    animationLoading={thinkificState.loading}
                  />
                </div>
              </div>
               <div className="cpt-right cpt__right">
                <h5>Course Overview</h5>
                <div className="cpt-info-wrapper">
                  <div className="cpt-info-box">
                      <div className="cpt-box-left">
                      <BarChart size={20}/>
                      <span className="cpt-title">Path progress</span>
                      </div>
                      <div className="cpt-box-right">
                        <span className="percentage">{course.percentage_completed}%</span>
                      </div>
                    </div>
                    <div className="cpt-info-box">
                    <div className="cpt-box-left">
                    <Clock size={20}/>
                    <span className="cpt-title">Duration</span>
                    </div>
                    <div className="cpt-box-right">
                      <span className="hours">{course.duration}</span>
                    </div>
                  </div>
                  <div className="cpt-info-box">
                    <div className="cpt-box-left">
                    <Book size={20}/>
                    <span className="cpt-title">Number of courses</span>
                    </div>
                    <div className="cpt-box-right">
                      <span className="total-courses">{course.total_lessons} courses</span>
                    </div>
                  </div>
                </div>
                
              </div> 
            </div>
            <div className="cpt-line" />
            <div className="cpt-line-bottom">

              <div className="cpt-line-b">
                <div className="cpt-txt">Instructors</div>
                <div className="cpt-icon-txt-container-div">
                 {
                 instructor?.length > 0 ? instructor?.map((item:any)=>(
                    <div className="cpt-icon-txt cpt-text-avatar-wrapper" key={item}>
                    <img
                      src={item.avatar_url?.startsWith("https",0)
                      ? item?.avatar_url
                      : userImage}
                      className="cpt-icon"
                      alt="instructor"
                    />
                    
                    <div className="cpt-text-icon">
                      {item.first_name} {item.last_name}
                    </div>
                  </div>
                  )):(<div className="no-inst">There are no instructors for this course.</div>)
                 }
                </div>
              </div>
            </div>
          </div>
          <div className="course-progress-bottom">
            <div className="cpb">
              <h2>Lessons in this course</h2>
              <div className="cpb-list">
                {chapters.map((chapter: any) => {
                  return (
                    <div className="cpb-container">
                      <button
                        type="button"
                        className="cpb-head"
                        onClick={() => {
                          if (openIndex === chapter.id) {
                            setOpenIndex(-1);
                          } else setOpenIndex(chapter.id);
                        }}
                      >
                        <div className="cpb-title">{chapter.name}</div>
                        <div className="cpb-icon">
                          {openIndex === chapter.id ? <Minus /> : <Plus />}
                        </div>
                      </button>
                      <div
                        className={
                          openIndex === chapter.id
                            ? "cpb-body d-block"
                            : "cpb-body"
                        }
                      >
                        <div className="cpb-line" />

                        <ul className="ull">
                          {chapter.contents.map((content: string) => {
                            return <li>{content}</li>;
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
}

export default CourseProgress;
