import { toast } from "react-toastify";

/* eslint-disable @typescript-eslint/naming-convention */
export const REQUIRED__TOASTER = (data: any) => {
  if (data.success) {
    toast.success(data.message);
  } else {
    toast.error(data.message);
  }
};

export const ERROR__TOASTER = (error: any) => {
  toast.error(error.response.data.message);
};
