import React, { useEffect, useState } from "react";
import Nav from "../../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import InterviewInstructionComp from "../../../../components/NewAcademicUser/InterviewPreparation/InterviewInstruction/InterviewInstructionComp";
import TourPopupModal from "../../../../components/NewAcademicUser/InterviewPreparation/Tour/TourPopupModal";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../store/store";
import { closeShowPopup, defaultProgress, goTostepOne, setShowInnerPopupFalse, setShowPopup } from "../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";
import { loadUser } from "../../../../store/features/Auth/AuthApi";

function InterviewInstruction(props: any) {
  const dispatch = useAppDispatch();

  const showPopupState: any = useAppSelector((store: RootStore) => store.ShowPopupSlice);
  const onBoardingstate:any = useAppSelector((store:RootStore)=>store.ShowPopupSlice);
  const { currentStep} = useAppSelector((store: RootStore) => store.ShowPopupSlice);

// Check if the user is came for the first time interview preparation
const isFirstTime = onBoardingstate?.webGuideDataInfo?.interview?.is_completed;

// console.log(isFirstTime,onBoardingstate);

function isFirstTimeUser() {
  if (isFirstTime === 0) {
    dispatch(setShowPopup());
    return true;
  }
  return false;
}
useEffect(() => {
  dispatch(goTostepOne());
  dispatch(defaultProgress(currentStep));
  if (!isFirstTimeUser()){
    dispatch(closeShowPopup());
    dispatch(setShowInnerPopupFalse());
  }
},[]);

  return (
    <>
      <Nav normal navBgColorHandle />
      <InterviewInstructionComp />
      {showPopupState.showPopup && <TourPopupModal />}
    </>
  );
}

export default InterviewInstruction;
