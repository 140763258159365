import TenantLoadingScreen from "../../components/Auth/TenantLoading/TenantLoadingScreen";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";

const TenantLoading = () => {
  return (
    <LandingLayout>
      <TenantLoadingScreen message="Your account is being created" />
    </LandingLayout>
  );
};

export default TenantLoading;
