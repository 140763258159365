import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import ListInterviewsAndWebinars from "../../../components/UserDashboard/InterviewsAndWebinars/ListInterviewsAndWebinars";

function InterviewsAndWebinars(props: any) {
  return (
    <AdminPanelLayout>
      <ListInterviewsAndWebinars />
    </AdminPanelLayout>
  );
}

export default InterviewsAndWebinars;
