import React from "react";
import VideoLinks from "../Dashboard/videolinks";

const Testimonials = () => {
  return (
    <div>
      <VideoLinks />
    </div>
  );
};

export default Testimonials;
