import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
import logo from "../../../assets/images/agileAcademyLogo.svg";

function NavBar(props: any) {
  return (
    <header className="landingheader">
      <div className="header-logo">
        <Link to="/">
          <img src={logo} alt="logo" />
        </Link>
      </div>
    </header>
  );
}

export default NavBar;
