/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import SelectDropDown from "../../../shared/SelectDropDown/SelectDropDown";
import { TestimonialFilterForTAAOnly, TestimonialFilterForTACOnly } from "./helper";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import ErrorText from "../../../shared/Inputs/ErrorText/ErrorText";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import TextField from "../../../shared/Inputs/TextField/TextField";
import TextArea from "../../../shared/Inputs/TextArea/TextArea";
import { getBase64, getFileExtension } from "../../../helpers/utils/FileHelper";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { searchTestimonials } from "../../../store/features/AdminPanel/Testimonial/TestimonialSlice";
import {
  CreateTestimonials,
  EditTestimonials,
} from "../../../store/features/AdminPanel/Testimonial/TestimonialApi";
import CheckBox from "../../../shared/Inputs/CheckBox/CheckBox";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";

const ProductPageTestimonialHeader = (props: any) => {
  const dispatch = useAppDispatch();
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const testimonialsState: any = useAppSelector((store: RootStore) => {
    return store.TestimonialsSlice;
  });
  const {
    showModal,
    setShowModal,
    setSelectedValue,
    selectedValue,
    editModal,
    setEditModal,
    currId,
  } = props;
  const [searchText, setSearchText] = useState("");
  const hiddenFileInput = useRef<any>(null);
  const [imageExt, setImageExt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [atTop, setAtTop] = useState(false);
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    dispatch(searchTestimonials(searchText));
  }, [searchText]);

  useEffect(() => {
    if (testimonialsState.req_success) {
      clearAll();
      setShowModal(false);
      setEditModal(false);
    }
  }, [testimonialsState.req_success]);

  useEffect(() => {
    if (editModal) {
      // console.log(testimonialsState.data, "data");
      const data = testimonialsState.data.filter((e: any) => {
        return e.id === currId;
      });
      setValue("name", data[0]?.name);
      setValue("designation", data[0]?.designation);
      setValue("video_url", data[0]?.video_url);
      setImageUrl(data[0].image);
      setValue("description", data[0].description);
      if (data[0].is_top) {
        setAtTop(true);
      } else {
        setAtTop(false);
      }
    }
  }, [editModal, currId, showModal]);

  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Name")),
    // designation: yup.string().required(REQUIRED_VALIDATION("Designation")),
    // description: yup.string().max(300, "Description must not exceed 300 characters"),
    // video_url: yup.string().required(REQUIRED_VALIDATION("Video url")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const onError = () => {};

  const onSubmit = (values: any) => {
    let data;
    if (errors?.description || errors?.video_url) {
      return;
    }
    if (editModal) {
      if (imageFile) {
        data = {
          ...values,
          name: values.name,
          description: values.description,
          designation: values.designation,
          rating: 0,
          is_top: atTop,
          video_url: values?.video_url,
          image: imageFile,
          extension: imageExt,
          type: selectedValue.value,
        };
      } else {
        data = {
          ...values,
          name: values.name,
          description: values.description,
          designation: values.designation,
          rating: 0,
          is_top: atTop,
          video_url: values?.video_url,
          type: selectedValue.value,
        };
      }
      // console.log(data);
      dispatch(EditTestimonials({ id: currId, data: data }));
    } else {
      // if (!imageFile) {
      //   setImageError("Image is required");
      //   data = {};
      //   return;
      // }
      data = {
        ...values,
        name: values.name,
        description: values.description,
        designation: values.designation,
        rating: 0,
        is_top: atTop,
        video_url: values?.video_url,
        image: imageFile,
        extension: imageExt,
        type: selectedValue.value,
      };
      // console.log(data);
      dispatch(CreateTestimonials(data));
    }
  };

  const handleOption = (data: any) => {
    setSelectedValue(data);
  };
  const handleClick = (event: any) => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];
    if (contentFile.size > 2 * 1024 * 1024) {
      setImageError("Image size must be less than 2 MB");
      return;
    }
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageError("");
  };

  const clearAll = () => {
    reset();
    setImageError("");
    setImageExt("");
    setImageFile("");
    setImageUrl("");
    setAtTop(false);
  };
  const onClick = () => {
    setShowModal(false);
  };

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Product Page Testimonial For</TableHeadingTitle>
        <div className="dropdown__holder">
          <SelectDropDown
            data={ configState.configs?.membership_enabled === Domain.TAC
              ?TestimonialFilterForTACOnly
              : TestimonialFilterForTAAOnly}
            selectedValue={selectedValue?.name}
            handleOption={handleOption}
          />
        </div>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={() => {
              setShowModal(true);
              setEditModal(false);
              clearAll();
            }}
          />
        </div>
      </div>
      <Modal show={showModal} setShow={setShowModal} onClick={onClick}>
        <ModalHeader
          title={
            editModal
              ? "Edit New Product Page Testimonial"
              : "Add New Product Page Testimonial"
          }
        />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form_row">
              <TextField
                type="text"
                label="Name"
                name="name"
                register={register}
                error={errors?.name}
              />
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="Designation"
                name="designation"
                register={register}
                error={errors?.designation}
              />
            </div>
            <div className="form_row">
              <TextField
                className="text-field"
                type="text"
                label="Video URL"
                name="video_url"
                register={register}
              />
            </div>

            <div className="form_row">
              <TextArea
                type="text"
                label="Description"
                name="description"
                register={register}
                error={errors?.description}
              />
            </div>
            {/* {
              (errors?.description || errors?.video_url) && (     <ErrorText error="Please give atleast one field Video Url or Description" />)

            } */}

            <div className="t__user__avatar">
              <span className="team_type_title">Avatar </span>
              <div className="upload_avatar_sec">
                {imageUrl && (
                  <img src={imageUrl} className="author_img" alt="thumbnail" />
                )}
                <div className="image__funcanality">
                  <span
                    className="upload_image"
                    aria-hidden
                    onClick={handleClick}
                  >
                    Upload new avatar
                  </span>
                </div>
              </div>
              <ErrorText error={imageError} />

              <input
                type="file"
                id="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={(e: any) => getFiles(e)}
                style={{ display: "none" }}
              />
            </div>
            {selectedValue?.value === "icp" && (
              <>
                <div
                  className="select-top-testimonial"
                  style={{ marginBottom: "3rem" }}
                >
                  <CheckBox
                    label="Set as top testimonial"
                    value={atTop}
                    onClick={() => {
                      setAtTop(!atTop);
                    }}
                  />
                  <div className="stt-text">
                    (Only one item can be set as top testimonial)
                  </div>
                </div>
                <div className="top_testiominal__error">
                  {testimonialsState.hasTop && (
                    <ErrorText error="Top testimonial has already been set. Check if you want to replace." />
                  )}
                </div>
              </>
            )}

            <div className="button__witdh mt-1">
              <PrimaryButton
                text={editModal ? "Update" : "Add"}
                type="submit"
                loading={testimonialsState.loading}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProductPageTestimonialHeader;
