/* eslint-disable jsx-a11y/control-has-associated-label */
import "./userProfileHeader.scss";

import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import {
  PrimaryButton,
  TACPrimaryButton,
} from "../../../shared/Buttons/Buttons";
import PasswordField from "../../../shared/Inputs/PasswordField/PasswordField";
import {
  changePassword,
  logoutUser,
} from "../../../store/features/Auth/AuthApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { ProfilePicture } from "./ProfilePicture";
import UserDetailForm from "./UserDetailForm";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../helpers/utils/Constants/roles";
import GoogleLoginWarning from "./gooleLoginWarning/GoogleLoginWarning";
import DeleteModal from "../../../shared/DeleteModal/DeleteModal";
import { disConnectWithGoHighlevel, getGoHighlevelInfo } from "../../../store/features/AdminPanel/ConnectWithGoHighlevel/ConnectWithGoHighlevelApi";


const UserProfileHeader = () => {
  const [showInfo, setShowInfo] = useState(true);
  const [showDisconnectModal,setShowDisConnectModal] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const connected = searchParams.get("connected");
  const dispatch = useAppDispatch();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const connectWithGoHighlevelState: any = useAppSelector((store: RootStore) => {
    return store.ConnectWithGoHighlevelSlice;
  });

  // Validate is TAA user or not 
  const isTAAUser = authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA;

  // validate is Trial user or not 
 const isTrialUser = 
 (authState.tac_user?.role === roles.BUISNESS_ANALYST_STUDENT ||
   authState.tac_user?.role === roles.SCRUM_MASTER_STUDENT ||
   authState.tac_user?.role === roles.PRODUCT_OWNER_STUDENT) &&
 authState?.tac_user?.default_password_updated &&
 configState.configs.membership_enabled === Domain.TAA

 const isSysAdmin = authState.tac_user?.role === roles.SYSTEM_ADMIN && configState.configs.membership_enabled === Domain.TAC

  const validationSchema = yup.object().shape({
    old_password: yup
      .string()
      .required(REQUIRED_VALIDATION("Current Password")),
    new_password: yup
      .string()
      .required(REQUIRED_VALIDATION("New Password"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_{|}~`])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_{|}~`]{8,}$/,
        "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 special case Character"
      ),
    new_password_confirmation: yup
      .string()
      .required(REQUIRED_VALIDATION("Confirm Password"))
      .oneOf([yup.ref("new_password"), null], "Passwords must match"),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const handlePassword = (values: any) => {
    dispatch(changePassword(values));
  };

  useEffect(() => {
    if (authState.pass_req_success) {
      reset();
      dispatch(logoutUser());
    }
  }, [authState.pass_req_success]);

  useEffect(()=>{
    dispatch(getGoHighlevelInfo());
  },[])


  useEffect(()=>{
    if(!connectWithGoHighlevelState?.disconnect){
      setShowDisConnectModal(false);
    }
    if(connectWithGoHighlevelState?.disSuccess){
      dispatch(getGoHighlevelInfo());
    }
  },[connectWithGoHighlevelState])

  useEffect(() => {
    if (connected && connectWithGoHighlevelState?.data?.connected ) {
      toast.success("Connected with gohighlevel")
    }
  }, [connected,connectWithGoHighlevelState?.data?.connected]);

  const openDisconnectModal = ()=>{
    setShowDisConnectModal(true);
  }
  const handleDisconnect = ()=>{
    dispatch(disConnectWithGoHighlevel());
  }

  return (
    <div className="profile-header-container mt-8">
      <div className="profile__row">
        <ProfilePicture />

        {showInfo && authState.tac_user?.is_social ? (
          <GoogleLoginWarning
            themeMode={configState.themeMode}
            setShowInfo={setShowInfo}
          />
        ) : (
          ""
        )}
      </div>

      <div className="forms-section-user">
        <UserDetailForm configState={configState} />
        <form
          className="profile_card__container"
          onSubmit={handleSubmit(handlePassword)}
        >
          <div className={`card_wrapper mt-0 ${ isTAAUser || isTrialUser ? "card__wrapper__div":""}`}>
            <span className="profile__topography">Password</span>
            <div className="input_text_section">
              <div className="form_row">
                <PasswordField
                  label="Current Password"
                  name="old_password"
                  register={register}
                  error={errors?.old_password}
                  handleInputStyle={isTAAUser || isTrialUser}
                />
              </div>

              <div className="form_row">
                <PasswordField
                  label="New Password"
                  name="new_password"
                  helperText="Must be at least 8 characters and contain at least 1 number and 1 uppercase letter and  1 lowercase letter and 1 special character."
                  register={register}
                  error={errors?.new_password}
                  handleInputStyle={isTAAUser || isTrialUser}
                />
              </div>
              <div className="form_row">
                <PasswordField
                  label="Confirm New Password"
                  name="new_password_confirmation"
                  register={register}
                  error={errors?.new_password_confirmation}
                  handleInputStyle={isTAAUser || isTrialUser}
                />
              </div>
            </div>

            <div className="button__witdh mt-1">
              {configState.configs.membership_enabled === Domain.TAC &&
              authState.tac_user?.role === roles.USER ? (
                <TACPrimaryButton
                  text="Update"
                  type="submit"
                  loading={authState.loading}
                />
              ) : (
                <PrimaryButton
                  text="Update"
                  type="submit"
                  loading={authState.loading}
                />
              )}
            </div>
          </div>
        </form>
      </div>

{
  isSysAdmin &&  (   <div className="connect-gohighlevelbox">
  <p className="connect-text">Connect with GoHighLevel</p>
  <p className="connect-desc">Connect with GoHighLevel to streamline your marketing, sales, and customer relationship management processes effortlessly. GoHighLevel to unlock the full potential of your marketing and sales efforts, and take your business to new heights</p>
  <div className="connect-btn-wrapper">
  {
  !connectWithGoHighlevelState?.data?.connected  ? ( <a href={`${connectWithGoHighlevelState?.data?.redirect_url}`} className="btn-width-handle ">
    <TACPrimaryButton
        text="Connect"
        type="button"
    />
  </a>) : (
     <div className="btn-width-handle disconnect-btn-width ">
          <TACPrimaryButton
              text="Disconnect"
              type="button"
              onClick = {openDisconnectModal}
          />
    </div>
     )
  } 
   
  </div>
</div>)
}

    <DeleteModal showModal={showDisconnectModal} setShowModal={setShowDisConnectModal} onClick = {handleDisconnect} message="You want to disconnect" yesBtn 
    loading = {connectWithGoHighlevelState?.disconnect}
    />
    </div>
  );
};

export default UserProfileHeader;
