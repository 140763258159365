/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { UserX } from "react-feather";
import * as yup from "yup";
import Table, { TableBody, TableHeading } from "../../../../shared/Table/Table";
import "./index.scss";
import userimage from "../../../../assets/icons/user.png";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  Deletemember,
  editMember,
  getAllMembers,
  resendInvitation,
} from "../../../../store/features/Tenant/Orgination/Member/MemberApi";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import SelectField from "../../../../shared/Inputs/SelectField/SelectField";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import Empty from "../../../../shared/Empty/Empty";

const MemberTable = (props: any) => {
  const { memberState } = props;
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const dispatch = useAppDispatch();
  const [reInviteId, setReInviteId] = useState(0);
  const [member, setMember] = useState<any>({});
  const navigate = useNavigate();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice.tac_user;
  });
  const options = [
    { value: "admin", label: "Admin" },
    { value: "student", label: "Student" },
  ];
  const validationSchema = yup.object().shape({
    role: yup.object().required(REQUIRED_VALIDATION("Member role")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);
  const onSubmit = (value: any) => {
    const data = {
      role: value.role.value,
    };
    setReloadPage(true);
    dispatch(editMember({ data: data, memberId: member.id }));
  };

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    dispatch(getAllMembers());
  }, []);

  useEffect(() => {
    if (memberState.req_success.delete) {
      setShowMemberModal(false);
      setDeleteShowModal(false);
      dispatch(getAllMembers());
    }
  }, [memberState.req_success.delete]);

  useEffect(() => {
    if (memberState.req_success.edit && reloadPage) {
      setShowMemberModal(false);
      setReloadPage(false);
      dispatch(getAllMembers());
    }
  }, [memberState.req_success.edit]);
  const handleNavigationWithRole = (member: any) => {
    if (member.role === "student") {
      navigate(`/members-details/${member.id}`);
    }
  };
  const handleAdminModal = (member: any) => {
    setMember(member);
    setShowMemberModal(true);
    setValue("role", {
      value: member.role,
      label: member.role.charAt(0).toUpperCase() + member.role.slice(1),
    });
  };
  return (
    <div className="member__table__section">
      <Table
        heading={
          <TableHeading>
            <th>Name</th>
            <th>Role</th>
            <th>Course Progress</th>
            <th>Groups</th>
            <th>Last Active</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {!memberState.loading.get &&
            memberState.req_success.get &&
            memberState.members.map((member: any) => {
              return (
                <tr className="member_table_tr" key={member.id}>
                  <td
                    className={`team__member__info ${
                      member.role === "student" && "not_admin"
                    }`}
                  >
                    <div
                      className="name_and_avatar_section"
                      onClick={() => handleNavigationWithRole(member)}
                    >
                      <img
                        src={member.avatar_url ?? userimage}
                        className="dash_team_avatar"
                        alt="name"
                      />
                      <div className="name__section">
                        <span className="name"> {member.name}</span>
                        <span className="user_name">{member.email}</span>
                      </div>
                    </div>
                  </td>
                  <td className="admin">{member.role}</td>
                  <td className="course__progress_td">
                    {member.role === "admin" ? (
                      "_"
                    ) : (
                      <div className="progress__section">
                        <span className="total__assigned__course">
                          Assigned courses: {member.assigned_courses}
                        </span>
                        <ProgressBar
                          completed={member.progress}
                          height="3px"
                          customLabel="."
                          baseBgColor="var( --primary-progress-bar-bg)"
                          bgColor="var( --secondary-progress-bar-bg)"
                          labelSize="0"
                          width="150px"
                        />
                        <span className="completed__text__precent">
                          {Math.floor(member.progress)}% completed
                        </span>
                      </div>
                    )}
                  </td>
                  <td className="groups__td">
                    {member.role === "admin" ? (
                      "_"
                    ) : (
                      <div className="group__list__name">
                        {member.min_group.map((group: any) => {
                          return (
                            <div className="group__name" key={group}>
                              <span className="dot">.</span>{" "}
                              <span>{group}</span>
                            </div>
                          );
                        })}

                        {member.max_group.length > 0 && (
                          <div className="view__more__groups__section">
                            <span className="view__more__text">
                              View all groups{" "}
                            </span>

                            <div className="group__list__card">
                              {member.max_group.map((group: any) => {
                                return (
                                  <div className="group__name" key={group}>
                                    <span className="dot">.</span>{" "}
                                    <span>{group}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                  <td className="date">{member.last_active ?? "-"}</td>
                  <td className="add_assignment">
                    {member.role === "admin" ? (
                      <span
                        onClick={() => {
                          handleAdminModal(member);
                        }}
                      >
                        Edit member{" "}
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          navigate(`/members-add-assignment/${member.id}`)
                        }
                      >
                        {" "}
                        Add assignment
                      </span>
                    )}

                    {member.last_active === null && (
                      <div className="resend__button_div">
                        {" "}
                        <PrimaryButton
                          text="Resend invitation"
                          type="button"
                          size="tiny"
                          loading={
                            reInviteId === member.id &&
                            memberState.loading.reinvite
                          }
                          onClick={() => {
                            setReInviteId(member.id);
                            dispatch(resendInvitation({ userId: member.id }));
                          }}
                        />{" "}
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
        </TableBody>
      </Table>

      <Modal
        show={showMemberModal}
        setShow={setShowMemberModal}
        // resetForm={reset}
        // onClick={handleModal}
      >
        <ModalHeader title="Edit member" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="mt-2" />

            <Controller
              control={control}
              name="role"
              render={({ field }) => (
                <SelectField
                  type="text"
                  label="Member role"
                  options={options}
                  register={register}
                  error={errors?.role}
                  {...field}
                />
              )}
            />
            <div className="header__member__modal_footer">
              {authState?.role === "owner" ? (
                <div
                  className="remove__access__text"
                  onClick={() => {
                    setDeleteShowModal(true);
                  }}
                >
                  {" "}
                  <UserX className="user_x_red" /> Remove access?
                </div>
              ) : (
                <div />
              )}
              <div>
                <PrimaryButton
                  text="Save"
                  type="submit"
                  loading={memberState.loading.edit}
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      {!memberState.loading.get &&
        memberState.req_success.get &&
        memberState.members.length === 0 && <Empty custombg emptyMember />}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={memberState.loading.delete}
        onClick={() => dispatch(Deletemember({ memberId: member.id }))}
      />
    </div>
  );
};

export default MemberTable;
