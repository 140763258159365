import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import { ChevronLeft } from "react-feather";
import "./index.scss";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import MemberList from "./MemberList";
import CourseDetail from "./CourseDetail";
import DeadlineDetail from "./DeadlineDetail";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getSingleGroupInfo } from "../../../../store/features/Tenant/Orgination/Group/GroupApi";
import TenantLoadingScreen from "../../../Auth/TenantLoading/TenantLoadingScreen";

const ViewGroupDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });
  useEffect(() => {
    dispatch(getSingleGroupInfo({ groupId: Number(params.id) }));
  }, []);

  return (
    <>
        {!groupState.loading.single_get && groupState.req_success.single_get && (
    <div className="view__group__wrapper">
          <div className="header__wrapper">
            <div className="title__section">
              <ChevronLeft
                className="back__icon"
                onClick={() => navigate("/groups")}
              />
              <span className="title">
                {" "}
                {groupState.single_group_info.group.name}
              </span>
            </div>
            <div className="button__section">
              <PrimaryButton
                type="button"
                text="Edit Group"
                onClick={() => navigate(`/edit-groups/${params.id}`)}
              />
            </div>
          </div>
          <div className="content__section">
            <div className="left__section">
              <MemberList members={groupState.single_group_info.users} />
            </div>
            <div className="right__section">
              <CourseDetail
                learningPaths={groupState.single_group_info.learning_paths}
                allCourses={groupState.single_group_info.all_courses}
              />
              <DeadlineDetail courses={groupState.single_group_info.deadline} />
            </div>
          </div>
    </div>
      )}
    {
      groupState.loading.single_get && !groupState.req_success.single_get &&(<TenantLoadingScreen message=""/>)
    }
    </>
  );
};

export default ViewGroupDetail;
