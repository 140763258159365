import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axiosInstance from "../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../apiConfigs/urlConfig";
import { renderDynamicUrlFromState } from "../../../helpers/utils/urlHelper";

export const enrollToThinkificCourse = createAsyncThunk(
  "enrollToThinkificCourse",
  async (
    { courseId }: { courseId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/enroll-to-course/${courseId}`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const enrollToThinkificCourse2 = createAsyncThunk(
  "enrollToThinkificCourse2",
  async (
    { courseId }: { courseId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/enroll-to-course/${courseId}`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const enrollToThinkificCourse3 = createAsyncThunk(
  "enrollToThinkificCourse3",
  async (
    { courseId }: { courseId: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/enroll-to-course/${courseId}`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginToThinkificAndRedirect = createAsyncThunk(
  "loginToThinkificAndRedirect",
  async (data: any, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/thinkific-login`,
        data
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
