/* eslint-disable no-restricted-syntax */
const mapHoursAndMinutes = (timeString: string) => {
  let hours = 0;

  const match = timeString.match(/^(\d+)h\s*(\d+)m$|^(\d+)h$|^(\d+)m$/);

  if (match) {
    if (match[1]) {
      hours += parseInt(match[1], 10);
      hours += parseInt(match[2], 10) / 60;
    } else if (match[3]) {
      hours += parseInt(match[3], 10);
    } else if (match[4]) {
      hours += parseInt(match[4], 10) / 60;
    }
  }

  // Return the output
  return hours;
};

const includesElement = (array1: any[], array2: any[]) => {
  for (const element of array1) {
    if (array2.includes(element)) {
      return true;
    }
  }
  return false;
};

export { mapHoursAndMinutes, includesElement };
