import React, { useState } from "react";

import "./recentFeedbackList.scss";

import { PrimaryButton } from "../../../../../../../shared/Buttons/Buttons";
import RecentSingleFeedBack from "./RecentSingleFeedBack";
import RecentFeedback from "../RecentFeedBack/RecentFeedback";

const RecentFeedBackList = (props: any) => {
  const { data } = props;

  const [showForm, setShowForm] = useState<boolean>(false);

  return showForm ? (
    <RecentFeedback id={data?.id} setShow={setShowForm} />
  ) : (
    <div className="right__content">
      <div className="scroll__bar custom__scrollbar">
        {data?.feedbacks?.length > 0 ? (
          data?.feedbacks?.map((item: any) => {
            return (
              <RecentSingleFeedBack
                key={item?.id}
                data={item}
                setShow={setShowForm}
              />
            );
          })
        ) : (
          <div className="feedback__empty__wrapper">
            <span> No feedback found.</span>
          </div>
        )}
      </div>
      <div className="fd_button_width">
        {" "}
        <PrimaryButton
          text="Add Feedback"
          type="button"
          onClick={() => setShowForm(true)}
        />
      </div>
    </div>
  );
};

export default RecentFeedBackList;
