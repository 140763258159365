import { createSlice } from "@reduxjs/toolkit";
import {
  downloadApplyNow,
  getApplyNowDetails,
  getApplyNowList,
} from "./ApplyNowApi";

export const ApplyNowSlice = createSlice({
  name: "ApplyNow",
  initialState: {
    applyNowListAll: [],
    applyNowList: [],
    singleApplyNow: null,
    loading: false,
    error: false,
    download: {
      loading: false,
      success: false,
    },
  },
  reducers: {
    setApplyNowList: (state, action) => {
      state.applyNowList = state.applyNowListAll.filter((item: any) => {
        return item.role === action.payload;
      });
    },
    searchApplyNowList: (state, action) => {
      if (action.payload.search !== "") {
        state.applyNowList = state.applyNowList.filter((item: any) => {
          return (
            item.full_name
              .toLowerCase()
              .includes(action.payload.search.toLowerCase()) ||
            item.email
              .toLowerCase()
              .includes(action.payload.search.toLowerCase())
          );
        });
      } else {
        state.applyNowList = state.applyNowListAll.filter((item: any) => {
          return item.role === action.payload.role;
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getApplyNowList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getApplyNowList.fulfilled, (state, action) => {
      state.loading = false;
      state.applyNowListAll = action.payload.data;
    });
    builder.addCase(getApplyNowList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getApplyNowDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getApplyNowDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.singleApplyNow = action.payload.data;
    });
    builder.addCase(getApplyNowDetails.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(downloadApplyNow.pending, (state) => {
      state.download.loading = true;
      state.download.success = false;
    });
    builder.addCase(downloadApplyNow.fulfilled, (state) => {
      state.download.loading = false;
      state.download.success = true;
    });
    builder.addCase(downloadApplyNow.rejected, (state) => {
      state.download.loading = false;
      state.download.success = false;
    });
  },
});

export default ApplyNowSlice.reducer;
export const { setApplyNowList, searchApplyNowList } = ApplyNowSlice.actions;
