import React from "react";
import "./index.scss";
import SingeBox from "./SingeBox";
import mavenIcon from "../../../assets/images/maven.svg";

const DoubleLoginSetup = () => {
  return (
    <div className="double__login__setup__section">
      <span className="heading"> Select an account type</span>
      <span className="email"> You’re signed in as john@agileacademy.com</span>
      <div className="double__box__container">
        <SingeBox
          title="agileacademy"
          domain="theagileacademy.com"
          url={mavenIcon}
        />

        <SingeBox
          title="Individual Account"
          domain="app.agileacademy.com"
          url={mavenIcon}
          individual
        />
      </div>
    </div>
  );
};

export default DoubleLoginSetup;
