import darkmistyGreyImg from "../../../assets/themeImages/Mystygrey.svg";
import darkIndigoImg from "../../../assets/themeImages/Indigo.svg";
import darkAzureImg from "../../../assets/themeImages/Azure.svg";
import lightMistyGreyImg from "../../../assets/themeImages/light-mistygrey-theme.svg";
import lightIndigoImg from "../../../assets/themeImages/light-indigo-theme.svg";
import lightAzureImg from "../../../assets/themeImages/light-azure-theme.svg";

// Slider images
import darkMisteygreyMyProgressImg from "../../../assets/themeImages/MisteyGreyTheme/myprogress-misteygrey-dark.png";
import lightMisteyGreyMyProgressImg from "../../../assets/themeImages/MisteyGreyTheme/myprogress-misteygrey-light.png";
import darkLearningPathMisteygreyImg from "../../../assets/themeImages/MisteyGreyTheme/learning-path-misteygrey-dark.png";
import lightLearningpathMisteygreyImg from "../../../assets/themeImages/MisteyGreyTheme/liearning-path-misteygrey-light.png";
import darkCourseMisteygreyImg from "../../../assets/themeImages/MisteyGreyTheme/course-misteygrey-dark.png";
import lightCourseMistygreyImg from "../../../assets/themeImages/MisteyGreyTheme/course-misteygrey-light.png";

import darkIndigoMyProgressImg from "../../../assets/themeImages/IndigoTheme/myprogress-indoigo-dark.png";
import lightIndigoMyProgressImg from "../../../assets/themeImages/IndigoTheme/myprogress-indigo-light.png";
import darkIndigoLearningPathImg from "../../../assets/themeImages/IndigoTheme/learning-path-ndigo-dark.png";
import lightIndigolearningPathImg from "../../../assets/themeImages/IndigoTheme/learning-path-indigo-light.png";
import darkIndigoCourseImg from "../../../assets/themeImages/IndigoTheme/course-indigo-dark.png";
import lightIndigoCourseImg from "../../../assets/themeImages/IndigoTheme/course-indigi-light.png";

import darkAzureMyprogressImg from "../../../assets/themeImages/AzureTheme/azure-my-progress-dark.png";
import lightAzureMyProgressImg from "../../../assets/themeImages/AzureTheme/azure-my-progress-light.png";
import darkAzurelearningPathImg from "../../../assets/themeImages/AzureTheme/azure-learning-path-dark.png";
import lightAzurelearningpathImg from "../../../assets/themeImages/AzureTheme/azure-learning-path-light.png";
import darkAzureCorseImg from "../../../assets/themeImages/AzureTheme/azure-course-dark.png";
import lightAzureCourseImg from "../../../assets/themeImages/AzureTheme/azure-course-light.png";

export const themeData = [
  {
    id: 1,
    theme_name: "MistyGrey",
    image: {
      dark: darkmistyGreyImg,
      light: lightMistyGreyImg,
    },
    images: [
      {
        id: 1,
        dark: darkMisteygreyMyProgressImg,
        light: lightMisteyGreyMyProgressImg,
      },
      {
        id: 2,
        dark: darkLearningPathMisteygreyImg,
        light: lightLearningpathMisteygreyImg,
      },
      {
        id: 3,
        dark: darkCourseMisteygreyImg,
        light: lightCourseMistygreyImg,
      },
    ],
  },
  {
    id: 2,
    theme_name: "Indigo",
    image: {
      dark: darkIndigoImg,
      light: lightIndigoImg,
    },
    images: [
      {
        id: 1,
        dark: darkIndigoMyProgressImg,
        light: lightIndigoMyProgressImg,
      },
      {
        id: 2,
        dark: darkIndigoLearningPathImg,
        light: lightIndigolearningPathImg,
      },
      {
        id: 3,
        dark: darkIndigoCourseImg,
        light: lightIndigoCourseImg,
      },
    ],
  },
  {
    id: 3,
    theme_name: "Azure",
    image: {
      dark: darkAzureImg,
      light: lightAzureImg,
    },
    images: [
      {
        id: 1,
        dark: darkAzureMyprogressImg,
        light: lightAzureMyProgressImg,
      },
      {
        id: 2,
        dark: darkAzurelearningPathImg,
        light: lightAzurelearningpathImg,
      },
      {
        id: 3,
        dark: darkAzureCorseImg,
        light: lightAzureCourseImg,
      },
    ],
  },
];
