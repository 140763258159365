/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import JoditEditor from "jodit-react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "../../../shared/Inputs/TextField/TextField";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { addOrUpdatePlans } from "../../../store/features/AdminPanel/TutionPlans/TutionPlanApi";
import ErrorText from "../../../shared/Inputs/ErrorText/ErrorText";

const TutionsPlan = (props: any) => {
  const {
    data,
    setDeleteShowModal,
    setDeletePlanId,
    setIndex,
    i,
    plans,
    setPlans,
  } = props;
  const dispatch = useAppDispatch();
  const [editId, setEditId] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const tutionState: any = useAppSelector((store: RootStore) => {
    return store.TutionPlansSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const config: any = {
    readOnly: false,
    placeholder: "write something here.....",
    height: 300,
    toolbarSticky: false,
    buttons: "bold,italic,underline,strikethrough,fontsize,link",
    theme: themeState.themeMode === "dark" ? "dark" : "default",
  };
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const validationSchema = yup.object().shape({
    title: yup.string().required(REQUIRED_VALIDATION("Title")),
    sub_title: yup.string().required(REQUIRED_VALIDATION("Sub title")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,

    formState: { errors },
  } = useForm<any>(formOptions);
  const removeTags = (str: any) => {
    if (str === null || str === "") {
      return false;
    } else {
      return str.replace(/(<([^>]+)>)/gi, "");
    }
  };
  const onSubmit = (values: any) => {
    const char = removeTags(content);
    if (char.length < 1) {
      setErrorMessage("Description is required");
      return;
    } else {
      setErrorMessage("");
    }
    let data: any = {};
    if (editId) {
      data = { ...values, description: content, id: editId };
    } else {
      data = { ...values, description: content };
    }
    dispatch(addOrUpdatePlans({ data: data, idForUpdate: Number(editId) }));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      setValue("sub_title", data.sub_title);
      setContent(data.description);
      setEditId(data.id);
    }
  }, [tutionState.req_success.add, tutionState.req_success.get]);
  const handleDelete = () => {
    setDeleteShowModal(true);
    setDeletePlanId(editId);
  };
  const hanldeLoading = () => {
    setUpdateId(data.id);
    setIndex(i);
  };
  const hanldeAddLoading = () => {
    setLoading(true);
  };
  const deleteEmptyPlan = () => {
    const newArr = plans.filter((e: any, index: any) => {
      return i !== index;
    });
    setPlans(newArr);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="plans__card">
        <div className="plans_row">
          <div className="plan_input_width">
            <TextField
              type="text"
              label="Title"
              name="title"
              register={register}
              error={errors?.title}
            />
          </div>
          <div className="plan_input_width">
            <TextField
              type="text"
              label="Sub title"
              name="sub_title"
              register={register}
              error={errors?.sub_title}
            />
          </div>
        </div>

        <span className="description__title"> Description</span>
        <div className="editor__wrapper">
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={(newContent) => setContent(newContent)}
          />
          <ErrorText error={errorMessage} />
        </div>
        <div className="buttom__section">
          {editId && (
            <span className="remove__text" onClick={handleDelete}>
              Remove
            </span>
          )}
          {!editId && (
            <span className="remove__text" onClick={deleteEmptyPlan}>
              Remove
            </span>
          )}
          <div className="">
            {editId && (
              <PrimaryButton
                onClick={hanldeLoading}
                type="submit"
                text="Update"
                sm
                loading={tutionState.loading.add && editId === updateId}
              />
            )}
            {!editId && (
              <PrimaryButton
                onClick={hanldeAddLoading}
                type="submit"
                text="Submit"
                sm
                loading={tutionState.loading.add && loading}
              />
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default TutionsPlan;
