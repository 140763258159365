import React from "react";

const SingeBox = (props: any) => {
  const { title, domain, url, individual } = props;
  return (
    <div className="single__box">
      <div className="img__container">
        <img src={url} alt="" className="image" />
      </div>
      <span className="titile"> {title}</span>
      <span className="domain"> {domain}</span>
    </div>
  );
};

export default SingeBox;
