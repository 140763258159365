import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  googleUserSignIn,
  populateUserFromGoogleSignIn,
  setThemeName,
} from "../../../../store/features/Auth/AuthSlice";
import { loadUser } from "../../../../store/features/Auth/AuthApi";
import { renderDynamicUrlFromComponentLevelState } from "../../../../helpers/utils/urlHelper";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { THEME_MODE, THEME_NAME } from "../../../../helpers/utils/Constants/themeName";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const GoogleLogin = () => {
  const dispatch = useAppDispatch();
  const { token, email, role } = useParams();
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") || "dark"
  );

  const navigate = useNavigate();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const dynamic_url = renderDynamicUrlFromComponentLevelState(themeState);

  useEffect(() => {
    dispatch(googleUserSignIn({ user: { email, role }, access_token: token }));
    axiosInstance
      .get(`${dynamic_url}/google-login-user/${email}`)
      .then((res) => {
        console.log(res);
        dispatch(
          populateUserFromGoogleSignIn({
            user: res.data.user,
            access_token: token,
          })
        );
        if (configState.configs.membership_enabled === Domain.TAA) {
          navigate("/academic-user-dashboard");
          return;
        }
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      });

    // navigate("/progress");
    return () => {};
  }, []);

  useLayoutEffect(() => {
    localStorage.setItem("officialThemeName", configState?.theme_name);
  }, []);

  useLayoutEffect(() => {
    const themName: any = localStorage.getItem("officialThemeName");
    dispatch(setThemeName(themName));
  }, []);

  useEffect(() => {
    // for default
    if (authState.theme_name === THEME_NAME.default && themeMode === "light") {
      localStorage.setItem("themeName", THEME_MODE.light);
    }

    if (authState.theme_name === THEME_NAME.default && themeMode === "dark") {
      localStorage.setItem("themeName", THEME_MODE.dark);
    }
    // for indigo
    if (authState.theme_name === THEME_NAME.Indigo && themeMode === "light") {
      localStorage.setItem("themeName", THEME_MODE.indigo_light);
    }
    if (authState.theme_name === THEME_NAME.Indigo && themeMode === "dark") {
      localStorage.setItem("themeName", THEME_MODE.indigo_dark);
    }

    // for mistGrey
    if (
      authState.theme_name === THEME_NAME.MistyGrey &&
      themeMode === "light"
    ) {
      localStorage.setItem("themeName", THEME_MODE.mistyGrey_light);
    }
    if (authState.theme_name === THEME_NAME.MistyGrey && themeMode === "dark") {
      localStorage.setItem("themeName", THEME_MODE.mistyGrey_dark);
    }

    // for azure

    if (authState.theme_name === THEME_NAME.Azure && themeMode === "light") {
      localStorage.setItem("themeName", THEME_MODE.azure_light);
    }
    if (authState.theme_name === THEME_NAME.Azure && themeMode === "dark") {
      localStorage.setItem("themeName", THEME_MODE.azure_dark);
    }
  }, []);

  return <div>Signing in...</div>;
};

export default GoogleLogin;
