import React, { useEffect, useState } from "react";
import { Card25 } from "../../../../shared/Card/Card25/Card25";
import "./index.scss";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import {
  getActiveSubscription,
  getCardInfo,
} from "../../../../store/features/UserDashboard/Payment/PaymentApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  clearStripeFalse,
  editUserPayment,
} from "../../../../store/features/UserDashboard/Payment/PaymentSlice";
import CancelSubscriptionModal from "../../../UserDashboard/UserProfile/Subscriptions/CancelSubscription/CancelSubscriptionModal";
import { deActivatePaidPlanForUser } from "../../../../store/features/Auth/AuthSlice";
import { loadUser } from "../../../../store/features/Auth/AuthApi";
import { TENANTPLANSTATUS } from "../../../../helpers/utils/Constants/tenantPlanStatus";

const SubscriptionsCard = (props: any) => {
  const { setShowModal } = props;
  const [showCancelModel, setShowCancelModel] = useState(false);
  const [authFail, setAuthFail] = useState(false);

  const dispatch = useAppDispatch();

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });

  useEffect(() => {
    dispatch(getActiveSubscription());
  }, []);
  useEffect(() => {
    if (paymentState.req_success.upgrade_account) {
      dispatch(loadUser({ setAuthFail }));

      dispatch(getActiveSubscription());
    }
  }, [paymentState.req_success.upgrade_account]);

  const openBillingModal = () => {
    dispatch(getCardInfo());
    setShowModal(true);
    dispatch(editUserPayment(paymentState.active_subscription[0]));
    dispatch(clearStripeFalse());
  };

  const openBillingModalforfistPay = () => {
    dispatch(getCardInfo());
    setShowModal(true);
    dispatch(clearStripeFalse());
    const data = { plan_name: "Monthly Subscription" };
    dispatch(editUserPayment(data));
  };

  useEffect(() => {
    if (paymentState.req_success.cancel_subscription) {
      setShowCancelModel(false);
      dispatch(loadUser({ setAuthFail }));
      dispatch(deActivatePaidPlanForUser());
    }
  }, [paymentState.req_success.cancel_subscription]);

  return (
    <div className="subscriptions__card__wrapper__padding">
      <Card25 title="Subscriptions">
        {authState.tenant_pay_details?.status === TENANTPLANSTATUS.TRIAL && (
          <div className="subscriptions__card__wrapper">
            Your account is currently enrolled in a{" "}
            {authState.tenant_pay_details?.days_left}-day free trial, which is
            scheduled to automatically renew in a few days. If you wish to
            discontinue the service, you can cancel your subscription before the
            renewal date.
          </div>
        )}
        {authState.tenant_pay_details?.status === TENANTPLANSTATUS.PAID && (
          <div className="subscriptions__plans__details">
            <span className="plan">
              {paymentState.active_subscription[0]?.plan_name}{" "}
            </span>
            <span className="expiration__details">
              <span className="pricing">
                {" "}
                {paymentState.active_subscription[0]?.pricing}{" "}
              </span>{" "}
              <ul>
                <li>
                  {" "}
                  Renewal Date:{" "}
                  {paymentState.active_subscription[0]?.renew_date}
                </li>
              </ul>
            </span>
            <p className="desc">
              {" "}
              You can upgrade your account to change company size according to
              your needs. If you wish to discontinue the service, simply select
              the 'Cancel Subscription' option.
            </p>
          </div>
        )}

        {authState.tenant_pay_details?.status === TENANTPLANSTATUS.CANCELED && (
          <div className="subscriptions__plans__details">
            <p className="desc">
              Your cancellation request has been received. Your subscription
              will remain active for the next{" "}
              {authState.tenant_pay_details?.days_left} days before it is
              discontinued. You will still have access to all features during
              this time.
            </p>
          </div>
        )}
        <div className="owner__profile__subscription__button">
          <div className="cancel__width">
            {" "}
            {(authState.tenant_pay_details?.status === TENANTPLANSTATUS.PAID ||
              authState.tenant_pay_details?.status ===
                TENANTPLANSTATUS.TRIAL) && (
              <button
                type="button"
                className="cancel__subscription"
                onClick={() => setShowCancelModel(true)}
              >
                Cancel subscription{" "}
              </button>
            )}
          </div>
          <div className="upgrade__width">
            {authState.tenant_pay_details?.status === TENANTPLANSTATUS.PAID && (
              <PrimaryButton
                text="Upgrade My Account"
                type="button"
                onClick={() => openBillingModal()}
              />
            )}

            {(authState.tenant_pay_details?.status === TENANTPLANSTATUS.TRIAL ||
              authState.tenant_pay_details?.status ===
                TENANTPLANSTATUS.CANCELED) && (
              <PrimaryButton
                text="Upgrade My Account"
                type="button"
                onClick={() => openBillingModalforfistPay()}
              />
            )}
          </div>
        </div>
      </Card25>

      {showCancelModel && (
        <CancelSubscriptionModal
          showModal={showCancelModel}
          setShowModal={setShowCancelModel}
        />
      )}
    </div>
  );
};

export default SubscriptionsCard;
