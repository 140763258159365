import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getSprintLearningPath,
  getCoursesForLearningPath,
  ceateOrUpdateSprint,
  getSprintList,
  deleteSprint,
} from "./SprintApi";
import { filterBydate } from "../../UserDashboard/Archives/ArchiveSlice";

const initialState: any = {
  loading: {
    get_learning_path: false,
    list_sprint: false,
    add: false,
    courses: false,
    delete: false,
    get: false,
  },
  req_success: {
    get_learning_path: false,
    list_sprint: false,
    add: false,
    delete: false,
    courses: false,
    get: false,
  },

  learning_paths: [],
  courses: [],
  sprints: [],
  all_sprints: [],
  temp_sprints: [],
  temp_update_sprints: [],
};

const SprintSlice = createSlice({
  name: "SprintSlice",
  initialState,
  reducers: {
    changeTempOrder: (state: any, { payload }: PayloadAction<any>) => {
      state.temp_update_sprints = state.all_sprints;
      const a = state.all_sprints[payload.sourdeIndex];

      state.all_sprints.splice(payload.sourdeIndex, 1);

      state.all_sprints.splice(payload.destinationIndex, 0, a);

      state.sprints = state.all_sprints;
    },
    cleanScreen: (state: any) => {
      state.all_teams = [];
      state.teams = [];
    },
  },
  extraReducers: (builder) => {
    // GET  LEARNING PATH FOR SPRINT
    builder.addCase(getSprintLearningPath.pending, (state) => {
      state.loading.get_learning_path = true;
      state.req_success.get_learning_path = false;
    });
    builder.addCase(
      getSprintLearningPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_learning_path = false;
        state.req_success.get_learning_path = true;
        state.learning_paths = payload.data;
      },
    );
    builder.addCase(getSprintLearningPath.rejected, (state) => {
      state.loading.get_learning_path = false;
    });

    // get courses for learnig path id
    builder.addCase(getCoursesForLearningPath.pending, (state) => {
      state.loading.courses = true;
      state.req_success.courses = false;
    });
    builder.addCase(
      getCoursesForLearningPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.courses = false;
        state.req_success.courses = true;
        state.courses = payload.data;
      },
    );
    builder.addCase(getCoursesForLearningPath.rejected, (state) => {
      state.loading.courses = false;
    });

    // create or update sprint
    builder.addCase(ceateOrUpdateSprint.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      ceateOrUpdateSprint.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.req_success.add = true;
        state.loading.add = false;
        if (payload?.idForUpdate > 0) {
          state.all_sprints = state.all_sprints.map((sprint: any) => {
            if (sprint.id === payload?.data.id) {
              return payload?.data;
            }
            return sprint;
          });
          state.sprints = state.all_sprints;
        }

        if (!payload.idForUpdate) {
          state.sprints = [...state.sprints, payload.data];
        }
      },
    );
    builder.addCase(ceateOrUpdateSprint.rejected, (state) => {
      state.loading.add = false;
    });

    //  get sprints lists
    builder.addCase(getSprintList.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getSprintList.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.sprints = payload.data;
        state.all_sprints = payload.data;
      },
    );
    builder.addCase(getSprintList.rejected, (state) => {
      state.loading.get = false;
    });

    //  delete sprints lists
    builder.addCase(deleteSprint.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteSprint.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_sprints = state.all_sprints.filter(
          (sprint: any) => sprint.id !== payload?.id,
        );
        state.sprints = state.all_sprints;
      },
    );
    builder.addCase(deleteSprint.rejected, (state) => {
      state.loading.delete = false;
    });
  },
});
export const {
  changeTempOrder,
} = SprintSlice.actions;
export default SprintSlice.reducer;
