import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./applyNowTable.scss";
import Table, { TableBody, TableHeading } from "../../../../shared/Table/Table";
import { useAppSelector } from "../../../../store/store";
import Empty from "../../../../shared/Empty/Empty";

const ApplyNowTable = () => {
  const applyNowState: any = useAppSelector((state) => state.ApplyNowSlice);

  return (
    <div className="apply__table__section__div">
      <Table
        heading={
          <TableHeading>
            <th>Full Name</th>
            <th>Email Address</th>
            <th>Phone Number</th>
            <th>LinkedIn Url</th>
            <th>Submitted At</th>
            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {!applyNowState.loading &&
            applyNowState.applyNowList &&
            applyNowState.applyNowList.map((item: any) => (
              <tr key={item}>
                <td>{item.full_name}</td>
                <td>{item.email}</td>
                <td>{item.mobile_number}</td>
                <td>
                  <a
                    href={item.linkedin_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.linkedin_url}
                  </a>
                </td>
                <td>{item.created_at}</td>
                <td className="view__details__td">
                  <Link to={`/apply-now-details/${item.id}`}>
                    <span>View Details</span>
                  </Link>
                </td>
              </tr>
            ))}
        </TableBody>
      </Table>

      {!applyNowState.loading && applyNowState.applyNowList.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
      )}
    </div>
  );
};

export default ApplyNowTable;
