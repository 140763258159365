import React, { useEffect, useState } from "react";

import "./userheader.scss";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../../shared/SearchBar/SearchBar";
import { useAppDispatch } from "../../../../../store/store";
import { filterUser } from "../../../../../store/features/AdminPanel/Webinar/WebinarSlice";

const UserHeader = () => {
  const [searchText, setSearchText] = useState("");
  const [date, setDate] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const data = {
      date: date,
      search: searchText,
    };
    dispatch(filterUser(data));
  }, [date, searchText]);

  return (
    <div>
      <div className="User_header_container">
        <div className="user_left_section">
          <TableHeadingTitle>Users</TableHeadingTitle>
        </div>
        <div className="user_right_section">
          <div className="user_date">
            <div className="user_left_date">
              <div className="filter_by">Filter by </div>
              <input
                type="date"
                className="date_input"
                onChange={(e) => setDate(e.target.value)}
              />
              {/* <TextField type="date"  name="" /> */}
            </div>
            <div className="webinar_user_searchbox">
              <SearchBar value={searchText} setValue={setSearchText} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserHeader;
