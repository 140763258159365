/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  GoogleLoginButton,
  PrimaryButton,
} from "../../../../shared/Buttons/Buttons";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import "./loginfrom.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { userSignIn } from "../../../../store/features/Auth/AuthApi";
import PasswordField from "../../../../shared/Inputs/PasswordField/PasswordField";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { renderDynamicUrlFromComponentLevelState } from "../../../../helpers/utils/urlHelper";
import { roles } from "../../../../helpers/utils/Constants/roles";
import { ROUTES } from "../../../../helpers/utils/Constants/route";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";

const LoginFrom = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  localStorage.removeItem("themeName");

  const email = new URLSearchParams(location?.search).get("email");
  const searchParams = new URLSearchParams(location.search);
  const status = searchParams.get("status");
  const paymentSuccess = searchParams.get("payment_success");
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .email("Invalid Email")
      .required(REQUIRED_VALIDATION("Email")),
    password: yup.string().trim().required(REQUIRED_VALIDATION("Password")),
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character",
    // ),
  });

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  useEffect(() => {
    if (
      authState?.req_success &&
      authState?.tac_user?.role === "sys_adm"
      // authState?.tac_user?.default_password_updated
    ) {
      return navigate("/dashboard");
    }
    if (
      authState?.req_success &&
      authState?.tac_user?.role === roles.SUB_ADMIN &&
      authState?.tac_user?.default_password_updated
    ) {
      return navigate("/dashboard");
    }

    if (
      authState?.req_success &&
      authState?.tac_user?.role === "mentor" &&
      authState?.tac_user?.default_password_updated
    ) {
      return navigate("/mentor/interview/recently-answered");
    }
    if (
      authState?.req_success &&
      authState?.tac_user?.role === "owner" &&
      authState?.tac_user?.default_password_updated
    ) {
      return navigate("/organization");
    }
    if (
      authState?.req_success &&
      authState?.tac_user?.role === "admin" &&
      authState?.tac_user?.default_password_updated
    ) {
      return navigate("/organization");
    }
    if (authState?.req_success && authState?.tac_user?.role === "student") {
      return navigate("/organization-user-dashboard");
    }
    if (
      authState?.req_success &&
      authState?.tac_user?.role === "user" &&
      authState?.tac_user?.default_password_updated &&
      configState.configs.membership_enabled === Domain.TAA
    ) {
      return navigate("/academic-user-dashboard");
    }

    if (
      authState?.req_success &&
      (authState.tac_user.role === roles.BUISNESS_ANALYST_STUDENT ||
        authState.tac_user.role === roles.SCRUM_MASTER_STUDENT ||
        authState.tac_user.role === roles.PRODUCT_OWNER_STUDENT) &&
      authState?.tac_user?.default_password_updated &&
      configState.configs.membership_enabled === Domain.TAA
    ) {
      return navigate(ROUTES.TRIAL_USER_COURSE);
    }

    if (
      authState?.req_success &&
      authState?.tac_user?.all_roles?.includes(roles.ICP_STUDENT) || authState?.tac_user?.all_roles?.includes(roles.APO_STUDENT) || authState?.tac_user?.all_roles?.includes(roles.PDM_STUDENT) || authState?.tac_user?.all_roles?.includes(roles.ICP_ACC_STUDENT) || authState?.tac_user?.all_roles?.includes(roles.ATF_STUDENT) &&
      authState?.tac_user?.default_password_updated &&
      configState.configs.membership_enabled === Domain.TAC
    ) {
      return navigate(ROUTES.ALL_PREMIUM_COURSES);
    }

    if (
      authState?.req_success &&
      authState?.tac_user?.role === "user" &&
      authState?.tac_user?.default_password_updated &&
      configState.configs.membership_enabled === Domain.TAC
    ) {
      return navigate("/progress");
    }

    if (
      authState?.req_success &&
      authState?.tac_user?.role === roles.PRODUCT_MANAGER_STUDENT &&
      authState?.tac_user?.default_password_updated &&
      configState.configs.membership_enabled === Domain.TAC
    ) {
      return navigate(ROUTES.PRODUCT_MANAGER_COURSES);
    }

    // default password change is only required for academic user i.e user
    if (
      authState?.req_success &&
      // authState?.tac_user?.role === "sys_adm" ||
      (authState?.tac_user?.role === "owner" ||
        authState?.tac_user?.role === "mentor" ||
        authState?.tac_user?.role === "admin" ||
        authState?.tac_user?.role === roles.SUB_ADMIN ||
        authState?.tac_user?.role === "student" ||
        authState?.tac_user?.role === roles.PRODUCT_MANAGER_STUDENT ||
        authState?.tac_user?.all_roles?.includes(roles.ICP_STUDENT) ||
        authState?.tac_user?.all_roles?.includes(roles.APO_STUDENT) ||
        authState?.tac_user?.all_roles?.includes(roles.PDM_STUDENT) ||
        authState?.tac_user?.all_roles?.includes(roles.ICP_ACC_STUDENT) ||
        authState?.tac_user?.all_roles?.includes(roles.ATF_STUDENT) ||
        authState?.tac_user?.role === "user") &&
      !authState?.tac_user?.default_password_updated
    ) {
      return navigate("/default-password-change");
    }
  }, [authState?.req_success]);

  useEffect(() => {
    if (status) {
      const error = {
        response: {
          data: {
            message: "Your account does not exist",
          },
        },
      };
      ERROR__TOASTER(error);
      navigate("/login");
    }
    if (paymentSuccess) {
      // REQUIRED__TOASTER({
      //   success: true,
      //   message:
      //     " Login successful. Please check your email for credentials. If you don't find it in your inbox, please check your spam folder.",
      // });
      toast.success(" Login successful. Please check your email for credentials. If you don't find it in your inbox, please check your spam folder.",{autoClose:5000})
    }
  }, [status, paymentSuccess]);

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    dispatch(userSignIn(values));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  const openForgetForm = () => {
    navigate("/forget-password");
  };
  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [email]);
  // if (!authState.verified_domain) navigate("/not-found");
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const dynamic_url = renderDynamicUrlFromComponentLevelState(themeState);

  const googleLogin = async () => {
    const res = await axiosInstance
      .get(`${dynamic_url}/google-login`)
      .then((res) => {
        console.log(res);
        window.location.href = res.data.data.url;
      });
  };

  const handleSignUpPage = () => {
    window.open(configState.configs?.api_url.replace("api", "sign-up"));
  };
  const isLoginPage =
    location.pathname.includes("/") &&
    configState.configs.membership_enabled === Domain.TAA;

  return (
    <form
      className="login__form__container"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <span className="welcome__text">Welcome back.</span>
      <span
        className={`welcome__description margin ${
          isLoginPage && "welcome__description__style"
        }`}
      >
        Login to continue your agile journey
      </span>
      <div className="form_row">
        <TextField
          type="text"
          label="Email"
          name="email"
          register={register}
          error={errors?.email}
          handleInputStyle={isLoginPage}
        />
      </div>
      <div className="form_row">
        <PasswordField
          label="Password"
          name="password"
          register={register}
          error={errors?.password}
          handleInputStyle={isLoginPage}
        />
      </div>

      <span className="forget__password" onClick={openForgetForm}>
        Forgot Password?
      </span>
      <div className="form_row mt-1">
        <PrimaryButton
          text="Login"
          type="submit"
          loading={authState?.loading}
        />

        {configState.configs.membership_enabled === Domain.TAA &&
          configState.configs.tenant_name === null && (
            <>
              {" "}
              <span className="or__text">or</span>
              <div className="google__login__btn__wrapper">
                <GoogleLoginButton
                  text="Login with Google"
                  style={{ marginTop: "20px" }}
                  onClick={googleLogin}
                />
              </div>
            </>
          )}
        {configState.configs.membership_enabled === Domain.TAC &&
          configState.configs.tenant_name === null && (
            <>
              {" "}
              <span className="or__text">or</span>
              <GoogleLoginButton
                text="Signin with Google"
                style={{ marginTop: "20px" }}
                onClick={googleLogin}
              />
            </>
          )}
      </div>

      {configState.configs.membership_enabled === Domain.TAC &&
        configState.configs.tenant_name === null && (
          <span className="signup__text">
            Don’t have an account?{" "}
            <span className="coloured__text" onClick={handleSignUpPage}>
              Sign up
            </span>
            .
          </span>
        )}
      {configState.configs.membership_enabled === Domain.TAA &&
        configState.configs.tenant_name === null && (
          <span className="signup__text">
            Don’t have an account?{" "}
            <span
              className="coloured__text"
              onClick={() =>
                window.open(
                  "https://agileacademy.io/path/business-analyst/signup"
                )
              }
            >
              Get a Sneak Peek.
            </span>
          </span>
        )}
    </form>
  );
};

export default LoginFrom;
