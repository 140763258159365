/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import { Check } from "react-feather";
import { useForm } from "react-hook-form";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { addOrUpdateBanner } from "../../../../store/features/AdminPanel/Banners/BannersApi";
import { popupBannerFormOptions } from "../helper";

const LandingPagePopup = () => {
  const [hideShow, setHideShow] = useState("hide");
  const [loading, setLoading] = useState(false);
  const [openInNewTab, setOpenInNewTab] = useState(false);
  const dispatch = useAppDispatch();
  const bannersState: any = useAppSelector(
    (store: RootStore) => store.BannersSlice
  );
  const { handleSubmit, register, setValue, formState } = useForm(popupBannerFormOptions);
  const {errors} = formState
  const onlyPopupbannerData = bannersState.banners?.filter(
    (item: any) => item.name === "popup_banner"
  );
  const submitForm = async (values: any) => {
    try {
      setLoading(true);
      const tempData = {
        ...values,
        open_new_tab: openInNewTab,
      };
      const data = {
        content: JSON.stringify(tempData),
        status: hideShow === "show" ? 1 : 0,
      };
      await dispatch(
        addOrUpdateBanner({
          data,
          id: onlyPopupbannerData[0]?.id
        })
      );
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (onlyPopupbannerData.length > 0 && onlyPopupbannerData[0]?.content) {
      try {
        const parsedContent = JSON.parse(
          JSON.parse(onlyPopupbannerData[0]?.content)
        );
        setValue("text_1", parsedContent?.text_1 || "");
        setValue("text_2", parsedContent?.text_2 || "");
        setValue("discount_text", parsedContent?.discount_text || "");
        setValue("btn_text", parsedContent?.btn_text || "");
        setValue("btn_link", parsedContent?.btn_link || "");
        setHideShow(onlyPopupbannerData[0]?.status ? "show" : "hide");
        setOpenInNewTab(parsedContent?.open_new_tab);
      } catch (error) {
        console.error("Error parsing banner content:", error);
      }
    }
  }, [onlyPopupbannerData[0], setValue]);

  return (
    <div className="banner__header__content ">
      <div className="header">
        <span className="lessons">Landing Page Popup Text</span>
      </div>
      <form
        className="banner__wrapper mt-1"
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="banner__input__text__field">
          <TextField
            label="Text 1"
            type="text"
            name="text_1"
            register={register}
            error={errors?.text_1}
          />
        </div>
        <div className="banner__input__text__field">
          <TextField
            label="Text 2"
            type="text"
            name="text_2"
            register={register}
            error={errors?.text_2}
          />
        </div>
        <div className="banner__input__text__field">
          <TextField
            label="Discount Text"
            type="text"
            name="discount_text"
            register={register}
            error={errors?.discount_text}
          />
        </div>
        <div className="grid__input">
          <TextField
            label="Button Name"
            type="text"
            name="btn_text"
            register={register}
            error={errors?.btn_text}
          />
          <TextField
            label="Button Link"
            type="text"
            name="btn_link"
            register={register}
            error={errors?.btn_link}
          />
        </div>
        <div className="button__section">
          <div className="checkboxes__modal">
            <div className="checkbox__sec">
              <span>Status</span>
              <div className="checks">
                <CheckBox
                  className="checkboxx"
                  label="Show"
                  labelClassname
                  value={hideShow === "show"}
                  onClick={() => setHideShow("show")}
                />
                <CheckBox
                  className="checkboxx"
                  label="Hide"
                  value={hideShow === "hide"}
                  onClick={() => setHideShow("hide")}
                  labelClassname
                />
                     <CheckBox
                  className="checkboxx"
                  label="Open in new tab"
                  value={openInNewTab}
                  onClick={() => setOpenInNewTab(!openInNewTab)}
                  labelClassname/>
              </div>
            </div>
          </div>
          <div className="addBtn">
            <PrimaryButton
              text={`${onlyPopupbannerData[0]?.content ? "Update" : "Add"}`}
              type="submit"
              loading={
                loading
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default LandingPagePopup;
