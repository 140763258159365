/* eslint-disable import/order */
/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState, useRef } from "react";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../store/store";
import { searchBlogInTable } from "../../../../../store/features/AdminPanel/Blog/BlogSlice";
import "./index.scss";

const BlogContentHeader = (props: any) => {
  const { setOpenBlogPage } = props;
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const openBlogPage = () => {
    setOpenBlogPage(true);
  };
  useEffect(() => {
    dispatch(searchBlogInTable(searchText));
  }, [searchText]);

  return (
    <div>
      <div className="blog_content__header_container">
        <div className="left__section">
          <TableHeadingTitle>Blog Listing</TableHeadingTitle>
        </div>
        <div className="right__section">
          <div className="search">
            <SearchBar value={searchText} setValue={setSearchText} />
          </div>
          <div className="button__witdh">
            <PrimaryButton
              text="Add"
              size="sm"
              type="button"
              onClick={openBlogPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogContentHeader;
