/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import { Edit2, Trash } from "react-feather";
import Table, { TableHeading } from "../../../shared/Table/Table";
import DeleteModal from "../../../shared/DeleteModal/DeleteModal";
import "./index.scss";
import {
  DeleteTestimonials,
  GetTestimonials,
} from "../../../store/features/AdminPanel/Testimonial/TestimonialApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import Empty from "../../../shared/Empty/Empty";
import userImage from "../../../assets/icons/user.png";

const ProductPageTestimonialContent = (props: any) => {
  const dispatch = useAppDispatch();
  const testimonialsState: any = useAppSelector((store: RootStore) => {
    return store.TestimonialsSlice;
  });

  const { setShowModal, setEditModal, selectedPage, currId, setCurrId } = props;
  const [showDeleteModal, setDeleteShowModal] = useState(false);

  const handleDelete = () => {
    dispatch(DeleteTestimonials(currId));
    if (!testimonialsState.loading && testimonialsState.req_success) {
      setDeleteShowModal(false);
    }
  };

  const handleEdit = (id: number) => {
    setShowModal(true);
    setEditModal(true);
    setCurrId(id);
  };

  useEffect(() => {
    dispatch(GetTestimonials(selectedPage.value));
  }, [selectedPage]);

  return (
    <div>
      {testimonialsState?.data?.length ? (
        <Table
          heading={
            <TableHeading>
              <th>Name</th>
              <th>Profession</th>
              <th>Description</th>
              <th className="hide__text">.</th>
              <th className="hide__text">.</th>
            </TableHeading>
          }
        >
          <tbody>
            {testimonialsState.filteractive &&
              testimonialsState.filterdata.map((e: any) => {
                return (
                  <tr className="" key={e.id}>
                    <td className="name__td">
                      <div className="testimonial__user__img__wrapper">
                        <img src={e.image? e.image : userImage} alt={e.name} />
                      </div>
                      <span>{e.name}</span>
                    </td>

                    <td className="profession__td">
                    {e.designation}
                    </td>
                    <td className="description__td">
                     {e.description}
                    </td>
                    <td className="top__tt__td">
                      {e.is_top ? "Top testimonial" : ""}
                    </td>

                    <td className="action__wrapper ">
                      <div className="action__container">
                        {" "}
                        <Edit2
                          className="edit__icon"
                          onClick={() => {
                            handleEdit(e.id);
                          }}
                        />
                        <Trash
                          className="delete__icon"
                          onClick={() => {
                            setDeleteShowModal(true);
                            setCurrId(e.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            {!testimonialsState.filteractive &&
              testimonialsState.data.map((e: any) => {
                return (
                  <tr className="" key={e.id}>
                    <td className="name__td">
                      <div className="testimonial__user__img__wrapper">
                      <img src={e.image? e.image : userImage} alt={e.name} />
                      </div>
                      <span>{e.name}</span>
                    </td>

                    <td className="profession__td">   {e.designation}</td>
                    <td className="description__td"> {e.description} </td>
                    <td className="top__tt__td">
                      {e.is_top ? "Top testimonial" : ""}
                    </td>

                    <td className="action__wrapper ">
                      <div className="action__container">
                        {" "}
                        <Edit2
                          className="edit__icon"
                          onClick={() => {
                            handleEdit(e.id);
                          }}
                        />
                        <Trash
                          className="delete__icon"
                          onClick={() => {
                            setDeleteShowModal(true);
                            setCurrId(e.id);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      ) : (
        <div className="mt-5 handle__empty__container__div">
          <Empty
            emptyText="It seems empty."
            emptyColorFulText="Add Testimonial"
            emptyText2="now."
            custombg={true}
          />
        </div>
      )}

      <div className="pagination">{/* <PaginateButton /> */}</div>

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={testimonialsState.loading}
        onClick={handleDelete}
      />
    </div>
  );
};

export default ProductPageTestimonialContent;
