import React from "react";
import "./inputLabel.scss";

const InputLabel = (props: any) => {
  const { name, text, required } = props;
  return (
    <label htmlFor={name}>
      {text}
      {required && <span className="red_astrick">*</span>}
    </label>
  );
};
export { InputLabel };
