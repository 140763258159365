import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";

const studentBannerValidationSchema = yup.object().shape({
    text: yup.string().required(REQUIRED_VALIDATION("This field")),
    btn_text:yup.string().required(REQUIRED_VALIDATION("Button name")),
    btn_link: yup.string().required(REQUIRED_VALIDATION("Button Link"))
    // .url("Invalid URL format. Please provide a valid link."),
  });
  const popupBannerValidationSchema = yup.object().shape({
    text_1: yup.string().required(REQUIRED_VALIDATION("This field")),
    text_2: yup.string().required(REQUIRED_VALIDATION("This field")),
    discount_text: yup.string().required(REQUIRED_VALIDATION("This field")),
    btn_text:yup.string().required(REQUIRED_VALIDATION("Button name")),
    btn_link: yup.string().required(REQUIRED_VALIDATION("Button Link"))
    // .url("Invalid URL format. Please provide a valid link."),
  });
  export const studentBannerFormOptions: any = {
    resolver: yupResolver(studentBannerValidationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  export const landingPageHeaderBannerFormOptions: any = {
    resolver: yupResolver(studentBannerValidationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  export const popupBannerFormOptions: any = {
    resolver: yupResolver(popupBannerValidationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  