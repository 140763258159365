import { useState } from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import PaymentHeader from "../../../components/AdminPanel/PaymentPlan/PaymentHeader/PaymentHeader";
import PaymentTable from "../../../components/AdminPanel/PaymentPlan/PaymentTable/PaymentTable";

const PaymentPlans = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <AdminPanelLayout>
     <div className="mt-7">
     <PaymentHeader />
      <PaymentTable />
     </div>
    </AdminPanelLayout>
  );
};

export default PaymentPlans;
