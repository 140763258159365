import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import "./forgetPasswordFrom.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { forgetPassword } from "../../../../store/features/Auth/AuthApi";
import { checkTenant } from "../../../../helpers/utils/CheckTenant";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";

const ForgetPasswordFrom = (props: any) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = checkTenant();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(REQUIRED_VALIDATION("Email"))
      .email("Email is invalid"),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    dispatch(
      forgetPassword({
        data: values,
        isTenant: user.isTenant,
        tenant: user.tenant,
      }),
    );
  };
  // useEffect(() => {
  //   if(authState.reset_password_success){
  //     navigate('')
  //   }
  // }, [authState.authState]);
  const location = useLocation();
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const isLoginPage = location.pathname.includes("/") && configState.configs.membership_enabled === Domain.TAA ;


  return (
    <form
      className="ForgetPasswordFrom__container"
      onSubmit={handleSubmit(onSubmit)}
    >
      <span className="choose_email">Choose email address</span>
      <span className="recover__text">Send recovery link to</span>
      <div className="form_row">
        <TextField
          type="text"
          name="email"
          placeholder="Enter email address"
          register={register}
          error={errors?.email}
          handleInputStyle={isLoginPage}
        />
      </div>
      <div className="form_row mt-2">
        <PrimaryButton
          text="Send"
          type="submit"
          loading={authState.forget_password_loading}
        />
      </div>
      <div className="return-to-signin">
        <Link to="/">Return to sign in</Link>
      </div>
    </form>
  );
};

export default ForgetPasswordFrom;
