import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getCoursesForStudent,
  getCourseDetailForStudent,
  tenantUserEnrollToCourse,
} from "./CoursesApi";

const initialState: any = {
  loading: false,
  req_success: false,
  enroll_to_course: false,
  all_courses: [],
  chapters: [],
  instructor: {},
  currentCourse: {},
};

const tenantUserCourseSlice = createSlice({
  name: "courseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL courses for student
    builder.addCase(getCoursesForStudent.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getCoursesForStudent.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.all_courses = payload?.data;
      },
    );
    builder.addCase(getCoursesForStudent.rejected, (state) => {
      state.loading = false;
    });

    // GET a course detail for student
    builder.addCase(getCourseDetailForStudent.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getCourseDetailForStudent.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.currentCourse = payload?.course;
        state.chapters = payload?.chapters;
        state.instructor = payload?.instructor;
      },
    );
    builder.addCase(getCourseDetailForStudent.rejected, (state) => {
      state.loading = false;
    });

    // Enroll to the course detail for student
    builder.addCase(
      tenantUserEnrollToCourse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.enroll_to_course = true;
      },
    );
  },
});

export default tenantUserCourseSlice.reducer;
