/* eslint-disable import/no-extraneous-dependencies */
import { Icon } from "@iconify/react"
import React from "react"
import { X } from "react-feather"

const PremiumCourseWarning = (props:any) => {
    const {setShowInfoWarning} = props
  return (
    <div className="premium__course__warning__wrapper">
    <div className="premium__course__warning">
      <div className="warning__icon">
        <Icon icon="ic:sharp-info" />
      </div>
      <p>These are Agile Certified Courses, meticulously designed, and will be charged separately, offering personalized enrollment options for all aspiring students.</p>
      <X size={16} className="warning__close" onClick={()=>setShowInfoWarning(false)}/>
    </div>
  </div>
  )
}

export default PremiumCourseWarning
