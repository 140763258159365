import "./index.scss";

export const Card25 = (props: any) => {
  const { title, children, baw } = props;
  return (
    <div className={`card_25 ${baw ? "baw" : ""}`}>
      {title && <span className="card25_title">{title}</span>}
      {children}
    </div>
  );
};
