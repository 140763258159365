import React from "react"
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout"
import BoxShadow from "../../../shared/BoxShadow/BoxShadow"
import WebinarDateTimeComp from "../../../components/AdminPanel/Webinar/WbinarTab/WbinarDateTimeAllTab"

const WebinarDateAndTime = () => {
  return (
    <AdminPanelLayout>
    <BoxShadow>
     <WebinarDateTimeComp/>
    </BoxShadow>
    </AdminPanelLayout>
  )
}

export default WebinarDateAndTime
