/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addOrUpdatePlans,
  deletePlans,
  getTutionPlans,
  addOrUpdateDescription,
  getDescription,
} from "./TutionPlanApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    delete: false,
    get_description: false,
    add_description: false,
  },
  req_success: {
    add: false,
    get: false,
    add_description: false,
    get_description: false,
    delete: false,
  },
  plans: [],
  description: "",
};

const TutionSlice = createSlice({
  name: "tutions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // LOAD plans
    builder.addCase(getTutionPlans.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getTutionPlans.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.plans = payload.data;
      },
    );
    builder.addCase(getTutionPlans.rejected, (state) => {
      state.loading.get = false;
    });

    // ADD  PLANS
    builder.addCase(addOrUpdatePlans.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      addOrUpdatePlans.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
        if (payload?.idForUpdate > 0) {
          state.plans = state.plans.map((plan: any) => {
            if (plan.id === payload?.data.id) {
              return payload?.data;
            }
            return plan;
          });
        }

        if (payload?.idForUpdate === 0) {
          state.plans = [...state.plans, payload?.data];
        }
      },
    );
    builder.addCase(addOrUpdatePlans.rejected, (state) => {
      state.loading.add = false;
    });

    // ADD descriptions
    builder.addCase(addOrUpdateDescription.pending, (state) => {
      state.loading.add_description = true;
      state.req_success.add_description = false;
    });
    builder.addCase(
      addOrUpdateDescription.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add_description = false;
        state.req_success.add_description = true;
      },
    );
    builder.addCase(addOrUpdateDescription.rejected, (state) => {
      state.loading.add_description = false;
    });

    // get descriptions
    builder.addCase(getDescription.pending, (state) => {
      state.loading.get_description = true;
      state.req_success.get_description = false;
    });
    builder.addCase(
      getDescription.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_description = false;
        state.req_success.get_description = true;
        state.description = payload.data;
      },
    );
    builder.addCase(getDescription.rejected, (state) => {
      state.loading.get_description = false;
    });

    // DELETE USERS
    builder.addCase(deletePlans.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deletePlans.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.plans = state.plans.filter(
          (plan: any) => plan.id !== payload?.id,
        );
      },
    );
    builder.addCase(deletePlans.rejected, (state) => {
      state.loading.delete = false;
    });
  },
});

// export const { setRecentSignupEmail, invokeLogout } = TutionSlice.actions;

export default TutionSlice.reducer;
