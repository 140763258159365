/* eslint-disable no-else-return */
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import PasswordField from "../../../../shared/Inputs/PasswordField/PasswordField";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  memberSignup,
  validateMemberSignup,
} from "../../../../store/features/Auth/AuthApi";

const MemberSignupForm = (props: any) => {
  const [invalidToken, setInvalidToken] = useState(false);
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const navigate = useNavigate();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const validationSchema = yup.object().shape({
    first_name: yup.string().required(REQUIRED_VALIDATION("First Name")),
    last_name: yup.string().required(REQUIRED_VALIDATION("Last Name")),
    email: yup.string().required(REQUIRED_VALIDATION("Email Address")),
    password: yup
      .string()
      .required(REQUIRED_VALIDATION("Password"))
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 special case Character",
      ),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    const data = {
      ...values,
      token: token,
    };
    dispatch(memberSignup(data));
  };
  useLayoutEffect(() => {
    const data = {
      token: token,
    };
    dispatch(validateMemberSignup({ data: data, fun: setInvalidToken }));
  }, []);
  useEffect(() => {
    if (authState.member_verify_token_success) {
      setValue("email", authState.recent_signup_email);
    }
  }, [authState?.member_verify_token_success]);

  useEffect(() => {
    if (authState.member_signup_success) {
      navigate("/");
    }
  }, [authState?.member_signup_success]);

  return (
    <form className="login__form__container" onSubmit={handleSubmit(onSubmit)}>
      {invalidToken && <div>invalid token</div>}
      {!authState.member_verify_token_loading &&
        authState.member_verify_token_success && (
          <>
            <span className="welcome__text">Welcome</span>
            <span className="welcome__description margin">
              Sign up as team member
            </span>
            <div className="form_row">
              <input type="text" style={{ display: "none" }} />
              <div className="form_row fr-c2">
                <TextField
                  type="text"
                  label="First Name"
                  name="first_name"
                  register={register}
                  error={errors?.first_name}
                />

                <TextField
                  type="text"
                  label="Last Name"
                  name="last_name"
                  register={register}
                  error={errors?.last_name}
                />
              </div>
              <div className="form_row">
                <TextField
                  type="text"
                  label="Email"
                  name="email"
                  disabled
                  placeholder="Enter email address"
                  register={register}
                  error={errors?.email}
                />
              </div>
              <PasswordField
                label="Password"
                name="password"
                register={register}
                error={errors?.password}
              />
            </div>
            <div className="form_row mt-3">
              <PrimaryButton
                text="Sign up"
                type="submit"
                loading={authState?.member_signup_loading}
              />
            </div>
          </>
        )}
    </form>
  );
};

export default MemberSignupForm;
