import React from "react"
import "./index.scss"

interface agilleCertifiedbadgeProps {
  text:string;
}
const AgilleCertifiedbadge:React.FC<agilleCertifiedbadgeProps> = ({
  text
}) => {
  return (
    <div className='agile__badge'>
      {text}
    </div>
  )
}

export default AgilleCertifiedbadge
