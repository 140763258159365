import React, { useEffect, useState } from "react";
import "./deletevideo.scss";
import Modal, {
  ModalBody,
  ModalHeader,
} from "../../../../../shared/Modals/Modal";
import CheckBox from "../../../../../shared/Inputs/CheckBox/CheckBox";
import GetSuperText from "./GetSuperText";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import { deleteAnswerVideo } from "../../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationApi";

const VidelDeleteModal = (props: any) => {
  const { showModal, setShowModal, loading, onClick } = props;

  const [tempData, setTempData] = useState<any>([]);
  let selectedData: any = [];

  const dispatch = useAppDispatch();
  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });
  useEffect(() => {
    if (interviewState.active_answer?.answers) {
      setTempData(interviewState.active_answer?.answers);
    }
  }, [interviewState.active_answer?.answers]);

  const getSelectData = (data: any) => {
    if (data.checked) {
      const users = tempData.map((user: any) => {
        if (data.id === user.id) {
          return { ...user, checked: false };
        }
        return user;
      });
      setTempData(users);
    }

    if (!data.checked) {
      const users = tempData.map((user: any) => {
        if (data.id === user.id) {
          return { ...user, checked: true };
        }
        return user;
      });
      setTempData(users);
    }
  };

  const deleteVideo = () => {
    tempData.forEach((e: any) => {
      if (e.checked) {
        selectedData.push(e.id);
      }
    });
    if (selectedData.length === 0) {
      return;
    }
    const data: any = { interview_answer_videos: selectedData };
    dispatch(deleteAnswerVideo(data));

    selectedData = [];
  };

  const handleModal = () => {};
  return (
    <Modal show={showModal} setShow={setShowModal} onClick={handleModal} bluishBgModal>
      <ModalHeader title="Select response to delete " />
      <ModalBody modalWidth="455px">
        {showModal && (
          <form>
            {tempData.map((answer: any, index: number) => {
              return (
                <div className="checkbox__handle" key={answer.id}>
                  <CheckBox
                    label={<GetSuperText number={index + 1} />}
                    CheckboxValue={answer.checked}
                    onClick={() => getSelectData(answer)}
                    border
                  />
                </div>
              );
            })}

            <div className="delete__divider divider__div" />
            <div className="row">
              <div className=" mt-1 mr-2">
                <button
                  className="custom__button feedback__delete__button "
                  type="button"
                  onClick={() => {
                    deleteVideo();
                  }}
                  disabled={interviewState.loading.delete_video}
                >
                  {!interviewState.loading.delete_video && "Delete"}
                  {interviewState.loading.delete_video && (
                    <div className="spinner mr-1" />
                  )}
                </button>
              </div>
              <div className=" mt-1">
                <button
                  className="custom__button feedback__cancel__button "
                  type="button"
                  onClick={() => setShowModal(false)}
                  disabled={interviewState.loading.delete_video}
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </ModalBody>
    </Modal>
  );
};

export default VidelDeleteModal;
