import React from "react"
import ReactHtmlParser from "react-html-parser";
import "./viewDetails.scss"
import { useAppSelector } from "../../../../../store/store";

const ViewDetailsTable = () => {
  const applyNowState: any = useAppSelector((state) => state.ApplyNowSlice);

  return (
    <div className="apply__now__question__answer__wrapper">
      {applyNowState.singleApplyNow?.question_answer?.map(
        (item: any, index: number) => (
          <div
            key={item.question}
            className="apply__now__question__answer__continer"
          >
            <span>
              Q{index + 1}. {item.question}
            </span>
            <p>{ReactHtmlParser(item.answer)}</p>
          </div>
        )
      )}
    </div>
  );
};

export default ViewDetailsTable
