import React, { useEffect, useState } from "react";
import "./datetime.scss";
import DeleteModal from "../../../../../shared/DeleteModal/DeleteModal";
import Table, {
  TableAction,
  TableBody,
  TableHeading,
} from "../../../../../shared/Table/Table";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  changeWebinarType,
  closeModal,
  editWebinarInfo,
  setShowModal,
} from "../../../../../store/features/AdminPanel/Webinar/WebinarSlice";
import { deleteWebinar } from "../../../../../store/features/AdminPanel/Webinar/WebinarApi";

const DateTimeTable = (props: any) => {
  const { isEdit, setIsEdit, showModal, setShowModal, dateValue } = props;

  const dispatch = useAppDispatch();
  const webinarState: any = useAppSelector((store: RootStore) => {
    return store.WebinarSlice;
  });

  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const openDeleteModal = (data: any) => {
    setDeleteShowModal(true);
    dispatch(changeWebinarType("upcoming"));
    dispatch(editWebinarInfo(data));
  };
  const editModalHandler = (data: any) => {
    setIsEdit(true);
    setShowModal(true);
    dispatch(editWebinarInfo(data));
  };

  const deleteWebinarHandler = () => {
    dispatch(deleteWebinar(webinarState.upcoming_webinar));
  };

  useEffect(() => {
    if (webinarState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [webinarState.loading.delete]);

  return (
    <>
      {" "}
      <div className="webinar_date_time_table">
        <Table
          heading={
            <TableHeading>
              <th className="width_handler_th"> Date</th>
              <th> Time</th>
              <th className="hide__text">.</th>
            </TableHeading>
          }
        >
          <TableBody>
            {webinarState.req_success.get && webinarState.upcoming_webinar && (
              <tr>
                <td>{webinarState.upcoming_webinar.date}</td>
                <td>{webinarState.upcoming_webinar.time}</td>
                {/* <td> */}
                <TableAction
                  onEdit={() => editModalHandler(webinarState.upcoming_webinar)}
                  onDelete={() =>
                    openDeleteModal(webinarState.upcoming_webinar)
                  }
                />
                {/* </td> */}
              </tr>
            )}
            {webinarState.req_success.get && !webinarState.upcoming_webinar && (
              <tr>
                <td className="padding_empty" colSpan={3}>
                  No Any Upcoming Webinars.
                </td>
              </tr>
            )}
          </TableBody>
        </Table>

        <DeleteModal
          loading={webinarState.loading.delete}
          showModal={showDeleteModal}
          setShowModal={setDeleteShowModal}
          onClick={deleteWebinarHandler}
        />
      </div>
    </>
  );
};

export default DateTimeTable;
