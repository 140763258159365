import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import ArchiveHeader from "../../../components/AdminPanel/Archive/Header/ArchiveHeader";
import ArchiveTable from "../../../components/AdminPanel/Archive/Table/ArchiveTable";

const Archive = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
      <div className="mt-7">
      <ArchiveHeader />
        <ArchiveTable />
      </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default Archive;
