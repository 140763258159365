import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import { TenantTeamTable } from "../../../components/AdminPanel/TenantTeam/TeantTeamTable/TenantTeamTable";

const TenantTeams = () => {
  return (
    <AdminPanelLayout>
     <div className="mt-7">
     <TenantTeamTable />
     </div>

      {/* <TeamsFooter /> */}
    </AdminPanelLayout>
  );
};

export default TenantTeams;
