import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addBlog, getBlogs, deleteBlog, updateBlog } from "./BlogApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    delete: false,
  },
  req_success: {
    add: false,
    get: false,
    delete: false,
    edit: false,
  },
  editBlog: false,
  single_blog_info: {},
  current_page: 0,
  all_blogs: [],
  temp_blogs: [],
  blogs: [],
};
// ADD about;
const BlogSlice = createSlice({
  name: "BlogSlice",
  initialState,
  reducers: {
    editBlogInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.editBlog = true;
      state.single_blog_info = payload;
    },
    closeBlogEditModal: (state: any) => {
      state.editBlog = false;
    },
    BlogTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.blogs = state.all_blogs.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.blogs = state.all_blogs.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.blogs = state.all_blogs.slice(payload * 10, 10 * payload + 10);
      }
    },
    searchBlogInTable: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_blogs = state.all_blogs.filter((blog: any) =>
          blog.title.toLowerCase().includes(payload.toLowerCase()),
        );
        state.blogs = state.temp_blogs.slice(0, 10);
      } else {
        state.blogs = state.all_blogs.slice(
          state.current_page * 10,
          10 * state.current_page + 10,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // ADD AUTHORS
    builder.addCase(addBlog.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      addBlog.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;

        if (payload.data) {
          state.all_blogs = [payload?.data, ...state.all_blogs];

          if (state.current_page === 0) {
            state.blogs = state.all_blogs.slice(0, 10);
          } else {
            state.blogs = state.all_blogs.slice(
              state.current_page * 10,
              state.current_page * 10 + 10,
            );
          }
        }
      },
    );
    builder.addCase(addBlog.rejected, (state) => {
      state.loading.add = false;
    });

    // GET blogs
    builder.addCase(getBlogs.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
      state.req_success.add = false;
    });
    builder.addCase(
      getBlogs.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.all_blogs = payload.data.reverse();
        state.blogs = state.all_blogs.slice(0, 10);
      },
    );
    builder.addCase(getBlogs.rejected, (state) => {
      state.loading.get = false;
    });

    // UPDATE AUTHORS
    builder.addCase(updateBlog.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      updateBlog.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
        state.editBlog = false;
        state.all_blogs = state.all_blogs.map((blog: any) => {
          if (blog.id === payload?.data.id) {
            return payload?.data;
          }
          return blog;
        });
        state.blogs = state.blogs.map((blog: any) => {
          if (blog.id === payload?.data.id) {
            return payload?.data;
          }
          return blog;
        });
      },
    );
    builder.addCase(updateBlog.rejected, (state) => {
      state.loading.add = false;
      state.req_success.add = false;
    });

    // DELETE AUTHORS
    builder.addCase(deleteBlog.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteBlog.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_blogs = state.all_blogs.filter(
          (blog: any) => blog.id !== payload?.id,
        );
        if (state.current_page === 0) {
          state.blogs = state.all_blogs.slice(0, 10);
        } else {
          state.blogs = state.all_blogs.slice(
            state.current_page * 10,
            state.current_page * 10 + 10,
          );
        }
      },
    );
    builder.addCase(deleteBlog.rejected, (state) => {
      state.loading.delete = false;
    });
  },
});
export const {
  BlogTablePaginate,
  searchBlogInTable,
  editBlogInfo,
  closeBlogEditModal,
} = BlogSlice.actions;
export default BlogSlice.reducer;
