/* eslint-disable import/no-cycle */
import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./blogsectionalltab.scss";
import BlogCatagoriesHeader from "../blogHeader/BlogCatagoriesHeaader/blogCatagoriesHeader";
import BlogCatagoryTable from "../BlogTable/blogCatagory/blogCatagoryTable";
import BlogContentTable from "../BlogTable/blogContent/blogContentTable";
import BlogContentHeader from "../blogHeader/BlogContentHeader/blogContentHeader";
import BlogAuthorHeader from "../blogHeader/blogAuthorHeader/BlogAuthorHeader";
import BlogAuthorTable from "../BlogTable/blogAuthor/BlogAuthorTable";
import BlogContentAdd from "../../../../pages/AdminPanel/Blog/blogContent";

const BlogSectionAllTab = () => {
  const [openBlogPage, setOpenBlogPage] = React.useState(false);
  return (
    <div className="master__container margin_top_7">
      <Tabs className="tab__container">
        <TabList className="tab__list__section">
          <Tab className="single__tab ">Authors</Tab>
          <Tab className="single__tab ml-3">Blog Categories</Tab>
          <Tab className="single__tab ml-3">Blog Content</Tab>
        </TabList>

        <TabPanel>
          <div className="author__header">
            <BlogAuthorHeader />
          </div>

          <BlogAuthorTable />
        </TabPanel>
        <TabPanel>
          <div className="author__header">
            <BlogCatagoriesHeader />
          </div>
          <BlogCatagoryTable />
        </TabPanel>
        <TabPanel>
          {openBlogPage && <BlogContentAdd setOpenBlogPage={setOpenBlogPage} />}

          {!openBlogPage && (
            <div className="author__header">
              {" "}
              <BlogContentHeader setOpenBlogPage={setOpenBlogPage} />
            </div>
          )}
          {!openBlogPage && (
            <BlogContentTable setOpenBlogPage={setOpenBlogPage} />
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default BlogSectionAllTab;
