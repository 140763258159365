import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Nav from "../../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import InterviewPreparationComp from "../../../../components/NewAcademicUser/InterviewPreparation/VideoRecord/InterviewPreparationComp";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getInterviewQuestionsbyRoleId } from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationApi";
import InterviewQuestEmpty from "../InterviewQuestEmpty/InterviewQuestEmpty";
import { setAllResposneSucessToFalse } from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationSlice";
import "./index.scss"
import { closeShowPopup, setShowInnerPopupFalse, setShowPopup } from "../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";

function InterviewPreparation(props: any) {
  const { selected } = props;

  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });
  

  useEffect(() => {
    dispatch(setAllResposneSucessToFalse());

    if (
      location.state === "undefined" ||
      location.state === undefined ||
      location.state === null ||
      location.state === "null"||
      location.state?.roleId==="null"||
      location.state?.roleId==null
    ) {
      navigate("/interview-preparation-instruction");
    }
    else{

      dispatch(getInterviewQuestionsbyRoleId({ id: location.state?.roleId }));
    }

  }, [location.state?.roleId]);

  const onBoardingstate:any = useAppSelector((store:RootStore)=>store.ShowPopupSlice);
  // Check if the user is came for the first time interview preparation
  const isFirstTime = onBoardingstate?.webGuideDataInfo?.interview?.is_completed;
  // console.log(isFirstTime,onBoardingstate);
  function isFirstTimeUser() {
    if (isFirstTime=== 0) {
      dispatch(setShowPopup());
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (!isFirstTimeUser()){
      dispatch(closeShowPopup());
      dispatch(setShowInnerPopupFalse());
    }
  },[]);

  return (
    <div className="interview__preparationcomp__wrapper">
        <Nav  normal navBgColorHandle />
      {interviewState.req_success.questions && (
        <InterviewPreparationComp selected={selected} selectedRoleData = {location.state} />
      )}
      {interviewState.req_success.questions &&
        interviewState.questions.length === 0 && <InterviewQuestEmpty />}
       </div>
  );
}

export default InterviewPreparation;
