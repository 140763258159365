import React from "react"
import "./index.scss";
import micIcon from "../../../../../assets/icons/micIcon.svg";
import mIcon from "../../../../../assets/icons/mic4.png";

const AudioEmptyScreen = () => {
  return (
    <div className='audio__empty__wrapper'>
        <div className="audio__empty__container">
            <img src={mIcon} alt="" />
            {/* <p>To begin recording, please click the 'Start' button below.</p> */}
            <p>Please click the below 'Start' button to begin recording your audio.</p>
        </div>
      
    </div>
  )
}

export default AudioEmptyScreen
