/* eslint-disable jsx-a11y/media-has-caption */

import React, { useEffect, useState } from "react";
import "./faqcontent.scss";

import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";

import {
  deleteFeedbackFromAnswers,
  deleteVideoFromAnswer,
  getInterviewAnswerByRoleId,
} from "../../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerApi";
import QuestionAnswerAccording from "./QuestionAnswerAccording";
import DeleteModal from "../../../../../shared/Modals/DeleteModal/DeleteModal";
import {
  setNotificableQuestionTotop,
  setVideoIdForDelete,
} from "../../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerSlice";

const QuestionAnswerFaqContent = (props: any) => {
  const { role } = props;
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [showVideoDeleteModal, setVideoDeleteShowModal] = useState(false);
  const dispatch = useAppDispatch();

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewAnswerSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  // get all inteview answer and question according to role
  useEffect(() => {
    const data: any = {
      user_id: role.userId,
      interview_role_id: Number(role.id),
    };
    dispatch(getInterviewAnswerByRoleId(data));
  }, [role]);

  useEffect(() => {
    if (InterviewState.req_success.add) {
      const data: any = {
        user_id: role.userId,
        interview_role_id: Number(role.id),
      };
      dispatch(getInterviewAnswerByRoleId(data));
    }
  }, [InterviewState.req_success.add]);

  // check the notification data coming from notification and show that answer to the top of all question and answer
  useEffect(() => {
    if (InterviewState.req_success.get && InterviewState.notificationData) {
      dispatch(setNotificableQuestionTotop());
    }
  }, [InterviewState.req_success.get, InterviewState.notificationData]);

  useEffect(() => {
    if (InterviewState.req_success.delete) {
      const data: any = {
        user_id: role.userId,
        interview_role_id: Number(role.id),
      };
      dispatch(getInterviewAnswerByRoleId(data));
    }
    setDeleteShowModal(false);
  }, [InterviewState.req_success.delete]);

  useEffect(() => {
    if (InterviewState.req_success.delete_video) {
      const data: any = {
        user_id: role.userId,
        interview_role_id: Number(role.id),
      };
      dispatch(getInterviewAnswerByRoleId(data));
    }
    setVideoDeleteShowModal(false);
    dispatch(setVideoIdForDelete(null));
  }, [InterviewState.req_success.delete_video]);

  const deleteUser = () => {
    dispatch(deleteFeedbackFromAnswers(InterviewState.active_feedback.id));
  };

  const deleteVideo = () => {
    dispatch(deleteVideoFromAnswer(InterviewState.video_id_for_delete));
    dispatch(setVideoIdForDelete(null));
  };

  return (
    <div>
      {InterviewState.req_success.get &&
        InterviewState.question_answers.map((data: any, index: number) => {
          return (
            <QuestionAnswerAccording
              data={data}
              key={data.id}
              index={index}
              id={data.id}
              authState={authState}
              InterviewState={InterviewState}
              setDeleteShowModal={setDeleteShowModal}
              setVideoDeleteShowModal={setVideoDeleteShowModal}
            />
          );
        })}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={InterviewState.loading.delete}
        onClick={deleteUser}
      />

      <DeleteModal
        showModal={showVideoDeleteModal}
        setShowModal={setVideoDeleteShowModal}
        loading={InterviewState.loading.delete_video}
        onClick={deleteVideo}
      />
    </div>
  );
};

export default QuestionAnswerFaqContent;
