import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import GroupContent from "../../../components/Orgination/Groups/GroupContent/GroupContent";
import GroupHeader from "../../../components/Orgination/Groups/GroupHeader/GroupHeader";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import PaginateButton from "../../../shared/PaginateButton/PaginateButton";
import { groupsPagination } from "../../../store/features/Tenant/Orgination/Group/GroupSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";

function Groups(props: any) {
  const dispatch = useAppDispatch();
  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });
  const handlePagination = (num: number) => {
    dispatch(groupsPagination(num));
  };
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <GroupHeader /> <GroupContent />
      </BoxShadow>

      <div className="pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={groupState.all_groups.length}
        />
      </div>
    </AdminPanelLayout>
  );
}

export default Groups;
