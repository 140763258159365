/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { Icon } from "@iconify/react";
import "./billingTypeButton.scss";

const BillingTypeButton = (props: any) => {
  const { checkedValue, onClick, type, subtype, desc, price } = props;
  return (
    <button
      type="button"
      className={`billing__type__button__wrapper ${checkedValue && "active"}`}
      onClick={onClick}
    >
      <div className="top__section">
        <span className="titile"> {type}</span>

        <div className="custom__radio_button">
          <div className={`radio__icon tac ${checkedValue && "active"}`}>
            {checkedValue && (
              <Icon
                icon="material-symbols:radio-button-unchecked"
                className="checked__icon_yes"
              />
            )}
          </div>
        </div>
      </div>

      <span className="price">
        {" "}
        ${price}
        <span className="type">{subtype}</span>
      </span>
      <span className="desc"> {desc}</span>
    </button>
  );
};

export default BillingTypeButton;
