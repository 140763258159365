import React from "react";
import "./instructorinfo.scss";
import Avatar from "../../../../shared/Avatar/Avatar";

const InstructorsInfo = (props: any) => {
  const { instructors } = props;
  return (
    <div>
      <div className="instructors__info__container">
        <div className="instructors__info__header__section">
          <span className="heading">Instructors</span>
        </div>
        {instructors.map((instructor: any) => {
          return (
            <div className="instructor__profile" key={instructor.id}>
              <div className="pic">
                <Avatar imageUrl={instructor.avatar_url} />
              </div>
              <div className="info">
                <span className="name">
                  {instructor.first_name} {instructor.last_name}
                </span>
                <span className="occupation"> Instructor</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InstructorsInfo;
