import React from "react";
import Footer from "../Footer/Footer";
import NavBar from "../Navbar/NavBar";
import "./index.scss";

function LayoutLanding(props: any) {
  const { children } = props;
  return (
    <div className="layout-landing">
      <NavBar />
      <div className="landing-content">{children}</div>
      <Footer />
    </div>
  );
}

export default LayoutLanding;
