/* eslint-disable @typescript-eslint/naming-convention */
// export const BASE_URL =
//   process.env.NODE_ENV === "development"
//     ? "http://127.0.0.1:8000/api"
//     : "https://agileacademy.io/api";
// export const AUTH_URL = `${BASE_URL}`;

export const NORMAL_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000/api"
    : "https://agileacademy.io/api";

export const THINKFIC_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://127.0.0.1:8000"
    : "https://agileacademy.io";

export const BASE_HOST =
  process.env.NODE_ENV === "development" ? "localhost" : "agileacademy";
const AUTH_URLs = () => {
  const user = {
    isNormal: false,
    isTenant: false,
    tenant: "",
  };
  const host: any = window.location.hostname;
  const checkhost = host.split(".");
  if (checkhost[0] === BASE_HOST || checkhost[0] === "academy") {
    user.isNormal = true;
  } else {
    user.isTenant = true;
    const host = checkhost[0];
    user.tenant = host;
  }
  let url;
  if (process.env.NODE_ENV === "development" && user.isTenant) {
    url = `http://127.0.0.1:8000/api/${user.tenant}`;
  }
  if (process.env.NODE_ENV === "development" && user.isNormal) {
    url = "http://127.0.0.1:8000/api";
  }
  if (process.env.NODE_ENV !== "development" && user.isTenant) {
    url = `https://agileacademy.io/api/${user.tenant}`;
  }
  if (process.env.NODE_ENV !== "development" && user.isNormal) {
    url = "https://agileacademy.io/api";
  }
  return url;
};
export const AUTH_URL = AUTH_URLs();

const getHostName = () => {
  const host: any = window.location.hostname;
  const checkhost = host.split(".");
  return checkhost[0];
};

export const THINKFIC_COURSE_URL = "https://learn-agile-academy.thinkific.com";
const BUNNY_VIDEO_LIBRARY_ID_FN = () => {
  const host = getHostName();
  if (process.env.NODE_ENV === "development") {
    // this is for localhost
    return "207373";
    
    // return "284957";
  }
  if (process.env.NODE_ENV === "production" && host === "staging") {
    // this is for staging server
    return "207373";
  }
  // this is for live production
  return "203793";
};

export const BUNNY_VIDEO_LIBRARY_ID = BUNNY_VIDEO_LIBRARY_ID_FN();

const BUNNY_API_KEY_FN = () => {
  const host = getHostName();
  if (process.env.NODE_ENV === "development") {
    // this is for localhost
    return "a00b51f7-4fd5-4adc-b339734dcc21-37e6-4f95";
    // return "e55baed1-04f0-4a53-9f8ff6ee0469-7c6e-4b41";
  }
  if (process.env.NODE_ENV === "production" && host === "staging") {
    // this is for staging server
    return "a00b51f7-4fd5-4adc-b339734dcc21-37e6-4f95";
  }
  // this is for live production
  return "89867773-7bab-4869-8b8394c5e632-92be-4b01";
};
export const BUNNY_API_KEY = BUNNY_API_KEY_FN();

const BUNNY_INTERVIEW_PREP_COLLECTION_ID_FN = () => {
  const host = getHostName();
  if (process.env.NODE_ENV === "development") {
    // this is for localhost
    // e55baed1-04f0-4a53-9f8ff6ee0469-7c6e-4b41
    return "cf744b2c-9ea7-47c3-9f51-22d2e62386fd";
    // return "9ed04f33-72d9-4144-a229-991a5e6f6514";
  }
  if (process.env.NODE_ENV === "production" && host === "staging") {
    // this is for staging server
    return "cf744b2c-9ea7-47c3-9f51-22d2e62386fd";
  }
  // this is for live production
  return "ec4b1f30-436d-4de1-8f43-aa8b8ca0e567";
};
export const BUNNY_INTERVIEW_PREP_COLLECTION_ID =
  BUNNY_INTERVIEW_PREP_COLLECTION_ID_FN();
