/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from "react";

import Table, {
  TableBody,
  TableAction,
  TableHeading,
} from "../../../../../shared/Table/Table";
import DeleteModal from "../../../../../shared/Modals/DeleteModal/DeleteModal";

import "./blogContentTable.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  BlogTablePaginate,
  editBlogInfo,
} from "../../../../../store/features/AdminPanel/Blog/BlogSlice";
import {
  deleteBlog,
  getBlogs,
} from "../../../../../store/features/AdminPanel/Blog/BlogApi";
import PaginateButton from "../../../../../shared/PaginateButton/PaginateButton";
import Empty from "../../../../../shared/Empty/Empty";

const BlogContentTable = (props: any) => {
  const { setOpenBlogPage } = props;
  const [blogId, setBlogId] = React.useState(0);
  const dispatch = useAppDispatch();

  const [showDeleteModal, setDeleteShowModal] = React.useState(false);
  const deleteModalHandler = (blogId: number) => {
    setBlogId(blogId);
    setDeleteShowModal(true);
  };
  const removeBlog = () => {
    dispatch(deleteBlog({ blogId: blogId }));
  };
  const editModalHandler = (author: any) => {
    dispatch(editBlogInfo(author));
    setOpenBlogPage(true);
  };
  const blogState: any = useAppSelector((store: RootStore) => {
    return store.BlogSlice;
  });
  useEffect(() => {
    dispatch(getBlogs());
  }, []);

  useEffect(() => {
    if (blogState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [blogState.req_success.delete]);
  const handlePagination = (num: number) => {
    dispatch(BlogTablePaginate(num));
  };

  return (
    <>
      {" "}
      <div className="blog_content_table">
        <Table
          heading={
            <TableHeading>
              <th> Title</th>
              <th> Status</th>
              <th> </th>
            </TableHeading>
          }
        >
          <TableBody>
            {!blogState.loading.get &&
              blogState.req_success.get &&
              blogState.blogs.map((blog: any) => {
                return (
                  <tr key={blog.id}>
                    <td>{blog.title}</td>
                    <td>
                      <div
                        className={blog.status ? "published" : "un_published"}
                      >
                        <span className="dot">.</span>
                        {blog.status ? "Publish" : "Draft"}
                      </div>
                    </td>
                    <TableAction
                      onEdit={() => editModalHandler(blog)}
                      onDelete={() => deleteModalHandler(blog.id)}
                    />
                  </tr>
                );
              })}
          </TableBody>
        </Table>
        {!blogState.loading.get &&
              blogState.req_success.get &&
              blogState.blogs.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
        
         )}
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setDeleteShowModal}
          loading={blogState.loading.delete}
          onClick={removeBlog}
        />
      </div>
      <div className="blog__pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={blogState.all_blogs.length}
        />
      </div>
    </>
  );
};

export default BlogContentTable;
