import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import VideoLinksHeader from "../../../components/AdminPanel/videolinks/videolinksHeader";

const VideoLinks = (props: any) => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <VideoLinksHeader />
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default VideoLinks;
