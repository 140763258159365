import "./index.scss";

import { useEffect, useState } from "react";

import { PlanButton, PrimaryButton } from "../../../shared/Buttons/Buttons";
import TextArea from "../../../shared/Inputs/TextArea/TextArea";
import {
  addOrUpdateBulletPoints,
  addOrUpdateDescription,
  deleteBulletPoint,
  getOnboardingInfo,
} from "../../../store/features/AdminPanel/Onboarding/OnboardingApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import SingleLesson from "./SingleLesson";

const Onboardings = () => {
  const [lessonList, setLessonList] = useState<JSX.Element[]>([]);
  const [description, setDescription] = useState<string>("");

  const dispatch = useAppDispatch();

  const onboardingState: any = useAppSelector((store: RootStore) => {
    return store.OnboardingSlice;
  });

  useEffect(() => {
    dispatch(getOnboardingInfo());
  }, []);

  useEffect(() => {
    const lessons =
      onboardingState.bullet_points &&
      onboardingState?.bullet_points.map((bul: any) => {
        return (
          <SingleLesson
            id={bul.id}
            key={bul.id}
            text={bul.description}
            onDelete={onDeleteLesson}
            onCreateOrEdit={onCreateOrEditLesson}
          />
        );
      });

    setLessonList(lessons);
  }, [onboardingState.bullet_points]);

  useEffect(() => {
    setDescription(onboardingState?.description?.description || "");
  }, [onboardingState.description]);

  const addNewTextBox = (event: any) => {
    setLessonList(
      lessonList.concat(
        <SingleLesson
          index={lessonList.length}
          key={lessonList.length}
          onDelete={onDeleteLesson}
          onCreateOrEdit={onCreateOrEditLesson}
        />
      )
    );
  };

  const onCreateOrEditLesson = (value: string, id: string) => {
    if (!value) {
      return;
    }
    const formData = new FormData();
    formData.append("description", value);
    formData.append("type", "bullet");
    if (id) formData.append("id", id);
    if (!onboardingState.loading.post_bul) {
      dispatch(addOrUpdateBulletPoints(formData));
    }
  };

  const onDeleteLesson = (id: number, index: number) => {
    if (!id) {
      const lessons = lessonList.filter(
        (les: any) => lessonList.indexOf(les) !== index
      );
      setLessonList(lessons);
    }
    if (!onboardingState.loading.del_bul) {
      dispatch(deleteBulletPoint({ id }));
    }
  };

  const createOrUpdateDescription = () => {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("type", "desc");
    if (onboardingState?.description?.id)
      formData.append("id", onboardingState?.description?.id);

    dispatch(addOrUpdateDescription(formData));
  };

  return (
    <div className="onboarding__wrapper mt-7">
      <form className="onboard__card">
        <span className="title">Onboarding</span>
        {/* <span className="description__title"> Description</span> */}

        <div className="editor__wrapper">
          <TextArea
            value={description}
            name="description"
            label="Description"
            onChange={(e: any) => setDescription(e.target.value)}
          />
        </div>
        <div className="desc__buttons">
          <PrimaryButton
            onClick={createOrUpdateDescription}
            text="Submit"
            type="button"
            loading={false}
          />
        </div>
      </form>

      <div className="onboard__card">
        <div className="header">
          <span className="lessons">Lessons in this course</span>
          <div className="plans__button__width">
            <PlanButton onClick={addNewTextBox} text="Add new" />
          </div>
        </div>
        {lessonList}
      </div>
    </div>
  );
};

export default Onboardings;
