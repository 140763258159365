import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import ManagingCoursesTable from "../../../components/AdminPanel/ManagingCourses/ManagingCoursesTable";
import PaginateButton from "../../../shared/PaginateButton/PaginateButton";
import { adminCoursePaginate } from "../../../store/features/AdminPanel/ManageCourse/ManageCourseSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import "./ManagingCourses.scss";

const ManagingCourses = () => {
  const dispatch = useAppDispatch();

  // const courseState: any = useAppSelector((store: RootStore) => {
  //   return store.ManageCourseSlice;
  // });

  const handlePagination = (num: number) => {
    dispatch(adminCoursePaginate(num));
  };
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <ManagingCoursesTable />
      </BoxShadow>
      {/* <div className="manage_course_pagination__section">
        <PaginateButton
          paginate={handlePagination}
          totalRows={courseState.all_courses.length}
        />
      </div> */}
    </AdminPanelLayout>
  );
};

export default ManagingCourses;
