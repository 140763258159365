/* eslint-disable no-else-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  inviteMembers,
  getAllMembers,
  resendInvitation,
  getMemberDetails,
  editMember,
  Deletemember,
  courseAssismentUpdateDadeline,
  deleteIndividualAssignment,
  getMembersAssignments,
  addAssignment,
  getRemaningUsers,
} from "./MemberApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    detail: false,
    delete: false,
    edit: false,
    reinvite: false,
    deadline_update: false,
    individual_delete: false,
    get_assignment: false,
    add_assignment: false,
    remaning_user: false,
  },
  req_success: {
    add: false,
    get: false,
    detail: false,
    edit: false,
    delete: false,
    individual_delete: false,
    reinvite: true,
    deadline_update: false,
    get_assignment: false,
    add_assignment: false,
    remaning_user: false,
  },
  current_page: 0,
  min_group: [],
  max_group: [],
  members: [],
  member_limit: {},
  remaning_user: "",
  all_members: [],
  temp_members: [],
  member: {},
  single_member_data: {},
};

const member = createSlice({
  name: "member",
  initialState,
  reducers: {
    searchMembers: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_members = state.all_members.filter(
          (user: any) =>
            user.name.toLowerCase().includes(payload.toLowerCase()) ||
            user.email.toLowerCase().includes(payload.toLowerCase())
        );
        state.members = state.temp_members.slice(0, 10);
      } else {
        state.members = state.all_members.slice(
          state.current_page * 10,
          10 * state.current_page + 10
        );
      }
    },

    membersPaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.members = state.all_members.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.members = state.all_members.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.members = state.all_members.slice(
          payload * 10,
          10 * payload + 10
        );
      }
    },
  },
  extraReducers: (builder) => {
    // invite members
    builder.addCase(inviteMembers.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      inviteMembers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
      }
    );
    builder.addCase(inviteMembers.rejected, (state) => {
      state.loading.add = false;
    });

    // resend invitation
    builder.addCase(resendInvitation.pending, (state) => {
      state.loading.reinvite = true;
      state.req_success.reinvite = false;
    });
    builder.addCase(
      resendInvitation.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.reinvite = false;
        state.req_success.reinvite = true;
      }
    );
    builder.addCase(resendInvitation.rejected, (state) => {
      state.loading.reinvite = false;
    });
    // get all members
    builder.addCase(getAllMembers.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getAllMembers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;

        state.member_limit = payload.memberLimit;
        const test = payload?.data.reverse();
        const modifiedData = test.map((d: any) => {
          d.min_group = d.group.slice(0, 3);
          d.max_group = d.group.slice(3);
          return d;
        });
        // state.all_members = payload?.data.reverse();

        // state.members = state.all_members.slice(0, 10);

        state.all_members = modifiedData;

        state.members = state.all_members.slice(0, 10);
      }
    );
    builder.addCase(getAllMembers.rejected, (state) => {
      state.loading.get = false;
    });

    // get remaning  users
    builder.addCase(getRemaningUsers.pending, (state) => {
      state.loading.remaning_user = true;
      state.req_success.remaning_user = false;
    });
    builder.addCase(
      getRemaningUsers.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.remaning_user = false;
        state.req_success.remaning_user = true;
        state.remaning_user = payload.remainingUsers;
      }
    );
    builder.addCase(getRemaningUsers.rejected, (state) => {
      state.loading.remaning_user = false;
    });

    // get member details
    builder.addCase(getMemberDetails.pending, (state) => {
      state.loading.detail = true;
      state.req_success.detail = false;
    });
    builder.addCase(
      getMemberDetails.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.detail = false;
        state.req_success.detail = true;
        state.member = payload.data;
        state.min_group = payload.data.groups.slice(0, 3);
        state.max_group = payload.data.groups.slice(3);
      }
    );
    builder.addCase(getMemberDetails.rejected, (state) => {
      state.loading.detail = false;
    });

    // edit member
    builder.addCase(editMember.pending, (state) => {
      state.loading.edit = true;
      state.req_success.edit = false;
    });
    builder.addCase(
      editMember.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.edit = false;
        state.req_success.edit = true;
      }
    );
    builder.addCase(editMember.rejected, (state) => {
      state.loading.edit = false;
    });

    // delte member
    builder.addCase(Deletemember.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      Deletemember.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
      }
    );
    builder.addCase(Deletemember.rejected, (state) => {
      state.loading.delete = false;
    });

    // update course assisment course deadline
    builder.addCase(courseAssismentUpdateDadeline.pending, (state) => {
      state.loading.deadline_update = true;
      state.req_success.deadline_update = false;
    });
    builder.addCase(
      courseAssismentUpdateDadeline.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.deadline_update = false;
        state.req_success.deadline_update = true;
      }
    );
    builder.addCase(courseAssismentUpdateDadeline.rejected, (state) => {
      state.loading.deadline_update = false;
    });

    // Delete Individual Assignment
    builder.addCase(deleteIndividualAssignment.pending, (state) => {
      state.loading.individual_delete = true;
      state.req_success.individual_delete = false;
    });
    builder.addCase(
      deleteIndividualAssignment.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.individual_delete = false;
        state.req_success.individual_delete = true;
      }
    );
    builder.addCase(deleteIndividualAssignment.rejected, (state) => {
      state.loading.individual_delete = false;
    });

    // Get Members Assignments
    builder.addCase(getMembersAssignments.pending, (state) => {
      state.loading.get_assignment = true;
      state.req_success.get_assignment = false;
    });
    builder.addCase(
      getMembersAssignments.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_assignment = false;
        state.req_success.get_assignment = true;
        state.single_member_data = payload.data;
        state.single_member_data.min_group = payload.data.groups.slice(0, 3);
        state.single_member_data.max_group = payload.data.groups.slice(3);
      }
    );
    builder.addCase(getMembersAssignments.rejected, (state) => {
      state.loading.get_assignment = false;
    });

    // add Assignments
    builder.addCase(addAssignment.pending, (state) => {
      state.loading.add_assignment = true;
      state.req_success.add_assignment = false;
    });
    builder.addCase(
      addAssignment.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add_assignment = false;
        state.req_success.add_assignment = true;
      }
    );
    builder.addCase(addAssignment.rejected, (state) => {
      state.loading.add_assignment = false;
    });
  },
});
export const { searchMembers, membersPaginate } = member.actions;
export default member.reducer;
