import React from "react";

import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import AllCoursesBody from "../../../components/NewAcademicUser/UserAllCourses/AllCoursesBody/AllCoursesBody";

function UserAllCourses(props: any) {
  return (
    <>
      <Nav normal navBgColorHandle />
      <AllCoursesBody />
      {/* <NoCourses /> */}
    </>
  );
}

export default UserAllCourses;
