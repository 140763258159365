import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import LearningPathsDesign from "../../../components/AdminPanel/LearningPaths/LearningPathsDesign";

const LearningPaths = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <LearningPathsDesign />
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default LearningPaths;
