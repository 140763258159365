import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getOnboardingInfo,
  addOrUpdateDescription,
  addOrUpdateBulletPoints,
  deleteBulletPoint,
} from "./OnboardingApi";

const initialState: any = {
  loading: {
    get: false,
    post_bul: false,
    post_desc: false,
    del_bul: false,
  },
  req_success: {
    get: false,
    post_bul: false,
    post_desc: false,
    del_bul: false,
  },
  description: "",
  bullet_points: [],
};

const OnboardingSlice = createSlice({
  name: "OnboardingSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET Onboarding info
    builder.addCase(getOnboardingInfo.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getOnboardingInfo.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.description = payload.data.onboarding;
        state.bullet_points = payload.data.bullet_points;
      },
    );
    builder.addCase(getOnboardingInfo.rejected, (state) => {
      state.loading.get = false;
    });

    // Add  or update description
    builder.addCase(addOrUpdateDescription.pending, (state) => {
      state.loading.post_desc = true;
      state.req_success.post_desc = false;
    });
    builder.addCase(
      addOrUpdateDescription.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.post_desc = false;
        state.req_success.post_desc = true;
        state.description = payload.data;
      },
    );
    builder.addCase(addOrUpdateDescription.rejected, (state) => {
      state.loading.patch = false;
    });

    // Add  or update a bullet point
    builder.addCase(addOrUpdateBulletPoints.pending, (state) => {
      state.loading.post_bul = true;
      state.req_success.post_bul = false;
    });
    builder.addCase(
      addOrUpdateBulletPoints.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.post_bul = false;
        state.req_success.post_bul = true;
        let found = false;
        state.bullet_points = state.bullet_points.map((obj: any) => {
          if (obj.id === payload.data.id) {
            found = true;
            return payload.data;
          }

          return obj;
        });

        if (!found)
          state.bullet_points = [...state.bullet_points, payload.data];
      },
    );
    builder.addCase(addOrUpdateBulletPoints.rejected, (state) => {
      state.loading.post_bul = false;
    });

    // Delete a bullet point
    builder.addCase(deleteBulletPoint.pending, (state) => {
      state.loading.del_bul = true;
      state.req_success.del_bul = false;
    });
    builder.addCase(
      deleteBulletPoint.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.del_bul = false;
        state.req_success.del_bul = true;
        state.bullet_points = state.bullet_points.filter(
          (bul: any) => bul.id !== payload,
        );
      },
    );
    builder.addCase(deleteBulletPoint.rejected, (state) => {
      state.loading.del_bul = false;
    });
  },
});
export default OnboardingSlice.reducer;
