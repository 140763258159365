/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import { Check, Trash2 } from "react-feather";

import TextField from "../../../shared/Inputs/TextField/TextField";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";

const SingleLesson = (props: any) => {
  const { id = null, onDelete, onCreateOrEdit, text, index } = props;
  const [value, setValue] = useState<string>(text);
  // loading.post_bul
  const dispatch = useAppDispatch();

  const onboardingState: any = useAppSelector((store: RootStore) => {
    return store.OnboardingSlice;
  });

  return (
    <form className="single__lesson__wrapper">
      <div className="lesson__field__width">
        <TextField
          value={value || ""}
          name="lesson"
          type="text"
          onChange={(e: any) => setValue(e.target.value)}
        />
      </div>
      <div className="button__section">
        <button
          className="onboarding__button"
          type="button"
          disabled={onboardingState.loading.post_bul}
          onClick={() => {
            onCreateOrEdit(value, id);
          }}
        >
          <Check className="check__icon" />
        </button>
        <button
          type="button"
          style={{ all: "unset" }}
          disabled={onboardingState.loading.del_bul}
          onClick={() => onDelete(id, index)}
        >
          <Trash2 className="trans__ion" />
        </button>
      </div>
    </form>
  );
};

export default SingleLesson;
