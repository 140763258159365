import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";
import "./addquestionmodal.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { addOrUpdateQuestions } from "../../../../store/features/AdminPanel/Interview/Question/InterviewApi";
import { closeInterviewModal } from "../../../../store/features/AdminPanel/Interview/Question/InterviewSlice";

const AddQuestionModal = (props: any) => {
  const { showModal, setShowModal, role } = props;

  const dispatch = useAppDispatch();
  // const location: any = useLocation();

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewSlice;
  });

  const validationSchema = yup.object().shape({
    question: yup.string().required(REQUIRED_VALIDATION("Question")),
    max_recording_time: yup
      .number()
      .typeError("Time must be a number")
      .required(REQUIRED_VALIDATION("Max recording time limit")),
  });

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>(formOptions);

  const onSubmit = (values: any) => {
    const data = { ...values, interview_role_id: role.id };
    if (InterviewState.edit) {
      data.id = InterviewState.singleData.id;
    }
    dispatch(
      addOrUpdateQuestions({
        data: data,
        idForUpdate: InterviewState.edit ? InterviewState.singleData.id : 0,
      })
    );
  };

  useEffect(() => {
    if (InterviewState.req_success.add) {
      reset();
      setShowModal(false);
    }
  }, [InterviewState.req_success.add]);

  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const handleModal = () => {
    reset();
    dispatch(closeInterviewModal());
  };

  useEffect(() => {
    if (InterviewState.edit) {
      setValue("question", InterviewState.singleData.question);
      setValue(
        "max_recording_time",
        InterviewState.singleData.max_recording_time
      );
      setShowModal(true);
    }
  }, [InterviewState?.edit, InterviewState?.singleData]);

  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      onClick={handleModal}
      width="modal_width"
    >
      <ModalHeader title={`${InterviewState.edit ? "Edit Question" : "Add Question"}`}/>
      <ModalBody modalWidth="100%">
        <form onSubmit={handleSubmit(onSubmit, onError)} className="">
          <div className="content">
            <div className="lg_width">
              <TextArea
                label="Question"
                name="question"
                register={register}
                error={errors?.question}
              />
            </div>
            <div className="lg_width ">
              <TextField
                type="number"
                label="Max recording time limit ( in minutes )"
                name="max_recording_time"
                register={register}
                error={errors?.max_recording_time}
                min={1}
              />
            </div>

            <div className="addbtn">
              <div>
                <PrimaryButton
                  text={`${InterviewState.edit ? "Update" : "Add"}`}
                  type="submit"
                  loading={InterviewState.loading.add}
                />
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddQuestionModal;
