import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";

import LearningPathSteppers from "../../../components/UserDashboard/LearningPathStepers/LearningPathSteppers";

const LearningPathStepper = () => {
  return (
    <AdminPanelLayout>
    <div className="mt-5">
    <LearningPathSteppers />
    </div>
    </AdminPanelLayout>
  );
};

export default LearningPathStepper;
