import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import TeamsFooter from "../../../components/AdminPanel/Teams/TeamsFooter/TeamsFooter";
import TeamsHeader from "../../../components/AdminPanel/Teams/TeamsHeader";
import TeamsTable from "../../../components/AdminPanel/Teams/teamsTable/teamTable";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";

const Teams = () => {
  return (
    <AdminPanelLayout>
      <BoxShadow>
     <div className="mt-7">
     <TeamsHeader />
        <TeamsTable />
     </div>
      </BoxShadow>
      {/* <TeamsFooter /> */}
    </AdminPanelLayout>
  );
};

export default Teams;
