import SignUpFrom from "../../components/Auth/AuthForm/SignUpForm/SignUpFrom";
import AuthLayout from "../../components/Misc/Layout/AuthLayout/AuthLayout";
import LandingLayout from "../../components/Misc/Layout/LandingLayout/LandingLayout";

const SignUp = (props: any) => {
  return (
    <LandingLayout>
      <AuthLayout>
        <SignUpFrom />
      </AuthLayout>
    </LandingLayout>
  );
};

export default SignUp;
