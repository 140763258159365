import "./index.scss";

const HelperText = (props: any) => {
  const { text } = props;
  return (
    <div className="helper__text">
      Must be at least 8 characters and contain at least 1 number and 1
      uppercase letter and 1 lowercase letter and 1 special character.
    </div>
  );
};

export default HelperText;
