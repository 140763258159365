import { useEffect, useState } from "react";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import { getResourcesForUser } from "../../../../store/features/UserDashboard/Resources/UserResourceApi";
import {
  closeShowInfoModal,
  searchFilesAndFolder,
  setActiveSlice,
} from "../../../../store/features/UserDashboard/Resources/UserResourceSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import "./index.scss";

const UserResourceHeader = (props: any) => {
  const dispatch = useAppDispatch();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const resourceState: any = useAppSelector((store: RootStore) => {
    return store.UserResourceSlice;
  });
  useEffect(() => {
    dispatch(searchFilesAndFolder(searchText));
  }, [searchText]);
  useEffect(() => {
    dispatch(getResourcesForUser());
  }, []);
  const handleShowInfoModal = () => {
    dispatch(closeShowInfoModal(false));
    dispatch(setActiveSlice(0));
  };

  useEffect(() => {
    if (resourceState.show_info) {
      setShowInfoModal(true);
    }
  }, [resourceState.show_info]);
  return (
    <div className="user__resource__header">
      <div className="left__section">
        <TableHeadingTitle>User Resources</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
      </div>

      <Modal
        show={showInfoModal}
        setShow={setShowInfoModal}
        onClick={handleShowInfoModal}
      >
        <ModalHeader
          title={
            resourceState.single_file_folder_info.resource_type === "file"
              ? "File Info"
              : "Folder Info"
          }
        />
        <ModalBody>
          <div className="file_info__section">
            <div className="detail_row">
              <span className="property_title">Modified Date :</span>
              <span className="property_detail">
                {resourceState.single_file_folder_info.last_modified}
              </span>
            </div>
            <div className="detail_row">
              <span className="property_title">Size :</span>
              <span className="property_detail">
                {resourceState.single_file_folder_info.files_size}
              </span>
            </div>
            {resourceState.single_file_folder_info.resource_type === "file" && (
              <div className="detail_row">
                <span className="property_title">Extension : </span>
                <span className="property_detail">
                  {" "}
                  {
                    resourceState.single_file_folder_info.resource_extension
                  }{" "}
                </span>
              </div>
            )}
            <div className="detail_row">
              <span className="property_title">Name : </span>
              <span className="property_detail">
                {resourceState.single_file_folder_info.resource_name}{" "}
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UserResourceHeader;
