import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import TableHeadingTitle from "../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import TextField from "../../../shared/Inputs/TextField/TextField";
import {
  AddMeetingLink,
  updateMeeting,
} from "../../../store/features/AdminPanel/MeetWithUs/MeetWithUsApi";
import {
  closeMeetingModal,
  searchMeetingInTable,
} from "../../../store/features/AdminPanel/MeetWithUs/MeetWithUsSlice";

const MeetHeader = () => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  const validationSchema = yup.object().shape({
    name: yup.string().required(REQUIRED_VALIDATION("Title is required")),
    designation: yup
      .string()
      .required(REQUIRED_VALIDATION("Designation is required")),
    meeting_link: yup
      .string()
      .url("Enter correct url!")
      .required(REQUIRED_VALIDATION("Meeting link is required")),
  });

  const meetLinkState: any = useAppSelector((store: RootStore) => {
    return store.MeetWithUsSlice;
  });

  const onSubmit = (values: any) => {
    const data = { ...values };
    if (meetLinkState.isEdit && meetLinkState.active_meeting) {
      data.id = meetLinkState.active_meeting.id;
      dispatch(updateMeeting(data));
    } else {
      dispatch(AddMeetingLink(data));
    }
  };

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const openModal = () => {
    reset();
    setShowModal(true);
  };

  useEffect(() => {
    if (meetLinkState.isEdit) {
      openModal();
      setValue("name", meetLinkState.active_meeting?.name);
      setValue("designation", meetLinkState.active_meeting?.designation);
      setValue("meeting_link", meetLinkState.active_meeting?.meeting_link);
    }
  }, [meetLinkState?.isEdit]);
  useEffect(() => {
    if (meetLinkState.req_success.add) {
      reset();
      setShowModal(false);
    }
  }, [meetLinkState?.req_success.add]);

  const handleModal = () => {
    reset();
    dispatch(closeMeetingModal());
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    dispatch(searchMeetingInTable(searchText));
  }, [searchText]);

  return (
    <div className="header_container boxshadow">
      <div className="left__section">
        <TableHeadingTitle>Meet With Us</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={openModal}
          />
        </div>
      </div>
      <Modal
        show={showModal}
        setShow={setShowModal}
        resetForm={reset}
        onClick={handleModal}
      >
        <ModalHeader
          title={
            meetLinkState.isEdit ? "Edit Meeting Link" : "Add Meeting Link"
          }
        />
        <ModalBody>
          <form
            className="user__form__container"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            <TextField
              type="text"
              label="Name"
              name="name"
              register={register}
              error={errors?.name}
            />

            <TextField
              type="text"
              label="Designation"
              name="designation"
              register={register}
              error={errors?.designation}
            />

            <TextField
              type="text"
              label="Meeting Link"
              name="meeting_link"
              register={register}
              error={errors?.meeting_link}
            />
            <div className="modal_footer">
              <div className="button__witdh">
                <PrimaryButton
                  text={meetLinkState.isEdit ? "Update" : "Add"}
                  type="submit"
                  size="sm"
                  loading={
                    meetLinkState.loading.add || meetLinkState.loading.patch
                  }
                />
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MeetHeader;
