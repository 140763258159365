import React, { useEffect, useState } from "react";
import downarrow from "../../assets/icons/downarrow.svg";
import tickIcon from "../../assets/icons/tickIcon.svg";
import "./index.scss";

function DropDownMultiple(props: any) {
  const { children, title, index, closeOther, isOpen } = props;
  const [showDropDown, setShowDropDown] = useState(false);

  document.body.addEventListener("click", (e: any) => {
    if (
      !e.target.classList.contains("ddc-option") &&
      !e.target.classList.contains("ddc-options") &&
      !e.target.classList.contains("dropdown") &&
      !e.target.classList.contains("ddc-title") &&
      !e.target.classList.contains("checkbox-div")
    ) {
      setShowDropDown(false);
    }
  });
  useEffect(() => {
    if (showDropDown) {
      closeOther(index);
    }
  }, [showDropDown]);

  return (
    <div className="dropdown">
      <button
        type="button"
        className="ddc-title"
        onClick={() => {
          setShowDropDown(!showDropDown);
        }}
      >
        {title} <img src={downarrow} className="arrow" alt="arrow icon" />
      </button>
      {showDropDown && isOpen && <div className="ddc-option">{children}</div>}
    </div>
  );
}

export default DropDownMultiple;
