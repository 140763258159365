import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddCategory,
  getCategories,
  deleteCategory,
  updateCategory,
} from "./CategoryApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    delete: false,
  },
  req_success: {
    add: false,
    get: false,
    delete: false,
    edit: false,
  },
  editCategory: false,
  single_category_info: {},
  current_page: 0,
  all_category: [],
  temp_category: [],
  categories: [],
};
// ADD about;
const AuthorSlice = createSlice({
  name: "AuthorSlice",
  initialState,
  reducers: {
    editCategoryInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.editCategory = true;
      state.single_category_info = payload;
    },
    closeCategoryEditModal: (state: any, { payload }: PayloadAction<any>) => {
      state.editCategory = false;
    },
    CategoryTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.categories = state.all_category.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.categories = state.all_category.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.categories = state.all_category.slice(
          payload * 10,
          10 * payload + 10,
        );
      }
    },
    searchCategoryInTable: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_category = state.all_category.filter((category: any) =>
          category.name.toLowerCase().includes(payload.toLowerCase()),
        );
        state.categories = state.temp_category.slice(0, 10);
      } else {
        state.categories = state.all_category.slice(
          state.current_page * 10,
          10 * state.current_page + 10,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // ADD AUTHORS
    builder.addCase(AddCategory.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      AddCategory.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;

        if (payload.data) {
          state.all_category = [payload?.data, ...state.all_category];

          if (state.current_page === 0) {
            state.categories = state.all_category.slice(0, 10);
          } else {
            state.categories = state.all_category.slice(
              state.current_page * 10,
              state.current_page * 10 + 10,
            );
          }
        }
      },
    );
    builder.addCase(AddCategory.rejected, (state) => {
      state.loading.add = false;
    });

    // GET AUTHORS
    builder.addCase(getCategories.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getCategories.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.all_category = payload.data.reverse();
        state.categories = state.all_category.slice(0, 10);
      },
    );
    builder.addCase(getCategories.rejected, (state) => {
      state.loading.get = false;
    });

    // UPDATE AUTHORS
    builder.addCase(updateCategory.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      updateCategory.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
        state.editCategory = false;
        state.all_category = state.all_category.map((category: any) => {
          if (category.id === payload?.data.id) {
            return payload?.data;
          }
          return category;
        });
        state.categories = state.categories.map((category: any) => {
          if (category.id === payload?.data.id) {
            return payload?.data;
          }
          return category;
        });
      },
    );
    builder.addCase(updateCategory.rejected, (state) => {
      state.loading.add = false;
      state.req_success.add = false;
    });

    // DELETE AUTHORS
    builder.addCase(deleteCategory.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteCategory.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_category = state.all_category.filter(
          (category: any) => category.id !== payload?.id,
        );
        if (state.current_page === 0) {
          state.categories = state.all_category.slice(0, 10);
        } else {
          state.categories = state.all_category.slice(
            state.current_page * 10,
            state.current_page * 10 + 10,
          );
        }
      },
    );
    builder.addCase(deleteCategory.rejected, (state) => {
      state.loading.delete = false;
    });
  },
});
export const {
  CategoryTablePaginate,
  searchCategoryInTable,
  editCategoryInfo,
  closeCategoryEditModal,
} = AuthorSlice.actions;
export default AuthorSlice.reducer;
