import React, { useEffect, useState } from "react";
import "./index.scss";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import Table, { TableBody, TableHeading } from "../../../../shared/Table/Table";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { getInterviewUsers } from "../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerApi";
import userImg from "../../../../assets/icons/user.png";
import { searchInterviewUsers } from "../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerSlice";
import Empty from "../../../../shared/Empty/Empty";

const InterviewAnswer = (props: any) => {
  const { setOpenAnswerPage, setRole, backToHomeTab } = props;
  const [searchvalue, setSearchvalue] = useState("");

  const dispatch = useAppDispatch();

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewAnswerSlice;
  });

  // const NotificationState: any = useAppSelector((store: RootStore) => {
  //   return store.NotificationSlice;
  // });

  useEffect(() => {
    backToHomeTab();
    dispatch(getInterviewUsers());
    // if (NotificationState.selectedNotification) {
    //   openAnswerPage({id: NotificationState.selectedNotification.role_id}, NotificationState.selectedNotification.user_id);
    // }
  }, []);

  useEffect(() => {
    dispatch(searchInterviewUsers(searchvalue));
  }, [searchvalue]);

  const openAnswerPage = (role: any, userId: number) => {
    setRole({ ...role, userId: userId });
    setOpenAnswerPage(true);
  };

  return (
    <div className="admin__interview_answer__wrapper">
      <div className="table_headings">
        <div className="left_control">
          <TableHeadingTitle>Interview Answers</TableHeadingTitle>
        </div>
        <div className="right_control">
          <div className="search">
            <SearchBar value={searchvalue} setValue={setSearchvalue} />
          </div>
        </div>
      </div>
      <div className="interview_question_answer__table">
        <Table
          heading={
            <TableHeading>
              <th>Name</th>
              <th className="title_role">Role</th>
              <th>Last updated on</th>
            </TableHeading>
          }
        >
          <TableBody>
            {InterviewState?.users?.length > 0 &&
              InterviewState.users.map((user: any) => {
                return (
                  <tr key={user.id}>
                    <td>
                      <div className="users">
                        <img src={user.avatar_url ?? userImg} alt="userpic" />
                        <span>{user.name}</span>
                      </div>
                    </td>
                    <td className="role">
                      {user?.interview_roles.map((role: any) => {
                        return (
                          <span
                            key={role.id}
                            role="button"
                            aria-hidden
                            onClick={() => openAnswerPage(role, user.id)}
                          >
                            {role.name}
                          </span>
                        );
                      })}
                    </td>
                    <td>{user.last_updated}</td>
                  </tr>
                );
              })}
          </TableBody>
          {/* {InterviewState.req_success.getUser &&
            InterviewState.all_users.length === 0 && (
              <tbody className="emptyquestion">
                <tr className="empty">
                  <td>No users to show.</td>
                </tr>
              </tbody>
            )} */}
        </Table>
        {
          InterviewState?.users?.length === 0 &&
          (<div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>)
        }
      </div>
    </div>
  );
};

export default InterviewAnswer;
