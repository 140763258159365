import React, { useState } from "react"
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import FileUploader from "../../../../shared/FilesAndFolderUplader/FileUploader/FileUploader";

const MentorFileUploaderModal = (props:any) => {
    const {showFileUploadModal,setShowFileUploadModal} = props;
    const [fileUploadError, SetFileUpladError] = useState(false);
    const [progress, setProgress] = useState(100);
  return (
    
    <Modal  show={showFileUploadModal} setShow={setShowFileUploadModal} >
    <ModalHeader title="File Upload"/>
    <ModalBody>
            <FileUploader progress={progress} fileUploadError={fileUploadError}/>
            <div className="button__witdh mt-2 sm-8">
          <PrimaryButton
            text="Done"
            type="submit"/>
      </div>
    </ModalBody>
  </Modal>
  )
}

export default MentorFileUploaderModal
