import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllCoursesAndLearningPath,
  createGroup,
  getAllGroups,
  deleteGroup,
  updateGroup,
  getSingleGroupInfo,
} from "./GroupApi";

const initialState: any = {
  loading: {
    add: false,
    get: false,
    list: false,
    update: false,
    single_get: false,
    delete: false,
  },
  req_success: {
    add: false,
    get: false,
    list: false,
    update: false,
    single_get: false,
    delete: false,
  },
  current_page: 0,
  single_group_info: {},
  groups: [],
  all_groups: [],
  temp_groups: [],
  courses: [],
  learning_paths: [],
  members: [],
};

const GroupSlice = createSlice({
  name: "GroupSlice",
  initialState,
  reducers: {
    editGroupInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.editGroup = true;
      state.single_group_info = payload;
    },
    groupsPagination: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.groups = state.all_groups.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.groups = state.all_groups.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.groups = state.all_groups.slice(payload * 10, 10 * payload + 10);
      }
    },

    searchGroups: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length > 1) {
        state.temp_groups = state.all_groups.filter((group: any) =>
          group.name.toLowerCase().includes(payload.toLowerCase()),
        );
        state.groups = state.temp_groups.slice(0, 10);
      } else {
        state.groups = state.all_groups.slice(
          state.current_page * 10,
          10 * state.current_page + 10,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // GET all groups
    builder.addCase(getAllGroups.pending, (state) => {
      state.loading.list = true;
      state.req_success.list = false;
      state.req_success.update = false;
    });
    builder.addCase(
      getAllGroups.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.list = false;
        state.req_success.list = true;
        state.groups = payload.data;
        state.all_groups = payload?.data.reverse();
        state.groups = state.all_groups.slice(0, 10);
      },
    );
    builder.addCase(getAllGroups.rejected, (state) => {
      state.loading.list = false;
    });

    // GET all courses and learning path
    builder.addCase(getAllCoursesAndLearningPath.pending, (state) => {
      state.loading.get = true;
      state.req_success.get = false;
    });
    builder.addCase(
      getAllCoursesAndLearningPath.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get = false;
        state.req_success.get = true;
        state.courses = payload.data.courses.map((course: any) => {
          return { ...course, checked: false };
        });
        state.learning_paths = payload.data.learning_paths.map(
          (learning_path: any) => {
            return { ...learning_path, checked: false };
          },
        );
        state.members = payload.data.members.map((member: any) => {
          return { ...member, checked: false };
        });
      },
    );
    builder.addCase(getAllCoursesAndLearningPath.rejected, (state) => {
      state.loading.get = false;
    });

    // create group
    builder.addCase(createGroup.pending, (state) => {
      state.loading.add = true;
      state.req_success.add = false;
    });
    builder.addCase(
      createGroup.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.add = false;
        state.req_success.add = true;
      },
    );
    builder.addCase(createGroup.rejected, (state) => {
      state.loading.add = false;
    });
    // get single group
    builder.addCase(getSingleGroupInfo.pending, (state) => {
      state.loading.single_get = true;
      state.req_success.single_get = false;
      state.req_success.update = false;
    });
    builder.addCase(
      getSingleGroupInfo.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.single_get = false;
        state.req_success.single_get = true;
        state.single_group_info = payload.data;
        state.single_group_info.deadline =
          state.single_group_info.all_courses.filter(
            (course: any) => course.deadline,
          );
      },
    );
    builder.addCase(getSingleGroupInfo.rejected, (state) => {
      state.loading.single_get = false;
    });

    // delete group
    builder.addCase(deleteGroup.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deleteGroup.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_groups = state.all_groups.filter(
          (group: any) => group.id !== payload?.id,
        );
        if (state.current_page === 0) {
          state.groups = state.all_groups.slice(0, 10);
        } else {
          state.groups = state.all_groups.slice(
            state.current_page * 10,
            state.current_page * 10 + 10,
          );
        }
      },
    );
    builder.addCase(deleteGroup.rejected, (state) => {
      state.loading.delete = false;
    });

    // update group
    builder.addCase(updateGroup.pending, (state) => {
      state.loading.update = true;
      state.req_success.update = false;
    });
    builder.addCase(
      updateGroup.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.update = false;
        state.req_success.update = true;
      },
    );
    builder.addCase(updateGroup.rejected, (state) => {
      state.loading.update = false;
      state.req_success.update = false;
    });
  },
});
export const { groupsPagination, searchGroups, editGroupInfo } =
  GroupSlice.actions;
export default GroupSlice.reducer;
