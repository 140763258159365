import "./learningPathArchive.scss";
import { Calendar, Clock } from "react-feather";

const LearningPathArchive = (props: any) => {
  const { archives } = props;
  return (
    <div className="learning__path__archive">
      <div className="learning__path__archive__container__wrapper">
        <div className="learning__path__archive__container">
          <div className="learning__path__archive__header">
            <span>Archive</span>
          </div>
          <div className="learning__path__archive__items">
            {archives.map((archive: any, index: number) => (
              <div className="learning__path__archive__item" key={archive.id}>
                <span>{archive.topic} </span>
                <div className="learning__path__arcive__date__time">
                  <div className="date__time">
                    <div className="clock__icon">
                      <Calendar />
                    </div>
                    <div className="date">{archive.date}</div>
                  </div>

                  <div className="date__time">
                    <div className="clock__icon">
                      <Clock />
                    </div>
                    <div className="time__range">
                      {archive.time_from} - {archive.time_to}
                    </div>
                  </div>
                  <div>
                    <a
                      href={archive.video_link}
                      target="_blank"
                      rel="noreferrer"
                      className="zoom__link"
                    >
                      Zoom Link
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningPathArchive;
