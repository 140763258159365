import React, { useEffect } from "react";
import "./mrecentchat.scss";
import { MessageSquare } from "react-feather";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../../store/store";
import {
  fetchConversation,
  getRecentSearch,
} from "../../../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";

const MobileViewRecentChat = (props: any) => {
  const { setTabIndex } = props;
  const dispatch = useAppDispatch();

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  useEffect(() => {
    dispatch(getRecentSearch());
  }, []);

  const handleConversation = (id: string) => {
    if (aiState.loading.question) {
      return;
    }
    dispatch(fetchConversation({ id: id }));
    setTabIndex(0);
  };

  return (
    <div className="mobile__question__div">
      <div className="mobile__question__wrapper">
        <div className="mobile__question__container">
          <div className="question__wrapper ">
            {aiState.req_success.all_conversation &&
              aiState.all_conversation.map((conversation: any) => {
                return (
                  <div className="question__div" key={conversation.uniqueId}>
                    <div
                      className="question__item"
                      role="button"
                      aria-hidden
                      onClick={() => handleConversation(conversation.uniqueId)}
                    >
                      <div className="message__icon__handle">
                        <MessageSquare className="square__messagebox" />
                      </div>
                      <span>{conversation.question}</span>
                    </div>
                    {/* <div className="question__underline" /> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileViewRecentChat;
