import { Link } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import couseImg from "../../../assets/icons/OnlineCourse.jpg";

const Course = (props: any) => {
  const { course } = props;

  return (
    <Link to={`/organization-user-courses-detail/${course.id}`}>
      <div className="course__wapper">
        <img
          src={course.course_card_image_url || couseImg}
          className="cover__img"
          alt="cover"
        />
        <div className="course__info__section">
          <span className="course__title">{course.name}</span>
          <div className="course__row">
            <span className="course__durarion">
              Duration {course.custom_duration}
            </span>
            {course.deadline && (
              <span className="course__deadline">
                Deadline: {course.deadline}
              </span>
            )}
          </div>
          <p className="course__description">{course.description}</p>
          <div className="progress__section">
            <ProgressBar
              completed={course.percentage_completed}
              height="3px"
              customLabel="."
              baseBgColor="var( --primary-progress-bar-bg)"
              bgColor="var( --secondary-progress-bar-bg)"
              labelSize="0"
            />
          </div>
          <span className="completed__text">
            {course.percentage_completed}% completed
          </span>
        </div>
      </div>
    </Link>
  );
};

export default Course;
