/* eslint-disable no-unsafe-optional-chaining */

import React, { useEffect, useState } from "react";
import "./recentanserd.scss";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../../shared/SearchBar/SearchBar";
import Table, {
  TableBody,
  TableHeading,
} from "../../../../../shared/Table/Table";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import { getRecentlyAnsweredInterviews } from "../../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.actions";
import { searchRecentlyAnswerdInterviewUsers } from "../../../../../store/features/UserDashboard/InterviewDetails/InterviewDetails.slice";
import Empty from "../../../../../shared/Empty/Empty";

const RecentAnswer = (props: any) => {
  const { setOpenRecentAnserPage, backToHomeTab, setSelectedUser } = props;
  const [searchvalue, setSearchvalue] = useState("");

  const dispatch = useAppDispatch();

  const { recentlyAnswered } = useAppSelector((store: RootStore) => {
    return store.InterviewDetailsSlice;
  });

  useEffect(() => {
    dispatch(getRecentlyAnsweredInterviews());
    backToHomeTab();
  }, []);

  useEffect(() => {
    dispatch(searchRecentlyAnswerdInterviewUsers(searchvalue));
  }, [searchvalue]);

  const getAvatarFromName = (name: string): string => name?.charAt(0);

  return (
    <div className="admin__interview_answer__wrapper">
      <div className="table_headings">
        <div className="left_control">
          <TableHeadingTitle>Recently Answered</TableHeadingTitle>
        </div>
        <div className="right_control">
          <div className="search">
            <SearchBar value={searchvalue} setValue={setSearchvalue} />
          </div>
        </div>
      </div>
      <div className="interview_question_answer__table">
        <Table
          heading={
            <TableHeading>
              <th>Name</th>
              <th>Last updated</th>
            </TableHeading>
          }
        >
          {recentlyAnswered?.length > 0 && (
            <TableBody>
              {recentlyAnswered?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td className="user__td">
                      <div
                        className="users"
                        aria-hidden
                        onClick={() => {
                          setSelectedUser(item.id);
                          setOpenRecentAnserPage(true);
                        }}
                      >
                        {/* <img src={userImg} alt="userpic" /> */}
                        <div className="avatar__name">
                          {getAvatarFromName(item?.name)}
                        </div>
                        <span className="name__text">{item.name}</span>
                      </div>
                    </td>

                    <td className="time__td">{item.last_updated}</td>
                  </tr>
                );
              })}
            </TableBody>
          )}
        </Table>
        {
          recentlyAnswered.length ===0 &&
          (<div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>)
        }
      </div>
    </div>
  );
};

export default RecentAnswer;
