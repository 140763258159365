import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  PrimaryButton,
  SecondaryButton,
} from "../../../shared/Buttons/Buttons";
import "./LearningPathDesign.scss";
import Modal, { ModalBody, ModalHeader } from "../../../shared/Modals/Modal";
import TextField from "../../../shared/Inputs/TextField/TextField";
import TextArea from "../../../shared/Inputs/TextArea/TextArea";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  GetLearningPathCard,
  updateLearningPath,
} from "../../../store/features/AdminPanel/LearningPathCard/LearningPathCard";

const LearningPathsDesign = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const learningPathCardState: any = useAppSelector((store: RootStore) => {
    return store.LearningPathCardSlice;
  });

  const validationSchema = yup.object().shape({
    description: yup.string(),
  });

  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  useEffect(() => {
    dispatch(GetLearningPathCard());
  }, []);

  useEffect(() => {
    if (learningPathCardState.update_success) {
      setShowModal(false);
    }
  }, [learningPathCardState.update_success]);

  const {
    setValue,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const updatePath = (path: any) => {
    setShowModal(true);
    setValue("path_id", path.path_id);
    setValue("name", path.name);
    setValue("description", path.description);
  };
  const onSubmit = (values: any) => {
    const data = {
      ...values,
      path_id: parseInt(values.path_id, 10),
      description: values.description,
      name: values.name,
    };
    dispatch(updateLearningPath(data));
  };

  const viewCourse = (path: any) => {
    navigate(`/list-learning-courses/${path.path_id}`, {
      state: {
        path_id: path.path_id,
        name: path.name,
      },
    });
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  const handleModal = () => {
    reset();
  };
  return (
    <div className="learning_paths_cards_container mt-7">
      <Modal show={showModal} setShow={setShowModal} onClick={handleModal}>
        <ModalHeader title="Update Course" />
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="form_row">
              <TextField
                type="text"
                label="Path Id"
                name="path_id"
                register={register}
                error={errors?.path_id}
                disabled
              />
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="Learning Path Name"
                name="name"
                register={register}
                error={errors?.name}
                disabled
              />
            </div>
            <div className="form_row">
              <TextArea
                label="Description"
                name="description"
                register={register}
                error={errors?.description}
              />
            </div>
            <div className="button__witdh mt-1">
              <PrimaryButton
                text="Submit"
                type="submit"
                loading={learningPathCardState.update_loading}
              />
            </div>
          </form>
        </ModalBody>
      </Modal>
      {!learningPathCardState.loading &&
        learningPathCardState.req_success &&
        learningPathCardState.learningPathCard.map((path: any) => {
          return (
            <div className="card_container mt-5" key={path.id}>
              <div className="learning_path_top">
                <img
                  className="course_thumbnail"
                  src={
                    path.bundle_card_image_url?.startsWith("https", 0)
                      ? path.bundle_card_image_url
                      : "http://teslarzone.in/wp-content/uploads/2014/04/dummy-image-grey-e1398449111870.jpg"
                  }
                  alt=""
                />
              </div>
              <div className="learning_path_bottom">
                <div className="lower_inner_container">
                  <p className="course_tag">
                    {path.path_courses?.length} Courses
                  </p>
                  <span className="learning_path_bundleTitle">{path.name}</span>
                  {/* <h3></h3> */}
                  <div className="course_button_container">
                    <SecondaryButton
                      // style={{ color: "#000000" }}
                      id="sec-btn-dsgn"
                      text="View Courses"
                      size="sm"
                      type="button"
                      onClick={() => viewCourse(path)}
                    />
                    {/* <PrimaryButton
                      id="pmry-btn-dsgn"
                      text="Update"
                      size="sm"
                      type="button"
                      onClick={() => updatePath(path)}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default LearningPathsDesign;
