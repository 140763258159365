import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";

export const getGoHighlevelInfo = createAsyncThunk(
  "getGoHighlevelInfo",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/gohighlevel/info`,
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      // ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const disConnectWithGoHighlevel = createAsyncThunk(
    "disConnectWithGoHighlevel",
    async (undefined, { dispatch, rejectWithValue, getState }) => {
      try {
        const state = getState();
        const dynamic_url = renderDynamicUrlFromState(state);
        const res = await axiosInstance.get(
          `${dynamic_url}/gohighlevel/disconnect`,
        );
        REQUIRED__TOASTER(res.data);
        return res.data;
      } catch (error: any) {
        ERROR__TOASTER(error);
        if (!error.response) {
          throw error;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );