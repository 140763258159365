import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

export const getSubscribers = createAsyncThunk(
  "getSubscribers",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/ebook-downloads-data`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTacSubscribers = createAsyncThunk(
  "getTacSubscribers",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/subscribers`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadSubscriberExcelFile = createAsyncThunk(
  "downloadSubscriberExcelFile",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const response = await axiosInstance.get(
        `${dynamic_url}/exportsubscribersexcel`,
        {
          responseType: "blob",
        }
      );
      const fileURL = window.URL.createObjectURL(response.data);
      const alink = document.createElement("a");
      alink.href = fileURL;
      const now = new Date();
      alink.download = `subscribers_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}.xlsx`;
      alink.click();
      URL.revokeObjectURL(fileURL);
      return true;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadSubscriberCsvFile = createAsyncThunk(
  "downloadSubscriberCsvFile",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const response = await axiosInstance.get(
        `${dynamic_url}/exportsubscriberscsv`,
        {
          responseType: "blob",
        }
      );
      const fileURL = window.URL.createObjectURL(response.data);
      const alink = document.createElement("a");
      alink.href = fileURL;
      const now = new Date();
      alink.download = `subscribers_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}.csv`;
      alink.click();
      URL.revokeObjectURL(fileURL);
      return true;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadTaaSubCSVFile = createAsyncThunk(
  "downloadTaaSubCSVFile",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const response = await axiosInstance.get(
        `${dynamic_url}/export-ebook-downloads/csv`,
        {
          responseType: "blob",
        }
      );
      const fileURL = window.URL.createObjectURL(response.data);
      const alink = document.createElement("a");
      alink.href = fileURL;
      const now = new Date();
      alink.download = `users_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}.csv`;
      alink.click();
      URL.revokeObjectURL(fileURL);
      return true;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadTaaSubXLSXFile = createAsyncThunk(
  "downloadTaaSubXLSXFile",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const response = await axiosInstance.get(
        `${dynamic_url}/export-ebook-downloads/xlsx`,
        {
          responseType: "blob",
        }
      );
      const fileURL = window.URL.createObjectURL(response.data);
      const alink = document.createElement("a");
      alink.href = fileURL;
      const now = new Date();
      alink.download = `users_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}.xlsx`;
      alink.click();
      URL.revokeObjectURL(fileURL);
      return true;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getContactMessages = createAsyncThunk(
  "getContactMessages",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/contact-messages`
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
