import React from "react";
import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import UserProfileHeader from "../../../components/AdminPanel/userProfile/userProfileHeader";
import "./myaccount.scss";

function MyAccount(props: any) {
  return (
    <>
      <Nav  normal navBgColorHandle/>
      <div className="myaccount__wrapper">
      <div className="myaccount">
        <h1>My Account</h1>
        <UserProfileHeader />
      </div>
      </div>
    </>
  );
}

export default MyAccount;
