/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getCourse,
  getCourseInstructor,
  getCoureChapter,
  getChapterDetail,
  entollToCourse,
} from "./ShowCourseApi";

const initialState: any = {
  loading: false,
  enroll_loading: false,
  enroll_success: false,
  chapters_details_loading: false,
  req_success: false,
  course_detail_success: false,
  chapters_success: false,
  chapters_detail_success: false,
  all_courses: [],
  temp_courses: [],
  course_detail: {},
  instructor_detail: {},
  instructor_success: false,
  chapters: {},
  chapters_details: {},
};

const FaqSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    // editUserInfo: (state: any, { payload }: PayloadAction<any>) => {
    //   state.editUser = true;
    //   state.single_user_info = payload;
    // },
    // closeEditModal: (state: any, { payload }: PayloadAction<any>) => {
    //   state.editUser = false;
    // },
    // searchUsers: (state: any, { payload }: PayloadAction<any>) => {
    //   if (payload.length > 1) {
    //     state.temp_users = state.all_users.filter(
    //       (user: any) =>
    //         user.name.toLowerCase().includes(payload.toLowerCase()) ||
    //         user.email.toLowerCase().includes(payload.toLowerCase()),
    //     );
    //     state.users = state.temp_users.slice(0, 9);
    //   } else {
    //     state.users = state.all_users.slice(
    //       state.current_page * 9,
    //       9 * state.current_page + 9,
    //     );
    //   }
    // },
  },
  extraReducers: (builder) => {
    // GET = COURSES
    builder.addCase(getCourse.pending, (state) => {
      state.loading = true;
      state.instructor_success = false;
      state.course_detail_success = false;
    });
    builder.addCase(
      getCourse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;

        if (payload.data) {
          state.course_detail = payload?.data;
        }
        state.course_detail_success = true;
      },
    );
    builder.addCase(getCourse.rejected, (state) => {
      state.loading = false;
    });

    // GET INSTRUCTOR
    builder.addCase(getCourseInstructor.pending, (state) => {
      state.loading = true;
      state.instructor_success = false;
      // state.instructor_detail = {};
    });
    builder.addCase(
      getCourseInstructor.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.instructor_detail = payload;
        state.instructor_success = true;
      },
    );
    builder.addCase(getCourseInstructor.rejected, (state) => {
      state.loading = false;
      state.instructor_success = false;
    });

    // GET CHAPTER
    builder.addCase(getCoureChapter.pending, (state) => {
      state.loading = true;
      state.chapters_success = false;
      state.chapters = {};
    });
    builder.addCase(
      getCoureChapter.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.chapters = payload.items;
        state.chapters_success = true;
      },
    );
    builder.addCase(getCoureChapter.rejected, (state) => {
      state.loading = false;
    });

    // GET CHAPTER DETAILS
    builder.addCase(getChapterDetail.pending, (state) => {
      state.chapters_details_loading = true;
      state.chapters_detail_success = false;
    });
    builder.addCase(
      getChapterDetail.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.chapters_details_loading = false;
        state.chapters_details = payload.items;
        state.chapters_detail_success = true;
      },
    );
    builder.addCase(getChapterDetail.rejected, (state) => {
      state.chapters_details_loading = false;
    });

    // GET CHAPTER DETAILS
    builder.addCase(entollToCourse.pending, (state) => {
      state.enroll_loading = true;
      state.enroll_success = false;
    });
    builder.addCase(
      entollToCourse.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.enroll_loading = false;
        state.enroll_success = true;

        // state.chapters_details = payload.items;
        // state.chapters_detail_success = true;
      },
    );
    builder.addCase(entollToCourse.rejected, (state) => {
      state.enroll_loading = false;
      state.enroll_success = false;
    });
  },
});

// export const { setRecentSignupEmail, invokeLogout } = FaqSlice.actions;

export default FaqSlice.reducer;
