import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { userApplicationTracker } from "./ApplicationTrackerApi";

const initialState: any = {
  loading: {
    link: false,
  },
  req_success: {
    link: false,
  },
  redirect_url: "",
};

const appTracker = createSlice({
  name: "appTracker",
  initialState,
  reducers: {
    handleStateforRedirect: (state: any) => {
      state.req_success.link = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userApplicationTracker.pending, (state) => {
      state.loading.link = true;
      state.req_success.link = false;
    });
    builder.addCase(
      userApplicationTracker.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.link = false;
        state.req_success.link = true;
        state.redirect_url = payload.redirect_url;
      }
    );
    builder.addCase(userApplicationTracker.rejected, (state) => {
      state.loading.link = false;
    });
  },
});

export default appTracker.reducer;

export const { handleStateforRedirect } = appTracker.actions;
