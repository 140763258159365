import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getPayments,
  getPaymentById,
  updatePayment,
  deletePayment,
  addNewPayment,
} from "./PaymentApi";

const initialState: any = {
  loading: {
    get_all: false,
    get_by_id: false,
    delete: false,
    patch: false,
    create: false,
  },
  req_success: {
    get_all: false,
    get_by_id: false,
    delete: false,
    patch: false,
    create: false,
  },
  current_page: 0,
  all_payments: [],
  current_payment: [],
  temp_payments: [],
  payments: [],
  isEdit: false,
  isChanged: false,
  showModal: false,
};

const PaymentSlice = createSlice({
  name: "PaymentSlice",
  initialState,
  reducers: {
    editPaymentInfo: (state: any, { payload }: PayloadAction<any>) => {
      state.isEdit = true;
      state.current_payment = payload;
    },
    closeModal: (state: any) => {
      state.isEdit = false;
      state.showModal = false;
      state.current_payment = null;
    },
    setShowModal: (state: any) => {
      state.showModal = true;
    },
    paymentTablePaginate: (state: any, { payload }: PayloadAction<any>) => {
      if (payload === 0) {
        state.current_page = 0;
        state.payments = state.all_payments.slice(payload, 10);
      } else if (payload === 1) {
        state.current_page = 1;
        state.payments = state.all_payments.slice(payload * 10, 10 * 2);
        state.current_page = payload;
      } else {
        state.current_page = payload;
        state.payments = state.all_payments.slice(
          payload * 10,
          10 * payload + 10,
        );
      }
    },
    searchPaymentInTable: (state: any, { payload }: PayloadAction<any>) => {
      if (payload.length >= 1) {
        state.temp_payments = state.all_payments.filter((payment: any) =>
          payment.title.includes(payload?.toLowerCase()),
        );
        state.payments = state.temp_payments.slice(0, 10);
      } else {
        state.payments = state.all_payments.slice(
          state.current_page * 10,
          10 * state.current_page + 10,
        );
      }
    },
  },
  extraReducers: (builder) => {
    // GET All Payments
    builder.addCase(getPayments.pending, (state) => {
      state.loading.get_all = true;
      state.req_success.get_all = false;
    });
    builder.addCase(
      getPayments.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_all = false;
        state.req_success.get_all = true;
        state.all_payments = payload.data;
        state.payments = state.all_payments.slice(0, 10);
      },
    );
    builder.addCase(getPayments.rejected, (state) => {
      state.loading.get_all = false;
    });

    // GET a payment by Id
    builder.addCase(getPaymentById.pending, (state) => {
      state.loading.get_by_id = true;
      state.req_success.get_by_id = false;
    });
    builder.addCase(
      getPaymentById.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.get_by_id = false;
        state.req_success.get_by_id = true;
        state.current_payment = payload.data;
      },
    );
    builder.addCase(getPaymentById.rejected, (state) => {
      state.loading.get_by_id = false;
    });

    // Update a payment
    builder.addCase(updatePayment.pending, (state) => {
      state.loading.patch = true;
      state.req_success.patch = false;
    });
    builder.addCase(
      updatePayment.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.patch = false;
        state.req_success.patch = true;
        state.isEdit = false;
        state.all_payments = state.all_payments.map((pay: any) => {
          if (pay.id === payload?.id) {
            return payload;
          }
          return pay;
        });

        state.payments = state.payments.map((pay: any) => {
          if (pay.id === payload?.id) {
            return payload;
          }
          return pay;
        });
        state.isChanged = !state.isChanged;
      },
    );
    builder.addCase(updatePayment.rejected, (state) => {
      state.loading.patch = false;
    });

    // Delete a payment
    builder.addCase(deletePayment.pending, (state) => {
      state.loading.delete = true;
      state.req_success.delete = false;
    });
    builder.addCase(
      deletePayment.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.delete = false;
        state.req_success.delete = true;
        state.all_payments = state.all_payments.filter(
          (pay: any) => pay.id !== payload?.id,
        );
        if (state.current_page === 0) {
          state.payments = state.all_payments.slice(0, 10);
        } else {
          state.payments = state.all_payments.slice(
            state.current_page * 10,
            state.current_page * 10 + 10,
          );
        }
        state.isChanged = !state.isChanged;
        state.current_payment = null;
      },
    );
    builder.addCase(deletePayment.rejected, (state) => {
      state.loading.delete = false;
    });

    // Create a payment
    builder.addCase(addNewPayment.pending, (state) => {
      state.loading.create = true;
      state.req_success.create = false;
    });
    builder.addCase(
      addNewPayment.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading.create = false;
        state.req_success.create = true;

        if (payload) {
          state.all_payments = [payload, ...state.all_payments];

          if (state.current_page === 0) {
            state.payments = state.all_payments.slice(0, 10);
          } else {
            state.payments = state.all_payments.slice(
              state.current_page * 10,
              state.current_page * 10 + 10,
            );
          }
        }
        state.isChanged = !state.isChanged;
      },
    );
    builder.addCase(addNewPayment.rejected, (state) => {
      state.loading.create = false;
    });
  },
});
export const {
  paymentTablePaginate,
  searchPaymentInTable,
  editPaymentInfo,
  closeModal,
  setShowModal,
} = PaymentSlice.actions;
export default PaymentSlice.reducer;
