import React from "react";
import "./supportbox.scss";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";

const SupportBox = () => {
  const openLinkInNewTab = () => {
    window.open(
      "https://calendly.com/michelle-east-the-agile-coach/30min",
      "_blank"
    );
  };

  return (
    <div className="support__box__wrapper">
      <div className="support__box__container">
        <span>Do you require further assistance from our team?</span>
        <div className="support__button__width__handle">
          <PrimaryButton
            text="Support"
            width="support__btn"
            onClick={openLinkInNewTab}
          />
        </div>
      </div>
    </div>
  );
};

export default SupportBox;
