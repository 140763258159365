/* eslint-disable import/no-extraneous-dependencies */
import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ChevronsUp, LogOut, Moon, Sun, User } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import logo from "../../../../assets/images/logo.svg";
import { logoutUser } from "../../../../store/features/Auth/AuthApi";
import { ChangeThemeMode } from "../../../../store/features/ThemeSlice";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";

const NavForTrialUser = (props: any) => {
  const { navbg, normal, wmode, navBgColorHandle } = props;
  const [navBg, setNavBg] = useState(navbg);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [scrollButtonVisibility, setScrollButtonVisibility] = useState(false);
  const [hideScrollButton, setHideScrollButton] = useState(false);

  // ******User Side Notification ******
  const [show, setShow] = useState(false);

  // ****ChatBot******* */
  const [showChatBox, setShowChatBox] = useState(false);
  const [mobileShowChatBox, setMobileShowChatBox] = useState(false);

  useEffect(() => {
    if (showChatBox) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [showChatBox]);

  //* ********FOR MOBILE VIEW CHATBOX************ */
  useEffect(() => {
    if (mobileShowChatBox) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [mobileShowChatBox]);

  //* **** FOR PROFILE DETECT OUTSIDE CLICK**************** */
  const userProfileRef: any = useRef(null);
  const dropdownRef: any = useRef(null);

  const handleClickOutSide = (event: any) => {
    if (
      userProfileRef.current &&
      !userProfileRef.current.contains(event.target)
    ) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
    // return ()=>{
    //   document.addEventListener("mousedown",handleClickOutSide);
    // }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme") || "dark"
  );
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    document.body.classList.remove("light");
    document.body.classList.remove("dark");
    document.body.classList.add(themeMode);
  }, []);

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  useEffect(() => {
    if (authState.logout_success) {
      navigate("/");
    }
  }, [authState?.logout_success]);

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  // console.log(authState);

  const handleThemeMode = () => {
    if (themeMode === "light") {
      localStorage.setItem("theme", "dark");
      setThemeMode("dark");
      dispatch(ChangeThemeMode("dark"));
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    } else {
      localStorage.setItem("theme", "light");
      setThemeMode("light");
      dispatch(ChangeThemeMode("light"));
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
  };
  const changeNavBg = () => {
    if (window.scrollY > 1) {
      setNavBg(true);
    } else {
      setNavBg(false);
    }
    if (window.scrollY > 1) {
      setScrollButtonVisibility(true);
    } else {
      setScrollButtonVisibility(false);
    }
  };
  const scrolltoTop = () => {
    window.scrollTo(0, 0);
  };
  window.addEventListener("scroll", changeNavBg);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className={`nav__wrapper ${navBg && "active"}  ${normal && "normal"} ${
          navBgColorHandle && "handleNavBgColor"
        } ${openDrawer && "drawer"} `}
      >


        <div className={`left__section ${openDrawer && "showLogo"}`}>
         

          <Link to="/free-trial-course">
          <img src={logo} className="logo__image" alt="logo" />
          </Link>
        </div>

        <div className={`right__section ${openDrawer && "show__text"}`}>
          <button
            type="button"
            className={`theme__mode__button ${themeMode}`}
            onClick={handleThemeMode}
          >
            {themeMode === "light" && <Moon className="moon__icon" />}
            {themeMode === "dark" && <Sun className="sun__icon " />}
          </button>
          <div className="user__profile__container" ref={userProfileRef}>
            <div
              className="user__profile"
              aria-hidden
              onClick={() => setShow(!show)}
            >
              <div className="user__profile__picture">
                <div className="user__first__letter">
                  {" "}
                  {authState.tac_user && authState.tac_user?.name.charAt(0)}
                </div>
              </div>
            </div>
            {show && (
              <div ref={dropdownRef} className="dropdown">
                <div className="drop_item">
                  <Link to="/my-profile">
                    <User className="drop_icon" />
                    <span>My Account</span>
                  </Link>
                </div>
                {/* <div className="divider_item" /> */}
                <div className="drop_item gap__handle">
                  <LogOut className="drop_icon" />
                  <span aria-hidden onClick={handleLogout}>
                    Sign Out
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <button
        type="button"
        onClick={scrolltoTop}
        aria-label="hidden"
        className={`scroll__button ${
          !hideScrollButton && scrollButtonVisibility && "visible"
        }`}
      >
        <ChevronsUp className="up__icon" />
      </button>
    </>
  );
};

export default NavForTrialUser;
