import React, { useState } from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import VideoLinksHeader from "../../../components/AdminPanel/videolinks/videolinksHeader";
import TestimonialHeader from "../../../components/AdminPanel/Testimonial/TestimonialHeader";
import TestimonialBody from "../../../components/AdminPanel/Testimonial/TestimonialBody";

const Testimonial = () => {
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currId, setCurrId] = useState(0);
  return (
    <AdminPanelLayout>
      <BoxShadow>
        <div className="mt-7">
          <TestimonialHeader
            showModal={showModal}
            setShowModal={setShowModal}
            editModal={editModal}
            setEditModal={setEditModal}
            currId={currId}
            setCurrId={setCurrId}
          />
          <TestimonialBody
            showModal={showModal}
            setShowModal={setShowModal}
            editModal={editModal}
            setEditModal={setEditModal}
            currId={currId}
            setCurrId={setCurrId}
          />
        </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
};

export default Testimonial;
