import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateSiteSettings, getSiteSettings } from "./SiteApi";

const initialState: any = {
  loading: false,
  req_success: false,
  data: {},
};
// ADD about;
const siteSettingsSlice = createSlice({
  name: "siteSettingsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // update
    builder.addCase(updateSiteSettings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      updateSiteSettings.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.data = { ...payload.data };
      },
    );
    builder.addCase(updateSiteSettings.rejected, (state) => {
      state.loading = false;
    });

    // GET
    builder.addCase(getSiteSettings.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getSiteSettings.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.req_success = true;
        state.data = payload.data;
        state.loading = false;
      },
    );
    builder.addCase(getSiteSettings.rejected, (state) => {
      state.loading = false;
      state.req_success = false;
    });
  },
});
export default siteSettingsSlice.reducer;
