/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import { Edit2, Trash } from "react-feather";

import Table, { TableBody, TableHeading } from "../../../../shared/Table/Table";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { deleteWeeklyLearning } from "../../../../store/features/AdminPanel/WeeklyLearning/WeeklyLearningApi";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import { editWeekly } from "../../../../store/features/AdminPanel/WeeklyLearning/WeeklyLearningSlice";
import Empty from "../../../../shared/Empty/Empty";

const WeeklyLearningTable = () => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [activeId, setActiveId] = useState<string | number>("");

  const weeklyState: any = useAppSelector((store: RootStore) => {
    return store.WeeklySlice;
  });

  useEffect(() => {
    if (weeklyState.loading.delete) {
      setDeleteShowModal(false);
      setActiveId("");
    }
  }, [weeklyState.loading.delete]);

  const openDeleteModal = (id: number) => {
    setDeleteShowModal(true);
    setActiveId(id);
  };

  return (
    <div className="weekly__learning__table__section">
      <Table
        heading={
          <TableHeading>
            <th>Week Interval</th>
            <th>Title</th>
            <th>Description</th>

            <th className="hide__text">.</th>
          </TableHeading>
        }
      >
        <TableBody>
          {!weeklyState.loading.get &&
            weeklyState.req_success &&
            weeklyState.weeklyPlans.map((data: any) => {
              return (
                <tr key={data.id}>
                  <td className="weekly_interval">{data.week_interval}</td>
                  <td className="weekly_title">{data.title}</td>
                  <td
                    className="weekly_description"
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />

                  <td className="weekly__action__icons">
                    <span />
                    <div className="action__holder">
                      {" "}
                      <Edit2
                        className="w_edit__icon"
                        role="button"
                        aria-hidden
                        onClick={() => dispatch(editWeekly(data))}
                      />
                      <Trash
                        className="w_delete__icon"
                        role="button"
                        aria-hidden
                        onClick={() => openDeleteModal(data.id)}
                      />{" "}
                    </div>
                  </td>
                </tr>
              );
            })}
        </TableBody>
      </Table>
      {!weeklyState.loading.get &&
        weeklyState.req_success &&
        weeklyState.weeklyPlans.length === 0 && (
          <div className="mt-5 handle__empty__container__div">
            <Empty
              emptyText="It seems empty."
              emptyColorFulText="Add weekly learning"
              emptyText2="now."
              custombg={true}
            />
          </div>
        )}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={weeklyState.loading.delete}
        onClick={() => dispatch(deleteWeeklyLearning({ id: activeId }))}
      />
    </div>
  );
};

export default WeeklyLearningTable;
