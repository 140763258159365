import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../../apiConfigs/urlConfig";
import { renderDynamicUrlFromState } from "../../../../../helpers/utils/urlHelper";

export const apiDefaultPropmts = createAsyncThunk(
  "apiDefaultPropmts",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);

      const res = await axiosInstance.get(`${dynamic_url}/ai-default-prompts`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const filterQuestion = createAsyncThunk(
  "filterQuestion",
  async (data: any, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/ai-chat`, data);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchConversation = createAsyncThunk(
  "fetchConversation",
  async ({ id }: { id: string }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/conversation/${id}`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRecentSearch = createAsyncThunk(
  "getRecentSearch",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/ai-searches`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const clearConversation = createAsyncThunk(
  "clearConversation",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/clear-searches`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const stopGenerating = createAsyncThunk(
  "stopGenerating",
  async (
    data: { question_id: string; answer: string },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/ai-update-answer`,
        data
      );
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
