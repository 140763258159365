/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogOut, User } from "react-feather";
import userImage from "../../../assets/icons/user.png";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { roles } from "../../../helpers/utils/Constants/roles";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";
import { ROUTES } from "../../../helpers/utils/Constants/route";
import { logoutUser } from "../../../store/features/Auth/AuthApi";
import "./profileandlogout.scss";

const ProfileAndLogout = () => {
  //* **** FOR Admin PROFILE DETECT OUTSIDE CLICK**************** */
  const adminProfileRef: any = useRef(null);
  const dropdownRef: any = useRef(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });

  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const handleClickOutSide = (event: any) => {
    if (
      adminProfileRef.current &&
      !adminProfileRef.current.contains(event.target)
    ) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);

  const handleProfileNavigation = () => {
    if (
      configState.configs.membership_enabled === Domain.TAC &&
      authState.tac_user.role === roles.PRODUCT_MANAGER_STUDENT
    ) {
      navigate(ROUTES.PRODUCT_MANAGER_PROFILE);
    }
    if (configState.configs.membership_enabled === Domain.TAA) {
      navigate(ROUTES.PROFILE);
    }

    if (
      configState.configs.membership_enabled === Domain.TAC &&
      authState.tac_user.role === roles.SYSTEM_ADMIN
    ) {
      navigate(ROUTES.PROFILE);
    }

    if (
      (configState.configs.membership_enabled === Domain.TAC &&
        authState.tac_user.role === roles.SYSTEM_ADMIN) ||
      authState.tac_user.role === roles.OWNER
    ) {
      navigate(`${ROUTES.TENANT_OWNER_PROFILE}/profile`);
    }
    if (
      (configState.configs.membership_enabled === Domain.TAC &&
        authState.tac_user.role === roles.SYSTEM_ADMIN) ||
        authState.tac_user.role === roles.SUB_ADMIN ||
      authState.tac_user.role === roles.TENANT_ADMIN ||
      authState.tac_user.role === roles.TENANT_STUDENT
    ) {
      navigate(ROUTES.PROFILE);
    }
    if (
      configState.configs.membership_enabled === Domain.TAC &&
      authState.tac_user?.role === roles.USER
    ) {
      navigate(`${ROUTES.MY_PROFILE}/profile`);
    }
    // if (
    //   configState.configs.membership_enabled === Domain.TAC &&
    //   authState.tac_user.role === roles.TEAMMEMBERSHIP_OWNER
    // ) {
    //   // navigate(ROUTES.PROFILE);
    // }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (authState.logout_success) {
      navigate("/");
    }
  }, [authState?.logout_success]);
  return (
    <div className="profile__and__logout_section" ref={adminProfileRef}>
      <img
        src={authState.tac_user?.avatar_url ?? userImage}
        className="avatar_img"
        alt="."
        onClick={() => setShow(!show)}
        role="button"
        aria-hidden
      />

      {show && (
        <div className="dropdown" ref={dropdownRef}>
          {authState.tac_user?.role !== roles.MENTOR && (
            <>
              <div
                className="drop_item"
                onClick={handleProfileNavigation}
                role="button"
                aria-hidden
              >
                <User className="drop_icon" />
                <span>Profile</span>
              </div>
              <div className="divider_item" />
            </>
          )}
          <div
            className="drop_item"
            onClick={handleLogout}
            role="button"
            aria-hidden
          >
            <LogOut className="drop_icon" />
            <span>Logout</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileAndLogout;
