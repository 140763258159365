/* eslint-disable import/no-extraneous-dependencies */

import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";

import "./index.scss";
import { ChangeEvent, useMemo } from "react";
import { RootStore, useAppSelector } from "../../../../../store/store";
import ErrorText from "../../../../../shared/Inputs/ErrorText/ErrorText";

const StripeCard = (props: any) => {
  const { setCheckoutError, checkoutError, setCardIsEmpty } = props;
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const options = useMemo(() => {
    return {
      style: {
        hidePostalCode: true,
        base: {
          fontSize: "16px",
          color: themeState.themeMode === "dark" ? "#BABABA " : "#444444",
          letterSpacing: "0.025em",
          iconColor: themeState.themeMode === "dark" ? "#BABABA " : "#444444",
          borderRadius: "4px",

          fontFamily: '"Inter",  sans-serif',
          "::placeholder": {
            color: themeState.themeMode === "dark" ? "#BABABA " : "#444444",
          },
        },
        invalid: {
          color: "#F04438",
          iconColor: "#F04438",
        },
      },
      hidePostalCode: true,
    };
  }, [themeState.themeMode]);

  const handleValidation = (e: StripeCardElementChangeEvent) => {
    if (!e.complete) {
      if (e.error?.message) {
        setCheckoutError(e.error?.message);
      } else {
        setCheckoutError("your card number is invalid");
      }
      if (e.empty) {
        setCheckoutError("");
      }
    } else {
      setCheckoutError("");
    }
    setCardIsEmpty(false);
  };

  return (
    <div className="card__emelent__section">
      <CardElement
        options={options}
        className="stripe__card"
        onChange={(e) => handleValidation(e)}
      />{" "}
      <ErrorText error={checkoutError} />
    </div>
  );
};

export default StripeCard;
