import { useEffect, useState } from "react";
import Table, { TableBody, TableHeading } from "../../../../shared/Table/Table";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import "./index.scss";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import PaginateButton from "../../../../shared/PaginateButton/PaginateButton";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  getCompanies,
  getCompanyById,
  resendTenantInvitation,
} from "../../../../store/features/AdminPanel/TenantTeams/TenantTeamApi";
import {
  searchTenantInTable,
  tenantTablePaginate,
} from "../../../../store/features/AdminPanel/TenantTeams/TenantTeamSlice";
import TenantTeamHeader from "../TenantTeamHeader/TenantTeamHeader";
import defaultCompanyImage from "../../../../assets/images/defaultCompanyImg.svg";
import Active from "../../../../shared/Indicatos/Active";
import Inactive from "../../../../shared/Indicatos/Inactive";
import Empty from "../../../../shared/Empty/Empty";

export const TenantTeamTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [resendId, setResendId] = useState<any>(null);
  const handleModal = () => {};

  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();
  const tenantTeamState: any = useAppSelector((store: RootStore) => {
    return store.TenantTeamSlice;
  });
  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    dispatch(searchTenantInTable(searchText));
  }, [searchText]);

  const handlePagination = (num: number) => {
    dispatch(tenantTablePaginate(num));
  };

  const getCompanyInformation = (id: string) => {
    setShowModal(true);
    dispatch(getCompanyById({ id }));
  };

  const handleResendInvitation = (id: string) => {
    setResendId(id);
    dispatch(resendTenantInvitation({ id })).then(() => setResendId(null));
  };

  return (
    <div>
      <TenantTeamHeader searchText={searchText} setSearchText={setSearchText} />
      <div className="tenant__table__wrapper">
        <div className="user__table__section globl__margin">
          <Table
            heading={
              <TableHeading>
                <th>Company Name</th>
                <th>Joined Date</th>
                <th>Payment plan</th>
                <th>Expiry Date</th>
                <th>Status</th>
                <th className="teams__actionsss">Action </th>
              </TableHeading>
            }
          >
            <TableBody>
              {!tenantTeamState.loading.get_all &&
                tenantTeamState.req_success.get_all &&
                tenantTeamState.tenants.map((ten: any) => {
                  return (
                    <tr key={ten.id}>
                      <td> {ten.name}</td>
                      <td>{ten.joined_date} </td>
                      <td> {ten.payment_plan}</td>
                      <td>{ten.expiry_date} </td>
                      <td>
                        {ten.active_status ? (
                          <Active text="Active" />
                        ) : (
                          <Inactive text="Inactive" />
                        )}
                      </td>

                      <td className="button__td">
                        {ten.active_status ? (
                          <button
                            type="button"
                            className="view__details__button"
                            onClick={() => getCompanyInformation(ten.id)}
                          >
                            View Details
                          </button>
                        ) : (
                          <button
                            type="button"
                            disabled={tenantTeamState.loading.invitation}
                            className="view__details__button"
                            onClick={() => handleResendInvitation(ten.id)}
                          >
                            {tenantTeamState.loading.invitation &&
                            ten.id === resendId ? (
                              <div className="spinner mr-1" />
                            ) : (
                              "Resend Invitation"
                            )}
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </TableBody>
            <Modal
              show={showModal}
              setShow={setShowModal}
              onClick={handleModal}
            >
              <ModalHeader title="Owner Details" />
              <ModalBody>
                {!tenantTeamState.loading.get_by_id &&
                  tenantTeamState.req_success.get_by_id && (
                    <div className="tenant_team_modal">
                      <div className="detail__section">
                        <div className="owner__image__section">
                          <img
                            src={
                              tenantTeamState.current_tenant.owner_avatar ??
                              defaultCompanyImage
                            }
                            alt=""
                            className="tenant_ownerImage"
                          />
                        </div>
                        <div className="name__section">
                          <span className="name">
                            {tenantTeamState.current_tenant.owner_name}
                          </span>
                          <span className="email">
                            {tenantTeamState.current_tenant.owner_email}
                          </span>
                        </div>
                      </div>
                      <span className="detail__title"> COMPANY DETAILS</span>
                      <div className="modal__divider" />
                      <div className="info__section">
                        <div className="single__section">
                          <div className="info">
                            <span className="title">Company Name : </span>
                            <span className="content ">
                              {tenantTeamState.current_tenant.company_name}
                            </span>
                          </div>
                          <div className="info">
                            <span className="title">Payment Plan : </span>
                            <span className="content ">
                              {tenantTeamState.current_tenant.payment_plan}
                            </span>
                          </div>
                          <div className="info">
                            <span className="title">Total Payment : </span>
                            <span className="content ">
                              $
                              {tenantTeamState.current_tenant.total_payment ||
                                0}
                            </span>
                          </div>
                        </div>
                        <div className="single__section padding">
                          <div className="info">
                            <span className="title">No of users : </span>
                            <span className="content ">
                              {tenantTeamState.current_tenant.total_users}
                            </span>
                          </div>
                          <div className="info">
                            <span className="title">
                              Trial Period or Paid :{" "}
                            </span>
                            <span className="content ">
                              {tenantTeamState.current_tenant.trial_period}
                            </span>
                          </div>
                          <div className="info">
                            <span className="title">Expiry Date : </span>
                            <span className="content ">
                              {tenantTeamState.current_tenant.expiry_date}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="extra__inf0">
                        <span className="title">Company URL/subdomain : </span>
                        <span className="content ">
                          <a
                            href={`${tenantTeamState.current_tenant.company_url}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {tenantTeamState.current_tenant.company_url}
                          </a>
                        </span>
                      </div>
                    </div>
                  )}
              </ModalBody>
            </Modal>
          </Table>
          {!tenantTeamState.loading.get_all &&
            tenantTeamState.tenants.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
         )}
        </div>
        {/* <Empty emptyText="It seems empty" custombg/> */}
        <div className="pagination__section">
          <PaginateButton
            paginate={handlePagination}
            totalRows={tenantTeamState.all_tenants.length}
          />
        </div>
      </div>
    </div>
  );
};

