/* eslint-disable func-names */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { Card } from "../../../../shared/Card/Card";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import {
  addAssignment,
  getMembersAssignments,
} from "../../../../store/features/Tenant/Orgination/Member/MemberApi";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import AssignCourses from "../AssignCourses/AssignCourses";
import "./index.scss";
import { intersect } from "../../../../helpers/utils/MathHelper";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import MemberDetailHeader from "./MemberDetailHeader";

const AddAssisment = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const groupState: any = useAppSelector((store: RootStore) => {
    return store.GroupSlice;
  });
  // logic for radio buttons
  const [singleDeadline, setSingleDeadline] = useState(false);
  const [individualDeadline, setIndividualDeadline] = useState(false);
  const indvCourse: any = [];
  const indvCourseWithDeadline: any = [];
  const [checkedLearningPathId, setCheckedLearningPathId] = useState<any>([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const memberState: any = useAppSelector((store: RootStore) => {
    return store.MemberSlice;
  });
  const activesingleDeadline = () => {
    if (singleDeadline) {
      setSingleDeadline(false);
    } else {
      setSingleDeadline(true);
      setIndividualDeadline(false);
    }
  };
  const activeIndividualDeadline = () => {
    if (individualDeadline) {
      setIndividualDeadline(false);
    } else {
      setSingleDeadline(false);
      setIndividualDeadline(true);
    }
  };
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm<any>();
  useEffect(() => {
    dispatch(getMembersAssignments({ id: Number(params.id) }));
  }, []);
  const onSubmit = (values: any) => {
    const data: any = {};
    if (
      checkedLearningPathId.length > 0 ||
      indvCourse.length > 0 ||
      indvCourseWithDeadline.length > 0 ||
      selectedCourses.length > 0
    ) {
      //
    } else {
      toast.error("Either Learning Path or Course is required");
      return;
    }
    if (singleDeadline) {
      data.single_deadline = values.single_deadline;
    }
    if (!individualDeadline) {
      const obj: any = {};
      checkedLearningPathId.forEach((element: any) => {
        const arr: any = [];
        selectedCourses.forEach((course: any) => {
          if (course.learning_path_ids.includes(element)) {
            arr.push(course.id);
          }
        });
        obj[element] = arr;
      });
      data.learning_paths = obj;

      selectedCourses.forEach((course: any) => {
        const res = intersect(course.learning_path_ids, checkedLearningPathId);
        if (res.length === 0) {
          indvCourse.push(course.id);
        }
      });
      data.individual_courses = indvCourse;
    }

    if (individualDeadline) {
      const obj: any = [];
      checkedLearningPathId.forEach((element: any) => {
        const arr: any = [];
        selectedCourses.forEach((course: any) => {
          if (course.learning_path_ids.includes(element)) {
            arr.push(course.id);
          }
        });
        obj.push({ [element]: arr });
      });
      data.learning_path_with_deadlines = obj;

      const generateInsideData = (value: any, val: any) => {
        const keys: any = Object.keys(val);
        const insideData: any = {};
        val.map((elem: any, index: number) => {
          return (insideData[elem] = values[elem]);
        });

        return insideData;
      };

      const result = data.learning_path_with_deadlines.reduce(function (
        items: any,
        item: any,
      ) {
        const fixed: any = {};
        const keys: any = Object.keys(item);

        keys.forEach(function (key: any) {
          return (fixed[key] = generateInsideData(item, item[key]));
        });
        items.push(fixed);
        return items;
      },
      []);
      data.learning_path_with_deadlines = Object.assign({}, ...result);

      selectedCourses.forEach((course: any) => {
        const res = intersect(course.learning_path_ids, checkedLearningPathId);
        if (res.length === 0) {
          for (const key in values) {
            if (key == course.id) {
              indvCourseWithDeadline.push({ [course.id]: values[key] });
            }
          }
        }
      });
      data.individual_course_deadlines = Object.assign(
        {},
        ...indvCourseWithDeadline,
      );
    }
    dispatch(addAssignment({ data: data, id: Number(params.id) }));
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  return (
    <form
      className="member__assignment__wapper"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      {!memberState.loading.get_assignment &&
        memberState.req_success.get_assignment && (
          <MemberDetailHeader data={memberState.single_member_data} />
        )}
      <div className="mt-2">
        <Card>
          <AssignCourses
            checkedLearningPathId={checkedLearningPathId}
            setCheckedLearningPathId={setCheckedLearningPathId}
            groupState={groupState}
            setSelectedCourses={setSelectedCourses}
            memberState={memberState}
            addAssignment
          />

          <div className="date__selection__wrapper">
            <span className="inivte__member__page__topography uppercase mb-3">
              set deadline
            </span>
            <div className="single__deadline__container">
              <button
                type="button"
                className="radio"
                onClick={activesingleDeadline}
              >
                <div className="radio__icon">
                  {singleDeadline && <span className="tick">.</span>}
                </div>{" "}
                Set a single deadline
              </button>
              {singleDeadline && (
                <div className="single__date__wrapper">
                  <div className="date__section">
                    <div className="calendar__width">
                      <TextField
                        type="date"
                        name="single_deadline"
                        placeholder="mm/dd/yyyy"
                        register={register}
                        min={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="multiple__deadline__container">
              <button
                type="button"
                className="radio"
                onClick={activeIndividualDeadline}
              >
                <div className="radio__icon">
                  {individualDeadline && <span className="tick">.</span>}
                </div>{" "}
                Set individual deadlines
              </button>
              {individualDeadline && (
                <div className="multiple__date__wrapper">
                  {selectedCourses.length > 0 &&
                    selectedCourses.map((course: any) => {
                      return (
                        <>
                          <div className="date__section">
                            <span className="date__label">
                              {" "}
                              {course.name} (Course)
                            </span>
                            <div className="calendar__width">
                              <TextField
                                type="date"
                                placeholder="mm/dd/yyyy"
                                name={course.id.toString()}
                                register={register}
                                min={new Date().toISOString().split("T")[0]}
                                // error={errors?.path.name}
                              />
                            </div>
                          </div>
                          <div className="date__divider" />
                        </>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          <div className="button__top__divider" />
          <div className="button__section">
            <PrimaryButton
              text="Add assignment"
              type="submit"
              loading={memberState.loading.add_assignment}
            />
          </div>
        </Card>
      </div>
    </form>
  );
};

export default AddAssisment;
