import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";

import ViewFullCourse from "../../../components/UserDashboard/ViewCourse/ViewFullCourse";

const MyProgressCourse = () => {
  return (
    <AdminPanelLayout>
      <ViewFullCourse />
    </AdminPanelLayout>
  );
};

export default MyProgressCourse;
