import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./seoheadeer.scss";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";
import Modal, { ModalBody, ModalHeader } from "../../../../shared/Modals/Modal";
import TextField from "../../../../shared/Inputs/TextField/TextField";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";

const SEOHeader = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const validationSchema = yup.object().shape({
    title: yup.string().required(REQUIRED_VALIDATION("Title")),
    urlPath: yup.string().required(REQUIRED_VALIDATION("Url Path")),
    SeoKeywords: yup.string().required(REQUIRED_VALIDATION("Seo Keywords")),
    SeoDescription: yup
      .string()
      .required(REQUIRED_VALIDATION("Seo Description")),

    order: yup.string().required(REQUIRED_VALIDATION("Order")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>(formOptions);
  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>SEO Management</TableHeadingTitle>
      </div>
      <div className="right__section">
        {/* <div className="search">
          <SearchBar />
        </div>
        <div className="button__witdh">
          <PrimaryButton
            text="Add New"
            size="sm"
            type="button"
            loading={false}
            onClick={() => setShowModal(true)}
          />
        </div> */}
      </div>
      <Modal show={showModal} setShow={setShowModal}>
        <ModalHeader title="SEO" />
        <ModalBody>
          <form className="login__form__container  ">
            <div className="form_row">
              <TextField
                type="text"
                label="Title"
                name="title"
                register={register}
                error={errors?.title}
              />
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="Title"
                name="title"
                register={register}
                error={errors?.title}
              />
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="Url Path"
                name="urlPath"
                register={register}
                error={errors?.urlPath}
              />
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="Seo Keywords"
                name="SeoKeywords"
                register={register}
                error={errors?.SeoKeywords}
              />
            </div>
            <div className="form_row">
              <TextField
                type="text"
                label="Question"
                name="question"
                register={register}
                error={errors?.question}
              />
            </div>

            <div className="form_row">
              <TextArea
                type="text"
                label="Seo Description"
                name="SeoDescription"
                register={register}
                error={errors?.SeoDescription}
              />
            </div>

            {/* <div className="button__witdh mt-1">
              <PrimaryButton text="Add" type="submit" loading={false} />
            </div> */}
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SEOHeader;
