/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import { ChevronDown, ChevronLeft, ChevronUp } from "react-feather";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import "./index.scss";
import Avatar from "../../../shared/Avatar/Avatar";
import {
  getCourseDetailForStudent
} from "../../../store/features/Tenant/User/Courses/CoursesApi";
import { enrollToThinkificCourse, loginToThinkificAndRedirect } from "../../../store/features/Thinkific/ThikificApi";
import {
  useAppSelector,
  RootStore,
  useAppDispatch,
} from "../../../store/store";
import TenantLoadingScreen from "../../Auth/TenantLoading/TenantLoadingScreen";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
// import { THINKFIC_BASE_URL } from "../../../apiConfigs/urlConfig";

const UserCourseDetail = () => {
  const dispatch = useAppDispatch();
  const [slug, setSlug] = useState("");
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const course: any = useAppSelector((store: RootStore) => {
    return store.TenantUserCourseSlice;
  });
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const startLearning = (course: any) => {
    setSlug(course.slug);
    setLoading(true);
    dispatch(enrollToThinkificCourse({ courseId: course.course_id })).then((res) => {
      dispatch(
        loginToThinkificAndRedirect({
          slug: course.slug,
        })
      ).then((res: any) => {
        if (res.payload) {
          console.log(res.payload.url);
          setLoading(false);
          window.open(res.payload.url, "_blank");
        }
      });
    });
  };

  useEffect(() => {
    if (course.enroll_to_course) {
      window.location.href = `${themeState.configs.site_url}/login/${authState.tac_user.email}/${slug}/${authState.tenant}`;
    }
  }, [course.enroll_to_course]);

  useEffect(() => {
    dispatch(getCourseDetailForStudent({ courseId: Number(params.id) }));
  }, []);

  return (
    <div>
      {!course.loading && course.req_success ? (
        <div className="UserCourseDetail__wrapper">
          <div className="course__detail__section">
            <div className="header__section">
              <Link to="/organization-user-courses">
                <ChevronLeft className="back__icon" />
              </Link>
              <span className="header__title">Lessons in this course </span>
            </div>
            <div className="custom__according__section">
              <Accordion allowZeroExpanded>
                {!!course?.chapters &&
                  !!course?.chapters.length &&
                  course.chapters.map((chap: any) => {
                    return (
                      <AccordionItem key={chap.id}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <div className="accordian__content__wrapper">
                            <span
                              className="test"
                            >
                              {chap.name}
                            </span>
                            <ChevronDown/>
                            </div>
                           
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                          {chap.contents &&
                            chap.contents.length &&
                            chap.contents.map((cont: any) => {
                              return (
                                <div key={cont} className="content__list">
                                  <span className="dot" />
                                  <span key={cont} className="course__unit">
                                    {cont}
                                  </span>
                                </div>
                              );
                            })}
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
              </Accordion>
            </div>
          </div>
          <div className="course__instructor__section">
            <div className="card__section">
              <div className="row">
                {!course.currentCourse.completed &&
                  course.currentCourse.percentage_completed === 0 && (
                    <PrimaryButton
                      text="Start Learning"
                      loading={loading && course.currentCourse.slug === slug}
                      onClick={() => startLearning(course.currentCourse)}
                    />
                  )}

                {!course.currentCourse.completed &&
                  course.currentCourse.percentage_completed > 0 && (
                    <PrimaryButton
                      text="Resume Course"
                      loading={loading && course.currentCourse.slug === slug}
                      onClick={() => startLearning(course.currentCourse)}
                    />
                  )}

                {!!course.currentCourse.completed && (
                  <PrimaryButton
                    text="Course Completed"
                    loading={loading && course.currentCourse.slug === slug}
                    onClick={() => startLearning(course.currentCourse)}
                  />
                )}
              </div>

              <span className="buldle__tile">{course.currentCourse.name}</span>

              <div className="row">
                <span className="buldle__duration_tile">
                  Duration {course.currentCourse.custom_duration}
                </span>
                {course.currentCourse.deadline && (
                  <span className="buldle__deadline_tile">
                    Deadline {course.currentCourse.deadline}
                  </span>
                )}
              </div>

              <ProgressBar
                completed={course.currentCourse.percentage_completed || 0}
                height="3px"
                customLabel="."
                baseBgColor="var( --primary-progress-bar-bg)"
                bgColor="var( --secondary-progress-bar-bg)"
                labelSize="0"
              />
              <span className="completed__count">
                {course.currentCourse.percentage_completed}%
              </span>
              <p className="description">{course.currentCourse.description}</p>

              <div className="detail__and__instructor__divider" />

              <div className="instructor__profile">
                <div className="pic">
                  <Avatar
                    imageUrl={
                      course.instructor.avatar_url ||
                      "https://www.w3schools.com/w3css/img_avatar3.png"
                    }
                  />
                </div>
                <div className="info">
                  <span className="name">
                    {course.instructor.first_name} {course.instructor.last_name}
                  </span>
                  <span className="occupation"> {course.instructor.title}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TenantLoadingScreen message="" />
      )}
    </div>
  );
};

export default UserCourseDetail;
