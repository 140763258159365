/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import Cropper from "react-cropper";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cropperjs/dist/cropper.css";
import "./profilePictrue.scss";
import { useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../shared/Modals/Modal";
import {
  PrimaryButton,
  SmokeButton,
  WhiteButton,
} from "../../../shared/Buttons/Buttons";
import {
  RootState,
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { getFileExtension } from "../../../helpers/utils/FileHelper";
import {
  removeAvatar,
  updateAvatar,
} from "../../../store/features/Auth/AuthApi";
import userImage from "../../../assets/icons/user.png";

const TopDivider = () => {
  return <div className="top__divider" />;
};

export const ProfilePicture: React.FC = () => {
  const authSlice: any = useAppSelector((store: RootState) => {
    return store.AuthSlice;
  });
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<any>("");
  const [imageExt, setImageExt] = useState("");
  const [showModal3, setShowModal3] = useState(false);
  const [cropData, setCropData] = useState("");
  const [cropper, setCropper] = useState<any>();

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };
  const handleClick = (event: any) => {
    setShowModal3(true);
  };
  const onDrop = useCallback((files: any) => {
    setImage(files[0]);
    const extension = getFileExtension(files[0].name);
    setImageExt(extension);
    const result = URL.createObjectURL(files[0]);
    setImage(result);
  }, []);

  const clearImage = () => {
    setImage("");
    setCropData("");
    setCropper("");
    setImageExt("");
  };
  const saveProfilePicture = () => {
    if (!cropData) {
      toast.info("Please Click Crop photo before save");
      return;
    }
    const data = {
      extension: imageExt,
      avatar_url: cropData,
    };
    dispatch(updateAvatar({ data: data }));
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [
        ".png",
        ".PNG",
        ".jpg",
        ".JPG",
        ".JPEG",
        ".jpeg",
        ".svg",
        ".bmp",
      ],
    },
  });
  return (
    <div className="profile__picture__section">
      <div className="image__section">
        <img
          src={cropData || (authSlice.tac_user?.avatar_url ?? userImage)}
          alt="a"
        />
      </div>
      <div className="image_function_section">
        <span className="upload" onClick={handleClick}>
          Upload new avatar
        </span>
        <span className="remove" onClick={() => dispatch(removeAvatar())}>
          Remove
        </span>
      </div>

      <div className="cropper__section">
        <Modal show={showModal3} setShow={setShowModal3} onClick={clearImage}>
          <ModalHeader title="Upload Photo" options={<TopDivider />} />

          <ModalBody>
            {!image && (
              <div className="uploader__card " {...getRootProps()}>
                <div className="circle_bg">
                  {/* <img
                    src={
                      themeState.themeMode === "dark"
                        ? blackUploadIcon
                        : whiteUploadIcon
                    }
                    alt="upload"
                  /> */}
                  <Icon icon="ci:file-upload" className="upload_icon" />
                </div>

                <input {...getInputProps()} id="upl" />

                <span className="upload_description mt-1">
                  Drag and drop files, or browse
                </span>
              </div>
            )}
            {image && (
              <Cropper
                style={{ height: 400, width: "100%" }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive
                autoCropArea={0.5}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides
              />
            )}
            <div className="top__secction">
              <div className="bottom__diver" />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="footer__section">
              <div />
              <div className="right__section">
                {!authSlice.avatar_loading && (
                  <div className="crop__button">
                    <SmokeButton
                      text="Crop photo"
                      size="sm"
                      type="button"
                      loading={false}
                      onClick={getCropData}
                    />
                  </div>
                )}
                <div className="save__button">
                  <PrimaryButton
                    text="Save"
                    size="sm"
                    type="button"
                    loading={authSlice.avatar_loading}
                    onClick={saveProfilePicture}
                  />
                </div>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default ProfilePicture;
