import React from "react";
import "./index.scss";

const Inactive = (props:any) => {
    const {text} =props;
  return (
    <div className="status__inactive">
      <div className="inactive__dot">.</div>
      <span className="inactive__text">{text}</span>
    </div>
  );
};

export default Inactive;
