import React from "react";
import Nav from "../../../components/NewAcademicUser/Dashboard/Nav/Nav";
import Conversation from "../../../components/NewAcademicUser/AiAssist/Conversation/Conversation";
import "./index.scss";
import { RootStore, useAppSelector } from "../../../store/store";
import TacAiAssistNav from "../../../components/UserDashboard/TacAiAssistNav/TacAiAssistNav";
import { Domain } from "../../../helpers/utils/DomainSwitcher/domains";

const AiConservation = () => {
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <>
      {configState.configs.membership_enabled === Domain.TAC && (
        <div className="Ai__conversation__wrapper__tac">
          <TacAiAssistNav />
          <div className="mt-6 tac__conversation__wrapper">
            <Conversation />
          </div>
        </div>
      )}

      {configState.configs.membership_enabled === Domain.TAA && (
        <div className="ai__assist__conatiner">
          <div className="Ai__conversation__wrapper">
            <Nav normal navBgColorHandle />
            <Conversation />
          </div>
        </div>
      )}
    </>
  );
};

export default AiConservation;
