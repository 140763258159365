import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  changePassword,
  defaultPasswordChange,
  forgetPassword,
  loadUser,
  logoutUser,
  memberSignup,
  removeAvatar,
  resetPassword,
  updateAvatar,
  updateUsername,
  userSignIn,
  validateMemberSignup,
  validateToken,
} from "./AuthApi";

const initialState: any = {
  loading: false,
  avatar_loading: false,
  forget_password_loading: false,
  reset_password_loading: "null",
  token_loading: false,
  logout_loading: false,
  loaduser_loading: false,
  update_user_loading: false,
  req_success: false,
  load_user_success: false,
  logout_success: false,
  pass_req_success: false,
  default_pass_req_success: false,
  token_success: false,
  forget_password_success: false,
  reset_password_success: false,
  update_user_req_success: false,
  email_validated: false,
  recent_signup_email: null,
  authenticated: false,
  load_user: null,
  tac_user: null,
  access_token: null,
  login_fail: false,
  member_verify_token_loading: false,
  member_verify_token_success: false,
  member_signup_success: false,
  member_signup_loading: false,
  tenant: "",
  tenant_pay_details: {},
  theme_name: "",
};

const AuthSlice = createSlice({
  name: "userSignUp",
  initialState,
  reducers: {
    setRecentSignupEmail: (state: any, { payload }: PayloadAction<string>) => {
      state.recent_signup_email = payload;
    },
    setVerifiedDomain: (state: any) => {
      state.verified_domain = true;
    },
    activatePaidPlanForUser: (state: any) => {
      state.tac_user.plan = "paid";
    },
    setThemeName: (state: any, { payload }: PayloadAction<string>) => {
      state.theme_name = payload;
    },
    deActivatePaidPlanForUser: (state: any) => {
      state.tac_user.plan = "free";
    },
    googleUserSignIn: (state: any, { payload }: PayloadAction<any>) => {
      localStorage.setItem("tac_user", JSON.stringify(payload.user));
      localStorage.setItem("access_token", payload.access_token);
      state.loading = false;
      state.logout_success = false;
      state.req_success = true;
      state.pass_req_success = false;

      // state.authenticated = true;
      state.tac_user = payload.user;
      state.access_token = payload.access_token;
    },
    populateUserFromGoogleSignIn: (
      state: any,
      { payload }: PayloadAction<any>
    ) => {
      localStorage.setItem("tac_user", JSON.stringify(payload.user));
      localStorage.setItem("access_token", payload.access_token);
      state.loading = false;
      state.logout_success = false;
      state.req_success = true;
      state.pass_req_success = false;

      // state.authenticated = true;
      state.tac_user = payload.user;
      state.access_token = payload.access_token;
    },
  },
  extraReducers: (builder) => {
    // LOAD USER
    builder.addCase(loadUser.pending, (state) => {
      state.loaduser_loading = true;
      state.load_user_success = false;
      state.login_fail = false;
    });
    builder.addCase(
      loadUser.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        const token = localStorage.getItem("access_token");
        state.login_fail = false;

        state.loaduser_loading = false;
        state.load_user_success = true;
        state.logout_success = false;
        state.recent_signup_email = payload?.data?.data?.email;
        state.authenticated = true;
        state.tenant = payload?.data?.tenant;
        state.tenant_pay_details = {
          billing_type: payload.data.billing_type,
          seat_count: payload.data.seat_count,
          status: payload.data.tenant_status,
          days_left: payload.data?.days_left ?? null,
        };
        state.tac_user = payload?.data.user;
        state.access_token = token;
      }
    );
    builder.addCase(loadUser.rejected, (state) => {
      state.loaduser_loading = false;
      state.login_fail = true;
    });

    // update AVATAR
    builder.addCase(updateAvatar.pending, (state) => {
      state.avatar_loading = true;
    });
    builder.addCase(
      updateAvatar.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.avatar_loading = false;
        state.tac_user = { ...state.tac_user, avatar_url: payload.avatar };
      }
    );
    builder.addCase(updateAvatar.rejected, (state) => {
      state.avatar_loading = false;
    });

    // remove AVATAR
    builder.addCase(removeAvatar.pending, (state) => {});
    builder.addCase(
      removeAvatar.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.tac_user = { ...state.tac_user, avatar_url: null };
      }
    );
    builder.addCase(removeAvatar.rejected, (state) => {});

    // SIGNIN
    builder.addCase(userSignIn.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      userSignIn.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.theme_name = payload.theme_name;
        localStorage.setItem("officialThemeName", payload.theme_name);
        localStorage.setItem("tac_user", JSON.stringify(payload.user));
        localStorage.setItem("access_token", payload.access_token);
        state.loading = false;
        state.logout_success = false;
        state.req_success = true;
        state.pass_req_success = false;
        // state.authenticated = true;
        state.tac_user = payload.user;
        state.access_token = payload.access_token;
      }
    );
    builder.addCase(userSignIn.rejected, (state) => {
      state.loading = false;
    });

    // CHANGE PASSWORD
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
      state.pass_req_success = false;
    });
    builder.addCase(
      changePassword.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.pass_req_success = true;
      }
    );
    builder.addCase(changePassword.rejected, (state) => {
      state.loading = false;
    });

    // CHANGE  DEFAULT  PASSWORD
    builder.addCase(defaultPasswordChange.pending, (state) => {
      state.loading = true;
      state.pass_req_success = false;
    });
    builder.addCase(
      defaultPasswordChange.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.default_pass_req_success = true;
        state.tac_user.default_password_updated = 1;
      }
    );
    builder.addCase(defaultPasswordChange.rejected, (state) => {
      state.loading = false;
    });

    // update User name
    builder.addCase(updateUsername.pending, (state) => {
      state.update_user_loading = true;
      state.update_user_req_success = false;
    });
    builder.addCase(
      updateUsername.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.update_user_loading = false;
        state.update_user_req_success = true;
        state.tac_user.first_name = payload?.data.first_name;
        state.tac_user.last_name = payload?.data.last_name;
        state.tac_user.name = payload?.data.name;
        state.tac_user.email = payload?.data.email;
      }
    );
    builder.addCase(updateUsername.rejected, (state) => {
      state.update_user_loading = false;
    });

    // LOGOUT USER
    builder.addCase(logoutUser.pending, (state) => {
      state.logout_success = false;
      state.logout_loading = true;
    });
    builder.addCase(
      logoutUser.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.logout_success = true;
        state.req_success = false;
        state.email_validated = false;
        state.recent_signup_email = null;
        state.load_user = null;
        state.tac_user = null;
        state.access_token = null;
        state.authenticated = false;
        state.loading = false;
        localStorage.removeItem("access_token");
        localStorage.removeItem("tac_user");
        state.logout_loading = false;
      }
    );
    builder.addCase(logoutUser.rejected, (state) => {
      state.logout_loading = false;
      state.loading = false;
    });

    // FORGET PASSWORDA
    builder.addCase(forgetPassword.pending, (state) => {
      state.forget_password_loading = true;
      state.forget_password_success = false;
    });
    builder.addCase(
      forgetPassword.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.forget_password_loading = false;
        state.forget_password_success = true;
      }
    );
    builder.addCase(forgetPassword.rejected, (state) => {
      state.forget_password_loading = false;
    });

    // validate token
    builder.addCase(validateToken.pending, (state) => {
      state.token_loading = true;
      state.token_success = false;
      state.loading = false;
      state.reset_password_loading = false;
    });
    builder.addCase(
      validateToken.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.token_loading = false;
        state.token_success = true;
      }
    );
    builder.addCase(validateToken.rejected, (state) => {
      state.token_loading = false;
    });

    // verify member
    builder.addCase(validateMemberSignup.pending, (state) => {
      state.member_verify_token_loading = true;
      state.member_verify_token_success = false;
      state.loading = false;
      state.member_signup_loading = false;
    });
    builder.addCase(
      validateMemberSignup.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.member_verify_token_loading = false;
        state.member_verify_token_success = true;
        state.recent_signup_email = payload?.email;
      }
    );
    builder.addCase(validateMemberSignup.rejected, (state) => {
      state.member_verify_token_loading = false;
    });

    // RESET PASSWORDA
    builder.addCase(resetPassword.pending, (state) => {
      state.reset_password_loading = true;
      state.reset_password_success = false;
      state.loading = false;
    });
    builder.addCase(
      resetPassword.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.reset_password_loading = false;
        state.reset_password_success = true;
      }
    );
    builder.addCase(resetPassword.rejected, (state) => {
      state.reset_password_loading = false;
    });

    // member signup
    builder.addCase(memberSignup.pending, (state) => {
      state.member_signup_loading = true;
      state.member_signup_success = false;
      state.loading = false;
    });
    builder.addCase(
      memberSignup.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.member_signup_loading = false;
        state.member_signup_success = true;
      }
    );
    builder.addCase(memberSignup.rejected, (state) => {
      state.member_signup_loading = false;
    });
  },
});

export const {
  setRecentSignupEmail,
  setVerifiedDomain,
  googleUserSignIn,
  populateUserFromGoogleSignIn,
  activatePaidPlanForUser,
  deActivatePaidPlanForUser,
  setThemeName,
} = AuthSlice.actions;

export default AuthSlice.reducer;
