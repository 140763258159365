import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getNotificationsList, markAllNotificationsAsRead, markNotificationsAsRead } from "./NotificationApi";

const initialState: any = {
  loading: false,
  req_success: false,
  all_notifications: [],
  unreadNotificationCount: 0,
  totalNotificationCount: 0,
  hasMoreNotification: false,
  currentPage: 1,
  type: "all",
  // selectedNotification: null,
  // notificationSelectedTime: null
};

const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET ALL ARCHIVE
    builder.addCase(getNotificationsList.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
        getNotificationsList.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        
        if (payload?.data?.pagination?.current_page !== 1) {
          const combinedArray = state.all_notifications.concat(payload?.data?.data);
          state.all_notifications = combinedArray;
        } else {
          state.all_notifications = payload?.data?.data;
        }

        state.type = payload?.type;
        state.loading = false;
        state.req_success = true;
        state.unreadNotificationCount = payload?.unreadNotificationCount;
        state.hasMoreNotification = payload?.data?.pagination?.next_page_url !== null;
        state.currentPage = payload?.data?.pagination?.current_page;
        state.totalNotificationCount = payload?.data?.pagination?.total;
      },
    );
    builder.addCase(getNotificationsList.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(markAllNotificationsAsRead.pending, (state) => {
      state.req_success = false;
      state.loading = true;
    });
    builder.addCase(
        markAllNotificationsAsRead.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;

        state.all_notifications = state.all_notifications.map((notification: any) => {
          notification.status = "read";
          return notification;
        });
        state.unreadNotificationCount = 0;
      }
    );
    builder.addCase(markAllNotificationsAsRead.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(markNotificationsAsRead.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(
          markNotificationsAsRead.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.loading = false;
          state.req_success = true;

          state.all_notifications = state.all_notifications.map((notification: any) => {
            if (notification.id === payload?.notificationId) {
              if (notification.status !== "read") {
                notification.status = "read";
                state.unreadNotificationCount -= 1;
              }
              // state.selectedNotification = notification;
              // state.notificationSelectedTime = new Date().getTime();
            }
            return notification;
          });
        },
      );
      builder.addCase(markNotificationsAsRead.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default NotificationSlice.reducer;
