import React from "react";
import { Calendar } from "react-feather";

const DeadlineDetail = (props: any) => {
  const { courses } = props;
  return (
    <div className="deadline__section">
      <span className="colorful__title">Individual deadlines</span>
      <div className="deadline__contents">
        {courses.map((course: any) => {
          return (
            <div className="contnet__section" key={course.id}>
              <span className="name">{course.name}: </span>
              <div className="date__section">
                <Calendar className="calendar" />{" "}
                <span className="deadline ">{course.deadline}</span>{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DeadlineDetail;
