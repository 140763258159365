import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getStudentDashboardStats } from "./DashboardApi";

const initialState: any = {
  loading: false,
  req_success: false,
  data: {},
};

const tenantUserDashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET student dashboard stats
    builder.addCase(getStudentDashboardStats.pending, (state) => {
      state.loading = true;
      state.req_success = false;
    });
    builder.addCase(
      getStudentDashboardStats.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.loading = false;
        state.req_success = true;
        state.data = payload.data;
      },
    );
    builder.addCase(getStudentDashboardStats.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default tenantUserDashboardSlice.reducer;
