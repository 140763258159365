import React from "react"
import "./support.scss";
import supportImg from "../../../../../assets/images/support.svg";
import whiteSupportImg from "../../../../../assets/images/wsupport.svg";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import { RootStore, useAppSelector } from "../../../../../store/store";

const Support = () => {
    const themeState: any = useAppSelector((store: RootStore) => {
        return store.ThemeSlice;
      });
  return (
    <div className="support__wrapper">
        <div className="support__content__center">
        <div className="support__container">
            <div className="support__left__section">
               <h3>Need Any Support?</h3>
               <p>Vestibulum eget est nec ipsum convalliselerique a sed mi com, velit non malesuada convall m eget est necge
                nec ipsum ips m eget est.</p>
                <div className="support__btn__width">
                <PrimaryButton text="Support" width="support__btn__width__handle"/>
                </div>
            </div>
            <div className="support__right__section">
                <div className="image__wrapper">
                    <img src={themeState.themeMode === "dark" ? supportImg : whiteSupportImg} alt="supportImg" />
                </div>
            </div>
        </div>

        </div>
       
    </div>
  )
}

export default Support
