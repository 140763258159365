import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../apiConfigs/axiosInstance";
import { getMainDomainWithProtocol } from "../../helpers/utils/urlHelper";
import LocalDB from "../../helpers/utils/LocalDb";

export const loadConfig = createAsyncThunk(
  "loadConfig",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      // const url = getMainDomainWithProtocol(window.location.origin);
      const url = getMainDomainWithProtocol(window.location.origin);

      const res = await axiosInstance.get(`${url}/configs`);
      LocalDB.save("config", res?.data?.data);
      // document.title = res?.data?.data?.site_name;
      return res?.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
