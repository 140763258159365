import React, { useEffect, useRef, useState } from "react";
import "./applyNowHeader.scss";
import SearchBar from "../../../../shared/SearchBar/SearchBar";
import TableHeadingTitle from "../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SelectDropDown from "../../../../shared/SelectDropDown/SelectDropDown";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import { filterArray, filterArrayForTAC } from "./applyNowHelper";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import {
  downloadApplyNow,
  getApplyNowList,
} from "../../../../store/features/AdminPanel/ApplyNow/ApplyNowApi";
import {
  searchApplyNowList,
  setApplyNowList,
} from "../../../../store/features/AdminPanel/ApplyNow/ApplyNowSlice";
import CheckBox from "../../../../shared/Inputs/CheckBox/CheckBox";
import { PrimaryButton } from "../../../../shared/Buttons/Buttons";

function ApplyNowHeader() {
  const [searchText, setSearchText] = useState("");
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const [showUserFileDownload, setShowUserFileDownload] = useState(false);
  const [csv, setCsv] = useState(false);
  const [xls, setXls] = useState(false);

  const downloadUserFilebtnRef: any = useRef(null);
  const downloadUserFileDropdownRef: any = useRef(null);

  const handleClickOutSide = (event: any) => {
    if (
      downloadUserFileDropdownRef.current &&
      !downloadUserFileDropdownRef.current.contains(event.target)
    ) {
      if (
        downloadUserFilebtnRef.current &&
        !downloadUserFilebtnRef.current.contains(event.target)
      ) {
        setShowUserFileDownload(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutSide);
  }, []);
  const applyNowState: any = useAppSelector((state) => state.ApplyNowSlice);

  interface Ivalue {
    id: number;
    name: string;
    value: string;
  }
  const [selectedValue, setSelectedValue] = useState<Ivalue>(
    configState.configs?.membership_enabled === Domain.TAC
      ? filterArrayForTAC[0]
      : filterArray[0]
  );

  const handleOption = (data: Ivalue) => {
    setSelectedValue(data);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getApplyNowList()).then(() =>
      dispatch(setApplyNowList(selectedValue.value))
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(setApplyNowList(selectedValue.value));
  }, [dispatch, selectedValue]);

  useEffect(() => {
    dispatch(
      searchApplyNowList({ role: selectedValue.value, search: searchText })
    );
  }, [dispatch, searchText, selectedValue]);

  const download = async () => {
    if (xls)
      dispatch(downloadApplyNow({ format: "xlsx", role: selectedValue.value }));
    if (csv)
      dispatch(downloadApplyNow({ format: "csv", role: selectedValue.value }));
  };

  useEffect(() => {
    if (applyNowState.download.success) {
      console.log("first");
      setCsv(false);
      setXls(false);
      setShowUserFileDownload(false);
    }
  }, [applyNowState.download.success]);

  return (
    <div className="header_container">
      <div className="left__section">
        <TableHeadingTitle>Apply Now For</TableHeadingTitle>
        <div className="dropdown__holder dropdown__holder__handle__width">
          <SelectDropDown
            data={
              configState.configs?.membership_enabled === Domain.TAC
                ? filterArrayForTAC
                : filterArray
            }
            selectedValue={selectedValue.name}
            handleOption={handleOption}
          />
        </div>
      </div>
      <div className="right__section">
        <SearchBar value={searchText} setValue={setSearchText} />
        <div className="download__btn__wrapper">
          <div
            className="download__user__file__btn__width__handle"
            aria-hidden
            onClick={() => setShowUserFileDownload(!showUserFileDownload)}
            ref={downloadUserFilebtnRef}
          >
            <button className="download__user__file__btn" type="button">
              Download User
            </button>
          </div>
          {showUserFileDownload && (
            <div
              className="download__user__file__download__dropdown__wrapper"
              ref={downloadUserFileDropdownRef}
            >
              <div className="download__user__file__download__dropdow">
                <div className="checkbox__wrapper">
                  {" "}
                  <CheckBox
                    label="CSV"
                    tacCheckBoxvalue={csv}
                    onClick={() => setCsv(!csv)}
                  />
                </div>
                <div className="checkbox__wrapper">
                  <CheckBox
                    label="XLS"
                    tacCheckBoxvalue={xls}
                    onClick={() => setXls(!xls)}
                  />
                </div>
                <div className="download__btn__width">
                  <PrimaryButton
                    text="Download"
                    onClick={download}
                    loading={applyNowState.download.loading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ApplyNowHeader;
