/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useRef, useState } from "react";
// JoditEditor editor
import JoditEditor from "jodit-react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LogOut, ChevronLeft } from "react-feather";
import SelectField from "../../../shared/Inputs/SelectField/SelectField";
import CheckBox from "../../../shared/Inputs/CheckBox/CheckBox";
import TextField from "../../../shared/Inputs/TextField/TextField";
import { PrimaryButton } from "../../../shared/Buttons/Buttons";
import "./blogContentHeader.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import { getAuthors } from "../../../store/features/AdminPanel/Blog/Authors/AuthorsApi";
import { getCategories } from "../../../store/features/AdminPanel/Blog/Category/CategoryApi";
import { REQUIRED_VALIDATION } from "../../../helpers/utils/formUtils";
import { getBase64, getFileExtension } from "../../../helpers/utils/FileHelper";
import ErrorText from "../../../shared/Inputs/ErrorText/ErrorText";
import {
  addBlog,
  updateBlog,
} from "../../../store/features/AdminPanel/Blog/BlogApi";
import { closeBlogEditModal } from "../../../store/features/AdminPanel/Blog/BlogSlice";
import TextArea from "../../../shared/Inputs/TextArea/TextArea";
import ArrayList from "../../../shared/Inputs/ArrayList/ArrayList";

const BlogContentAdd = (props: any) => {
  const { setOpenBlogPage } = props;
  const dispatch = useAppDispatch();

  const [imageExt, setImageExt] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageError, setImageError] = useState("");
  const [publish, setPublish] = useState(false);
  const [draft, setDraft] = useState(true);

  const [checkboxError, setCheckboxError] = useState("");
  // const [editorState, setEditorState] = useState<any>("");
  const hiddenFileInput = useRef<any>(null);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [dataArray, setDataArray] = useState<any>([]);

  const statusPublish = () => {
    if (publish) {
      setPublish(false);
    } else {
      setPublish(true);
      setDraft(false);
    }
    setCheckboxError("");
  };
  const statusDraft = () => {
    if (draft) {
      setDraft(false);
    } else {
      setPublish(false);
      setDraft(true);
    }
    setCheckboxError("");
  };
  const getFiles = async (e: any) => {
    const contentFile = e.target.files[0];
    if (contentFile.size > 2 * 1024 * 1024) {
      setImageError("Image size must be less than 2 MB");
      return;
    }
    const result = URL.createObjectURL(contentFile);
    setImageUrl(result);
    const extension = getFileExtension(contentFile.name);
    setImageExt(extension);
    const base64Image: any = await getBase64(contentFile);
    setImageFile(base64Image);
    setImageError("");
  };

  const handleClick = (event: any) => {
    hiddenFileInput && hiddenFileInput?.current?.click();
  };
  const authorState: any = useAppSelector((store: RootStore) => {
    return store.AuthorSlice;
  });
  const catState: any = useAppSelector((store: RootStore) => {
    return store.CategorySlice;
  });
  const blogState: any = useAppSelector((store: RootStore) => {
    return store.BlogSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  const config = useMemo(() => ({
    uploader: {
      insertImageAsBase64URI: true,
    },
    readOnly: false,
    placeholder: "write something here.....",
    height: 700,
    toolbarSticky: false,
    theme: themeState.themeMode === "dark" ? "dark" : "default",
  }), [themeState.themeMode]);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getAuthors());
  }, []);

  const validationSchema = yup.object().shape({
    title: yup.string().required(REQUIRED_VALIDATION("Title")),
    blog_category_id: yup.object().required(REQUIRED_VALIDATION("Category")),
    author_id: yup.object().required(REQUIRED_VALIDATION("Author")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };

  const options =
    !catState.loading.get &&
    catState.req_success.get &&
    catState.categories.map((cat: any) => {
      return { value: cat.id, label: cat.name };
    });
  const optionsII =
    !authorState.loading.get &&
    authorState.req_success.get &&
    authorState.authors.map((author: any) => {
      return { value: author.id, label: author.name };
    });

  const {
    setValue,
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm<any>(formOptions);

  const clearFrom = () => {
    reset();
    dispatch(closeBlogEditModal());
    setImageError("");
    setCheckboxError("");
    setImageFile("");
    setImageExt("");
    setImageUrl("");
    setPublish(false);
    setDraft(false);
    setValue("author_id", {
      label: "Select Option",
    });
    setValue("blog_category_id", {
      label: "Select Option",
    });
    setValue("seo_title", "");
    setValue("seo_description", "");
    setDataArray([]);
  };
  const removeImage = () => {
    setImageUrl("");
    setImageError("Image is required");
  };
  const openBlogTable = () => {
    clearFrom();

    setOpenBlogPage(false);
  };

  const onSubmit = (values: any) => {
    if (!publish && !draft) {
      setCheckboxError("Status is required");
      return;
    }
    if (!blogState.editBlog) {
      if (!imageFile) {
        setImageError("Image is required");
        return;
      }
      const data = {
        ...values,
        author_id: values.author_id.value,
        blog_category_id: values.blog_category_id.value,
        cover_image: imageFile,
        extension: imageExt,
        description: content,
        status: publish ? 1 : 0,
        seo_description: values.seo_description,
        seo_title: values.seo_title,
        seo_keywords: dataArray.join(","),
      };
      dispatch(addBlog(data));
    }
    if (blogState.editBlog) {
      let data;
      if (!imageFile) {
        data = {
          ...values,
          author_id: values.author_id.value,
          blog_category_id: values.blog_category_id.value,
          description: content,

          status: publish ? 1 : 0,
          _method: "put",
          seo_description: values.seo_description,
          seo_title: values.seo_title,
          seo_keywords: dataArray.join(","),
        };
      }
      if (imageFile) {
        data = {
          ...values,
          author_id: values.author_id.value,
          blog_category_id: values.blog_category_id.value,
          description: content,
          cover_image: imageFile,
          extension: imageExt,
          status: publish ? 1 : 0,
          _method: "put",
          seo_description: values.seo_description,
          seo_title: values.seo_title,
          seo_keywords: dataArray.join(","),
        };
      }
      dispatch(
        updateBlog({ blogId: blogState.single_blog_info.id, data: data }),
      );
    }
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };
  useEffect(() => {
    if (blogState.req_success.add) {
      //
      setOpenBlogPage(false);
      clearFrom();
    }
  }, [blogState.req_success.add]);
  useEffect(() => {
    if (blogState.editBlog) {
      setValue("title", blogState.single_blog_info.title);
      setImageUrl(blogState.single_blog_info.image);
      setValue("author_id", {
        value: blogState.single_blog_info.author.id,
        label:
          blogState.single_blog_info.author.name.charAt(0).toUpperCase() +
          blogState.single_blog_info.author.name.slice(1),
      });
      setValue("blog_category_id", {
        value: blogState.single_blog_info.category.id,
        label:
          blogState.single_blog_info.category.name.charAt(0).toUpperCase() +
          blogState.single_blog_info.category.name.slice(1),
      });
      setValue("seo_title", blogState.single_blog_info.seo_title);
      setValue("seo_description", blogState.single_blog_info.seo_description);

      if (blogState.single_blog_info.status) {
        setPublish(true);
        setDraft(false);
      } else {
        setDraft(true);
        setPublish(false);
      }

      setContent(blogState.single_blog_info?.description);
    }
  }, [blogState?.editBlog]);

  useEffect(() => {
    if (blogState.req_success.get && blogState.editBlog) {
      if (blogState.single_blog_info.seo_keywords)
        setDataArray(blogState.single_blog_info.seo_keywords?.split(","));
    }
  }, [blogState.req_success.get, blogState.editBlog]);

  return (
    <div className="login__form__container">
      <form className="mdal" onSubmit={handleSubmit(onSubmit, onError)}>
        <span
          style={{
            display: "flex",
            marginBottom: "1rem",
            marginLeft: "-1rem",
            cursor: "pointer",
          }}
        >
          <span>
            <ChevronLeft onClick={openBlogTable} />
          </span>
          <span> Add New Blog</span>
        </span>
        <div className="upper__section">
          <div className="left__section">
            <Controller
              control={control}
              name="blog_category_id"
              render={({ field }) => (
                <SelectField
                  type="text"
                  label="Select Blog Catagory"
                  options={options}
                  register={register}
                  error={errors?.blog_category_id}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="author_id"
              render={({ field }) => (
                <SelectField
                  type="text"
                  label="Select Author"
                  options={optionsII}
                  register={register}
                  error={errors?.author_id}
                  {...field}
                />
              )}
            />
            <TextField
              label="Title"
              name="title"
              type="text"
              register={register}
              error={errors?.title}
            />
          </div>
          <div className="right__section">
            <div className={`file_fieldd ${imageError ? "error" : ""}`}>
              {!imageUrl && (
                <div className="file_input_design" onClick={handleClick}>
                  {/* <span style={{ display: "flex", justifyContent: "center" }}> */}
                  <LogOut className="logout_icon" />
                  <p className="clik_text">Click to add Image</p>
                  {/* </span> */}
                  <span className="blog__img__size__recomemded">
                    Recommended size 1600*900 px
                  </span>
                </div>
              )}
              {imageUrl && (
                <div className="thumbnail__section">
                  <img
                    src={imageUrl}
                    className="thumbnail__image"
                    alt="thumbnail"
                    onClick={handleClick}
                  />
                  {!blogState.editBlog && (
                    <span className="remove__image" onClick={removeImage}>
                      Remove
                    </span>
                  )}
                </div>
              )}
              <ErrorText error={imageError} />

              <input
                className="filefield"
                type="file"
                name="image"
                id="file"
                accept="image/*"
                ref={hiddenFileInput}
                onChange={(e: any) => getFiles(e)}
              />
            </div>
          </div>
        </div>
        <div className="fields-forms">
          <div className="selectField_blogg">
            {/* <div className="form__group"></div> */}
            {/* <div className="form__group"></div> */}
          </div>
        </div>

        {/* <div className="form__group"></div> */}
        <div className="editor-wrapper">
          <JoditEditor
            ref={editor}
            value={content}
            config={config}
            onBlur={(newContent) => setContent(newContent)}
          />
        </div>
        <div className="checkboxes-modal">
          <div className="checkbox_sec">
            <span>Status</span>
            <div className="checks">
              <CheckBox
                className="checkboxx"
                label="Publish"
                name="publish"
                type="text"
                value={publish}
                onClick={statusPublish}
              />
              <CheckBox
                className="checkboxx"
                label="Draft"
                name="draft"
                type="text"
                value={draft}
                onClick={statusDraft}
              />
            </div>
          </div>
        </div>
        <div className="seo_section_editor">
          <h2 className="mb-1">SEO Settings</h2>
          <div className="sse_row">
            <div className="sse1">
              <TextField
                label="Meta Title"
                name="seo_title"
                type="text"
                register={register}
              />
              <ArrayList
                name="seo_keyword"
                label="Meta Keyword"
                dataArray={dataArray}
                updateDataArray={setDataArray}
              />
            </div>
            <div className="sse2">
              <TextArea
                label="Meta Description"
                name="seo_description"
                type="text"
                register={register}
              />
            </div>
          </div>
        </div>

        <ErrorText error={checkboxError} />

        <div
          className={`add_button__witdh mt-1 ${
            blogState.editBlog ? "update" : ""
          }`}
        >
          <PrimaryButton
            text={blogState.editBlog ? "Update" : "Add"}
            type="submit"
            loading={blogState.loading.add}
            // onClick={onSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default BlogContentAdd;
