/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./index.scss";
import { ChevronDown } from "react-feather";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import {
  getFilterdSprintByPathId,
  getLearningPathForSprintFilter,
  getOnBoarding,
  updateSelectedLearningPath,
} from "../../../../store/features/UserDashboard/NewUserDashboard/NewUserDashoardApi";

interface IPath {
  id: number;
  name: string;
}

const TextAndSelect = () => {
  const [selectedPath, setSelectedPath] = useState<IPath>();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const authState: any = useAppSelector((store: RootStore) => store.AuthSlice);
  const dashState: any = useAppSelector(
    (store: RootStore) => store.NewDashboard
  );

  const deleteWordFromSentence = (sentence: any): String => {
    if (sentence) {
      let str: any = sentence.replace(/ Learning/g, "");
      str = str.replace(/ Path/g, "");
      return str;
    }
    return sentence;
  };

  useEffect(() => {
    dispatch(getLearningPathForSprintFilter());
    dispatch(getOnBoarding());
  }, [dispatch]);

  useEffect(() => {
    if (dashState.req_success.get_path) {
      const checkSelected = dashState.learning_path_for_filter.filter(
        (lp: any) => lp.selected
      );
      if (checkSelected.length > 0) {
        setSelectedPath(checkSelected[0]);
        dispatch(getFilterdSprintByPathId({ id: checkSelected[0].id }));
      } else {
        setSelectedPath(dashState.learning_path_for_filter[0]);
        dispatch(
          getFilterdSprintByPathId({
            id: dashState.learning_path_for_filter[0].id,
          })
        );
      }
    }
  }, [
    dashState.req_success.get_path,
    dashState.learning_path_for_filter,
    dispatch,
  ]);

  const [showfilter, setShowfilter] = useState(false);

  const handleFilter = (data: { id: number; name: string }) => {
    setSelectedPath(data);
    setShowfilter(false);
    dispatch(getFilterdSprintByPathId({ id: data.id }));
    dispatch(updateSelectedLearningPath({ id: data.id }));
  };

  const handleBodyClick = (e: any) => {
    if (
      !e.target.classList.contains("option__sections") &&
      !e.target.classList.contains("active") &&
      !e.target.classList.contains("select__title__section") &&
      !e.target.classList.contains("select__title") &&
      !e.target.classList.contains("down__icon") &&
      !e.target.classList.contains("option")
    ) {
      setShowfilter(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  return (
    <div className="text__and__select__section">
      <span className="welcome__text">
        Welcome, {authState?.tac_user?.first_name}!
      </span>
      {pathname.includes("/product-manager-user-dashboard") ? (
        <div className="text__wrapper">
          <span>You are on Product Manager Track.</span>
        </div>
      ) : (
        <div className="select__wrapper">
          <span className="simple__text">You are on</span>
          <div className="select__section">
            <div
              className="select__title__section"
              onClick={() => setShowfilter(!showfilter)}
            >
              <span className="select__title">
                {deleteWordFromSentence(selectedPath?.name)}
              </span>
              <ChevronDown className="down__icon" />
            </div>
            <div className={`option__sections ${showfilter && "active"}`}>
              {!dashState.loading.get_path &&
                dashState.req_success.get_path &&
                dashState.learning_path_for_filter.map((lp: any) => (
                  <span
                    className="option"
                    key={lp.id}
                    onClick={() => handleFilter(lp)}
                  >
                    {deleteWordFromSentence(lp.name)}
                  </span>
                ))}
            </div>
          </div>
          <span className="simple__text">track.</span>
        </div>
      )}
    </div>
  );
};

export default TextAndSelect;
