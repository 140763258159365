import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

export const ListAllCertificate = createAsyncThunk(
  "ListAllCertificate",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/my-certificates`);
      return res.data;
    } catch (error: any) {
      toast.error(error.response.data.data);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
