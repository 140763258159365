import React, { useEffect } from "react";
import "./index.scss";
import MeetCard from "./MeetCard";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../store/store";
import {
  getAllTeamMebersMeetLinks,
  getDescriptionByUrl,
} from "../../../store/features/UserDashboard/NewUserDashboard/MeetWithTeam/MeetWithTeamApi";

interface ITeam {
  id: number;
  name: string;
  designation: string;
  meeting_link: string;
}

const MeetWithTeamComp = () => {
  const dispatch = useAppDispatch();
  const teamState: any = useAppSelector((store: RootStore) => {
    return store.MeetWithTeamSlice;
  });

  useEffect(() => {
    dispatch(getAllTeamMebersMeetLinks());
    dispatch(getDescriptionByUrl({ url: window.location.href.toString() }));
  }, []);

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });


  return (
    <div className="meetWithteam__section">
      <div className="header__section">
        <h2 className="titile">Meet with Team </h2>
        <span className="desc">{teamState.desc}</span>
      </div>
      <div className="center__container">
        {" "}
        <div className="mt-5 meet_card__container">
          {teamState.req_success.get &&
            teamState.all_members.map((team: ITeam) => {
              return (
                <MeetCard
                  key={team.id}
                  name={team.name}
                  designation={team.designation}
                  meetingLink={team.meeting_link}
                />
              );
            })}
        </div>{" "}
      </div>
    </div>
  );
};

export default MeetWithTeamComp;
