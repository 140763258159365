/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useRef, useState } from "react";
import { X } from "react-feather";
import ErrorText from "../Inputs/ErrorText/ErrorText";
import { InputLabel } from "../Inputs/InputLabel/InputLabel";
import InputContainer from "../Layouts/Input/InputContainer";
import "./index.scss";

function Tags(props: any) {
  const {
    name,
    label,
    error,
    Enablehover,
    setError,
    register,
    transparent,
    dataArray,
    originalData,
    setOriginalData,
    updateDataArray,
    boldFirstIndex,
    onClick,
    input,
    tempData,
    setTempData,
    ...rest
  } = props;
  const [txt, setTxt] = useState("");
  const inputRef = useRef<any>(null);
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleChange = (e: any) => {
    setTxt(e.target.value);
  };
  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (txt.trim().length) {
        if (txt.trim().match(mailformat)) {
          setError("");

          if (dataArray.includes(txt.trim())) {
            setError("You can't add dublicate email  address");
            setTxt("");
            return;
          }
          if (dataArray) {
            updateDataArray([...dataArray, txt.trim()]);
          } else {
            updateDataArray([txt.trim()]);
          }
        } else {
          setError("You tried to add invalid email address");
        }

        setTxt("");
      }
    }
  };
  const removeTag = (data: any, index: any) => {
    const newArr = dataArray.filter((e: any, i: any) => {
      return i !== index;
    });
    updateDataArray(newArr);
    const user = originalData.map((user: any) => {
      if (data.id === user.id) {
        return { ...user, user, checked: false };
      }
      return user;
    });

    if (tempData) {
      const users = tempData.map((user: any) => {
        if (data.id === user.id) {
          return { ...user, user, checked: false };
        }
        return user;
      });
      setTempData(users);
    }

    setOriginalData(user);
  };
  const handleContainerClick = (e: any) => {
    inputRef.current!.focus();
    e.stopPropagation();
  };
  const test = (e: any) => {
    setTxt(e.target.value);

    if (txt.trim().length) {
      if (txt.trim().match(mailformat)) {
        setError("");

        if (dataArray.includes(txt.trim())) {
          setError("You can't add dublicate email  address");
          setTxt("");
          return;
        }
        if (dataArray) {
          updateDataArray([...dataArray, txt.trim()]);
        } else {
          updateDataArray([txt.trim()]);
        }
      } else {
        setError("You tried to add invalid email address");
      }

      setTxt("");
    }
    //
  };
  return (
    <div
      className={`custom__tags_container ${transparent && "transparent"} ${
        input && "input"
      }   ${error && "error"}  ${Enablehover && "input"} `}
      onClick={onClick}
    >
      {dataArray !== null &&
        !input &&
        dataArray?.map((e: any, i: any) => {
          return (
            <div
              key={e.id + i * 10}
              className={`al_tag ${transparent && "transparent"} ${
                boldFirstIndex && i === 0 && "boldFirstIndex"
              }`}
            >
              {e.name}{" "}
              <button
                type="button"
                className={`tag__list__button ${transparent && "transparent"}`}
                onClick={() => removeTag(e, i)}
              >
                <X size={15} className="delete__tag__icon" />
              </button>
            </div>
          );
        })}

      {dataArray !== null &&
        input &&
        dataArray?.map((e: any, i: any) => {
          return (
            <div
              key={e + i * 100}
              className={`al_tag ${transparent && "transparent"}`}
            >
              {e}{" "}
              <button
                type="button"
                className={`tag__list__button ${transparent && "transparent"}`}
                onClick={() => removeTag(e, i)}
              >
                <X size={15} className="delete__tag__icon" />
              </button>
            </div>
          );
        })}

      {input && (
        <input
          className="change__height"
          type="text"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={txt}
          ref={inputRef}
          onBlur={test}
        />
      )}
    </div>
  );
}

export default Tags;
