import { Edit } from "react-feather";
import "./index.scss";
import { RootStore, useAppSelector } from "../../../../store/store";

export const NewChatButton = (props: any) => {
  const { onClick } = props;
  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });

  return (
    <button
      type="button"
      className={`new__chat__button ${
        (aiState.loading.question || aiState.loading.current_answer) &&
        "disabled"
      }`}
      onClick={onClick}
      disabled={aiState.loading.current_answer || aiState.loading.question}
    >
      <Edit className="new__chat__icon" />
      <span> New chat</span>
    </button>
  );
};
