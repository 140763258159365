/* eslint-disable react/no-array-index-key */
import React, { useEffect } from "react";
import "./index.scss";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";

import TacTable, {
  TacTableAction,
  TacTableBody,
  TacTableHeading,
} from "../../../../../shared/TacTable/TacTable";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  getActiveSubscription,
  getCardInfo,
} from "../../../../../store/features/UserDashboard/Payment/PaymentApi";
import { editUserPayment } from "../../../../../store/features/UserDashboard/Payment/PaymentSlice";

const SubscriptionTable = () => {
  const dispatch = useAppDispatch();
  const paymentState: any = useAppSelector((store: RootStore) => {
    return store.TACPaymentSlice;
  });

  useEffect(() => {
    dispatch(getActiveSubscription());
  }, [paymentState.req_success.upgrade_account]);

  const editUserPlan = (subscription: any) => {
    dispatch(getCardInfo());
    dispatch(editUserPayment(subscription));
  };
  return (
    <div className="user__subscription__container">
      <div className="user__subscription__header__section">
        <div className="left__section">
          <TableHeadingTitle>Subscriptions</TableHeadingTitle>
        </div>
      </div>
      <div className="user__subscription__table">
        {" "}
        <TacTable
          heading={
            <TacTableHeading tac>
              <th>Subscription</th>
              <th>Price</th>
              <th>Started On</th>
              <th>Automatic Renew Date</th>
              <th>Status </th>
              <th className="hide__text">.</th>
            </TacTableHeading>
          }
        >
          <TacTableBody>
            {paymentState.req_success.subscription &&
              paymentState.active_subscription.map(
                (subscription: any, index: number) => {
                  return (
                    <tr key={index + 1}>
                      <td>{subscription.plan_name}</td>
                      <td>{subscription.pricing}</td>
                      <td>{subscription.start_date}</td>
                      <td>{subscription.renew_date}</td>
                      <td className="active__inactinve">
                        {subscription.status.toLowerCase() === "active" && (
                          <ul className="active__color">
                            <li> Active</li>
                          </ul>
                        )}

                        {subscription.status.toLowerCase() === "inactive" && (
                          <ul className="inactive__color">
                            <li> Inactive</li>
                          </ul>
                        )}
                      </td>
                      <TacTableAction
                        onEdit={() => editUserPlan(subscription)}
                      />
                    </tr>
                  );
                }
              )}
          </TacTableBody>
        </TacTable>
      </div>
    </div>
  );
};

export default SubscriptionTable;
