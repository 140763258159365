import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";

export const filterArrayForTAC = [
  {
    id: 1,
    name: "Become a Product Manager",
    value: "product_manager",
  },
  {
    id: 2,
    name: "Agile Fundamental (ICP) Certification",
    value: "icp",
  },
  {
    id: 3,
    name: "Agile Team Facilitation (ATF) Certification",
    value: "atf",
  },
  {
    id: 4,
    name: "ICP/ACC Certification",
    value: "icp_acc",
  },
  {
    id: 4,
    name: "ICP/APO Certification",
    value: "apo",
  },
];

export const filterArray = [
  {
    id: 1,
    name: "Business Analyst",
    value: "business_analyst",
  },

  {
    id: 2,
    name: "Product Owner",
    value: "product_owner",
  },

  {
    id: 3,
    name: "Scrum Master",
    value: "scrum_master",
  },
];
