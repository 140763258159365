import { Link } from "react-router-dom";
import couseImg from "../../../assets/icons/OnlineCourse.jpg";

const Bundle = (props: any) => {
  const { path } = props;
  return (
    <Link to={`/organization-user-learningpath-detail/${path.id}`}>
      <div className="bundle__wapper">
        <img
          src={path.bundle_card_image_url || couseImg}
          className="cover__img"
          alt="cover"
        />
        <div className="bundle__info__section">
          <span className="bundle__title">{path.name}</span>
          <span className="bundle__count">
            {path.path_courses_count} Courses
          </span>
          <p className="bundle__description">{path.description}</p>
          {/* <div className="bundle__divider" /> */}
          {/* <span className="bundle__deadline">Individual deadline</span> */}
        </div>
      </div>
    </Link>
  );
};

export default Bundle;
