import React, { useState } from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import BoxShadow from "../../../shared/BoxShadow/BoxShadow";
import ReferenceHead from "../../../components/AdminPanel/Reference/ReferenceHead/ReferenceHead";
import ReferenceBody from "../../../components/AdminPanel/Reference/ReferenceBody/ReferenceBody";

function Reference(props: any) {
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState(false);
  return (
    <AdminPanelLayout>
      <BoxShadow>
    <div className="mt-7">
    <ReferenceHead
          openModal={openModal}
          setOpenModal={setOpenModal}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setDeleteShowModal={setDeleteShowModal}
          showDeleteModal={showDeleteModal}
        />
        <ReferenceBody
          openModal={openModal}
          setOpenModal={setOpenModal}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setDeleteShowModal={setDeleteShowModal}
          showDeleteModal={showDeleteModal}
        />
    </div>
      </BoxShadow>
    </AdminPanelLayout>
  );
}

export default Reference;
