/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-template */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import { ChevronUp, Edit, Menu, RefreshCcw, StopCircle } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import InputMessage from "../InputMessage/InputMessage";
import Message from "../Message/Message";
import "./index.scss";
import {
  fetchConversation,
  filterQuestion,
  stopGenerating,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistApi";
import MessageLoading from "../Message/MessageLoading";
import {
  addAnswerToMessageStack,
  addConversationToSidebar,
  askNewQuestionToAi,
  setActiveConversationId,
  setLoaderId,
  setLoadingCurrentAnswer,
  setResponseGenerated,
} from "../../../../store/features/UserDashboard/NewUserDashboard/AiAssist/AiAssistSlice";
import axiosInstance from "../../../../apiConfigs/axiosInstance";
import CurrentResponse from "../Message/CurrentResponse";
import { renderDynamicUrlFromComponentLevelState } from "../../../../helpers/utils/urlHelper";
import { Domain } from "../../../../helpers/utils/DomainSwitcher/domains";
import { roles } from "../../../../helpers/utils/Constants/roles";

const ChatingArea = (props: any) => {
  const { setShowSideBar } = props;
  const dispatch = useAppDispatch();
  const bottomRef = useRef<any>(null);
  const params = useParams();
  const [messageStack, setMessageStack] = useState<any>([]);
  const [currentResponse, setCurrentResponse] = useState("");
  const [current_questionId, setCurrentQuestionId] = useState("");
  const [stopResponse, setStopResponse] = useState<boolean>(false);

  const eventSourceRef = useRef<EventSource | null>(null);

  const aiState: any = useAppSelector((store: RootStore) => {
    return store.AiSlice;
  });
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const dynamic_url = renderDynamicUrlFromComponentLevelState(themeState);
  useEffect(() => {
    if (params.hashId) {
      dispatch(fetchConversation({ id: params.hashId }));
    }
  }, []);

  const askQuestionWithAiNew = async () => {
    const data: any = { question: aiState.question };
    if (aiState.active_conversation_id) {
      data.conversation_id = aiState.active_conversation_id;
    }
    dispatch(setLoadingCurrentAnswer(true));

    const res = await axiosInstance.post(
      `${dynamic_url}/ai-stream-question`,
      data
    );

    dispatch(addConversationToSidebar(res.data.data));
    dispatch(setActiveConversationId(res.data.data.conversation_id));
    const eventSource: any = new EventSource(
      `${dynamic_url}/ai-stream-chat?question_id=${res.data.data.id}`
    );

    setCurrentQuestionId(res.data.data.id);

    eventSourceRef.current = eventSource;

    let sentence = "";

    eventSource.onmessage = (e: any) => {
      if (e.data === "[DONE]") {
        eventSource.close();

        dispatch(setResponseGenerated(true));
        dispatch(setLoadingCurrentAnswer(false));

        if (sentence) {
          dispatch(addAnswerToMessageStack(sentence));
          setStopResponse(false);
          setCurrentResponse("");
          setCurrentQuestionId("");
        }
      } else {
        const txt = JSON.parse(e.data).choices[0].delta.content;

        if (txt !== undefined) {
          sentence += txt;

          if (sentence.length > 150) {
            setStopResponse(true);
          }
          // sentence.replace(/(?:\r\n|\r|\n)/g, "<br>");
          setCurrentResponse(sentence);
          bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    };
    eventSource.onerror = (e: any) => {
      toast.error(e);
      dispatch(setLoadingCurrentAnswer(false));

      eventSource.close();
    };
  };

  useEffect(() => {
    if (aiState.question) {
      askQuestionWithAiNew();
      // dispatch(askQuestionWithAi(data));

      setMessageStack([
        ...messageStack,
        { id: uuidv4(), message: aiState.question, user: true, ai: false },
      ]);

      dispatch(askNewQuestionToAi(""));
      dispatch(setResponseGenerated(false));
    }
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [aiState.question]);

  const handleAiFilter = (
    question: string,
    filter: string,
    questionId: number
  ) => {
    if (aiState.loading.question) {
      return;
    }
    const data: any = { question: question, filter: filter };
    if (aiState.active_conversation_id) {
      data.conversation_id = aiState.active_conversation_id;
    }
    dispatch(setLoaderId(questionId));
    dispatch(filterQuestion(data));
  };

  const scrolltoTop = () => {
    window.scrollTo(0, 0);
  };

  const handleStopResponse = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      dispatch(setResponseGenerated(true));
      dispatch(setLoadingCurrentAnswer(false));

      if (currentResponse) {
        dispatch(addAnswerToMessageStack(currentResponse));
        dispatch(
          stopGenerating({
            question_id: current_questionId,
            answer: currentResponse,
          })
        );
        setStopResponse(false);
        setCurrentQuestionId("");
        setCurrentResponse("");
      }
    }
  };

  return (
    <div className="chating__area__section">
      <div className="mobile__view__nav__bar">
        <Menu className="icon" onClick={() => setShowSideBar(true)} />{" "}
        <span className="ai_assist"> Ai Assist</span> <Edit className="icon" />
      </div>
      <div className="messaging__list__area">
        {aiState.messagesStack.map((message: any) => {
          return (
            <Message
              themeState={themeState}
              key={message.id}
              user={message?.user}
              ai={message?.ai}
              message={message}
              handleAiFilter={handleAiFilter}
              // loading={
              //   aiState.loading.question &&
              //   aiState.loaderId === message?.questionId
              // }
            />
          );
        })}
        {currentResponse && currentResponse.length > 0 && (
          <CurrentResponse message={currentResponse} themeState={themeState} />
        )}
        {aiState.loading.question && !aiState.loaderId && <MessageLoading />}
        <div ref={bottomRef} />
      </div>
      <button
        type="button"
        onClick={scrolltoTop}
        className={`chat__scroll__button ${ authState.tac_user?.role === roles.USER && configState.configs.membership_enabled === Domain.TAA && "chat__scroll__button__style "}`}
      >
        <ChevronUp className="chat_up__icon" />
      </button>

      <div className="fixed__button__wrapper">
        {(aiState.loading.question || aiState.loading.current_answer) && (
          <div className="fixed__response__stop__button">
            {stopResponse ? (
              <button type="button" onClick={handleStopResponse}>
                <StopCircle className="refresh__icon" /> Stop generating
                response
              </button>
            ) : (
              <button type="button">
                <RefreshCcw className="refresh__icon" /> Generating response..
              </button>
            )}
          </div>
        )}
        {aiState.response_generated && !aiState.loading.current_answer && (
          <div className="fixed__response__stop__button">
            <button type="button">
              {/* <img
                src={
                  themeState.themeMode === "dark" ? tickDoneIcon : wtickDoneIcon
                }
                alt="done"
              /> */}
              <Icon icon="fluent-mdl2:completed" className="tick__icon" />
              Response generated!
            </button>
          </div>
        )}
      </div>
      <div className="fixed__input__message">
        <div className="width__padding">
          <InputMessage />
        </div>
      </div>
    </div>
  );
};

export default ChatingArea;
