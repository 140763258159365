/* eslint-disable no-else-return */
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkTenant } from "../../helpers/utils/CheckTenant";
import { checkForDomain, loadUser } from "../../store/features/Auth/AuthApi";
import { RootStore, useAppDispatch, useAppSelector } from "../../store/store";
import { Domain } from "../../helpers/utils/DomainSwitcher/domains";
import { ROUTES } from "../../helpers/utils/Constants/route";
import { roles } from "../../helpers/utils/Constants/roles";
import { setThemeName } from "../../store/features/Auth/AuthSlice";

const CheckAuthRoute = ({ children }: { children: JSX.Element }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showLogin, setshowLogin] = useState(false);
  const [validated, setValidated] = useState(false);
  const [checking, setChecking] = useState(true);
  const [authFail, setAuthFail] = useState(false);

  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const configState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  console.log("check--auth--route");

  const user = checkTenant();

  useLayoutEffect(() => {
    setChecking(true);
    dispatch(
      checkForDomain({
        subdomain: user.tenant,
        setValidated: setValidated,
        setChecking: setChecking,
      })
    );
    dispatch(loadUser({ setAuthFail: setAuthFail }));
    const themName: any = localStorage.getItem("officialThemeName");
    dispatch(setThemeName(themName));
  }, []);
  useLayoutEffect(() => {
    if (user.tenant && !validated && !checking) {
      navigate("/not-found");
    }

    if (!checking) {
      if (
        authState.authenticated &&
        authState.tac_user &&
        authState.tac_user.default_password_updated
      ) {
        if (
          authState.tac_user.role === "user" &&
          configState.configs?.membership_enabled === Domain.TAA
        ) {
          navigate(ROUTES.ACADEMIC_USER_DASHBOARD);
        }
        if (
          authState.tac_user.role === "user" &&
          configState.configs?.membership_enabled === Domain.TAC
        ) {
          navigate(ROUTES.PROGRESS);
        }
        if (
          authState.tac_user.all_roles?.includes(roles.ICP_STUDENT) || authState?.tac_user?.all_roles?.includes(roles.APO_STUDENT) || authState?.tac_user?.all_roles?.includes(roles.PDM_STUDENT) || authState?.tac_user?.all_roles?.includes(roles.ICP_ACC_STUDENT) || authState?.tac_user?.all_roles?.includes(roles.ATF_STUDENT)  &&
          configState.configs?.membership_enabled === Domain.TAC
        ) {
          navigate(ROUTES.ALL_PREMIUM_COURSES);
          return;
        }
        if (
          authState.tac_user.role === roles.PRODUCT_MANAGER_STUDENT &&
          configState.configs?.membership_enabled === Domain.TAC
        ) {
          navigate(ROUTES.PRODUCT_MANAGER_COURSES);
        }
        if (authState.tac_user.role === "sys_adm") {
          navigate("/dashboard");
        }
        if (authState.tac_user.role === roles.SUB_ADMIN) {
          navigate("/dashboard");
        }
        if (authState.tac_user.role === "owner") {
          navigate("/organization");
        }
        if (authState.tac_user.role === "admin") {
          navigate("/organization");
        }
        if (authState.tac_user.role === "student") {
          navigate("/organization-user-dashboard");
        }
      }

      if (!authState.authenticated) {
        setshowLogin(true);
      }
    }
  }, [authState.authenticated, authState.tenant_req_success, checking]);
  if (showLogin) {
    return children;
  } else {
    return null;
  }
};

export default CheckAuthRoute;
