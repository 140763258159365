import React from "react";
import AdminPanelLayout from "../../../components/AdminPanel/AdminPanelLayout/AdminPanelLayout";
import ViewBlog from "../../../components/UserDashboard/Blog/ViewBlog/ViewBlog";

function ViewSingleBlog(props: any) {
  return (
    <div>
      <AdminPanelLayout>
        <ViewBlog />
      </AdminPanelLayout>
    </div>
  );
}

export default ViewSingleBlog;
