import { useEffect, useState } from "react";
import Rating from "react-rating";
import { ChevronLeft, Mic, Star } from "react-feather";
import { useForm } from "react-hook-form";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { PrimaryButton } from "../../../../../shared/Buttons/Buttons";
import TextArea from "../../../../../shared/Inputs/TextArea/TextArea";
import "./feedback.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import {
  addOrUpdateAnswer,
  getPresignedUrlForAdminAudioUpload,
  uploadAdminAudioToAws,
} from "../../../../../store/features/AdminPanel/Interview/Answer/InterviewAnswerApi";
import AudioFeedback from "../../../../../shared/AudioFeedback/AudioFeedback";

const Feedback = (props: any) => {
  const {
    activeAnswer,
    setLoading,
    loadingId,
    ratingCount,
    SetRatingCount,
    id,
    loading,
    showFeedbackAgain,
    actionData,
    showFeedbackForEdit,
    setShowFeedbackAgain,
  } = props;
  const [addFeedback, setAddFeedback] = useState(false);
  const [audioUploadLoading, setAudioUploadLoading] = useState(false);
  const [showAudioFeedback, setShowAudioFeedback] = useState(false);
  const [duration, setDuration] = useState(0);

  const dispatch = useAppDispatch();
  const recorderControls = useAudioRecorder();

  const InterviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewAnswerSlice;
  });

  const ratingHandler = (n: number) => {
    if (ratingCount === 1 && n === 1) {
      SetRatingCount(0);
    } else {
      SetRatingCount(n);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const onSubmit = (values: any) => {
    const data = { ...values, rating: ratingCount };
    if (showFeedbackForEdit && actionData) {
      data.id = actionData.id;
    }
    dispatch(
      addOrUpdateAnswer({
        data: data,
        videoId: activeAnswer.id,
        setLoading,
        loadingId,
      })
    );
    setLoading(true);
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  useEffect(() => {
    if (recorderControls.recordingTime !== 0) {
      setDuration(recorderControls.recordingTime);
    }
  }, [recorderControls.recordingTime]);

  useEffect(() => {
    if (actionData && showFeedbackForEdit) {
      setValue("feedback_message", actionData.feedback);
      SetRatingCount(actionData.rating);
    }
  }, [actionData, showFeedbackForEdit]);

  const handleBack = () => {
    setAddFeedback(false);
    setShowFeedbackAgain(false);
  };

  const handleAudioFeedback = () => {
    setAudioUploadLoading(true);
    setShowAudioFeedback(false);
    dispatch(getPresignedUrlForAdminAudioUpload());
  };

  const startRecording = () => {
    setShowAudioFeedback(true);
    recorderControls.startRecording();
  };

  useEffect(() => {
    if (InterviewState.req_success.audio_presigned_url) {
      dispatch(
        uploadAdminAudioToAws({
          url: InterviewState.audio_presigned_url,
          file: recorderControls.recordingBlob,
        })
      );
    }
  }, [InterviewState.req_success.audio_presigned_url]);

  useEffect(() => {
    if (InterviewState.req_success.upload_audio) {
      if (!InterviewState.audio_presigned_url) {
        return;
      }
      const url = InterviewState.audio_presigned_url.split("?");
      const data = {
        feedback_message: url[0],
        duration: duration,
        rating: ratingCount,
      };

      dispatch(
        addOrUpdateAnswer({
          data: data,
          videoId: activeAnswer.id,
          setLoading,
          loadingId,
        })
      );
    }
  }, [InterviewState.req_success.upload_audio]);
  return (
    <form className="right_cotent" onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="form_content">
        <div className="heading">Feedback</div>
        {!addFeedback && !showFeedbackAgain && (
          <>
            {" "}
            <div className="feedback__empty__wrapper">
              <span> No feedback found.</span>
            </div>
            <div className="add__feedback">
              {" "}
              <PrimaryButton
                text="Add Feedback"
                type="button"
                onClick={() => setAddFeedback(true)}
              />
            </div>
          </>
        )}

        {(addFeedback || showFeedbackAgain) && (
          <>
            <TextArea
              name="feedback_message"
              className="custom__textarea responsive__text__area"
              register={register}
              error={errors?.feedback_message}
              // disabled={disabled}
            />
            <div className="admin_rating">
              <p>Rating</p>
              <div className="star mb-1">
                {/*  @ts-ignore */}
                <Rating
                  quiet
                  initialRating={ratingCount}
                  onClick={(i) => ratingHandler(i)}
                  emptySymbol={<Star color="#FFC600" />}
                  fullSymbol={<Star color="#FFC600" fill="#FFC600" />}
                />
              </div>
            </div>
            {!showAudioFeedback && (
              <div className="feedback__footer__section">
                <div
                  className="back__icon__div"
                  role="button"
                  aria-hidden
                  onClick={handleBack}
                >
                  <ChevronLeft className="back__icon" />
                </div>

                {audioUploadLoading && (
                  <PrimaryButton text="" type="button" loading />
                )}
                {!audioUploadLoading && (
                  <PrimaryButton
                    text="Submit"
                    type="submit"
                    loading={
                      InterviewState.loading.add && id === loadingId && loading
                    }
                  />
                )}
                {!audioUploadLoading && (
                  <div
                    className="recored__feedback__icon"
                    role="button"
                    aria-hidden
                    onClick={startRecording}
                  >
                    <Mic className="audio__mic" />
                  </div>
                )}
              </div>
            )}
            {showAudioFeedback && (
              <AudioFeedback
                recorderControls={recorderControls}
                handleAudioFeedback={handleAudioFeedback}
              />
            )}
          </>
        )}
      </div>
    </form>
  );
};

export default Feedback;
