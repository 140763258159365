import React, { useState } from "react"
import "./tourDonePopupModal.scss";
import { X } from "react-feather";
import tourDoneImg from "../../../../../assets/images/tour-done.svg";
import { RootStore, useAppDispatch, useAppSelector } from "../../../../../store/store";
import { closeTourDonePopupModal } from "../../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnboardingPopupSlice";
import { webGuideOnboarding } from "../../../../../store/features/UserDashboard/ShowOnboardingPopup/ShowOnBoardingPopupApi";
import { loadUser } from "../../../../../store/features/Auth/AuthApi";

const TourDonePopupModal = () => {
    const dispatch = useAppDispatch();
    const[autheFail,setAuthFail] = useState(false)
    const {currentStep,steps} = useAppSelector((store:RootStore) => store.ShowPopupSlice);
    const singleStepData = steps.filter((item:any)=>item.id === currentStep);

    const closeDoneModal = ()=>{
        dispatch(closeTourDonePopupModal());
        dispatch(webGuideOnboarding({current_step: currentStep,
          is_completed: true})).then(()=>dispatch(loadUser({setAuthFail:setAuthFail})));
    }

  return (
    <div className="tour__done__wrapper">
    <div className="tour__done__container">
      <div
        className="close__icon"
        onClick={closeDoneModal}
        role="button"
        aria-hidden
      >
        <X />
      </div>
      <div className="content__wrapper">
        <img src={tourDoneImg} alt="" />
        <h3>{singleStepData[0].title}</h3>
        <p>
        {singleStepData[0].message}
        </p>
        <button type="button" 
        onClick={closeDoneModal}
        >
          Done
        </button>
      </div>
    </div>
  </div>
  )
}

export default TourDonePopupModal
