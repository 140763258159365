/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import tickIcon from "../../../assets/icons/tickIcon.svg";
import tacCheckboxTickIcon from "../../../assets/icons/tac-checbox-icon.svg";

import "./checkBox.scss";


export default function CheckBox(props: any) {
  const { name, label, sm, fullwidth, value, onClick ,tacCheckBoxvalue,border,CheckboxValue,labelClassname} = props;

  return (
    <div className="checkbox__container" onClick={onClick}>
      <div className={` checkbox ${sm && "sm"}`}>
       <div>
       <div className={` checkbox__icon ${sm && "sm"} ${border && "checkbox__div"}`}>
          {value && (
            <img
              src={tickIcon}
              className={` tickiconforcheckbox ${sm && "sm"}`}
              alt="tick"
            />
          )}
          {
            tacCheckBoxvalue && (
              <img
              src={tacCheckboxTickIcon}
              className={` tickiconforcheckbox ${sm && "sm"}`}
              alt="tick"
            />

            )
          }
          {
             CheckboxValue && (  <Icon icon="charm:tick"  className="tick__icon"/>)
          }
        </div>{" "}
       </div>
        <span className={`label ${fullwidth && "full"} ${sm && "sm"} ${tacCheckBoxvalue && "active__label"} ${labelClassname && "label__style"} `}>
          {" "}
          {label}
        </span>
      </div>
    </div>
  );
}
