import { useState } from "react";
import { ChevronDown, LogOut, X } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { RootStore, useAppSelector } from "../../../../store/store";

const MyDrawer = (props: any) => {
  const { drawer, setDrawer, handleNavigation, handleLogout } = props;
  const [openLink, setOpenLink] = useState(false);
  const location = useLocation();

  const dashState: any = useAppSelector((store: RootStore) => {
    return store.NewDashboard;
  });
 

  const closeAllDrawer = () => {
    setOpenLink(false);
    setDrawer(false);
  };
  const handleDrawerNavigation = (link: string, redirect = true) => {
    closeAllDrawer();
    handleNavigation(link);
  };

  return (
    <div className={`drawer__container ${drawer ? "active" : "hide"}`}>
      <div className={`card__column  ${drawer ? "active" : "hide"}`}>
        <div className="icon__div">
          <X
            className="closing__icon"
            role="button"
            aria-hidden
            onClick={() => setDrawer(false)}
          />
        </div>
        <div className="user__info__section">
          {/* <img
            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
            alt="user"
            className="user__image"
          /> */}
          <span className="name"> Matt Mathews</span>
          <span className="email"> mattmatthews@gmail.com</span>
        </div>
        <div className="all__link__wrapper">
          <div className="links__sction">
            <div className="drawer__divider mt-2" />
            {
              !location.pathname.includes("/product-manager-user-dashboard")  ? (   <HashLink
                to="/academic-user-dashboard#myprogress"
                onClick={closeAllDrawer}
              >
                <span className="link">My Courses</span>
              </HashLink>) : (   <HashLink
              to="/product-manager-user-dashboard#myprogress"
              onClick={closeAllDrawer}
            >
              <span className="link">My Courses</span>
            </HashLink>)
            }

         
            <div className="drawer__divider mt-2" />
            {
               !location.pathname.includes("/product-manager-user-dashboard") && ( <Link to="/ai-assist">
               {" "}
               <span className="link">AI Assist</span>
             </Link>)
            }
           
            <div className="drawer__divider mb-2" />
            {
               !location.pathname.includes("/product-manager-user-dashboard") && (<div
                className={`drawer__quick__link__wrapper ${
                  openLink && "active__drawer"
                }`}
              >
                <div className="quick__linK__header ">
                  <span className={`quick__link ${openLink && "active__drawer"}`}>
                    Quick Links
                  </span>
                  <ChevronDown
                    className={`down__icon ${openLink && "active__drawer"}`}
                    onClick={() => setOpenLink(!openLink)}
                  />
                </div>
  
                {!dashState.loading.quick_links &&
                  dashState.req_success.quick_links &&
                  dashState.quick_links.map((link: any) => {
                    return (
                      <span
                        className="link sub_link"
                        key={link.id}
                        role="button"
                        aria-hidden
                        onClick={() => handleDrawerNavigation(link)}
                      >
                        {link.title}
                      </span>
                    );
                  })}
              </div>)
            }
                      {
            location.pathname.includes("/product-manager-user-dashboard") && ( 
           
              <Link to="/support"><span className="link">Support</span></Link>
           )
          }
            

            <div className="drawer__divider mt-2" />
            <Link to="/myaccount">
              {" "}
              <span className="link">My Account</span>
            </Link>
          </div>
          <div className="logout__section">
            <div className="drawer__divider mb-2" />
            <div
              className="logout__button"
              role="button"
              onClick={handleLogout}
              aria-hidden
            >
              <LogOut className="logout__iocn" />
              <span className="logout__text">Logout</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDrawer;
