/* eslint-disable jsx-a11y/control-has-associated-label */
import { Edit2, Trash } from "react-feather";
import "./table.scss";

const Table = (props: any) => {
  const { children, heading, full } = props;

  return (
    <div className={`table__container ${full && "full"}`}>
      <table>
        {heading}
        {children}
      </table>
    </div>
  );
};

export default Table;

export const TableHeading = (props: any) => {
  const { children } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    // <>
    <thead>
      <tr>{children}</tr>
    </thead>
    // </>
  );
};

export const TableBody = (props: any) => {
  const { children } = props;

  return <tbody>{children}</tbody>;
};

export const TableAction = (props: any) => {
  const { onEdit, onDelete, padding, mt2, hide, hideDelete } = props;

  return (
    <td
      className={`action__icons ${padding ? "increse_padding" : ""} ${
        hide && "hide"
      } `}
    >
      <Edit2 className="edit__icon" onClick={onEdit} />
      {!hideDelete && <Trash className="delete__icon" onClick={onDelete} />}
    </td>
  );
};
