import { useEffect, useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft } from "react-feather";

import * as yup from "yup";
import "react-tabs/style/react-tabs.css";
import SingleFeedback from "./SingleFeedback";
import "./index.scss";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../store/store";
import TextArea from "../../../../shared/Inputs/TextArea/TextArea";
import { REQUIRED_VALIDATION } from "../../../../helpers/utils/formUtils";
import {
  deleteUserFeedback,
  getQuestionAnswerById,
  submitAnswerFeedback,
} from "../../../../store/features/UserDashboard/NewUserDashboard/InterviewPrepration/InterviewPreprationApi";
import DeleteModal from "../../../../shared/Modals/DeleteModal/DeleteModal";
import FeedbackDeleteModal from "./DeleteModal/FeedbackDeleteModal";
import ReplyButton from "./ReplyButton";

const Feedback = (props: any) => {
  const {
    feedback,
    setSelectedTabIndex,
    selectedTabIndex,
    showReplyBox,
    setShowReplyBox,
    stopCamera,
    setCurrentDuration,
    currentDuration,
    classNamehandle,
    setAudioOrVideoResponseTye
  } = props;

  const [showDeleteModal, setDeleteShowModal] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [actionData, setActionData] = useState<any>(null);
  // const [selectedIndex, setSelectedIndex] = useState(5);

  const dispatch = useAppDispatch();
  const authState: any = useAppSelector((store: RootStore) => {
    return store.AuthSlice;
  });
  const interviewState: any = useAppSelector((store: RootStore) => {
    return store.InterviewPreprationSlice;
  });

  const validationSchema = yup.object().shape({
    feedback_message: yup.string().required(REQUIRED_VALIDATION("Reply")),
  });
  const formOptions: any = {
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    reValidateMode: "onChange",
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,

    formState: { errors },
  } = useForm<any>(formOptions);
  const onSubmit = (values: any) => {
    const data = { ...values };

    if (showReplyBox && actionData) {
      data.id = actionData.id;
    }
    dispatch(
      submitAnswerFeedback({
        data: data,
        id: interviewState.active_answer.answers[selectedTabIndex - 1].id,
      })
    );
  };
  const onError = (error: any) => {
    console.log("ERROR:::", error);
  };

  const handleSelectedIndex = (index: number) => {
    stopCamera();
    if (currentDuration || currentDuration !== "" || currentDuration !== "0") {
      setCurrentDuration(0);
    }
    if (index >= 0 && typeof index === "number") {
      setSelectedTabIndex(Number(index + 1));
      setAudioOrVideoResponseTye("video");
    } else {
      setSelectedTabIndex(null);
    }
    setShowReplyBox(false);
  };

  const handleAction = (feedback: any) => {
    setActionData(feedback);
    setShowAction(true);
  };

  document.body.addEventListener("click", (e: any) => {
    if (
      !e.target.classList.contains("three__dot") &&
      !e.target.classList.contains("select__section") &&
      !e.target.classList.contains("select__action") &&
      !e.target.classList.contains("action__icon") &&
      !e.target.classList.contains("action__divider")
    ) {
      setShowAction(false);
    }
  });

  useEffect(() => {
    if (interviewState.req_success.delete_feedback) {
      setDeleteShowModal(false);
      dispatch(
        getQuestionAnswerById({
          id: feedback.id,
        })
      );
    }
  }, [interviewState.req_success.delete_feedback]);

  useEffect(() => {
    if (interviewState.req_success.add_answer) {
      setShowReplyBox(false);
      reset();
      dispatch(
        getQuestionAnswerById({
          id: feedback.id,
        })
      );
    }
  }, [interviewState.req_success.add_answer]);

  useEffect(() => {
    if (showReplyBox && actionData) {
      setValue("feedback_message", actionData.feedback);
    }
  }, [showReplyBox, actionData]);

  const deleteUser = () => {
    dispatch(deleteUserFeedback(actionData.id));
  };

  const handleAddReply = () => {
    reset();
    setShowReplyBox(true);
    setActionData(null);
  };

  const handleBack = () => {
    reset();
    setShowReplyBox(false);
  };
  useEffect(() => {
    if (
      interviewState.req_success.get_answer &&
      interviewState.notificationData &&
      interviewState.active_answer?.answers?.length > 0
    ) {
      let answerIndex: any = "";

      interviewState.active_answer?.answers.forEach(
        (answer: any, index: number) => {
          if (
            answer.id ===
            interviewState.notificationData.interview_answer_video_id
          ) {
            answerIndex = index;
          }
        }
      );
      handleSelectedIndex(answerIndex);
    }
  }, [interviewState.req_success.get_answer, interviewState.notificationData]);

  return (
    <div className="feedback__wrapper ">
      <span className="feedback__title"> Feedback on recorded responses</span>

      {interviewState.req_success.get_answer && (
        <Tabs
          className="tab__container"
          selectedIndex={selectedTabIndex - 1}
          onSelect={(index) => handleSelectedIndex(index)}
        >
          <TabList
            className={`tab__list__section ${
              !interviewState.active_answer?.answers && "no__tabs"
            }`}
          >
            {interviewState.active_answer?.answers &&
              interviewState.active_answer?.answers.map(
                (answer: any, index: number) => {
                  return (
                    <Tab
                      className={`single__tab  ${index > 0 && "mll"}`}
                      key={answer.id}
                    >
                      {index + 1}
                      <sup>
                        {" "}
                        {index + 1 === 1 && "st"} {index + 1 === 2 && "nd"}
                        {index + 1 === 3 && "rd"}
                        {index + 1 > 3 && "th"}
                      </sup>{" "}
                      Response{" "}
                    </Tab>
                  );
                }
              )}
          </TabList>

          {!showReplyBox && interviewState.active_answer?.answers === null && (
            <div className="no__feedback__found">No feedback found.</div>
          )}

          {!showReplyBox &&
            interviewState.active_answer?.answers &&
            interviewState.active_answer?.answers.map((answer: any) => {
              return (
                <TabPanel key={answer.id}>
                  <div className="scrollable__feedback__list ">
                    {answer?.feedbacks &&
                      answer?.feedbacks.map((feedback: any) => {
                        return (
                          <SingleFeedback
                            key={feedback.id}
                            feedback={feedback}
                            authState={authState}
                            setShowAction={setShowAction}
                            handleAction={handleAction}
                            actionData={actionData}
                            showAction={showAction}
                            setDeleteShowModal={setDeleteShowModal}
                            setShowReplyBox={setShowReplyBox}
                            handleDeleteEditDropdownStyle
                          />
                        );
                      })}
                    {answer.feedbacks.length === 0 && (
                      <div className="no__feedback__found">
                        No feedback found.
                      </div>
                    )}
                  </div>
                </TabPanel>
              );
            })}

          <form
            className="feedback__form"
            onSubmit={handleSubmit(onSubmit, onError)}
          >
            {showReplyBox && (
              <div className="text__area__form">
                {" "}
                <TextArea
                  name="feedback_message"
                  text="Reply"
                  label="Reply"
                  height32
                  register={register}
                  error={errors?.feedback_message}
                  handleTextAreaStyle
                />
              </div>
            )}
            <ReplyButton
              interviewState={interviewState}
              selectedIndex={selectedTabIndex}
              showReplyBox={showReplyBox}
              handleAddReply={handleAddReply}
              handleBack={handleBack}
            />
          </form>
        </Tabs>
      )}

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={interviewState.loading.delete_feedback}
        onClick={deleteUser}
      />
      <FeedbackDeleteModal
        showModal={showDeleteModal}
        setShowModal={setDeleteShowModal}
        loading={interviewState.loading.delete_feedback}
        onClick={deleteUser}
      />
    </div>
  );
};

export default Feedback;
