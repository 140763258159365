/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
import { ArrowRight } from "react-feather";
import arrowIcon from "../../assets/images/arrow.svg";
import warrowIcon from "../../assets/images/warrow.svg";
import "./buttons.scss";
import { RootStore, useAppSelector } from "../../store/store";
import GoogleIcon from "../../assets/icons/googleIcon.svg";

// function Buttons(props: any) {
//   return <div />;
// }

const PrimaryButton = (props: any) => {
  const { text, type, width, loading, size, disable, onClick, disableBtnStyle, ...more } = props;

  return (
    <button
      className={`custom__button primary__button ${width} ${size} ${disableBtnStyle ? "disableBtnStyle":""} `}
      type={type || "button"}
      onClick={onClick}
      disabled={loading || disable}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const TACPrimaryButton = (props: any) => {
  const { text, type, width, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button tac_primary__button ${width} ${size} `}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const SuccessButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button success__button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const SecondaryButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button secondary__button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const DangerButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button danger__button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const MutedeButton = (props: any) => {
  const { text, type, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button muted__button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled
      {...more}
    >
      {text}
    </button>
  );
};

const WhiteButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button white__button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const SmokeButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button smoke__button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};
const MdSmokeButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button md__smoke__button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const YellowButton = (props: any) => {
  const {
    text,
    type,
    loading,
    size,
    normal,
    animationLoading,
    onClick,
    className,
    ...more
  } = props;
  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });
  return (
    <button
      className={`custom_yellow_button ${className}  ${size} ${
        normal && "normal"
      }`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading || animationLoading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {animationLoading && (
        <div className="gooey">
          <span className="dot" />
          <div className="dots">
            <span className="span-dot" />
            <span className="span-dot" />
            <span className="span-dot" />
          </div>
        </div>
      )}
      {!loading && !animationLoading && <span className="text">{text}</span>}
      {!loading && !animationLoading && (
        <img
          src={themeState.themeMode === "light" ? warrowIcon : arrowIcon}
          className="arrow_right"
          alt="arrow"
        />
      )}
    </button>
  );
};

const SkyButton = (props: any) => {
  const {
    text,
    type,
    loading,
    size,
    normal,
    animationLoading,
    onClick,
    ...more
  } = props;

  return (
    <button
      className={`sky_button  ${size} ${normal && "normal"}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading || animationLoading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {animationLoading && (
        <div className="gooey">
          <span className="dot" />
          <div className="dots">
            <span className="span-dot" />
            <span className="span-dot" />
            <span className="span-dot" />
          </div>
        </div>
      )}
      {!loading && !animationLoading && text}
    </button>
  );
};
const PlanButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button plan__button ${size}`}
      type={type || "button"}
      disabled={loading}
      onClick={onClick}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const ActiveToggleButton = (props: any) => {
  const { text, type, size, active, onClick, ...more } = props;

  return (
    <button
      className={`custom__button activetoggle__button ${size} ${
        active ? "activetoggle__button-active" : ""
      }`}
      type={type || "button"}
      onClick={onClick}
      {...more}
    >
      {text}
    </button>
  );
};
const DangerTextButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button danger_text___button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

const PrimaryTextButton = (props: any) => {
  const { text, type, loading, size, onClick, tac, nounderline, ...more } =
    props;

  return (
    <button
      className={`custom__button primary_text___button ${size} ${
        nounderline && "no_underline"
      } ${tac && "tac"}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && text}
    </button>
  );
};

// Goolge login button
const GoogleLoginButton = (props: any) => {
  const { text, type, loading, size, onClick, ...more } = props;

  return (
    <button
      className={`custom__button google__button ${size}`}
      type={type || "button"}
      onClick={onClick}
      disabled={loading}
      {...more}
    >
      {loading && <div className="spinner mr-1"></div>}
      {!loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <img
            src={GoogleIcon}
            alt="Google Icon"
            style={{ height: "1.8rem", width: "1.8rem" }}
          />
          {text}
        </div>
      )}
    </button>
  );
};
// export default Buttons;
export {
  PrimaryButton,
  SuccessButton,
  SecondaryButton,
  DangerButton,
  MutedeButton,
  ActiveToggleButton,
  WhiteButton,
  PlanButton,
  DangerTextButton,
  PrimaryTextButton,
  YellowButton,
  SkyButton,
  GoogleLoginButton,
  TACPrimaryButton,
  SmokeButton,
  MdSmokeButton
};
