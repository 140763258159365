/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
import { useEffect, useState } from "react";
import TableHeadingTitle from "../../../../../shared/TableHeadingTitle/TableHeadingTitle";
import SearchBar from "../../../../../shared/SearchBar/SearchBar";
import { searchUsers } from "../../../../../store/features/AdminPanel/User/UserSlice";
import { useAppDispatch } from "../../../../../store/store";

const TrialUserHeader = (props: any) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(searchUsers(searchText));
  }, [searchText]);
  return (
    <div className="user__table__header_container">
      <div className="left__section">
        <TableHeadingTitle>Trial Users</TableHeadingTitle>
      </div>
      <div className="right__section">
        <div className="search__box">
          <SearchBar value={searchText} setValue={setSearchText} />
        </div>
      </div>
    </div>
  );
};

export default TrialUserHeader;
