/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axiosInstance from "../../../../apiConfigs/axiosInstance";
import { AUTH_URL } from "../../../../apiConfigs/urlConfig";
import {
  ERROR__TOASTER,
  REQUIRED__TOASTER,
} from "../../../../helpers/utils/TosterError";
import { renderDynamicUrlFromState } from "../../../../helpers/utils/urlHelper";

export const loadUsers = createAsyncThunk(
  "loadUsers",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/get-all-users`);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getusersByFilter = createAsyncThunk(
  "getusersByFilter",
  async (data:any, { dispatch, rejectWithValue, getState }) => {
    try {
       
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(`${dynamic_url}/get-all-users?${data.role?`roles=${data.role}`:""}${data.plan?`&plan=${data.plan}`:""}`);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllTrialUser = createAsyncThunk(
  "getAllTrialUser",
  async (undefined, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.get(
        `${dynamic_url}/get-all-training-users`
      );
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const addUsers = createAsyncThunk(
  "addUsers",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/add-user`, data);
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
export const rensedInvitation = createAsyncThunk(
  "rensedInvitation",
  async (
    { email }: { email: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/resend-user-invite`,
        {
          email: email,
        }
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUsers = createAsyncThunk(
  "updateUsers",
  async (
    { userId, data }: { userId: any; data: any },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/update-user/${userId}`,
        data
      );
      REQUIRED__TOASTER(res.data);
      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteUsers = createAsyncThunk(
  "deleteUsers",
  async (data: { data: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.delete(`${dynamic_url}/users/${data}`);
      REQUIRED__TOASTER(res.data);

      res.data.id = data;

      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.response);
    }
  }
);

export const deleteMultipleUsers = createAsyncThunk(
  "deleteMultipleUsers",
  async ({ids}: { ids: any }, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(`${dynamic_url}/users/bulk-delete`, 
      {
        ids: ids
      });
      REQUIRED__TOASTER(res.data);

      res.data.ids = ids;

      return res.data;
    } catch (error: any) {
      ERROR__TOASTER(error);

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.response);
    }
  }
);

export const UploadCSVFile = createAsyncThunk(
  "UploadCSVFile",
  async (
    {
      name,
      file,
      setProgress,
      SetFileUpladError,
    }: {
      name: any;
      file: any;
      setProgress: any;
      SetFileUpladError: any;
    },
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const res = await axiosInstance.post(
        `${dynamic_url}/add-user/bulk-upload`,
        file,
        {
          onUploadProgress: (p) => {
            const percentageCompleted = Math.round((p.loaded * 100) / p.total);
            setProgress({
              name: name,
              progress: percentageCompleted,
            });
          },
        }
      );

      REQUIRED__TOASTER(res.data);

      return res.data;
    } catch (error: any) {
      SetFileUpladError(error.response.data);
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadUserExcelFile = createAsyncThunk(
  "downloadUserExcelFile",
  async (role: string | null, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const response = await axiosInstance.get(
        `${dynamic_url}/exportusersexcel${role ? `?role=${role}` : ""}`,
        {
          responseType: "blob",
        }
      );
      const fileURL = window.URL.createObjectURL(response.data);
      const alink = document.createElement("a");
      alink.href = fileURL;
      const now = new Date();
      alink.download = `users_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}${
        role ? `_${role}` : ""
      }.xlsx`;
      alink.click();
      URL.revokeObjectURL(fileURL);
      return true;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadUserCsvFile = createAsyncThunk(
  "downloadUserCsvFile",
  async (role: string | null, { dispatch, rejectWithValue, getState }) => {
    try {
      const state = getState();
      const dynamic_url = renderDynamicUrlFromState(state);
      const response = await axiosInstance.get(
        `${dynamic_url}/exportuserscsv${role ? `?role=${role}` : ""}`,
        {
          responseType: "blob",
        }
      );
      const fileURL = window.URL.createObjectURL(response.data);
      const alink = document.createElement("a");
      alink.href = fileURL;
      const now = new Date();
      alink.download = `users_${now.getFullYear()}_${now.getMonth()}_${now.getDate()}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}${
        role ? `_${role}` : ""
      }.csv`;
      alink.click();
      URL.revokeObjectURL(fileURL);
      return true;
    } catch (error: any) {
      toast.error(error.response.data.message);
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
