/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/media-has-caption */
import { Pause, Play } from "react-feather";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import "./index.scss";
import { useRef, useState } from "react";
import { getCurrentMinuteAndSecondFromSeconds } from "../../helpers/utils/dateTime";
import blackPlay from "../../assets/images/blackPlay.svg";
import whitePlay from "../../assets/images/whiteplay.svg";
import whitePause from "../../assets/images/whitepause.svg";
import blackPause from "../../assets/images/blackpause.svg";
import { RootStore, useAppSelector } from "../../store/store";

interface Iprops {
  // total duration in seconds
  duration: string;
  url: string;
  AudiPlayerStyleHanlde?:boolean;
}

const AudioPlayer = (props: Iprops) => {
  const { duration, url ,AudiPlayerStyleHanlde} = props;
  const [play, setPlay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentDuration, setCurrentDuration] = useState(0);
  const audioRef = useRef<any>();

  const themeState: any = useAppSelector((store: RootStore) => {
    return store.ThemeSlice;
  });

  const handleMusicPlayer = () => {
    if (audioRef.current?.paused) {
      audioRef.current?.play();
      setPlay(true);
    } else {
      audioRef.current?.pause();
      setPlay(false);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentDuration(Math.round(audioRef.current.currentTime));
    }
  };

  const checkIfVideoEnded = () => {
    setPlay(false);
  };
  const seekingHandler = () => {
    setLoading(true);
  };
  const seekedHandler = () => {
    setLoading(false);
  };

  const handleSliderTime = (seconds: any) => {
    audioRef.current.currentTime = seconds;
    setCurrentDuration(seconds);
  };
  return (
    <div className={`custom__react__audio__player ${AudiPlayerStyleHanlde && "handleAudioPlayerStyle"}`}>
      <audio
        ref={audioRef}
        onTimeUpdate={handleTimeUpdate}
        onEnded={checkIfVideoEnded}
        onSeeking={seekingHandler}
        onSeeked={seekedHandler}
      >
        <source src={url} type="audio/mp3" />
      </audio>

      {/* {!play && <Play className="play__icon" />}
       */}
      {/* {play && <Pause className="pause__icon" />} */}

      {!play && (
        <img
          src={themeState.themeMode === "dark" ? whitePlay : blackPlay}
          className="pause_play_icon"
          alt="icon"
          role="button"
          aria-hidden
          onClick={handleMusicPlayer}
        />
      )}

      {play && (
        <img
          src={themeState.themeMode === "dark" ? whitePause : blackPause}
          className="pause_play_icon sm"
          alt="icon"
          role="button"
          aria-hidden
          onClick={handleMusicPlayer}
        />
      )}

      <div className="current__durtation">
        {getCurrentMinuteAndSecondFromSeconds(currentDuration)}{" "}
      </div>

      <div className="time__progress__section">
        <Slider
          onChange={(nextValues: any) => {
            handleSliderTime(nextValues);
          }}
          value={Number(currentDuration)}
          min={0}
          max={Number(duration)}
          defaultValue={0}
          step={1}
        />
      </div>
      <div className="total__durtation">
        <span> {getCurrentMinuteAndSecondFromSeconds(Number(duration))}</span>
      </div>
    </div>
  );
};

export default AudioPlayer;
