/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";

import Table, {
  TableBody,
  TableAction,
  TableHeading,
} from "../../../../../shared/Table/Table";
import DeleteModal from "../../../../../shared/Modals/DeleteModal/DeleteModal";
import {
  RootStore,
  useAppDispatch,
  useAppSelector,
} from "../../../../../store/store";
import image from "../../../../../assets/icons/user.png";
import { deleteAuthors } from "../../../../../store/features/AdminPanel/Blog/Authors/AuthorsApi";
import PaginateButton from "../../../../../shared/PaginateButton/PaginateButton";
import "./index.scss";
import Avatar from "../../../../../shared/Avatar/Avatar";
import {
  authorTablePaginate,
  editAuthorInfo,
} from "../../../../../store/features/AdminPanel/Blog/Authors/AuthorSlice";
import Empty from "../../../../../shared/Empty/Empty";

const BlogAuthorTable = () => {
  const [authorId, setAthorId] = useState(0);
  const dispatch = useAppDispatch();

  const [showDeleteModal, setDeleteShowModal] = React.useState(false);
  const deleteModalHandler = (authorId: number) => {
    setAthorId(authorId);
    setDeleteShowModal(true);
  };
  const deleteAuthor = () => {
    dispatch(deleteAuthors({ authorId: authorId }));
  };
  const editModalHandler = (author: any) => {
    dispatch(editAuthorInfo(author));
  };
  const authorState: any = useAppSelector((store: RootStore) => {
    return store.AuthorSlice;
  });
  useEffect(() => {
    if (authorState.req_success.delete) {
      setDeleteShowModal(false);
    }
  }, [authorState.req_success.delete]);
  const handlePagination = (num: number) => {
    dispatch(authorTablePaginate(num));
  };
  return (
    <>
      {" "}
      <div className="author__table">
        <Table
          heading={
            <TableHeading>
              <th> Name</th>
              <th className="hide__text">.</th>
            </TableHeading>
          }
        >
          <TableBody>
            {!authorState.loading.get &&
              authorState.req_success.get &&
              authorState.authors.map((author: any) => {
                return (
                  <tr key={author.id}>
                    <td className="author_table_tr">
                      <div className="avatar__section">
                        <Avatar imageUrl={author.avatar_url || image} />
                      </div>
                      {author.name}
                    </td>
                    <td>
                      {" "}
                      <TableAction
                        onEdit={() => editModalHandler(author)}
                        onDelete={() => deleteModalHandler(author.id)}
                      />
                    </td>
                  </tr>
                );
              })}
          </TableBody>
        </Table>
        {!authorState.loading.get &&
              authorState.req_success.get &&
              authorState.authors.length === 0 && (
        <div className="mt-5 handle__empty__container__div">
          <Empty emptyText="It seems empty." custombg={true} />
        </div>
         )}

        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setDeleteShowModal}
          loading={authorState.loading.delete}
          onClick={deleteAuthor}
        />
      </div>
      <div className="author__pagination__section">
        <PaginateButton
          rowPerView={6}
          paginate={handlePagination}
          totalRows={authorState.all_authors.length}
        />
      </div>
    </>
  );
};

export default BlogAuthorTable;
